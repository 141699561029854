import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getRequest} from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class ExtendVendorMaster extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        showtable: false,
         formData: {
            vendorname: '',
            vendorid: '',
            vendortype: '',
            status: '',
            orgname: ''
         },
         tableData: [],
         dropdown_vendorname: [],
         dropdown_vendorid: [],
         dropdown_vendortype: [],
         extendJSON: [],
         dropdown_orgname: [],
         companyDetails: [],
         error: false,
         errorMessage: '',
         createJSON: []
      }
    }

    componentDidMount(){
        localStorage.setItem("pageName", "Search VendorMaster")

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))

        getRequest(`api/edureka/getVendorJSONForExtension`).then(res => {
            console.log(res.res.data)
            this.setState({
                ...this.state,
                extendJSON: res.res.data,
                createJSON: res.res.data
            })
        })

        getRequest(`api/edureka/getVM1`).then(res => {
            if(res.res.status === 'success'){
                let temp = JSON.parse(res.res.data)
                console.log(temp)
                let temp1 = temp.map(value => value['Vendor Name'])
                let vendorname = [... new Set(temp1)]
                console.log(vendorname)
                let temp3 = temp.map(value => value['Vendor Type'])
                let tem = temp3.filter(value=> value)
                let vendortype = [... new Set(tem)]

                let vid1 = temp.map(value=> value["Vendor ID"])
                let vid2 = [... new Set(vid1)]
    
                this.setState({
                    ...this.state,
                    dropdown_vendorname: vendorname,
                    dropdown_vendorid: vid2,
                    dropdown_vendortype: vendortype
                })
            } else {
                console.log("No Data")
            }
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleVendor = (i) => {
        console.log(i)
        const vendorid = i['Vendor ID']

        getRequest(`api/edureka/getVMUpdatedJSON?vendorid=${vendorid}`).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({ redirect : true, SearchJSON : res.res.data })                
            } else {
                console.log("No Data")
            }
        })
    }

    handleSearch = () => {

        const {orgname} = this.state.formData

        if(orgname){

            this.setState({
                ...this.state,
                error: false
            })

            const vendorname = this.state.formData.vendorname
            const vendortype = this.state.formData.vendortype
            const vendorid = this.state.formData.vendorid
    
            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
            console.log(id)
    
            const companyid = id.map(item => item.orgid)
    
            console.log(companyid)
    
            getRequest(`api/edureka/getExtendVendorMasterData?vendorname=${vendorname}&vendortype=${vendortype}&vendorid=${vendorid}&orgid=${companyid[0]}`).then(res => {
                if(res.res.status === 'success'){
                    let temp = JSON.parse(res.res.data)
                    console.log(temp)
        
                    this.setState({
                        ...this.state,
                        tableData: temp,
                        showtable: true
                    })
                } else {
                    console.log("No Data")
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }

    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            error: false,
            formData: {
                vendorname: '',
                vendorid: '',
                vendortype: '',
                status: '',
                orgname: ''
             }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

    handleExtend = (item) => {
        const vendorID = item["Vendor ID"]
        const vendorName = item["Vendor Name"]

        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

        console.log(id)

        const companyid = id.map(item => item.orgid)

        console.log(companyid)

        console.log(vendorID, vendorName)

        const vendorNameIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Vendor Name')

        const orgNameIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Organization Name')

        const vendorIDIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Vendor ID')

        const orgIDIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Organization ID')

        const updatedJSONData = [...this.state.createJSON]

        // console.log(updatedJSONData[0].children[0])

        updatedJSONData[1].children[0].children[vendorNameIndex].val = vendorName
        updatedJSONData[1].children[0].children[vendorNameIndex].editable = "false"
        updatedJSONData[1].children[0].children[vendorIDIndex].val = vendorID
        updatedJSONData[1].children[0].children[orgNameIndex].val = this.state.formData.orgname
        updatedJSONData[1].children[0].children[orgIDIndex].val = companyid[0]

        this.setState({
            ...this.state,
            extendJSON: updatedJSONData,
            redirect: true
        },() => console.log(this.state.extendJSON))
    }

    handleScreenRouting = (item) => {
        console.log(item)
        const orgID = item['Org ID']
        const preferredID = item['Preferences Vendor ID']

        console.log(orgID, preferredID)

        getRequest(`api/edureka/getExtendJSONForVendorPreference?lde1=${orgID}&lde2=${preferredID}`).then(response => {

            this.setState({
                ...this.state,
                extendJSON: response.res.data,
                redirect: true
            })
            console.log(response.res.data)
        }).catch(err => console.log(err))
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"VendorPreferences"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.extendJSON} 
            onBack = {this.onBack}
            />        
        )
    }
    else return (

      <Fragment>
        <div className='header_div'>Extend Vendor Master</div>
        <br/>

        <Form style={{display:'flex', flexDirection:'column'}}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
                <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
                <select className='col-lg-3' name='vendorname' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
                <select className='col-lg-3' name='vendorid' value={this.state.formData.vendorid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_vendorid.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Type:</div>
                <select className='col-lg-3' name='vendortype' value={this.state.formData.vendortype} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_vendortype.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{display:'flex', justifyContent:'center'}}>
                <Button variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft: '10px'}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>

        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length >0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        {/* <th>Organization ID</th> */}
                        <th>Preferred Vendor ID</th>
                        <th>Vendor Name</th>
                        <th>Member Type</th>
                        <th>GST Treatment</th>
                        <th>Status</th>
                        <th>Vendor Group</th>
                        <th>Extend</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b)=>a['Vendor ID'].localeCompare(b['Vendor ID'])).map((item, index) => {
                        return (
                            <tr key={index}>
                                {/* <td>{item['Org ID']}</td> */}
                                <td><Button variant='link' onClick={() => this.handleScreenRouting(item)} size='sm'>{item['Preferences Vendor ID']}</Button></td>
                                <td>{item['Vendor Name']}</td>
                                <td>{item['Member Type']}</td>
                                <td>{item['GST Treatment']}</td>
                                <td>{item['Status']}</td>
                                <td>{item['Vendor Group']}</td>
                                <td><Button size='sm' onClick={() => this.handleExtend(item)} disabled={item['Preferences Vendor ID']} variant={item['Preferences Vendor ID'] ? 'warning' : 'success'}>{item['Preferences Vendor ID'] ? 'Already Extended' : 'Extend'}</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: null}

        {this.state.error ?
        <div className='center-screen'>
            {this.state.errorMessage}
        </div> :  null}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        userData: state.login.userData
    }
}

export default connect(mapStateToProps)(ExtendVendorMaster)

 

 