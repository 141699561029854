import React , { Component } from 'react';
import DatesTemplate from './../components/dates_template'
import { getRequest, checkValidDate, postRequest, getStandardDateTime } from '../globalhelper/helper';
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from 'react-bootstrap/ProgressBar';
import store from '../store';
import Modal from 'react-bootstrap/Modal'
import Button from '@material-ui/core/Button';
import { navUrl } from '../actions/common_actions'
import { connect } from 'react-redux';
import DataTableTemplate from "../components/datatable";
import Card from '@material-ui/core/Card';

// const state = store.getState();
// console.log(state, "cState");
// const userData = state.login.userData;
let interval;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
class SchGenerator extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            formData : {
                fromdate : new Date(new Date().setDate(new Date().getDate()-30)),
                todate : new Date()
            },
            data : [],
            processes : [
                {
                    process : 'Fixed Asset Addition',
                    processCode : 'Fixed_Asset_Addition',
                    totalCount : 0,
                    count : 0
                },
                {
                    process : 'Fixed Asset Deletion',
                    processCode : 'Fixed_Asset_Deletion',
                    totalCount : 0,
                    count : 0
                },
                {
                    process : 'Fixed Asset Transfer',
                    processCode : 'Fixed_Asset_Transfer',
                    totalCount : 0,
                    count : 0
                },
                {
                    process : 'Fixed Asset ReClassification',
                    processCode : 'Fixed_Asset_ReClassification',
                    totalCount : 0,
                    count : 0
                },
            ],
            buttonDisabled : true,
            showProcess : false,
            loading : false,
            buttonDisabled : true,
            processPending : false,
            pendingProcess : "",
        }

        this.userData = this.props.state.login.userData;
        this.tempCol = [
          {
            name: 'Transaction ID',
            selector: 'Transaction_ID',
            sortable: true,
            width : '150px',
            wrap : true,
          },
          {
            name: 'Process',
            selector: 'Process',
            sortable: true,
            width : '150px',
            wrap : true,
        },
            {
                name : "Stated",
                cell: (row) => {
                    return getStandardDateTime(row.Start);
                },
                wrap : true,
                width : '200px',
                button: true,
            },
            {
                name : "Ended",
                cell: (row) => {
                    return getStandardDateTime(row.End);
                },
                wrap : true,
                width : '200px',
                button: true,
            },
            {
                name : "Total Time (In Mins)",
                cell: (row) => {
                    return ((Number(new Date(row.End) - new Date(row.Start))/ 1000) / 60).toFixed(2);
                },
                wrap : true,
                width : '200px',
                button: true,
            },
          {
            name: 'Status',
            cell: (row) => { 

            return (
                <div>
                    <br/>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <Button variant="outlined" size="small" color={ row.Status === "Completed" ? "primary" : "secondary" }>{ row.Status }</Button>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Button variant="outlined" size="small" color="primary" >{ row.Success }</Button>
                        </div>
                        <div className="col-md-4">
                            <Button variant="outlined" size="small" color="secondary">{ row.Failure }</Button>
                        </div>
                        <div className="col-md-4">
                            <Button variant="outlined" size="small" color="default">{ row.Total }</Button>
                        </div>
                    </div>
                    <br/>
                </div>
            )},
            sortable: true,
            wrap : true,
            width : '350px',
            button: true,
          },
        ]
        this.columns = this.tempCol;
    }

    componentDidMount = () => {
        interval = setInterval(() => {
            this.validate();
        }, 1000);
        // getRequest('api/module/getProcesses?product=' + localStorage.getItem("module") + '&tenant_id=' + this.userData.tenant_id)
        // .then(result => {
        //     var processes = [];
        //     result.res.data.forEach(d => {
        //         var f = {
        //             process : d.name,
        //             c :  0
        //         }
        //         processes.push(f);
        //     })

        //     this.setState({
        //         ...this.state,
        //         processes : processes
        //     })
        // })
    }

    
    setDate = (date, field) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [field]: date
            }
        })
    }

    
    navPage = (url) => {
        this.props.navUrl(url)
      }

      getPreviousSchedules = () => {
          getRequest('api/fixedasset/getPreviousSchedules?tenant_id=' + this.userData.tenant_id)
          .then(result => {
              if(result.type === "success")
              {
                  this.setState({
                      ...this.state,
                      data : result.res.data
                  })
              }
          })
      }

    validate = () => {
        var processes = this.state.processes;

        if(processes.length > 0)
        {
            var d = processes.filter(process => process.count > 0);
            this.setState({
                ...this.state,
                buttonDisabled : d.length > 0 ? false : true
            })
        }
        else{
            this.setState({
                ...this.state,
                buttonDisabled : true
            })
        }
    }

    componentWillMount = () => {
        var tenant_id = localStorage.getItem("tenant_id");
        getRequest('api/fixedasset/checkPendingForSchGenerate?tenant_id=' + tenant_id)
        .then(result => {
            if(result.type === "success")
           {
            var process = result.res.data;
            var proName = "";
            var f = 0;
            process.forEach((pro,index) => {
                if(pro.count > 0)
                {
                    f++;
                    console.log(proName);
                    proName += index === 0 && proName === "" ? "" : " , " + pro.process;
                    console.log(proName);
                }
            })
            if(f > 0)
            {
                this.setState({
                    ...this.state,
                    processPending : true,
                    pendingProcess : proName
                })  
            }
        }
        })
        this.getPreviousSchedules()
    }

    componentWillUnmount() {
        clearInterval(interval);
      }

    handleSubmit = () => {
        var formData = this.state.formData;
        console.log(formData.fromdate, formData.todate);
        // var processes = this.state.processes;

        var sData = {
            fromDate : formData.fromdate,
            toDate : formData.todate,
            processes : this.state.processes
        }

        this.setState({ ...this.state, loading : true, showProcess :false })
        postRequest('api/fixedasset/getCounts',sData)
        .then(result => {
            if(result.type === "success")
            {
                this.setState({
                    ...this.state,
                    processes : result.res.data,
                    showProcess : true,
                    loading : false
                })
            }
        })

        // setInterval(() => {
        //     this.setState({
        //         ...this.state,
        //         showProcess : true
        //     })
        // },1000)
    }

    generateSch = () => {
        var formData = this.state.formData;
        console.log(formData.fromdate, formData.todate);
        formData.tenant_id = this.userData.tenant_id;
        postRequest('api/fixedasset/calculateSch',formData)
        .then(result => {
            if(result.type === "success")
            {
                window.location.reload()
            }
        })
    }

    render (){
        const { data } = this.state;
        return (
            <>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="fromdate">From</label> <br />
                        <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false} id="fromdate"
                            name="fromdate" maxDate={ this.state.formData.todate } dateFormat="dd-MM-yyyy"
                            className="col-md-12" onChange={ (date)=> this.setDate(date,'fromdate') } />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="todate">To</label> <br />
                        <DatePicker selected={this.state.formData.todate} showPopperArrow={false} id="todate"
                            name="todate" minDate={ this.state.formData.fromdate } className="col-md-12"
                            dateFormat="dd-MM-yyyy" onChange={ (date)=> this.setDate(date,'todate') } />
                    </div>
                    <div className="col-md-3 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" disabled={ this.state.processPending } onClick={ this.handleSubmit }> { this.state.processPending ? "Process Already Pending" : "Load" } </button>
                    </div>
                </div>
                <br/><br/>


                {this.state.showProcess ? 
                <>
                <h4> <u> Processes : </u> </h4>
                        <div className="row">
                            {   this.state.processes.map((process,index) => (
                                <div className="col-md-6" >
                                    <div className="row flex-nowrap process-card"  >
                                        <div className="col-md-6">
                                            <strong> { process.process.split("_").join(" ") }</strong>
                                        </div>
                                        <div className="col-md-1">
                                            <strong> : </strong>
                                        </div>
                                        <div className="col-md-5">
                                            {/* <strong> { process.count + " No's" } </strong> */}
                                            <ProgressBar >


                                            <ProgressBar animated className="highlight-bar" style={{ width : '100%' }} max={process.totalCount} striped label={process.count + ' out of ' + process.totalCount} variant={ process.count === 0 ? 'success' : 'danger' } now={process.totalCount} key={index} /> <br/>


                                            {/* <ProgressBar animated max={process.totalCount} label={ process.count} variant="danger" now={process.count} key={index} /> */}
                                            </ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            ))

                    }
                </div>
                <div className="row" >
                    <div className="col-md-11" align="right">
                        <button className="btn btn-teal" onClick={ this.generateSch } disabled={ this.state.buttonDisabled } > Generate </button>
                    </div>
                </div>
                    </>
                : 
                this.state.loading === true ?
                    
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                    <Spinner animation="border" variant="text-navy" />
                </div>
                : null
                }
                  {/* {this.state.processPending ? 
                    <Modal show={this.state.processPending} onHide={() => this.navPage('/sch_generator')}>
                        <Modal.Header closeButton>
                        <Modal.Title>Process Pending</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>A Process already Running. Please wait for the process to Complete!  </Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.navPage('/home')}>
                            Go To Home
                        </Button>
                        </Modal.Footer>
                    </Modal>
                :
                null
                } */}
                <Card style={{ height: '100%' }}>
                    <DataTableTemplate 
                    name="Previous Schedules"
                    columns={this.columns}
                    data={data}
                    defaultSortField='index'
                    />
                </Card>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps,
    { navUrl }
)(SchGenerator);
