// import React, { Component, Fragment } from 'react'
// import {Form, Button} from 'react-bootstrap';
// import {connect} from 'react-redux'
// import { getRequest } from '../../globalhelper/helper';
// import '../CI/COD.css'
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

// export class ItemMaster_Search extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//         showtable: false,
//          formData: {
//             preferredvendor: '',
//             itemname: '',
//             itemid: '',
//             status: ''
//          },
//          tableData: [],
//          dropdown_preferredvendor: [],
//          dropdown_itemname: [],
//          dropdown_itemid: [],
//          dropdown_status: []
//       }
//     }

//     componentDidMount(){

//         localStorage.setItem("pageName", "Search ItemMaster")

//         getRequest(`api/edureka/getIM1`).then(res => {
//             let temp = JSON.parse(res.res.data)

//             let temp1 = temp.map(value=> value['Preferred Vendor'])
//             let temp2 = temp1.filter(value=>value)

//             let temp3 = temp.map(value =>value['Item Name'])
//             let temp4 = temp3.filter(value=>value)
//             let tem = [... new Set(temp4)]

//             let temp7 = temp.map(value => value['Item ID'])
//             let temp8 = temp7.filter(value =>value)

//             this.setState({
//                 ...this.state,
//                 dropdown_preferredvendor: temp2,
//                 dropdown_itemname: tem,
//                 dropdown_itemid: temp8
//             })
//         })
//     }

//     handleChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         })
//     }

//     handleSearch = () => {
//        const itemname = this.state.formData.itemname
//        const itemid = this.state.formData.itemid
//        const status = this.state.formData.status

//        getRequest(`api/edureka/getIMSearch?itemname=${itemname}&itemid=${itemid}&status=${status}`).then(res => {
//         if(res.res.status === 'success'){
//             let temp = JSON.parse(res.res.data)
//             console.log(temp)

//             this.setState({
//                 ...this.state,
//                 tableData: temp,
//                 showtable: true
//             })
//         } else {
//             console.log('No Data')
//         }
//        })
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             formData: {
//                 preferredvendor: '',
//                 itemid: '',
//                 itemname: '',
//                 status: ''
//             }
//         })
//     }

//     handleItemMaster = (i) => {
//         console.log(i)
//         const itemid = i['Item ID']

//         getRequest(`api/edureka/getIMUpdatedJSON?itemid=${itemid}`).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({ redirect : true, SearchJSON : res.res.data })                
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"ItemMaster"}
//             screenAction = {"edit"}  
//             From_SearchJSON = {this.state.SearchJSON}   
//             onBack = {this.onBack}
//             />        
//         )
//     }
//     else
//     return (
//       <Fragment>
//         <div className='header_div'>Search Item Master</div>

//         <br/>

//         <Form style={{display:'flex', flexDirection:'column'}}>

//             {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Preferred Vendor:</div>
//                 <select className='col-lg-3' name='preferredvendor' value={this.state.formData.preferredvendor} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_preferredvendor.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div> */}

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item Name:</div>
//                 <select className='col-lg-3' name='itemname' value={this.state.formData.itemname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_itemname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item ID:</div>
//                 <select className='col-lg-3' name='itemid' value={this.state.formData.itemid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_itemid.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
//                 <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='Active'>Active</option>
//                     <option value='Inactive'>Inactive</option>
//                     <option value='Not Released'>Not Released</option>
//                     <option value='Rejected'>Rejected</option>
//                     <option value='Void'>Void</option>
//                     <option value='Waiting For Approval'>Waiting For Approval</option>
//                 </select>
//             </div>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='primary' onClick={this.handleSearch}>Search</Button>
//                 <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>

//         </Form>

//         <br/>

//         {this.state.showtable && this.state.tableData.length >0 ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>Item ID</th>
//                         <th>Item Name</th>
//                         <th>Rate</th>
//                         <th>Commodity Code</th>
//                         <th>Status</th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b) =>a['Item ID'].localeCompare(b['Item ID'])).map((item, index)=> {
//                         return (
//                             <tr key={index}>
//                                 <td><Button variant='link' onClick={() =>this.handleItemMaster(item)}>{item['Item ID']}</Button></td>
//                                 <td>{item['Item Name']}</td>
//                                 <td>{item.Rate}</td>
//                                 <td>{item['Commodity Code']}</td>
//                                 <td>{item['Status']}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}
//       </Fragment>
//     )
//   }
// }

// function mapStateToProps(state){
//     return {
//         userData: state.login.userData
//     }
// }

// export default connect(mapStateToProps)(ItemMaster_Search)



import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getRequest } from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import ReactSelect from 'react-select';

export class ItemMaster_Search extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        showtable: false,
         formData: {
            preferredvendor: '',
            itemname: '',
            itemid: '',
            status: ''
         },
         tableData: [],
         dropdown_preferredvendor: [],
         dropdown_itemname: [],
         dropdown_itemid: [],
         dropdown_status: []
      }
    }

    componentDidMount(){

        localStorage.setItem("pageName", "Search ItemMaster")

        getRequest(`api/edureka/getIM1`).then(res => {
            let temp = JSON.parse(res.res.data)

            let temp1 = temp.map(value=> value['Preferred Vendor'])
            let temp2 = temp1.filter(value=>value)

            let temp3 = temp.map(value =>value['Item Name'])
            let temp4 = temp3.filter(value=>value)
            let tem = [... new Set(temp4)]

            let temp7 = temp.map(value => value['Item ID'])
            let temp8 = temp7.filter(value =>value)

            this.setState({
                ...this.state,
                dropdown_preferredvendor: temp2,
                dropdown_itemname: tem,
                dropdown_itemid: temp8
            })
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleSearch = () => {
       const itemname = this.state.formData.itemname
       const itemid = this.state.formData.itemid
       const status = this.state.formData.status

       getRequest(`api/edureka/getIMSearch?itemname=${itemname}&itemid=${itemid}&status=${status}`).then(res => {
        if(res.res.status === 'success'){
            let temp = JSON.parse(res.res.data)
            console.log(temp)

            this.setState({
                ...this.state,
                tableData: temp,
                showtable: true
            })
        } else {
            console.log('No Data')
        }
       })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            formData: {
                preferredvendor: '',
                itemid: '',
                itemname: '',
                status: ''
            }
        })
    }

    handleItemMaster = (i) => {
        console.log(i)
        const itemid = i['Item ID']

        getRequest(`api/edureka/getIMUpdatedJSON?itemid=${itemid}`).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({ redirect : true, SearchJSON : res.res.data })                
            } else {
                console.log("No Data")
            }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"ItemMaster"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.SearchJSON}   
            onBack = {this.onBack}
            />        
        )
    }
    else
    return (
      <Fragment>
        <div className='header_div'>Search Item Master</div>

        <br/>

        <Form style={{display:'flex', flexDirection:'column'}}>

            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Preferred Vendor:</div>
                <select className='col-lg-3' name='preferredvendor' value={this.state.formData.preferredvendor} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_preferredvendor.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Item Name:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='itemname'
                    value={this.state.dropdown_itemname.find(option => option.value === this.state.formData.itemname)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'itemname', value: selectedOption.value } })}
                    options={this.state.dropdown_itemname.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Item ID:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='itemid'
                    value={this.state.dropdown_itemid.find(option => option.value === this.state.formData.itemid)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'itemid', value: selectedOption.value } })}
                    options={this.state.dropdown_itemid.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
                <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Not Released'>Not Released</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Void'>Void</option>
                    <option value='Waiting For Approval'>Waiting For Approval</option>
                </select>
            </div>

            <div style={{textAlign:'center'}}>
                <Button variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>

        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length >0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Item Name</th>
                        <th>Rate</th>
                        <th>Commodity Code</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b) =>a['Item ID'].localeCompare(b['Item ID'])).map((item, index)=> {
                        return (
                            <tr key={index}>
                                <td><Button variant='link' onClick={() =>this.handleItemMaster(item)}>{item['Item ID']}</Button></td>
                                <td>{item['Item Name']}</td>
                                <td>{item.Rate}</td>
                                <td>{item['Commodity Code']}</td>
                                <td>{item['Status']}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        userData: state.login.userData
    }
}

export default connect(mapStateToProps)(ItemMaster_Search)