// import { useEffect, useState } from "react"
// import React from 'react';
// import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
// import '../CI/COD.css';
// import {Button, Form} from 'react-bootstrap';
// import SouthIcon from '@mui/icons-material/South';
// import NorthIcon from '@mui/icons-material/North';

// const Input = ({label, type, name, value, onChange}) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
//             </Form.Group>
//         </div>
//     )
// }

// const Select = ({label, name, value, options, onChange}) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const SelectMonth = ({label, name, value, options, onChange}) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     {options.map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const SelectType = ({label, name, value, onChange}) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     <option value="CreditCard">Credit Card</option>
//                     <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
//                     <option value='EmployeeCost'>Employee Cost</option>
//                     <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
//                     <option value='EmployeeCostFnF'>Employee Cost FnF</option>
//                     <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
//                     <option value="EmployeeReimbursement">Employee Reimbursement</option>
//                     <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
//                     <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
//                     <option value='InstructorInvoice'>Instructor Invoice</option>
//                     <option value='Payment'>Payment</option>
//                     <option value='PettyCash'>Petty Cash</option>
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const BulkApprovals = () => {

//     const [formData, setFormData] = useState({
//         transtype: '',
//         fromDate: '',
//         toDate: '',
//         company: ''
//     })

//     const [companyName, setCompanyName] = useState([])

//     const [companyDetails, setCompanyDetails] = useState([])

//     const [loading, setLoading] = useState(false)

//     const [queryResult, setQueryResult] = useState([])

//     const [selectedItems, setSelectedItems] = useState([])

//     const [selectedRowData, setSelectedRowData] = useState([])

//     const [selectAll, setSelectAll] = useState(false)

//     const [activityCode, setActivityCode] = useState([])

//     const [showError, setShowError] = useState(false)

//     const [warningMessage, setWarningMessage] = useState('')

//     const [month, setMonth] = useState("")

//     const [year, setYear] = useState("")

//     const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

//     const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

//     const [showScrollButton, setShowScrollButton] = useState(false)

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)
//         }).catch(err => console.log(err))
//     }, [])

//     const handleMonthChange = (e) => {
//         setMonth(e.target.value)
//     }

//     const handleYearChange = (e) => {
//         setYear(e.target.value)
//     }

//     useEffect(() => {
//         const selectedYear = year
//         const selectedMonth = month 

//         const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
//         const startDate = new Date(selectedYear, monthIndex, 1)
//         const endDate = new Date(selectedYear, monthIndex + 1, 0)

//         console.log(getStandardDate(startDate), getStandardDate(endDate))

//         setFormData(prev => {
//             return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
//         })
//     }, [year, month])

//     const handleChange = (e) => {
//         const {name, value} = e.target
//         setFormData((prev) => {
//             return {...prev, [name]: value}
//         })
//     }

//     const handleSubmit = () => {

//         const id = companyDetails.filter(item => item.orgname === formData.company)

//         const companyid = id.map(item => item.orgid)

//         const item = {
//             forprdfrom: formData.fromDate,
//             forprdto: formData.toDate,
//             type: formData.transtype,
//             orgid: companyid[0]
//         }
//         setLoading(true)
//         setShowError(false)
//         postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
//             console.log(response.res.data)
//             const res = response.res.data
//             if(!res.length){
//                 console.log("Entered")
//                 setShowError(true)
//                 setWarningMessage("No data found, please try some other combination")
//             }
//             const val = response.res.data.map(item => item["Activity Code"])
//             console.log(val)
//             setLoading(false)

//             setQueryResult(response.res.data)
//             setActivityCode([...new Set(val)])
//         }).catch(err => {
//             setLoading(false)
//             console.log(err)
//         })
//     }

//     const handleSelectAll = () => {
//         if(selectAll){
//             console.log("SelectAll")
//             setSelectedItems([])
//             setSelectedRowData([])
//         } else {
//             const allIndices = Array.from({length: queryResult.length}, (_, i) => i)
//             setSelectedItems(allIndices)

//             const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
//             console.log(filteredColumns)

//             // const selectedRows = allIndices.map((index) => ({fileLocationWF: queryResult[index]["FileLocation"], fileLocationAD: queryResult[index]["AD OUTPUT FILE"]})).filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item.fileLocationWF))
//             const selectedRows = allIndices
//                 .map((index) => [
//                 queryResult[index]["FileLocation"],
//                 queryResult[index]["AD OUTPUT FILE"]
//                 ])
//                 .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
//                 .flat()
//             const deDuplicatedArray = [...new Set(selectedRows)]
//             console.log(deDuplicatedArray)
//             setSelectedItems(deDuplicatedArray)
//             setSelectedRowData(deDuplicatedArray)
//         }
//         setSelectAll(!selectAll)
//     }

//     const handleCheckboxChange = (index, adFileLocation) => {

//         const selected = [...selectedItems];

//         if (selected.includes(index) && selected.includes(adFileLocation)) {
//           // Remove items if they exist
//           const filteredSelected = selected.filter((item) => item !== index && item !== adFileLocation);
//           console.log(filteredSelected)
//           setSelectedItems(filteredSelected);
//           setSelectedRowData(filteredSelected);
//         } else {
//           // Add items if they don't exist
//           setSelectedItems([...selected, index, adFileLocation]);
//           setSelectedRowData([...selected, index, adFileLocation]);
//         }
//     };


//     const handleReset = () => {
//         setFormData({
//             transtype: '',
//             fromDate: '',
//             toDate: '',
//             company: ''
//         })
//         setSelectedItems([])
//         setSelectedRowData([])
//         setSelectAll(false)
//         setQueryResult([])
//         setLoading(false)
//         setWarningMessage('')
//         setShowError(false)
//         setYear("")
//         setMonth("")
//     }

//     const handleApproval = (status) => {
//         const requestParams = {
//             s3FilePaths: selectedRowData,
//             activityCode: activityCode,
//             status
//         }
//         console.log(requestParams)
//         postRequest(`api/edureka/bulkApprovalStatusUpdate`, requestParams).then(response => {
//             console.log(response.res.data)
//             const {transtype, fromDate, toDate} = formData

//             const id = companyDetails.filter(item => item.orgname === formData.company)

//             const companyid = id.map(item => item.orgid)

//             const item = {
//                 forprdfrom: fromDate,
//                 forprdto: toDate,
//                 type: transtype,
//                 orgid: companyid[0]
//             }
//             setLoading(true)
//             setShowError(false)
//             postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
//                 const finalResponse = response.res.data
//                 const val = response.res.data.map(item => item["Activity Code"])
//                 console.log(val)
//                 setLoading(false)           
//                 setQueryResult(finalResponse)
//                 setActivityCode([...new Set(val)])
//                 setSelectedItems([])
//                 setSelectedRowData([])
//             }).catch(err => {
//                 setLoading(false)
//                 console.log(err)
//             })

//         }).catch(err => console.log(err))
//     }

//     const handleScroll = () => {
//         // Check if the user is close to the bottom of the page
//         const isCloseToBottom =
//           window.innerHeight + window.scrollY >= document.body.offsetHeight

//         // Update the state to show or hide the scroll button
//         setShowScrollButton(isCloseToBottom);
//     };

//     useEffect(() => {
//         // Add event listener for scroll events
//         window.addEventListener('scroll', handleScroll);

//         // Clean up the event listener on component unmount
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const scrollToBottom = () => {
//         window.scrollTo({
//           top: document.body.scrollHeight,
//           behavior: 'smooth',
//         });
//       };

//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     };

//   return (
//     <>
//         {/* <div style={{alignItems:'left', fontSize:'large', fontWeight:'bolder', fontFamily:'sans-serif', marginBottom:'20px'}}>
//             Bulk Approvals
//         </div> */}

//         <form onSubmit={handleSubmit}>
//             <div className="row">
//                 <div className="col-md-2">
//                     <Form.Group>
//                         <Form.Label>Company</Form.Label>
//                         <select className="spacing" value={formData.company} name='company' onChange={handleChange}>
//                             <option value='' disabled>Select</option>
//                             {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div>

//                 <div className="col-md-2">
//                     <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
//                 </div>

//                 <div className="col-md-2">
//                     <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
//                 </div>

//                 <div className="col-md-2">
//                     <SelectType label="Type" name='transtype' value={formData.transtype} onChange={handleChange} />
//                 </div>
//             </div>

//             <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
//                 <Button size="sm" onClick={handleSubmit} variant="success">Submit</Button>
//                 <Button size="sm" onClick={handleReset} style={{marginLeft:'10px'}} variant="secondary">Reset</Button>
//                 {selectedRowData.length ?
//                 <Button size="sm" onClick={() =>handleApproval("Active")} style={{marginLeft:'10px'}} variant="warning" >Approve</Button> : null}

//                 {selectedRowData.length ?
//                 <Button size="sm" onClick={() => handleApproval("Rejected")} style={{marginLeft:'10px'}} variant="danger">Reject</Button> : null}
//             </div>
//         </form>

//         {!loading && queryResult.length ?
//         <div>
//             <table border='1' id="table-css">
//                 <thead>
//                     <tr>
//                         <th>
//                             <input
//                                 type="checkbox"
//                                 checked={selectAll}

//                                 onChange={handleSelectAll}
//                             />
//                         </th>
//                         <th>Accounting Journal</th>
//                         <th>Created ID</th>
//                         <th style={{maxWidth:'50px'}}>Accounting Date</th>
//                         <th style={{maxWidth:'50px'}}>Activity Status</th>
//                         <th>Ledger Name</th>
//                         <th style={{maxWidth:'50px'}}>Debit (INR)</th>
//                         <th style={{maxWidth:'50px'}}>Credit (INR)</th>
//                         <th>Activity Name</th>
//                         <th>Activity Code</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {queryResult.sort((a, b) => a["Created ID"].localeCompare(b["Created ID"])).map((item, index) => {
//                         return (
//                             <tr key={index}
//                             style={{
//                                 backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
//                               }}
//                             >
//                                 <td>
//                                     <input
//                                         type="checkbox"
//                                         checked={selectedItems.includes(item["FileLocation"])}
//                                         // onChange={() => handleCheckboxChange(item["FileLocation"])}
//                                         // disabled={item["Activity Status"] === 'Active'}
//                                         onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
//                                     />
//                                 </td>
//                                 <td>{item.accountingdocnumber}</td>
//                                 <td>{item["Created ID"]}</td>
//                                 <td style={{maxWidth:'40px'}}>{getHumanReadableDate(item["accountingdate"])}</td>
//                                 <td style={{maxWidth:'60px'}}>{item["Activity Status"]}</td>
//                                 <td>{item.ledgername}</td>
//                                 <td style={{textAlign:'right', maxWidth:'40px'}}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
//                                 <td style={{textAlign:'right', maxWidth:'40px'}}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
//                                 <td>{item["Activity Name"]}</td>
//                                 <td>{item["Activity Code"]}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}

//         <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
//             {showScrollButton ? (
//             <>
//             <Button size='sm' onClick={scrollToTop}><NorthIcon/></Button>
//             <br />
//             <br/>
//             <Button size='sm' onClick={scrollToBottom}><SouthIcon/></Button>
//             </>
//         ) : null}
//         </div>

//         {showError &&
//          <div className="center-screen">
//             {warningMessage}
//         </div>}

//         {loading ? <div className='center'>
//                 <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//             </div>: null}
//     </>
//   )
// }

// export default BulkApprovals


// import { useEffect, useState } from "react"
// import React from 'react';
// import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
// import '../CI/COD.css';
// import { Button, Form } from 'react-bootstrap';
// import SouthIcon from '@mui/icons-material/South';
// import NorthIcon from '@mui/icons-material/North';
// import { CSVLink } from "react-csv";
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import { connect } from "react-redux";
// import './Modal.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf, faTimes } from '@fortawesome/free-solid-svg-icons';

// const SelectType = ({ label, name, value, onChange }) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='BillExtract'>Bill</option>
//                     <option value="CreditCard">Credit Card</option>
//                     <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
//                     <option value='EmployeeCost'>Employee Cost</option>
//                     <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
//                     <option value='EmployeeCostFnF'>Employee Cost FnF</option>
//                     <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
//                     <option value="EmployeeReimbursement">Employee Reimbursement</option>
//                     <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
//                     <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
//                     <option value='InstructorInvoice'>Instructor Invoice</option>
//                     <option value='Payment'>Payment</option>
//                     <option value='PettyCash'>Petty Cash</option>
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const SelectStatus = ({ label, name, value, onChange }) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='Active'>Active</option>
//                     <option value="Waiting For Approval">Waiting For Approval</option>
//                     <option value="Rejected">Rejected</option>
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const BulkApprovals = (props) => {
//     const screenName = localStorage.getItem("pageName")
//     const [formData, setFormData] = useState({
//         transtype: '',
//         fromDate: '',
//         toDate: '',
//         company: '',
//         email: '',
//         transstatus: ''
//     })

//     const [companyName, setCompanyName] = useState([])
//     const [companyDetails, setCompanyDetails] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [queryResult, setQueryResult] = useState([])
//     const [qresult, setQResult] = useState([])
//     const [selectedItems, setSelectedItems] = useState([])
//     const [selectedRowData, setSelectedRowData] = useState([])
//     const [selectAll, setSelectAll] = useState(false)
//     const [activityCode, setActivityCode] = useState([])
//     const [showError, setShowError] = useState(false)
//     const [warningMessage, setWarningMessage] = useState('')
//     const [month, setMonth] = useState("")
//     const [year, setYear] = useState("")
//     const [showScrollButton, setShowScrollButton] = useState(false)
//     const [isBillType, setIsBillType] = useState(false);
//     const [selectedStatus, setSelectedStatus] = useState('');
//     const [redirectBill, setRedirectBill] = useState(false);
//     const [searchJSON, setSearchJSON] = useState(null);
//     const [UserInputdetails, setUserInputdetails] = useState({});
//     const [pdfUrl, setPdfUrl] = useState(null);
//     const [activePdf, setActivePdf] = useState(null);
//     const [isFormVisible, setIsFormVisible] = useState(true);


//     // useEffect(() => {
//     //     getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//     //         console.log(response.res.data)
//     //         const responseData = response.res.data
//     //         const orgname = responseData.map(item => item.orgname)
//     //         setCompanyDetails(responseData)
//     //         setCompanyName(orgname)
//     //     }).catch(err => console.log(err))
//     // }, [])

//     // const handleChange = (e) => {
//     //     const {name, value} = e.target
//     //     setFormData((prev) => {
//     //         return {...prev, [name]: value}
//     //     })
//     //     console.log("setisbilltype",value);
//     //     setIsBillType(value === 'BillExtract' ? true : false);
//     // }

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)

//             const companyid = responseData.map(item => item.orgid);
//             console.log("companyid", companyid);
//             const COD = companyid.sort()
//             console.log("COD", COD);
//             const CID = companyid[0]
//             console.log("CID", CID);

//             getRequest(`api/edureka/getUserInputFE?orgID=${CID}&page=${screenName}`).then(response => {
//                 console.log(response.res.data);
//                 const userdata = response.res.data.Item
//                 console.log("USERDTA", userdata);
//                 setUserInputdetails(userdata)
//                 setFormData({
//                     company: userdata.orgId[0] || '',
//                     fromDate: userdata.forprdfrom || '',
//                     toDate: userdata.forprdto || '',
//                     transtype: userdata.type || '',
//                     transstatus: userdata.transstatus || ''
//                 });
//             }).catch(err => console.log(err))
//         }).catch(err => console.log(err))
//     }, [])

//     useEffect(() => {
//         setIsBillType(formData.transtype === 'BillExtract' ? true : false);
//     }, [formData.transtype]);

//     const handleChange = (e) => {
//         const { name, value } = e.target
//         setFormData((prev) => {
//             return { ...prev, [name]: value }
//         })
//     }

//     const handleexChange = (e) => {
//         const { name, value } = e.target
//         setFormData((prev) => {
//             return { ...prev, [name]: value }
//         })
//     }

//     const getPdf = async (pdfloc) => {

//         const id = companyDetails.filter(item => item.orgname === formData.company);
//         const companyid = id.length > 0 ? id.map(item => item.orgid)[0] : UserInputdetails.orgId;
//         console.log("COMPANYID", companyid);

//         postRequest(`api/edureka/getThePdf?pdfloc=${pdfloc}&orgID=${companyid}`).then(response => {
//             console.log("R", response);
//             const bufferData = response.res.data.data;
//             console.log("BD", bufferData);
//             const byteArray = new Uint8Array(bufferData);
//             const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
//             const url = URL.createObjectURL(pdfBlob);
//             setPdfUrl(url);
//             setActivePdf(pdfloc);
//             setIsFormVisible(false);
//         }).catch(error => {
//             console.error('Error fetching PDF:', error);
//         });

//     };

//     const closeModal = () => {
//         setPdfUrl(null);
//         setActivePdf(null);
//     };

//     const handleSubmit = () => {
//         // console.log("COMPANY", formData.company);
//         // const id = UserInputdetails.orgId
//         // console.log("ID", id);  
//         const id = companyDetails.filter(item => item.orgname === formData.company);
//         const companyid = id.length > 0 ? id.map(item => item.orgid) : UserInputdetails.orgId;
//         console.log("COMPANYID", companyid);


//         const item = {
//             forprdfrom: formData.fromDate,
//             forprdto: formData.toDate,
//             type: formData.transtype,
//             orgid: companyid,
//             email: formData.email,
//             transstatus: formData.transstatus,
//             page: screenName
//         };

//         setLoading(true);
//         setShowError(false);

//         postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
//             console.log(response.res.data);
//             const res = response.res.data;
//             if (!res.length) {
//                 console.log("Entered");
//                 setShowError(true);
//                 setWarningMessage("No data found, please try some other combination");
//             }

//             // Extract bill numbers (createdid)
//             const billNumbers = res.map(item => item.createdid);
//             // console.log(billNumbers);
//             const field = formData.transstatus ? formData.transstatus : "Waiting For Approval"
//             console.log("FIELD", field);
//             // console.log(field)
//             const filteredData = res.filter(item => item.ActivityStatus === field)
//             // console.log(filteredData)
//             // setIsBillType(formData.transtype === 'BillExtract');
//             setQResult(filteredData);
//             setQueryResult(res); // Sort by date
//             setLoading(false);
//             setActivityCode([...new Set(billNumbers)]); // Assuming you want unique bill numbers
//         }).catch(err => {
//             setLoading(false);
//             console.log(err);
//         });

//     };

//     const handleSelectAll = () => {
//         // if (selectedStatus === '') {
//         //     alert('Cannot select items when transaction type is "All"')
//         //     // setShowError(true)
//         //     // setWarningMessage('Cannot select items when transaction type is "All"');
//         //     return;
//         // }
//         if (selectAll) {
//             // console.log("SelectAll")
//             setSelectedItems([])
//             setSelectedRowData([])
//         } else {
//             const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
//             setSelectedItems(allIndices)

//             const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
//             // console.log(filteredColumns)

//             // const selectedRows = allIndices.map((index) => ({fileLocationWF: queryResult[index]["FileLocation"], fileLocationAD: queryResult[index]["AD OUTPUT FILE"]})).filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item.fileLocationWF))
//             const selectedRows = allIndices
//                 .map((index) => [
//                     queryResult[index]["FileLocation"],
//                     queryResult[index]["AD OUTPUT FILE"]
//                 ])
//                 .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
//                 .flat()
//             const deDuplicatedArray = [...new Set(selectedRows)]
//             console.log(deDuplicatedArray)
//             setSelectedItems(deDuplicatedArray)
//             setSelectedRowData(deDuplicatedArray)
//         }
//         setSelectAll(!selectAll)
//     }

//     const handleCheckboxChange = (index, adFileLocation) => {
//         console.log(index, adFileLocation)
//         // if (selectedStatus === '') {
//         //     alert('Cannot select items when transaction type is "All"')
//         //     return;
//         // }
//         const selected = [...selectedItems];
//         if (selected.includes(index) && selected.includes(adFileLocation)) {
//             // Remove items if they exist
//             const filteredSelected = selected.filter((item) => item !== index && item !== adFileLocation);
//             //   console.log(filteredSelected)
//             setSelectedItems(filteredSelected);
//             setSelectedRowData(filteredSelected);
//         } else {
//             // Add items if they don't exist
//             setSelectedItems([...selected, index, adFileLocation]);
//             setSelectedRowData([...selected, index, adFileLocation]);
//         }
//         setWarningMessage('');
//     };

//     const handleReset = () => {
//         setFormData({
//             transtype: '',
//             fromDate: '',
//             toDate: '',
//             company: '',
//             email: '',
//             transstatus: ''
//         })
//         setSelectedItems([])
//         setSelectedRowData([])
//         setSelectAll(false)
//         setQueryResult([])
//         setLoading(false)
//         setWarningMessage('')
//         setShowError(false)
//         setYear("")
//         setMonth("")
//     }

//     const handleApproval = (status) => {
//         // const id = companyDetails.find(item => item.orgname === formData.company);
//         // const companyid = id.orgid;
//         const id = UserInputdetails.orgId
//         console.log("ID", id);

//         let selectedActivities = [];
//         let RowData = [];

//         if (formData.transtype === 'BillExtract') {
//             selectedActivities = queryResult
//                 .filter(item => selectedItems.includes(item.FileLocation))
//                 .map(item => status === "Approved" ? item["YTAActivityCode"] : item["AActivityCode"])
//                 .filter(value => value)
//                 .flatMap(value => value.split(','))
//                 .filter((value, index, self) => self.indexOf(value) === index);
//         } else {
//             selectedActivities = queryResult
//                 .filter(item => selectedItems.includes(item.FileLocation))
//                 .map(item => status === "Approved" ? item["YTAActivityCode"] : item["AActivityCode"])
//                 .filter(value => value)
//                 .flatMap(value => value.split(','))
//                 .filter((value, index, self) => self.indexOf(value) === index);
//         }

//         RowData = selectedRowData.flatMap(value => value.split(','));
//         console.log("LOCATION", RowData);

//         const requestParams = {
//             s3FilePaths: RowData,
//             activityCode: selectedActivities,
//             status,
//             orgId: id,
//             type: formData.transtype
//         };
//         console.log("REQPARAMS", requestParams);

//         setLoading(true);
//         setShowError(false);

//         postRequest(`api/edureka/bulkApprovalStatusUpdate`, requestParams)
//             .then(response => {
//                 console.log(response.res.data);

//                 const item = {
//                     forprdfrom: formData.fromDate,
//                     forprdto: formData.toDate,
//                     type: formData.transtype,
//                     orgid: id
//                 };

//                 postRequest(`api/edureka/getBulkApprovals`, item)
//                     .then(response => {
//                         const finalResponse = response.res.data;
//                         const val = response.res.data.map(item => item["AActivityCode"]);
//                         // console.log("AFTER APPROVAL", val);
//                         setLoading(false);
//                         setQueryResult(finalResponse.sort((a, b) => new Date(b.accountingdate) - new Date(a.accountingdate))) // Sort by date
//                         setActivityCode([...new Set(val)]);
//                         setSelectedItems([]);
//                         setSelectedRowData([]);
//                     })
//                     .catch(err => {
//                         setLoading(false);
//                         console.log(err);
//                     });

//             })
//             .catch(err => {
//                 setLoading(false);
//                 console.log(err);
//             });
//     };

//     const handleScroll = () => {
//         const isCloseToBottom =
//             window.innerHeight + window.scrollY >= document.body.offsetHeight
//         setShowScrollButton(isCloseToBottom);
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const scrollToBottom = () => {
//         window.scrollTo({
//             top: document.body.scrollHeight,
//             behavior: 'smooth',
//         });
//     };

//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     };

//     const handleBill = async (item) => {
//         try {
//             const id = companyDetails.filter(company => company.orgname === formData.company);
//             const companyid = id.length > 0 ? id.map(company => company.orgid) : UserInputdetails.orgId;
//             console.log("COMPANYID", companyid);
//             console.log(`key=${item["createdid"]}&companyid=${companyid}`);
            
//             const response = await getRequest(`api/edureka/getJSONViaKey1?key=${item["createdid"]}&companyid=${companyid}`);
            
//             if (response && response.res && response.res.data) {
//                 console.log(response.res.data);
//                 setSearchJSON(response.res.data);
//                 setRedirectBill(true);
//             } else {
//                 setRedirectBill(false);
//             }
//         } catch (error) {
//             console.error("Error fetching the data", error);
//             setRedirectBill(false); // Set to false if an error occurs
//         }
//     };
    

//     const toggleFormVisibility = () => {
//         setIsFormVisible(!isFormVisible);
//     };

//     const filteredResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : qresult
//     const filteredSecondTableResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : queryResult;

//     if (redirectBill) {
//         return (
//             <VR2_Forms
//                 screenName={"BillExtract"}
//                 screenAction={"edit"}
//                 From_SearchJSON={searchJSON}
//                 onBack={() => setRedirectBill(false)}
//             />
//         )
//     }

//     return (
//         <>
//              <div>
//             <Button onClick={toggleFormVisibility} variant="primary">
//                 {isFormVisible ? '<' : '>'}
//             </Button>
//             <div style={{
//                 maxHeight: isFormVisible ? '1000px' : '0',
//                 overflow: 'hidden',
//                 transition: 'max-height 0.5s ease-in-out',
//                 borderBottom: !isFormVisible ? '1px solid #ccc' : 'none',
//                 paddingBottom: !isFormVisible ? '10px' : '0'
//             }}>

//             {isFormVisible && (
//                 <form onSubmit={handleSubmit}>
//                     <div className="row">
//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>Company</Form.Label>
//                                 <select className="spacing" value={formData.company} name='company' onChange={handleChange}>
//                                     <option value='' disabled>Select</option>
//                                     {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                                 </select>
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>From Date</Form.Label>
//                                 <input
//                                     type="date" name="fromDate" value={formData.fromDate} onChange={handleChange} className="form-control"
//                                 />
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>To Date</Form.Label>
//                                 <input
//                                     type="date" name="toDate" value={formData.toDate} onChange={handleChange} className="form-control"
//                                 />
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <SelectType label="Type" name='transtype' value={formData.transtype} onChange={handleChange} />
//                         </div>
//                         {!loading && queryResult.length ?

//                             <div className="col-md-2">
//                                 <SelectStatus label="Transaction Status" name='transstatus' value={formData.transstatus} onChange={handleChange} />
//                             </div> : null}
//                     </div>

//                     <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//                         <Button size="sm" onClick={handleSubmit} variant="warning">Submit</Button>
//                         <Button size="sm" onClick={handleReset} style={{ marginLeft: '10px' }} variant="secondary">Reset</Button>
//                         {queryResult.length ? <CSVLink size="sm" className="btn btn-primary" style={{ marginLeft: '10px' }} filename="BulkApprovals.csv" data={filteredResults}>Download</CSVLink> : null}
//                         {selectedRowData.length && (selectedStatus === "Rejected" || "Waiting For Approval" || "Approved") ? (
//                             <Button size="sm" onClick={() => handleApproval("Approved")} style={{ marginLeft: '10px' }} variant="success">Approve</Button>
//                         ) : null}
//                         {selectedRowData.length && (selectedStatus === "Approved" || "Active") ? (
//                             <Button size="sm" onClick={() => handleApproval("Rejected")} style={{ marginLeft: '10px' }} variant="danger">Reject</Button>
//                         ) : null}
//                     </div>
//                 </form>
//             )}
//             </div>
//         </div>

//             {!loading && queryResult.length ?
//                 <div style={{ width: '100vw' }}>
//                     {isBillType ? (
//                         <div className={`contin ${pdfUrl ? 'split' : ''}`}>
//                             <div className="left-panel">
//                                 <table border='1' id="table-css">
//                                     <thead>
//                                         <tr>
//                                             <th>
//                                                 <input
//                                                     type="checkbox"
//                                                     checked={selectAll}
//                                                     onChange={handleSelectAll}
//                                                 />
//                                             </th>
//                                             {/* <th>Transaction Status</th> */}
//                                             <th>PDF</th>
//                                             <th>Status</th>
//                                             <th>Approved Activity Code</th>
//                                             <th>Accounting Date</th>
//                                             <th>Created Id</th>
//                                             <th>For Period From</th>
//                                             <th>For Period To</th>
//                                             <th>Account Code</th>
//                                             <th>Expense Ledgers</th>
//                                             <th>Prd Provisions</th>
//                                             <th>Prd Expense Booked</th>
//                                             <th>Prd PrePaid Expense Booked</th>
//                                             <th>WH Tax Classification</th>
//                                             <th>Prd WH Tax Amount</th>
//                                             <th>Input Tax Classification</th>
//                                             <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
//                                             <th>LOB Allocated Value</th>
//                                             <th>IGST Input</th>
//                                             <th>CGST Input</th>
//                                             <th>SGST Input</th>
//                                             <th>RCM Output Tax Classification</th>
//                                             <th>IGST Output</th>
//                                             <th>CGST Output</th>
//                                             <th>SGST Output</th>
//                                             <th>Prd Total Input Tax</th>
//                                             <th>Prd Total Output Tax</th>
//                                             <th>Prd Payable Amount</th>
//                                             <th>Item Type</th>
//                                             <th>Account Groups</th>
//                                             <th>MIS Group Names</th>
//                                             <th>Vendor ID</th>
//                                             <th>Vendor Name</th>
//                                             <th>Vendor Invoice List</th>
//                                             <th>Journals</th>

//                                             <th>Transaction Type</th>
//                                             <th>Yet To Approve Activity Code</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {filteredResults.map((item, index) => (
//                                             <tr key={index}
//                                                 style={{
//                                                     backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
//                                                 }}
//                                             >
//                                                 <td>
//                                                     <input
//                                                         type="checkbox"
//                                                         checked={selectedItems.includes(item["FileLocation"])}
//                                                         onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"], selectedStatus)}
                                                        
//                                                     />
//                                                 </td>
//                                                 <td>
//                                                     <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
//                                                         <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
//                                                     </a>
//                                                 </td>
//                                                 {/* <td>{item["ActivityStatus"]}</td> */}
//                                                 <td>{item["Status"]}</td>
//                                                 <td>{item["AActivityCode"]}</td>
//                                                 <td>{item.accountingdate}</td>
//                                                 <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td>
//                                                 {/* <td>{item["createdid"]}</td> */}
//                                                 <td>{item.ReportingPrdFrom}</td>
//                                                 <td>{item.ReportingPrdTo}</td>
//                                                 <td>{item.AccountCode}</td>
//                                                 <td>{item.ExpenseLedgers}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
//                                                 <td>{item.WHTaxClassification}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
//                                                 <td>{item.InputTaxClassification}</td>
//                                                 <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
//                                                 <td>{item.LobAllocValue}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
//                                                 <td>{item.RCMOutputTaxClassification}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
//                                                 <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
//                                                 <td>{item.ItemType}</td>
//                                                 <td>{item.AccountGroups}</td>
//                                                 <td>{item.MISGroupNames}</td>
//                                                 <td>{item.VendorID}</td>
//                                                 <td>{item.VendorNames}</td>
//                                                 <td>{item.VendorInvoiceList}</td>
//                                                 <td>{item.Journals}</td>

//                                                 <td>{item.transactiontype}</td>
//                                                 <td>{item["YTAActivityCode"]}</td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                             <div style={{ width: '50%' }}>
//                                 {pdfUrl && (
//                                     <div className="right-panel">
//                                         <button className="close-btn" onClick={closeModal}>
//                                             <FontAwesomeIcon icon={faTimes} />
//                                         </button>
//                                         <iframe src={pdfUrl} style={{ width: '100%', height: '100%' }} />
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     ) : (
//                         <table border='1' id="table-css">
//                             <thead>
//                                 <tr>
//                                     <th>
//                                         <input
//                                             type="checkbox"
//                                             checked={selectAll}
//                                             onChange={handleSelectAll}
//                                         />
//                                     </th>
//                                     <th>Status</th>
//                                     <th>Accounting Journal</th>
//                                     <th>Created ID</th>
//                                     <th style={{ maxWidth: '50px' }}>Accounting Date</th>
//                                     {/* <th style={{maxWidth:'50px'}}>Activity Status</th> */}
//                                     <th>Ledger Name</th>
//                                     <th style={{ maxWidth: '50px' }}>Debit (INR)</th>
//                                     <th style={{ maxWidth: '50px' }}>Credit (INR)</th>
//                                     <th>Activity Name</th>
//                                     <th>Approved Activity Code</th>
//                                     <th>Yet To Approve Activity Code</th>

//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {filteredSecondTableResults.map((item, index) => {
//                                     return (
//                                         <tr key={index}
//                                             style={{
//                                                 backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
//                                             }}
//                                         >
//                                             <td>
//                                                 <input
//                                                     type="checkbox"
//                                                     checked={selectedItems.includes(item["FileLocation"])}
//                                                     onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
//                                                 />
//                                             </td>
//                                             <td>{item["Status"]}</td>
//                                             <td>{item.accountingdocnumber}</td>
//                                             <td>{item["Created ID"]}</td>
//                                             <td style={{ maxWidth: '40px' }}>{getHumanReadableDate(item["accountingdate"])}</td>
//                                             {/* <td style={{maxWidth:'60px'}}>{item["Activity Status"]}</td> */}
//                                             <td>{item.ledgername}</td>
//                                             <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
//                                             <td>{item["Activity Name"]}</td>
//                                             <td>{item["AActivityCode"]}</td>
//                                             <td>{item["YTAActivityCode"]}</td>

//                                         </tr>
//                                     )
//                                 })}
//                             </tbody>
//                         </table>
//                     )}
//                 </div> : null}
//             <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
//                 {showScrollButton ? (
//                     <>
//                         <Button size='sm' onClick={scrollToTop}><NorthIcon /></Button>
//                         <br />
//                         <br />
//                         <Button size='sm' onClick={scrollToBottom}><SouthIcon /></Button>
//                     </>
//                 ) : null}
//             </div>
//             {showError &&
//                 <div className="center-screen">
//                     {warningMessage}
//                 </div>}
//             {loading ? <div className='center'>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//             </div> : null}
//         </>
//     )
// }
// export default BulkApprovals;

import { useEffect, useState } from "react"
import React from 'react';
import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
import '../CI/COD.css';
import { Button, Form, Modal } from 'react-bootstrap';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { CSVLink } from "react-csv";
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import { connect } from "react-redux";
import './Modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimes } from '@fortawesome/free-solid-svg-icons';

const SelectType = ({ label, name, value, onChange }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    <option value='BillExtract'>Bill</option>
                    <option value='GoodsAndServicesEntries'>GSE</option>
                    <option value="CreditCard">Credit Card</option>
                    <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
                    <option value='EmployeeCost'>Employee Cost</option>
                    <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
                    <option value='EmployeeCostFnF'>Employee Cost FnF</option>
                    <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
                    <option value="EmployeeReimbursement">Employee Reimbursement</option>
                    <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
                    <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
                    <option value='InstructorInvoice'>Instructor Invoice</option>
                    <option value='Payment'>Payment</option>
                    <option value='PettyCash'>Petty Cash</option>
                </select>
            </Form.Group>
        </div>
    )
}

const SelectStatus = ({ label, name, value, onChange }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    <option value='Active'>Active</option>
                    <option value="Waiting For Approval">Waiting For Approval</option>
                    <option value="Rejected">Rejected</option>
                </select>
            </Form.Group>
        </div>
    )
}

const BulkApprovals = (props) => {
    const screenName = localStorage.getItem("pageName")
    const [formData, setFormData] = useState({
        transtype: '',
        fromDate: '',
        toDate: '',
        company: '',
        email: '',
        transstatus: ''
    })

    const [companyName, setCompanyName] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [queryResult, setQueryResult] = useState([])
    const [qresult, setQResult] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedRowData, setSelectedRowData] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [activityCode, setActivityCode] = useState([])
    const [showError, setShowError] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [month, setMonth] = useState("")
    const [year, setYear] = useState("")
    const [showScrollButton, setShowScrollButton] = useState(false)
    const [isBillType, setIsBillType] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [redirectBill, setRedirectBill] = useState(false);
    const [searchJSON, setSearchJSON] = useState(null);
    const [UserInputdetails, setUserInputdetails] = useState({});
    const [pdfUrl, setPdfUrl] = useState(null);
    const [activePdf, setActivePdf] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [approvalStatus, setApprovalStatus] = useState('');


    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)

            const companyid = responseData.map(item => item.orgid);
            console.log("companyid", companyid);
            const COD = companyid.sort()
            console.log("COD", COD);
            const CID = companyid[0]
            console.log("CID", CID);

            getRequest(`api/edureka/getUserInputFE?orgID=${CID}&page=${screenName}`).then(response => {
                console.log(response.res.data);
                const userdata = response.res.data.Item
                console.log("USERDTA", userdata);
                setUserInputdetails(userdata)
                setFormData({
                    company: userdata.orgId[0] || '',
                    fromDate: userdata.forprdfrom || '',
                    toDate: userdata.forprdto || '',
                    transtype: userdata.type || '',
                    transstatus: userdata.transstatus || ''
                });
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setIsBillType(formData.transtype === 'BillExtract' ? true : false);
    }, [formData.transtype]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const handleexChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const getPdf = async (pdfloc) => {

        const id = companyDetails.filter(item => item.orgname === formData.company);
        const companyid = id.length > 0 ? id.map(item => item.orgid)[0] : UserInputdetails.orgId;
        console.log("COMPANYID", companyid);

        postRequest(`api/edureka/getThePdf?pdfloc=${pdfloc}&orgID=${companyid}`).then(response => {
            console.log("R", response);
            const bufferData = response.res.data.data;
            console.log("BD", bufferData);
            const byteArray = new Uint8Array(bufferData);
            const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);
            setActivePdf(pdfloc);
            setIsFormVisible(false);
        }).catch(error => {
            console.error('Error fetching PDF:', error);
        });

    };

    const closeModal = () => {
        setPdfUrl(null);
        setActivePdf(null);
    };

    const handleSubmit = () => { 
        const id = companyDetails.filter(item => item.orgname === formData.company);
        const companyid = id.length > 0 ? id.map(item => item.orgid) : UserInputdetails.orgId;
        console.log("COMPANYID", companyid);


        const item = {
            forprdfrom: formData.fromDate,
            forprdto: formData.toDate,
            type: formData.transtype,
            orgid: companyid,
            email: formData.email,
            transstatus: formData.transstatus,
            page: screenName
        };

        setLoading(true);
        setShowError(false);

        postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
            console.log(response.res.data);
            const res = response.res.data;
            if (!res.length) {
                console.log("Entered");
                setShowError(true);
                setWarningMessage("No data found, please try some other combination");
            }
            const billNumbers = res.map(item => item.createdid);
            const field = formData.transstatus ? formData.transstatus : "Waiting For Approval"
            console.log("FIELD", field);
            const filteredData = res.filter(item => item.ActivityStatus === field)
            setQResult(filteredData);
            setQueryResult(res);
            setLoading(false);
            setActivityCode([...new Set(billNumbers)]);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([])
            setSelectedRowData([])
        } else {
            if (formData.transtype === "GoodsAndServicesEntries" || formData.transtype === "BillExtract") {
                const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
                setSelectedItems(allIndices)
                const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
                const selectedRows = allIndices
                    .map((index) => [
                        queryResult[index]["createdid"]
                    ])
                    .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["createdid"] === item[0]))
                    .flat()
                const deDuplicatedArray = [...new Set(selectedRows)]
                console.log(deDuplicatedArray)
                setSelectedItems(deDuplicatedArray)
                setSelectedRowData(deDuplicatedArray)
            }else{
            const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
            setSelectedItems(allIndices)
            const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
            const selectedRows = allIndices
                .map((index) => [
                    queryResult[index]["FileLocation"],
                    queryResult[index]["AD OUTPUT FILE"]
                ])
                .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
                .flat()
            const deDuplicatedArray = [...new Set(selectedRows)]
            console.log(deDuplicatedArray)
            setSelectedItems(deDuplicatedArray)
            setSelectedRowData(deDuplicatedArray)
        }
        }
        setSelectAll(!selectAll)
    }

    const handleCheckboxChange = (cid, index, adFileLocation) => {
        if(formData.transtype==="BillExtract" || formData.transtype==="GoodsAndServicesEntries"){
            console.log(cid)
            const selected = [...selectedItems];
            if ( selected.includes(cid)) {
                const filteredSelected = selected.filter((item) => item !== cid);
                setSelectedItems(filteredSelected);
                setSelectedRowData(filteredSelected);
            } else {
                setSelectedItems([...selected,cid]);
                setSelectedRowData([...selected,cid]);
            }
            setWarningMessage('');
        }else{
            console.log(cid, index)
            const selected = [...selectedItems];
            if (selected.includes(cid) && selected.includes(index)) {
                const filteredSelected = selected.filter((item) => item !== cid && item !== index);
                setSelectedItems(filteredSelected);
                setSelectedRowData(filteredSelected);
            } else {
                setSelectedItems([...selected, cid, index]);
                setSelectedRowData([...selected, cid, index]);
            }
            setWarningMessage('');
        }

    };

    const handleReset = () => {
        setFormData({
            transtype: '',
            fromDate: '',
            toDate: '',
            company: '',
            email: '',
            transstatus: ''
        })
        setSelectedItems([])
        setSelectedRowData([])
        setSelectAll(false)
        setQueryResult([])
        setLoading(false)
        setWarningMessage('')
        setShowError(false)
        setYear("")
        setMonth("")
    }

    const handleApproval = (status) => {
        if (status === "Rejected") {
          setApprovalStatus(status);
          setShowModal(true);
        } else {
          processApproval(status, null);
        }
      };
    
      const processApproval = (status, reason) => {
        console.log("REJ");
        
        const id = UserInputdetails.orgId;
        console.log("ID", id);
    
        let selectedActivities = [];
        let RowData = [];
    
        if (formData.transtype === 'BillExtract' || formData.transtype === 'GoodsAndServicesEntries') {
          selectedActivities = queryResult
            .filter(item => selectedItems.includes(item["createdid"]))
            .map(item => status === "Approved" ? item["YTAActivityCode"] : item["YTAActivityCode"])
            .filter(value => value)
            .flatMap(value => value.split(','))
            .filter((value, index, self) => self.indexOf(value) === index);
        } else {
          selectedActivities = queryResult
            .filter(item => selectedItems.includes(item.FileLocation))
            .map(item => status === "Approved" ? item["YTAActivityCode"] : item["AActivityCode"])
            .filter(value => value)
            .flatMap(value => value.split(','))
            .filter((value, index, self) => self.indexOf(value) === index);
        }
    
        RowData = selectedRowData.flatMap(value => value.split(','));
        console.log("LOCATION", RowData);
    
        const requestParams = {
          s3FilePaths: RowData,
          activityCode: selectedActivities,
          status,
          orgId: id,
          type: formData.transtype,
          reason: reason // add the rejection reason here if available
        };
        console.log("REQPARAMS", requestParams);
    
        setLoading(true);
        setShowError(false);
    
        postRequest(`api/edureka/bulkApprovalStatusUpdate`, requestParams)
          .then(response => {
            console.log(response.res.data);
    
            const item = {
              forprdfrom: formData.fromDate,
              forprdto: formData.toDate,
              type: formData.transtype,
              orgid: id
            };
    
            postRequest(`api/edureka/getBulkApprovals`, item)
              .then(response => {
                const finalResponse = response.res.data;
                const val = response.res.data.map(item => item["AActivityCode"]);
                setLoading(false);
                setQueryResult(finalResponse.sort((a, b) => new Date(b.accountingdate) - new Date(a.accountingdate))) // Sort by date
                setActivityCode([...new Set(val)]);
                setSelectedItems([]);
                setSelectedRowData([]);
              })
              .catch(err => {
                setLoading(false);
                console.log(err);
              });
    
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
          });
      };

    const handleScroll = () => {
        const isCloseToBottom =
            window.innerHeight + window.scrollY >= document.body.offsetHeight
        setShowScrollButton(isCloseToBottom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleBill = async (item) => {
        try {
            const id = companyDetails.filter(company => company.orgname === formData.company);
            const companyid = id.length > 0 ? id.map(company => company.orgid) : UserInputdetails.orgId;
            console.log("COMPANYID", companyid);
            console.log(`key=${item["createdid"]}&companyid=${companyid}`);
            
            const response = await getRequest(`api/edureka/getJSONViaKey1?key=${item["createdid"]}&companyid=${companyid}`);
            
            if (response && response.res && response.res.data) {
                console.log(response.res.data);
                setSearchJSON(response.res.data);
                setRedirectBill(true);
            } else {
                setRedirectBill(false);
            }
        } catch (error) {
            console.error("Error fetching the data", error);
            setRedirectBill(false); // Set to false if an error occurs
        }
    };
    

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleModalSubmit = () => {
        processApproval(approvalStatus, rejectionReason);
        setShowModal(false);
        setRejectionReason(''); // clear rejection reason after use
      };

    const filteredResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : qresult
    const filteredSecondTableResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : queryResult;

    if (redirectBill) {
        return (
            <VR2_Forms
                screenName={"BillExtract"}
                screenAction={"edit"}
                From_SearchJSON={searchJSON}
                onBack={() => setRedirectBill(false)}
            />
        )
    }

    return (
        <>
             <div>
            <Button onClick={toggleFormVisibility} variant="primary">
                {isFormVisible ? '<' : '>'}
            </Button>
            <div style={{
                maxHeight: isFormVisible ? '1000px' : '0',
                overflow: 'hidden',
                transition: 'max-height 0.5s ease-in-out',
                borderBottom: !isFormVisible ? '1px solid #ccc' : 'none',
                paddingBottom: !isFormVisible ? '10px' : '0'
            }}>

            {isFormVisible && (
                <>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>Company</Form.Label>
                                <select className="spacing" value={formData.company} name='company' onChange={handleChange}>
                                    <option value='' disabled>Select</option>
                                    {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>From Date</Form.Label>
                                <input
                                    type="date" name="fromDate" value={formData.fromDate} onChange={handleChange} className="form-control"
                                />
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>To Date</Form.Label>
                                <input
                                    type="date" name="toDate" value={formData.toDate} onChange={handleChange} className="form-control"
                                />
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <SelectType label="Type" name='transtype' value={formData.transtype} onChange={handleChange} />
                        </div>
                        {!loading && queryResult.length ?

                            <div className="col-md-2">
                                <SelectStatus label="Transaction Status" name='transstatus' value={formData.transstatus} onChange={handleChange} />
                            </div> : null}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <Button size="sm" onClick={handleSubmit} variant="warning">Submit</Button>
                        <Button size="sm" onClick={handleReset} style={{ marginLeft: '10px' }} variant="secondary">Reset</Button>
                        {queryResult.length ? <CSVLink size="sm" className="btn btn-primary" style={{ marginLeft: '10px' }} filename="BulkApprovals.csv" data={filteredResults}>Download</CSVLink> : null}
                        {selectedRowData.length && (selectedStatus === "Rejected" || "Waiting For Approval" || "Approved") ? (
                            <Button size="sm" onClick={() => handleApproval("Approved")} style={{ marginLeft: '10px' }} variant="success">Approve</Button>
                        ) : null}
                        {selectedRowData.length && (selectedStatus === "Approved" || "Active") ? (
                            <Button size="sm" onClick={() => handleApproval("Rejected")} style={{ marginLeft: '10px' }} variant="danger">Reject</Button>
                        ) : null}
                    </div>
                </form>
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Reason for Rejection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please enter the reason for rejection:</Form.Label>
                        <Form.Control type="text" value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleModalSubmit}>Continue</Button>
                    </Modal.Footer>
                </Modal>
            </>
            )}
            </div>
        </div>

            {!loading && queryResult.length && formData.transtype === 'BillExtract' ? (
                <div style={{ width: '100vw' }}>
                    <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                        <div className="left-panel">
                        <table border='1' id="table-css">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                        </th>
                                        {/* <th>Transaction Status</th> */}
                                        <th>PDF</th>
                                        <th>Status</th>
                                        <th>Approved Activity Code</th>
                                        <th>Accounting Date</th>
                                        <th>Created Id</th>
                                        <th>For Period From</th>
                                        <th>For Period To</th>
                                        <th>Account Code</th>
                                        <th>Expense Ledgers</th>
                                        <th>Prd Provisions</th>
                                        <th>Prd Expense Booked</th>
                                        <th>Prd PrePaid Expense Booked</th>
                                        <th>WH Tax Classification</th>
                                        <th>Prd WH Tax Amount</th>
                                        <th>Input Tax Classification</th>
                                        <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
                                        <th>LOB Allocated Value</th>
                                        <th>IGST Input</th>
                                        <th>CGST Input</th>
                                        <th>SGST Input</th>
                                        <th>RCM Output Tax Classification</th>
                                        <th>IGST Output</th>
                                        <th>CGST Output</th>
                                        <th>SGST Output</th>
                                        <th>Prd Total Input Tax</th>
                                        <th>Prd Total Output Tax</th>
                                        <th>Prd Payable Amount</th>
                                        <th>Item Type</th>
                                        <th>Account Groups</th>
                                        <th>MIS Group Names</th>
                                        <th>Vendor ID</th>
                                        <th>Vendor Name</th>
                                        <th>Vendor Invoice List</th>
                                        <th>Journals</th>

                                        <th>Transaction Type</th>
                                        <th>Yet To Approve Activity Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredResults.map((item, index) => (
                                        <tr key={index}
                                            style={{
                                                backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(item["createdid"])}
                                                    onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                    
                                                />
                                            </td>
                                            <td>
                                                <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
                                                    <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
                                                </a>
                                            </td>
                                            {/* <td>{item["ActivityStatus"]}</td> */}
                                            <td>{item["Status"]}</td>
                                            <td>{item["AActivityCode"]}</td>
                                            <td>{item.accountingdate}</td>
                                            <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td>
                                            {/* <td>{item["createdid"]}</td> */}
                                            <td>{item.ReportingPrdFrom}</td>
                                            <td>{item.ReportingPrdTo}</td>
                                            <td>{item.AccountCode}</td>
                                            <td>{item.ExpenseLedgers}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
                                            <td>{item.WHTaxClassification}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
                                            <td>{item.InputTaxClassification}</td>
                                            <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
                                            <td>{item.LobAllocValue}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
                                            <td>{item.RCMOutputTaxClassification}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
                                            <td>{item.ItemType}</td>
                                            <td>{item.AccountGroups}</td>
                                            <td>{item.MISGroupNames}</td>
                                            <td>{item.VendorID}</td>
                                            <td>{item.VendorNames}</td>
                                            <td>{item.VendorInvoiceList}</td>
                                            <td>{item.Journals}</td>

                                            <td>{item.transactiontype}</td>
                                            <td>{item["YTAActivityCode"]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {pdfUrl && (
                            <div className="right-panel">
                                <button className="close-btn" onClick={closeModal}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                                <iframe src={pdfUrl} style={{ width: '100%', height: '100%' }} />
                            </div>
                        )}
                    </div>
                </div>
            ):null}
    
            {/* GSE Table */}
            {!loading && queryResult.length && formData.transtype === 'GoodsAndServicesEntries' ? (
                <div style={{ width: '100vw' }}>
                <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                    <div className="left-panel">
                    <table border='1' id="table-css">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    {/* <th>Transaction Status</th> */}
                                    {/* <th>PDF</th> */}
                                    <th>Status</th>
                                    <th>Approved Activity Code</th>
                                    <th>Accounting Date</th>
                                    <th>Created Id</th>
                                    <th>For Period From</th>
                                    <th>For Period To</th>
                                    <th>Account Code</th>
                                    <th>Expense Ledgers</th>
                                    <th>Prd Provisions</th>
                                    <th>Prd Expense Booked</th>
                                    <th>Prd PrePaid Expense Booked</th>
                                    <th>WH Tax Classification</th>
                                    <th>Prd WH Tax Amount</th>
                                    <th>Input Tax Classification</th>
                                    <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
                                    <th>LOB Allocated Value</th>
                                    <th>IGST Input</th>
                                    <th>CGST Input</th>
                                    <th>SGST Input</th>
                                    <th>RCM Output Tax Classification</th>
                                    <th>IGST Output</th>
                                    <th>CGST Output</th>
                                    <th>SGST Output</th>
                                    <th>Prd Total Input Tax</th>
                                    <th>Prd Total Output Tax</th>
                                    <th>Prd Payable Amount</th>
                                    <th>Item Type</th>
                                    <th>Account Groups</th>
                                    <th>MIS Group Names</th>
                                    <th>Vendor ID</th>
                                    <th>Vendor Name</th>
                                    <th>Vendor Invoice List</th>
                                    <th>Journals</th>

                                    <th>Transaction Type</th>
                                    <th>Yet To Approve Activity Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredResults.map((item, index) => (
                                    <tr key={index}
                                        style={{
                                            backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
                                        }}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(item["createdid"])}
                                                onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                
                                            />
                                        </td>
                                        {/* <td>
                                            <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
                                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
                                            </a>
                                        </td> */}
                                        {/* <td>{item["ActivityStatus"]}</td> */}
                                        <td>{item["Status"]}</td>
                                        <td>{item["AActivityCode"]}</td>
                                        <td>{item.accountingdate}</td>
                                        {/* <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td> */}
                                        <td>{item["createdid"]}</td>
                                        <td>{item.ReportingPrdFrom}</td>
                                        <td>{item.ReportingPrdTo}</td>
                                        <td>{item.AccountCode}</td>
                                        <td>{item.ExpenseLedgers}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
                                        <td>{item.WHTaxClassification}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
                                        <td>{item.InputTaxClassification}</td>
                                        <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
                                        <td>{item.LobAllocValue}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
                                        <td>{item.RCMOutputTaxClassification}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
                                        <td>{item.ItemType}</td>
                                        <td>{item.AccountGroups}</td>
                                        <td>{item.MISGroupNames}</td>
                                        <td>{item.VendorID}</td>
                                        <td>{item.VendorNames}</td>
                                        <td>{item.VendorInvoiceList}</td>
                                        <td>{item.Journals}</td>

                                        <td>{item.transactiontype}</td>
                                        <td>{item["YTAActivityCode"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            ):null}
    
            {/* Third Table (For Other Transaction Types) */}
            {!loading && queryResult.length && formData.transtype !== 'BillExtract' && formData.transtype !== 'GoodsAndServicesEntries' ? (
                <div style={{ width: '100vw' }}>
                    <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                        <div className="left-panel">
                        <table border='1' id="table-css">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Status</th>
                                    <th>Accounting Journal</th>
                                    <th>Created ID</th>
                                    <th style={{ maxWidth: '50px' }}>Accounting Date</th>
                                    {/* <th style={{maxWidth:'50px'}}>Activity Status</th> */}
                                    <th>Ledger Name</th>
                                    <th style={{ maxWidth: '50px' }}>Debit (INR)</th>
                                    <th style={{ maxWidth: '50px' }}>Credit (INR)</th>
                                    <th>Activity Name</th>
                                    <th>Approved Activity Code</th>
                                    <th>Yet To Approve Activity Code</th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredSecondTableResults.map((item, index) => {
                                    return (
                                        <tr key={index}
                                            style={{
                                                backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(item["FileLocation"])}
                                                    onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
                                                />
                                            </td>
                                            <td>{item["Status"]}</td>
                                            <td>{item.accountingdocnumber}</td>
                                            <td>{item["Created ID"]}</td>
                                            <td style={{ maxWidth: '40px' }}>{getHumanReadableDate(item["accountingdate"])}</td>
                                            {/* <td style={{maxWidth:'60px'}}>{item["Activity Status"]}</td> */}
                                            <td>{item.ledgername}</td>
                                            <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
                                            <td>{item["Activity Name"]}</td>
                                            <td>{item["AActivityCode"]}</td>
                                            <td>{item["YTAActivityCode"]}</td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            ):null}
            <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
                {showScrollButton ? (
                    <>
                        <Button size='sm' onClick={scrollToTop}><NorthIcon /></Button>
                        <br />
                        <br />
                        <Button size='sm' onClick={scrollToBottom}><SouthIcon /></Button>
                    </>
                ) : null}
            </div>
            {showError &&
                <div className="center-screen">
                    {warningMessage}
                </div>}
            {loading ? <div className='center'>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
            </div> : null}
        </>
    )
}
export default BulkApprovals;