import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './account_reports.css';
import '../../../App.css';
import {
  Redirect
} from "react-router-dom";
import { getRequest } from '../../../globalhelper/helper'
// import store from '../../../store';
// import { Button } from 'bootstrap';

import Iframe from 'react-iframe';

// const state = store.getState();
// const userData = state.login.userData;
class AttdProdReports extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            embed_url : '',
            show_dashboard_button : false,
        };
    }
   
    componentDidMount = () => {
        // getRequest('api/transactions/getDashboard?dashboard_id=' + this.props.dashboard_id).then(response => {
        const DASHBOARD_ID = localStorage.getItem("dashboard_id")
        getRequest('api/transactions/getDashboard?dashboard_id=' + DASHBOARD_ID).then(response => {
            if(response.res.status === "info")
            {
                // window.open(response.res.data.EmbedUrl, "_blank")
                this.setState({
                    show_dashboard_button : true,
                    embed_url : response.res.data.EmbedUrl
                })
            }
        })
    }

    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div>
                {!this.state.show_dashboard_button ?
                   <h5> Please wait while preparing your report</h5>
                   :
                   <div>
                    <a href={this.state.embed_url} target="_blank" rel="noreferrer" className="btn btn-primary" >Open Dashboard in New Tab</a>
                    <br/>
                    <br/>
                    
                    <Iframe url={ this.state.embed_url }
                        id="myId"
                        className="iFrame"
                        display="initial"
                        position="relative"/>
                    {/* <Button onClick={() => { window.location.reload() }} >Reload Report</Button> */}
                </div>
                    }
            </div>
        );
    }
}

export default AttdProdReports;
