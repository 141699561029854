import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper'
import DataTable from '../../components/DataTable2';



export class Cost_Allocation_Report extends Component {

    constructor(props) {
      super(props)

      this.state = {
        info : {
            VendorName : '',
            VendorID : '',
            Department : '',
            LOB : '',
            Status : '',
        },
        CSV_columns : [{
            name: 'Bill Number',
            selector: row => row['Bill Number'],
            sortable: true,
            },
            {
            name: 'Cost Allocation Method',
            selector: row => row['Cost Allocation Method'],
            sortable: true,
            },
            {
            name: 'Department',
            selector: row => row['Department'],
            sortable: true,
            },
            {
            name: 'ForPrd From',
            selector: row => row['ForPrd From'],
            sortable: true,
            },
            {
            name: 'ForPrd To',
            selector: row => row['ForPrd To'],
            sortable: true,
            },
            {
            name: 'Item ID',
            selector: row => row['Item ID'],
            sortable: true,
            },
            {
            name: 'LOB',
            selector: row => row['LOB'],
            sortable: true,
            },
            {
            name: 'Vendor ID',
            selector: row => row['Vendor ID'],
            sortable: true,
            },
            {
            name: 'Vendor Name',
            selector: row => row['Vendor Name'],
            sortable: true,
            },
            {
            name: 'Transaction Status',
            selector: row => row['Transaction Status'],
            sortable: true,
            },
            {
            name: 'Cost Allocation Parameter Value',
            selector: row => row['Cost Allocation Parameter Value'],
            sortable: true,
            },
            {
            name: 'Cost Allocation Value',
            selector: row => row['Cost Allocation Value'],
            sortable: true,
            },
            {
            name: 'transactiontype',
            selector: row => row['transactiontype'],
            sortable: true,
            }
        ],
        CSV_ARRAY : [],
        isLoading : false
      }
    }


    componentDidMount(){
    }


    handleChange = (e) => {
        let id = e.target.name
        let value = e.target.value

        console.log(id, value)

        this.setState({
            ...this.state,
            info: {
                ...this.state.info,
                [id]: value
            }
        })
    }

    
    getReport = (e) => {
        e.preventDefault()

        this.setState({ isLoading : true }, () => {

            postRequest('api/edureka/get_Cost_Allocation_Report', this.state.info)
            .then( res => {
                if( res.res.status === "success"){
    
                    let DATA = JSON.parse(res.res.data)
    
                    console.log(DATA)
    
                    this.setState({ CSV_ARRAY : DATA })
                    
                }

                this.setState({ isLoading : false})
            })

        })

    }


  render() {
    return (
    <div>
        <div className='row'>

            <div className='col-md-3'>
                <label htmlFor='VendorName'>Vendor Name:</label>
                <input id='VendorName' name='VendorName' type='text' onChange={this.handleChange} />
            </div>
            <div className='col-md-3'>
                <label htmlFor='VendorID'>Vendor ID:</label>
                <input id='VendorID' name='VendorID' type='text' onChange={this.handleChange} />
            </div>
            <div className='col-md-3'>
                <label htmlFor='Department'>Department:</label>               
                <select id='Department' name='Department' onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value='Content'>Content</option>
                    <option value='Sales'>Sales</option>
                    <option value='Marketing'>Marketing</option>
                    <option value='Technology'>Technology</option>
                    <option value='Product'>Product</option>
                    <option value='Admin'>Admin</option>
                    <option value='Finance'>Finance</option>
                    <option value='HR'>HR</option>
                </select>
            </div>
            <div className='col-md-3'>
                <label htmlFor='LOB'>LOB:</label>
                <select id='LOB' name='LOB' onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value='HED'>HED</option>
                    <option value='B2C'>B2C</option>
                    <option value='PGP'>PGP</option>
                    <option value='B2B'>B2B</option>
                    <option value='ELC'>ELC</option>
                    <option value='VLAB'>VLAB</option>
                </select>
            </div>
            <div className='col-md-3'>
                <label htmlFor='status'>Status:</label>
                <select id='status' name='status' onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Not Released'>Not Released</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Void'>Void</option>
                    <option value='Waiting For Approval'>Waiting For Approval</option>
                </select>
            </div>

            <div className='col-md-3'>
                <button className='btn btn-info' onClick={this.getReport}>Get Report</button>
            </div>

        </div>
        <div>

            <Fragment>
                <DataTable title="Cost Allocation Report" loading={this.state.isLoading} columns={this.state.CSV_columns} key={this.state.CSV_ARRAY} data={this.state.CSV_ARRAY} />
            </Fragment> 

        </div>
    </div>
    )
  }
}

export default Cost_Allocation_Report;