import React, { PureComponent, Fragment } from 'react';
import './revenue_tariff.css';
import { connect } from 'react-redux';
import { fetchData, fetchSavedTreeData } from '../../actions/treeAction'
import {  getRequest, getStandardDate, postRequest } from '../../globalhelper/helper'
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import {
  Redirect
} from "react-router-dom";
import DisplayContainer from "../displayContainer"


class RateCard extends PureComponent {
    
    constructor(props) {
        super(props);
        this.currentState = this.props.state;
        this.userData = this.currentState.login.userData;
        this.state = {
            selectedObj : [],
            rateCards : [],
            show_details : false,
            fetchingData : true,
            count : 1,
            pagename : localStorage.getItem("pagename"),
            formData : {
                fromdate : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                todate : new Date(new Date().getFullYear(), new Date().getMonth() , 0)
            }
        };
        this.previousLoadLength = 0;
        // this.from_date = this.props.match.params.from_date;
        this.from_date = this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom ? this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom : new Date();
        // this.to_date = this.props.match.params.to_date;
        this.to_date = this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo ? this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo : new Date();
    }

    getRTItems = () => {
        this.setState({
            rateCards : [],
            fetchingData : true,
            show_details : false,
            selectedObj : []
        }, () => {
            const FROM_DATE = getStandardDate(this.state.formData.fromdate)
            // const TO_DATE = getStandardDate(this.state.formData.todate)
            var API = localStorage.getItem('pagename') === "RTScreen" ? 'api/transactions/getAllRevenueTariff?tenant_id=AB00003&process_name=Revenue_Tariff&from_date=' + FROM_DATE : 'api/transactions/getAllRevenueTariffSlab?tenant_id=AB00003&process_name=Revenue_Tariff_SubComp&from_date=' + FROM_DATE;
            getRequest(API).then(response => {
                // console.log(response);
                if(response.type === "success")
                {
                    var data = response.res.data;
                    this.setState({
                        ...this.state,
                        fetchingData : false,
                        show_details : true,
                        selectedObj : data,
                        count : 3
                    })
                }
            })

        })

    }

    // handleSubmit = (event) => {
    //     event.preventDefault();
    //     this.
    // }

    componentDidMount = () => {
        console.log(localStorage.getItem("pagename"))

        this.setState({
            ...this.state, 
            formData : {
                fromdate : this.from_date ? new Date(this.from_date) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                todate : this.to_date ? new Date(this.to_date) : new Date(new Date().getFullYear(), new Date().getMonth() , 0)
            }
        }, () => {
            this.getRTItems();
        })

    }

    // shouldComponentUpdate = (nextProps, nextState) => {
    //     return this.state.pagename !== nextState.pagename;
    // }


    handleChange = (event) => {
        this.setState({ leaseSelected : false, 
            show_details : false,
            });
        var id = [event.target.id];
        this.setState({
            formData: {
                  ...this.state.formData,
                  [id]: event.target.value
            }
        })
        
    }

    detectBottomOfDiv = () => {
        var element = document.getElementById("scroll-div")
            if (element.scrollHeight <= element.scrollTop + 1000 && !this.state.fetchingData) {

                this.setState({
                    ...this.state,
                    fetchingData : true
                })


                let formData = this.state.formData;
                formData.tenantId = this.userData.tenant_id;
                formData.org = this.userData.organization;
               console.log("you're at the bottom of the page");
               getRequest('api/fixedasset/getAllAssets?tenant_id=' + this.userData.tenant_id + "&status="+ formData.asset_status + "&external_id="+ formData.asset_id + '&length=' + this.previousLoadLength)
               .then(response => {
                   if(response.type === "success")
                   {
                       this.previousLoadLength += response.res.data.length;
                       this.setState(prevState => {
                           var items = prevState.items;
                           items.push(...response.res.data)
                        return { items };
                       },() => {
                           this.setState({
                               ...this.state,
                               fetchingData : false
                           })
                       })
                   }
               })
            }
    }


    getRevenueTariffItem = (rc) => {
        console.log(rc, "selected Obj")
        rc.type = "live_rendering"
        this.setState({
            ...this.state,
            selectedObj: rc,
            show_details : true
        })

        this.props.fetchData(rc, localStorage.getItem("module"))
    }

    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date
        }
        })
    }

    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <Fragment key={localStorage.getItem("pagename")}>
                 <div className="row">
                        <div className='col-md-3'>
                            <label>Effective From</label> <br/>
                            <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                        </div>
                        {/* <div className='col-md-3'>
                            <label>To</label><br/>
                            <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                        </div> */}
                        
                        <div className="col-md-3 text-center"  style={{ marginTop : "25px" }}>
                            <button className="btn btn-teal" onClick={ this.getRTItems }> Load </button>
                        </div>
                    </div>
                    <br />
                    <br />
            <div className="row" >
                <div className="col-md-10" >
                    <Fragment >
                        { this.state.show_details ?
                            // <DisplayContainer data={ this.state.selectedObj } title={ localStorage.getItem('pagename') === "RTScreen" ? "OneCommerce" : "RTS_Header"} />CIRC_Header
                            <DisplayContainer data={ this.state.selectedObj } title={ localStorage.getItem('pagename') === "RTScreen" ? "OneCommerce" : "CIRC_Header"} />
                            : 
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                                <h6>Receiving Data... </h6> 
                                <Spinner animation="grow" variant="text-navy"  /> 
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps,
    { fetchData, fetchSavedTreeData }
)(RateCard);
