import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';

import { getRequest, postRequest } from '../../globalhelper/helper'
import Display from './Display'
import { updateTreeData, flushCurrentNode, currentNodeData, copySetOfNode } from '../../actions/tree_Action';

import SortableTree, {changeNodeAtPath, addNodeUnderParent, removeNodeAtPath, getFlatDataFromTree, map} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

import 'react-sortable-tree/style.css'; 
import { MdDeleteForever } from 'react-icons/md';
import { FaAngleRight, FaChevronCircleRight } from 'react-icons/fa';
import  ConfirmDialog, { confirmDialog } from '../../components/dialog';

import DataTable from '../../components/DataTable2';
import './tree.css'

import {Tooltip} from '@mui/material'





class Tree_schema extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        nodeLocation: null,
        schemaNamesList: [],
        groupName: '',
        showFinalResult : false,
        OUTPUT_NOTIN : [],
        schemaOutput: {
          schemaname: '',
          fetching: false,
          columns : false,
          data: []
        },
      }
    }

    componentDidMount() {       
        this.props.updateTreeData(this.props.Schema_TREE.length ? this.props.Schema_TREE : false)
        this.props.flushCurrentNode()
        this.props.copySetOfNode(null)
        this.get_OUTPUT_NOTIN()
        console.log('from componentDidMount tree_schemn')
        // this.updateTreeToS3(this.props.tree_Data)
      
    }

    get_OUTPUT_NOTIN = () => {

      getRequest(`api/upload/OUTPUT_NOTIN`)
      .then( res => {

        if(res.res.status === "success" && res.res.data.length){

          let OUTPUT_NOTIN = res.res.data[0].Values
  
          console.log(OUTPUT_NOTIN)
  
          this.setState({ OUTPUT_NOTIN : OUTPUT_NOTIN})
        }

      })

    }

    updateTreeToS3 = (tree) => {

      postRequest(`api/upload/updateSchemaTreeToS3?schemaCode=${this.props.schemaCode}`, { data : JSON.stringify(tree) } )
      .then( res => {

        console.log(res)
      })
    }

    handleTreeChange_in_Tree = (tree) => {
        this.props.updateTreeData(tree) 
        this.props.flushCurrentNode()   
        // this.updateTreeToS3(tree)
    }

    handleChangeTreeOnMove = () => {
      //   this.props.flushCurrentNode()     
      }

    handleTreeChange = (tree) => {
        this.props.updateTreeData(tree)    
    }

    deleteCurrentNode = (data)=> {

        let { node, path, treeIndex } = data
        confirmDialog(`Are you sure you want to delete "${node.technicalName }" node ?`,
        // yes
        () => {

            if(this.props.current_NodeData && node.technicalName === this.props.current_NodeData.node.technicalName){
              this.props.flushCurrentNode()
            }
      
            console.log(node, this.props.current_NodeData)
      
            let TreeData = removeNodeAtPath({
                   treeData: this.props.tree_Data,
                   path,
                   getNodeKey: ({ treeIndex }) => treeIndex,
                //    ignoreCollapsed : true
               })
        
           this.handleTreeChange(TreeData)    
        },
        // no
        () => {  }
        )
    }


    modifyNodeAtPath = (data) => {
        let { node, path, treeIndex } = data

        console.log(data)
        
         let newTreeData = changeNodeAtPath({
                treeData: this.props.tree_Data,
                path,
                newNode: node,
                getNodeKey: ({ treeIndex }) => treeIndex,
                // ignoreCollapsed : true
            })

        this.handleTreeChange(newTreeData)     
    }


    displayCurrentNode = (data) => {
      console.log(data)
    
      this.pathLocation(data.path)

      this.props.currentNodeData(data)
    }

    pathLocation = (input) => {
        let path = input
        
        let flatData = getFlatDataFromTree({
            treeData: this.props.tree_Data,
            getNodeKey: ({ treeIndex }) => treeIndex
        });
        
        let location = []

        path.forEach( i => location.push(flatData[i].node.technicalName) )
    
        this.setState({ nodeLocation : location})  
    }
  

    addingNodeUnderParent = ( newNode , node) => {

      let newTree = addNodeUnderParent({
        treeData : this.props.tree_Data,
        newNode : newNode,
        expandParent : true,
        parentKey : node.treeIndex,
        getNodeKey: ({ treeIndex }) => treeIndex,
        // ignoreCollapsed : true,
      })

      this.handleTreeChange(newTree.treeData)
    }

    handleTreeOnDrop = (node )=> {

      // let the user drap and drop only within the same parent, not letting the user to drag and drop the node under another parent      
      if(node && node.prevParent && node.nextParent && node.nextPath && node.prevPath){

        if(node.nextParent.technicalName === node.prevParent.technicalName && node.nextPath.length === node.prevPath.length){
          if( node.nextPath.length > 1 && node.nextPath[node.nextPath.length-2] === node.prevPath[node.prevPath.length-2]){
            return true
          }
          else if(node.nextPath.length === 1 && node.prevPath.length === 1 ){
            return true
          }
          else {
            return false
          }
        }
        else{
          return false
        }
      }
      else if(node.nextPath && node.prevPath && node.nextPath.length === 1 && node.prevPath.length === 1 ){
        return true
      }
      else{
        return false
      }

    }

    // function toggleExpandedForAll({ treeData, expanded = true }) {
    //   return map({
    //     treeData,
    //     callback: ({ node }) => ({ ...node, expanded }),
    //     getNodeKey: ({ treeIndex }) => treeIndex,
    //     ignoreCollapsed: false,
    //   });
    // }

    saveTreeToS3 = () => {   
      
      let newTreeData = map({
        treeData : this.props.tree_Data,
        callback: ({ node }) => {
          delete node.expanded
          if( node.children && !node.children.length) delete node.children
          if( node.children && node.children.length) delete node.val
          return node
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false,
      });

      console.log(newTreeData)

      // this.handleTreeChange(newTreeData)

      this.updateTreeToS3(newTreeData)

    }

    ActivateSchema = (activate) => {

      let schemaNamesList = []
      let groupName = ''

      let newTreeData = map({
        treeData : this.props.tree_Data,
        callback: ({ node }) => {
          if( node.title === "SchemaName") schemaNamesList.push(node.val)
          if( node.title === "SchemaGroupName") groupName = node.val
          return node
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false,
      });

      console.log(schemaNamesList)
      console.log(groupName)
      
      this.setState({ schemaNamesList : schemaNamesList, groupName : groupName}, 
        () => {

          if(activate){

            getRequest(`api/upload/activateSchemaLambda?schemaCode=${this.props.schemaCode}&module=${localStorage.getItem("module")}`)
            .then( res => {
      
              console.log(res)
              console.log(res.res.data)
      
              if(res.res.status === "success"){
      
                this.setState({showFinalResult : true})
                
              }
              else{
                
                this.setState({showFinalResult : false})
              }
              
            })

          }
          else{

            this.setState({showFinalResult : true})
          }

        })

    }

    resultViewOnChange_ = (e) => {

      const val = e.target.value
      console.log(val)

      this.setState({
        ...this.state,
        schemaOutput: {
          ...this.state.schemaOutput,
          columns : false,
          // fetching: true,
          // schemaname: val
        }
      }, () => {

        getRequest(`api/upload/getSchemaNameWiseResult?schemaName=${val}&limit=1`)
        .then( res => {
          console.log(res)
  
          if(res.res.status === "success"){

            let DATA = JSON.parse(res.res.data)
            let columns = []

            let keys = Object.keys( DATA.length ? DATA[0] : {})
            console.log(keys)

            keys.forEach( each => {
                columns.push({
                  name: each,
                  selector: row => row[each],
                  sortable: true,
                })
            })

            console.log(columns)

            this.setState({
              ...this.state,
              schemaOutput: {
                ...this.state.schemaOutput,
                // fetching: false,
                // data: DATA,
                columns : columns,  
                fetching: true,
                schemaname: val            
              
              }
            }, () => {
              getRequest(`api/upload/getSchemaNameWiseResult?schemaName=${val}`)
              .then( res_ => {
                console.log(res_)
        
                if(res_.res.status === "success"){

                  this.setState({
                    ...this.state,
                    schemaOutput: {
                      ...this.state.schemaOutput,
                      fetching: false,
                      data: JSON.parse(res_.res.data),
                      // columns : columns, 
                    }
                  })

                }
              })

            })
          }
          else{

            this.setState({
              ...this.state,
              schemaOutput: {
                ...this.state.schemaOutput,
                fetching: false,
                data: [],
                columns: false
              }

            })
          }
          
        })
      })
    }

    resultViewOnChange = (e) => {

      const val = e.target.value
      console.log(val)

      this.setState({
        ...this.state,
        schemaOutput: {
          ...this.state.schemaOutput,
          columns : false,
          // fetching: true,
          // schemaname: val
        }
      }, () => {
        let input = { schemaName : val, groupName : this.state.groupName, limit : "0" }

        postRequest(`api/upload/getSchemaNameWiseResultS3`, input)
        .then( res => {
          console.log(res)
  
          if(res.res.status === "success"){

            let DATA = JSON.parse(res.res.data)
            let columns = []

            let keys = Object.keys( DATA.length ? DATA[0] : {})
            console.log(keys)

            keys.forEach( each => {

              if( !this.state.OUTPUT_NOTIN.includes(each) ) {

                columns.push({
                  name: each,
                  selector: row => row[each],
                  sortable: true,
                })
              }
              
            })

            console.log(columns)

            this.setState({
              ...this.state,
              schemaOutput: {
                ...this.state.schemaOutput,
                // fetching: false,
                // data: DATA,
                columns : columns,  
                fetching: true,
                schemaname: val            
              
              }
            }, () => {
              let input = { schemaName : val, groupName : this.state.groupName }

              postRequest(`api/upload/getSchemaNameWiseResultS3`, input)
              .then( res_ => {
                console.log(res_)
        
                if(res_.res.status === "success"){

                  this.setState({
                    ...this.state,
                    schemaOutput: {
                      ...this.state.schemaOutput,
                      fetching: false,
                      data: JSON.parse(res_.res.data),
                      // columns : columns, 
                    }
                  })

                }
              })

            })
          }
          else{

            this.setState({
              ...this.state,
              schemaOutput: {
                ...this.state.schemaOutput,
                fetching: false,
                data: [],
                columns: false
              }

            })
          }

        })
      })


    }
    

  render() {
    return (
        <div>
        <div className='row'>

            <ConfirmDialog />
            
            <div style={{ height: 500, fontSize: '.8rem'}} className="col-md-5">
            {/* <div style={{ height: 650}} className="col-md-5"> */}
                <SortableTree
                treeData={this.props.tree_Data}
                onChange={this.handleTreeChange_in_Tree}
                canDrop={this.handleTreeOnDrop}
                onMoveNode={this.handleChangeTreeOnMove}
                rowHeight={23}
                theme={FileExplorerTheme}
                generateNodeProps={rowInfo =>({
                    buttons: [ 
                    <>
                        {/* <button style={{color: 'red', border: 'none', background: 'none', fontSize: '1rem'}} onClick={() => this.deleteCurrentNode(rowInfo)}><MdDeleteForever /></button> */}
                        
                        {/* <Tooltip title={rowInfo && rowInfo.node && rowInfo.node.val ? rowInfo.node.val : "" }> */}

                        <button style={{border: 'none', background: 'none', fontSize: '1rem'}} onClick={() => this.displayCurrentNode(rowInfo)}>
                        {
                        ( this.props.current_NodeData) &&
                        ( rowInfo.node.technicalName === this.props.current_NodeData.node.technicalName) &&
                        ( rowInfo.path[rowInfo.path.length-1] === this.props.current_NodeData.path[this.props.current_NodeData.path.length-1]) ? 
                        <FaChevronCircleRight /> : <FaAngleRight /> 
                        }
                        <span style={{marginLeft: '.5rem', fontSize:'.8rem', 
                        color : (this.props.current_NodeData) && ( rowInfo.node.technicalName === this.props.current_NodeData.node.technicalName) &&
                        ( rowInfo.path[rowInfo.path.length-1] === this.props.current_NodeData.path[this.props.current_NodeData.path.length-1]) ? '#4E4B4B' : 'green' }}
                        >{rowInfo.node.val}</span>
                        </button>

                        {/* </Tooltip> */}
                        {/* <span style={{color:'green'}}>{rowInfo.node.val}</span> */}
                    </>

                    ]
                }
                )}
                />
            </div>

            <div className="col-md-7">

                {this.props.treeNodeDisplay ? 
                <Display
                data= {this.props.current_NodeData} 
                modifyNodeAtPath = { this.modifyNodeAtPath}
                addingNodeUnderParent = { this.addingNodeUnderParent }
                deleteCurrentNode = { this.deleteCurrentNode}
                fileData = { this.props.fileData }
                nodeLocation = { this.state.nodeLocation }
                themeData = { this.props.themeData }
                />
                :
                null
                }


            </div>

        </div>

        { this.props.tree_Data && this.props.tree_Data.length ?
          <div align="center">
              {/* <pre> {JSON.stringify(this.props.tree_Data, null, 2) } </pre>  */}

              <button className='btn btn-success' onClick={this.saveTreeToS3}>Save</button>

              {/* <a
              type="button"
              href={`data:text/json;charset=utf-8,${encodeURIComponent( JSON.stringify(this.props.tree_Data) ) }`}
              download="TreeJSON.json"
              >
              Download Json
              </a> */}

              <button className='btn btn-warning ml-3' onClick={() => this.ActivateSchema(true)}>Activate</button>

              <button className='btn btn-primary ml-3' onClick={() => this.ActivateSchema(false)}>Show Result</button>


          </div>
        : null
        }

        { this.state.showFinalResult &&
        <>
        <div className="row">

          <label htmlFor="value">Schema Name:</label>

          <select name="schemaname" id="schemaname" className='ml-4' onChange={this.resultViewOnChange}>
            <option value="">Select</option>
            { this.state.schemaNamesList.map( each => <option key={each} value={each}>{each}</option> )}
          </select>

        </div>

        { this.state.schemaOutput.columns && 
          <div className="row">
              <Fragment>
                  <DataTable title={this.state.schemaOutput.schemaname} loading={this.state.schemaOutput.fetching} columns={this.state.schemaOutput.columns} key={this.state.schemaOutput.data} data={this.state.schemaOutput.data} />
              </Fragment> 
          </div>
        }
        </>
        }


        </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        tree_Data: state.tree_Reducer.tree_Data,
        current_NodeData: state.tree_Reducer.current_NodeData,
        treeNodeDisplay: state.tree_Reducer.treeNodeDisplay
    }
}

export default connect(
    mapStateToProps,
    { updateTreeData, flushCurrentNode, currentNodeData, copySetOfNode }
)(Tree_schema);
