import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './account_reports.css';
import DatePicker from "react-datepicker";
import ApiConstants from "../../../config";
import '../../../App.css';
import {Animated} from "react-animated-css";
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Redirect
} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios'; 
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { postRequest } from '../../../globalhelper/helper';
import { connect } from 'react-redux';
// import store from '../../../store';

// const state = store.getState();
// const userData = state.login.userData;
class AccountReports extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            alert : "",
            message : "",
            filename : "",
            showFile : false,
            redirect : null,
            ledgerTypes : [],
            accData : [],
            acc_table_header : [],
            noData : false,
            acc_table_pagination : [],
            formData : {
                fromdate : new Date('2019-04-01'),
                todate : new Date('2020-03-31'),
                ledger : "",
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
   
    handleSubmit = () =>{
        this.setState({
            ...this.state,
            acc_table_header : [],
            acc_table_pagination : [],
            accData : []
        })
        var title = this.props.type
        postRequest( 'api/reports/getAccTable',{
            fromdate : this.state.formData.fromdate,
            todate : this.state.formData.todate,
            title : title,
            ledger : this.state.formData.ledger,
            tenantId : this.props.state.login.userData.tenant_id
          })
        .then(response => {
            if(response.res.status === "success")
            {
                var data = response.res.data;
                var data_length = data.length;
                if(data_length > 0)
                {
                    var headers  = Object.keys(data[0]._id);
                    
                    headers.forEach(header => {
                        if(header !== "_id")
                        {
                            var filter = null;
                            // if(header === 'External_ID' || header === 'GL')
                            // {
                            //     filter = textFilter();
                            // }
                            this.setState({
                                ...this.state,
                                acc_table_header : [
                                    ...this.state.acc_table_header,
                                    {
                                        sort :true,
                                        dataField : header,
                                        filter: filter,
                                        text : header,
                                        headerStyle: (colum, colIndex) => {
                                            if(header.includes('Debit') || header.includes('Credit'))
                                            {
                                                return { textAlign: 'right' };
                                            }
                                            else{
                                                return { textAlign: 'center' };
                                            }
                                        },
                                        formatter : (rowContent, row) => {
                                            if(header.includes('Debit') || header.includes('Credit'))
                                            {
                                                return <p style={{ textAlign : "right", minWidth : "120px", color : "black" }}> { rowContent && Number(rowContent) !== 0 ? rowContent.toFixed(2) : "0.00" } </p>
                                            }
                                            else if(header.includes('External_ID')){
                                                return <p style={{ textAlign : "left", color : "black" }}> { rowContent } </p>;
                                            }
                                            else if(header.includes('Exchange_Rate')){
                                                return <p style={{ textAlign : "right" , minWidth : "120px", color : "black" }}> { rowContent } </p>;
                                            }
                                            else{
                                                return <p style={{ textAlign : "left", color : "black" }}> { rowContent } </p>;
                                            }
                                        }
                                    }   
                                ],
                                acc_table_pagination :  {
                                    custom: true,
                                    sizePerPage : 50,
                                },
                                accClicked : false
                            })
                        }
                    })
                    
                    var newArray = data.map(d => d._id);

                    this.setState(prevState => {
                        let accData = Object.assign({}, prevState.accData);
                        accData = Object.assign({ accData :newArray }, prevState.accData)
                        return accData ;
                    }, () => {
                        console.log(this.state)
                    })

                }
                else{
                    this.setState({
                        ...this.state,
                        noData : true
                    })
                }
            }
        })
        .catch(err => {
            this.setState({
                alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem with connecting Dataserver... </strong></div> </Animated>
            });
            setTimeout(() => { this.setState({alert : null}); }, 3000);
        });
    }

    getLedgerType = () => {
        this.setState({
            ledgerTypes : []
        })

        postRequest( 'api/lease/getLedgerTypes',
        {
            tenantId : this.props.state.login.userData.tenant_id
        })
        .then(result => {
            this.setState({
                ...this.state,
                ledgerTypes : result.res.data,
                formData : {
                    ...this.state.formData,
                    ledger :  result.res.data[0].Ldg 
                }
            })
        })
    }

    
    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date
        }
        },() => {
        console.log(this.state)
        })
    }

    componentWillMount = () => {
        this.getLedgerType();
    }
   
    downloadExcel = () =>{
        var title = this.props.type
        axios.post(ApiConstants.externals.serverUrl + 'api/reports/exportAccounttExcel',{
            fromdate : this.state.formData.fromdate,
            todate : this.state.formData.todate,
            ledger : this.state.formData.ledger,
            tenantId : this.props.state.login.userData.tenant_id,
            title : title
          }, { responseType: 'arraybuffer' })
        .then(response => {
            var FileSaver = require('file-saver');
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, title + '.xlsx');
        })
        .catch(err => {
            this.setState({
                alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem with connecting Dataserver... </strong></div> </Animated>
            });
            setTimeout(() => { this.setState({alert : null}); }, 3000);
        });
    }

    handleChange = (event) =>{

        var id = [event.target.id];
        this.setState({
            formData: {
                    ...this.state.formData,
                    [id]: event.target.value
            }
        })
    }


    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div>
                <div className="row">
                    <div className='col-md-3'>
                        <label>From</label> <br/>
                            <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                    </div>
                    

                    <div className='col-md-3'>
                        <label>To</label><br/>
                        <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                    </div>
                    

                    <div className="col-md-3">
                        <lable> Ledger </lable><br/>
                        <select className="form-control"  id="lease_status" id="ledger" value={ this.state.formData.ledger } onChange={ this.handleChange }  style={{ marginTop : "7px" }}>
                            <option disabled>Ledger</option>
                            { this.state.ledgerTypes.map(opt => 
                                <option key={opt.Ldg} defaultValue={ this.state.ledgerTypes.length === 1 ? true : false } value={opt.Ldg}> {opt.Ldg} </option> )
                            }
                        </select>
                    </div>
                    <div className="col-md-2 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" onClick={ this.handleSubmit }> Load </button>
                    </div>
                </div>

                { this.state.accData && this.state.accData.length > 0
                ?
                <div>
                    <div className="row" >
                        <div className="col-md-12" align="right">
                            <button className="btn btn-info" onClick={ this.downloadExcel }>
                                 {/* <FontAwesomeIcon icon={faDownload} /> */}
                                 <GetAppIcon />
                                  </button>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{ width : '100%', overflowX : 'auto' }}>
                            {/* report-table    // need to uncomment on upload */}
                            <BootstrapTable rowStyle={{ color : 'black'}} classes="BootstrapTable" key="table7" keyField='_id' data={ this.state.accData } columns={ this.state.acc_table_header } filter={ filterFactory() } pagination={ paginationFactory() }/>
                        </div>
                    </div>
                </div>
                    : 
                    
                    this.state.noData ? 
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                        <h6> No Data Found for the given dates !</h6>
                    </div>
                    : 
                    null
                    }
                {this.state.alert}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps
)(AccountReports);

// export default AccountReports;