import {
    createStore,
    applyMiddleware,
    combineReducers
} from 'redux';
import thunk from 'redux-thunk'
import {
    initiateLogin
} from './actions/login_actions'
import {
    initiateCommon, verifyLogin
} from './actions/common_actions';
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from './reducers';

const middleware = [thunk];
const initialState = localStorage.getItem("store") && localStorage.getItem("store") !== null ? JSON.parse(localStorage.getItem("store")) : {
    login: {
        user: {
            login: false
        },
        alert: null,
        auth : null
    },
    common : {
        url: null
    }
}

// const allReducers = combineReducers({...reducer, ...rootReducer})

// localStorage.removeItem("store")
// console.log(allReducers);
const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middleware))
)

store.dispatch(initiateLogin(initialState.login));
store.dispatch(initiateCommon(initialState.common));
// store.dispatch(verifyLogin())
if(initialState.login.auth !== null && initialState.login.user.login)
{
    console.log("Verifying Token...")
    setInterval(() => store.dispatch(verifyLogin()),660000)
}

export default store;