import React, {
    Component, Fragment
} from 'react';
import { connect } from 'react-redux'
import DisplayContainer from './display_container';
import TreeContainer from './tree_container';


class GenerateComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.userData = this.props.state.login.userData;
    }
    


    render() {
        return ( 
            <Fragment>
                <div className="row">
                    <div className="col-md-3">
                        <TreeContainer></TreeContainer>
                    </div>
                    <div className="col-md-9">
                        <DisplayContainer/>
                    </div>
                </div>
           </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
)(GenerateComponents);