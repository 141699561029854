import { Button } from 'react-bootstrap';
import React, {
    Component, Fragment
} from 'react';
import { connect } from 'react-redux'
import { getRequest } from '../../globalhelper/helper';
import _ from 'underscore';
import data from './data.json';

const time = new Date();

console.log("Initiated : ", time);

class DisplaySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elements : [],
            elementSelected : 'disabled',
            rows : data
        }
        this.userData = this.props.state.login.userData;
    }
    
    componentDidMount = () => {
        console.log("Finished : ", new Date() - time);
        getRequest('api/visual/getAllSavedElements').then(res => {
            if(res.type === "success")
            {
              this.setState({
                elements : res.res.data
              })
            }
          })
    }

    componentWillMount = () => {
        console.log("Constructing : ",  new Date() - time);
    }

    onClickData = (data, rowIndex, dataKey) => {
        this.setState(prevState => {
            
            var rows =  JSON.parse(JSON.stringify(prevState.rows));
            var currentRow = rows[rowIndex];
            var currentData = currentRow.data[dataKey];
            
            currentData.json.style.color = 'black';
            currentData.json.style.backgroundColor = 'white';
            currentData.json.style.width = '200px';
            return {
                ...prevState,
                rows : rows
            };
        })
        // console.log(data)
    }

    onAddData = (index) => {
        this.setState(prevState => {
            
            var rows =  JSON.parse(JSON.stringify(prevState.rows));
            var currentRow = rows[index];
            currentRow.data.push({
                style : {
                },
                value : "Harish"
            })
            return {
                ...prevState,
                rows : rows
            };
        })
    }

    onAddRow = (index=this.state.rows.length) => {
        this.setState(prevState => {
            
            var rows =  JSON.parse(JSON.stringify(prevState.rows));
            var newRow = { data : [] };
            rows.splice(index+1, 0, newRow)
            return {
                ...prevState,
                rows : rows
            };
        })
    }

    onElementSelect = (ev, rowIndex, dataIndex) => {
        const {name, value} = ev.target;
        this.setState(prevState => {
            var state = JSON.parse(JSON.stringify(prevState))
            var selectedElement = state.elements.filter(obj => {
                return obj.component === value
              });
            var rows =  state.rows;
            var currentRow = rows[rowIndex];
            currentRow.data[dataIndex] = selectedElement[0];
            // currentData = { ...currentData, PK : selectedElement.PK };
            console.log(value,selectedElement, this.state.elements);
            return {
                ...prevState,
                elementSelected : value,
                rows : rows
            };
        })
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        { this.state.rows.map((row, index) =>
                            <table key={index} style={{  width: '1376.4px', minWidth: '1376.4px', maxWidth: '1376.4px' }}>
                                <tbody style={{ minWidth : 1376.4 }}>
                                    <tr key={index} style={{ minWidth : '100%'}}>
                                        { row.data.length ?
                                            <>
                                        {row.data.map((data, key) => {
                                                const WIDTH = data.json.key_style.width * 22.2;
                                                const HEIGHT = data.json.key_style.height * 19.74;
                                                return(
                                                    // onClick={() => this.onClickData(data,index, key)}
                                                    <td key={key} style={{ width : WIDTH, minWidth : WIDTH, maxWidth : WIDTH, height : HEIGHT, }}>
                                                        {
                                                            typeof data.PK == "undefined" && !data.json ?
                                                            <select className="form-control"  id="element" model={ this.state.elementSelected } value={ this.state.elementSelected } onChange={(ev) => this.onElementSelect(ev, index,key)}>
                                                                    <option disabled>Please select an element</option>
                                                                    { this.state.elements.map(opt => 
                                                                        <option key={opt.component} value={opt.component}> {opt.component} </option> )
                                                                    }
                                                                </select>
                                                                :
                                                            data.json.type === "label" ?
                                                                <label onClick={() => this.onClickData(data, index,key)} style={{...data.json.key_style, width : WIDTH, minWidth : WIDTH, maxWidth : WIDTH, height : HEIGHT, overflow : 'hidden', marginLeft : 5}}> {data.json.value}</label>
                                                                : 
                                                            data.json.type === "input" ?
                                                                <input onClick={() => this.onClickData(data, index,key)} type={data.json.inputType} style={{...data.json.key_style, width : WIDTH, minWidth : WIDTH, maxWidth : WIDTH, height : HEIGHT, overflow : 'hidden'}} defaultValue={data.json.value} />
                                                                : 
                                                            data.json.type === "table" ?
                                                                <Fragment>
                                                                    <table border={1} style={{ border : '1px solid grey', width : '100%' }} >
                                                                        <thead>
                                                                            <tr>
                                                                                <th> { "S.No" } </th>
                                                                                { data.json.headers.map((header, index) => 
                                                                                    <th key={index} colSpan={header.colSpan} style={{ width : Number(header.width) * 22.2 }} >{ header.label }</th>
                                                                                ) } 
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {data.json.rows.map((row,rowKey) => 
                                                                                <tr key={rowKey}>
                                                                                    <td> {Number(rowKey) + 1} </td>
                                                                                    { row.data.map((dt, dataKey) => 
                                                                                        dt.type === "label" ?
                                                                                        <td key={dataKey} >
                                                                                            {dt.value}
                                                                                            </td>
                                                                                        : dt.type === "input" ?
                                                                                            <td key={dataKey}>
                                                                                                <input type={dt.inputType} style={{width : "100%", overflow : 'hidden'}} defaultValue={dt.value} />
                                                                                            </td>
                                                                                        : dt.type === "table" ?
                                                                                            <td key={dataKey} colSpan={data.json.headers.length+4} >
                                                                                                <table style={{ width : '100%',  }}>
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            { dt.headers.map((head,headIndex) => 
                                                                                                                <th key={headIndex} colSpan={head.colSpan} style={{ width : Number(head.width) * 22.2 }} > {head.label} </th>
                                                                                                                ) }
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        { dt.rows.map((cRow,cRowKey) => 
                                                                                                            <tr key={cRowKey}>
                                                                                                                { cRow.data.map((cDt, cDtKey) => 
                                                                                                                    <td key={cDtKey}>
                                                                                                                        { cDtKey.type === "label" ? 
                                                                                                                            cDt.label
                                                                                                                            : 
                                                                                                                            cDt.type === "input" ?
                                                                                                                                <input type={cDt.inputType} style={{width : "100%", overflow : 'hidden'}} defaultValue={cDt.value} />
                                                                                                                                : null
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                ) }
                                                                                                            </tr>
                                                                                                        ) }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                         :
                                                                                         null
                                                                                    ) }
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </Fragment>
                                                                : 
                                                                null
                                                                // console.log(data.component)
                                                        }
                                                    </td>)}
                                            ) }
                                                {/* <td>
                                                    <Button onClick={() => this.onAddData(index)} >+ Add data</Button>
                                                    <Button onClick={() => this.onAddRow(index)} >+ Add Row</Button>
                                                </td> */}
                                            </>
                                            : 
                                            null
                                            // <td>
                                            //     <Button onClick={() => this.onAddData(index)} >+ Add data</Button>
                                            //     <Button onClick={() => this.onAddRow(index)} >+ Add Row</Button>
                                            // </td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        ) }
                        </div>
                    </div>
                {/* <Fragment>
                    <hr />
                    <h4>Styles</h4>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="widthUnits">Width Units</label>
                            <input type="number" min={1} name="widthUnits" id="widthUnits" placeholder="Please input width units" />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3"></div>
                    </div>
                </Fragment> */}
            </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
)(DisplaySection);