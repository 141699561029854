import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getRequest } from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class ExtendItemMaster extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        showtable: false,
         formData: {
            preferredvendor: '',
            itemname: '',
            itemid: '',
            status: '',
            orgname: ''
         },
         tableData: [],
         dropdown_preferredvendor: [],
         dropdown_itemname: [],
         dropdown_itemid: [],
         dropdown_status: [],
         extendJSON: [],
         dropdown_orgname: [],
         companyDetails: [],
         error: false,
         errorMessage: "Please select all the required fields",
         createJSON: []
      }
    }

    componentDidMount(){

        localStorage.setItem("pageName", "Search ItemMaster")

        getRequest(`api/edureka/getItemJSONForExtension`).then(res => {
            console.log(res.res.data)
            this.setState({
                ...this.state,
                extendJSON: res.res.data,
                createJSON: res.res.data
            })
        })

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))

        getRequest(`api/edureka/getIM1`).then(res => {
            let temp = JSON.parse(res.res.data)

            let temp1 = temp.map(value=> value['Preferred Vendor'])
            let temp2 = temp1.filter(value=>value)

            let temp3 = temp.map(value =>value['Item Name'])
            let temp4 = temp3.filter(value=>value)
            let tem = [... new Set(temp4)]

            let temp7 = temp.map(value => value['Item ID'])
            let temp8 = temp7.filter(value =>value)

            this.setState({
                ...this.state,
                dropdown_preferredvendor: temp2,
                dropdown_itemname: tem,
                dropdown_itemid: temp8
            })
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleSearch = () => {

        const {orgname} = this.state.formData

        if(orgname){

            this.setState({
                ...this.state,
                error: false
            })

            const itemname = this.state.formData.itemname
            const itemid = this.state.formData.itemid
     
            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
     
            console.log(id)
     
            const companyid = id.map(item => item.orgid)
     
            console.log(companyid)
            
     
            getRequest(`api/edureka/getExtendItemMasterData?itemname=${itemname}&itemid=${itemid}&orgid=${companyid[0]}`).then(res => {
                if(res.res.status === 'success'){
                    let temp = JSON.parse(res.res.data)
                    console.log(temp)
        
                    this.setState({
                        ...this.state,
                        tableData: temp,
                        showtable: true
                    })
                } else {
                    console.log('No Data')
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: true
            })
        }

    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            error: false,
            formData: {
                preferredvendor: '',
                itemid: '',
                itemname: '',
                status: '',
                orgname: ''
            }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

    handleExtend = (item) => {
        const itemID = item["Item ID"]
        const itemName = item["Item Name"]

        console.log(itemID, itemName)

        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

        console.log(id)

        const companyid = id.map(item => item.orgid)

        console.log(companyid)

        const itemNameIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Item Name')

        const itemIDIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Item ID')

        const orgNameIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Organization Name')

        const orgIDIndex = this.state.extendJSON[1].children[0].children.findIndex(item => item.title === 'Organization ID')

        const updatedJSONData = [...this.state.createJSON]

        console.log(updatedJSONData)

        updatedJSONData[1].children[0].children[itemNameIndex].val = itemName
        updatedJSONData[1].children[0].children[itemIDIndex].val = itemID
        updatedJSONData[1].children[0].children[orgNameIndex].val = this.state.formData.orgname
        updatedJSONData[1].children[0].children[orgIDIndex].val = companyid[0]

        this.setState({
            ...this.state,
            extendJSON: updatedJSONData,
            redirect: true
        },() => console.log(this.state.extendJSON))
    }

    handleScreenRouting = (item) => {
        const orgID = item['Org ID']
        const itemID = item['Preferences item ID']

        getRequest(`api/edureka/getExtendJSONForItemPreference?lde1=${orgID}&lde2=${itemID}`).then(response => {
            console.log(response.res.data)

            this.setState({
                ...this.state,
                extendJSON: response.res.data,
                redirect: true
            })
        }).catch(err => console.log(err))
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"ItemPreferences"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.extendJSON}   
            onBack = {this.onBack}
            />        
        )
    }
    else
    return (
      <Fragment>
        <div className='header_div'>Extend Item Master</div>

        <br/>

        <Form style={{display:'flex', flexDirection:'column'}}>
            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Preferred Vendor:</div>
                <select className='col-lg-3' name='preferredvendor' value={this.state.formData.preferredvendor} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_preferredvendor.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
                <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item Name:</div>
                <select className='col-lg-3' name='itemname' value={this.state.formData.itemname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_itemname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item ID:</div>
                <select className='col-lg-3' name='itemid' value={this.state.formData.itemid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_itemid.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
                <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Not Released'>Not Released</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Void'>Void</option>
                    <option value='Waiting For Approval'>Waiting For Approval</option>
                </select>
            </div> */}

            <div style={{display:'flex', justifyContent:'center'}}>
                <Button variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>

        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length >0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Preferred Item ID</th>
                        <th>Item Name</th>
                        <th>Rate</th>
                        <th>Commodity Code</th>
                        <th>Status</th>
                        <th>Extend</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b) =>a['Item ID'].localeCompare(b['Item ID'])).map((item, index)=> {
                        return (
                            <tr key={index}>
                                <td>{item['Item ID']}</td>
                                <td><Button variant='link' onClick={() => this.handleScreenRouting(item)} size='sm'>{item['Preferences item ID']}</Button></td>
                                <td>{item['Item Name']}</td>
                                <td>{item.Rate}</td>
                                <td>{item['Commodity Code']}</td>
                                <td>{item['Status']}</td>
                                <td><Button size='sm' disabled={item['Preferences item ID']} onClick={() => this.handleExtend(item)} variant={item['Preferences item ID'] ? "warning" : "success"}>{item['Preferences item ID'] ? "Already extended" : "Extend"}</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {this.state.error ?
        <div className='center-screen'>
            {this.state.errorMessage}
        </div> :  null}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        userData: state.login.userData
    }
}

export default connect(mapStateToProps)(ExtendItemMaster)