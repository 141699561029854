import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper';
import DatePicker from "react-datepicker";
import DataTable from '../../components/DataTable2';



class RecommHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            Date : new Date(),
            // Date : '',
            maxDate : new Date().setDate(new Date().getDate()-10),
            input_month : '',
            showRecom : false,
            fetching : false,
            recom : [],
            columns: [{
                name: 'McBehaviourID',
                selector: row => row['McBehaviourID'],
                sortable: true,
            },
            {
                name: 'EventDevice',
                selector: row => row['EventDevice'],
                sortable: true,
            },
            {
                name: 'RecommendedDt',
                selector: row => row['RecommendedDt'],
                sortable: true,
            },
            {
                name: 'RecommendedSpares',
                selector: row => row['RecommendedSpares'],
                sortable: true,
            },
            {
                name: 'ActualSparesRepl',
                selector: row => row['ActualSparesRepl'],
                sortable: true,
            },
            {
                name: 'ErrorPattern',
                selector: row => row['ErrorPattern'],
                sortable: true,
            },
            {
                name: 'VerifyStatus',
                selector: row => row['VerifyStatus'],
                sortable: true,
            }]
         }
    }

   
    handleChange = (ev) => {

        console.log(ev)       

        this.setState({
            Date: ev
        })

    }

    onGetRecommHistory = () => {
        this.setState({
            ...this.state,
            fetching : true,
            showRecom : false
        }, () => {
            const DATE =  this.state.Date

            postRequest('api/cms/getRecommHistory', { date : DATE })
            .then(res => {
                if(res.type === 'success') {
                    this.setState({
                        recom : JSON.parse(res.res.data),
                        fetching : false,
                        showRecom : true
                    });
                }
            });
        })
    }

    // setDate = (date,field) =>{

    //     this.setState({
    //     ...this.state,
    //     [field] : date
    //     })
    // }


    render() { 
        return (
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="errcode_obj">Select Month</label>

                            <DatePicker selected={this.state.Date} showPopperArrow={false} id="Date" name="Date" maxDate={ this.state.maxDate } className="col-md-12" dateFormat="yyyy/MM" onChange={ date => this.handleChange(date) } />


                        </div>
                        <div className="col-md-2">
                            <button type='button'
                             disabled={ this.state.Date === "" || this.state.fetching } 
                             className='btn btn-info' style={{ marginTop : 33, width: '250px', height: '4rem' }} onClick={this.onGetRecommHistory} >Get Recomm History</button>
                        </div>
                    </div>

                    <br /><br />


                    
                    <Fragment>
                        <DataTable title="Recommendation History" loading={this.state.fetching} columns={this.state.columns} key={this.state.recom} data={this.state.recom} />
                    </Fragment> 
                

                </>
                
        );
    }
}
 
export default RecommHistory;