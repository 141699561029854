import {
    combineReducers
} from 'redux'
import treeReducer, * as fromTree from './treeReducer'
import tree_Reducer, * as from_Tree from './tree_Reducer'
import VR2_Reducer from  './vr2_reducer'
import Dynamic_Forms_Reducer from  './dynamic_formsReducer'
import popupReducer from './popupReducer'
import common from './common_reduceres';
import componentsReducer from './components';
import login from './login_reduxres';
import interactions from './interactions_reducers';
import selectAppReducer from "../redux/SelectApp/saReducer";
import selectProcessReducer from "../redux/SelectProcess/spReducer";
import fileDataReducer from "../redux/FileData/fileDataReducer";
import dateFormReducer from "../redux/DateForm/formReducer";
import dataLoadReducer from "../redux/DataLoad/dlReducer";
import FormInputs from "../redux/FormInputs/formReducer";
import dialogflowReducer from "../redux/api/Dialogflow/dialogflowReducer";
import processArrayReducer from "../redux/api/ProcessArray/processArrayReducer";
import processDefnReducer from "../redux/api/ProcessDefn/processDefnReducer";
import getTemplateReducer from "../redux/api/GetTemplate/getTemplateReducer";
import downloadTemplateReducer from "../redux/api/DownloadTemplate/downloadTemplateReducer";
import csvUploadReducer from "../redux/api/CsvUpload/csvUploadReducer";



export default combineReducers({
    treeReducer,
    tree_Reducer,
    VR2_Reducer,
    Dynamic_Forms_Reducer,
    popupReducer,
    login,
    common,
    interactions,
    components: componentsReducer,
    selectApp: selectAppReducer,
    selectProcess: selectProcessReducer,
    fileData: fileDataReducer,
    dateForm: dateFormReducer,
    dataLoad: dataLoadReducer,
    dialogflow: dialogflowReducer,
    processArray: processArrayReducer,
    processDefn: processDefnReducer,
    getTemplate: getTemplateReducer,
    downloadTemplate: downloadTemplateReducer,
    csvUpload: csvUploadReducer,
    chatbotFormInputs: FormInputs
})