import React, { Component, Fragment } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper';
import FormBasicSelect from './FormBasicSelect';
import {Form} from 'react-bootstrap';
import './COD.css';
import './LARPopup.css'
import StationPopup from './StationPopup';

export class AddNewStation extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         AllStationDetails: [],
         FinalData: [],
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         showPopup: false,
         fetching: false,
         formData: {
            client: '',
            model: '', 
            zone: '',
            stationcode: ''
         }
      }
    }

    getFilteredArray = (value, key) => {
        let dropdown_array = value.map((value) => value[key])
        let filtered_dropdown_array = [... new Set(dropdown_array)]
        return filtered_dropdown_array.filter((item) => item != "undefined" && item != 'OFFICE KIRANA CONNECT')
    }

    // Fetches all the available stations

    componentDidMount(){
        this.setState({
            ...this.state,
            fetching: true
        })
        getRequest(`api/cod/getAllStationsDetails`).then(response => {

            let allDetails = JSON.parse(response.res.data)

            let filtered_client = this.getFilteredArray(allDetails, "client_short_name")
            let filtered_model = this.getFilteredArray(allDetails, "ci_business_type")
            let filtered_zone = this.getFilteredArray(allDetails, "zone")

            this.setState({
                ...this.state,
                AllStationDetails: allDetails,
                dropdown_client: filtered_client,
                dropdown_model: filtered_model,
                dropdown_zone: filtered_zone,
                fetching: false
            })
        })
    }

    // Handles state changes

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleBack = () => {
        window.location.reload()
    }

    // Prepares the JSON file to be uploaded

    handleAdd = () => {
        if(this.state.formData.client && this.state.formData.model && this.state.formData.zone && this.state.formData.stationcode){

            const arr1 = [{
                client_short_name: this.state.formData.client,
                client_hub_code: this.state.formData.stationcode,
                zone: this.state.formData.zone,
                ci_business_type: this.state.formData.model,
                status: 'Disabled'
            }]
    
            const combinedArray = [...arr1, ...this.state.AllStationDetails]
    
            this.setState({
                ...this.state,
                FinalData: combinedArray
            }, () => this.handleSubmit())
        } else {
            this.setState({
                ...this.state,
                showPopup: true
            })
        }
    }

    // Triggers the function to upload it to the S3

    handleSubmit = async () => {
        await postRequest(`api/cod/selectionUpload`, this.state.FinalData).then(response => {
            window.location.reload()
        })
    }

  render() {
    return (
      <Fragment>
        <div className='header_div'>
            Add New Station
        </div>

        <br/>

        {!this.state.fetching ?

        <div className='basic_outline'>

            <FormBasicSelect label='Client' value={this.state.formData.client} name='client' onChange={this.handleChange} options={this.state.dropdown_client}></FormBasicSelect>

            <FormBasicSelect label='Model' value={this.state.formData.model} name='model' onChange={this.handleChange} options={this.state.dropdown_model}></FormBasicSelect>

            <FormBasicSelect label='Zone' value={this.state.formData.zone} name='zone' onChange={this.handleChange} options={this.state.dropdown_zone}></FormBasicSelect>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <input className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleChange}></input>
                </Form.Group>
            </div>

        </div> : null }

        {this.state.fetching ? <div className='center'>
            <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
        </div>: null}

        {this.state.showPopup ?
        <StationPopup handleClose={() => this.setState({...this.state, showPopup: false})}></StationPopup> : null}

        <br/>

        {!this.state.fetching ?

        <div style={{display:'inline-block'}}>
            <button className='btn red_button' style={{marginLeft:500}} onClick={this.handleBack}>Back</button>
            <button className='btn green_button' style={{marginLeft:10}} onClick={this.handleAdd}>Add</button>
        </div> : null }


      </Fragment>
    )
  }
}

export default AddNewStation