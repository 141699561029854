import React, { Fragment, Component } from "react";
import { navUrl } from '../../actions/common_actions';
import { connect } from 'react-redux';
import { getRequest, postRequest } from '../../globalhelper/helper';
// import { Animated } from "react-animated-css";
import DataTable from '../../components/DataTable2';
import Multiselect from 'multiselect-react-dropdown';
import '../table.css';
import Popup from '../../components/SimplePOPUP';
// import { Dropdown } from 'semantic-ui-react'
// import 'semantic-ui-css/semantic.min.css'
import { BiExport } from 'react-icons/bi';
import { IoMdArrowRoundBack } from 'react-icons/io';
import _ from 'lodash';
import moment from 'moment'

// import Select from 'react-select';


class AreaManager extends Component {
    constructor(props) {
      super(props)
      this.currentState = this.props.state;
      this.userData = this.currentState.login.userData;
    
      this.state = {
        month_option : [],
        month : "current",
        isLoading : true,
        P_A_G_E : "default",
        ALL_STATION_ACCESS : false,
        showPopup: false,
        temp : null,        
        allData : [],  
        allOptions : [], 
        filteredData : [],     
        Filter : {
            clients : [],
            model : [],
            grp: [],
            stationcode : [],
            text : []
        },
        Default_array : {
            clients : [],
            model : [],
            grp: [],
            stationcode : [],
            text : []
        },
        DUSTPIN : {
            clients : [],
            model : [],
            grp: [],
            stationcode : [],
            text : []
        },
        clients : [],
        model : [],
        grp: [],
        stationcode : [],
        text : [],
        Assigned_Data : [],
        CSV_ARRAY : [],
        CSV_columns : [{
            name: 'Client',
            selector: row => row['Client'],
            sortable: true,
            },
            {
            name: 'ClientBusiness',
            selector: row => row['ClientBusiness'],
            sortable: true,
            },
            {
            name: 'Client Hub Code',
            selector: row => row['Client Hub Code'],
            sortable: true,
            },
            {
            name: 'Code',
            selector: row => row['Code'],
            sortable: true,
            },
            {
            name: 'Text',
            selector: row => row['Text'],
            sortable: true,
            },
            {
            name: 'ci_identity',
            selector: row => row['ci_identity'],
            sortable: true,
            },
            {
            name: 'Name',
            selector: row => row['Name'],
            sortable: true,
            },
            {
            name: 'User_type/CIA Contract Type',
            selector: row => row['User_type/CIA Contract Type'],
            sortable: true,
            },
            {
            name: 'Commission Code',
            selector: row => row['Commission Code'],
            sortable: true,
            },
            {
            name: 'Commission Category',
            selector: row => row['Commission Category'],
            sortable: true,
            },
            {
            name: 'Contract Holder ID',
            selector: row => row['Contract Holder ID'],
            sortable: true,
            },
            {
            name: 'Contract holder Name',
            selector: row => row['Contract holder Name'],
            sortable: true,
            },
            {
            name: 'CIA Activity Type',
            selector: row => row['CIA Activity Type'],
            sortable: true,
            },
            // {
            // name: 'Variable',
            // selector: row => row['Variable'],
            // sortable: true,
            // },
            // {
            // name: 'Fuel Allowence',
            // selector: row => row['Fuel Allowence'],
            // sortable: true,
            // },
            // {
            // name: 'Adhoc Allowence',
            // selector: row => row['Adhoc Allowence'],
            // sortable: true,
            // },
        ]
      }
    }

    navPage = (url) => {
        this.props.navUrl(url)
    }

    componentDidMount = () => {       

        const ARRAY = [{
            value: 'current',
            name: moment(new Date()).format('YYYY/MM')
        }, {
            value: 'prev',
            name: moment(new Date()).subtract(1, 'months').format('YYYY/MM')
        }]

        this.setState({ month_option : ARRAY})

        this.Important_Data()
    }
        
    
    Important_Data = () => {
        
        getRequest('api/upload/getAreaManagerData?month=' + this.state.month)
        .then(res => {
    
            if(res.res.data){
                const allData = JSON.parse(res.res.data.DATA);
    
                let clients = [];
                let model = [];
                let grp = [];
                let stationcode = [];
                let text = [];

                if(allData.length > 0){

                    allData.forEach(each => {        
        
                        each.stdcode = !each.stdcode  ? '' : each.stdcode;
                        each.stdtext = !each.stdtext  ? '' : each.stdtext;
                        each.code = !each.code  ? '' : each.code;
                        each.text = !each.text  ? '' : each.text;
                      
        
                        clients.push(each.client ? each.client : '');
                        model.push(each.model ? each.model : '');
                        grp.push(each.grp ? each.grp : '');
                        stationcode.push(each.stationcode ? each.stationcode : '');  
                        text.push(each.text ? each.text : '');
                    })
        
                    let a = [...new Set(clients)].sort().map(each =>{ return { label: each, value: each } } )
                    let b = [...new Set(model)].sort().map(each =>{ return { label: each, value: each } } )
                    let o = [...new Set(grp)].sort().map(each =>{ return { label: each, value: each } } )
                    let c = [...new Set(stationcode)].sort().map(each =>{ return { label: each, value: each } } )
                    let d = [...new Set(text)].sort().map(each =>{ return { label: each, value: each } } )
        
                    let aa = [...new Set(clients)].sort()
                    let bb = [...new Set(model)].sort()
                    let oo = [...new Set(grp)].sort()
                    let cc = [...new Set(stationcode)].sort()
                    let dd = [...new Set(text)].sort()
                              
                    this.setState({
                        allData : allData,
                        filteredData : _.orderBy(allData, ["text"], ["asc"]),
                        ALL_STATION_ACCESS : res.res.data.ALL_ACCESS,
                        clients: a,
                        model : b,
                        grp : o,
                        stationcode : c,
                        text : d,
                        Filter : {
                            clients : aa,
                            model : bb,
                            grp : oo,
                            stationcode : cc,
                            text : dd
                        },
                        Default_array : {
                            clients : aa,
                            model : bb,
                            grp : oo,
                            stationcode : cc,
                            text : dd
                        },
                        isLoading : false
        
                    }, () => {
                        console.log(this.state.allData)
                        // console.log(this.state.clients)
                        // console.log(this.state.stationcode)
                    })

                }
                else {
                    this.setState({isLoading : false})
                }
    
            }
            else {
                this.setState({isLoading : false})
            }
            
        })
    
        getRequest('api/upload/getAreaManager_Data_options?month=' + this.state.month)
        .then(res => {
            let allOptions = JSON.parse(res.res.data);

            // dummy ci code
            allOptions.push({
                associatetype: "Direct",
                ciidentity: "NOT_CI",
                firstname: "NOT_CI"
            })
            allOptions.push({
                associatetype: "Direct",
                ciidentity: "ADHOC_CI",
                firstname: "ADHOC_CI"
            })
    
    
            this.setState({ allOptions : allOptions },
                () => {
                    // console.log(this.state.allOptions)
                    console.log(this.state.allOptions.length)
                    console.log(this.state.allOptions)
                })
    
        })
    }

    
    update_Filter_Option = () => {
        const allData = this.state.allData;

        let clients = [];
        let model = [];
        let grp = [];
        let stationcode = [];
        let text = [];

        if(allData.length > 0){

            allData.forEach(each => {     

                clients.push(each.client ? each.client : '');
                model.push(each.model ? each.model : '');
                grp.push(each.grp ? each.grp : '');
                stationcode.push(each.stationcode ? each.stationcode : '');
                text.push(each.text ? each.text : '');
            })

            let a = [...new Set(clients)].sort().map(each =>{ return { label: each, value: each } } )
            let b = [...new Set(model)].sort().map(each =>{ return { label: each, value: each } } )
            let o = [...new Set(grp)].sort().map(each =>{ return { label: each, value: each } } )
            let c = [...new Set(stationcode)].sort().map(each =>{ return { label: each, value: each } } )
            let d = [...new Set(text)].sort().map(each =>{ return { label: each, value: each } } )

            let aa = [...new Set(clients)].sort()
            let bb = [...new Set(model)].sort()
            let oo = [...new Set(grp)].sort()
            let cc = [...new Set(stationcode)].sort()
            let dd = [...new Set(text)].sort()

            this.setState({
                clients: a,
                    model : b,
                    grp : o,
                    stationcode : c,
                    text : d,
                    // Filter : {
                    //     clients : aa,
                    //     model : bb,
                    //     grp : oo,
                    //     stationcode : cc,
                    //     text : dd
                    // },
                    Default_array : {
                        clients : aa,
                        model : bb,
                        grp : oo,
                        stationcode : cc,
                        text : dd
                    },

            })
        }
    }

    
    monthChange = (e) => {
        this.setState({
            month: e.target.value,
            isLoading : true,
            P_A_G_E : "default",
            ALL_STATION_ACCESS : false,
            showPopup: false,
            temp : null,
            DUSTPIN : {
                clients : [],
                model : [],
                grp: [],
                stationcode : [],
                text : []
            },
            allData : [],  
            allOptions : [], 
            filteredData : [],     
            Filter : {
                clients : [],
                model : [],
                grp: [],
                stationcode : [],
                text : []
            },
            Default_array : {
                clients : [],
                model : [],
                grp: [],
                stationcode : [],
                text : []
            },
            clients : [],
            model : [],
            grp: [],
            stationcode : [],
            text : [],
            Assigned_Data : [],
            CSV_ARRAY : []
        },
        ()=>{
            console.log(this.state.month)
            this.Important_Data()
            
        })
    }


    // handleChange = (ev) => {
    //     let key = ev.target.name
    //     let value = ev.target.value

    //     this.setState({
    //         ...this.state,
    //         Filter : {
    //             ...this.state.Filter,
    //             [key] : value
    //         }
    //     })
    // }

    inputChange = (e , i , key) => {
        let allData = this.state.allData

        allData[i][key] = e.target.value

        this.setState({ allData : allData }, () => console.log(this.state.allData))
    }


    onSelect = (e, key) => {
        let val = null

        if(e.length === 0) {
            val = this.state.Default_array[key]            
        }
        else{
            val = e.map( each => each.value )
        }
        console.log(val)

        this.setState({ 
            ...this.state,
            Filter : {
                ...this.state.Filter,
                [key] : val
            },
            DUSTPIN : {
                ...this.state.DUSTPIN,
                [key] : e
            }
        },
        //  () => this.ApplyFilter()
        )
        
    }


    ApplyFilter = () => {

        console.log(this.state.Filter)
        
        let allData = JSON.parse(JSON.stringify(this.state.allData))
        console.log(allData)

        let filtered_data = []

        let client = this.state.Filter.clients
        let model = this.state.Filter.model
        let grp = this.state.Filter.grp
        let stationcode = this.state.Filter.stationcode
        let text = this.state.Filter.text

        for(const[i, each] of allData.entries()) {
            if( client.includes(each.client) && model.includes(each.model) && stationcode.includes(each.stationcode) && text.includes(each.text) && grp.includes(each.grp) ) {
                console.log(each)

                filtered_data.push(each)
            }
            if( i === allData.length - 1 ) {
                console.log('last')

                console.log(filtered_data)
                console.log(this.state.allData)
        
                if(filtered_data.length > 0) {
                    this.setState({
                        filteredData : _.orderBy(filtered_data, ["text"], ["asc"])
                    },
                    //  ()=> {

                    //     // changing the filter options based on previous selected filter
                    //     let filteredData = this.state.filteredData

                    //     let clients = [];
                    //     let model = [];
                    //     let grp = [];
                    //     let stationcode = [];
                    //     let text = [];
            
                    //     filteredData.forEach(each => {
            
                    //         clients.push(each.client ? each.client : '');
                    //         model.push(each.model ? each.model : '');
                    //         grp.push(each.grp ? each.grp : '');
                    //         stationcode.push(each.stationcode ? each.stationcode : '')   
                    //         text.push(each.text ? each.text : '')
                    //     })
            
                    //     let a = [...new Set(clients)].sort().map(each =>{ return { label: each, value: each } } )
                    //     let b = [...new Set(model)].sort().map(each =>{ return { label: each, value: each } } )
                    //     let o = [...new Set(grp)].sort().map(each =>{ return { label: each, value: each } } )
                    //     let c = [...new Set(stationcode)].sort().map(each =>{ return { label: each, value: each } } )
                    //     let d = [...new Set(text)].sort().map(each =>{ return { label: each, value: each } } )
            
                    //     let aa = [...new Set(clients)].sort()
                    //     let bb = [...new Set(model)].sort()
                    //     let oo = [...new Set(grp)].sort()
                    //     let cc = [...new Set(stationcode)].sort()
                    //     let dd = [...new Set(text)].sort()

                    //     this.setState({
                    //         ...this.state,
                    //         clients: a,
                    //         model : b,
                    //         grp : o,
                    //         stationcode : c,
                    //         text : d,
                    //         Filter : {
                    //             ...this.state.Filter,
                    //             clients : aa,
                    //             model : bb,
                    //             grp : oo,
                    //             stationcode : cc,
                    //             text : dd
                    //         },
                    //         // Filter : {
                    //         //     // clients : aa,
                    //         //     // model : bb,
                    //         //     grp : oo,
                    //         //     stationcode : cc,
                    //         //     text : dd
                    //         // }
                    //     })


                    // }
                    )
                }
                else {
                    this.setState({
                        filteredData : filtered_data
                    })
                }
            }
        }
    }


    ClearFilter = () => {

        let Default_array = this.state.Default_array

        this.setState({
            Filter : {
                clients : Default_array.clients,
                model : Default_array.model,
                grp : Default_array.grp,
                stationcode : Default_array.stationcode,
                text : Default_array.text
            },
            DUSTPIN : {
                clients : [],
                model : [],
                grp: [],
                stationcode : [],
                text : []
            }            
        }, 
        () => this.ApplyFilter()
        )

    }


    popupSelect = (val) => {
        console.log(val)
        
        this.setState({
            ...this.state,
            Assigned_Data : [],
            temp : {
                ...this.state.temp,
                no_vendor_master : null,
                selected_option : val.length ? val : null
            }
        }, () => {
            console.log(this.state.temp) 
            if( val.length && val[0].associatetype === 'Vendor'){
                console.log("ok")

                getRequest('api/upload/getAreaManager_ContractorName?stationcode=' + this.state.temp.selectedRow.stationcode)
                .then( res => {

                    let DATA = JSON.parse(res.res.data)
                    console.log(DATA)
                    
                    if(DATA === "no_vendor_master") {

                        this.setState({ 
                            temp : {
                                ...this.state.temp,
                                no_vendor_master : "No verdor master available for this station code"
                            }
                        })
                    }
                    else {

                        this.setState({ 
                            temp : {
                                ...this.state.temp,
                                all_contractor: DATA
                            }
                        })
                    }

                })

            }

        })

    }

    contract = (val) => {
        // let val = e.target.value

        // let selected_option = this.state.temp.selected_option

        // selected_option[0][id] = val


        // this.setState({
        //     ...this.state,
        //     temp : {
        //         ...this.state.temp,
        //         selected_option: selected_option
        //     }
        // }, () => console.log(this.state.temp))

        this.setState({
            ...this.state,
            Assigned_Data : [],
            temp : {
                ...this.state.temp,
                selected_contractor: val.length ? val : null
            }
        })
    }


    togglePopup = ( obj , index) => {

        if( !obj && !index ) {
            this.setState({
                showPopup : !this.state.showPopup,
                temp : null,
                Assigned_Data : []
            })
        }
        else{
            this.setState({
                showPopup : !this.state.showPopup,
                Assigned_Data : [],
                temp : {
                    selectedRow : obj,
                    selectedIndex : index,
                    selected_option: null,
                    all_contractor: [],
                    selected_contractor: null
                }
            }, () => console.log(this.state.temp))
        }
    }

    popupAssign = () => {
        let allData = this.state.allData
        let filteredData = this.state.filteredData
        let temp = this.state.temp
        let selected_option = temp.selected_option[0]

        let DATA = this.state.temp.selectedRow
        DATA.stdcode = selected_option.ciidentity
        DATA.stdtext = selected_option.firstname

        let stdclass1 = selected_option.associatetype
        let stdclass4 = temp.selected_contractor !== null && temp.selected_contractor[0].contractorid ? temp.selected_contractor[0].contractorid : ""
        let stdclass5 = temp.selected_contractor !== null && temp.selected_contractor[0].contractorname ? temp.selected_contractor[0].contractorname : ""
        
        DATA.stdclass1 = stdclass1
        DATA.stdclass4 = stdclass1 === 'Vendor' ? stdclass4 : null
        DATA.stdclass5 = stdclass1 === 'Vendor' ? stdclass5 : null

        // console.log(selected_option)
        // console.log(filteredData)


        postRequest('api/upload/update_areaManager_data', {
            data: DATA
        })
        .then(res => {
            console.log(res)
            if(temp.selected_option) {
              
                for(let i = 0; i < filteredData.length; i++) {
                    if(filteredData[i].text === temp.selectedRow.text) {
                        filteredData[i].stdcode = selected_option.ciidentity
                        filteredData[i].stdtext = selected_option.firstname
                        filteredData[i].stdclass1 = stdclass1
                        filteredData[i].stdclass4 = stdclass1 === 'Vendor' ? stdclass4 : ''
                        filteredData[i].stdclass5 = stdclass1 === 'Vendor' ? stdclass5 : ''
                        filteredData[i].grp = "Newly Mapped"

                        console.log(filteredData[i], 'filteredData[i]')
                    }
                }
        
                for(let i = 0; i < allData.length; i++) {
                    if(allData[i].text === temp.selectedRow.text) {
                        allData[i].stdcode = selected_option.ciidentity
                        allData[i].stdtext = selected_option.firstname
                        allData[i].stdclass1 = stdclass1
                        allData[i].stdclass4 = stdclass1 === 'Vendor' ? stdclass4 : ''
                        allData[i].stdclass5 = stdclass1 === 'Vendor' ? stdclass5 : ''
                        allData[i].grp = "Newly Mapped"
                        
                        console.log(allData[i], 'allData[i]')
                    }
                }
        
                this.setState({
                    allData : allData,
                    filteredData : filteredData,
                    showPopup : !this.state.showPopup,
                    temp : null
                }, () => this.update_Filter_Option())
            }
            else{
                console.log('No option selected')
            }
        })


    }


    popupCheckFn = () => {
        let temp = this.state.temp

        if(temp.selected_option.length) {
            console.log(temp.selected_option[0])

            if(temp.selected_option[0].ciidentity === 'NOT_CI' || temp.selected_option[0].ciidentity === 'ADHOC_CI'){

                this.popupAssign()
            }
            else{

                getRequest('api/upload/check_ci_stdcode?stdcode=' + temp.selected_option[0].ciidentity + '&stdtext=' + temp.selected_option[0].firstname+ '&month=' + this.state.month)
                .then(res => {
                    console.log(res.res.data)
    
                    if(res.res.data.length === 0) {
                        
                        this.popupAssign()
                    }
                    else if(res.res.data.length > 0) {
                        this.setState({
                            Assigned_Data : res.res.data
                        })
                    }
                   
                })
                
            }
        }
    }

    changePage = (page) => {
        this.setState({ page : page })
    }

    export = () => {
        let DATA = this.state.filteredData

        let CSV_ARRAY = []

        this.setState({ P_A_G_E : "download"}, 
        () => {

            DATA.forEach( each => {
                let obj = {
                    "Client": each.client === 0 ? 0 : each.client ? each.client : "",
                    "ClientBusiness": each.model === 0 ? 0 : each.model ? each.model : "" ,
                    "Client Hub Code": each.stationcode === 0 ? 0 : each.stationcode ? each.stationcode : "",
                    "Code": each.code === 0 ? 0 : each.code ? each.code : "",
                    "Text": each.text === 0 ? 0 : each.text ? each.text : "",
                    "ci_identity": each.stdcode === 0 ? 0 : each.stdcode ? each.stdcode : "",
                    "Name": each.stdtext === 0 ? 0 : each.stdtext ? each.stdtext : "",
                    "User_type/CIA Contract Type": each.stdclass1 === 0 ? 0 : each.stdclass1 ? each.stdclass1 : "",
                    "Commission Code": each.stdclass2 === 0 ? 0 : each.stdclass2 ? each.stdclass2 : "",
                    "Commission Category": each.stdclass3 === 0 ? 0 : each.stdclass3 ? each.stdclass3 : "",
                    "Contract Holder ID": each.stdclass4 === 0 ? 0 : each.stdclass4 ? each.stdclass4 : "",
                    "Contract holder Name": each.stdclass5 === 0 ? 0 : each.stdclass5 ? each.stdclass5 : "",
                    "CIA Activity Type": each.stdclass6 === 0 ? 0 : each.stdclass6 ? each.stdclass6 : "",
                    // "Variable": each.stdclass7 === 0 ? 0 : each.stdclass7 ? each.stdclass7 : "",
                    // "Fuel Allowence": each.stdclass8 === 0 ? 0 : each.stdclass8 ? each.stdclass8 : "",
                    // "Adhoc Allowence": each.stdclass9 === 0 ? 0 : each.stdclass9 ? each.stdclass9 : ""              
                }
    
                CSV_ARRAY.push(obj)
            })
    
            this.setState({ CSV_ARRAY : CSV_ARRAY})
        })

    }
    

    onVerify = ( row , index) => {

        let allData = this.state.allData
        let filteredData = this.state.filteredData

        postRequest('api/upload/verify_areaManager_data', row )
        .then( res => {
            console.log(res)

            for(let i = 0; i < filteredData.length; i++) {
                if(filteredData[i].text === row.text) {
                    
                    filteredData[i].grp = "Verified"

                    console.log(filteredData[i], 'filteredData[i]')
                }
            }
    
            for(let i = 0; i < allData.length; i++) {
                if(allData[i].text === row.text) {
                   
                    allData[i].grp = "Verified"
                    
                    console.log(allData[i], 'allData[i]')
                }
            }

            this.setState({
                allData : allData,
                filteredData : filteredData                
            }, () => this.update_Filter_Option())

        })

    }


        


  render() {
    return (

    <>

    { this.state.P_A_G_E === 'default' ? 
        <div>

            {/* PopUp */}
            {this.state.showPopup && <Popup
            content={<>
            <div className='row'>
                <div className='col-md-6'>
                    <label htmlFor="stdtext" style={{color: this.state.temp.selected_option ? 'green' : ''}}>CI Name</label>
                    <Multiselect
                    selectionLimit="1"
                    options={this.state.allOptions} 
                    selectedValues={this.state.temp.selected_option}
                    onSelect={this.popupSelect}
                    onRemove={this.popupSelect}
                    displayValue="firstname"            
                    />  
                </div>
                <div className='col-md-6'>
                    <label htmlFor="stdcode" style={{color: this.state.temp.selected_option ? 'green' : ''}}>CIOS ID</label>
                    <Multiselect
                    selectionLimit="1"
                    options={this.state.allOptions} 
                    selectedValues={this.state.temp.selected_option}
                    onSelect={this.popupSelect}
                    onRemove={this.popupSelect}
                    displayValue="ciidentity"                                                
                    />  
                    {/* <Dropdown placeholder='stdcode' search selection options={this.state.allOptions} /> */}

                </div>
            </div>  
            {this.state.temp.selected_option && this.state.temp.selected_option[0].associatetype === "Vendor" ?
            <>
            < br/>
            <div className='row'>
                <div className='col-md-3'>
                    <label htmlFor="associatetype" style={{color: 'orange'}}>Associate Type : </label>
                    <span className='font-weight-bold'> {this.state.temp.selected_option[0].associatetype} </span>                  
                </div>
                <div className='col-md-4'>
                    <label htmlFor="contractname" style={{color: this.state.temp.selected_option[0].contractname ? 'green' : ''}}>Contract Name : </label>
                    {/* <input type="text" onChange={(e) => this.contract(e, 'contractname')} value={this.state.temp.selected_option[0].contractname} placeholder="Contract Name" ></input>             */}
                    <Multiselect
                    selectionLimit="1"
                    options={this.state.temp.all_contractor}
                    selectedValues={this.state.temp.selected_contractor}
                    onSelect={this.contract}
                    onRemove={this.contract}
                    displayValue="contractorname"                                                
                    />  
                </div>
                <div className='col-md-4'>
                    <label htmlFor="contractid" style={{color: this.state.temp.selected_option[0].contractid ? 'green' : ''}}>Contract ID : </label>
                    {/* <input type="text" onChange={(e) => this.contract(e, 'contractid')} value={this.state.temp.selected_option[0].contractid} placeholder="Contract ID" ></input>      */}
                    <Multiselect
                    selectionLimit="1"
                    options={this.state.temp.all_contractor}
                    selectedValues={this.state.temp.selected_contractor}
                    onSelect={this.contract}
                    onRemove={this.contract}
                    displayValue="contractorid"                                                
                    />        
                </div>
            </div>
            </>
            :
            null }

            <div align='right'>
                {this.state.temp.no_vendor_master ? 
                <div style={{color: 'red'}}>{this.state.temp.no_vendor_master}</div>
                :
                <button type="button" className='btn btn-link'
                 disabled={ !this.state.temp.selected_option || (this.state.temp.selected_option[0].associatetype === 'Vendor' ? (this.state.temp.selected_contractor === null || this.state.temp.selected_contractor.length === 0) : null ) } 
                 onClick={this.popupCheckFn}>Check & Assign</button>
                }
            </div>   

            <div className='row'>
                {/* <div className='col-md-6'>
                    <label htmlFor="forprdfrom" style={{color: 'green'}}>Prd from : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.forprdfrom} </span>                  
                </div> */}

                <div className='col-md-6'>
                    <label htmlFor="client" style={{color: 'green'}}>Client : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.client} </span>
                </div>
                <div className='col-md-6'>
                    <label htmlFor="Model" style={{color: 'green'}}>Model : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.model} </span>                
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <label htmlFor="StationCode" style={{color: 'green'}}>Client Hub Name : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.stationcode} </span>                                
                </div>
                <div className='col-md-6'>
                    <label htmlFor="code" style={{color: 'green'}}>Client ID : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.code} </span>              
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <label htmlFor="text" style={{color: 'green'}}>Name as Per Client System : </label>
                    <span className='font-weight-bold'> {this.state.temp.selectedRow.text} </span>                                
                </div>
            </div>  <br/>

            { this.state.Assigned_Data.length > 0 ?

            <>
            <h5 className='font-weight-bold' style={{color: 'red'}}>Previous mapping for "{this.state.temp.selected_option[0].ciidentity}" :</h5>
            <div className='scroll-table'>
            <table>
                <thead>
                <tr>
                    <th>S.No</th>
                    {/* <th>Prd From</th> */}
                    <th>Client</th>
                    <th>Model</th>
                    <th>Client Hub Name</th>
                    <th>Client ID</th>
                    <th>Name as Per Client System</th>
                    <th>CI Name</th>
                    <th>CIOS ID</th>
                </tr>
                </thead>

                <tbody>                        
                    { this.state.Assigned_Data.map( (each, i) => {                                    
                        
                        return(
                        
                            <tr key={i} >

                                <td>{i+1}</td>
                                {/* <td>{each.forprdfrom}</td> */}
                                <td>{each.client}</td>
                                <td>{each.model}</td>
                                <td>{each.stationcode}</td>
                                <td>{each.code}</td>
                                <td>{each.text}</td>
                                <td>{each.stdtext}</td>                             
                                <td>{each.stdcode}</td>

                            </tr>
                        )
                    })
                    }        
                </tbody>            

            </table>
        </div>
        <div align='center'>
            <h6 className='font-weight-bold'>Still want to proceed ? click  <button className='btn btn-success' disabled={ !this.state.temp.selected_option } onClick={this.popupAssign}>Assign</button></h6>
        </div> 
        </>
        : null
        }



            </>}
            handleClose={this.togglePopup}
        />}


        <div className='row'>

            <div className='col-md-1'>
            <label htmlFor="client">Month</label>< br />
                <select name="month" id="month" style={{ borderRadius: '20%', marginTop: '.3rem'}} value={this.state.month} onChange={this.monthChange}>
                    {/* <option value="" disabled>select</option> */}
                    {this.state.month_option.map( each => <option key={each.value} value={each.value}>{each.name}</option>)}
                </select>
            </div>

            <div className='col-md-3'>

            <label htmlFor="client">Client</label>
            <Multiselect
                options={this.state.clients} // Options to display in the dropdown
                selectedValues={this.state.DUSTPIN.clients} // Preselected value to persist in dropdown
                onSelect={(e) =>this.onSelect(e, 'clients')} // Function will trigger on select event
                onRemove={(e) =>this.onSelect(e, 'clients')} // Function will trigger on remove event
                displayValue="label" // Property name to display in the dropdown options
            />                
            </div>

            <div className='col-md-3'>

            <label htmlFor="model">Model</label>
            <Multiselect
                options={this.state.model} 
                selectedValues={this.state.DUSTPIN.model}
                onSelect={(e) =>this.onSelect(e, 'model')} 
                onRemove={(e) =>this.onSelect(e, 'model')} 
                displayValue="label" 
            />
            </div>
                            

            <div className='col-md-3'>

            <label htmlFor="type">Type</label>
            <Multiselect
                options={this.state.grp} 
                selectedValues={this.state.DUSTPIN.grp}
                onSelect={(e) =>this.onSelect(e, 'grp')} 
                onRemove={(e) =>this.onSelect(e, 'grp')} 
                displayValue="label" 
            />
            
            </div>

        </div>

        <div className='row'>

            <div className='col-md-4'>
                <label htmlFor="stationcode">Client Hub Name</label>
                <Multiselect
                options={this.state.stationcode} 
                selectedValues={this.state.DUSTPIN.stationcode}
                onSelect={(e) =>this.onSelect(e, 'stationcode')} 
                onRemove={(e) =>this.onSelect(e, 'stationcode')} 
                displayValue="label" 
                />                
            </div>

            <div className='col-md-4'>
                <label htmlFor="text">Name as Per Client System</label>
                <Multiselect
                options={this.state.text} 
                selectedValues={this.state.DUSTPIN.text}
                onSelect={(e) =>this.onSelect(e, 'text')} 
                onRemove={(e) =>this.onSelect(e, 'text')} 
                displayValue="label" 
                />     
            </div>
            <div className='col-md-2'>
                <button className='btn btn-success' style={{margin: '2.6rem 0 0 3rem'}} onClick={this.ApplyFilter}>Apply Filter</button> 
            </div>
            <div className='col-md-2'>
                <button className='btn btn-danger' style={{margin: '2.6rem 0 0 0'}} onClick={this.ClearFilter}>Clear Filter</button> 
            </div>

        </div>       
        


        { this.state.isLoading > 0 ?

        <div className="font-weight-bold" style={{marginTop: '10rem', fontSize: '1.7rem'}} align="center">Loading...</div>

        :
        

        <div className="row mt-4">
            <div className="col-md-12">

                

                { !this.state.filteredData.length ? 

                <div style={{marginTop: '10rem', fontSize: '1.3rem'}} align="center">No records found</div>
                :
                <>
                { this.state.ALL_STATION_ACCESS ? 

                    <div align='right'>
                        <button className='btn btn-warning mr-5 mb-2' onClick={this.export}><BiExport/> Export</button>
                    </div> 

                : null
                }

                <div className='scroll-table'>
                    <table id='table-css' >
                        <thead>
                        <tr>
                            <th>S.No</th>
                            {/* <th>Prd From</th> */}
                            <th>Client</th>
                            <th>Model</th>
                            <th>Client Hub Name</th>
                            <th>Client ID</th>
                            <th>Name as Per Client System</th>
                            <th>CI Name</th>
                            <th>CIOS ID</th>
                            <th>Associate Type</th>
                            <th>Group</th>
                            <th></th>
                            { this.state.ALL_STATION_ACCESS ? <th></th> : null }
                        </tr>
                        </thead>

                        <tbody>                        
                            { this.state.filteredData.map( (each, i) => {                                    
                                
                                return(
                                
                                    <tr key={i} >

                                        <td>{i+1}</td>
                                        {/* <td>{each.forprdfrom}</td> */}
                                        <td>{each.client}</td>
                                        <td>{each.model}</td>
                                        <td>{each.stationcode}</td>
                                        <td>{each.code}</td>
                                        <td>{each.text}</td>
                                        <td>{each.stdtext}</td>                             
                                        <td>{each.stdcode}</td>
                                        <td>{each.stdclass1}</td>
                                        <td>{each.grp}</td>
                                        <td><button onClick={() => this.togglePopup(each, i)} className='btn btn-primary'>Assign</button></td>  
                                        { this.state.ALL_STATION_ACCESS && each.grp !== "Unassigned" && each.grp !== "Existing Mapping" ? 
                                        <td>
                                            <button onClick={() => this.onVerify(each, i)} disabled={each.grp === "Verified"} className='btn btn-success'>Verify</button>
                                        </td>
                                        : null }  

                                    </tr>
                                )
                            })
                            }        
                        </tbody>            

                    </table>
                </div>  
                </>

                }                
                
                
            </div>
        </div>

        } 

        </div>

    : 
    
        <div>
        <button className="btn btn-warning" style={{margin: '1rem 0 2rem'}} onClick={()=> this.setState({ P_A_G_E : 'default'})}> <IoMdArrowRoundBack /> Go Back</button>

        <Fragment>
            <DataTable title="Area Manager Data" loading={false} columns={this.state.CSV_columns} key={this.state.CSV_ARRAY} data={this.state.CSV_ARRAY} />
        </Fragment> 
        </div>
    }
    </>
    )
  }
}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(AreaManager);

