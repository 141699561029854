import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ApiConstants from "../../config";
import './po.css'
import {Animated} from "react-animated-css";
import Spinner from "react-bootstrap/Spinner";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import download from 'downloadjs';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Accordion, Card,  TableHeaderColumn } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { postRequest, getRequest } from '../../globalhelper/helper'

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)


class PoList extends Component {

  constructor (props){
    super(props);
    this.state = 
    {
      alert : '',
      selectedExternal_id : '',
      formData : {
        status : 'Active',
        po_external_id : ''
      },
      poList : [],
      details : [],
      processOptions : [],
      redirect : null,
      noModules : false,
      selectedpo :null,
      pagination : [],
      showTable : false,
      itemDetails : [],
      itemHeader : [
        {
          text : "System",
          dataField : "System",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.System_truth ? row.System_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "Material Code",
          dataField : "PO_Item_MaterialCd",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_MaterialCd_truth ? row.PO_Item_MaterialCd_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "Location Code",
          dataField : "PO_Item_LocationCd",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_LocationCd_truth ? row.PO_Item_LocationCd_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurQty",
          dataField : "PO_Item_PurQty",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurQty_truth ? row.PO_Item_PurQty_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurUOM",
          dataField : "PO_Item_PurUOM",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurUOM_truth ? row.PO_Item_PurUOM_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurRate",
          dataField : "PO_Item_PurRate",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurRate_truth ? row.PO_Item_PurRate_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurRatePer",
          dataField : "PO_Item_PurRatePer",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurRatePer_truth ? row.PO_Item_PurRatePer_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurRateUOM",
          dataField : "PO_Item_PurRateUOM",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurRateUOM_truth ? row.PO_Item_PurRateUOM_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurCurr",
          dataField : "PO_Item_PurCurr",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurCurr_truth ? row.PO_Item_PurCurr_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PurRateConv",
          dataField : "PO_Item_PurRateConv",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_PurRateConv_truth ? row.PO_Item_PurRateConv_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "BaseQtyConv",
          dataField : "PO_Item_BaseQtyConv",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_BaseQtyConv_truth ? row.PO_Item_BaseQtyConv_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "BaseQty",
          dataField : "PO_Item_BaseQty",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_BaseQty_truth ? row.PO_Item_BaseQty_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "BaseUOM",
          dataField : "PO_Item_BaseUOM",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_BaseUOM_truth ? row.PO_Item_BaseUOM_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "BaseValue",
          dataField : "PO_Item_BaseValue",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_BaseValue_truth ? row.PO_Item_BaseValue_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "BaseRate",
          dataField : "PO_Item_BaseRate",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_BaseRate_truth ? row.PO_Item_BaseRate_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "DiscP",
          dataField : "PO_Item_DiscP",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_DiscP_truth ? row.PO_Item_DiscP_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "DiscAmt",
          dataField : "PO_Item_DiscAmt",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_DiscAmt_truth ? row.PO_Item_DiscAmt_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "NettBaseValue",
          dataField : "PO_Item_NettBaseValue",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_NettBaseValue_truth ? row.PO_Item_NettBaseValue_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "TaxP",
          dataField : "PO_Item_TaxP",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_TaxP_truth ? row.PO_Item_TaxP_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "TaxAmt",
          dataField : "PO_Item_TaxAmt",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_TaxAmt_truth ? row.PO_Item_TaxAmt_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "TotalValue",
          dataField : "PO_Item_TotalValue",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.PO_Item_TotalValue_truth ? row.PO_Item_TotalValue_truth : 'black' }}> { rowContent } </p>
          }
        },
      ],
      header : [
        {
          text : "System",
          dataField : "System",
          sort : true,
        },
        {
          text : "PO No.",
          dataField : "PO_No",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', color : row.PO_No_truth ? row.PO_No_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PO Date",
          dataField : "PO_Date",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', color : row.PO_Date_truth ? row.PO_Date_truth : 'black' }}> { rowContent ?  rowContent.split("T")[0] : '' } </p>
          }
        },
        {
          text : "PO Type",
          dataField : "PO_Type",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', color : row.PO_Type_truth ? row.PO_Type_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PO Company",
          dataField : "PO_Company",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', color : row.PO_Company_truth ? row.PO_Company_truth : 'black' }}> { rowContent } </p>
          }
        },
        {
          text : "PO Organisation",
          dataField : "PO_Organisation",
          sort : true,
          formatter : (rowContent, row) => {
            return <p style={{ padding : '0px', color : row.PO_Organisation_truth ? row.PO_Organisation_truth : 'black' }}> { rowContent } </p>
          }
        }
      ],
      grnHeader : [{
        "sort": true,
        "dataField": "System",
        "text": "System",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', minWidth : '100px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.System_truth ? row.System_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_InternalID",
        "text": "GRN Item InternalID",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_InternalID_truth ? row.GRN_Item_InternalID_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_MaterialCd",
        "text": "GRN Item MaterialCd",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_MaterialCd_truth ? row.GRN_Item_MaterialCd_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_MaterialDesc",
        "text": "GRN Item MaterialDesc",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_MaterialDesc_truth ? row.GRN_Item_MaterialDesc_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_LocationCd",
        "text": "GRN Item LocationCd",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_LocationCd_truth ? row.GRN_Item_LocationCd_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_RejQty",
        "text": "GRN Item RejQty",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_RejQty_truth ? row.GRN_Item_RejQty_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_RejPurUOM",
        "text": "GRN Item RejPurUOM",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_RejPurUOM_truth ? row.GRN_Item_RejPurUOM_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_AcptQty",
        "text": "GRN Item AcptQty",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_AcptQty_truth ? row.GRN_Item_AcptQty_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_BaseQty",
        "text": "GRN Item BaseQty",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_BaseQty_truth ? row.GRN_Item_BaseQty_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_BaseValue",
        "text": "GRN Item BaseValue",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_BaseValue_truth ? row.GRN_Item_BaseValue_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_AcptPurUOM",
        "text": "GRN Item AcptPurUOM",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_AcptPurUOM_truth ? row.GRN_Item_AcptPurUOM_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_PurRate",
        "text": "GRN Item PurRate",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_PurRate_truth ? row.GRN_Item_PurRate_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_PurRatePer",
        "text": "GRN Item PurRatePer",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_PurRatePer_truth ? row.GRN_Item_PurRatePer_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_PurRateUOM",
        "text": "GRN Item PurRateUOM",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_PurRateUOM_truth ? row.GRN_Item_PurRateUOM_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_PurCurr",
        "text": "GRN Item PurCurr",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_PurCurr_truth ? row.GRN_Item_PurCurr_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_PurRateConv",
        "text": "GRN Item PurRateConv",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_PurRateConv_truth ? row.GRN_Item_PurRateConv_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_BaseQtyConv",
        "text": "GRN Item BaseQtyConv",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_BaseQtyConv_truth ? row.GRN_Item_BaseQtyConv_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_BaseRate",
        "text": "GRN Item BaseRate",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_BaseRate_truth ? row.GRN_Item_BaseRate_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_BaseUOM",
        "text": "GRN Item BaseUOM",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_BaseUOM_truth ? row.GRN_Item_BaseUOM_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_DiscAmt",
        "text": "GRN Item DiscAmt",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_DiscAmt_truth ? row.GRN_Item_DiscAmt_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_NettBaseValue",
        "text": "GRN Item NettBaseValue",
        formatter : (rowContent, row) => {
          console.log(typeof rowContent)
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_NettBaseValue_truth ? row.GRN_Item_NettBaseValue_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_DiscP",
        "text": "GRN Item DiscP",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_DiscP_truth ? row.GRN_Item_DiscP_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_TotalValue",
        "text": "GRN Item TotalValue",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_TotalValue_truth ? row.GRN_Item_TotalValue_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_TaxAmt",
        "text": "GRN Item TaxAmt",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_TaxAmt_truth ? row.GRN_Item_TaxAmt_truth : 'black' }}> { rowContent } </p>
        }
    }, {
        "sort": true,
        "dataField": "GRN_Item_TaxP",
        "text": "GRN Item TaxP",
        formatter : (rowContent, row) => {
          return <p style={{ padding : '0px', width : '80px', textAlign: typeof rowContent === 'number' || typeof rowContent === 'float'  ? 'right' : 'left',  color : row.GRN_Item_TaxP_truth ? row.GRN_Item_TaxP_truth : 'black' }}> { rowContent } </p>
        }
    }],
      grn_pagination : [],
      grnDetails : [],
      data_arraived : false
    }
  }

  
  handleChange = (event) => {
    var id = [event.target.id];

    this.setState( {
        formData: {
              ...this.state.formData,
              [id]: event.target.value
        }
    })

}

  
  handleSubmit = () => {
    var formdata = this.state.formData;

    this.setState({
      ...this.state,
      poList : [],
      details : [],
      showTable : false,
    })

    getRequest( 'api/po/getPoList', formdata)
    .then(result => {
      console.log(result)
      if(result.res.status === "info")
      {
        this.setState({
          ...this.state,
          poList : result.res.data
        })
      }
    })
}

getDetails = (po) => {
  this.setState({
    ...this.state,
    selectedpo : po.id,
    selectedExternal_id : po.id,
    details : po.items,
    showTable : po.items.length > 0 ? true : false,
    pagination : {
      custom: true,
      sizePerPage : po.items.length,
    }
  })
}

getGrnDetails = () => {
  this.setState({
    ...this.state,
    data_arraived : false
  })
    postRequest( 'api/po/getGrnDetails', {External_ID : this.state.selectedExternal_id, status : this.state.formData.status } )
    .then(response => {
      var data = response.data.data;
      var data_length = data.length;
      if(data_length > 0)
      {
          var headers  = Object.keys(data[0]);
          var grnHeader = [];
          // if(this.state.grnHeader && this.state.grnHeader.length <= 0)
          // {
              // headers.forEach(header => {
              //     if(header !== "_id")
              //     {
              //       grnHeader.push({
              //                 sort :true,
              //                 dataField : header,
              //                 text : header.split("_").join(" "),
              //                 headerStyle: (colum, colIndex) => {
              //                   if(header.includes('Qty') || header.includes('Value') || header.includes('Amnt'))
              //                     {
              //                         return { textAlign: 'right', width : '70px' };
              //                     }
              //                     else{
              //                         return { textAlign: 'center', width : '70px' };
              //                     }
              //                 },
              //                 formatter : (rowContent, row) => {
                                  
              //                   if(header.includes('Qty') || header.includes('Value') || header.includes('Amnt'))
              //                     {
              //                         return <p style={{ textAlign : "right", minWidth : "120px" }}> { rowContent && Number(rowContent) !== 0 ? rowContent.toFixed(2) : "0.00" } </p>
              //                     }
              //                     else{
              //                         return <p style={{ textAlign : "left" }}> { rowContent } </p>;
              //                     }
              //                 }
              //             } 
              //       )
              //     }
              //   })

              //   console.log(grnHeader)
                  this.setState({
                    ...this.state,
                    grn_pagination :  {
                        custom: true,
                        sizePerPage : data_length,
                    },
                    data_arraived : true 
                })
              // }
              // else{
              //     this.setState({
              //         ...this.state,
              //         data_arraived : true,
              //         grn_pagination :  {
              //             custom: true,
              //             sizePerPage : data_length,
              //         }
              //     })
              // }
              var newArray = data.map(d => d);

              this.setState(prevState => {
                  let grnDetails = Object.assign({}, prevState.grnDetails);
                  grnDetails = Object.assign({ grnDetails : newArray }, grnDetails)
                  return { grnDetails : grnDetails.grnDetails };
              },() => {
                console.log(this.state)
              })
      }
    })
}


expandRow = {
  onExpand: (row, isExpand, rowIndex, e) => {
    postRequest( 'api/po/getItemDetails', {External_ID : this.state.selectedExternal_id, status : this.state.formData.status } )
    .then(result => {
      this.setState({
        ...this.state,
        itemDetails : result.data.data,
        item_pagination : {
          custom : true,
          sizePerPage : result.data.data.length,
        }
      })
      this.getGrnDetails();
    })
    
  },
  renderer: row => (
    <div >
      {/* { JSON.stringify(row) } */}
      { row.System === 'HIS' ?
          <div>
          <h5>Item Details</h5>
            <BootstrapTable key="table1" keyField='_id' classess="BootstrapTable" id="table" headerWrapperClasses="poHeaderClass" data={ this.state.itemDetails }  columns={ this.state.itemHeader } pagination={ paginationFactory(this.state.item_pagination) } />
            <br/>
            { this.state.grnDetails.length > 0 ?
            <>
              <h5>GRN Details</h5>
              <BootstrapTable key="table2" keyField='System' classess="BootstrapTable" id="table5" headerWrapperClasses="poHeaderClass" data={ this.state.grnDetails } columns={ this.state.grnHeader } pagination={ paginationFactory(this.state.grn_pagination) } />
              </>
              : null }
          </div>
          : null }
    </div>
  ),
  onlyOneExpanding: true,
  // showExpandColumn: true,
  expandByColumnOnly: false,
  nonExpandable: [1, 3 ,5]
}


  componentWillMount = () =>{
    var module = localStorage.getItem("module");
    this.handleSubmit()
  }

  
  render() {
    
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div>
        <div className="row">

        <div className="col-md-6 text-center ">
            <input className="form-control" type="text" id="po_external_id" value={ this.state.formData.po_external_id } placeholder="Search PO No." aria-label="Search"  onChange={ this.handleChange }/> <br/>
        </div>

        <div className="col-md-6">
          <div className="row">
              <div className="col-md-4 text-center">
                  <div className="form-group">
                      <select className="form-control"  id="lease_status" model={ this.state.formData.status }  onChange={ this.handleChange }>
                          <option disabled>PO Status</option>
                          <option key="Active">Active</option>
                          <option key="Inactive">Inactive</option>
                      </select>
                  </div>
              </div>
              <div className="col-md-4 text-center">
                  <button className="btn btn-teal" onClick={ this.handleSubmit }> Load </button>
              </div>
          </div>
      </div>
        </div>
        <br/>
        <br/>

        <div className="row" id="po-table">


          <div className="col-md-2 scroll-div" style={{ height : '70vh' }}>
            {
              this.state.poList.map(po =>  
                <a className= { this.state.selectedpo === po._id ?  "card text-center leases active-lease" : "card text-center leases" } href="#po-table"  key={ po._id } onClick={ () => this.getDetails(po) }>
                  <div className="row">
                    <div className="col-md-12 text-align" >
                      { this.state.selectedpo === po._id ?
                          <FontAwesomeIcon icon="check-circle" style={{ color : "green" }} />
                      : null
                      }
                      <b> {  po.po_id }</b>
                  </div>
                </div>
                </a>
                )
            }
          </div>

          {
          this.state.showTable ?
            <div className="col-md-10" id="lease-table">
                {
                    this.state.details ? 
                      <div className="scroll-table">
                          {/* <BootstrapTable headerStyle={{ backgroundColor : '#233067' }} headerWrapperClasses="BootstrapTable" classes="BootstrapTable" key="table6" id="main-table" headerClasses="customHeaderClass"  rowClasses="customRowClass" keyField='_id' expandRow={ this.expandRow } data={ this.state.details } columns={ this.state.header } pagination={ paginationFactory(this.state.pagination) }/> */}
                          <table width="100%" border="1" >
                            <thead>
                              <tr>
                                <th> S.No </th>
                                <th> Matl Code </th>
                                <th> Requisition Ref </th>
                                <th> Expected DelyDt </th>
                                <th colSpan="2"> Pur Qty </th>
                                <th colSpan="2"> Pur Base Price </th>
                                <th colSpan="2"> Disc Code </th>
                                <th> Net Price </th>
                                <th> Base Total </th>
                                <th> Tax Parameters </th>
                                <th> Item Total </th>
                              </tr>
                            </thead>
                            <tbody>
                              { this.state.details.map((item, itemKey) => 
                                <tr key={itemKey}>
                                  <td>{itemKey + 1}</td>
                                  <td> {item.MaterialCode} </td>
                                  <td>  </td>
                                  <td>  </td>
                                  <td> {item.PurQty} </td>
                                  <td> {item.PurUOM} </td>
                                  <td> {item.PurRate} </td>
                                  <td> {item.PurRateConv} </td>
                                  <td> {item.DiscPer} </td>
                                  <td> {item.DiscAmt} </td>
                                  <td>  </td>
                                  <td>  </td>
                                  <td>  </td>
                                  <td>  </td>
                                </tr>
                              ) }
                            </tbody>
                          </table>
                      </div>
                    : null
                }

          </div>
          :
          null }
        </div>
      </div>
    );
  }
}

export default  PoList;
