import React, { Fragment, PureComponent } from 'react';
import { getRequest } from '../globalhelper/helper';

class InspectUpload extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                process_code : '',
                assessment_year : '',

            },
            processList : [
                {
                    name : 'SRR',
                    value : 'SRR'
                },
                {
                    name : 'RCPT',
                    value : 'RCPT'
                },
                {
                    name : 'FORM26AS',
                    value : 'FORM26AS'
                },
                {
                    name : 'TDSR',
                    value : 'TDSR'
                },
            ],
            assessmentYearList : [],
            filesList : [],
        }
    }

    componentDidMount = () => {
        // getRequest('api/upload/getAllProcessFolders').then(result => {
        //     if(result.type === "success")
        //     {
        //         this.setState({
        //             ...this.state,
        //             processList : result.res.data
        //         })
        //     }
        // })
    }

    render(){
        return(
            <Fragment>
                <h3> Test</h3>
                {
                    this.state.processList.map((process,ind) => 
                        <h3 key={ind}>{ process.name }</h3>
                    )
                }
            </Fragment>
        )
    }
}

export default InspectUpload;