import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from "../../globalhelper/helper";
// import Select from 'react-select';
import Datatable from "../../components/DataTable2";


class ControlTotal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        input : '',
        input_option : [],
        data : [],
        fetching : false,
        columns : [
            {
                name: 'Sales',
                selector: row => row["Sales"],
                sortable: true,
                width : '250px',
            },
            {
                name: 'Receipt',
                selector: row => row["Receipt"],
                sortable: true,
                width : '250px',
            },
            {
                name: 'TDS_Receivable',
                selector: row => row["TDS_Receivable"],
                sortable: true,
                width : '250px',
            },
            {
                name: 'Form26AS_AmtPaid',
                selector: row => row["Form26AS_AmtPaid"],
                sortable: true,
                width : '250px',
            },
            {
                name: 'Form26AS_TdsAmt',
                selector: row => row["Form26AS_TdsAmt"],
                sortable: true,
                width : '250px',
            },
        ]
      }
    }

    componentDidMount() {

        getRequest('api/tds/getTxnentity')
        .then(res => {
            let data = JSON.parse(res.res.data)
            console.log(data)

            let input_option = data.map( each => each.txnentity )

            this.setState({ input_option : input_option })
        })
        .catch(err => {
            console.log(err)
        })
    }

    
    getDATA = () => {
        
        this.setState( { fetching : true }, 
            () => {
                getRequest('api/tds/getControlTotal?input=' + this.state.input)
                .then(res => {
                    let data = JSON.parse(res.res.data)
                    console.log(data)
    
                    this.setState({ data : data, fetching : false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ fetching : false })
                })
            })
    }


    onInputChange = (e) => {
        this.setState({
            input: e.target.value,
        }, 
        () => this.getDATA()
        )
    }
            
  render() {
    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-2 mt-1'>
                <label htmlFor="input">Company</label>< br />
                    <select name="input" id="input" style={{width: '9rem', borderRadius: '20%', marginTop: '.3rem'}} value={this.state.input} onChange={this.onInputChange}>
                        <option value="" disabled>select</option>
                        {this.state.input_option.map( each => <option key={each} value={each}>{each}</option>)}
                    </select>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-12">
                    <Datatable columns={this.state.columns} title="Control Total" loading={this.state.fetching} key={this.state.data} data={this.state.data} ></Datatable>
                </div>
            </div>
        </Fragment>
    )
  }
}

export default ControlTotal;