import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import './FormBasicInput';
import {connect} from 'react-redux';
import './COD.css';
import './LARPopup.css';
import FormBasicInput from './FormBasicInput';
import { getHumanReadableDate, postRequest } from '../../globalhelper/helper'

export class RemittancePopup extends Component {
    constructor(props) {
      super(props)

      let value = this.props.item
    
      this.state = {
         formData: {
            client: value.client,
            model: value.businessmodel,
            zone: value.zone,
            stationcode: value.stationcode,
            date: value.createddate,
            remittanceref: value.remittanceref,
            amount: value.amount
         }
      }
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleBack = () => {
       this.props.handleBack()
    }

    handleUpdate = async (e) => {
        e.preventDefault()

        const {client, businessmodel, zone, stationcode, createddate} = this.props.item

        // const pk = this.props.item.pk
        // const sk = this.props.item.sk

        const data = {
            Client: client,
            BusinessModel: businessmodel,
            Zone: zone,
            StationCode: stationcode,
            "Created Date": getHumanReadableDate(createddate),
            RemittanceRef: this.state.formData.remittanceref,
            Amount: this.state.formData.amount
        }

        console.log(data)

        await postRequest(`api/cod/deleteRemittanceHistoryRecords`, {item: this.props.item}).then(response => {
            if(response.res.status === 'success'){
                postRequest(`api/cod/editRemittanceHistory`, data).then(res => {
                   this.props.closePopup({item: this.props.item, data})
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 10000)
                })
            } else {
                console.log('Record cannot be deleted')
            }
        }).catch(err => console.log(err))
    }

    render(){
        return (
            <Fragment>
                <div className='header_div'>Edit Remittance History</div>

                <br/>

                <div className='basic_outline'>

                    <FormBasicInput label='Client' value={this.state.formData.client} type='text' name='client' disabled={true}/>

                    <FormBasicInput label='Model' value={this.state.formData.model} type='text' name='model' disabled={true}/>

                    <FormBasicInput label='Zone' value={this.state.formData.zone} type='text' name='zone' disabled={true}/>

                    <FormBasicInput label='Station Code' value={this.state.formData.stationcode} type='text' name='stationcode' disabled={true}/>

                    <FormBasicInput label='Date' value={this.state.formData.date} name='date' type='date' disabled={true} />

                    <FormBasicInput label='Remittance Reference' value={this.state.formData.remittanceref} type='text' name='remittanceref' disabled={false} onChange={this.handleChange}/>

                    <FormBasicInput label='Amount' value={this.state.formData.amount} type='number' name='amount' disabled={false} onChange={this.handleChange}/>

                </div>

                <br/>

                <div style={{display:'inline-block'}}>
                    <button className='btn red_button' style={{marginLeft:500}} onClick={this.handleBack}>Back</button>
                    <button className='btn green_button' style={{marginLeft:10}} onClick={this.handleUpdate}>Update</button>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (RemittancePopup)