import React, { Component } from 'react'
import Tree from './tree_schema'
import { getRequest, postRequest } from '../../globalhelper/helper'
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export class Container extends Component {
  
  constructor(props) {
    super(props)
    this.currentState = this.props.state;
    this.userData = this.currentState.login.userData;  

    this.state = {
      listModuleOptions: [],
      listProcessOptions: [],
      themesInDynamo: [],
      themeData : null,
      fileData : null,
      mySchemasList : [],
      selectedSchemaCd : null,
      selectedSchemaDict : null,
      SCHEMA_TREE : null,
      newDefaultTree : [{
          "title": "Root",
          "technicalName": "Root",
          "children": [{
              "title": "SchemaGroupName",
              "technicalName": "SchemaGroupName",
              "val": ""
          }, {
              "title": "SchemaClass",
              "technicalName": "SchemaClass",
              "children": [{
                  "title": "#SchemaClass#1",
                  "technicalName": "#SchemaClass#1",
                  "children": [{
                      "title": "SchemaName",
                      "technicalName": "SchemaName",
                      "val": ""
                  }, {
                      "title": "DataSource",
                      "technicalName": "DataSource",
                      "val": ""
                  }, {
                      "title": "DateAggregationOn",
                      "technicalName": "DateAggregationOn",
                      "val": ""
                  }, {
                      "title": "SettlementType",
                      "technicalName": "SettlementType",
                      "val": ""
                  }, {
                      "title": "OutputProcessCode",
                      "technicalName": "OutputProcessCode",
                      "val": ""
                  }, {
                      "title": "Components",
                      "technicalName": "Components",
                      "children": [{
                          "title": "#Components#1",
                          "technicalName": "#Components#1",
                          "children": [{
                              "title": "ComponentName",
                              "technicalName": "ComponentName",
                              "val": ""
                          }, {
                              "title": "ModelID",
                              "technicalName": "ModelID",
                              "val": ""
                          }, {
                              "title": "Type",
                              "technicalName": "Type",
                              "val": ""
                          }, {
                              "title": "ComponentTyp[e",
                              "technicalName": "ComponentTyp[e",
                              "val": ""
                          }, {
                              "title": "ComponentExpr",
                              "technicalName": "ComponentExpr",
                              "val": ""
                          }, {
                              "title": "Criteria",
                              "technicalName": "Criteria",
                              "children": [{
                                  "title": "#Criteria#1",
                                  "technicalName": "#Criteria#1",
                                  "children": [{
                                      "title": "FordPrdFrom",
                                      "technicalName": "FordPrdFrom",
                                      "val": ""
                                  }, {
                                      "title": "ForPrdTo",
                                      "technicalName": "ForPrdTo",
                                      "val": ""
                                  }]
                              }]
                          }]
                      }]
                  }]
              }]
          }]
      }],
      formData : {
        branch :this.userData.USR_Organization,
        // process : "",
        process : "BT3I",
        company: this.userData.USR_Organization,
        module : localStorage.getItem("module"),
        theme : "",
      },

    }
  }

  componentDidMount() {

    const FORMDATA = this.state.formData
    
    // this.getModules()

    getRequest(`api/upload/getSchemaBT3File?process_code=${FORMDATA.process}`)
    .then(res => {
        let json = JSON.parse(res.res.data)            
       
        console.log(json)
        this.setState({ fileData : json})
    })

    // getRequest(`api/upload/getThemes?module=${FORMDATA.module}&process_code=${FORMDATA.process}`)
    // .then( res => {
    //   let themes = res.res.data
    //   console.log(themes, 'Themes')

    //   this.setState({ themesInDynamo : themes })

    // })

    getRequest(`api/upload/getMySchemas`)
    .then( res => {
      let mySchemasList = res.res.data
      console.log(mySchemasList, 'mySchemasList')

      this.setState({ mySchemasList : mySchemasList })

    })
  }

  getModules = () => {

    getRequest("api/module/modules?tenantId=" + this.userData.USR_Tenant)
    .then(response => {
        if(response.res.status === "success"){
          this.setState({
            ...this.state,
            listModuleOptions : response.res.data
          }, () => {
              this.GetProcess();
          })
        }
    })
  }

  onModuleChange = (e) => {
    console.log(e.target.value)
    this.setState({
        ...this.state,
        listProcessOptions: [],
        themesInDynamo: [],
        formData: {
            ...this.state.formData,
            module: e.target.value,
            process : '',
            theme : ''
        }
    }, () => {
        this.GetProcess();
    })
  
  }

  GetProcess = () => { 

    let module = this.state.formData.module;
    console.log(module);
    if(module !== "" && module !== null && module !== 'default1')
    {
        postRequest( 'api/module/moduleProcess', {
            tenantId : this.userData.tenant_id,
            moduleName : this.state.formData.module
        })
        .then(response => {
            if(response.res.status === "success")
            {
                this.setState({
                    ...this.state,
                    listProcessOptions : response.res.data
                },() => {
                })
            }
            else{
                this.setState({
                    alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
            }
        })
        .catch(err => {
                this.setState({
                    alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
        })
    }
  }

  onProcessCodeChange = (e) => {

    const VALUE = e.target.value
    console.log(VALUE)

    this.setState( 
      {  
          ...this.state,
          themesInDynamo : [],
          formData : {
              ...this.state.formData,
              process: VALUE,
              theme : ''
          }

      }, () => {
        const FORMDATA = this.state.formData;

        getRequest(`api/upload/getThemes?module=${FORMDATA.module}&process_code=${FORMDATA.process}`)
        .then( res => {
          let themes = res.res.data
          console.log(themes, 'Themes')

          this.setState({ themesInDynamo : themes })

        })
      })
  }


  onThemeChange = (e) => {

    const VALUE = e.target.value
    console.log(VALUE)

    this.setState( 
      {  
          ...this.state,
          formData : {
              ...this.state.formData,
              theme: VALUE,
          }
      }, () => {

        const FORMDATA = this.state.formData;

        getRequest(`api/upload/getThemeData?module=${FORMDATA.module}&process_code=${FORMDATA.process}&theme=${FORMDATA.theme}`)
        .then( res => {
          let themeData = res.res.data
          console.log(themeData, 'ThemeDATA')

          this.setState({ themeData : themeData })

        })

        // getRequest(`api/upload/getSchemaBT3File?process_code=${FORMDATA.process}`)
        // .then(res => {
        //     let json = JSON.parse(res.res.data)            
           
        //         console.log(json)
        //         this.setState({ fileData : json})
        // })

      })

  }

  schemaChange = (e, NEW) => {

    let val = e ? e.target.value : NEW

    this.setState( { 
      selectedSchemaCd : val,
      SCHEMA_TREE : null
    }, 
    () => {

      getRequest(`api/upload/getNewSchemaCode_OR_getSchemaInDict?schemaCode=${val}`)
        .then( res => {
          let resp = JSON.parse(res.res.data)
          console.log(resp, 'resp')

          this.setState({
            selectedSchemaDict : resp.MySchemaDict,
            SCHEMA_TREE : val !== 'new' ? resp.MySchemaDef : this.state.newDefaultTree
          })

        })

    })

  }

  saveSchemaDictionary = () => {

    postRequest(`api/upload/saveSchemaDictionary`, this.state.selectedSchemaDict)
      .then( res => {
        let resp = res.res.data
        console.log(resp, 'resp')

      })

  }


  render() {
    return (
      <div>

        <div className="row mt-0">

            {/* <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                  <InputLabel id="module">Module</InputLabel>
                  <Select labelId="module" id="module" value={ this.state.formData.module} onChange={this.onModuleChange} label="Select a Module"  >
                        { this.state.listModuleOptions.map( each => <MenuItem key={each.name} value={each.name}>{each.name.split("_").join(" ") + " [ " + each.desc +"] "}</MenuItem> )}
                  </Select>
                </FormControl>
            </div>

            <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="process_code">Select a Process</InputLabel>
                    <Select labelId="process_code" id="process_code" value={this.state.formData.process} onChange={this.onProcessCodeChange} label="Select a Process Code" >
                          { this.state.listProcessOptions.map( each => <MenuItem key={each.process_code} value={each.process_code}>{each.short_text} ({each.process_code})</MenuItem> )}
                    </Select>
                </FormControl>
            </div> */}

            {/* <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="process_code">Select a Theme</InputLabel>
                    <Select labelId="theme" id="theme" value={this.state.formData.theme} onChange={this.onThemeChange} label="Select a Theme" >
                          { this.state.themesInDynamo.map( each => <MenuItem key={each.theme} value={each.theme}>{each.theme}</MenuItem> )}
                    </Select>
                </FormControl>
            </div> */}

            <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="process_code">Select a Schema</InputLabel>
                    <Select labelId="theme" id="theme" value={ this.state.selectedSchemaCd } onChange={this.schemaChange} label="Select a Schema" >
                    {/* <MenuItem value="new" >Create New</MenuItem> */}
                          { this.state.mySchemasList.map( each => <MenuItem key={each.schemacode} value={each.schemacode}>{each.schemacode}</MenuItem> )}
                    </Select>
                </FormControl>
            </div>

            <div className="col-md-3 mt-4 ml-3">
              <button className='btn btn-primary' onClick={() => this.schemaChange(false, 'new')}>Create new</button>
            </div>

        </div>


        { this.state.SCHEMA_TREE ?

        <>

            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="schemacode">Schema Code:</label>
                  </div>
                  <div className="col-md-5">
                    <input type="text" name="schemacode" value={this.state.selectedSchemaDict.schemacode} disabled />
                  </div>
                </div>                
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="schemadesc">Schema Description:</label>
                  </div>
                  <div className="col-md-5">
                    <input type = "text" name = "schemadesc" value = {this.state.selectedSchemaDict.schemadesc} onBlur={this.saveSchemaDictionary}
                      onChange = {
                        (e) => this.setState({
                          ...this.state,
                          selectedSchemaDict : {
                            ...this.state.selectedSchemaDict,
                            schemadesc : e.target.value
                          }
                        })
                      }
                    />
                  </div>
                </div>  
              </div>
              {/* <div className="col-md-4">
                <button onClick={this.saveSchemaDictionary}>Save</button>
              </div> */}
            </div>

          <Tree 
            themeData={ this.state.themeData }
            fileData={ this.state.fileData }
            Schema_TREE= { this.state.SCHEMA_TREE }
            schemaCode= { this.state.selectedSchemaDict.schemacode }
          />

        </>
         : null
        } 


      </div>  
    )
  }
}

function mapStateToProps(state) {
  return {
      state
  }
}

export default connect(
  mapStateToProps
)(Container);

