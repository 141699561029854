import React, { Component } from 'react';
// import background from '../../assets/Audire_Login.svg';
import background_ from '../../assets/DataTwin First Page-1.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './resetPassword.css';
import ApiConstants from "../../config";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import '../../App.css';
import {Animated} from "react-animated-css";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { postRequest } from '../../globalhelper/helper'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import axios from 'axios'; 

class Confirmation extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            passwordType : 'password',
            retype_passwordType : 'password',
            password : '',
            retype_password : '',
            error : {
                password : '',
                retype_password : ''
            },
            conditionsMet : {
                minLength: false,
                hasUpperCase: false,
                hasLowerCase: false,
                hasNumbers: false,
                hasSpecialChars: false,
            }
        };
        // this.routeParam = this.props.location;
        var d = window.location.pathname.split('/');
        var a = d[2].split('=');
        this.token = a[1];

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    

      handleChange(event) {
        var id = event.target.id;
        if( id === "password"){
            this.isStrongPassword(event.target.value)
        }
        else{
            this.setState({
                ...this.state,
                [id]: event.target.value
            })
        } 
      }

      isStrongPassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /[0-9]/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        this.setState({
            ...this.state,
            password: password,
            conditionsMet : {
                ...this.state.conditionsMet,
                minLength: password.length >= 8,
                hasUpperCase: hasUpperCase,
                hasLowerCase: hasLowerCase,
                hasNumbers: hasNumbers,
                hasSpecialChars: hasSpecialChars
            }
        })
      
        if (password.length < minLength) {
          return "Password must be at least 8 characters long."
        }
        if (!hasUpperCase) {
          return "Password must contain at least one uppercase letter."
        }
        if (!hasLowerCase) {
          return "Password must contain at least one lowercase letter."
        }
        if (!hasNumbers) {
          return "Password must contain at least one number."
        }
        if (!hasSpecialChars) {
          return "Password must contain at least one special character."
        }
      
        return true;
      }

      TopErrAlert = (msg) => {
        this.setState({
            alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{msg}</strong></div> </Animated>
        });
        setTimeout(() => { this.setState({alert : null}); }, 3000);
      }
    
      handleSubmit = (event) => {
        event.preventDefault();

        let isStrongPass = this.isStrongPassword(this.state.password)
        let body = {
            password : this.state.password,
            retype_password : this.state.retype_password
        };
        if(body.password !== body.retype_password){
            this.TopErrAlert("Password and Retype Password Should be Same!")
        }
        else if(typeof isStrongPass === "string"){
            return this.TopErrAlert(isStrongPass)
        }
        else{
            axios.defaults.headers.common['authorization'] = this.token;
            axios.post(ApiConstants.externals.serverUrl + 'api/user/changePass',body ,{
                headers: { authorization: "Bearer " + this.token }
            })
            .then(response => {
                    if (response.data.status === "failed") {
                        this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{response.data.message}</strong></div> </Animated> });
                        setTimeout(() => { this.setState({alert : null}); }, 3000);
                    } else {
                        this.setState({alert : <div className="alert alert-success"><strong>{response.data.message}</strong></div> });
                        setTimeout(() => { this.setState({redirect : '/login'}); }, 3000);
                    }
            });            
        }
      }

      togglePassword = (type) => {
          if(type === 'password')
          {
            if(this.state.passwordType === 'password')
            {
              this.setState({ passwordType : 'text' });
            }
            else{
              this.setState({ passwordType : 'password' });
            }
          }
          else
          {
            if(this.state.retype_passwordType === 'password')
            {
              this.setState({ retype_passwordType : 'text' });
            }
            else{
              this.setState({ retype_passwordType : 'password' });
            }
          }
    }


    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div className="row">
                {this.state.alert}
                <div className="col-md-6 login-sub1">
                    <img src={background_} alt="bg" className="bg"/>
                </div>
                <div className="col-md-6 flex-screen" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '98vh'}}>
                    <form className="needs-validation" id="login" noValidate>
                        <div className="col-md-12 ">
                            <b> <h3 className="audire-header">DataTwin</h3> </b>
                    <br/><br/>
                        </div>
                        <div>
                            <div className="row">
                                <div className="input-group mb-3">
                                    <input type={this.state.passwordType} value={this.state.password} className={ this.state.error.password ? 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' + ' error' : 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' } id="password" placeholder="Password" onChange={ this.handleChange } required />
                                    <span className="input-group-append bg-white border-left-0 border-right-0 border-top-0">
                                        <span className="input-group-text bg-transparent border-left-0 border-right-0 border-top-0  login-textbox">
                                        { this.state.passwordType === 'password' ? <VisibilityIcon  style={{ fontSize : 17 }}  onClick={ () => this.togglePassword('password') } /> : <VisibilityOffIcon style={{ fontSize : 17 }}  onClick={ () => this.togglePassword('password') } /> }
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-group mb-3">
                                    <input type={this.state.retype_passwordType} value={this.state.retype_password} className={ this.state.error.password ? 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' + ' error' : 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' } id="retype_password" placeholder="Retype Password" onChange={ this.handleChange } required />
                                    <span className="input-group-append bg-white border-left-0 border-right-0 border-top-0">
                                        <span className="input-group-text bg-transparent border-left-0 border-right-0 border-top-0  login-textbox">
                                        { this.state.retype_passwordType === 'password' ? <VisibilityIcon style={{ fontSize : 17 }}   onClick={ () => this.togglePassword('retype_password') } /> : <VisibilityOffIcon style={{ fontSize : 17 }}   onClick={ () => this.togglePassword('retype_password') } /> }
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <br/>

                            <div className="row">
                                <div className="col-md-12">
                                    <button className="btn btn-purple" type="submit" onClick= { this.handleSubmit }>Submit</button>
                                </div>
                            </div> <br/>

                            <div className='row'>
                                <p>Password must meet the following conditions:</p>< br />
                            </div>
                            <div className='row' style={{textAlign: 'left'}}>
                            <ul>
                                <li style={{ color: this.state.conditionsMet.minLength ? 'green' : 'red' }}>
                                    At least 8 characters long
                                </li>
                                <li style={{ color: this.state.conditionsMet.hasUpperCase ? 'green' : 'red' }}>
                                    At least one uppercase letter
                                </li>
                                <li style={{ color: this.state.conditionsMet.hasLowerCase ? 'green' : 'red' }}>
                                    At least one lowercase letter
                                </li>
                                <li style={{ color: this.state.conditionsMet.hasNumbers ? 'green' : 'red' }}>
                                    At least one number
                                </li>
                                <li style={{ color: this.state.conditionsMet.hasSpecialChars ? 'green' : 'red' }}>
                                    At least one special character
                                </li>
                            </ul>
                            </div>

                        </div>
                    </form>
                </div>
                    
                {this.state.alert}
            </div>
        );
    }
}

export default Confirmation;
