import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import GetAppIcon from '@material-ui/icons/GetApp';
import { convertNumberIntoINR } from '../globalhelper/helper';
import ApiConstants from "../config.json";
import download from 'downloadjs';
import axios from 'axios'; 



function downloadResultFile ( s3Key, TenantID, fileName ) {
    let obj = {
        key : s3Key,
        tenant_id : TenantID
    }
    axios.post( ApiConstants.externals.serverUrl +'api/transactions/downloadS3FileWithKey', obj ,
    { responseType: 'blob' } )
    .then(result => {
        if(result)
        {
          const blob = result.data;
          download(blob, fileName + '.csv');
        }
        else{
          console.log("No such file found")
        }
    })
    .catch((error) => {
        console.log(error);
        console.log("No such file found")
    })
  }



function ActionsMemo(props){
    return (React.useMemo(() => <button type="button" className="btn btn-info" onClick={ () => downloadResultFile(props.s3Key, props.TenantID, props.fileName) }><GetAppIcon /></button>, []))
}

const paginationComponentOptions = {
    selectAllRowsItem : true
}

class DataTableS3Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns : this.props.columns,
            data : this.props.data,
        }
    }

    componentDidMount = () => {
        let {columns} = this.state;
        columns.map((column, index) => {
            column.wrap = column.wrap ? column.wrap : true;
            column.compact = true;
            column.row = (row) => row[column.name] ? row[column.name] : " ";
            if(column.isNumber) {
                column.row = (row) => <p className='number-format' > {row[column.name]} </p>
                column.right = true;
            }

            if(column.isAmount)
            {
                column.row = (row) => <p className='number-format' > { convertNumberIntoINR(row[column.name])} </p>
                column.right = true;
            }
            if(index === columns.length - 1) {
                this.setState({
                    columns : columns
                })
            }

        })
    }

    render() { 
        return (
            <DataTable title={this.props.title} fixedHeader fixedHeaderScrollHeight="65vh" columns={this.state.columns} data={this.props.data} pagination paginationComponentOptions={paginationComponentOptions}  progressPending={this.props.loading} actions={ this.props.data.length > 0 ? <ActionsMemo s3Key={this.props.s3Key} TenantID={this.props.TenantID} fileName={this.props.title} /> : null} />
        );
    }
}
 
export default DataTableS3Download;