import React, { Component } from 'react';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class ExceptionReport extends Component {
  render() {
    return (
      <VR2_Forms
      screenName = 'EXC_Screen'
      screenAction = 'create'
      />
    )
  }
}

export default ExceptionReport