import _ from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { addChildren, changeFormValues, initialState, add_UJ_JSON } from '../../actions/dynamic_forms_action';
import SelectBox from './SelectBox';
import { navUrl } from '../../actions/common_actions'

import { getRequest, postRequest } from '../../globalhelper/helper'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import VendorMaster_Search from '../../pages/Edureka/VendorMaster_Search'
import PurchaseOrder_Search from '../../pages/Edureka/PO_Search'

import ItemMaster_Search from '../../pages/Edureka/ItemMaster_Search'
import InternalPlan_Search from '../../pages/Edureka/InternalPlan_Search'
import Organization_Search from '../Edureka/Organization_Search';
import BillExtract_Search from '../Edureka/BillExtract_Search';
import CostAllocation_Search from '../Edureka/CostAllocation_Search';

 
import data from './sample2.json';
import VendorItemInfoSearch from '../Edureka/VendorItemInfoSearch';
import GoodsAndServicesSearch from '../Edureka/GoodsAndServicesSearch';
import ExtendVendorMaster from '../Edureka/ExtendVendorMaster';
import ExtendItemMaster from '../Edureka/ExtendItemMaster';


class RecursiveBuilder extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            render: false,
            recursiveData : [],
            formValues : {}
        }
    }

    componentDidMount =() => {

        this.start()
    }

    start = () => {
        this.props.initialState()

        getRequest(`api/vr2/get_uj_json?module=${localStorage.getItem("module")}`)
        .then( res => {

            if( res.res.status === "success"){
                console.log(res.res.data)

                this.props.add_UJ_JSON(res.res.data)

                this.props.addChildren(null)

                this.setState({render: true}, () => {
                    console.log(this.props.UJ_JSON, 'kkkkkkkkkkkkkkk')
                })
            }
        })
    }
  

    updateFormValue = (event, id) => {
        let name = event.target.name, value = event.target.value;

        let formValues = this.state.formValues

        formValues[name] = value

        this.props.changeFormValues(formValues, id);
        this.setState({ formValues })

        let screen = _.filter(this.props.UJ_JSON, item => item.type === "Change_Screen" && item.value === value);

        console.log(screen, 'Change_Screen')


        if(screen.length && screen.length === 1) {

            this.setState({
                screenInfo: {
                    redirect: screen[0].nav_to,
                    screenName: screen[0].screenName,
                    screenAction: screen[0].action
                }
            })
        
        }
    }

    onBack = () => {
        this.start()
        this.setState({
            screenInfo: {
                redirect: null,
                screenName: null,
                screenAction: null
            },
            render: false,
            recursiveData : [],
            formValues : {}
        })
    }


    render() {
        const formValues = this.state.formValues

        if(this.state.screenInfo && this.state.screenInfo.redirect === "VR2_Forms") {
            return (
                <VR2_Forms
                screenName = {this.state.screenInfo.screenName}
                screenAction = {this.state.screenInfo.screenAction} 
                onBack = {this.onBack}     
                />
            )
        }
        else if(this.state.screenInfo && this.state.screenInfo.redirect === "VendorMaster") {

            return (
                <VendorMaster_Search />
            )
        }
        else if(this.state.screenInfo && this.state.screenInfo.redirect === "PurchaseOrder") {

            return (
                <PurchaseOrder_Search />
            )
        }
        else if(this.state.screenInfo && this.state.screenInfo.redirect === "ItemMaster") {

            return (
                <ItemMaster_Search />
            )
        }
        else if(this.state.screenInfo && this.state.screenInfo.redirect === "InternalPlan") {

            return (
                <InternalPlan_Search />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "OrganizationMaster") {

            return (
                <Organization_Search />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "BillExtract") {

            return (
                <BillExtract_Search />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "CostAllocationMaster") {

            return (
                <CostAllocation_Search />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "VendorItemInfo") {

            return (
                <VendorItemInfoSearch />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "GoodsAndServicesEntries") {

            return (
                <GoodsAndServicesSearch />
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "VendorMasterExtend") {
            return (
                <ExtendVendorMaster/>
            )
        }

        else if(this.state.screenInfo && this.state.screenInfo.redirect === "ItemMasterExtend") {
            return (
                <ExtendItemMaster/>
            )
        }
        if( this.state.render && this.props.UJ_JSON ){
            return (
                <div>
                    {/* <h3> Recursive Builder </h3> <br/> */}
                    { this.props.UJ_JSON.map((obj, key) => {
                        {console.log('comming')}
                        if(obj.active)
                        {
                            if(obj.type === "input")
                            {
                                return (
                                    <Fragment key={key}>
                                        <label htmlFor={obj.serviceKey} > {obj.label} </label> <br/>
                                        <input name={obj.serviceKey} style={obj.inputOptions.css} id={obj.serviceKey} value={formValues[obj.serviceKey]} onChange={(ev) => this.updateFormValue(ev, obj.id)} type={obj.inputOptions.type} onBlur={() => this.props.addChildren(obj.id)} /> <br/>
                                    </Fragment>
                                )
                            }
                            else if(obj.type === "select")
                            {
                                return(
                                    
                                    <SelectBox {...obj} value={formValues[obj.serviceKey]} onChange={(ev, id) => {this.updateFormValue(ev, id); this.props.addChildren(obj.id);}}/>
                                )
                            }
                            else{
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    ) }
                </div>
            );
        }

        else {
            return (<div>Loading......</div>)
        }

    }
}


function mapStateToProps(state) {
    return {
        UJ_JSON: state.Dynamic_Forms_Reducer.UJ_JSON,
        formValues: state.Dynamic_Forms_Reducer.formValues
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addChildren: (parent_id) => {
            dispatch(addChildren(parent_id))
        },
        changeFormValues : (key, value) => {
            dispatch(changeFormValues(key, value))
        },
        initialState : (data) => {
            dispatch(initialState(data))
        }
    }
}

// export default connect(
//     mapStateToProps, mapDispatchToProps
// )(RecursiveBuilder);

export default connect(
    mapStateToProps,
    { addChildren, changeFormValues, initialState, add_UJ_JSON, navUrl }
)(RecursiveBuilder);
