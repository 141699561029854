import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import './CollapsibleSection.css'; // Import the CSS file for the component

const Table = ({ title, data, uniqueId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef = useRef(title);

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.keyCode === 38 && selectedRow > 0) {
  //       e.preventDefault();

  //       setSelectedRow((prev) => prev - 1);
  //     } else if (e.keyCode === 40 && selectedRow < data.length - 1) {
  //       e.preventDefault();

  //       setSelectedRow((prev) => (prev === null ? 0 : prev + 1));
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [selectedRow, data.length]);


  // useEffect(() => {
  //   const table = tableRef.current;
  //   if (table && selectedRow !== null) {
  //     const rows = table.getElementsByTagName('tr');
  //     const selectedRowElement = rows[selectedRow];
  //     if (selectedRowElement) {
  //       const top = selectedRowElement.offsetTop;
  //       const tableHeight = table.offsetHeight;
  //       const scroll = table.scrollTop;
  //       if (top < scroll) {
  //         table.scrollTop = top;
  //       } else if (top > scroll + tableHeight - selectedRowElement.offsetHeight) {
  //         table.scrollTop = top - tableHeight + selectedRowElement.offsetHeight;
  //       }
  //     }
  //   }
  // }, [selectedRow]);

  // useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 38 && selectedRow > 0) {
        e.preventDefault();
        setSelectedRow((prev) => prev - 1);
      } else if (e.keyCode === 40 && selectedRow < data.length - 1) {
        e.preventDefault();
        setSelectedRow((prev) => (prev === null ? 0 : prev + 1));
      }
      scrollSelectedRowIntoView();
    };
    

    const scrollSelectedRowIntoView = () => {
      console.log(tableRef)
      if (tableRef.current && selectedRow !== null) {
        const row_up = tableRef.current.querySelector(`tr:nth-child(${selectedRow - 1})`);
        const row_down = tableRef.current.querySelector(`tr:nth-child(${selectedRow + 2})`);

        const row = tableRef.current.querySelector(`tr:nth-child(${selectedRow + 1})`);

        if (row) {
          const tableRect = tableRef.current.getBoundingClientRect();
          
          if(row_up){
            const rowRect_up = row_up.getBoundingClientRect();

            if (rowRect_up.top < tableRect.top) {
              // Row is above the visible area, scroll up
              console.log(rowRect_up.top - tableRect.top)
              tableRef.current.scrollTop += rowRect_up.top - tableRect.top;
            } 
          }

          if(row_down){
            const rowRect_down = row_down.getBoundingClientRect();

            if (rowRect_down.bottom > tableRect.bottom) {
              // Row is below the visible area, scroll down
              console.log(rowRect_down.bottom - tableRect.bottom)
              tableRef.current.scrollTop += rowRect_down.bottom - tableRect.bottom;
            }
          }
          
        }
      }
    };

  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [selectedRow, data.length]);


  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const headings = data.length ? Object.keys(data[0]) : [];

  return (
    <div onKeyDown={handleKeyDown} className="mt-2 mb-2" key={uniqueId}>
      <div className={`collapsible-section ${isOpen ? 'open' : 'closed'}`}>
        <div
          onClick={toggleSection}
          style={{
            background: '#e4e4e4',
            textAlign: 'center',
            padding: '.6rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </div>
        <div className={`scroll-table ${title}`} tabIndex="0" ref={tableRef}>
          <table id="table-css">
            <thead>
              <tr>
                {headings.map((heading, index) => (
                  <th key={index}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => handleRowClick(rowIndex)}

                  style={selectedRow === rowIndex ? { backgroundColor: 'lightblue' } : null}
                >
                  {headings.map((heading, cellIndex) => (
                    <td key={cellIndex} style={{
                      maxWidth: '100px', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }
                    }>{item[heading]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(Table);
