import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getRequest, postRequest } from '../../globalhelper/helper'
import { useDispatch } from 'react-redux';
import { navUrl } from '../../actions/common_actions';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CreditCard_Upload() {
    

    const dispatch = useDispatch();

    const navPage = (url) => {
        dispatch(navUrl(url));
    };
   

    const fileInputOnChange = (e) => {

        console.log(e.target)

        let file = e.target.files[0]

        if(file){

            const formData = new FormData();
            formData.append('file', file);

            postRequest(`api/upload/CreditCardUpload`, formData)
            .then( res => {

                console.log(res)
                
                // navPage('/transactions')
            })
        }
    }


  return (
    <div>
        
        <div className='row mt-2'>
                      
            <div className='col-md-2'>

                <label style={{fontSize: '1rem'}}> Credit Card Upload </label>
            
                <Button component="label" variant="contained" sx={{ marginTop: 3 }} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput type="file" accept='.pdf' onChange={fileInputOnChange} />
                </Button>
            
            </div>

        </div>
          
    </div>
  )
}

