import { ADD_CHILDREN, CHANGE_FORM_VALUES, INITIAL } from "../actions/dynamic_forms_action";

const initialState = {
    UJ_JSON : [],
    formValues : {}
}

export default function Dynamic_Forms_Reducer (state = initialState, action) {
    switch (action.type) {
        case INITIAL : 
            return{
                ...state, 
                formValues : {},
                UJ_JSON : []
            }
        case ADD_CHILDREN : 
            return{
                ...state,
                UJ_JSON : action.UJ
            }
        case CHANGE_FORM_VALUES:
            return {
                ...state,
                formValues : action.formValues
            }
            default:
                return state
    }
}