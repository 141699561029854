import '../../CI/COD.css'
import { Form } from 'react-bootstrap'

export const Select = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}