import React, { Component } from 'react';
import './COD.css';
import Alert from '@mui/material/Alert'
import {getRequest, postRequest, getHumanReadableDate, convertToINR, getDateDuration} from '../../globalhelper/helper';
import {Form, Button} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Pagination1 from './Pagination';

export class DocumentWiseComparison extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            startdate: '',
            enddate: ''
        },
        error: '',
        tableData: [],
        show_filtertable: [],
        show_maintable: [],
        fetching: false,
        showtable: false,
        showmenu: false,
        allData: [],
        dropdown_client: [],
        dropdown_model: [],
        dropdown_zone: [],
        dropdown_stationcode: [],
        unique_stationcode: [],
        downloadableData: [],
        filterTable: [],
        postsPerPage: 15,
        currentPage: 1,
        postsPerPage1: 15,
        currentPage1: 1,
        tableDisplay: [],
        filterDisplay: [],
        show_date: false
      }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            fetching: true
        })

        getRequest(`api/cod/documentComparison`).then(response => {
            let tableData = response.res.data
            console.log(tableData)
            let client = []
            let stationcode = []
            tableData.forEach(item => {
                client.push(item.client)
            })
            tableData.forEach(item => {
                stationcode.push(item.stationcode)
            })

            let temp = [... new Set(client)]
            let temp1 = [... new Set(stationcode)]
            this.setState({
                ...this.state,
                allData: tableData,
                dropdown_client: temp,
                dropdown_stationcode: temp1,
                unique_stationcode: temp1,
                fetching: false,
                show_maintable: true
            }, () => this.handlePagination())
        })

    }

    handleClientChange = (event) => {
        const VALUE = event.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                client: VALUE
            }
        })

        let arrModel = []

        this.state.allData.forEach((item,index)=> {
            if(item.client === VALUE){
                arrModel.push(item.businessmodel)
                return arrModel
            }
        })

            let arr1 = [...new Set(arrModel)]
            this.setState({
                ...this.state,
                dropdown_model: arr1,
                dropdown_zone: [],
                dropdown_stationcode: [],
                formData: {
                    ...this.state.formData,
                    model: '',
                    stationcode: '',
                    zone: '',
                    client: VALUE
                }  
            }, () => {
                this.handleClientFilter()
                console.log(this.state.dropdown_model)
            })
        
    }

    handleClientFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    handleModelChange = (event) => {
        const VALUE = event.target.value

        const client = this.state.formData.client

        this.setState({
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.allData.forEach((item) => {
            if(item.client === client && item.businessmodel === VALUE){
                arrZone.push(item.zone)
                return arrZone
            }
        })

            let arr2 = [...new Set(arrZone)]

            this.setState({
                ...this.state,
                dropdown_zone: arr2,
                dropdown_stationcode: [],
                formData: {
                    ...this.state.formData,
                    stationcode: '',
                    zone: '',
                    model: VALUE
                }
            }, () => this.handleModelFilter())
        
    }

    handleModelFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                stationcode.push(item.stationcode)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                stationcode: '',                
                zone: VALUE
            }
        }, () => this.handleZoneFilter())
    }

    handleZoneFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    handleStationChange = (event) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: event.target.value
            }
        }, () => this.handleStationFilter())
    }

    handleStationFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.stationcode === this.state.formData.stationcode){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    handleChange = (event) => {
        const NAME = event.target.name
        const VALUE = event.target.value

        this.setState({
            ...this.state,
            error: '',
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleEndDateChange = (event) => {
        const NAME = event.target.name
        const VALUE = event.target.value 

        if(VALUE < this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'End date must be greater than start date',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else if (!this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'Please select start date first',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        }  else {
            this.setState({
                ...this.state,
                error: '',
                formData: {
                    ...this.state.formData,
                    [NAME]: VALUE
                }
            })
        }
    }

    handleFilter = async () => {
        const stationcode = this.state.formData.stationcode
        const client = this.state.formData.client
        // console.log(stationcode, client)
        await getRequest(`api/cod/documentComparisonFilter?stationcode=${stationcode}&startdate=${this.state.formData.startdate}&enddate=${this.state.formData.enddate}&client=${client}`).then(response => {
           if(response.res.status === "success"){
            this.setState({
                ...this.state,
                show_filtertable: true,
                show_maintable: false,
                currentPage1: 1,
                show_date: true,
                filterTable: response.res.data
            }, () => this.handlePagination1())
           } 
        }) 
    }

    handleDownload = async () => {
        if(this.state.filterTable.length){
            this.setState({
                ...this.state
            }, () => {
                this.downloadLink.link.click()
            })
        } else {
            await getRequest(`api/cod/documentComparison`).then(response => {
                if(response.res.status === "success"){
                 this.setState({
                     ...this.state,
                     downloadableData: response.res.data
                 }, () => {
                    this.downloadLink.link.click()
                 })
                } 
             }) 
        }
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            error: '',
            show_filtertable: false,
            show_maintable: true,
            dropdown_model: [],
            dropdown_zone: [],
            show_date: false,
            filterTable: [],
            dropdown_stationcode: this.state.unique_stationcode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                startdate: '',
                enddate: ''
            }
        })
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        console.log(this.state.allData)
        let currentPosts = this.state.allData.sort((a,b)=>b.depositdate?b.depositdate.localeCompare(a.depositdate):null).slice(indexofFirstPost, indexOfLastPost)
        let temp = this.state.allData.sort((a,b) => {
            const dateA = new Date(a.depositdate || a.remittancedate || '1970-01-01')
            const dateB = new Date(b.depositdate || b.remittancedate || '1970-01-01')
            return dateB - dateA
        }).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: temp
        })
    }

    paginate1 = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    handlePagination1 = () => {
        let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
        console.log(this.state.allData)
        let currentPosts = this.state.filterTable.sort((a,b)=>b.depositdate?b.depositdate.localeCompare(a.depositdate):null).slice(indexofFirstPost, indexOfLastPost)
        let temp = this.state.filterTable.sort((a,b) => {
            const dateA = new Date(a.depositdate || a.remittancedate || '1970-01-01')
            const dateB = new Date(b.depositdate || b.remittancedate || '1970-01-01')
            return dateB - dateA
        }).slice(indexofFirstPost, indexOfLastPost)
        console.log(currentPosts)
        this.setState({
            ...this.state,
            filterDisplay: temp
        })
    }

    paginate2 = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage1: pageNumber
        }, () => this.handlePagination1())
    }
    
  render() {
    return (
      <div>
        <div className='header_div'>
            Station Daywise Detailed COD Report
        </div>

        {!this.state.fetching && this.state.allData.length > 0  ? 
        <div>
            <div>
                <Button variant='success' style={{marginLeft:10}} className='pull-right' onClick={this.handleDownload}>Download</Button>
                <CSVLink data={this.state.filterTable.length ? this.state.filterTable : this.state.downloadableData} filename='DocumentWise Comparison.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
            </div>
            <div>
                <Button variant='danger' className='pull-right' onClick={this.handleReset}>Reset Filter</Button>
            </div>

        </div> : null}

        <br/>
        <br/>

        {!this.state.fetching && this.state.allData.length > 0 ?
        
        <div className='row'>
            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Client</Form.Label>
                    <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_client.sort().map((item,index)=><option key={item} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_model.sort().map((item,index)=><option key={item} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_zone.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_stationcode.sort().map((item,index)=><option key={item} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Start date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.startdate} name='startdate' onChange={this.handleChange}></input>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>End date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.enddate} name='enddate' onChange={this.handleEndDateChange}></input>
                </Form.Group>
                {this.state.error && <div style={{color: 'red'}}>{this.state.error}</div>}
            </div>

           
        </div> :null}

        {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}

        
        {!this.state.fetching && this.state.allData.length > 0 ?
        
        <div className='wrapper'>
            <Button onClick={this.handleFilter} disabled={!this.state.formData.enddate}>Filter</Button>
        </div>: null}


        {/* {!this.state.fetching && this.state.allData.length > 0 ?
        
        <div className='wrapper'>
            <Button variant='warning' onClick={this.handleDownload}>Download</Button>
            <CSVLink data={this.state.downloadableData} filename='DocumentWise Comparison.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
        </div>: null} */}
        
        <br/>

        {this.state.show_maintable && this.state.allData.length > 0?
        
        
        <div>
            {/* {!this.state.show_date ?
            <span className='pull-right'>{<span>{<Alert severity='warning'><strong>{getDateDuration()}</strong></Alert>}</span>}</span> : null } */}
            <br/>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th style={{minWidth: 90}}>Deposit Date</th>
                        <th style={{minWidth: 90}}>Remittance Date</th>
                        <th>Deposit Reference</th>
                        <th>Remittance Reference</th>
                        <th>Amount Collected</th>
                        <th>Amount Deposited</th>
                        <th>Amount Pending</th>
                        <th>Cumulative Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.tableDisplay.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{item.depositdate?getHumanReadableDate(item.depositdate): null}</td>
                                <td>{item.remittancedate?getHumanReadableDate(item.remittancedate): null}</td>
                                <td>{item.depositref}</td>
                                <td>{item.remittanceref}</td>
                                <td>{item.amountcollected?convertToINR(item.amountcollected): null}</td>
                                <td>{item.amountdeposited?convertToINR(item.amountdeposited): null}</td>
                                <td>{item.amountpending?convertToINR(item.amountpending): null}</td>
                                <td>{item.cumulativeamount?convertToINR(item.cumulativeamount) : null}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: null}

        {this.state.show_maintable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.allData.length} paginate={this.paginate1}></Pagination1>
        </div>: null}

        {this.state.show_filtertable && this.state.filterTable.length > 0?
        
        <div>
            {/* {!this.state.show_date ?
            <span className='pull-right'>{<span>{<Alert severity='warning'><strong>{getDateDuration()}</strong></Alert>}</span>}</span> : null } */}
            <br/>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th style={{minWidth: 90}}>Deposit Date</th>
                        <th style={{minWidth: 90}}>Remittance Date</th>
                        <th>Deposit Reference</th>
                        <th>Remittance Reference</th>
                        <th>Amount Collected</th>
                        <th>Amount Deposited</th>
                        <th>Amount Pending</th>
                        <th>Cumulative Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.filterDisplay.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{item.depositdate?getHumanReadableDate(item.depositdate): null}</td>
                                <td>{item.remittancedate?getHumanReadableDate(item.remittancedate): null}</td>
                                <td>{item.depositref}</td>
                                <td>{item.remittanceref}</td>
                                <td>{item.amountcollected?convertToINR(item.amountcollected): null}</td>
                                <td>{item.amountdeposited?convertToINR(item.amountdeposited): null}</td>
                                <td>{item.amountpending?convertToINR(item.amountpending): null}</td>
                                <td>{item.cumulativeamount?convertToINR(item.cumulativeamount) : null}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>:null}

        {this.state.show_filtertable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage1} totalPosts={this.state.filterTable.length} paginate={this.paginate2}></Pagination1>
        </div>       
        : null }

        {this.state.allData.length <= 0 && !this.state.fetching? <div className='center-screen'>
            <h5>No Data Found</h5>
        </div>: null}
        
      </div>
    )
  }
}

export default DocumentWiseComparison