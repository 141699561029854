const tableData=[
  {
    "firstName": "divya",
    "lastName": 123,
    "age": "24",
    "bloodGroup": "Hello World",
    "dob": "13-09-1993",
    "phoneNumber": "9600142537",
    "email": "divya@gmail.com",
    "address1": "123",
    "address2": "#82b92c",
    "city": "Hello World",
    "region": "new",
    "zipCode": "123",
    "accountNo": "#82b92c",
    "bankName": "Hello World",
    "branch": "tnagar",
    "ifscCode": "123",
    "accountType": "#82b92c",
    "customerId": "Hello World"
  },
  {
    "firstName": null,
    "lastName": 123,
    "age": "#82b92c",
    "bloodGroup": "Hello World",
    "dob": null,
    "phoneNumber": 123,
    "email": null,
    "address1": 123,
    "address2": "#82b92c",
    "city": "Hello World",
    "region": null,
    "zipCode": 123,
    "accountNo": "#82b92c",
    "bankName": "Hello World",
    "branch": null,
    "ifscCode": 123,
    "accountType": "#82b92c",
    "customerId": "Hello World"
  },
  {
    "firstName": null,
    "lastName": 123,
    "age": "#82b92c",
    "bloodGroup": "Hello World",
    "dob": null,
    "phoneNumber": 123,
    "email": null,
    "address1": 123,
    "address2": "#82b92c",
    "city": "Hello World",
    "region": null,
    "zipCode": 123,
    "accountNo": "#82b92c",
    "bankName": "Hello World",
    "branch": null,
    "ifscCode": 123,
    "accountType": "#82b92c",
    "customerId": "Hello World"
  },
  {
    "firstName": null,
    "lastName": 123,
    "age": "#82b92c",
    "bloodGroup": "Hello World",
    "dob": null,
    "phoneNumber": 123,
    "email": null,
    "address1": 123,
    "address2": "#82b92c",
    "city": "Hello World",
    "region": null,
    "zipCode": 123,
    "accountNo": "#82b92c",
    "bankName": "Hello World",
    "branch": null,
    "ifscCode": 123,
    "accountType": "#82b92c",
    "customerId": "Hello World"
  },
  {
    "firstName": null,
    "lastName": 123,
    "age": "#82b92c",
    "bloodGroup": "Hello World",
    "dob": null,
    "phoneNumber": 123,
    "email": null,
    "address1": 123,
    "address2": "#82b92c",
    "city": "Hello World",
    "region": null,
    "zipCode": 123,
    "accountNo": "#82b92c",
    "bankName": "Hello World",
    "branch": null,
    "ifscCode": 123,
    "accountType": "#82b92c",
    "customerId": "Hello World"
  }
]

export default tableData;