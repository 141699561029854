import {
  FETCH_PROCESS_ARRAY_REQUEST,
  FETCH_PROCESS_ARRAY_SUCCESS,
  FETCH_PROCESS_ARRAY_FAILURE,
} from "./processArrayTypes";

import axios from "axios";
import queryString from "query-string";
import {
  postRequest
} from "../../../globalhelper/helper";

export const fetchProcessArray = (query) => {
  return async (dispatch) => {
    dispatch(fetchProcessArrayRequest());
    try {
      // const response = await axios({
      //   method: "POST",
      //   url: "https://audiresb.oneintegral.com/backend/api/module/moduleProcess",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     Authorization: process.env.REACT_APP_TOKEN,
      //   },
      //   data: queryString.stringify(
      const BODY = {
        tenantId: localStorage.getItem("tenant_id"),
        moduleName: query.split(" ").join("_"),
      }
      // ),
      // });
      postRequest('api/module/moduleProcess', BODY).then(response => {
        const data = response.res.data;
        // console.log(data, 'Module Process')
        dispatch(fetchProcessArraySuccess(data));
        return data;
      })
    } catch (error) {
      dispatch(fetchProcessArrayFailure(error));
    }
  };
};

export const fetchProcessArrayRequest = () => {
  return {
    type: FETCH_PROCESS_ARRAY_REQUEST,
  };
};

export const fetchProcessArraySuccess = (data) => {
  return {
    type: FETCH_PROCESS_ARRAY_SUCCESS,
    payload: data,
  };
};

export const fetchProcessArrayFailure = (error) => {
  return {
    type: FETCH_PROCESS_ARRAY_FAILURE,
    payload: error,
  };
};