import React, { Fragment, Component } from "react";
import { getHumanReadableDateTime, getRequest, postRequest } from "../../globalhelper/helper";
import Select from 'react-select';
import Datatable from "../../components/DataTable2";

class ReverseRecom extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData :{
                txnentity : '',
                tan : '',
                txnentity_obj : '',
                tan_obj : ''
            },
            columns : [
                {
                    name: 'Txn. ID',
                    selector: row => row.TransactionID,
                    sortable: true,
                    width : '280px'
                },
                {
                    name : "Status",
                    selector : row => <div style={{ alignContent : 'center', overflow : 'unset'}}>
                        <div className="row" align="center">
                            <div className="col-md-12">
                                <span className={ row.Status === "Finished" ? "batch warning alert-success" : "batch warning alert-warning"} > {row.Status} </span>
                            </div>
                        </div>
                    </div>,
                    sortable : true,
                    width : '200px'
                },
                {
                    name: 'Counts',
                    selector: row => <div style={{ alignContent : 'center', overflow : 'unset'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <span title="Success" className={"batch warning alert-success"} > {row.Success} </span>
                            {/* </div>
                            <div className="col-md-3"> */}
                                <span title="Failed" className={"batch warning alert-error"} > {row.Failed} </span>
                            {/* </div>
                            <div className="col-md-3"> */}
                                <span title="Total" className={ "batch warning alert-info"} > {row.Total} </span>
                            </div>
                        </div>

                    </div>,
                    width : '200px'
                },
                {
                    name: 'Date & Time',
                    selector: row => <div style={{ alignContent : 'center', overflow : 'unset'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <span title="Start" className="batch warning alert-warning"> {getHumanReadableDateTime(row.Started)} </span>
                            {/* </div>
                            <div className="col-md-12"> */}
                                <span title="End" className="batch warning alert-info"> {getHumanReadableDateTime(row.Started)} </span>
                            </div>
                        </div>
                    </div>,
                    sortable: true,
                    width : '380px'
                },
            ],
            fetching : false,
            txnEntityList : [],
            tanList : [],
            txnList : [],
         };
    }

    getTxnEntityList = () => {
        getRequest('api/tds/getTxnEntityList').then(res => {
            if(res.type === 'success') {
                this.setState({
                    txnEntityList : JSON.parse(res.res.data)
                });
            }
        });
    }

    handleChange = (ev) => {
        let { formData } = this.state;
        let NAME = ev.target.name, VALUE = ev.target.value;
        console.log(NAME, VALUE)
        formData[NAME] = VALUE;
        if(NAME === 'txnentity_obj'){
            formData['txnentity'] = VALUE.value;
        }
        if(NAME === 'tan_obj'){
            formData['tan'] = VALUE.map(tan => tan.value).join(',');
        }
        this.setState({
            ...this.state,
            formData
        }, () => {
            if(NAME === "txnentity_obj")
            {
                this.getTansListForTxnEntity(this.state.formData.txnentity);
            }
        });
    }

    getTansListForTxnEntity = () => {
        getRequest('api/tds/getReverseRecomTansForEntity?txnentity=' + this.state.formData.txnentity).then(res => {
            if(res.type === 'success') {
                this.setState({
                    tanList : JSON.parse(res.res.data)
                });
            }
        });
    }

    getTxnList = () => {
        this.setState({
            ...this.state,
            fetching : true 
        }, () => {

            getRequest('api/tds/getTxnList?PK=REVERSERECOM').then(res => {
                if(res.type === 'success') {
                    this.setState({
                        txnList : JSON.parse(res.res.data),
                        fetching : false,
                    });
                }
            });
        })
    }

    componentDidMount = () => {
        this.getTxnEntityList();
        this.getTxnList();
    }

    reverseRecom = () => {
        const BODY = {
            tan : this.state.formData.tan,
            txnentity : this.state.formData.txnentity
        }
        postRequest('api/tds/reverseAllRecoms', { data : JSON.stringify(BODY)}).then(res => {
            if(res.type === 'success') {
                window.location.reload();
            }
        });

    }

    render() { 
        return (  
            <Fragment>
                <form >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Txn Entity</label>
                                <Select
                                    value={this.state.formData.txnentity_obj}
                                    onChange={(value) => this.handleChange({ target : { name : 'txnentity_obj', value } })}
                                    options={this.state.txnEntityList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>TAN</label>
                                <Select
                                    value={this.state.formData.tan_obj}
                                    isMulti
                                    onChange={(value) => this.handleChange({ target : { name : 'tan_obj', value } })}
                                    options={this.state.tanList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary" style={{ marginTop : 33 }} disabled={this.state.formData.txnentity == ""} onClick={this.reverseRecom} >Execute</button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <Datatable columns={this.state.columns} title="Transactions" loading={this.state.fetching} key={this.state.txnList} data={this.state.txnList} ></Datatable>
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
export default ReverseRecom;