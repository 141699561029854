import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper';
import Select from 'react-select';

class GetPredictions extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            errcodes : [],
            formData : {
                errcode_obj : '',
                errcodes : ''
            },
            recom : [],
            showRecom : false,
            fetching : false
         }
    }

    componentDidMount = () => {
        getRequest('api/cms/getAllErrCodesSB').then(res => {
            this.setState({
                errcodes : JSON.parse(res.res.data)
            })

        })
    }

    
    handleChange = (ev) => {
        const { formData } = this.state;
        const NAME = ev.target.name, VALUE = ev.target.value;
        formData[NAME] = VALUE;
        if(NAME === 'errcode_obj'){
            formData['errcodes'] = VALUE.map(item => item.value).join(',');;
        }
        this.setState({ formData });
    }

    onGetRecom = () => {
        this.setState({
            ...this.state,
            fetching : true,
            showRecom : false
        }, () => {
            const BODY = {
                errcodes : this.state.formData.errcodes
            }
            postRequest('api/cms/getPredictionOutput', { data : JSON.stringify(BODY)}).then(res => {
                if(res.type === 'success') {
                    this.setState({
                        recom : JSON.parse(res.res.data),
                        showRecom : true,
                        fetching : false
                    });
                }
            });
        })
    }



    render() { 
        return (
            <Fragment>
                 <form name="event_history_form" defaultValue={this.state.formData}>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="errcode_obj">Device Error Codes</label>
                            <Select
                                value={this.state.formData.errcode_obj}
                                onChange={(value) => this.handleChange({ target : { name : 'errcode_obj', value } })}
                                options={this.state.errcodes}
                                isMulti
                            />
                        </div>
                        <div className="col-md-2">
                            <button type='button' disabled={ this.state.formData.errcodes === "" || this.state.fetching } className='btn btn-info' style={{ marginTop : 33 }} onClick={this.onGetRecom} >Get Recommendations</button>
                        </div>
                    </div>
                </form>
                <br />
                { this.state.showRecom && this.state.recom.length > 0 && 
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Recommendations by DataTwin</h4>
                        </div>
                        { this.state.recom.map((recom, ind) => 
                            <div className="col-md-4" key={ind}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title" style={{ color: "green"}}> Module : {recom.Module}</h4>
                                        <h6 className="card-title"> Spare Recommended : {recom.SpReplaced === '' ? "No Recommendation" : recom.SpReplaced }</h6>
                                        <h6 className="card-text"> Confidence : {(Number(recom.Confidence) * 100).toFixed(2)}%</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                
            </Fragment> 
        );
    }
}
 
export default GetPredictions;