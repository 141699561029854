import React, { Component, Fragment } from 'react';
import '../CI/COD.css';
import {connect} from 'react-redux';
import {Form, Button, Card, Accordion} from 'react-bootstrap';
import { getPreviousDate, getRequest, postRequest } from '../../globalhelper/helper';

export class AreaManagerReport extends Component {
  constructor(props) {
    super(props)

    this.current = this.props.state

    this.vansHiredRef = React.createRef()
    this.staffShortageRef = React.createRef()
    this.vansNotReporting = React.createRef()
    this.clientTarget = React.createRef()
    this.plan = React.createRef()
  
    this.state = {
      button: '',
       formData: {
        date: getPreviousDate(),
        // date: '2023-03-20',
        user: this.current.login.userData.USR_Email,
        staffplanned: '',
        staffpresent: '',
        deliveryassociatesplanned: '',
        deliveryassociatespresent: '',
        attendancemarkedda: '',
        dasnotreportedforwork: '',
        backendstaffplanned: '',
        backendstaffpresent: '',
        attendancemarkedbackendstaff: '',
        backendstaffnotreportedforwork: '',
        loadersplanned: '',
        loaderspresent: '',
        attendancemarkedloaders: '',
        loadersnotreportedforwork: '',
        vansplanned: '',
        vanspresent: '',
        vansreportedlate: '',
        vansnotpresent: '',
        numberofvanshired: '',
        vanshiredaction: '',
        totalnoofshipmentsreceived: '',
        noofshipmentsphysicallyreceived: '',
        shipmentsaspersystemdata: '',
        discrepanciesreceivedexcess: '',
        discrepanciesnotreceived: '',
        incomingshipmentsplanned: '',
        incomingshipmentsactual: '',
        varianceinplannedvsactual: '',
        incomingshipmentweight: '',
        noofinscannedshipments: '',
        yesterdayundeliveredsentoutfordelivery: '',
        totalshipmentsoutfordelivery: '',
        noofcodshipmentsoutfordelivery: '',
        totalcodcashvalue: '',
        shipmentsonholdatofficeonclientsinstruction: '',
        heldatofficenottakenfordelivery: '',
        totalshipmentsdelivered: '',
        totalcodshipmentsdelivered: '',
        codamountcollected: '',
        codamountshortreceived: '',
        lostormissingnoofshipments: '',
        noofshipmentsnotreceivedathub: '',
        endofdayholdatofficestock: '',
        totaloffrolestaffnotreportedforwork: '',
        reasonforstaffshortageandactiontaken: '',
        vansnotreported: '',
        reasonforvansnotreportingandactiontaken: '',
        varianceinvolume: '',
        clientwhohasnotmettarget: '',
        plantomeettargetshortfall: ''
       }
    }
  }

  componentDidMount(){
    getRequest(`api/ciportal/getAreaManagerConsolidatedValues?date=${getPreviousDate()}`).then(response => {
      console.log(response.res.data)
      let value = response.res.data

      if(!response.res.data.length){
          this.setState({
            ...this.state,
            button: 'disable'
          })
      } else {
        
        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData,
            staffplanned: value[0]['Staff Planned'],
            staffpresent: value[0]['Staff Present'],
            deliveryassociatesplanned: value[0]['Delivery Associates (CIA) Planned'],
            deliveryassociatespresent: value[0]['Delivery Associates (CIA) Present'],
            attendancemarkedda: value[0]['Attendance Marked - DA'],
            dasnotreportedforwork: value[0][`DA's Not Reported For Work`],
            backendstaffplanned: value[0]['Backend Staff Planned'],
            backendstaffpresent: value[0]['Backend Staff Present'],
            attendancemarkedbackendstaff: value[0]['Attendance Marked - Backend Staff'],
            backendstaffnotreportedforwork: value[0]['Backend Staff Not Reported For Work'],
            loadersplanned: value[0]['Loaders Planned'],
            loaderspresent: value[0]['Loaders Present'],
            attendancemarkedloaders: value[0]['Attendance Marked - Loaders'],
            loadersnotreportedforwork: value[0]['Loaders Not Reported For Work'],
            vansplanned: value[0]['Vans Planned'],
            vanspresent: value[0]['Vans Present'],
            vansreportedlate: value[0]['Vans Reported Late'],
            vansnotpresent: value[0]['Vans Not Present'],
            numberofvanshired: value[0]['Number Of Vans Hired'],
            totalnoofshipmentsreceived: value[0]['Total Number Of Shipments Received'],
            noofshipmentsphysicallyreceived: value[0]['Number Of Shipments Physically Received'],
            shipmentsaspersystemdata: value[0]['Number Of Shipments As Per System Data'],
            discrepanciesreceivedexcess: value[0]['Discrepancies - Received Excess Against Data'],
            discrepanciesnotreceived: value[0]['Discrepancies - Not Received Against Data'],
            incomingshipmentsplanned: value[0]['Incoming Shipments Planned'],
            incomingshipmentsactual: value[0]['Incoming Shipments Actual'],
            varianceinplannedvsactual: value[0]['Variance In Planned Vs Actual'],
            incomingshipmentweight: value[0]['Incoming Shipment Weight'],
            noofinscannedshipments: value[0]['Number Of In Scanned Shipments'],
            yesterdayundeliveredsentoutfordelivery: value[0]['Yesterday Undelivered Sent Out For Delivery'],
            totalshipmentsoutfordelivery: value[0]['Total Shipments Out For Delivery'],
            noofcodshipmentsoutfordelivery: value[0]['Number Of COD Shipments Out For Delivery'],
            totalcodcashvalue: value[0]['Total COD Cash Value Out For Delivery'],
            shipmentsonholdatofficeonclientsinstruction: value[0][`Shipments On Hold At Office On Client's Instruction`],
            heldatofficenottakenfordelivery: value[0]['Held At Office (Not Taken Out For Delivery)'],
            totalshipmentsdelivered: value[0]['Total Shipments Delivered'],
            totalcodshipmentsdelivered: value[0]['Number Of COD Shipments Delivered'],
            codamountcollected: value[0]['COD Amount Collected'],
            codamountshortreceived: value[0]['COD Amount Short Received'],
            lostormissingnoofshipments: value[0]['Lost Or Missing Number Of Shipments'],
            noofshipmentsnotreceivedathub: value[0]['Number Of Shipments Not Delivered Received @ Hub BTS'],
            endofdayholdatofficestock: value[0]['End Of Day Hold At Office Stock'],
            totaloffrolestaffnotreportedforwork: value[0]['Total Off Role Staff Not Reported For Work'],
            vansnotreported: value[0]['Vans Not Reported'],
            varianceinvolume: value[0]['Variance in Volume (Planned Vs Actual)']
          }
        })
      }

    })
  }

  handleChange = (event) => {
    const NAME = event.target.name, VALUE = event.target.value
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [NAME]: VALUE
      }
    })
  }

  handleSubmit = () => {
    const formData = new FormData()
    formData.append("file", this.state.formData)

    if(!this.vansHiredRef.current.value){
      this.vansHiredRef.current.focus()
    } else if (!this.staffShortageRef.current.value) {
      this.staffShortageRef.current.focus()
    } else if (!this.vansNotReporting.current.value) {
      this.vansNotReporting.current.focus()
    } else if (!this.clientTarget.current.value){
      this.clientTarget.current.focus()
    } else if (!this.plan.current.value) {
      this.plan.current.focus()
    } else {
      postRequest(`api/ciportal/areamanager_dataupload`, this.state.formData).then(response => {
        console.log(response.res.data)
      })
    }
  }
  render() {
    return (
      <Fragment>
        {/* <div className='header_div'>Area Manager Report</div> */}

        <br/>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Area Manager Report</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Date: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='date' name='date' value={this.state.formData.date} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>User: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='text' name='user' value={this.state.formData.user} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Staff Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Staff Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='staffplanned' value={this.state.formData.staffplanned} onChange={this.handleChange} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Staff Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='text' name='staffpresent' value={this.state.formData.staffpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Delivery Associates (CIA) Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='deliveryassociatesplanned' value={this.state.formData.deliveryassociatesplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Delivery Associates (CIA) Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='deliveryassociatespresent' value={this.state.formData.deliveryassociatespresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - DA: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedda' value={this.state.formData.attendancemarkedda} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>DA's Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='dasnotreportedforwork' value={this.state.formData.dasnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffplanned' value={this.state.formData.backendstaffplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffpresent' value={this.state.formData.backendstaffpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - Backend Staff: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedbackendstaff' value={this.state.formData.attendancemarkedbackendstaff} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffnotreportedforwork' value={this.state.formData.backendstaffnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loadersplanned' value={this.state.formData.loadersplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loaderspresent' value={this.state.formData.loaderspresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - Loaders: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedloaders' value={this.state.formData.attendancemarkedloaders} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loadersnotreportedforwork' value={this.state.formData.loadersnotreportedforwork} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Van Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Vans Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansplanned' value={this.state.formData.vansplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vanspresent' value={this.state.formData.vanspresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Reported Late: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansreportedlate' value={this.state.formData.vansreportedlate} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Not Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansnotpresent' value={this.state.formData.vansnotpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Vans Hired: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='numberofvanshired' value={this.state.formData.numberofvanshired} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Why Vans Were Hired - Justify Action And Cost: <span style={{color:'red'}}>*</span> </div>
                <textarea className='col-lg-4' name='vanshiredaction' value={this.state.formData.vanshiredaction} rows='4' cols='50' onChange={this.handleChange} ref={this.vansHiredRef}></textarea>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Business Volume Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Total Number Of Shipments Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalnoofshipmentsreceived' value={this.state.formData.totalnoofshipmentsreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments Physically Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofshipmentsphysicallyreceived' value={this.state.formData.noofshipmentsphysicallyreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments As Per System Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='shipmentsaspersystemdata' value={this.state.formData.shipmentsaspersystemdata} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Discrepancies - Received Excess Against Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='discrepanciesreceivedexcess' value={this.state.formData.discrepanciesreceivedexcess} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Discrepancies - Not Received Against Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='discrepanciesnotreceived' value={this.state.formData.discrepanciesnotreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipments Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentsplanned' value={this.state.formData.incomingshipmentsplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipments Actual: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentsactual' value={this.state.formData.incomingshipmentsactual} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Variance In Planned Vs Actual: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='varianceinplannedvsactual' value={this.state.formData.varianceinplannedvsactual} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipment Weight: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentweight' value={this.state.formData.incomingshipmentweight} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Other Shipment Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Number Of In Scanned Shipments: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofinscannedshipments' value={this.state.formData.noofinscannedshipments} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Yesterday Undelivered Sent Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='yesterdayundeliveredsentoutfordelivery' value={this.state.formData.yesterdayundeliveredsentoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total Shipments Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalshipmentsoutfordelivery' value={this.state.formData.totalshipmentsoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of COD Shipments Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofcodshipmentsoutfordelivery' value={this.state.formData.noofcodshipmentsoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total COD Cash Value Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalcodcashvalue' value={this.state.formData.totalcodcashvalue} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Shipments On Hold At Office On Client's Instruction: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='shipmentsonholdatofficeonclientsinstruction' value={this.state.formData.shipmentsonholdatofficeonclientsinstruction} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Held At Office (Not Taken Out For Delivery): <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='heldatofficenottakenfordelivery' value={this.state.formData.heldatofficenottakenfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total Shipments Delivered: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalshipmentsdelivered' value={this.state.formData.totalshipmentsdelivered} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of COD Shipments Delivered: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalcodshipmentsdelivered' value={this.state.formData.totalcodshipmentsdelivered} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>COD Amount Collected: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='codamountcollected' value={this.state.formData.codamountcollected} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>COD Amount Short Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='codamountshortreceived' value={this.state.formData.codamountshortreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Lost Or Missing Number Of Shipments: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='lostormissingnoofshipments' value={this.state.formData.lostormissingnoofshipments} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments Not Delivered Received @ Hub BTS: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofshipmentsnotreceivedathub' value={this.state.formData.noofshipmentsnotreceivedathub} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>End Of Day Hold At Office Stock: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='endofdayholdatofficestock' value={this.state.formData.endofdayholdatofficestock} disabled></input>
              </div>


            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Area Manager Additional Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Total Off Role Staff Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totaloffrolestaffnotreportedforwork' value={this.state.formData.totaloffrolestaffnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Reason For Staff Shortage And Action Taken: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='reasonforstaffshortageandactiontaken' value={this.state.formData.reasonforstaffshortageandactiontaken} rows='4' cols='50' onChange={this.handleChange} ref={this.staffShortageRef}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Not Reported: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansnotreported' value={this.state.formData.vansnotreported} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Reason For Vans Not Reporting And Action Taken: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='reasonforvansnotreportingandactiontaken' value={this.state.formData.reasonforvansnotreportingandactiontaken} rows='4' cols='50' onChange={this.handleChange} ref={this.vansNotReporting}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Variance In Volume (Planned Vs Actual): <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='varianceinvolume' value={this.state.formData.varianceinvolume} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Client Who Has Not Met Target: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='clientwhohasnotmettarget' value={this.state.formData.clientwhohasnotmettarget} rows='4' cols='50' onChange={this.handleChange} ref={this.clientTarget}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Plan To Meet Target Shortfall: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='plantomeettargetshortfall' value={this.state.formData.plantomeettargetshortfall} rows='4' cols='50' onChange={this.handleChange} ref={this.plan}></textarea>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <div className='wrapper'>
          <Button variant='primary' disabled={this.state.button === "disable"} onClick={this.handleSubmit}>Submit</Button>
        </div>
        
      </Fragment>
      
    )
  }
}

function mapStateToProps(state) {
  return {
      state
  }
}

export default connect(mapStateToProps) (AreaManagerReport)