import React, { Fragment, PureComponent } from 'react';
import DatePicker from "react-datepicker";
import { getRequest, getStandardDate, subtractDays } from '../../globalhelper/helper';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { ExportCSVButton } = CSVExport;
  
class CIATrack extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                fromdate : new Date(subtractDays(30, 'days')),
                todate : new Date(),
            },
            records : [],
            columns : [{
                dataField: 'OtherFields.Client',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Client'
            }, {
                dataField: 'client_model',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                formatter : (cell) => cell,
                text: 'Client Business'
            }, {
                dataField: 'station_code',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                formatter : (cell) => cell,
                text: 'Client Station Code'
            // },{
            //     dataField: 'AccessID',
            //     csvFormatter: (cell, row, rowIndex) => {
            //         return cell == null ? " " : cell.split("/")[2]
            //     },
            //     text: 'Client Station Code'
            }, {
                dataField: 'Text',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Client ID - Text',
                style: {
                    width: '250px',
                }
            }, {
                dataField: 'Code',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Client ID - Code'
            }, {
                dataField: 'StdCode',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'CI Identity'
            }, {
                dataField: 'StdText',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Name',
                style: {
                    width: '170px',
                }
            }, {
                dataField: 'StdClass1',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'User_type/CIA Contract Type'
            }, {
                dataField: 'StdClass2',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Commission Code'
            }, {
                dataField: 'StdClass3',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Commission Category'
            }, {
                dataField: 'StdClass4',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Contract Holder ID'
            }, {
                dataField: 'StdClass5',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Contract holder Name'
            }, {
                dataField: 'StdClass6',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'CIA Activity Type'
            }, {
                dataField: 'StdClass7',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : cell
                },
                text: 'Variable'
            },
            {
                dataField: 'ForPrdFrom',
                csvFormatter: (cell, row, rowIndex) => {
                    return cell == null ? " " : getStandardDate(cell/1000000)
                },
                formatter : (cell, row) => cell == null ? " " : getStandardDate(cell),
                text: 'Period'
            }],
            showRecords : false,
            loading : false
        }
    }

    
  setDate = (date,field) =>{
  
      this.setState({
        ...this.state,
        formData : {
          ...this.state.formData,
          [field] : date,
        }
      })
    }


    componentDidMount =() => {
        this.getDetails()
    }

    getDetails = () => {
        this.setState({
            ...this.state,
            showRecords : false,
            records : [],
        }, () => {
            getRequest('api/transactions/getCIATrackRecords?fromdate=' + this.state.formData.fromdate + "&todate=" + this.state.formData.todate).then(result => {
                if(result.type === "success")
                {
                    var data = JSON.parse(result.res.data)
                    if(data.length > 0)
                    {
                        getRequest('api/transactions/getThemeHeaders?processCode=TE1&themeName=CIATRACK1').then(headerRes => {
                            if(headerRes.type === "success")
                            {
                                var headers = JSON.parse(headerRes.res.data);
                                var columns = JSON.parse(JSON.stringify(this.state.columns));
                                columns.map((column, colInd) => {
                                    headers.map((header, headerInd) => {
                                        if(column.dataField !== "AccessID")
                                        {
                                            if(column.dataField === header.TargetFieldName)
                                            {
                                                column.text = header.SourceFieldName
                                            }
                                        }
                                        if(headerInd === headers.length - 1 && colInd === columns.length - 1)
                                        {
                                            this.setState({
                                                ...this.state,
                                                records : data,
                                                columns : columns,
                                            }, () => {
                                                this.setState({
                                                    ...this.state,
                                                    showRecords : true
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        })
                    }
                }
            })
        })
    }
  

    render(){
        return(
            <Fragment>
                <div className="row">
                    <div className='col-md-3'>
                        <label>Period From</label> <br/>
                            <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                    </div>
                    <div className='col-md-3'>
                        <label>Period To</label> <br/>
                            <DatePicker selected={this.state.formData.todate} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                    </div>
                    <div className='col-md-3'>
                        <button className="btn btn-primary" onClick={this.getDetails} style={{ marginTop : 32 }} >Load</button>
                    </div>
                </div> 
                <br />
                <br />
                <div className="row">
                    <div className="col-md-12">
                        { this.state.showRecords ?
                            <ToolkitProvider
                            keyField="Text"
                            data={ this.state.records }
                            columns={ this.state.columns }
                            exportCSV
                            
                          >
                            {
                              props => (
                                <div>
                                  <ExportCSVButton { ...props.csvProps } style={{ textAlign : "right" }} ><button className="btn btn-info float-right" > Export CSV </button></ExportCSVButton>
                                  <hr />
                                  <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } />
                                </div>
                              )
                            }
                          </ToolkitProvider>
                          : null
                         }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default CIATrack;