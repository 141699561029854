import React,  { Component } from "react";
import DatePicker from "react-datepicker";
import { getCurrentDate, subtractDays, getDateFormat } from '../globalhelper/helper'

class DatesTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
            fromdate : new Date(),
            todate : new Date().setDate(new Date().getDate()-10)
        }
    }

    // componentDidMount = () =>{
    //     console.log("hari")
    //     console.log(getCurrentDate())
    //     console.log(getDateFormat())
    //     console.log(subtractDays())
    // }

    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        [field] : date
        })
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <label htmlFor="fromdate">From</label> <br/>
                    <DatePicker selected={this.state.fromdate} showPopperArrow={false} id="fromdate" name="fromdate" maxDate={ this.state.todate } className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } />
                </div>
                
                <div className="col-md-6">
                    <label htmlFor="todate">To</label> <br/>
                    <DatePicker selected={this.state.todate} showPopperArrow={false} id="todate" name="todate" minDate={ this.state.fromdate } className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } />
                </div>
            </div>
        )
    }
}

export default DatesTemplate;