import React, {useState, useEffect, Fragment} from 'react';
import './PaymentScreen.css';
import "../CI/COD.css";
import { Form, Button, Modal, Spinner } from "react-bootstrap"
import { getRequest, postRequest, getHumanReadableDate, convertToINR } from '../../globalhelper/helper';
import PaginationEmail from './PaginationEmail';
import { connect } from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid'
import { CSVLink } from 'react-csv';
import moment from 'moment';
import NewPaymentPopup from './NewPaymentPopup';
import CancelIcon from '@mui/icons-material/Cancel';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

const FormGroup = ({ label, name, value, handleChange, options, type, required }) => {
    return (
        <div className='container_group'>
            <div className='col-lg-2 container_div'>
                {label}
            </div>
            {type === 'date' ?
                <input
                    className='col-lg-3 container_select'
                    required={required}
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
                :
                <select
                    className='col-lg-3 container_select'
                    name={name}
                    value={value}
                    onChange={handleChange}
                >
                    <option disabled value="">Select</option>
                    {options.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            }
        </div>
    );
};

const NewPaymentScreen = (props) => {

    const [formData, setFormData] = useState({
        company: "",
        transactionDate: "",
        bank: "",
        transactionType: "",
        paymentMode: "",
        vendorType: ""
    })

    const [dropdown, setDropdown] = useState({
        billNumber: "",
        vendorName: ""
    })

    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: ""
    })

    const [companyDetails, setCompanyDetails] = useState([])
    const [companyName, setCompanyName] = useState([])

    const [bankList, setBankList] = useState([])
    const [paymentModeList, setPaymentModeList] = useState([])
    const [warning, setWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")
    const [fetching, setFetching] = useState(false)
    const [dropdownBillNumber, setDropdownBillNumber] = useState([])
    const [dropdownVendorName, setDropdownVendorName] = useState([])
    const [completeDataFromDB, setCompleteDataFromDB] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDisplay, setTableDisplay] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [paymentScreen, setPaymentScreen] = useState(false)
    const [mainScreen, setMainScreen] = useState(true)
    const [selectedRows, setSelectedRows] = useState([])
    const [amountPayable, setAmountPayable] = useState(0)
    const [showSpinner, setShowSpinner] = useState(false)
    const [paymentFileDownload, setPaymentFileDownload] = useState([])

    const [selectedCheckBox, setSelectedCheckBox] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    //Related to pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(15)
    const [popupTable, setPopupTable] = useState([])

    const [dateRange, setDateRange] = useState([])

    const [showAddPopup, setShowAddPopup] = useState(false)

    const [popupData, setPopupData] = useState({})

    const [billsForWF, setBillsForWF] = useState("")

    const [approverDetails, setApproverDetails] = useState([])
 
    const handlePagination = () => {
        let indexOfLastPost = currentPage * postsPerPage
        let indexOfFirstPost = indexOfLastPost - postsPerPage
        console.log(currentPage)

        console.log(tableData)
        let currentPosts = tableData.sort((a, b) => {
            if(a.accountingdate && b.accountingdate){
                return(
                    new Date(a.accountingdate) - new Date(b.accountingdate)
                )
            } if(a.accountingdate){
                return -1
            } if(b.accountingdate){
                return 1
            }
            return 0
        }).slice(indexOfFirstPost, indexOfLastPost)
        console.log(currentPosts)
        setTableDisplay(currentPosts)

        if(tableData.length < 15 && currentPage > 1 ){
            setCurrentPage(1)
        }
    }

    useEffect(() => {
        handlePagination()
    }, [tableData, currentPage])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    // API to get all the companies assigned to a user

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            console.log(responseData)
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
            if (orgname.length === 1) {
                setFormData(prevState => ({
                    ...prevState,
                    company: orgname[0]
                }));
            }
        }).catch(err => console.log(err))
    }, [])

    // Function to get the banks for the specified company

    useEffect(() => {
        Promise.all([
            getRequest(`api/edureka/getBankList`),
            getRequest(`api/edureka/getPaymentModeList`)
        ]).then(([item, value]) => {
            const responseData = item.res.data
            const uniqueBanks = responseData.map(item => item.accountclass)
            setBankList(uniqueBanks)
            setPaymentModeList(value.res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const getFilteredArray = async (value, key) => {
        const dropdown_array = value.map(val => val[key])
        const filtered_dropdown_array = [...new Set(dropdown_array)]
        return filtered_dropdown_array.filter(item => item !== undefined && item !== '')
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prevState) => {
            return {...prevState, [name]: value}
        })
    }

    // Handles couple of API calls to determine already paid bills and pending bills

    const handleSubmit = async () => {
        const {transactionDate, bank, transactionType, vendorType} = formData

        if(transactionDate && bank && transactionType && vendorType){
            setWarning(false)
            setFetching(true)
            setShowSpinner(true)

            try {

                const id = companyDetails.filter(item => item.orgname === formData.company)

                const companyid = id.map(item => item.orgid)

                Promise.all([
                    getRequest(`api/edureka/getEdurekaPayment?vendortype=${vendorType}&orgid=${companyid[0]}&transDate=${transactionDate}&transType=${transactionType}`),
                    getRequest(`api/edureka/alreadyPaidBillNumbers?orgid=${companyid[0]}`)
                ]).then(async([item, value]) => {
                    const responseData = JSON.parse(item.res.data)
                    const billNumbers = JSON.parse(value.res.data)

                    const distinctBillNumbers = billNumbers.map(item => item.linkref)
                    console.log(distinctBillNumbers)
                    // const filteredArray = responseData.filter(obj => !distinctBillNumbers.includes(obj.documentnumber))
                    const filteredArray = responseData.filter((obj)=>(obj.Lc_PayAmount1 != 0))

                    console.log(filteredArray)

                    const billNumberList = await getFilteredArray(filteredArray, "documentnumber")
                    const vendorNameList = await getFilteredArray(filteredArray, "sendername")

                    setFetching(false)
                    setDropdownBillNumber(billNumberList)
                    setDropdownVendorName(vendorNameList)
                    setCompleteDataFromDB(filteredArray)
                    setTableData(filteredArray)
                    setPaymentScreen(true)
                    setMainScreen(false)
                    setShowSpinner(false)

                })

                
            } catch(err){
                setFetching(false)
                setShowSpinner(false)
                console.log(err)
            }

        } else {
            setWarning(true)
            setWarningMessage("Please select all the required fields")
            toast.error("Please select all the required fields")
        }
    }

    // Function to get createdby, modifiedby from Workflow

    useEffect(() => {

        if(completeDataFromDB.length > 0){

            const distinctBillNumbers = completeDataFromDB.map(item => item.documentnumber)
    
            const billNosForQuerying = `('${distinctBillNumbers.join("', '")}')`
    
            console.log(billNosForQuerying)
    
            console.log(distinctBillNumbers)
    
            setBillsForWF(billNosForQuerying)
        }

    }, [completeDataFromDB])

    useEffect(() => {
        console.log(billsForWF)
        if(billsForWF.trim().length > 0){

            postRequest(`api/edureka/getApproverForBills`, {item: billsForWF}).then(response => {
                console.log(response.res.data)
    
                const details = JSON.parse(response.res.data)
                setApproverDetails(details)
            }).catch(err => console.log(err))
        }
    }, [billsForWF])

    const handleBack = () => {
        setMainScreen(true)
        setPaymentScreen(false)
        setSelectedCheckBox([])
        setSelectAll(false)
    }

    const removeDuplicatesFromAnArrayOfObjects = (arr1, arr2, key) => {
        const result = []

        const arr2Set = new Set(arr2.map(obj => obj[key]))

        for (const obj of arr1){
            if(!arr2Set.has(obj[key])){
                result.push(obj)
            }
        }

        return result
    }

    // All the filtering logic goes here

    useEffect(() => {
        const filteredData = [...completeDataFromDB]

        setCurrentPage(1)

        const filteredVendorName = dropdown.vendorName.length > 0 ? dropdown.vendorName : dropdownVendorName
        const filteredBillNumber = dropdown.billNumber.length > 0 ? dropdown.billNumber : dropdownBillNumber
        const filteredDates = dateRange.length > 0 ? dateRange : [...new Set(filteredData.map(item => item.accountingdate))]

        console.log(filteredDates)
    
        const filteredArray = filteredData.filter(item =>
            filteredVendorName.includes(item.sendername) &&
            filteredBillNumber.includes(item.documentnumber) &&
            filteredDates.includes(item.accountingdate)
        )

        // const addToExisting = [...selectedRows, ...filteredArray]

        console.log(filteredArray)
    
        const uniqueArray = removeDuplicatesFromAnArrayOfObjects(filteredArray, selectedRows, "documentnumber")

        console.log(uniqueArray.length)

        console.log(currentPage)
    
        setTableData(uniqueArray)
    }, [dropdown.billNumber, dropdown.vendorName, dateRange])
    

    const handleDropdownChange = (e) => {
        const {name, value} = e.target

        setDropdown(prevState => {
            return {...prevState, [name]: value}
        })

    }

    // Display total amount payable

    useEffect(() => {
        const totalAmount = selectedRows.map(item => item.Lc_FinalAmount)
        console.log(totalAmount)
        const sum = totalAmount.reduce((acc, com) => acc + com, 0)
        console.log(sum)
        setAmountPayable(Number(-1 * sum))
    }, [selectedRows])

    // Handles remove functionality

    const handleRemove = (item) => {

        const updatedArray = selectedRows.filter(obj => obj.documentnumber !== item.documentnumber)

        const removeFromSelectedRow = selectedCheckBox.filter(selectedItem => selectedItem !== item.documentnumber)

        console.log(removeFromSelectedRow)

        setSelectedRows(updatedArray)
        setSelectedCheckBox(removeFromSelectedRow)
    }

    // Handles Reset

    const handleReset = () => {
        setTableData(completeDataFromDB)
        setDropdown({
            billNumber: "",
            vendorName: ""
        })
        setFilterData({
            fromDate: "",
            toDate: ""
        })
        setCurrentPage(1)
        setDateRange([])
        setSelectedRows([])
        setSelectAll(false)
        setSelectedCheckBox([])
    }

    // Makes API call to backend to create JSON in the specified format and calls schema

    const handleFileSubmit = async () => {
        try {
            setShowSpinner(true)
            const distinctVendorIDs = selectedRows.map(item => item.senderaccount)
            const joinedArr = `('${distinctVendorIDs.join("', '")}')`

            console.log(joinedArr)

            // Gets bank details

            const response = await postRequest(`api/edureka/generatePaymentFile`, { item: joinedArr })
            const responseData = response.res.data
            console.log(responseData)
            const copyOfSelected = [...selectedRows]
            console.log(copyOfSelected)

            const finalData = joinTwoArrays( copyOfSelected, responseData, "senderaccount")

            console.log(finalData)

            const setTransactionType = processTransactionType(finalData)

            console.log(setTransactionType)

            const objectsNotPresent = checkKeysPresence(setTransactionType)

            console.log(objectsNotPresent)

            if(objectsNotPresent.length){
                setPopupTable(objectsNotPresent)
                setShowTable(true)
                setPaymentScreen(false)
                setShowSpinner(false)
            } else {
                const finalArr = prepareFinalArray(setTransactionType)

                console.log(finalArr)

                await postPaymentSchema(finalArr)

                const uniqueList = await getReferenceNumber(finalArr)

                console.log(JSON.parse(uniqueList))

                const result = JSON.parse(uniqueList)
                // const uniqueDocs = result.map(item => item)

                const includeReferenceNumber = async (arr1, arr2) => {
                    return arr1.map(item1 => {
                        const item2 = arr2.find(item2 => item2["linkref"] === item1["documentnumber"])
                        return {...item1, ...item2}
                    })
                }

                const results = await includeReferenceNumber(setTransactionType, result)

                console.log(results)


                // downloadCSV(results)

                setMainScreen(true)
                setPaymentScreen(false)
                handleReset()
                setTableData([])
                setShowSpinner(false)
            }

        } catch (err){
            console.log(err)
            setShowSpinner(false)
            toast.error("Oops something went wrong")
        }
    }

    const getReferenceNumber = async (array) => {
        const id = companyDetails.find(item => item.orgname === formData.company)
        const companyid = id.orgid
        const billNumbers = array.map(item => item.billdocumentnumber)

        console.log(billNumbers)

        const request = {
            arr: billNumbers,
            orgid: companyid
        }

        const response = await postRequest(`api/edureka/getPaymentReferences`, request)

        console.log(response.res.data)

        return response.res.data

    }

    const processTransactionType = (responseData) => {
        return responseData.map(item => {
            const hdfc = 'hdfc';
            const bankName = item.bankname ? item.bankname.toLowerCase() : '';
            const transactiontype = bankName.includes(hdfc.toLowerCase()) ? 'I' : 'N';
    
            return {
                ...item,
                txnType: transactiontype,
                currentDate: getHumanReadableDate(new Date()),
                Lc_PayAmount: item.Lc_FinalAmount * -1,
                Lc_FinalAmount: item.Lc_FinalAmount * -1,
                communicationemailid: "payment.advices@edureka.co"
            };
        });
    };

    const checkKeysPresence = (data) => {
        const keysToCheck = ["accountholdername", "accountnumber", "ifsccode", "bankname"]
        return data.filter(obj => keysToCheck.some(key => !(key in obj)))
    }

    const prepareFinalArray = (data) => {
        const id = companyDetails.find(item => item.orgname === formData.company)
        const companyid = id.orgid

        return data.map(item => ({
            ...item,
            receivername: formData.company,
            receiveraccount: companyid,
            bankref: formData.bank,
            financialdate: formData.transactionDate,
            type: "Payment",
            orgid: companyid,
            documentnumber: "",
            billdocumentnumber: item.documentnumber
        }))
    }

    const postPaymentSchema = async (finalArr) => {
        const id = companyDetails.find(item => item.orgname === formData.company)
        const companyid = id.orgid

        const request = {
            arr: finalArr,
            orgid: companyid,
            user: props.state.login.userData.USR_Email,
            filename: uuidv4()
        }

        const response = await postRequest(`api/edureka/paymentSchema`, request)
        console.log(response)
    }

    const downloadCSV = (data) => {
        const headers = ["txnType", "Doc", "accountnumber", "Lc_PayAmount", "accountholdername", "draweelocation", "printlocation", "add1", "add2", "add3", "add4", "add5", "instrurefno", "Doc", "pd1", "pd2", "pd3", "pd4", "pd5", "pd6", "pd7", "instno", "currentDate", "micr", "ifsccode", "bankname", "branchname", "communicationemailid"]

        const csvContent = convertToCSV(data, headers, "accountnumber")
    
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'bank.csv')
        
        document.body.appendChild(link)
        link.click()

        // Clean up
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }

    const joinTwoArrays = (arr1, arr2, key) => {
        return arr1.map(item1 => {
            const item2 = arr2.find(item => item[key] === item1[key])
            return {...item1, ...item2}
        })
    }

    const convertToCSV = (array, headers, keyToConvertToString) => {
        if (!array || array.length === 0) {
            return null;
        }
    
        const csvData = array.map(item => 
            headers.map(key => 
                (key === keyToConvertToString && typeof item[key] === 'number') // Check if the key matches the keyToConvertToString and if the value is a number
                ? `"${item[key].toString()}"` // If true, convert the value to a string and enclose it in double quotes
                : (item[key] ? `${item[key]}` : null) // If false, proceed as before
            ).join(',')
        );
    
        return csvData.join('\n');
    };
    
    const handlePopupTableChange = (item, e) => {
        const arrData = [...popupTable]

        const {name, value} = e.target

        console.log(name,value)

        const objIndex = arrData.findIndex(val => val.documentnumber === item.documentnumber)

        console.log(objIndex)

        const tempArr = arrData[objIndex]

        const updatedArray = {...tempArr, [name]:value}

        console.log(updatedArray)

        const finalArray = [...arrData.slice(0, objIndex), updatedArray, ...arrData.slice(objIndex + 1)]

        console.log(finalArray)

        setPopupTable(finalArray)
    }

    const handlePopupClose = () => {
        setPaymentScreen(true)
        setShowTable(false)
    }

    const handleVerification = () => {
        console.log(popupTable)
        setPaymentScreen(true)
        setShowTable(false)

    }

    async function getUserName(email){
        const parts = email.split('@')

        const username = parts[0]

        if(username.includes(".")){

            const userNameParts = username.split(".")

            const formattedUserName = userNameParts.map(item => {
                return item.charAt(0).toUpperCase() + item.slice(1)
            }).join(" ")

            return formattedUserName
        } else {
            return username.charAt(0).toUpperCase() + username.slice(1)
        }
    }

    async function getUserDetails(billNumber){
        const filteredDetails = approverDetails.filter(item => item.createdid === billNumber)

        if(filteredDetails.length === 0) return ""

        const [modifiedByUser, createdByUser] = await Promise.all([
            getUserName(filteredDetails[0].modifiedby),
            getUserName(filteredDetails[0].createdby)
        ])

        return {
            approver: modifiedByUser,
            validator: createdByUser
        }
    }

    useEffect(() => {
        const fetchFileDetails = async () => {
            const fileData = await Promise.all(selectedRows.map(async item => {
                const userDetails = await getUserDetails(item.documentnumber);
                return {
                    "Vendor Name": item.sendername,
                    "Invoice Number": item.documentref,
                    "Validated By": userDetails.validator,
                    "Approved By": userDetails.approver,
                    "Invoice Date": item.documentdate,
                    "Due Date": item.postingdate,
                    "Basic": item.Lc_BaseValue,
                    "Total GST": item.Lc_TaxAmount,
                    "TDS": item.Lc_WHTaxAmount,
                    "Net Amount Payable": Number(item.Lc_FinalAmount) * -1,
                    "P&L LedgerName": item.Ledger,
                    "Details/Remarks": `Bill from ${item.sendername} for the period from ${item.FPF} to ${item.FPT} towards ${item.Ledger}`
                }
            }))
            setPaymentFileDownload(fileData);
        }

        fetchFileDetails()
    }, [selectedRows])

    // useEffect(() => {
    //     const file = selectedRows.map(async item => {
    //         return {
    //             "Vendor Name": item.sendername,
    //             "Invoice Number": item.documentref,
    //             // "Validated By": props.state.login.userData.USR_Name,
    //             "Validated By": await getValidatorDetails(item.documentnumber),
    //             "Approved By": await getApproverDetails(item.documentnumber),
    //             "Invoice Date": item.documentdate,
    //             "Due Date": item.postingdate,
    //             "Basic": item.Lc_BaseValue,
    //             "Total GST": item.Lc_TaxAmount,
    //             "TDS": item.Lc_WHTaxAmount,
    //             "Net Amount Payable": Number(item.Lc_FinalAmount) * -1,
    //             "P&L LedgerName": item.Ledger,
    //             "Details/Remarks": `Bill from ${item.sendername} for the period from ${item.FPF} to ${item.FPT} towards ${item.Ledger}`
    //         }
    //     })

    //     setPaymentFileDownload(file)
    // }, [selectedRows])

    const handleFilterDateChange = (e) => {
        const {name, value} = e.target

        console.log(name)

        console.log(value)

        setFilterData(prev => {
            return {...prev, [name]: value}
        })
    }

    const getDatesInBetween =  (startDate, endDate) => {
        let dates = []
        let currentDate = moment(startDate)
        const lastDate = moment(endDate)

        while (currentDate <= lastDate) {
            dates.push(currentDate.format('YYYY-MM-DD'))
            currentDate = currentDate.clone().add(1, 'days')
        }

        return dates
    }

    // Find the dates between the given range

    useEffect(() => {
        const {fromDate, toDate} = filterData

        console.log(fromDate, toDate)

        if(new Date(fromDate) > new Date(toDate)){
            toast.error("From date must be lesser than To date")
        } else if(fromDate && toDate) {

            const datesBetween = getDatesInBetween(fromDate, toDate)

            setDateRange(datesBetween)

            console.log(datesBetween)
        }
    }, [filterData.toDate, filterData.fromDate])

    useEffect(() => {
        setFilterData(prev => {
            return {...prev, "toDate": formData.transactionDate}
        })
    }, [formData.transactionDate])

    const handleSubmission = (finalData) => {
        console.log(finalData)

        setShowAddPopup(false)

        const filteredArray = [...selectedRows]

        const indexToReplace = filteredArray.findIndex(obj => obj.documentnumber === finalData.documentnumber)

        filteredArray.splice(indexToReplace, 1, finalData)

        // const filtered = filteredArray.filter(obj => obj.documentnumber !== finalData.documentnumber)

        // const finalArray = [...filtered, finalData]

        // setTableData(updatedArray)
        setSelectedRows(filteredArray)
    }

    const handleCheckboxChange = (item) => {
        const selectedIndex = selectedCheckBox.indexOf(item.documentnumber)

        let updatedSelected = [...selectedCheckBox]

        if(selectedIndex === -1){
            updatedSelected.push(item.documentnumber)
        } else {
            updatedSelected.splice(selectedIndex, 1)
        }

        setSelectedCheckBox(updatedSelected)
    }

    const handleSelectAll = () => {
        const allDocNumbers = tableData.map(item => item.documentnumber)

        if(selectAll){
            setSelectedCheckBox([])
        } else {
            setSelectedCheckBox(allDocNumbers)
        }

        setSelectAll(!selectAll)
    }

    useEffect(() => {
        const updatedArray = completeDataFromDB.filter(item => selectedCheckBox.includes(item.documentnumber))

        console.log(updatedArray)

        console.log(selectedRows)

        const finalArray = updatedArray.map(item => {
            return (
                {...item, Lc_FinalAmount: item.Lc_PayAmount1, percentage: 100}
            )
        })

        // const existingList = [...selectedRows, ...finalArray]

        // console.log(existingList)

        console.log(finalArray)

        setSelectedRows(finalArray)

    }, [selectedCheckBox])

    const handlePaymentTypeChange = (item) => {
        setPopupData(item)
        setShowAddPopup(true)
    }

    if(mainScreen){
        return (
            <Fragment>
                <div className='header_div'>
                    Payment Preparation
                </div>

                <br />

                <Form style={{ display: "flex", flexDirection: "column" }}>

                    <FormGroup
                        label="Organization Name:"
                        name="company"
                        value={formData.company}
                        handleChange={handleChange}
                        options={companyName.sort()}
                    />

                    <FormGroup
                        label="Transaction Date:"
                        name="transactionDate"
                        type="date"
                        value={formData.transactionDate}
                        handleChange={handleChange}
                        required={true}
                    />

                    <FormGroup
                        label="Bank:"
                        name="bank"
                        value={formData.bank}
                        handleChange={handleChange}
                        options={bankList}
                    />

                    <FormGroup
                        label="Transaction Type:"
                        name="transactionType"
                        value={formData.transactionType}
                        handleChange={handleChange}
                        options={['Salary Payment', 'Vendor Payment', 'VendorAdvancePayments', 'EmployeeAdvancePayments']}
                    />

                    <FormGroup
                        label="Vendor Type:"
                        name="vendorType"
                        value={formData.vendorType}
                        handleChange={handleChange}
                        options={['Domestic', 'International']}
                    />

                </Form>

                <div style={{ textAlign: "center" }}>
                    <Button variant="secondary" size="sm" onClick={handleSubmit}>Submit</Button>
                </div>

                {warning &&
                    <div className='center-screen'>
                        {warningMessage}
                    </div>}

                {fetching &&
                    <div className='center'>
                        {[...Array(10)].map((_, index) => (
                            <div key={index} className="wave"></div>
                        ))}
                    </div>}

                <ToastContainer />
            </Fragment>
        )
    } else if(paymentScreen){
        return (
            <Fragment>
                <div className='header_div'>
                    Payment Preparation
                </div>

                <br/>

                <div className='row'>
                    <div className='col-md-10'>
                        <table border="1" id="table-css">
                            <thead>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Bank</th>
                                    {/* <th>Bank Account</th> */}
                                    <th>Transaction Type</th>
                                    <th>Vendor Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getHumanReadableDate(formData.transactionDate)}</td>
                                    <td>{formData.bank}</td>
                                    {/* <td>{formData.accountClass}</td> */}
                                    <td>{formData.transactionType}</td>
                                    <td>{formData.vendorType}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{textAlign:'right'}}>
                        <Button variant='danger' style={{marginRight: '10px'}} size='sm' onClick={handleReset}>Reset</Button>
                        <Button size='sm' onClick={handleBack} variant='warning'>Back</Button>
                    </div>
                </div>

                <br/>
                <br/>

                <div className='row'>
                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Vendor Name</Form.Label>
                            <select className='spacing' value={dropdown.vendorName} name="vendorName" onChange={handleDropdownChange} >
                                <option value="">Select</option>
                                {dropdownVendorName.sort().map((item, index) => <option value={item} key={index} >{item}</option>)}
                            </select>
                        </Form.Group>
                    </div>

                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Bill Number</Form.Label>
                            <select className='spacing' value={dropdown.billNumber} name="billNumber" onChange={handleDropdownChange} >
                                <option value="">Select</option>
                                {dropdownBillNumber.sort().map((item, index) => <option value={item} key={index} >{item}</option>)}
                            </select>
                        </Form.Group>
                    </div>

                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Acc. Date From</Form.Label>
                            <input className='spacing' type='date' name='fromDate' value={filterData.fromDate} onChange={handleFilterDateChange}></input>
                        </Form.Group>
                    </div>

                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Acc. Date To</Form.Label>
                            <input className='spacing' type='date' name='toDate' value={filterData.toDate} onChange={handleFilterDateChange}></input>
                        </Form.Group>
                    </div>

                    {selectedRows.length ?
                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Total Amount Payable</Form.Label>
                            <h6><strong>{convertToINR(amountPayable)}</strong></h6>
                        </Form.Group>
                    </div> : null }

                    {selectedRows.length && !showSpinner ?
                    <div>
                        <Button size='sm' style={{marginRight:'10px'}} onClick={handleFileSubmit} variant='secondary'>Submit</Button>
                    </div> : null}

                    {selectedRows.length && showSpinner ?
                    <div>
                        <Button size='sm' style={{marginLeft:'10px'}} variant="warning" disabled>
                            Loading...
                        </Button>
                    </div>
                     : null}

                     {selectedRows.length ?
                     <div>
                        <CSVLink size='sm' className="btn btn-success btn-sm" filename='Payments.csv' style={{marginLeft: '10px'}} data={paymentFileDownload} ><FileDownloadRoundedIcon color='success'/></CSVLink>
                     </div>: null}
                </div>

                {showAddPopup ?
                <NewPaymentPopup 
                    data={popupData}
                    handleClose = {() => {
                        setShowAddPopup(false)
                    }}
                    onSubmit={handleSubmission}
                /> : null}

                <br/>

                {selectedRows.length ?
                <div>
                    <table border='1' id='table-css'>
                        <thead>
                            <tr>
                                <th>Remove</th>
                                {/* <th>Transaction Type</th> */}
                                <th>Edit</th>
                                <th>Accounting Date</th>
                                <th style={{ minWidth: 90 }}>Due Date</th>
                                <th style={{ minWidth: 90 }}>Bill Date</th>
                                <th>Percentage</th>
                                <th>Bill Number</th>
                                <th>Current Payable</th>
                                <th>Total Bill Amount</th>
                                <th>Document Reference</th>
                                <th>Sender Account</th>
                                <th>Sender ID</th>
                                <th>Sender Name</th>  
                                <th>Transaction Currency</th>                           
                                {formData.vendorType !== 'Domestic' && (
                                    <> 
                                        <th>Transaction Base Value</th>
                                        <th>Transaction Tax Amount</th>
                                        <th>Transaction WH Tax Amount</th>
                                        <th>Transaction Amount Payable</th> 
                                    </>
                                )}
                                <th>Local Currency Base Value</th>
                                <th>Local Currency Tax Amount</th>
                                <th>Local Currency WH Tax Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedRows.map((item, index) => {
                                return (
                                    <tr key={item.documentnumber}>
                                        <td>
                                            <Button variant='link' size='sm' onClick={() => handleRemove(item, index)}><CancelIcon color='error'/></Button>
                                        </td>
                                        <td>
                                            <Button variant='link'  size='sm' onClick={() => handlePaymentTypeChange(item, index)}><EditOutlinedIcon color='success'/></Button>
                                        </td>
                                        <td>{getHumanReadableDate(item.accountingdate)}</td>
                                        <td>{item.postingdate ? getHumanReadableDate(item.postingdate) : ""}</td>
                                        <td>{getHumanReadableDate(item.documentdate)}</td>
                                        <td>{item.percentage}</td>
                                        <td>{item.documentnumber}</td>                                                                                       {/*mbp */}
                                        <td style={{textAlign:'right', fontWeight:'bolder'}}>{Number(-1 * item.Lc_FinalAmount).toFixed(2)}</td> 
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_PayAmount ? -1 * item.Lc_PayAmount : 0).toFixed(2)}</td>
                                        <td>{item.documentref}</td>
                                        <td>{item.senderaccount}</td>
                                        <td>{item.senderid}</td>
                                        <td>{item.sendername}</td>
                                        <td>{item.transactioncurrency}</td>
                                        {formData.vendorType !== 'Domestic' && (
                                            <>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_BaseValue ? item.Txn_BaseValue : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_TaxAmount ? item.Txn_TaxAmount : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_WHTaxAmount ? item.Txn_WHTaxAmount : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_PayAmount ? item.Txn_PayAmount : 0).toFixed(2)}</td>
                                            </>
                                        )}
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_BaseValue ? item.Lc_BaseValue : 0).toFixed(2)}</td>
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_TaxAmount ? item.Lc_TaxAmount : 0).toFixed(2)}</td>
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_WHTaxAmount ? item.Lc_WHTaxAmount : 0).toFixed(2)}</td>                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div> : null}

                <br/>
                
                {tableDisplay.length ?
                <div>
                    <table border='1' id='table-css'>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type='checkbox'
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                {/* <th>Add</th> */}
                                <th>Accounting Date</th>
                                <th style={{ minWidth: 90 }}>Due Date</th>
                                <th style={{ minWidth: 90 }}>Bill Date</th>
                                <th>Bill Number</th>
                                <th>Document Reference</th>
                                <th>Sender Account</th>
                                <th>Sender ID</th>
                                <th>Sender Name</th>
                                <th>Transaction Currency</th>
                                {formData.vendorType !== 'Domestic' && (
                                    <>
                                        <th>Transaction Base Value</th>
                                        <th>Transaction Tax Amount</th>
                                        <th>Transaction WH Tax Amount</th>
                                        <th>Transaction Amount Payable</th>
                                    </>
                                )}                            
                                <th>Local Currency Base Value</th>
                                <th>Local Currency Tax Amount</th>
                                <th>Local Currency WH Tax Amount</th>
                                <th>Local Currency Amount Payable</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableDisplay.map((item, index) => {
                                return (
                                    <tr key={item.documentnumber}>
                                        <td>
                                            <input 
                                                type='checkbox'
                                                checked= {selectedCheckBox.includes(item.documentnumber)}
                                                onClick={() => handleCheckboxChange(item)}
                                            />
                                        </td>
                                        {/* <td>
                                            <Button variant='success' size='sm' onClick={() => handleAdd(item, index)}>
                                                Add
                                            </Button>
                                        </td> */}
                                        <td>{getHumanReadableDate(item.accountingdate)}</td>
                                        <td>{item.postingdate ? getHumanReadableDate(item.postingdate) : ""}</td>
                                        <td>{getHumanReadableDate(item.documentdate)}</td>
                                        <td>{item.documentnumber}</td>
                                        <td>{item.documentref}</td>
                                        <td>{item.senderaccount}</td>
                                        <td>{item.senderid}</td>
                                        <td>{item.sendername}</td>
                                        <td>{item.transactioncurrency}</td>
                                        {formData.vendorType !== 'Domestic' && (
                                            <>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_BaseValue ? item.Txn_BaseValue : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_TaxAmount ? item.Txn_TaxAmount : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_WHTaxAmount ? item.Txn_WHTaxAmount : 0).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{Number(item.Txn_PayAmount ? item.Txn_PayAmount : 0).toFixed(2)}</td>
                                            </>
                                        )}
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_BaseValue ? item.Lc_BaseValue : 0).toFixed(2)}</td>
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_TaxAmount ? item.Lc_TaxAmount : 0).toFixed(2)}</td>
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_WHTaxAmount ? item.Lc_WHTaxAmount : 0).toFixed(2)}</td>
                                        <td style={{textAlign:'right'}}>{Number(item.Lc_PayAmount ? -1 * item.Lc_PayAmount : 0).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div> : null }

                <br/>

                {tableDisplay.length ?
                <PaginationEmail postsPerPage={postsPerPage} totalPosts={tableData.length} currentPage={currentPage} paginate={paginate}/> : null}

            <ToastContainer/>
            </Fragment>
        )
    } else if(showTable){
        return (
            <Fragment>
                <Modal show={true} size='lg'>
                    <Modal.Header>
                        <Modal.Title style={{fontSize:'1rem'}}>
                            Please fill the missing values to continue
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table border="1" id="table-css">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Vendor/Account Name</th>
                                    <th>Account Number</th>
                                    <th>IFSC Code</th>
                                    <th>Bank Name</th>
                                    <th>Email ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {popupTable.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input type='text' value={item.accountholdername} name="accountholdername"  onChange={(e) => handlePopupTableChange(item, e)}></input>
                                            </td>
                                            <td>
                                                <input type='text' value={item.accountnumber} name="accountnumber" onChange={(e) => handlePopupTableChange(item, e)}></input>
                                            </td>
                                            <td>
                                                <input type='text' value={item.ifsccode} name="ifsccode" onChange={(e) => handlePopupTableChange(item, e)}></input>
                                            </td>
                                            <td>
                                                <input type='text' value={item.bankname} name="bankname" onChange={(e) => handlePopupTableChange(item, e)}></input>
                                            </td>
                                            <td>
                                                <input type='text' value={item.communicationemailid} name="communicationemailid" onChange={(e) => handlePopupTableChange(item, e)}></input>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                        <Button size='sm' variant='danger' onClick={handlePopupClose}>
                            Back
                        </Button>
                        <Button size='sm' variant='success' onClick={handleVerification}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer/>
            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps) (NewPaymentScreen)
