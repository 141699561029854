import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from "../../globalhelper/helper";
import Select from 'react-select';
import Datatable from "../../components/DataTable2";

class InputOutputRecon extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData :{
                txnentity : '',
                tan : '',
                txnentity_obj : '',
                tan_obj : ''
            },
            columns : [
                {
                    name: 'S.No',
                    selector: (row, ind) => ind+1,
                    sortable: true,
                    width : '50px',
                },
                {
                    name: 'TAN',
                    selector: row => row["TAN"],
                    sortable: true,
                    width : '250px',
                },
                {
                    name: 'Customer Account',
                    selector: row => row["Customer Account"],
                    sortable: true,
                    width : '250px',
                },
                {
                    name: 'Source',
                    selector: row => row["Source"],
                    sortable: true,
                    width : '200px',
                },
                {
                    name: 'Input Amt',
                    selector: row => row["Input Amt"],
                    sortable: true,
                    isAmount : true,
                    width : '250px',
                },
                {
                    name: 'Output Amt',
                    selector: row => row["Output Amt"],
                    sortable: true,
                    isAmount : true,
                    width : '250px',
                },
                {
                    name: 'Difference',
                    selector: row => row["Difference"],
                    sortable: true,
                    width : '250px',
                    isAmount : true,
                }
            ],
            fetching : false,
            txnEntityList : [],
            tanList : [],
            data : [],
         };
    }

    getTxnEntityList = () => {
        getRequest('api/tds/getTxnEntityList').then(res => {
            if(res.type === 'success') {
                this.setState({
                    txnEntityList : JSON.parse(res.res.data)
                });
            }
        });
    }

    handleChange = (ev) => {
        let { formData } = this.state;
        let NAME = ev.target.name, VALUE = ev.target.value;
        console.log(NAME, VALUE)
        formData[NAME] = VALUE;
        if(NAME === 'txnentity_obj'){
            formData['txnentity'] = VALUE.value;
        }
        if(NAME === 'tan_obj'){
            formData['tan'] = VALUE.map(tan => tan.value).join(',');
        }
        this.setState({
            ...this.state,
            formData
        }, () => {
            if(NAME === "txnentity_obj")
            {
                this.getTansListForTxnEntity(this.state.formData.txnentity);
            }
        });
    }

    getTansListForTxnEntity = () => {
        getRequest('api/tds/getTanListForTxnEntity?txnentity=' + this.state.formData.txnentity).then(res => {
            if(res.type === 'success') {
                this.setState({
                    tanList : JSON.parse(res.res.data)
                });
            }
        });
    }

    componentDidMount = () => {
        this.getTxnEntityList();
    }

    getInputOutputReconForEntityAndTAN = () => {
        this.setState({
            ...this.state,
            fetching : true
        },() => {
            const BODY = {
                tan : this.state.formData.tan,
                txnentity : this.state.formData.txnentity
            }
            postRequest('api/tds/getInputOutputReconForEntityAndTAN', { data : JSON.stringify(BODY)}).then(res => {
                if(res.type === 'success') {
                    // window.location.reload();
                    this.setState({
                        ...this.state,
                        data : JSON.parse(res.res.data),
                        fetching : false
                    })
                }
            });
        });

    }

    render() { 
        return (  
            <Fragment>
                <form >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Txn Entity</label>
                                <Select
                                    value={this.state.formData.txnentity_obj}
                                    onChange={(value) => this.handleChange({ target : { name : 'txnentity_obj', value } })}
                                    options={this.state.txnEntityList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>TAN</label>
                                <Select
                                    value={this.state.formData.tan_obj}
                                    isMulti
                                    onChange={(value) => this.handleChange({ target : { name : 'tan_obj', value } })}
                                    options={this.state.tanList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary" style={{ marginTop : 33 }} disabled={this.state.formData.txnentity == ""} onClick={this.getInputOutputReconForEntityAndTAN} >Get Data</button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <Datatable columns={this.state.columns} title="Input-Output Reconciliation" loading={this.state.fetching} key={this.state.data} data={this.state.data} ></Datatable>
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
export default InputOutputRecon;