import React, { Component, Fragment } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper';
import Select from 'react-select';
import DataTable from '../../components/DataTable2';
import DatePicker from "react-datepicker";


class Predictions extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData: {
                behaviour_id : '',
                behaviour_obj : '',
                period_from : '',
                period_to : ''
            },
            BehaviourIDList : [],
            columns : [
                {
                    name: 'Behaviour ID',
                    selector: row => row['Behaviour ID'],
                    sortable: true,
                    width : '250px'
                },
                {
                    name: 'Parts Replace',
                    selector: row => row['Parts Replace'],
                    sortable: true,
                    width : '200px'
                },
                {
                    name: 'Error Codes',
                    selector: row => row['Error Codes'],
                    sortable: true,
                    // selector: row => <p style={{ whiteSpace : 'normal' }}>{row[]}</p>,
            },
                {
                    name: 'Scores',
                    selector: row =>  Number(row['Scores']).toFixed(1) + "%",
                    sortable: true,
                    width : '70px'
                    // selector: row => <p className="number-format" >{ Number(row[]).toFixed(3)}</p>,
                },
            ],
            data : [],
            showData : false,
            fetching : false
         }
    }

    handleChange = (ev) => {
        const { formData } = this.state;
        formData[ev.target.name] = ev.target.value;
        if(ev.target.name === 'behaviour_obj'){
            formData['behaviour_id'] = ev.target.value.value;
        }
        this.setState({ formData });
    }

    onBehaviourIDChange = (behaviour_id) => {
        console.log(behaviour_id);
    }

    componentDidMount = () => {
        getRequest('api/cms/getBehaviourIdsFromPrediction').then((res) => {
            if (res.type === "success") {
                this.setState({ BehaviourIDList : JSON.parse(res.res.data) });
            }
        })

    }

    
  setDate = (date,field) =>{
  
      this.setState({
        ...this.state,
        formData : {
          ...this.state.formData,
          [field] : date,
        }
      })
    }

    onFetchData = () => {
        this.setState({
            ...this.state,
            fetching : true,
            showData : false,
            data : []
        }, () => {
            postRequest('api/cms/getPredictions',this.state.formData).then(res => {
                if(res.type === "success")
                {
                    this.setState({
                        ...this.state,
                        data : JSON.parse(res.res.data),
                        showData : true,
                        fetching : false
                    })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    ...this.state,
                    fetching : false
                })
            })
        })
    }
    
    buttonDisabled = () => {
        if (this.state.fetching) return true;

        if (this.state.formData.behaviour_id !== '') return false

        if (this.state.formData.period_from !== '' && this.state.formData.period_to !== '') return false;


        return true;
    }

    onClear = () => {
        console.log('clear');
        this.setState({
            ...this.state,
            formData : {
                behaviour_id : '',
                behaviour_obj : '',
                period_from : '',
                period_to : ''
            }
        })
    }


    render() { 
        return (  
            <div>
                <form name="event_history_form" defaultValue={this.state.formData}>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="behaviour_obj">Behaviour ID</label>
                            <Select
                                value={this.state.formData.behaviour_obj}
                                onChange={(value) => this.handleChange({ target : { name : 'behaviour_obj', value } })}
                                options={this.state.BehaviourIDList}
                            />
                        </div>
                        <div className="col-md-1" align="middle">
                            <div class="hr-sect">OR</div>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_from">Period From</label>
                            <DatePicker selected={this.state.formData.period_from} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_from') } id="period_from" />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_to">Period To</label>
                            <DatePicker selected={this.state.formData.period_to} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_to') } id="period_to" />
                        </div>
                        <div className="col-md-1">
                            <button type='button' className='btn btn-info' disabled={ this.state.fetching } style={{ marginTop : 33 }} onClick={this.onFetchData} >Fetch Data</button>
                        </div>
                        <div className="col-md-1">
                            <button type='button' className='btn btn-warning' style={{ marginTop : 33 }} onClick={this.onClear} >Clear</button>
                        </div>
                    </div>
                </form>
                <br /><br />
                <Fragment>
                    <DataTable title="Predictions [Current]" loading={this.state.fetching} columns={this.state.columns} key={this.state.data} data={this.state.data} />
                </Fragment>
            </div>
        );
    }
}
 
export default Predictions;