import React from "react";

// import DateForm from "./DateForm";
import SelectForm from "./SelectForm";
import ButtonGroup from "./ButtonGroup";
// import MenuCard from "./MenuCard";
import { connect } from 'react-redux';
import { forms } from "./arrayrefs";
import GetInputField from "./GetInput";
import GetOutputField from "./BOTOutputs";

function ViewLogic(props) {
  return props.intent && props.options && props.options.length && props.type !== "BOTInput" && props.type !== "BOTOutput" && props.type !== "Parameter" ? (
    <ButtonGroup
      title={props.short_text}
      type={props.type}
      showTitle={props.showTitle ? props.showTitle : true}
      haveSiblings={props.haveSiblings}
      options={props.options}
    />
  ) : props.options && props.options.length && props.type === "BOTInput" ? (
    <div className="row" style={{ margin : 'auto', marginLeft : ' 10px' }} >
      {props.options.map((option, index) =>
        <GetInputField option={option} index={index} length={props.options.length} />
        )}
      </div>
  ) : props.options && props.options.length && (props.type === "BOTOutput" || props.type === "Parameter") ? (
    <div className="row" style={{ margin : 'auto', marginLeft : '10px' }} >
      {props.options.map((option, index) =>
        <GetOutputField option={option} index={index} length={props.options.length} />  
        )}
    </div>
  ) : props.value && props.intent && forms.includes(props.value) ? (
    <SelectForm />
  ) : props.value && props.intent ? (
    <div
      className="d-inline-flex p-3 bg-light"
      style={{ borderRadius: "10px" }}
    >
      {props.value}
    </div>
  ) : props.value && !props.intent ? (
    <div
      className="d-inline-flex p-3 bg-light"
      style={{ borderRadius: "10px" }}
    >
      {props.value}
    </div>
  ) : (
    <div>{console.log("initial state from chatbot empty")}</div>
  );
}


const mapStateToProps = (state) => {
  return {
    store: state
  }
};

// export default  Transactions;

export default connect(
  mapStateToProps
)(ViewLogic);


// export default ViewLogic;
