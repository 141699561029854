
function updateTreeDataNow(treeData) {
    console.log(treeData)
    return {
        type : 'updateTreeDataNow',
        treeData : treeData
    }
}

export function updateTreeData (treeData) {

    return ( dispatch) => {
        dispatch(updateTreeDataNow(treeData))
    }
}


function currentData(data) {
    console.log(data)
    return {
        type : 'currentNodeData',
        // treeNodeDisplay : data.treeNodeDisplay,   
        current_NodeData : data
    }
}


export function currentNodeData (data) {
    
    return ( dispatch) => {
        dispatch(currentData(data))
    }
}

function flushCurrentNodeData() {
    console.log("flush")
    return {
        type : 'flushCurrentNode',
    }
}

export function flushCurrentNode () {
    
    return ( dispatch) => {
        dispatch(flushCurrentNodeData())
    }
}

function flushTempNewChildData() {
    console.log("flushTempNewChild")
    return {
        type : 'flushTempNewChild',
    }
}

export function flushTempNewChild () {

    return ( dispatch) => {
        dispatch(flushTempNewChildData())
    }
}

function shalliAddChild(data) {
    return {
        type : 'shalliAddChild',
        shalliAddChild : data
    }
}

export function addShalliAddChild (data) {

    return ( dispatch) => {
        dispatch(shalliAddChild(data))
    }
}

function tempNewChild(key, val) {
    return {
        type : 'tempNewChild',
        tempNewChild_key : key,
        tempNewChild_val : val
    }
}

export function addTempNewChild (key, val) {

    return ( dispatch) => {
        dispatch(tempNewChild(key, val))
    }
}

function addSetOf_Node(data) {
    return {
        type : 'copySetOfNode',
        setOfCopiedNode : data
    }
}

export function copySetOfNode (data) {

    return ( dispatch) => {
        dispatch(addSetOf_Node(data))
    }
}