import React, { Component } from 'react';
class MyForm extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '', age: '', gender: '', address: '' };
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

    }
    mySubmitHandler = (event) => {
        event.preventDefault();
    }
    renderingChildren = (childrenNode) => {
    }

    render() {
        return (
            <div className='view-container' >
                {this.props.tree.children.map((treenode, index) => {
                        if (treenode.type === 'section' && treenode.view === 'form' && treenode.enable === true) {
                                return (<div className='form-container' key={index}>
                                {
                                    treenode.children.map((childrenNode, index) => {
                                        if (childrenNode.position === 'header' && childrenNode.type === 'field' && childrenNode.enable === true) {
                                            return (<div key={index} className="row p-3">
                                                <div className="col-lg-2">{childrenNode.title}:</div>
                                                <input type='text' className="col-lg-2" name="{childrenNode.title}" onChange={this.myChangeHandler} />
                                            </div>
                                            )
                                        }
                                    })
                                }
                                <div className='section-title'>{treenode.short_text}</div>

                                <div className='row'>
                                    <form onSubmit={this.mySubmitHandler} className="col-lg-12">
                                        {
                                            treenode.children.map((childrenNode, index) => {
                                                if ((childrenNode.position === undefined || (childrenNode.position !== 'header' && childrenNode.position !== 'footer')) && childrenNode.enable === true) {
                                                    return (<fieldset key={index} className={childrenNode.children !== undefined ? 'field-container col-lg-12 group-container' : 'field-container col-lg-12'}>{
                                                        (childrenNode.children === undefined && childrenNode.type === 'field') ? <div className="row">
                                                            <div className="col-lg-4">{childrenNode.title}:</div>
                                                            <input type='text' className="col-lg-6" name={childrenNode.title} onChange={this.myChangeHandler} />
                                                        </div> : (childrenNode.children !== undefined && childrenNode.type === 'group') ? <legend>{childrenNode.title}</legend> : null
                                                    }
                                                        {childrenNode.children !== undefined ? childrenNode.children.map((grandChild, index) => {
                                                            return (<div className="row field-container" key={index}>

                                                                <div className="col-lg-4">{grandChild.title}:</div>
                                                                <input type='text' name="{grandChild.title}" className="col-lg-4" onChange={this.myChangeHandler} />
                                                            </div>)
                                                        }) : null}
                                                    </fieldset>)
                                                }
                                            })
                                        }
                                        <input type='submit' className="btn btn-info"/>
                                        <div>
                                            {
                                                treenode.children.map((childrenNode, index) => {
                                                    if (childrenNode.position === 'footer' && childrenNode.type === 'field' && childrenNode.enable === true) {
                                                        return (<div className="row p-3 d-flex flex-row-reverse">
                                                            <input type='text' className="col-lg-2" name="{childrenNode.title}" onChange={this.myChangeHandler} />
                                                            <div className="col-lg-2">{childrenNode.title}:</div>
                                                            
                                                        </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>)
                        }
                    })
                }


            </div >
        );
    }
}
export default MyForm

