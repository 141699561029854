import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from "../../globalhelper/helper";
import DataTable from "../../components/DataTable2";
import { connect } from 'react-redux';
import { navUrl } from '../../actions/common_actions';


class TANtoCustomerMapping extends Component {
    constructor(props) {
        super(props)
        this.currentState = this.props.state;
        this.userData = this.currentState.login.userData;
    
      this.state = {
          fetching : false,
          JSON_ARRAY : [],
          columns : [
            {
                name: 'nameaspersales',
                selector: row => row["nameaspersales"],
                sortable: true,
            },
            {
                name: 'account',
                selector: row => row["account"],
                sortable: true,
            },
            {
                name: 'nameasperform26as',
                selector: row => row["nameasperform26as"],
                sortable: true,
            },
            {
                name: 'tan',
                selector: row => row["tan"],
                sortable: true,
            },
            {
                name: 'Confidence',
                selector: row => row["Confidence"],
                sortable: true,
            }
          ]
      }
    }


    componentDidMount = () => {

        this.setState({ fetching: true },
        () => {

            getRequest('api/tds/getTAN_Mapping')
            .then(res => {
                let data = JSON.parse(res.res.data)
                
                console.log(data)
                this.setState({ JSON_ARRAY : data, fetching : false })
               
            })
            .catch(err => {
                console.log(err)
            })
        })


    }

    render() {
        return (
          <div>
            <Fragment>
                <DataTable title="TAN to Customer Mapping" loading={this.state.fetching} columns={this.state.columns} key={this.state.JSON_ARRAY} data={this.state.JSON_ARRAY} />
            </Fragment> 
          </div>
        )
      }
}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(TANtoCustomerMapping);


