// import React, { useState, useEffect, Fragment } from 'react';
// import { getRequest } from '../../globalhelper/helper';
// import { Form, Button } from 'react-bootstrap';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { CSVLink } from 'react-csv';

// const FunctionalProfitAndLoss = () => {
//   const [companyDetails, setCompanyDetails] = useState([]);
//   const [companyName, setCompanyName] = useState([]);
//   const [formData, setFormData] = useState({
//     fromDate: '',
//     toDate: '',
//     company: '',
//     theme: '',
//   });
//   const [apiResponse, setApiResponse] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedColumn, setSelectedColumn] = useState(''); // State for selected column
//   const [filterValue, setFilterValue] = useState(''); // State for filter value

//   useEffect(() => {
//     getRequest(`api/edureka/getCompanyAssignedToUser`)
//       .then((response) => {
//         const responseData = response.res.data;
//         console.log(responseData);
//         const orgname = responseData.map((item) => item.orgname);
//         setCompanyDetails(responseData);
//         setCompanyName(orgname);
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     setFormData((prevState) => {
//       return { ...prevState, [name]: value };
//     });
//   };

//   const handleSubmit = () => {
//     const { fromDate, toDate, company, theme } = formData;
//     if (fromDate && toDate && company && theme) {
//       setLoading(true);
//       const id = companyDetails.filter((item) => item.orgname === company);
//       const companyid = id.map((item) => item.orgid);
//       getRequest(
//         `api/edureka/getFunctionalProfitAndLoss?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}&theme=${theme}`
//       )
//         .then((response) => {
//           console.log(response);
//           const responseData = response.res.data.processed_data;
//           console.log(responseData);
//           setLoading(false);
//           setApiResponse(
//             responseData.map((row) => {
//               const formattedRow = {};
//               for (const key in row) {
//                 // Check if the key is 'Costallocationmethod' and if the value is '-'
//                 if (key === 'costallocationmethod' && row[key] === '-') {
//                   formattedRow[key] = "CAM not defined";
//                 } else {
//                   formattedRow[key] =
//                     typeof row[key] === 'number' ? row[key].toFixed(2) : row[key];
//                 }
//               }
//               return formattedRow;
//             })
//           );
//         })
//         .catch((err) => {
//           setLoading(false);
//           console.log(err);
//           toast.error('Oops something went wrong');
//         });
//     } else {
//       toast.error('Please select all the required fields');
//     }
//   };
  

//   const columnNames = Object.keys(apiResponse[0] || {}).filter(
//     (column) =>
//       !column.endsWith('-_Allocated') &&
//       !column.endsWith('-_Direct') &&
//       !column.endsWith('-_Total')
//   );

//   // Filter the data based on selected column and filter value
//   const filteredData = selectedColumn && filterValue
//     ? apiResponse.filter((row) => String(row[selectedColumn]) === filterValue)
//     : apiResponse;

//   const uniqueFilterValues = selectedColumn
//     ? [...new Set(apiResponse.map((row) => String(row[selectedColumn])))]
//     : [];

//     const capitalizeFirstLetter = (string) => {
//         return string.charAt(0).toUpperCase() + string.slice(1);
//       };

//   return (
//     <Fragment>
//       <div className="row">
//         <div className="col-md-3">
//           <Form.Group>
//             <Form.Label>Company</Form.Label>
//             <select className="spacing" name="company" value={formData.company} onChange={handleChange}>
//               <option value="" disabled>
//                 Select
//               </option>{companyName.sort().map((item, index) => (
//                   <option key={index} value={item}>{item}</option>
//                 ))}
//             </select>
//           </Form.Group>
//         </div>
//         <div className="col-md-3">
//           <Form.Group>
//             <Form.Label>From Date</Form.Label>
//             <input type="date" className="spacing" name="fromDate" value={formData.fromDate} onChange={handleChange}></input>
//           </Form.Group>
//         </div>
//         <div className="col-md-3">
//           <Form.Group>
//             <Form.Label>To Date</Form.Label>
//             <input type="date" className="spacing" name="toDate" value={formData.toDate} onChange={handleChange}></input>
//           </Form.Group>
//         </div>
//         <div className="col-md-3">
//           <Form.Group>
//             <Form.Label>Theme</Form.Label>
//             <select className="spacing" name="theme" value={formData.theme} onChange={handleChange}>
//               <option value="" disabled>
//                 Select
//               </option>
//               <option value="Theme1">Cost Allocation Theme</option>
//             </select>
//           </Form.Group>
//         </div>
//       </div>
//       {!loading && filteredData.length ? (
//       <div className="row" style={{ marginTop: '20px' }}>
//         <div className="col-md-3">
//           <Form.Group>
//             <Form.Label>Select Column to Filter</Form.Label>
//             <select className="spacing" name="selectedColumn" value={selectedColumn} onChange={(e) => {
//                 setSelectedColumn(e.target.value);
//                 setFilterValue(''); // Reset filter value when column changes
//               }}
//             >
//               <option value="">
//                 Select
//               </option>
//               {columnNames.map((column, index) => (
//                 <option key={index} value={column}>
//                   {column}
//                 </option>
//               ))}
//             </select>
//           </Form.Group>
//         </div>

//         {selectedColumn && (
//           <div className="col-md-3">
//             <Form.Group>
//               <Form.Label>Select Value to Filter</Form.Label>
//               <select className="spacing" name="filterValue" value={filterValue} onChange={(e) => setFilterValue(e.target.value)}>
//                 <option value="">
//                   Select
//                 </option>
//                 {uniqueFilterValues.map((value, index) => (
//                   <option key={index} value={value}>
//                     {value}
//                   </option>
//                 ))}
//               </select>
//             </Form.Group>
//           </div>
//         )}
//       </div>
//       ) : null}

//       <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//         <Button size="sm" onClick={handleSubmit} variant="success">
//           Submit
//         </Button>
//         {filteredData.length ? (
//           <CSVLink data={filteredData} filename="Functional Profit And Loss.csv" style={{ marginLeft: '10px' }}>
//             Download
//           </CSVLink>
//         ) : null}
//       </div>
      

//       {loading ? (
//         <div className="center">
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//           <div className="wave"></div>
//         </div>
//       ) : null}

// {!loading && filteredData.length ? (
//       <div className="scroll-table">
//         <table border="1" id="table-css">
//           <thead>
//             <tr>
//               {columnNames.map((column, index) => (
//                 <th key={index}>{capitalizeFirstLetter(column)}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((row, rowIndex) => (
//               <tr key={rowIndex}>
//                 {columnNames.map((column, columnIndex) => (
//                   <td key={columnIndex}>{row[column] || ''}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     ) : null}
//       <ToastContainer />
//     </Fragment>
//   );
// };
// export default FunctionalProfitAndLoss;






import React, { useState, useEffect, Fragment } from 'react';
import { getRequest } from '../../globalhelper/helper';
import { Form, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';

const FunctionalProfitAndLoss = () => {
    const [companyDetails, setCompanyDetails] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [formData, setFormData] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        theme: ""
    });
    const [apiResponse, setApiResponse] = useState([]);
    const [customApiResponse, setCustomApiResponse] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data;
            const orgname = responseData.map(item => item.orgname);
            setCompanyDetails(responseData);
            setCompanyName(orgname);
            if (orgname.length === 1) {
                setFormData(prevState => ({ ...prevState, company: orgname[0] }));
            }
        }).catch(err => console.log(err));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = () => {
        const { fromDate, toDate, company, theme } = formData;
        if (fromDate && toDate && company && theme) {
            setLoading(true);
            const id = companyDetails.find(item => item.orgname === company)?.orgid;
            getRequest(`api/edureka/getFunctionalProfitAndLoss?fromDate=${fromDate}&toDate=${toDate}&company=${id}&theme=${theme}`)
            .then(response => {
                const responseData = response.res.data.processed_data;
                setLoading(false);
    
                const updatedResponse = responseData.map(row => {
                    const formattedRow = {};
                    let grandTotal = 0;
    
                    for (const key in row) {
                        formattedRow[key] = typeof row[key] === 'number' ? row[key].toFixed(2) : row[key];
                        if (["B2C_Total", "B2B_Total", "HED_Total", "PGP_Total", "ELC_Total", "VLAB_Total", "UnIdentified_Total"].includes(key)) {
                            grandTotal += parseFloat(row[key]) || 0;
                        }
                    }
                    formattedRow["GrandTotal"] = grandTotal.toFixed(2);
                    return formattedRow;
                });
    
                setApiResponse(updatedResponse);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                toast.error("Oops something went wrong");
            });
        } else {
            toast.error("Please select all the required fields");
        }
    };
    const handleCustomButtonClick = () => {
        const { fromDate, toDate, company } = formData;
        if (fromDate && toDate && company) {
            const id = companyDetails.find(item => item.orgname === company)?.orgid;
            if (id) {
                getRequest(`api/edureka/reconReport?fromDate=${fromDate}&toDate=${toDate}&orgid=${id}`)
                    .then(response => {
                        const customData = JSON.parse(response.res.data);
                        setCustomApiResponse(customData.map(row => {
                            const formattedRow = {};
                            for (const key in row) {
                                formattedRow[key] = typeof row[key] === 'number' ? row[key].toFixed(2) : row[key];
                            }
                            return formattedRow;
                        }));
                        handleShowModal(); // Show the modal
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error("Error in sending data");
                    });
            } else {
                toast.error("Company ID not found");
            }
        } else {
            toast.error("Please select all the required fields");
        }
    };

    const columnNames = Object.keys(apiResponse[0] || {});

    const staticColumnHeaders = [
      "ApprovalStatus",
      "transactiontype",
      "createdid",
      "category",
      "ExpenseAccounted",
      "UnAllocatedAmount",
      "AllocationAmount",
      "diff"
    ];
    const uniqueColumnNames = [...new Set([...columnNames, "GrandTotal"])];

    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                    <Form.Label>Company</Form.Label>
                    {companyName.length === 1 ? (
                        // If there's only one company, show it as text or disabled input
                        <input
                        type="text"
                        className="spacing"
                        name="company"
                        value={companyName[0]}
                        readOnly // Prevents editing
                        />
                    ) : (
                        // If there are multiple companies, show the dropdown
                        <select
                        className="spacing"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        >
                        <option value="" disabled>Select</option>
                        {companyName.sort().map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                        </select>
                    )}
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>From Date</Form.Label>
                        <input type='date' className='spacing' name='fromDate' value={formData.fromDate} onChange={handleChange}></input>
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>To Date</Form.Label>
                        <input type='date' className='spacing' name='toDate' value={formData.toDate} onChange={handleChange}></input>
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Theme</Form.Label>
                        <select className='spacing' name='theme' value={formData.theme} onChange={handleChange}>
                            <option value="" disabled>Select</option>
                            <option value="Theme1">Cost Allocation Theme</option>
                            {/* Add other themes as needed */}
                        </select>
                    </Form.Group>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Button size='sm' onClick={handleSubmit} variant='success'>Submit</Button>
                {apiResponse.length ? (
                    <CSVLink data={apiResponse} filename='Functional_Profit_And_Loss.csv' style={{ marginLeft: '10px' }}>
                        Download
                    </CSVLink>
                ) : null}
            </div>
            {!loading && apiResponse.length ? (
            <div>
                <Button size='sm' onClick={handleCustomButtonClick} variant='primary'>Show Recon (FinAc&CostAllocation)</Button>
            </div>
            ) : null}

            {loading ? (
                <div className='center'>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
            ) : null}

            

            {!loading && apiResponse.length ? (
                <div className='scroll-table'>
                    <table border='1' id='table-css'>
                        <thead>
                            <tr>
                                {uniqueColumnNames.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {apiResponse.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {uniqueColumnNames.map((column, columnIndex) => (
                                        <td key={columnIndex}>{row[column] || ""}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}

            <Modal show={showModal} onHide={handleCloseModal} size="xl" style={{marginLeft: "90px"}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recon (FinAc&CostAllocation)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {customApiResponse.length ? (
                      <div className='scroll-table'>
                          <table border='1' id='table-css'>
                              <thead>
                                  <tr>
                                      {staticColumnHeaders.map((column, index) => (
                                          <th key={index}>{column}</th>
                                      ))}
                                  </tr>
                              </thead>
                              <tbody>
                                  {customApiResponse.map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                          {staticColumnHeaders.map((column, columnIndex) => (
                                              <td key={columnIndex}>{row[column] || ""}</td>
                                          ))}
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      </div>
                  ) : <p>No data available</p>}
              </Modal.Body>
                <Modal.Footer>
                    <CSVLink data={customApiResponse} filename='Recon (FinAc&CostAllocation).csv'>Download</CSVLink>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </Fragment>
    );
};

export default FunctionalProfitAndLoss;
