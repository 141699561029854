import React, { Component, Fragment } from 'react';
import './FormBasicInput';
import {connect} from 'react-redux';
import './COD.css';
import './LARPopup.css';
import FormBasicInput from './FormBasicInput';
import { getCurrentDate, postRequest } from '../../globalhelper/helper'

export class LARPopup extends Component {
    constructor(props) {
      super(props)

      let value = this.props.item
    
      this.state = {
         formData: {
            client: value.client,
            model: value.businessmodel,
            zone: value.zone,
            stationcode: value.stationcode,
            category: value.sourcetype,
            amount: value.amount,
            remarks: value.remarks,
            date: getCurrentDate(),
            postedby: this.props.state.login.userData.USR_Name,
            receiptname: value.attachment,
            selectedFile: null
         }
      }
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleBack = () => {
        window.location.reload()
    }

    handleUpdate = async (e) => {
        e.preventDefault()

        const pk = this.props.item.pk
        const sk = this.props.item.sk

        await postRequest(`api/cod/deleteRecoveryHistoryRecords`, {pk:pk, sk:sk}).then(response => {
            if(response.res.status === 'success'){
                postRequest(`api/cod/recoveryData`, this.state.formData).then(res => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 7000)
                })
            } else {
                console.log('Record Cannot be Updated')
            }
        })

    }
  render() {
    return (
      <Fragment>
        <div className='header_div'>
            Edit Loss And Recovery
        </div>

        <br/>

        <div className='basic_outline'>

            <FormBasicInput label='Client' value={this.state.formData.client} type='text' name='client' disabled={true}/>

            <FormBasicInput label='Model' value={this.state.formData.model} type='text' name='model' disabled={true}/>

            <FormBasicInput label='Zone' value={this.state.formData.zone} type='text' name='zone' disabled={true}/>

            <FormBasicInput label='Station Code' value={this.state.formData.stationcode} type='text' name='stationcode' disabled={true}/>

            <FormBasicInput label='Category' value={this.state.formData.category} type='text' name='category' disabled={true}/>

            <FormBasicInput label='Amount' value={this.state.formData.amount} type='number' name='amount' disabled={false} onChange={this.handleChange}/>

            <FormBasicInput label='Remarks' value={this.state.formData.remarks} type='text' name='remarks' disabled={true}/>

            <br/>

            <div style={{display:'inline-block'}}>
                <button className='btn red_button' style={{marginLeft:500}} onClick={this.handleBack}>Back</button>
                <button className='btn green_button' style={{marginLeft:10}} onClick={this.handleUpdate}>Update</button>
            </div>

        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (LARPopup)