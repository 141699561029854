import React from 'react';

import DateForm from './DateForm';
import Input from './Input';
import SelectForm from "./SelectForm";
import { Button } from "react-bootstrap";
import { getStandardDate } from "../globalhelper/helper";
import { connect } from 'react-redux';
import NearMeIcon from '@material-ui/icons/NearMe';
import { navUrl } from "../actions/common_actions";
import { useDispatch } from "react-redux";

import { toggleVRScreenMode } from "../actions/treeAction";
import ViewLogic from './ViewLogic';
import GetOutputField from './BOTOutputs';


function GetInputField(props) {

    const dispatch = useDispatch();

    const getScreen = () => {
        // useHistory('/home')
        dispatch(toggleVRScreenMode())
        dispatch(navUrl('/home/' + getStandardDate(props.store.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom) + "/" + getStandardDate(props.store.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo)));
    }
    return (    
        <>
            { props.option.Input === null || props.option.Input === "BOT" ?
            props.option.FieldInputType === "date" ? <div className="col-md-6">  <DateForm label={props.option.parent_short_text} modelName={props.option.parent_name} /> </div>
                :
                props.option.FieldInputType === "dropdown" ? <div className="col-md-6">  <SelectForm label={props.option.parent_short_text} modelName={props.option.parent_name} options={props.option.list} ></SelectForm> </div>
                    :
                    props.option.parent_name === "OCLCT1_BOTPost_VRScreenName" ? <div className="col-md-6">  <Button style={{ borderRadius: "15px" }} className="m-4 selection-button" onClick={getScreen}> <NearMeIcon className="selection-icon" /> Select From Screen</Button></div>
                        :
                        props.option.FieldInputType === "input" ?
                            <Input className="col-md-6" label={props.option.parent_short_text} modelName={props.option.parent_name}></Input>
                            // <p> {props.store.chatbotFormInputs["OCLCT1_Parameter_CiIdentity"]} </p>
                            : null
                : props.option.Input !== null && props.option.Input.includes("VR") ? 
                    <GetOutputField option={props.option} index={props.index} length={props.length}  ></GetOutputField>
                : null
            }
            {props.index === props.length - 1 && props.option.name === "OCLCT1_Intent_CIPSelectionScreen" && props.option.parent_name !== "OCLCT1_Intent_GetMainErrClass" && props.store.chatbotFormInputs.selectedObj && JSON.stringify(props.store.chatbotFormInputs.selectedObj) === "{}" ?
                <div style={{ marginTop : 15 }}>
                    <Button variant="primary" varient="primary" className="m-3" style={{ height: '25px', maxHeight: '25px', marginTop: 'auto', marginBottom: 'auto' }} onClick={getScreen} >Select From Screen</Button> 
                </div>
                :
                props.option.parent_name === "OCLCT1_Intent_GetMainErrClass" && typeof props.store.chatbotFormInputs.OCLCT1_BOTInput_ClientStationCode === "undefined" ?
                <Button  variant="primary" className="m-3" style={{ height: '25px', maxHeight: '25px', marginTop: 'auto',  marginBottom: 'auto' }} >Next</Button> :
                <div style={{ marginTop : 22 }}>
                    <ViewLogic options={props.option.children} intent={props.option.parent_short_text} haveSiblings={props.option.children.length} type={props.option.parent_type} title={props.option.parent_short_text} ></ViewLogic>
                </div>
            }
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        store: state
    }
};

// export default  Transactions;

export default connect(
    mapStateToProps
)(GetInputField);

// export default GetInputField;