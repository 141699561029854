import { useEffect, useState } from "react"
import React from 'react';
import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
import '../CI/COD.css';
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';

// getBookClosureRecord
// api/vr2/getBookClosureRecord?Company=${Company}&Year=${Year}&Month=${Month}


const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const Select = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const SelectMonth = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const SelectType = ({label, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    <option value="CreditCard">Credit Card</option>
                    <option value="Journal">Journal</option>
                    <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
                    <option value='EmployeeCost'>Employee Cost</option>
                    <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
                    <option value='EmployeeCostFnF'>Employee Cost FnF</option>
                    <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
                    <option value="EmployeeReimbursement">Employee Reimbursement</option>
                    <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
                    <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
                    <option value='InstructorInvoice'>Instructor Invoice</option>
                    <option value='Master'>Master List (Bill)</option>
                    <option value='Payment'>Payment</option>
                    <option value='PettyCash'>Petty Cash</option>
                    <option value='PeriodEndProcess'>Period End Process</option>
                    <option value='Transaction'>Transaction List (Bill)</option>
                </select>
            </Form.Group>
        </div>
    )
}

const TallyInputUserSelect = () => {

    const [queryResult, setQueryResult] = useState([])

    const [companyDetails, setCompanyDetails] = useState([])

    const [selectedItems, setSelectedItems] = useState([])

    const [selectedRowData, setSelectedRowData] = useState([])

    const [loading, setLoading] = useState(false)

    const [selectAll, setSelectAll] = useState(false)

    const [showModal, setShowModal] = useState(false)

    const [showSpinner, setShowSpinner] = useState(false)

    const [masterLedgerInfo, setMasterLedgerInfo] = useState([])

    const [permanentData, setPermanentData] = useState([])

    const [formData, setFormData] = useState({
        transtype: '',
        fromDate: '',
        toDate: '',
        companyName: ''
    })

    const [month, setMonth] = useState("")

    const [year, setYear] = useState("")

    const [monthInInt, setMonthInInt] = useState("")

    const [showPopup, setShowpopup] = useState(false)

    const [filterTable, setFilterTable] = useState('')

    const [dropDown, setDropDown] = useState({
        parentName: [],
        primaryGroup: []
    })

    const [showError, setShowError] = useState(false)

    const [warningMessage, setWarningMessage] = useState('')

    const [companies, setCompanies] = useState([])

    const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

    const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

    const [companyCode, setCompanyCode] = useState("")

    useEffect(() => {
        if(companyCode !== ""){
            getRequest(`api/edureka/getLedgerNameForTally?companycode=${companyCode} `).then(response => {
                console.log(JSON.parse(response.res.data))
                const data = JSON.parse(response.res.data)
                const tempParentName = data.filter(item => item !== undefined).map((item) => item["Parent Name"])
                const tempPrimaryGroup = data.filter(item => item !== undefined).map((item) => item["Primary Group"])
                setDropDown({
                    parentName: [...new Set(tempParentName)],
                    primaryGroup: [...new Set(tempPrimaryGroup)]
                })
            })
        }
    }, [companyCode])

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanies(orgname)
        }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if(formData.companyName !== ""){

            const id = companyDetails.find(item => item.orgname === formData.companyName)
            const companyid = id.orgid
            setCompanyCode(companyid)
        }

    }, [formData.companyName])

    const handleChange = () => {
        const {fromDate, toDate, transtype, companyName} = formData
        if(fromDate && toDate && transtype !== 'Master' && transtype !== 'Journal' && transtype !== 'Transaction' && transtype !== 'CreditCard' && transtype !== 'PettyCash' && transtype !== 'InstructorInvoice' && transtype !== 'PeriodEndProcess' && transtype !== 'DigitalWalletAccounting' && transtype !== 'Payment' && transtype !== "EmployeeReimbursement" && companyName && transtype){
            setShowError(false)
            setWarningMessage('')
            setSelectedItems([])
            setSelectedRowData([])
            setSelectAll(false)
            setShowSpinner(false)
            setLoading(true)
            setMasterLedgerInfo([])

            getRequest(`api/vr2/getBookClosureRecord?Company=${companyCode}&Year=${year}&Month=${monthInInt}`).then(resp => {
                if(resp.res.status === "success"){
                    let data = resp.res.data
                    if(data.Bill === "close" && formData.transtype === "Transaction"){
                        setLoading(false)
                        setShowpopup(true)
                    } else {
                        Promise.all([getRequest(`api/edureka/getTallyDataForOtherReports?startDate=${formData.fromDate}&endDate=${formData.toDate}&type=${formData.transtype}&companycode=${companyCode}`), getRequest(`api/edureka/getDynamoLogsForTally`)]).then(([tallyResponse, statusResponse]) => {
                            console.log(JSON.parse(tallyResponse.res.data))
                            const tallyRes = JSON.parse(tallyResponse.res.data)
                            if(!tallyRes.length){
                                setShowError(true)
                                setWarningMessage("No data to display..Please try some other combination")
                            }
                            const statusRes = statusResponse.res.data
                            console.log(statusRes)
             
                                console.log(tallyRes)
                                const res = tallyRes
                                    .filter((item) => item["Ledger Name"] !== undefined)
                                    .map((item, index) => {
                                        return item
                                    })
            
                                console.log(res)
                                
                                const combinedArray = tallyRes.map(item => {
                                    if(statusRes){
                                        // const matched = statusRes.find(val => item["Accounting Journal"] === val.ajlist)
                                        
                                        const matched = statusRes.find(val => val.SK === item["Accounting Journal"] )
            
                                        if(matched){
                                            return {...item, ...matched}
                                        } else {
                                            return item
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                console.log(combinedArray)
                                setQueryResult(combinedArray)
                                setPermanentData(combinedArray)
                                setLoading(false)
                                          
                        }).catch(err => console.log(err))

                    }
                }
            })

        }
        else if(fromDate && toDate && transtype && companyName){
            setShowError(false)
            setWarningMessage('')
            setSelectedItems([])
            setSelectedRowData([])
            setSelectAll(false)
            setShowSpinner(false)
            setLoading(true)
            setMasterLedgerInfo([])

            getRequest(`api/vr2/getBookClosureRecord?Company=${companyCode}&Year=${year}&Month=${monthInInt}`).then(resp => {
                if(resp.res.status === "success"){
                    let data = resp.res.data
                    if(data.Bill === "close" && formData.transtype === "Transaction"){
                        setLoading(false)
                        setShowpopup(true)
                    } else {
                        
                        Promise.all([getRequest(`api/edureka/getTallyDataFENew?startDate=${formData.fromDate}&endDate=${formData.toDate}&type=${formData.transtype}&companycode=${companyCode}`), getRequest(`api/edureka/getDynamoLogsForTally`)]).then(([tallyResponse, statusResponse]) => {
                            console.log(JSON.parse(tallyResponse.res.data))
                            const tallyRes = JSON.parse(tallyResponse.res.data)
                            if(!tallyRes.length){
                                setShowError(true)
                                setWarningMessage("No data to display..Please try some other combination")
                            }
                            const statusRes = statusResponse.res.data
                            console.log(statusRes)
            
                            if(formData.transtype === 'Master'){
                                console.log(tallyRes)
                                const res = tallyRes
                                    .filter((item) => item["Ledger Name"] !== undefined)
                                    .map((item, index) => {
                                        return item
                                    })
            
                                console.log(res)
                                
                                const combinedArray = res.map(item => {
                                    if(statusRes){
                                        const matched = statusRes.find(val => val.SK === item["Accounting Journal"] )
                                        if(matched){
                                            return {...item, ...matched}
                                        } else {
                                            return item
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setQueryResult(combinedArray)
                                setPermanentData(combinedArray)
                                setLoading(false)
                            }   
                            else if (formData.transtype === 'Transaction' || formData.transtype === 'Journal' || formData.transtype === 'CreditCard' || formData.transtype === 'PettyCash' || formData.transtype === 'InstructorInvoice' || formData.transtype === 'PeriodEndProcess' || formData.transtype === "DigitalWalletAccounting" || formData.transtype === "Payment" || formData.transtype === "EmployeeReimbursement"){
            
                                const res = tallyRes
                                    .filter((item) => item["Ledger Name"] !== undefined)
                                    .map((item, index) => {
                                        return item
                                    })
            
                                const combinedArray = res.map(item => {
                                    if(statusRes){
                                        const matched = statusRes.find(val => val.SK === item["Accounting Journal"] ) 
                                        if(matched){
                                            return {...item, ...matched}
                                        } else {
                                            return item
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                console.log(combinedArray)
                                setQueryResult(combinedArray)
                                setPermanentData(combinedArray)
                                setLoading(false)
                            }               
                        }).catch(err => console.log(err))

                    }
                } else {
                    setShowError(true)
                    setWarningMessage("No data to display..Please try some other combination")
                    setLoading(false)

                }
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })

        } else {
            setShowError(true)
            setWarningMessage("Please fill all the required fields")
            console.log("Please fill all the required fields")
        }       
    }

    const handleCheckboxChange = (index) => {
        console.log(index)
        const selected = [...selectedItems]
        if(selected.includes(index)){
            selected.splice(selected.indexOf(index), 1)
        } else {
            selected.push(index)
        }

        console.log(selected)

        // const selectedRows = selected.map(selectedIndex => queryResult[selectedIndex]["Accounting Journal"])

        setSelectedItems(selected)
        setSelectedRowData(selected)
    }

    const handleSelectAll = () => {
        if(selectAll){
            console.log("SelectAll")
            setSelectedItems([])
            setSelectedRowData([])
        } else {
            const allIndices = Array.from({length: queryResult.length}, (_, i) => i)
            setSelectedItems(allIndices)

            const filteredColumns = queryResult.filter((item) => item.Status === 'Initiated' || item.Status === 'Success')
            console.log(filteredColumns)

            const selectedRows = allIndices.map((index) => queryResult[index]["Accounting Journal"]).filter((item) => !filteredColumns.some((filteredItem) => filteredItem["Accounting Journal"] === item))
            console.log(selectedRows)
            setSelectedItems(selectedRows)
            setSelectedRowData(selectedRows)
        }
        setSelectAll(!selectAll)
    }

    const handlePush = () => {
        setShowSpinner(true)
        if(formData.transtype !== 'Master' && formData.transtype !== 'Transaction' && formData.transtype !== 'Journal' && formData.transtype !== 'CreditCard' && formData.transtype !== 'PettyCash' && formData.transtype !== 'InstructorInvoice' && formData.transtype !== 'PeriodEndProcess' && formData.transtype !== "DigitalWalletAccounting" && formData.transtype !== "Payment" && formData.transtype !== "EmployeeReimbursement"){
            const request = {
                selectedItems : queryResult,
                company: formData.companyName,
                type: formData.transtype,
                companycode: companyCode
            }
            postRequest(`api/edureka/convertJSON`, request).then(response => {
                console.log(response)
                handleChange()
            }).catch(err => {
                console.log(err)
                setQueryResult([])
                setPermanentData([])
                setShowError(true)
                setWarningMessage("Oops!! Something went wrong please try again after sometime")
            })
        }
        else {
            console.log("Entered else condition")
            const request = {
                selectedRowData: selectedRowData,
                ledgerInfo: masterLedgerInfo,
                company: formData.companyName,
                type: formData.transtype,
                companycode : companyCode
            }
            console.log(request);
            
            postRequest(`api/edureka/pushToTallyViaSFTP`, request).then(response => {
                console.log(response)
                handleChange()
            }).catch(err => {
                console.log(err)
                setQueryResult([])
                setPermanentData([])
                setMasterLedgerInfo([])
                setShowError(true)
                setWarningMessage("Oops!! Something went wrong please try again after sometime")
            })
        }
    }

    const handleDateChange = (e) => {
        console.log(e.target.value)
        const {name, value} = e.target
        console.log(name, value)
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleTypeChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleMonthChange = (e) => {
        setMonth(e.target.value)
    }

    const handleYearChange = (e) => {
        setYear(e.target.value)
    }

    useEffect(() => {
        const selectedYear = year
        const selectedMonth = month 

        const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
        const startDate = new Date(selectedYear, monthIndex, 1)
        const endDate = new Date(selectedYear, monthIndex + 1, 0)

        console.log(getStandardDate(startDate), getStandardDate(endDate))
        const monthInNumber = monthIndex + 1 < 10 ? "0" + Number(monthIndex + 1) : Number(monthIndex + 1)
        setMonthInInt(monthInNumber)

        setFormData(prev => {
            return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
        })
    }, [year, month])

    const handleReset = () => {
        setFormData({
            transtype:'',
            fromDate:'',
            toDate:'',
            companyName: ''
        })
        setSelectedItems([])
        setSelectedRowData([])
        setSelectAll(false)
        setQueryResult([])
        setShowError(false)
        setWarningMessage('')
        setLoading(false)
        setMasterLedgerInfo([])
        setFilterTable('')
        setYear('')
        setMonth('')
    }

    const handleItemSelect = (val, e) => {
        const {name, value} = e.target
        console.log(val)
        console.log(e.target.value)
        const data = [...queryResult]
        console.log(val["Accounting Journal"])

        const mappedData = data.map((item, index) => { 
            if(item["Ledger Name"] === val["Ledger Name"]){
                return {...item, [name] : value}               
            } else {
                return item
            }
        })

        setQueryResult(mappedData)

        console.log(mappedData)

        const masterList = mappedData.map(item => {
            return {
                "ledger_name": item["Ledger Name"] || '',
                "account_group": item["parentName"] || '',
                "primary_group": item["primaryGroup"] || ''
            }
        })

        const uniqueArray = masterList.filter((item, index, array) => {
            const isDuplicate = array.findIndex((element) =>
                element.ledger_name === item.ledger_name &&
                element.account_group === item.account_group &&
                element.primary_group === item.primary_group
            ) === index;
        
            return isDuplicate;
        });

        setMasterLedgerInfo(uniqueArray)

        console.log(uniqueArray)

        console.log(masterList)
    }

    const handleFilterTable = (e) => {
        setFilterTable(e.target.value)
        console.log(e.target.value)

        let data = []

        if(e.target.value === 'No'){
            data = permanentData.filter(item => item.Status === undefined)
        } else {
            data = permanentData.filter(item => item.Status === e.target.value)
        }
           
        console.log(data)
        setQueryResult(data)
        // setQueryResult(data)
    }

  return (
    <>
        {/* <div style={{alignItems:'left', fontSize:'large', fontWeight:'bolder', fontFamily:'sans-serif', marginBottom:'20px'}}>
            Tally Input
        </div> */}

        <form style={{marginTop: '20px'}} onSubmit={handleChange}>

            <div className="row">
                <div className="col-md-2">
                    <Select label="Company" name="companyName" options={companies} value={formData.companyName} onChange={handleDateChange} />
                </div>

                <div className="col-md-2">
                    <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
                </div>

                <div className="col-md-2">
                    <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
                </div>

                <div className="col-md-2">
                    <SelectType label="Type" name="transtype" value={formData.transtype} onChange={handleTypeChange} />
                </div>

                {!loading && permanentData.length ?

                <div className="col-md-2">
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <select className="spacing" name="filterTable" value={filterTable} onChange={handleFilterTable} >
                            <option value='' disabled>Select</option>
                            <option value='Initiated'>Initiated</option>
                            <option value="No">Not Processed</option>
                            <option value="Failed">Failed</option>
                            <option value="Success">Success</option>
                        </select>
                    </Form.Group>
                </div> : null }
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size="sm" onClick={handleChange} variant="success">Submit</Button>
                <Button size="sm" style={{marginLeft:'10px'}} onClick={handleReset} variant="danger">Reset</Button>
                {selectedRowData.length && !showSpinner ?
                <Button size="sm" style={{marginLeft:'10px'}} disabled={formData.transtype !== 'Transaction' && formData.transtype !== 'Master' && formData.transtype !== 'CreditCard' && formData.transtype !== 'PettyCash' && formData.transtype !== 'PeriodEndProcess' && formData.transtype !== "DigitalWalletAccounting" && formData.transtype !== "Payment" && formData.transtype !== "EmployeeReimbursement" && formData.transtype !== "Journal"} variant="warning" onClick={handlePush}>
                        Push to ERP
                </Button>
                : null }

                {showSpinner ?
                <Button style={{marginLeft:'10px'}} variant="warning" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
                // <Spinner animation="border" style={{marginLeft:'10px'}} variant="warning"></Spinner>
                 : null}
            </div>
        </form>


        {/* {showModal &&
            <div>
                <Modal show={true} onHide={false} backdrop='static'>
                    <Modal.Header>
                        <Modal.Title style={{fontSize:'1rem', alignContent:'center'}}>
                            Few items are waiting to be sent to tally, so journals which you've selected will be added to
                            to the queue and you have to trigger it later. Do you still want to continue?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                        <Button size="sm" variant="success">Yes</Button>
                        <Button size='sm' variant="danger" onClick={()=>setShowModal(false)}>No</Button>
                    </Modal.Footer>
                </Modal>
            </div>} */}

        {!loading && queryResult.length ?
        <div>
            <div align='right'>
                <CSVLink size='sm' className="btn btn-primary" filename='Tally.csv' data={queryResult.sort((a,b) => a["Accounting Journal"].localeCompare(b["Accounting Journal"]))} > <GetAppIcon /> </CSVLink>
            </div>
            <table border='1' id="table-css">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Status</th>
                        <th>Reason</th>
                        <th>Accounting Date</th>
                        <th>Accounting Journal</th>
                        {formData.transtype === 'Master' ?
                        <th style={{minWidth: '100px'}}>Parent Name</th> : null}
                        {formData.transtype === 'Master' ?
                        <th style={{minWidth:'100px'}}>Primary Group</th> : null}
                        <th>Debit (INR)</th>
                        <th>Credit (INR)</th>
                        <th>Ledger Name</th>
                        <th>Bill Number</th>
                        <th>Narration</th>
                    </tr>
                </thead>
                <tbody>
                    {queryResult.sort((a,b) => a["Accounting Journal"].localeCompare(b["Accounting Journal"])).map((item, index) => {
                        return(
                            <tr key={index} 
                            style={{
                                backgroundColor: selectedItems.includes(item["Accounting Journal"]) ? '#a0d3ff' : 'transparent',
                              }}
                            >
                                <td>
                                    <input
                                        disabled={item.Status === 'Initiated' || item.Status === 'Success'}
                                        type="checkbox"
                                        checked={selectedItems.includes(item["Accounting Journal"])}
                                        onChange={() => handleCheckboxChange(item["Accounting Journal"])}
                                        // onChange = {() => handleCheckboxChange(item)}
                                    />
                                </td>
                                <td>{item.Status}</td>
                                <td>{item.Reason}</td>
                                <td>{getHumanReadableDate(item["Accounting Date"])}</td>
                                <td>{item["Accounting Journal"]}</td>

                                {formData.transtype === 'Master' ?
                                <td style={{minWidth:'100px'}}>
                                    <select  name='parentName' value={item.parentName || ''} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={(e) => handleItemSelect(item, e)} required>
                                        <option value='' disabled>Select</option>
                                        {dropDown.parentName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                    </select>
                                </td> : null}

                                {formData.transtype === 'Master' ?
                                <td style={{minWidth:'100px'}}>
                                    <select  name='primaryGroup' value={item.primaryGroup || ''} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={(e) => handleItemSelect(item, e)} required>
                                        <option value='' disabled>Select</option>
                                        {dropDown.primaryGroup.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                    </select>
                                </td> : null}
                                
                                <td style={{textAlign:'right'}}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
                                <td style={{textAlign:'right'}}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
                                <td>{item["Ledger Name"]}</td>
                                <td>{item["Bill No"]}</td>
                                <td>{item["Narration"]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

         {showError &&
         <div className="center-screen">
            {warningMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
            </div>: null}

        {showPopup ?
            <div>
                <Modal
                    show={showPopup}
                    onHide={false}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header>
                        <Modal.Title>
                            Oops! This action cannot be done
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {`${"The books have been closed for the selected period - "}`} {`${month}, ${year}`}
                         
                    </Modal.Body>
                    <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                        <Button  variant="danger" size="sm" onClick={() => setShowpopup(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
         : null}
    </>
  )
}

export default TallyInputUserSelect