import React, {Fragment, useEffect, useState} from 'react';
import { getRequest } from '../../globalhelper/helper';
import {Form, Button} from 'react-bootstrap'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const VendorListReport = () => {

    const [tableData, setTableData] = useState([])
    const [companyName, setCompanyName] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: ""
    })

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleReset = () => {
        setFormData({
            fromDate: "",
            toDate: "",
            company: ""
        })
        setTableData([])
    }

    const handleSubmit = () => {
        const {fromDate, toDate, company} = formData

        if(fromDate && toDate && company){
            setLoading(true)

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)
            console.log(companyid);
            

            getRequest(`api/edureka/getVendorListForReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`).then(response => {
                const responseData = response.res.data
                if(responseData.length){
                    setTableData(responseData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.warning("No data found, please try some other combination")
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                toast.warning("Oops something went wrong")
            })

        } else {
            toast.error("Please select all the required fields", {theme: "colored"})
        }
    }


  return (
    <Fragment>
        <form>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                            <Form.Label>Company</Form.Label>
                            <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                                <option value='' disabled>Select</option>
                                {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                        </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="Period From" name="fromDate" value={formData.fromDate} onChange={handleChange} />
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="Period To" name="toDate" value={formData.toDate} onChange={handleChange} />
                </div>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                {tableData.length ?
                <CSVLink data={tableData} filename='VendorList_Report.csv' style={{marginLeft:'10px'}}>Download</CSVLink> : null}
            </div>
        </form>

        {!loading && tableData.length ?
        <div>
            <table border="1" id="table-css">
                <thead>
                    <tr>
                        <th>Approval Status</th>
                        <th>Created DateTime</th>
                        <th>Modified DateTime</th>
                        <th>Vendor ID</th>
                        <th>Member Type</th>
                        <th>MSME</th>
                        <th>Member Group</th>
                        <th>Vendor Name</th>
                        <th>Currency</th>
                        <th>GST Treatment</th>
                        <th>Tax Preference</th>
                        <th>Vendor Tax Classification</th>
                        <th>Tax Regs</th>
                        <th>PAN</th>
                        <th>Payment Mode</th>
                        <th>Transaction Status</th>
                        <th>Yet to Approve Count</th>
                        <th>Account Holder Name</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th>Bank Type</th>
                        <th>Branch Name</th>
                        <th>IFSC Code</th>
                        <th>MICR Code</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.ApprovalStatus}</td>
                                <td>{item.CreatedDateTime}</td>
                                <td>{item.ModifiedDateTime}</td>
                                <td>{item.vendorid}</td>
                                <td>{item.membertype}</td>
                                <td>{item.msme}</td>
                                <td>{item.membergroup}</td>
                                <td>{item.vendorname}</td>
                                <td>{item.currency}</td>
                                <td>{item.gsttreatment}</td>
                                <td>{item.taxpreference}</td>
                                <td>{item.vendortaxclassification}</td>
                                <td>{item.TaxRegs}</td>
                                <td>{item.pan}</td>
                                <td>{item.paymentmode}</td>
                                <td>{item.transactionstatus}</td>
                                <td>{item.YettoApproveCnt}</td>
                                <td>{item.accountholdername}</td>
                                <td>{item.accountnumber}</td>
                                <td>{item.bankname}</td>
                                <td>{item.banktype}</td>
                                <td>{item.branchname}</td>
                                <td>{item.ifsccode}</td>
                                <td>{item.micrcode}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
        </div>: null}
        <ToastContainer/>
    </Fragment>
  )
}

export default VendorListReport