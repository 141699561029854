import React, { Component, Fragment } from 'react';
import {Button, Form} from 'react-bootstrap';
import {getRequest, getStandardDate, getPreviousDate, getHumanReadableDate} from '../../globalhelper/helper'
import './COD.css';
import { CSVLink } from 'react-csv';
import Pagination1 from './Pagination';

export class CODSubmissionStatus extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       fetching: false,
       currentDate: getStandardDate(new Date()),
       previousDate: getPreviousDate(new Date()),
       filteredData: [],
       formData: {
        client: '',
        model: '',
        zone: '',
        stationcode: ''
       },
       dropdown_client: [],
       dropdown_model: [],
       dropdown_zone: [],
       dropdown_stationcode: [],
       unique_stationcode: [],
       allData: [],
       uniqueData: [],
       uniqueModel: [],
       uniqueZone: [],
       uniqueStationCode: [],
       show_maintable: false,
       show_filtertable: false,
       tableDisplay: [],
       currentPage1: 1,
       currentPage2: 1,
       postsPerPage1: 15,
       postsPerPage2: 15,
       filterDisplay: [],
       filterTable: [],
       tableData: []
    }
  }

  componentDidMount(){

    this.setState({
      ...this.state,
      fetching: true
    })

    // On initial render gets the submission details of the current date

    getRequest(`api/cod/getCODSubmissionStatus?currentdate=${this.state.currentDate}`).then(res => {
      console.log(JSON.parse(res.res.data))
      if(JSON.parse(res.res.data) !== undefined){
        let tableData = JSON.parse(res.res.data)
    
        let client = []
        tableData.forEach(item => {
          client.push(item.client)
        })

        let dropdown_model = tableData.map(value => value.businessmodel)
        let dropdown_zone = tableData.map(value => value.zone)
        let dropdown_stationcode = tableData.map(value => value.stationcode)

        let temp = [... new Set(client)]
        let temp1 = [... new Set(dropdown_stationcode)]

        this.setState({
          ...this.state,
          allData: tableData,
          dropdown_client: temp,
          uniqueModel: dropdown_model,
          uniqueZone: dropdown_zone,
          uniqueStationCode: temp1,
          dropdown_stationcode: temp1,
          uniqueData: tableData,
          fetching: false,
          show_maintable: true
        }, () =>this.handlePagination1())
      } else {
        console.log('No Data')
      }
    })
  }

  // Handles client change and fills model dropdown

  handleClientChange = (event) => {
    const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                client: VALUE
            }
        })

        let arrModel = []

        this.state.allData.forEach((item) => {
            if(item.client === VALUE){
                arrModel.push(item.businessmodel)
                return arrModel
            }
        })

        let arr1 = [...new Set(arrModel)]
        this.setState({
            ...this.state,
            dropdown_model: arr1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formData: {
              ...this.state.formData,
                model: '',
                stationcode: '',
                zone: '',
                status: '',
                client: VALUE
            }
        }, () => this.handleClientFilter())
  }

  // Handles client filter

  handleClientFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.client === this.state.formData.client){
            newArray.push(item)
            // console.log(newArray)
        }
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray.length >= 1 ? newArray : []
    },()=>this.handlePagination2())
}

  // Handles model state and fills the zone dropdown

  handleModelChange = (event) => {
    const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.allData.forEach(each => {
            if(each.client === this.state.formData.client && each.businessmodel === VALUE){
                arrZone.push(each.zone)
                return arrZone
            }
        })
        let arr2 = [...new Set(arrZone)]
            this.setState({
                ...this.state,
                dropdown_zone: arr2,
                dropdown_stationcode: [],
                formData: {
                    ...this.state.formData,
                    stationcode: '',
                    zone: '',
                    status: '',
                    model: VALUE
                    }
                }, () => this.handleModelFilter())
        
  }

  // Handles model filter

  handleModelFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
            newArray.push(item)
            // console.log(newArray)
        }
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray.length >= 1 ? newArray : []
    },()=>this.handlePagination2())
}

// Handles zone change and fills the station code dropdown

  handleZoneChange = (event) => {
    const VALUE = event.target.value;

    let stationcode = []

    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            zone : VALUE
        }
    })

    this.state.allData.forEach(item => {
        if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
            stationcode.push(item.stationcode)
            return stationcode
        }
    })

    let stationcode1 = [... new Set(stationcode)]

    this.setState({
        ...this.state,
        dropdown_stationcode: stationcode1,
        formData: {
            ...this.state.formData,
            stationcode: '',                
            zone: VALUE
        }
    }, () => this.handleZoneFilter())
  }

  // Handle zone filter

  handleZoneFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
            newArray.push(item)
            console.log(newArray)
        }
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray.length >= 1 ? newArray : []
    }, () => this.handlePagination2())
}

// Handles station code state change

  handleStationCodeChange = (event) => {
    this.setState({
      ...this.state,
      formData: {
          ...this.state.formData,
          stationcode: event.target.value
      }
  }, () => this.handleStationFilter())
  }

  // Station filter 

  handleStationFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.stationcode === this.state.formData.stationcode){
            newArray.push(item)
            console.log(newArray)
        }
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray.length >= 1 ? newArray : []
    }, ()=> this.handlePagination2())
  }

  // Fetches details on change of date

  handleDateChange = (event) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        date: event.target.value
      }
    }, () => {
      getRequest(`api/cod/getCODSubmissionStatus?currentdate=${this.state.formData.date}`).then(res => {
        console.log(JSON.parse(res.res.data))
      if(JSON.parse(res.res.data) !== undefined){
        let tableData = JSON.parse(res.res.data)
        let client = []
        // const filterTable = []
        tableData.forEach(item => {
          client.push(item.client)
        })

        const filterTable = tableData.filter(item => item.client === this.state.formData.client)
        console.log(filterTable)

        let dropdown_model = tableData.map(value => value.businessmodel)
        let dropdown_zone = tableData.map(value => value.zone)
        let dropdown_stationcode = tableData.map(value => value.stationcode)

        let temp = [... new Set(client)]
        let temp1 = [... new Set(dropdown_stationcode)]

        this.setState({
          ...this.state,
          allData: tableData,
          filterTable: filterTable.length ? filterTable : [],
          formData: {
            client: this.state.formData.client ? this.state.formData.client : '',
            // client: '',
            model: '',
            zone: '',
            stationcode: ''
          },
          dropdown_client: temp,
          uniqueModel: dropdown_model,
          uniqueZone: dropdown_zone,
          uniqueStationCode: temp1,
          dropdown_stationcode: temp1,
          uniqueData: tableData,
          fetching: false,
          show_maintable: filterTable.length ? false : true
        }, () => {
          if(this.state.show_maintable){
            this.handlePagination1()
          } else {
            this.handlePagination2()
          }
        })
      } else {
        console.log('No Data')
      }
    })
    })
  }

  handlePagination1 = () => {
    let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
    let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
    let currentPosts = this.state.allData.sort((a,b)=>a.client.localeCompare(b.client)).slice(indexofFirstPost, indexOfLastPost)
    this.setState({
      ...this.state,
      tableDisplay: currentPosts
    })
  }

  handlePagination2 = () => {
    console.log(this.state.currentPage2)
    let indexOfLastPost = this.state.currentPage2 * this.state.postsPerPage2
    let indexofFirstPost = indexOfLastPost - this.state.postsPerPage2
    let currentPosts = this.state.filterTable.sort((a,b)=>a.client.localeCompare(b.client)).slice(indexofFirstPost, indexOfLastPost)
    this.setState({
        ...this.state,
        filterDisplay: currentPosts
    })
}

  paginate1 = (pageNumber) => {
    console.log(pageNumber)
    this.setState({
        ...this.state,
        currentPage1: pageNumber
    }, () => this.handlePagination1())
  }

  paginate2 = (pageNumber) => {
    console.log(pageNumber)
    this.setState({
        ...this.state,
        currentPage2: pageNumber
    }, () => this.handlePagination2())
  }

  // Downloads the report as csv

  downloadReport = async () => {
    // if(this.state.filterTable.length){
      this.setState({
        ...this.state
      }, () => {
        this.downloadLink.link.click()
      })
  }

  handleReset = () => {
    this.setState({
      ...this.state,
      dropdown_model: [],
      dropdown_zone: [],
      dropdown_stationcode: this.state.uniqueStationCode,
      show_maintable: true,
      show_filtertable: false,
      filterDisplay: [],
      filterTable: [],
      formData: {
        client: '',
        model: '',
        zone: '',
        stationcode: '',
        date: ''
      }
    })
  }

  render() {
    return (
      <Fragment>
        <div className='header_div'>COD Submission Status</div>

        <br/>

        {!this.state.fetching &&
        <div>
          <div>
            <Button variant='success' style={{marginLeft:10}} className='pull-right' onClick={this.downloadReport}>Download</Button>
            <CSVLink data={this.state.filterTable.length ? this.state.filterTable : this.state.allData} filename='COD_Submission_Status.csv' ref={(r)=>this.downloadLink=r} target='_blank'></CSVLink>
          </div>

          <div>
            <Button className='pull-right' variant='danger' onClick={this.handleReset}>Reset Filter</Button>
          </div>
        </div>}

        <br/>

        {!this.state.fetching &&
        <div className='row'>
          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Client</Form.Label>
                <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                  <option value='' disabled>Select</option>
                  {this.state.dropdown_client.sort().map((item,index)=><option key={index}>{item}</option>)}
                </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Model</Form.Label>
                <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                  <option value='' disabled>Select</option>
                  {this.state.dropdown_model.sort().map((item,index)=><option key={index}>{item}</option>)}
                </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Zone</Form.Label>
                <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                  <option value='' disabled>Select</option>
                  {this.state.dropdown_zone.sort().map((item,index)=><option key={index}>{item}</option>)}
                </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Station Code</Form.Label>
                <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationCodeChange}>
                  <option value='' disabled>Select</option>
                  {this.state.dropdown_stationcode.sort().map((item,index)=><option key={index}>{item}</option>)}
                </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Deposit Date</Form.Label>
                <input className='spacing' type='date' value={this.state.formData.date} name='date' onChange={this.handleDateChange}></input>
            </Form.Group>
          </div>
        </div>}

        <br/>

        {this.state.fetching ? <div className='center'>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>: null}

        {this.state.show_maintable && this.state.allData.length ?
        <div>
          <table border='1' id='table_styling'>
            <thead>
              <tr>
                <th>Client</th>
                <th>Business Model</th>
                <th>Zone</th>
                <th>Station Code</th>
                <th>Deposit Date</th>
                <th>Number Of Deposits</th>
                <th>COD Form Submission Date & Time</th>
                <th>Form Submission Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tableDisplay.map((item,index)=> {
                return(
                  <tr key={index}>
                    <td>{item.client}</td>
                    <td>{item.businessmodel}</td>
                    <td>{item.zone}</td>
                    <td>{item.stationcode}</td>
                    <td>{getHumanReadableDate(item['Deposit Date'])}</td>
                    <td>{item['Num Of Deposits']}</td>
                    <td>{item['COD Form Submission Date']}</td>
                    <td>{item['Form Submission Status']}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div> : null}

        {this.state.show_filtertable && this.state.filterTable.length ?
        <div>
          <table border='1' id='table_styling'>
            <thead>
              <tr>
              <th>Client</th>
                <th>Business Model</th>
                <th>Zone</th>
                <th>Station Code</th>
                <th>Deposit Date</th>
                <th>Number Of Deposits</th>
                <th>COD Form Submission Date & Time</th>
                <th>Form Submission Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filterDisplay.map((item,index)=> {
                return(
                  <tr key={index}>
                    <td>{item.client}</td>
                    <td>{item.businessmodel}</td>
                    <td>{item.zone}</td>
                    <td>{item.stationcode}</td>
                    <td>{getHumanReadableDate(item['Deposit Date'])}</td>
                    <td>{item['Num Of Deposits']}</td>
                    <td>{item['COD Form Submission Date']}</td>
                    <td>{item['Form Submission Status']}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div> : null}

        {this.state.show_maintable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage1} totalPosts={this.state.allData.length} paginate={this.paginate1}></Pagination1>
        </div>       
        : null }

        {this.state.show_filtertable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage2} totalPosts={this.state.filterTable.length} paginate={this.paginate2}></Pagination1>
        </div>       
        : null }

      </Fragment>
    )
  }
}

export default CODSubmissionStatus