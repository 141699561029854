import React, { Component, Fragment } from 'react'
import {Button, Form} from 'react-bootstrap';
import FormBasicDate from '../CI/FormBasicDate';
import '../CI/COD.css';
import { getRequest, convertToINR, getHumanReadableDate } from '../../globalhelper/helper';
import Pagination1 from '../CI/Pagination';
import FormBasicSelect from '../CI/FormBasicSelect';
import { CSVLink } from 'react-csv';

export class ExpenseJournalDump extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        show_table: false,
        tableData: [],
        tableDisplay: [],
        currentPage: 1,
        postsPerPage: 20,
        billNumber: [],
        dropdown_status: ['Active', 'Waiting For Approval'],
         formData: {
            FromDate: '',
            ToDate: '',
            Status: '',
            docid: '',
            company: ''
         },
        companyDetails: [],
        companyName: [],
        error: false,
        errorMessage: "",
        loading: false
      }
    }

    componentDidMount(){

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            this.setState({
                ...this.state,
                companyDetails: responseData,
                companyName: orgname
            })
        })
    }

    handleBillNumber = () => {
        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.company)

        const companyid = id.map(item => item.orgid)

        getRequest(`api/edureka/getDistinctDocumentID?company=${companyid[0]}`).then(response => {
            console.log(response.res.data)

            let values = response.res.data.map(item => item.createdid)

            let id = response.res.data ? values : []

            this.setState({
                ...this.state,
                billNumber: id
            })
        }).catch(err => {
            console.log(err)
        })
    }

    handleOrgChange = (e) => {
        const {name, value} = e.target

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => this.handleBillNumber())
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME] : VALUE
            }
        })
    }

    handleSubmit = () => {
        const {FromDate, ToDate, company, Status, docid} = this.state.formData
        if(FromDate && ToDate && company){
            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.company)

            const companyid = id.map(item => item.orgid)

            this.setState({
                ...this.state,
                loading: true,
                error: false
            })

            getRequest(`api/edureka/ADJournal?FromDate=${FromDate}&ToDate=${ToDate}&Status=${Status}&DocID=${docid}&company=${companyid[0]}`).then(response => {
                const responseData = JSON.parse(response.res.data)

                if(responseData.length){
                    this.setState({
                        ...this.state,
                        loading: false,
                        tableData: responseData
                    }, () => this.handlePagination())
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: true,
                        errorMessage: "No data found, please try some other combination"
                    })
                }               
            }).catch(err => {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: true
                })
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        let currentPosts = this.state.tableData.sort((a,b) => a.CreatedID.localeCompare(b.CreatedID)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    handleDownload = () => {
        this.setState({
            ...this.state
        }, () => {
            this.downloadLink.link.click()
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            tableData: [],
            error: false,
            loading: false,
            formData: {
                FromDate: '',
                ToDate: '',
                Status: '',
                docid: '',
                company: ''
            }
        })
    }

  render() {
    return (
        <Fragment>
            {/* <div style={{alignItems:'left', fontSize:'large', fontWeight:'bolder', fontFamily:'sans-serif'}}>
                Expense Journal Dump
            </div> */}

            {/* <div>
                <Button variant='success' className='pull-right' onClick={this.handleDownload}>Download</Button>
                <CSVLink data={this.state.tableData} filename={`Expense Journal Dump ${this.state.formData.FromDate} to ${this.state.formData.ToDate}.csv`} ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
            </div> */}

            <br/>

            <form>
                <div className='row'>
                    <div className='col-md-3'>
                        <Form.Group>
                            <Form.Label>Company</Form.Label>
                            <select className='spacing' value={this.state.formData.company} name='company' onChange={this.handleOrgChange}>
                                <option value="" disabled>Select</option>
                                {this.state.companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                        </Form.Group>
                    </div>

                    <FormBasicDate label='From Date' value={this.state.formData.FromDate} name='FromDate' onChange={this.handleChange}></FormBasicDate>

                    <FormBasicDate label='To Date' value={this.state.formData.ToDate} name='ToDate' onChange={this.handleChange}></FormBasicDate>

                    <FormBasicSelect label='Status' value={this.state.formData.Status} name='Status' onChange={this.handleChange} options={this.state.dropdown_status}></FormBasicSelect>

                    <FormBasicSelect label='Document ID' value={this.state.formData.docid} name='docid' onChange={this.handleChange} options={this.state.billNumber}></FormBasicSelect>

                </div>

                <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                    <Button size='sm' variant='success' onClick={this.handleSubmit}>Submit</Button>
                    <Button size='sm' variant='danger' onClick={this.handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                    {this.state.tableData.length ?
                    <CSVLink data={this.state.tableData} filename={`Expense Journal Dump ${this.state.formData.FromDate} to ${this.state.formData.ToDate}.csv`} ref={(r)=>this.downloadLink = r} target='_blank' style={{marginLeft:'10px'}}>Download</CSVLink> : null}
                </div>

            </form>


            {/* <div>
            <Button type='primary' onClick={this.handleSubmit}>Submit</Button>
            </div> */}

            <br/>

            {this.state.tableData.length ?
            <div>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th>Transaction Type</th>
                            <th>Document Type</th>
                            <th>Document ID</th>
                            <th>Status</th>
                            <th>Document Reference</th>
                            <th>Accounting Document Number</th>
                            <th>Accounting Date</th>
                            <th>Account Type</th>
                            <th>Account Code</th>
                            <th>Tally Ledger Name</th>
                            <th>Ledger Name</th>
                            <th>SubLedger Name</th>
                            <th>MIS Grouping</th>
                            <th>Amount in Local Currency</th>
                            {/* <th>Local Currency</th> */}
                            <th>Amount in Transaction Currency</th>
                            <th>Transaction Currency</th>
                            <th>For Period From</th>
                            <th>For Period To</th>
                            <th>Link Reference 1</th>
                            <th>Link Reference 2</th>
                            <th>Account Group</th>
                            <th>Account Class</th>
                            <th>Item ID</th>
                            <th>Item Type</th>
                            <th>Sender Account</th>
                            <th>Sender Name</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tableDisplay.map((item,index) => {
                            return(
                                <tr key={index}>
                                    <td>{item.TransactionType}</td>
                                    <td>{item.DocumentType}</td>
                                    <td>{item.CreatedID}</td>
                                    <td>{item.Status}</td>
                                    <td>{item.DocumentRef}</td>
                                    <td>{item.AccountingDocNumber}</td>
                                    <td>{getHumanReadableDate(item.AccountingDate)}</td>
                                    <td>{item.AccountType}</td>
                                    <td>{item.AccountCode}</td>
                                    <td>{item.TallyLedgerName}</td>
                                    <td>{item.LedgerName}</td>
                                    <td>{item.SubLedgerName}</td>
                                    <td>{item.MisGrouping}</td>
                                    <td>{convertToINR(item.AmountinLocalCurrency)}</td>
                                    {/* <td>{item.LocalCurrency}</td> */}
                                    <td>{item.AmountinTransactionCurrency}</td>
                                    <td>{item.TransactionCurrency}</td>
                                    <td>{getHumanReadableDate(item.ForPrdFrom)}</td>
                                    <td>{getHumanReadableDate(item.ForPrdTo)}</td>
                                    <td>{item.LinkRef1}</td>
                                    <td>{item.LinkRef2}</td>
                                    <td>{item.AccountGroup}</td>
                                    <td>{item.AccountClass}</td>
                                    <td>{item.ItemID}</td>
                                    <td>{item.ItemType}</td>
                                    <td>{item.SenderAccount}</td>
                                    <td>{item.SenderName}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : null}

            {this.state.error ?
            <div className='center-screen'>
                {this.state.errorMessage}
            </div> : null}

            {this.state.loading ? 
            <div className='center'>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>    
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
            </div>: null}

            <br/>

            {this.state.tableData ? <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.tableData.length} paginate={this.paginate}></Pagination1> : null}
        </Fragment>
      
    )
  }
}

export default ExpenseJournalDump