
import _ from 'lodash';
import data from '../pages/VR2_Form_Rendering/sample.json';
import treeJson from '../pages/VR2_Form_Rendering/tree2.json';
import store from '../store';


export function changeTreeData(treeData) {   
    console.log(treeData)
    return {
        type: 'changeTreeData',
        treeData: treeData
    }
}

export function dependencyJson(json) {   
    console.log(json)
    return {
        type: 'dependency_json',
        json: json
    }
}

export function dynamicOption_Done(Arr) {   
    console.log(Arr)
    return {
        type: 'dynamicOption_Done',
        Arr: Arr
    }
}
export function ORG_ID_CONST(val) {   
    console.log(val)
    return {
        type: 'ORG_ID_CONST',
        ID: val
    }
}
export function changeLogs(Logs) {   
    console.log(Logs)
    return {
        type: 'changeLogs',
        Logs: Logs
    }
}
export function storeVals(vals) {   
    console.log(vals)
    return {
        type: 'storeVals',
        vals: vals
    }
}
export function athenaStorage(input) {   
    console.log(input)
    return {
        type: 'athenaStorage',
        data: input
    }
}

function requestTreeData() {
    return {
        type: "requestTreeData"
    }
}

export function one_side_PDF_Key(key) {   
    console.log(key)
    return {
        type: 'one_side_PDF_Key',
        PDF_Key: key
    }
}

export function update_one_side_PDF_Key(key) {
    
    return (dispatch) =>{
        dispatch(one_side_PDF_Key(key));
    } 
}

export function update_dependencyJson(json) {
    
    return (dispatch) =>{
        dispatch(dependencyJson(json));
    } 
}
export function fetchTreeData() {
    
    return (dispatch) =>{
        dispatch(requestTreeData());
        dispatch(changeLogs({}));
        dispatch(storeVals({}));
        dispatch(athenaStorage({}));
        dispatch(one_side_PDF_Key(''));
        dispatch(dynamicOption_Done([]))
        dispatch(ORG_ID_CONST(''));
    } 
}
export function updateChangeLogs(Logs){
    
    return (dispatch) =>{
        dispatch(changeLogs(Logs))
    } 
}
export function updateDynamicOption_Done(Arr) {
    
    return (dispatch) => {
        dispatch(dynamicOption_Done(Arr));
    };
}
export function Set_ORG_ID_CONST(val) {

    return (dispatch) => {
        dispatch(ORG_ID_CONST(val));
    };
}
export function updateStoreVals(vals){
    
    return (dispatch) =>{
        dispatch(storeVals(vals))
    } 
}
export function updateAthenaStorage(input){
    
    return (dispatch) =>{
        dispatch(athenaStorage(input))
    } 
}
export function handleTreeChange(treeData){
    
    return (dispatch) =>{
        dispatch(changeTreeData(treeData))
    } 
}


export function isNullOrEmpty (value, operator) {

    if(operator === "EQ") return value === "" || value === undefined || value === null
    if(operator === "NE") return value !== "" && value !== undefined && value !== null
}


export function checkDependency (dependency, formArray_obj) {

    dependency.map((dep, ind) => {
        dep.satisfied = false;

        if(dep.operator === "EQ")
        {
            dep.satisfied = dep.value === "" ?  isNullOrEmpty(formArray_obj[dep.lookFor], dep.operator) : formArray_obj[dep.lookFor] === dep.value
        }
        else if(dep.operator === "NE")
        {
            dep.satisfied = dep.value === "" ? isNullOrEmpty(formArray_obj[dep.lookFor], dep.operator) : formArray_obj[dep.lookFor] !== dep.value
        }
    })
    return dependency.every(item => item.satisfied === true);
}



function if2hashRemoveLast (myString) {

    if (myString.includes("#") && (myString.match(/#/g) || []).length === 2) {    // only containd 2 #

        let str = myString.split("#")

        str.splice(-1)

        return str.join("#")
    }
    else {
        return myString
    }
}


export function getDropdownOptions(parent_id, formArray, formArray_obj, treeData, valEmpty ){

    const state = store.getState();
    const DEP_JSON = state.VR2_Reducer.dependency_json;
    let options = _.filter(DEP_JSON, (item, i) => {
        if(item.type === "dropdown-option" && ( item.parentId === parent_id ) )
        {
            if(item.dependency && item.dependency.length)
            {
                return checkDependency(item.dependency, formArray_obj);
            }
            else{
                return true;
            }
        }
        else{
            return false;
        }
    });

    console.log('Affected ID- ', parent_id, 'Child options- ', options)

    changeValByserviceID( treeData, formArray, parent_id, 'options', options, valEmpty )
}


export function changeDependencyValues(parent_id, formArray, formArray_obj, treeData, valEmpty){
    const state = store.getState();
    const DEP_JSON = state.VR2_Reducer.dependency_json;
    // let children = _.filter(DEP_JSON, item => item.type !== "dropdown-option" && item.type !== "apiCall" && ( item.parentId == parent_id || item.parentId === Number(parent_id) ) );
    let children = _.filter(DEP_JSON, item => item.type === "select" && ( item.parentId === parent_id ) );
    console.log('Change ID-', parent_id, "Dependency-", children)

    children.map((child) => {
        
        console.log('Change ID-', parent_id, "Dependency-", child.id)
        getDropdownOptions(child.id, formArray, formArray_obj, treeData, valEmpty)
    })
}


export function changeValByserviceID (treeData, formArray, serviceID, key, val, serviceKey, valEmpty) {

    console.log('Edit JSON', serviceID, key, val )

    let found = false
    treeData.forEach((each, i) => {

        if (found) return
        if (each.children && each.children.length) {

            each.children.forEach((each2, j) => {

                if (found) return
                if (each2.children && each2.children.length) {

                    each2.children.forEach((each3, k) => {

                        if (found) return
                        if (!each3.children && each3.serviceID === serviceID) {

                            // treeData[i].children[j].children[k][key] = val
                            each3[key] = val
                            if (key === "options" && valEmpty) each3["val"] = ""

                            console.log(each3, 'Updated Obj')

                            found = true
                            return (dispatch) => {
                                dispatch(changeTreeData(treeData))
                            }
                        } else if (each3.children && each3.children.length) {

                            each3.children.forEach((each4, m) => {

                                if (found) return
                                if (each4.serviceID === serviceID) {

                                    each4[key] = val
                                    if (key === "options" && valEmpty) each3["val"] = ""

                                    console.log(each4, 'Updated Obj')

                                    found = true
                                    return (dispatch) => {
                                        dispatch(changeTreeData(treeData))
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }
    })
    
}

