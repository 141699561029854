import React, { Component } from 'react'
import { connect } from 'react-redux';
import { flushCurrentNode, addShalliAddChild, addTempNewChild, copySetOfNode } from '../../actions/tree_Action';
import Multiselect from 'multiselect-react-dropdown';
import { TbCornerDownRight } from 'react-icons/tb';
import { TiArrowBack } from 'react-icons/ti';
import { GrCopy } from 'react-icons/gr';
import { IoIosCopy } from 'react-icons/io';
import { MdOutlineContentPaste } from 'react-icons/md';
import { MdDeleteForever, MdKeyboardArrowRight } from 'react-icons/md';
import { RiDeleteBinFill } from 'react-icons/ri';






class Display extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        // Criteria : ['ModelID','SettlementType','Type', ''],
        MainParents : ['SchemaClass', 'Components', 'Criteria', 'Conditions']

      }
    }

    componentDidMount() {
      console.log('new')
    }

    onNodeNameChange = (e) => {

      let value = e.target.value

      let { node, path } = this.props.data
      node.title = value
      node.technicalName = this.props.themeData && this.props.themeData[1][value] ? this.props.themeData[1][value] : value

      this.props.modifyNodeAtPath({node, path})

      this.props.nodeLocation[this.props.nodeLocation.length - 1 ] = value
    }

    onNodeValChange = (e) => {

      let value = e.target.value
      let { node, path } = this.props.data
      
      if("equality" in node){

        if(node.equality === 'IN' || node.equality === 'NI' ){

          if(value.includes(',')) value = value.split(',')
          
          else value = [value]
        }
      } 

      console.log(value)
      
      node.val = value

      this.props.modifyNodeAtPath({node, path})
    }

    addMultiValue = (VAL) => {

      let { node, path } = this.props.data
      node.val = VAL

      this.props.modifyNodeAtPath({node, path})
    }

    add_Child = () => {
      console.log(this.props.data)

      let NODE = this.props.data.node

      if( !NODE.children || !NODE.children.length ){

        let { node, path } = this.props.data

          if(node.val || node.val === ""){
            delete node.val
            this.props.modifyNodeAtPath({node, path})
          }
            
          this.props.addingNodeUnderParent(this.props.tempNewChild, this.props.data)
          this.props.flushCurrentNode()

      }
      else{

        for(let i=0; i < NODE.children.length; i++){
  
          if( NODE.children[i].technicalName === this.props.tempNewChild.technicalName){
            console.log('same')
            alert(`The same "${this.props.tempNewChild.technicalName}" title cannot be repeated`)
            break;
          }
  
          else if( i === NODE.children.length -1 ){
  
            let { node, path } = this.props.data
  
            if(node.val || node.val === ""){
              delete node.val
              this.props.modifyNodeAtPath({node, path})
            }
              
            this.props.addingNodeUnderParent(this.props.tempNewChild, this.props.data)
            this.props.flushCurrentNode()
            
          }
        }

      }
      
    }
    

    pasteChild = () => {
      let { node, path } = this.props.data

      if(node.val || node.val === ""){
        delete node.val
        this.props.modifyNodeAtPath({node, path})
      }

      this.props.addingNodeUnderParent(this.props.setOfCopiedNode, this.props.data)
        
      this.props.flushCurrentNode()
      
      this.props.copySetOfNode(null)

    }

    childNameonChange = (e) => {

      let value = e.target.value
      this.props.addTempNewChild("title", value)
      this.props.addTempNewChild("technicalName", this.props.themeData && this.props.themeData[1][value] ? this.props.themeData[1][value] : value )
    }

    showChild = () => {
      
      console.log(this.props.data.node.technicalName)
      console.log(this.props.tempNewChild)
      
      if(this.state.MainParents.includes(this.props.data.node.technicalName)){
        let tempNewChild = this.props.tempNewChild

        let name = '#' + this.props.data.node.technicalName + '#' + (this.props.data.node.children && this.props.data.node.children.length ? this.props.data.node.children.length + 1 : 1)

        tempNewChild.title = name
        tempNewChild.technicalName = name


        this.props.addingNodeUnderParent(tempNewChild, this.props.data)
        this.props.flushCurrentNode()
        
      }
      else{
        
        this.props.addShalliAddChild(!this.props.shalliAddChild)
      }
    }

    onChangeEquality = (e) => {

      let { node, path } = this.props.data

      // if(e.target.name === "ALL"){
        
      //   node.val = "*"
      //   node.equality = "EQ"
      //   this.props.modifyNodeAtPath({node, path})
      // }
      // else {

        // node.val = []
        node.equality = e.target.name
        this.props.modifyNodeAtPath({node, path})
      // }
    }


    deleteEquality = (e) => {

      e.preventDefault()

      let { node, path } = this.props.data

      // check if key exists
      "equality" in node && delete node.equality

      this.props.modifyNodeAtPath({node, path})
    }


    copyNode = () => {

      let copy = JSON.parse(JSON.stringify(this.props.data.node))

      this.props.copySetOfNode(copy)

    }

   

  render() {
    const node = this.props.data.node
    const parentNode = this.props.data.parentNode
    const nodeLocation = this.props.nodeLocation
    return (
      <div>
        { this.props.data ?
        <>
        <div className="row">
          <p style={{ overflow: 'visible'}}>
            {/* <label style={{ padding: 0, margin: 0}}>Path :</label> */}
            { nodeLocation && nodeLocation.length ? nodeLocation.join(`  ❯  `) : false}
          </p>
        </div>
        <br />


        <div className="row">
          <div className="col-md-2">
            <label htmlFor="title">Title:</label>            
          </div>
          <div className="col-md-10">
            <input type="text" name='title' value={node.title} onChange={this.onNodeNameChange}/>
            <button onClick={this.copyNode} className='btn btn-secondary ml-2'
            // style={{border: 'none', background: 'none'}}
            ><IoIosCopy /> Copy Node</button>
            <button onClick={() => this.props.deleteCurrentNode(this.props.data)} className='btn btn-danger ml-2'
            // style={{color: 'red', border: 'none', background: 'none', fontSize: '1rem'}} 
            ><MdDeleteForever /> Delete</button>
          </div>
        </div>
        
        <br /> 

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-10">

            <button className='btn btn-primary' onClick={this.showChild}>{ !this.props.shalliAddChild ? <>Add Child <TbCornerDownRight /></>:<><TiArrowBack /> Go Back</>}</button>
            { this.props.setOfCopiedNode ?  <>(OR)
            <button className='btn btn-success' onClick={this.pasteChild}><MdOutlineContentPaste /> Add Copied Child</button></>
            : null}

          </div>
        </div>

        
        <br />
        {/* <br /> */}

          { this.props.shalliAddChild ?

            <>

            <div className="row">
              <div className="col-md-2">
                <label htmlFor="title">Child Title:</label>
              </div>
              <div className="col-md-10">
                <select name="SchemaClass" id="SchemaClass" value={this.props.tempNewChild.title} onChange={this.childNameonChange}>
                  <option value="">choose</option>
                  { this.props.fileData.Header_Array.map( each => 

                  <>
                  { this.props.themeData ? 
                    <>
                    {this.props.themeData[0][each] ? 
                      <option key={each} value={this.props.themeData[0][each]}>{this.props.themeData[0][each]} (OR) {each}</option> 
                      : null
                    }
                    </>
                  : 
                    <option key={each} value={each}>{each}</option>
                  }
                  </>

                  )}
                </select> (OR)
              
                <input className='ml-1' type="text" name='title' value={this.props.tempNewChild.title} onChange={this.childNameonChange}/>
                
                <button className='btn btn-success ml-2' onClick={this.add_Child}>Add</button> 

              </div>
            </div>
            
            
            

            
            </>

          : 

          <>
          { !node.children || !node.children.length ? 
            <>
              {/* { this.props.fileData && this.props.fileData.Header_Array && this.props.fileData.Header_Array.includes(node.technicalName) ? */}
                <>
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="value">Equality:</label>
                  </div>
                  <div className="col-md-10 pt-1">                    
                    <input className='mr-1' type="radio" name="EQ" checked={"equality" in node && node.equality === "EQ" } onChange={this.onChangeEquality}/>
                    <span htmlFor="EQ">EQUAL</span>
                    <input className='ml-4 mr-1' type="radio" name="IN" checked={"equality" in node && node.equality === "IN" } onChange={this.onChangeEquality}/>
                    <span htmlFor="IN">IN</span>
                    <input className='ml-4 mr-1' type="radio" name="NI" checked={"equality" in node && node.equality === "NI" } onChange={this.onChangeEquality}/>
                    <span htmlFor="NI">NOT IN</span>
                    {"equality" in node 
                    && <span onClick={this.deleteEquality} style={{color:'red', marginLeft: '1rem', paddingBottom: '1rem'}}><RiDeleteBinFill/></span>}
                  </div>
                </div>


                {/* { node.equality && node.equality === "EQ" ? 

                  <div className="row">
                    <div className="col-md-2">
                      <label htmlFor="value">Value:</label>
                    </div>
                    <div className="col-md-10">
                      <input type="text" name='value' value={node.val ? node.val : "" } style={{width:'25.5rem'}} disabled onChange={this.onNodeValChange}/>  
                    </div>
                  </div>

                  :  */}
                  
                  <div className="row">
                    <div className="col-md-2">
                      <label htmlFor="value">Value:</label>
                    </div>
                    <div className="col-md-10">
                      <textarea type="text" name='value' onChange={this.onNodeValChange}
                      value={!node.val ? '' : typeof node.val === 'string' ? node.val  : node.val.join(',') } style={{width:'25.5rem'}} 
                      />  
                    </div>
                  </div>
                {/* }  */}
                


                {/* { node.equality && node.equality !== "EQ" ? 

                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-10">
                      <Multiselect
                        options={this.props.fileData[node.technicalName]}
                        isObject={false}
                        selectedValues={node.val ? node.val : [] }
                        onSelect={this.addMultiValue}
                        onRemove={this.addMultiValue}
                      />
                    </div>
                  </div>


                  : null
                }                   */}
                
                </>

              {/* : 

              <>

                { !this.state.MainParents.includes(node.technicalName) ? 
                    <>

                    <div className="row">
                      <div className="col-md-2">
                        <label htmlFor="value">Value:</label>
                      </div>
                      <div className="col-md-10">
                        <input type="text" name='value' value={node.val ? node.val : "" } style={{width:'25.5rem'}} onChange={this.onNodeValChange}/>  
                      </div>
                    </div>
                    
                    </> 

                  : null
                }

              </>
              } */}

            </>
          :
            null
          }


            </> 
          }

        </>
        : null
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        tree_Data: state.tree_Reducer.tree_Data,
        current_NodeData: state.tree_Reducer.current_NodeData,
        shalliAddChild: state.tree_Reducer.shalliAddChild,
        tempNewChild: state.tree_Reducer.tempNewChild,
        setOfCopiedNode: state.tree_Reducer.setOfCopiedNode,
    }
}

export default connect(
    mapStateToProps,
    { flushCurrentNode, addShalliAddChild, addTempNewChild, copySetOfNode }
)(Display);

