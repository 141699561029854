import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper';
import { Form, Button, Modal, Spinner } from "react-bootstrap"
import CancelIcon from '@mui/icons-material/Cancel';
import PaginationEmail from '../Edureka/PaginationEmail'
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import Select from 'react-select'
import { CSVLink } from 'react-csv';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

const Logs = () => {
    const [companyName, setCompanyName] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedItemType, setSelectedItemType] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [selectedAccountGroup, setSelectedAccountGroup] = useState('');
    const [selectedAccountClass, setSelectedAccountClass] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);
    const [accountGroups, setAccountGroups] = useState([]);
    const [accountClasses, setAccountClasses] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');

    // States for row selection and editing
    const [selectedRows, setSelectedRows] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentRowsData, setCurrentRowsData] = useState([]);

    const[tableLedgername,setTableLedgername] = useState("")

      // Pagination state
      const [currentPage, setCurrentPage] = useState(1); // current page
      const [postsPerPage] = useState(10); // posts per page

      const [editMode1, setEditMode1] = useState(true); // Toggle between Edit and Create New modes
      const [mode, setMode] = useState('edit'); // 'edit' or 'create'
      

    const itemType = [
        "BANK", "CC", "OffsetBank", "OffsetCC", "InterCompany", "International", "MSME", "Non-MSME",
        "Delivery Partner", "Rent", "G&A", "Books", "Content", "Freight", "Marketing", "Technology",
        "Financing Cost", "Capital", "CWIP", "Inventory", "Taxable", "RCM", "OffsetPC", "PC", "Instructors",
        "Partner Share / Annual Fees", "Foreign", "Individual", "Other Than Individual"
    ];
    // {companyName.map((item, key) => 
    //     <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
    // )}
    const companyOptions = companyName.map((item) => ({
        value: item.orgid, 
        label: `${item.orgname}(${item.orgid})` 
    }));
    const handleSelectChangeCompany = (selectedOption) => {
        console.log(selectedOption, "selected option");
        setSelectedCompany(selectedOption ? selectedOption.value : ""); 
    };

    const itemTypeOptions = itemType.map(item => ({
        value: item, // value of the option
        label: item  // label shown in the select box
    }));
    const handleSelectChange = (selectedOption) => {
        console.log(selectedOption,"selected option")
        setSelectedItemType(selectedOption);
    };
    const handleSelectChangeAT = (selectedOption) => {
        console.log(selectedOption,"selected option")
        setSelectedAccountType(selectedOption);
    };
    const accountTypeOPtions =  accountTypes.map(item=>({
        value: item, // value of the option
        label: item  // label shown in the select box
    }))

    const handleSelectChangeAC = (selectedOption) => {
        console.log(selectedOption,"selected option")
        setSelectedAccountClass(selectedOption);
    };
    const accountClassOPtions =  accountClasses.map(item=>({
        value: item, // value of the option
        label: item  // label shown in the select box
    }))

    const handleSelectChangeAG = (selectedOption) => {
        console.log(selectedOption,"selected option")
        setSelectedAccountGroup(selectedOption);
    };
    const accountGroupOPtions =  accountGroups.map(item=>({
        value: item, // value of the option
        label: item  // label shown in the select box
    }))
    useEffect(() => {
        // Fetch company names
        getRequest('api/edureka/getCompanyAssignedToUser')
            .then(response => {
                const responseData = response.res.data;
                setCompanyName(responseData);
            })
            .catch(err => console.log(err));
    }, []);

    const getMasterAccountDetermination = async () => {
        const requestBody = {
            company: selectedCompany,
        };
        console.log(requestBody.company,"company id")

        postRequest('api/edureka/getMasterAccountDetermination', requestBody).then(response => {
            const responseData = response.res.data.data;
            console.log(responseData);
            if (responseData.length < 1) {
                setResponseMessage("No data found");
            } else {
                setResponseData(responseData);
                setFilteredData(responseData);

                // Extract unique values for AccountType, AccountGroup, and AccountClass
                const uniqueAccountTypes = [...new Set(responseData.map(item => item.AccountType))];
                const uniqueAccountGroups = [...new Set(responseData.map(item => item.AccountGroup))];
                const uniqueAccountClasses = [...new Set(responseData.map(item => item.AccountClass))];

                const ledgername = responseData.map((item)=>item.LedgerName)

                // Set the unique values into state
                setAccountTypes(uniqueAccountTypes);
                setAccountGroups(uniqueAccountGroups);
                setAccountClasses(uniqueAccountClasses);
                
            }
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        if (selectedCompany) {
            getMasterAccountDetermination();
        }
    }, [selectedCompany]);

    // Filter the data based on the selected dropdown values
    useEffect(() => {
        let filtered = responseData;

        if (selectedItemType) {
            filtered = filtered.filter(item => item.ItemType === selectedItemType.value);
        }

        if (selectedAccountType) {
            filtered = filtered.filter(item => item.AccountType === selectedAccountType.value);
        }

        if (selectedAccountGroup) {
            filtered = filtered.filter(item => item.AccountGroup === selectedAccountGroup.value);
        }

        if (selectedAccountClass) {
            filtered = filtered.filter(item => item.AccountClass === selectedAccountClass.value);
        }

        setFilteredData(filtered);
    }, [selectedItemType, selectedAccountType, selectedAccountGroup, selectedAccountClass, responseData]);

    // Function to handle checkbox selection
    const handleRowSelection = (index) => {
        console.log(index)
        const isSelected = selectedRows.includes(index);
        const newSelection = isSelected
            ? selectedRows.filter(item => item !== index)
            : [...selectedRows, index];

        setSelectedRows(newSelection);

        if (newSelection.length > 0) {
            // Update current rows data
            const newCurrentRowsData = newSelection.map(selectedIndex => filteredData[selectedIndex]);
            setCurrentRowsData(newCurrentRowsData);
            setEditMode(true);
        } else {
            setCurrentRowsData([]);
            setEditMode(false);
        }
    };

    // Function to handle input changes for multiple rows
    const handleInputChange = (index, field, value) => {
        const updatedRowsData = [...currentRowsData];
        updatedRowsData[index] = {
            ...updatedRowsData[index],
            [field]: value
        };
        setCurrentRowsData(updatedRowsData);
    
        // If the data is modified, automatically uncheck the row
        if (selectedRows.includes(index)) {
            const updatedSelectedRows = selectedRows.filter(rowIndex => rowIndex !== index);
            setSelectedRows(updatedSelectedRows);
        }
    };

   const handleRemoveRow = (index) => {
    const updatedSelection = selectedRows.filter((_, i) => i !== index); // Remove selected row
    const updatedRowsData = currentRowsData.filter((_, i) => i !== index); // Remove corresponding row data

    setSelectedRows(updatedSelection); // Update the main table selection
    setCurrentRowsData(updatedRowsData); // Update edit section data

    if (updatedRowsData.length === 0) {
        setEditMode(false); // Turn off edit mode if no rows left to edit
    }
};
    

    // Submit function to send updated data to the API
    const handleSubmit = () => {
        // Extract the key combination for each row
        const duplicateRows = [];
        const uniqueCombinations = new Set();
    
        currentRowsData.forEach((data, index) => {
            const combination = `${data.AccountType}-${data.ItemType}-${data.AccountGroup}-${data.AccountClass}`;
    
            // If the combination is already found, store the row index
            if (uniqueCombinations.has(combination)) {
                duplicateRows.push({ rowIndex: index + 1, rowData: data });
            } else {
                uniqueCombinations.add(combination);
            }
        });
    
        // If duplicates are found, alert the user and stop the submission
        if (duplicateRows.length > 0) {
            // const duplicateMessage = duplicateRows
                // .map(row => Row ${row.rowIndex}: ${JSON.stringify(row.rowData)})
                // .join('\n');
            // alert(Duplicate combinations found:\n${duplicateRows});
            const duplicateMessage = duplicateRows
                .map(row => `Row ${row.rowIndex}`)
                .join(', '); // Join the row numbers into a comma-separated string
            alert(`Duplicate combinations found in rows: ${duplicateMessage}`);
            return; // Stop execution if duplicates are found
        }
    
        // Proceed with submitting the data if no duplicates are found
        const updatedData = currentRowsData.map(data => ({
            ...data,
        }));
    
        const requestBody = {
            data: updatedData,
            company: selectedCompany,
            mode: mode
        };
    
        console.log(updatedData, "updated data");
    
        postRequest('api/edureka/saveMasterAccountDetermination', requestBody)
            .then(response => {
                const responseMessage = response.res.data.message;
    
                if (response.res.data.status === "success") {
                    alert(responseMessage);
    
                    // Reset selected rows and current rows on success
                    setSelectedRows([]);
                    setCurrentRowsData([]);
    
                    // Turn off edit mode
                    setEditMode(false);
    
                    // Fetch the updated data
                    getMasterAccountDetermination();
    
                } else if (response.res.data.status === "fail") {
                    alert(responseMessage);
                    setEditMode(true); // Keep the edit mode on failure
                }
            })
            .catch(err => console.log(err));
    };
    
    

    const handlefilterdataChange = (index, value, item) => {
        // Create a new object with the updated LedgerName
        const updatedItem = { ...item, LedgerName: value };
        
        // Update the filteredData array with the new object
        const updatedData = [...filteredData];
        updatedData[index] = updatedItem;
    
        // Set the updated data in the state
        setFilteredData(updatedData);
    };

    const handleAddRow = () => {
        const emptyRow = {};
        columnOrder.forEach(key => {
            emptyRow[key] = ''; // Add empty values for each key
        });
        
        setCurrentRowsData([...currentRowsData, emptyRow]); // Add new empty row
    };
    
    
     // Pagination logic
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentFilteredData = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const columnOrder = ['ForPrdFrom','ForPrdTo','AccountType', 'ItemType', 'LedgerName', 'AccountClass', 'AccountGroup','MSGrouping','COA', 'AccountCode', 'Parameter1', 'SingleTresholdLimit', 'CummThresholdLimit','TransactionStatus','SplH','Priority'];
   
const handleModeToggle = () => {
    setMode((prevMode) => (prevMode === 'edit' ? 'create' : 'edit'));
};
    return (
        <>
            <div className="row mt-2">
                {/* Existing dropdowns for filtering */}
                {/* <div className='col-md-2'>
                    <label htmlFor="Company" style={{ fontSize: '1rem' }}> Company </label>
                    <select 
                        className="browser-default custom-select" 
                        id="Company" 
                        name="Company" 
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                    >
                        <option value=""> Select </option>
                        {companyName.map((item, key) => 
                            <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
                        )}
                    </select>
                </div> */}
                <div className='col-md-2'>
                        <label htmlFor="Company" style={{ fontSize: '1rem' }}> Company </label>
                        <Select
                            id="Company"
                            name="Company"
                            value={selectedCompany ? companyOptions.find(option => option.value === selectedCompany) : null}
                            onChange={handleSelectChangeCompany}
                            options={companyOptions}
                                    isClearable // Allows clearing the selected option
                                    placeholder="Select"
                                    styles={{
                                        container: (base) => ({ ...base, padding: 5 }),
                                        control: (base) => ({ 
                                            ...base, 
                                            borderRadius: 5, 
                                            border: '1px solid #ccc', 
                                            minHeight: '38px',  
                                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                                        }),
                                        valueContainer: (base) => ({
                                            ...base,
                                            padding: '0 15px', // Add horizontal padding here instead
                                        }),
                                        input: (base) => ({
                                            ...base,
                                            margin: 0,
                                            padding: 0, 
                                        }),
                                        singleValue: (base) => ({
                                            ...base,
                                            margin: 0,
                                            padding: 0,
                                        }),
                                        // Add z-index to the dropdown menu
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 9999, // Ensure the menu is on top
                                            width: '300px', // Set the desired width for the dropdown menu here
                                        }),
                                        // Optionally, apply z-index to the menu portal if you're using a portal
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999, // Adjust this if you need to position the menu in a portal
                                        })
                                    }}
                        menuPortalTarget={document.body} // Optional: if using menuPortal
                                />
                            </div>


                {/* <div className='col-md-2'>
            <label htmlFor="item-type" style={{ fontSize: '1rem' }}>Item Type</label>
            <Select
                id="item-type"
                name="item-type"
                value={selectedItemType}
                onChange={handleSelectChange}
                options={itemTypeOptions}
                isClearable // Allows clearing the selected option
                placeholder="Select"
                styles={{
                    
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    }),
                // Add z-index to the dropdown menu
        menu: (base) => ({
            ...base,
            zIndex: 9999, // Adjust this value to ensure the menu is on top
        }),
        // Optionally, apply z-index to the menu portal if you're using a portal
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Adjust this if you need to position the menu in a portal
        })
    }}
    menuPortalTarget={document.body} // Optional: if using menuPortal
            />
        </div> */}
        
        <div className='col-md-2'>
            <label htmlFor="AccountType" style={{ fontSize: '1rem' }}> Account Type </label>
            <Select
                id="account-type"
                name="account-type"
                value={selectedAccountType}
                onChange={handleSelectChangeAT}
                options={accountTypeOPtions}
                isClearable // Allows clearing the selected option
                placeholder="Select"
                styles={{
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 10000,  // Ensure the dropdown has a higher z-index
                        width: '250px', // Increase width of the options dropdown
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 10000, // Ensure the menu portal has a high z-index
                    })
                }}
                menuPortalTarget={document.body} // Optional: if using menuPortal
            />
        </div>


        {/* <div className='col-md-2'>
            <label htmlFor="AccountGroup" style={{ fontSize: '1rem' }}>  Account Group </label>
            <Select
                id="account-group"
                name="account-group"
                value={selectedAccountGroup}
                onChange={handleSelectChangeAG}
                options={accountGroupOPtions}
                isClearable // Allows clearing the selected option
                placeholder="Select"
                styles={{
                    
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    }),
                // Add z-index to the dropdown menu
        menu: (base) => ({
            ...base,
            zIndex: 9999, // Adjust this value to ensure the menu is on top
        }),
        // Optionally, apply z-index to the menu portal if you're using a portal
        menuPortal: (base) => ({
            ...base,
            
            zIndex: 9999, // Adjust this if you need to position the menu in a portal
        })
    }}
    menuPortalTarget={document.body} // Optional: if using menuPortal
            />
        </div> */}





       


        <div >
            <label htmlFor="AccountClass" style={{ fontSize: '1rem' }}> Account Class </label>
            <Select
                id="AccountClass"
                name="AccountClass"
                value={selectedAccountClass}
                onChange={handleSelectChangeAC}
                options={accountClassOPtions}
                isClearable // Allows clearing the selected option
                placeholder="Select"
                styles={{
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 10000,  // Ensure the dropdown has a higher z-index
                        width: '250px', // Increase width of the options dropdown
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 10000, // Ensure the menu portal has a high z-index
                    })
                }}
    menuPortalTarget={document.body} // Optional: if using menuPortal
            />
        </div>
       
        <div className="d-flex  ">
    {editMode && currentRowsData.length > 0 ? (
        <div className="col-md-2 ">
            <button 
                onClick={handleModeToggle} 
                className="btn btn-secondary p-4"
                style={{ marginTop: "37px", width: "150px"  }}
            >
                {mode === 'edit' ? 'Edit' : 'Copy As New'}
            </button>
        </div>
    ) : null}

    {responseData.length > 0 && (
        <div className="col-md-2" style={{ marginLeft: "100px", marginTop: "37px" }}>
            <CSVLink  
                size="sm"
                className="btn btn-success btn-sm" 
                data={responseData}       // The data to be downloaded
                filename={"Master_Account_Determination.csv"}  // The name of the CSV file
                target="_blank"
            >
                <FileDownloadRoundedIcon color="success" />
            </CSVLink>
        </div>
    )}
</div>



       

                {/* <div className='col-md-2'>
                    <label htmlFor="item-type" style={{ fontSize: '1rem' }}> Item Type </label>
                    <select 
                        className="browser-default custom-select" 
                        id="item-type" 
                        name="item-type"
                        value={selectedItemType}
                        onChange={(e) => setSelectedItemType(e.target.value)}
                    >
                        <option value=""> Select </option>
                        {itemType.map((item, key) => 
                            <option key={key} value={item}>{item}</option>
                        )}
                    </select>
                </div> */}
                
                {/* Account Type, Group, and Class Dropdowns */}
                {/* <div className="col-md-2">
                    <label htmlFor="AccountType" style={{ fontSize: '1rem' }}> Account Type </label>
                    <select 
                        className="browser-default custom-select" 
                        id="AccountType" 
                        name="AccountType"
                        value={selectedAccountType}
                        onChange={(e) => setSelectedAccountType(e.target.value)}
                    >
                        <option value=""> Select </option>
                        {accountTypes.map((type, key) => (
                            <option key={key} value={type}>{type}</option>
                        ))}
                    </select>
                </div> */}

                {/* <div className="col-md-2">
                    <label htmlFor="AccountGroup" style={{ fontSize: '1rem' }}> Account Group </label>
                    <select 
                        className="browser-default custom-select" 
                        id="AccountGroup" 
                        name="AccountGroup"
                        value={selectedAccountGroup}
                        onChange={(e) => setSelectedAccountGroup(e.target.value)}
                    >
                        <option value=""> Select </option>
                        {accountGroups.map((group, key) => (
                            <option key={key} value={group}>{group}</option>
                        ))}
                    </select>
                </div> */}

                {/* <div className="col-md-2">
                    <label htmlFor="AccountClass" style={{ fontSize: '1rem' }}> Account Class </label>
                    <select 
                        className="browser-default custom-select" 
                        id="AccountClass" 
                        name="AccountClass"
                        value={selectedAccountClass}
                        onChange={(e) => setSelectedAccountClass(e.target.value)}
                    >
                        <option value=""> Select </option>
                        {accountClasses.map((accountClass, key) => (
                            <option key={key} value={accountClass}>{accountClass}</option>
                        ))}
                    </select>
                </div> */}
            </div>
           
   {/* Edit Section */}
   {editMode && currentRowsData.length > 0 && (
        <div className="mt-3">
            {/* Toggle Button */}
          

            <h5>{mode === 'edit' ? 'Edit' : 'Copy As New'}</h5>
            
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Remove</th>
                        {columnOrder.map((key, index) => (
                            <th key={index} className='auto'>{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRowsData.map((rowData, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <Button variant='link' size='sm' onClick={() => handleRemoveRow(rowIndex)}>
                                    <CancelIcon color='error' />
                                </Button>
                            </td>
                            {columnOrder.map((key, index) => (
                                <td key={index}>
                                    {/* Conditionally render inputs based on mode */}
                                    {mode === 'edit' ? (
                                        // In "Edit" mode, only `ledgerName` is editable
                                        key === 'LedgerName' ? (
                                            <input
                                                type="text"
                                                value={rowData[key]}
                                                onChange={(e) => handleInputChange(rowIndex, key, e.target.value)}
                                                className='text-center p-1'
                                                style={{ width: '150px', minWidth: '150px' }}
                                            />
                                        ) : (
                                            <span>{rowData[key]}</span> // Non-editable columns
                                        )
                                    ) : (
                                        // In "Create New" mode, all columns are editable
                                        <input
                                            type="text"
                                            value={rowData[key]}
                                            onChange={(e) => handleInputChange(rowIndex, key, e.target.value)}
                                            className='text-center p-1'
                                            style={{ width: '150px', minWidth: '150px' }}
                                        />
                                    )}
                                </td>
                            ))}
                        </tr>                                
                    ))}

                    {/* Add New Row Button */}
                    {mode === 'create' && (
                        <tr>
                            <td>
                                <button 
                                    onClick={handleAddRow} 
                                    className='btn text-center' 
                                    style={{color: 'green', padding: '0', border:"none",outline:"none"}}
                                >
                                    <IoMdAddCircle/>
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Submit Button */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <button onClick={handleSubmit} className="btn btn-primary mt-1 " style={{margin:"auto"}}>
                Submit
            </button></div>
           
        </div>
    )}

            {/* Display filtered data in table */}
            
            {filteredData.length > 0 ? (
                <div className="mt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                            <th>Copy</th>
                    {columnOrder.map((key, index) => (
                        <th key={index}>{key}</th>
                    ))}
                </tr>
            </thead>
                        <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            checked={selectedRows.includes(index)}
                                            onChange={() => handleRowSelection(index)}
                                        />
                                    </td>
                                    {columnOrder.map((key, idx) => (
                                        <td key={idx}>{item[key]}</td>
                                    ))}


{/* {Object.keys(item).map((key, index) => (
    <td key={index}>
        {key !== 'LedgerName' ? (
            item[key] // Display as plain text for non-LedgerName fields
        ) : (
            <input
                type="text"
                value={item[key]}
                onChange={(e) => handlefilterdataChange(index, e.target.value, item)} // Call the handler for LedgerName updates
                className='text-center p-1'
            />
        )}
    </td>
))} */}

                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Add Pagination */}
                    {/* <PaginationEmail 
                        postsPerPage={postsPerPage} 
                        totalPosts={filteredData.length} 
                        currentPage={currentPage} 
                        paginate={paginate} 
                    /> */}
                </div>
            ) : (
                <div className="mt-3">{responseMessage}</div>
            )}



        </>
    );
};

export default Logs;
