import React, { Component, Fragment } from 'react';
import './COD.css';
import CIPopup from './CIPopup';
import {Form, Button} from 'react-bootstrap';
import { getRequest, convertToINR, getHumanReadableDate, postRequest } from '../../globalhelper/helper';
import {CSVLink} from 'react-csv';
import Pagination1 from './Pagination';
import RemittancePopup from './RemittancePopup';

export class RemittanceHistory extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            startdate: '',
            enddate: ''
         },
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         allData: [],
         tableData: [],
         filterTable: [],
         selectedRows: [],
         fetching: false,
         show_maintable: false,
         show_filtertable: false,
         currentPage1: 1,
         currentPage2: 1,
         postsPerPage1: 20,
         postsPerPage2: 20,
         tableDisplay: [],
         filterDisplay: [],
         uniqueModel: [],
         uniqueZone: [],
         uniqueData: [],
         uniqueStationCode: [],
         showpopup: false,
         deleteitem: []
      }
    }

    componentDidMount(){
      this.setState({
        ...this.state,
        fetching: true
      })
        getRequest(`api/cod/getRemittanceHistory`).then(response => {
          if(response.res.status === "success" && response.res.data && response.res.data.length){
            let allData = response.res.data

            let dropdown_client = allData.map(item => item.client)
            let dropdown_model = allData.map(item => item.businessmodel)
            let dropdown_zone = allData.map(item => item.zone)
            let dropdown_stationcode = allData.map(item => item.stationcode)

            let temp1 = [... new Set(dropdown_client)]
            let temp4 = [... new Set(dropdown_stationcode)]

            this.setState({
              ...this.state,
              fetching: false,
              allData: allData,
              dropdown_client: temp1,
              uniqueModel: dropdown_model,
              uniqueZone: dropdown_zone,
              uniqueStationCode: temp4,
              uniqueData: allData,
              dropdown_stationcode: temp4,
              show_maintable: true
            }, () => this.handlePagination1())
          } else {
            this.setState({
              ...this.state,
              fetching: false
            })
          }
        })
    }

    handleRow = (event, id) => {
      if(event.target.checked){
        this.setState(prevState => ({
          selectedRows: [...prevState.selectedRows, id]
        }))
      } else {
        this.setState(prevState => ({
          selectedRows: prevState.selectedRows.filter(rowID=> rowID !== id)
        }))
      }
    }

    handleDeleteRows = () => {
      this.state.allData.onDelete(this.state.selectedRows)
      this.setState({
        ...this.state,
        selectedRows: []
      })
    }

    downloadRemittanceReport = async () => {
      if(this.state.filterTable.length <=0){
        await getRequest(`api/cod/getRemittanceHistory`).then(response => {
          let tableData = response.res.data

          const tableDatafiltered = tableData.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
          })

          this.setState({
            ...this.state,
            tableData: tableDatafiltered
          }, () => {
            this.downloadLink.link.click()
          })
        })
      } else {
          this.setState({
            ...this.state,
            filterTable: this.state.filterTable
          }, () => {
            this.downloadLink.link.click()
        })
      }
    }

  handleReset = () => {
    this.setState({
      ...this.state,
      show_filtertable: false,
      show_maintable: true,
      filterDisplay: [],
      dropdown_model: [],
      dropdown_zone: [],
      dropdown_stationcode: this.state.uniqueStationCode,
      formData: {
        client: '',
        model: '',
        zone: '',
        stationcode: ''
      }
    })
  }

  handlePagination1 = () => {
      let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
      let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
      // console.log(this.state.allData)
      let currentPosts = this.state.allData.sort((a,b)=>b.createddate.localeCompare(a.createddate)).slice(indexofFirstPost, indexOfLastPost)
      this.setState({
          ...this.state,
          tableDisplay: currentPosts
      })
  }

  handlePagination2 = () => {
      console.log(this.state.currentPage2)
      let indexOfLastPost = this.state.currentPage2 * this.state.postsPerPage2
      let indexofFirstPost = indexOfLastPost - this.state.postsPerPage2
      // console.log(this.state.allData)
      let currentPosts = this.state.filterTable.sort((a,b)=>b.createddate.localeCompare(a.createddate)).slice(indexofFirstPost, indexOfLastPost)
      this.setState({
          ...this.state,
          filterDisplay: currentPosts
      })
  }

  paginate1 = (pageNumber) => {
      console.log(pageNumber)
      this.setState({
          ...this.state,
          currentPage1: pageNumber
      }, () => this.handlePagination1())
  }

  paginate2 = (pageNumber) => {
      console.log(pageNumber)
      this.setState({
          ...this.state,
          currentPage2: pageNumber
      }, () => this.handlePagination2())
  }

  handleClientChange = (event) => {
    const VALUE = event.target.value;

    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            client: VALUE
        }
    })

    let arrModel = []

    this.state.allData.forEach((item) => {
        if(item.client === VALUE){
            arrModel.push(item.businessmodel)
            console.log(arrModel)
            return arrModel
        }
    })

        let arr1 = [...new Set(arrModel)]
        this.setState({
            ...this.state,
            dropdown_model: arr1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formData: {
                ...this.state.formData,
                model: '',
                stationcode: '',
                zone: '',
                status: '',
                client: VALUE
            }
        }, () => this.handleClientFilter()) 
  }

  handleClientFilter = () => {
    let newArray = []

    if(this.state.filterTable.length > 0){
        this.state.filterTable.forEach(item => {
            if(item.client === this.state.formData.client){
                newArray.push(item)
            }
        })
    } else {
        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client){
                newArray.push(item)
                console.log(newArray)
            }
        })
    }

    const newArray1 = newArray.map(obj => {
        const {inprdfrom, inprdto, checksum, transactionid, input, output,  ...rest } = obj
        return rest
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray1.length >= 1 ? newArray1 : []
    },()=>this.handlePagination2())
  }

  handleModelChange = (event) => {
    const VALUE = event.target.value;

    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            model: VALUE
        }
    })

    let arrZone = []

    this.state.allData.forEach(each => {
        if(each.client === this.state.formData.client && each.businessmodel === VALUE){
            arrZone.push(each.zone)
            return arrZone
        }
    })
    let arr2 = [...new Set(arrZone)]
        this.setState({
            ...this.state,
            dropdown_zone: arr2,
            dropdown_stationcode: [],
            formData: {
                ...this.state.formData,
                stationcode: '',
                zone: '',
                status: '',
                model: VALUE
                }
            }, () => this.handleModelFilter())
  }

  handleModelFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
            newArray.push(item)
            console.log(newArray)
        }
    })

    const newArray1 = newArray.map(obj => {
        const {inprdfrom, inprdto, checksum, transactionid, input, output,  ...rest } = obj
        return rest
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray1.length >= 1 ? newArray1 : []
    },()=>this.handlePagination2())
  }

  handleZoneChange = (event) => {
    const VALUE = event.target.value;

    let stationcode = []

    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            zone : VALUE
        }
    })

    this.state.allData.forEach(item => {
        if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
            stationcode.push(item.stationcode)
            return stationcode
        }
    })

    let stationcode1 = [... new Set(stationcode)]

    this.setState({
        ...this.state,
        dropdown_stationcode: stationcode1,
        formData: {
            ...this.state.formData,
            stationcode: '',                
            zone: VALUE
        }
    }, () => this.handleZoneFilter())
  }

  handleZoneFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
        if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
            newArray.push(item)
            console.log(newArray)
        }
    })

    const newArray1 = newArray.map(obj => {
        const {inprdfrom, inprdto, checksum, transactionid, input, output,  ...rest } = obj
        return rest
    })

    this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray1.length >= 1 ? newArray1 : []
    }, () => this.handlePagination2())
  }

  handleStationChange = (event) => {
    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            stationcode: event.target.value
        }
    }, () => this.handleStationFilter())
  }

  handleStationFilter = () => {
    let newArray = []

    this.state.allData.forEach(item => {
      if(item.stationcode === this.state.formData.stationcode){
          newArray.push(item)
      }
  })

    const newArray1 = newArray.map(obj => {
        const {inprdfrom, inprdto, checksum, transactionid, input, output,  ...rest } = obj
        return rest
    })

      this.setState({
        ...this.state,
        show_filtertable: true,
        show_maintable: false,
        currentPage2: 1,
        filterTable: newArray1.length >= 1 ? newArray1 : []
    }, ()=> this.handlePagination2()) 
  }

  handleDelete = (item) => {

    this.setState({
      ...this.state,
      showpopup: true,
      deleteitem: item
    })

    // postRequest(`api/cod/deleteRemittanceHistoryRecords`, {item: item}).then(res => {
    //   console.log(res.res.data)

    //   setTimeout(()=> {
    //     window.location.reload()
    //   }, 1000)
    // })
  }

  handleEdit = (item) => {
    console.log(item)
    this.setState({
      ...this.state,
      redirectScreen: true,
      valueJSON: item
    })
  }

  closePopup = (data) => {
    console.log("Received")
    console.log(data)

    const initialValue = data.item
    const dataFromProps = data.data

    const finalValue = {
      amount: Number(dataFromProps.Amount),
      businessmodel: initialValue.businessmodel,
      client: initialValue.client,
      createddate: initialValue.createddate,
      pk: initialValue.pk,
      sk: initialValue.sk,
      stationcode: initialValue.stationcode,
      zone: initialValue.zone,
      remittanceref: dataFromProps.RemittanceRef
    }

    const array1 = [...this.state.allData]
    const array2 = [...this.state.tableDisplay]
    const array3 = [...this.state.filterTable]
    const array4 = [finalValue]
    console.log(array4)

    const toBeCheckedArray = finalValue

    console.log(toBeCheckedArray)

    const objIndex1 = array1.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)
    const objIndex2 = array2.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)
    const objIndex3 = array3.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)

    console.log(objIndex1)
    console.log(objIndex2)
    console.log(objIndex3)

    if(objIndex1 !== -1){

      console.log("Entered 1st block")

      array1.splice(objIndex1, 1)

      console.log(array1)

      array1.splice(objIndex1, 0, toBeCheckedArray)

      console.log(array1)

      this.setState({
        ...this.state,
        allData: array1,
        redirectScreen: false,
        valueJSON: []
      }, () => this.handlePagination1())
    }

    if(objIndex2 !== -1){

      console.log("Entered 2nd block")

      array2.splice(objIndex2, 1)

      console.log(array2)

      array2.splice(objIndex2, 0, toBeCheckedArray)

      console.log(array2)

      this.setState({
        ...this.state,
        tableDisplay: array2,
        redirectScreen: false,
        valueJSON: []
      }, () => this.handlePagination1())
    } 

    if(objIndex3 !== -1){

      console.log("Entered 3rd block")

      array3.splice(objIndex3, 1)

      array3.splice(objIndex3, 0, toBeCheckedArray)

      this.setState({
        ...this.state,
        filterTable: array3,
        redirectScreen: false,
        valueJSON: []
      }, () => this.handlePagination2())
    } 


    // else {
    //   this.setState({
    //     ...this.state,
    //     redirectScreen: false,
    //     valueJSON: []
    //   })
    //   console.log("Entered Else Block")
    // }
  }

  handleBack = () => {
    this.setState({
      ...this.state,
      redirectScreen: false,
      valueJSON: []
    })
  }

  render() {
    if(this.state.redirectScreen){
      return (
        <RemittancePopup item={this.state.valueJSON} closePopup={this.closePopup} handleBack={this.handleBack}/>
      )
    }
    return (
      <Fragment>
        <div className='header_div'>
            Remittance History
        </div>

        {!this.state.fetching && this.state.allData.length > 0 ?
        <div>
          <div>
            {/* <Button variant='success' style={{marginLeft: 10}} className='pull-right' onClick={this.downloadRemittanceReport}>Download</Button> */}
            <button className='btn green_button pull-right' style={{marginLeft: 10}} onClick={this.downloadRemittanceReport}>Download</button>
            <CSVLink data={this.state.filterTable.length<=0 ? this.state.tableData : this.state.filterTable} filename='Remittance_History.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
          </div>

          <div>
          {/* <Button variant='danger' className='pull-right' onClick={this.handleReset}>Reset Filter</Button> */}
          <button className='btn red_button pull-right' onClick={this.handleReset}>Reset</button>
          </div>
        </div> : null}

        <br/>
        <br/>

        {!this.state.fetching && this.state.allData.length > 0 ? 
        <div className='row'>
          <div className='col-md-3'>
              <Form.Group>
                  <Form.Label>Client</Form.Label>
                  <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                      <option value='' disabled>Select</option>
                      {this.state.dropdown_client.sort().map((item, index) => <option className='option_styling' key={index} value={item}>{item}</option>)}
                  </select>
              </Form.Group>
          </div>

          <div className='col-md-3'>
              <Form.Group>
                  <Form.Label>Model</Form.Label>
                  <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                      <option value='' disabled>Select</option>
                      {this.state.dropdown_model.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                  </select>
              </Form.Group>
          </div>

          <div className='col-md-3'>
              <Form.Group>
                  <Form.Label>Zone</Form.Label>
                  <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                      <option value='' disabled>Select</option>
                      {this.state.dropdown_zone.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                  </select>
              </Form.Group>
          </div>

          <div className='col-md-3'>
              <Form.Group>
                  <Form.Label>Station Code</Form.Label>
                  <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                      <option value='' disabled>Select</option>
                      {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                  </select>
              </Form.Group>
          </div>
        </div>: null}

        {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}

          {this.state.showpopup?
            <CIPopup handleClose={() => this.setState({...this.state, deleteitem: [], showpopup: false})} handleDeleteAfterConfirmation={() => {

              this.setState({
                  ...this.state,
                  showpopup: false
              })

              postRequest(`api/cod/deleteRemittanceHistoryRecords`, {item: this.state.deleteitem}).then(res => {
                const array1 = [...this.state.allData]
                const array2 = [...this.state.tableDisplay]
                const array3 = [...this.state.filterTable]
                const array4 = [this.state.deleteitem]

                const objIndex1 = array1.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)
                const objIndex2 = array2.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)
                const objIndex3 = array3.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.createddate === array4[0].createddate)

                if(objIndex1 !== -1){
                  array1.splice(objIndex1, 1)

                  this.setState({
                    ...this.state,
                    allData: array1
                  }, () => this.handlePagination1())
                }

                if(objIndex2 !== -1){
                  array2.splice(objIndex2, 1)

                  this.setState({
                    ...this.state,
                    tableDisplay: array2
                  }, () => this.handlePagination1())
                }

                if(objIndex3 !== -1){
                  array3.splice(objIndex3, 1)

                  this.setState({
                    ...this.state,
                    filterTable: array3
                  }, () => this.handlePagination2())
                }
              })
            }}/> : null}

        {this.state.show_maintable && this.state.allData.length > 0 ?
          <div className='offset-1'>
            {/* <button disabled={this.state.selectedRows.length === 0} onClick={this.handleDeleteRows}>Delete Rows</button> */}
            <table border='1' id='table_styling'>
              <thead>
                <tr>
                  {/* <th>Select</th> */}
                  <th>Client</th>
                  <th>Business Model</th>
                  <th>Zone</th>
                  <th>Station Code</th>
                  <th>Remittance Date</th>
                  <th>Remittance Amount</th>
                  <th>Remittance Reference</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tableDisplay.map((item, index) => {
                  return(
                    <tr key={index}>
                      {/* <td>
                        <input 
                          type='checkbox'
                          checked={this.state.selectedRows.includes(index)}
                          onChange={(event) => this.handleRow(event, index)}></input>
                      </td> */}
                      <td>{item.client}</td>
                      <td>{item.businessmodel}</td>
                      <td>{item.zone}</td>
                      <td>{item.stationcode}</td>
                      <td>{getHumanReadableDate(item.createddate)}</td>
                      <td>{convertToINR(item.amount)}</td>
                      <td>{item.remittanceref}</td>
                      {/* <td><Button variant='danger' onClick={() => this.handleDelete(item)}>Delete</Button></td> */}
                      <td><button className='btn red_button' onClick={() => this.handleEdit(item)}>Edit</button></td>
                      <td><button className='btn green_button' onClick={() => this.handleDelete(item)}>Delete</button></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          : null}

        {this.state.show_filtertable && this.state.filterTable.length > 0 ?
          <div className='offset-1'> 
            {/* <button disabled={this.state.selectedRows.length === 0} onClick={this.handleDeleteRows}>Delete Rows</button> */}
            <table border='1' id='table_styling'>
              <thead>
                <tr>
                  {/* <th>Select</th> */}
                  <th>Client</th>
                  <th>Business Model</th>
                  <th>Zone</th>
                  <th>Station Code</th>
                  <th>Remittance Date</th>
                  <th>Remittance Amount</th>
                  <th>Remittance Reference</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filterDisplay.map((item, index) => {
                  return(
                    <tr key={index}>
                      {/* <td>
                        <input 
                          type='checkbox'
                          checked={this.state.selectedRows.includes(index)}
                          onChange={(event) => this.handleRow(event, index)}></input>
                      </td> */}
                      <td>{item.client}</td>
                      <td>{item.businessmodel}</td>
                      <td>{item.zone}</td>
                      <td>{item.stationcode}</td>
                      <td>{getHumanReadableDate(item.createddate)}</td>
                      <td>{convertToINR(item.amount)}</td>
                      <td>{item.remittanceref}</td>
                      <td><button className='btn red_button' onClick={() => this.handleEdit(item)}>Edit</button></td>
                      <td><button className='btn green_button' onClick={() => this.handleDelete(item)}>Delete</button></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          : null}

      {this.state.show_maintable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage1} totalPosts={this.state.allData.length} paginate={this.paginate1}></Pagination1>
        </div>       
        : null }

        {this.state.show_filtertable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage2} totalPosts={this.state.filterTable.length} paginate={this.paginate2}></Pagination1>
        </div>       
        : null }

      {this.state.allData.length <= 0 && !this.state.fetching? <div className='center-screen'>
            <h5>No Data Found</h5>
        </div>: null}
      </Fragment>
    )
  }
}

export default RemittanceHistory