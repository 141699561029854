import React, {useEffect, useState} from 'react';
import '../CI/COD.css';
import { getRequest, getHumanReadableDate, postRequest } from "../../globalhelper/helper";
import {Button, Form} from 'react-bootstrap'
import {CSVLink} from 'react-csv'

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const BillAnalysisAndStatusReport = () => {

    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        company: ''
    })

    const [companyDetails, setCompanyDetails] = useState([])

    const [companyName, setCompanyName] = useState([])

    const [queryResult, setQueryResult] = useState([])

    const [loading, setLoading] = useState(false)

    const [showError, setShowError] = useState(false)

    const [warningMessage, setWarningMessage] = useState('')

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    const handleChange = (e) => {
        console.log(e.target.value)
        const {name, value} = e.target
        console.log(name, value)
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            type: '',
            company: ''
        })
        setQueryResult([])
        setLoading(false)
        setShowError(false)
    }

    const handleSubmit = () => {
        const {fromDate, toDate, company} = formData

        if(fromDate && toDate && company){
            setLoading(true)
            setShowError(false)

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            getRequest(`api/edureka/billAnalysisAndStatusReport?startDate=${fromDate}&endDate=${toDate}&company=${companyid[0]}`).then(response => {
                console.log(response.res.data)
                const responseData = JSON.parse(response.res.data)
                if(responseData.length){
                    setQueryResult(responseData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setShowError(true)
                    setWarningMessage("No data found, please try some other combination")
                }
            }).catch(err => {
                setLoading(false)
            })
        } else {
            setShowError(true)
            setWarningMessage("Please select all the required fields")
        }
    }

  return (
    <>
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' name='company' value={formData.company} onChange={handleChange}>
                        <option value='' disabled>Select</option>
                        {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                        <Input type="date" label="From Date" name="fromDate" value={formData.fromDate}  onChange={handleChange}/>
                    </div>

                <div className='col-md-3'>
                    <Input type="date" label="To Date" name="toDate" value={formData.toDate}  onChange={handleChange}/>
                </div>
            </div>


            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size="sm" onClick={handleSubmit} variant="success">Submit</Button>
                <Button size="sm" onClick={handleReset} style={{marginLeft:'10px'}} variant="danger">Reset</Button>
                {queryResult.length ?
                <CSVLink data={queryResult} filename='Bill Analysis and Status Report.csv' style={{marginLeft:'10px'}} >Download</CSVLink> :  null}
            </div>
        </form>

        {!loading && queryResult.length ?
        <div>
            <table border='1' id='table-css'>
                <thead>
                    <tr>
                        <th>Transaction Status</th>
                        <th>Transaction Type</th>
                        <th>Category</th>
                        <th>Accounting Date</th>
                        <th>Bill Number</th>
                        <th>VendorName As Per Master</th>
                        <th>Party LedgerName As Per Tally</th>
                        <th>PAN</th>
                        <th>Vendor GST No</th>
                        <th>WH Tax Classification</th>
                        <th>For Period From</th>
                        <th>For Period To</th>
                        <th>Account Code</th>
                        <th>Expense Ledgers</th>
                        <th>Prd Provision</th>
                        <th>Prd Expense Booked</th>
                        <th>Prd PrePaid Expense Booked</th>
                        <th>Prd WH Tax Amount</th>
                        <th>Input Tax Classification</th>
                        <th>IGST Input</th>
                        <th>CGST Input</th>
                        <th>SGST Input</th>
                        <th>RCM Output Tax Classification</th>
                        <th>IGST Output</th>
                        <th>CGST Output</th>
                        <th>SGST Output</th>
                        <th>Prd Total Input Tax</th>
                        <th>Prd Total Output Tax</th>
                        <th>Prd Payable Amount</th>
                        <th>Item Type</th>
                        <th>Account Groups</th>
                        <th>MIS Group Names</th>
                        <th>Vendor ID</th>
                        {/* <th>Vendor Name</th> */}
                        <th>Vendor Invoice List</th>
                        {/* <th>Journals</th> */}
                    </tr>
                </thead>
                <tbody>
                    {queryResult.map((item, index) => {
                        return (

                        <tr key={index}>
                            <td>{item["ActivityStatus"]}</td>
                            <td>{item.transactiontype}</td>
                            <td>{item.category}</td>
                            <td>{item.accountingdate}</td>
                            <td>{item["createdid"]}</td>
                            <td>{item.VendorNameAsPerMaster}</td>
                            <td>{item.PartyLedgerNameAsPerTally}</td>
                            <td>{item.PAN}</td>
                            <td>{item.VendorGSTNo}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
                            {/* <td>{item["category"]}</td> */}
                            <td>{item.ReportingPrdFrom}</td>
                            <td>{item.ReportingPrdTo}</td>
                            <td>{item.AccountCode}</td>
                            <td>{item.PrdProvision}</td>
                            <td>{item.ExpenseLedgers}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
                            <td>{item.WHTaxClassification}</td>
                            <td>{item.InputTaxClassification}</td>
                            <td style={{textAlign:'right'}}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
                            <td>{item.RCMOutputTaxClassification}</td>
                            <td style={{textAlign:'right'}}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
                            <td>{item.ItemType}</td>
                            <td>{item.AccountGroups}</td>
                            <td>{item.MISGroupNames}</td>
                            <td>{item.VendorID}</td>
                            {/* <td>{item.VendorNames}</td> */}
                            <td>{item.VendorInvoiceList}</td>
                            {/* <td>{item.Journals}</td> */}
                        </tr>
                        )
                    })}
                </tbody>
            </table>

        </div> : null}

        {showError &&
         <div className="center-screen">
            {warningMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
            </div>: null}
    </>
  )
}

export default BillAnalysisAndStatusReport