import React, { PureComponent } from 'react';
import { Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getRequest } from '../../globalhelper/helper';
import DisplaySection from './display_section';

const WIDTH_PX_CONVERSION = 22.2;
const HEIGHT_PX_CONVERSION = 19.74;


class GenerateSection extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      showModal : false,
      elements : [],
      section : [],
      showEditing : false,
      editingComponent : {}
    }
  }

  componentDidMount = () => {
    getRequest('api/visual/getAllSavedElements').then(res => {
      if(res.type === "success")
      {
        this.setState({
          elements : res.res.data
        })
      }
    })
  }

  addNewRow = () => {

  }
  
useElement = (component) => {
  this.setState(prevState => {
    // var sections = prevState.section;
    prevState.section.push(component);

    return prevState
  }, () => {
    this.closeComponentModal()
  })
}

  openComponentModal = () => {
    this.setState({
      ...this.state,
      showModal : true
    })
  }

  closeComponentModal = () => {
    this.setState({
      ...this.state,
      showModal : false
    })
  }

  onElementClick = (component, index) => {
    this.setState({
      ...this.state,
      editingIndex : index,
      editingComponent : component.json,
      showEditing : true
    })
  }

onStyleChange = (ev, index, type=null) => {
  var name = ev.target.name;
  var value = name.includes("height") || name.includes("Height") ? Number(ev.target.value) * HEIGHT_PX_CONVERSION + "px" : name.includes("width") || name.includes("Width") || name.includes("margin") || name.includes("padding")  ? Number(ev.target.value) * WIDTH_PX_CONVERSION + "px" : ev.target.value;
  console.log( name , " : ", value)
  this.setState(prevState => {
    var sections = JSON.parse(JSON.stringify(prevState.section));
    var currentItem = sections[index];
    if(type !== null)
    {
      currentItem.json[type + "_style"][name] = value;
    }
    else{
      currentItem.json.style[name] = value;
    }
    return {
      ...this.state,
      section: sections
    }
  })

}

deleteElement = () => {
  this.setState(prevState => {
    var sections = JSON.parse(JSON.stringify(prevState.section));
    sections.splice(this.state.editingIndex, 1);
    return {
      ...this.state,
      section: sections,
      editingComponent : {},
      editingIndex : 0,
      showEditing : false
    }
  })
}

onPropertyChange = (ev, index) => {
  var name = ev.target.name;
  var value = ev.target.value;
  this.setState(prevState => {
    var sections = JSON.parse(JSON.stringify(prevState.section));
    var currentItem = sections[index];
    currentItem.json[name] = value;
    return {
      ...this.state,
      section: sections
    }
  })

}

    render () {
      const { editingComponent, editingIndex } = this.state;
        return(
          <Fragment>
            <p> Generate Sections </p>
            <div className="row">
              <div className="col-md-3" style={{ height : '75vh', overflow : 'auto' }}>
                Components <br/>
                <Button onClick={this.openComponentModal} disabled={ this.state.showEditing } >+ Add Component</Button> 
                <Button onClick={ this.addNewRow } className="btn btn-success" disabled={ this.state.showEditing } >+ Add Row</Button> <br/>
                <br/>
                { this.state.section.map((item, index) => 
                    item.json.type === "image" ?
                    <Fragment>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="domain">Domain : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="domain" id="domain" value={item.domain} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>
                      </div>
                    </Fragment>:
                    item.json.type === "label" ?
                    <Fragment>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="value">{item.value_key} : </label>
                        </div>
                        <div className="col-md-9">
                          <input name={ item.value_key } id={ item.value_key } value={item.value} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="domain">Domain : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="domain" id="domain" value={item.value} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>
                      </div>
                    </Fragment>:
                    item.json.type === "input" ?
                    <Fragment>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="displayName"> Label : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="displayName" id="displayName" value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>

                        </div>
                      </div>
                    </Fragment>:
                    item.json.type === "dropdown" ?
                    <Fragment>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="displayName"> Label : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="displayName" id="displayName" value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>

                        </div>
                      </div>
                    </Fragment>:
                    item.json.type === "key-value" ?
                    <Fragment>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor={ item.value_key }> Label : </label>
                        </div>
                        <div className="col-md-9">
                          <input name={ item.value_key } id={ item.value_key } value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="domain"> Domain : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="domain" id="domain" value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>
                      </div>
                    </Fragment>:
                    item.json.type === "button" ?
                    <Fragment>
                      <div className="row">
                        {/* <div className="col-md-3">
                          <label htmlFor={ item.value_key }> Label : </label>
                        </div>
                        <div className="col-md-9">
                          <input name={ item.value_key } id={ item.value_key } value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div> */}

                        <div className="col-md-3">
                          <label htmlFor="domain"> Domain : </label>
                        </div>
                        <div className="col-md-9">
                          <input name="domain" id="domain" value={item.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                          <br/>
                        </div>
                      </div>
                    </Fragment>:
                    
                    null
                  ) }
                  <br/>
                  <br/>
                { this.state.showEditing &&
                 <Fragment >
                   { this.state.editingComponent.type === "key-value" || this.state.editingComponent.type === "input" || this.state.editingComponent.type === "dropdown" ?
                    <Fragment>
                        <b>Key Style : </b> <br/>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="width">Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="width" id="width" value={editingComponent.key_style.width} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="minWidth">Minimum Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="minWidth" id="minWidth" value={editingComponent.key_style.minWidth} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="maxWidth">Maximum Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="maxWidth" id="maxWidth" value={editingComponent.key_style.maxWidth} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="height">Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="height" id="height" value={editingComponent.key_style.height} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="minHeight">Minimum Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="minHeight" id="minHeight" value={editingComponent.key_style.minHeight} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="maxHeight">Maximum Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="maxHeight" id="maxHeight" value={editingComponent.key_style.maxHeight} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingTop">Padding Top : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingTop" id="paddingTop" value={editingComponent.key_style.paddingTop}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingBottom">Padding Bottom : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingBottom" id="paddingBottom" value={editingComponent.key_style.paddingBottom}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingLeft">Padding Left : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingLeft" id="paddingLeft" value={editingComponent.key_style.paddingLeft}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingRight">Padding Right : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingRight" id="paddingRight" value={editingComponent.key_style.paddingRight}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginTop">Margin Top : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginTop" id="marginTop" value={editingComponent.key_style.marginTop} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginBottom">Margin Bottom : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginBottom" id="marginBottom" value={editingComponent.key_style.marginBottom}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginLeft">Margin Left : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginLeft" id="marginLeft" value={editingComponent.key_style.marginLeft}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginRight">Margin Right : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginRight" id="marginRight" value={editingComponent.key_style.marginRight}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "key")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="float">Float : </label>
                          </div>
                          <div className="col-md-9">
                            <input name="float" id="float" value={editingComponent.key_style.float} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "key")} />
                            <br />
                          </div>
                        </div>
                        <br/>
                        <br/>

                        <b>Value Style : </b> <br/>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="width">Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="width" id="width" value={editingComponent.value_style.width} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="minWidth">Minimum Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="minWidth" id="minWidth" value={editingComponent.value_style.minWidth} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="maxWidth">Maximum Width : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="maxWidth" id="maxWidth" value={editingComponent.value_style.maxWidth} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="height">Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="height" id="height" value={editingComponent.value_style.height} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="minHeight">Minimum Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="minHeight" id="minHeight" value={editingComponent.value_style.minHeight} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="maxHeight">Maximum Height : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="maxHeight" id="maxHeight" value={editingComponent.value_style.maxHeight} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingTop">Padding Top : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingTop" id="paddingTop" value={editingComponent.value_style.paddingTop}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingBottom">Padding Bottom : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingBottom" id="paddingBottom" value={editingComponent.value_style.paddingBottom}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingLeft">Padding Left : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingLeft" id="paddingLeft" value={editingComponent.value_style.paddingLeft}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="paddingRight">Padding Right : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="paddingRight" id="paddingRight" value={editingComponent.value_style.paddingRight}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginTop">Margin Top : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginTop" id="marginTop" value={editingComponent.value_style.marginTop} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginBottom">Margin Bottom : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginBottom" id="marginBottom" value={editingComponent.value_style.marginBottom}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginLeft">Margin Left : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginLeft" id="marginLeft" value={editingComponent.value_style.marginLeft}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="marginRight">Margin Right : </label>
                          </div>
                          <div className="col-md-9">
                            <input type="number" name="marginRight" id="marginRight" value={editingComponent.value_style.marginRight}
                              onChange={(ev)=> this.onStyleChange(ev, editingIndex, "value")} />
                            <br />

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="float">Float : </label>
                          </div>
                          <div className="col-md-9">
                            <input name="float" id="float" value={editingComponent.value_style.float} onChange={(ev)=>
                            this.onStyleChange(ev, editingIndex, "value")} />
                            <br />
                          </div>
                        </div>
                        <br/>
                    </Fragment>
                   :
                   <Fragment>
                     {console.log(editingComponent)}
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="width">Width : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="width" id="width" value={editingComponent.style.width} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="minWidth">Minimum Width : </label>
                      </div>
                      <div className="col-md-9">
                        {/* <input type="number" name="minWidth" id="minWidth" value={editingComponent.style.minWidth} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} /> */}
                        <input type="number" name="minWidth" id="minWidth" value={editingComponent.style.minWidth} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="maxWidth">Maximum Width : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="maxWidth" id="maxWidth" value={editingComponent.style.maxWidth} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="height">Height : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="height" id="height" value={editingComponent.style.height} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="minHeight">Minimum Height : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="minHeight" id="minHeight" value={editingComponent.style.minHeight} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="maxHeight">Maximum Height : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="maxHeight" id="maxHeight" value={editingComponent.style.maxHeight} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="paddingTop">Padding Top : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="paddingTop" id="paddingTop" value={editingComponent.style.paddingTop}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="paddingBottom">Padding Bottom : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="paddingBottom" id="paddingBottom" value={editingComponent.style.paddingBottom}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="paddingLeft">Padding Left : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="paddingLeft" id="paddingLeft" value={editingComponent.style.paddingLeft}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="paddingRight">Padding Right : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="paddingRight" id="paddingRight" value={editingComponent.style.paddingRight}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="marginTop">Margin Top : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="marginTop" id="marginTop" value={editingComponent.style.marginTop} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="marginBottom">Margin Bottom : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="marginBottom" id="marginBottom" value={editingComponent.style.marginBottom}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="marginLeft">Margin Left : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="marginLeft" id="marginLeft" value={editingComponent.style.marginLeft}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="marginRight">Margin Right : </label>
                      </div>
                      <div className="col-md-9">
                        <input type="number" name="marginRight" id="marginRight" value={editingComponent.style.marginRight}
                          onChange={(ev)=> this.onStyleChange(ev, editingIndex)} />
                        <br />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="float">Float : </label>
                      </div>
                      <div className="col-md-9">
                        <input name="float" id="float" value={editingComponent.style.float} onChange={(ev)=>
                        this.onStyleChange(ev, editingIndex)} />
                        <br />
                      </div>
                    </div>
                    <br/>
                    </Fragment>
    }

                  <div className="row">
                    <div className="col-md-6">
                      <Button onClick={() => {
                        this.setState({
                          ...this.state,
                          showEditing : false,
                          editingComponent : {},
                          editingIndex : 0
                        })
                      }}>Done</Button>
                    </div>
                    <div className="col-md-6">
                      <Button onClick={this.deleteElement} className="btn btn-danger" >Delete</Button>
                    </div>
                  </div>

                  </Fragment>
                }

              </div>
              <div className="col-md-9">
                  <DisplaySection elements={this.state.section} key={this.state.section} onElementClick={this.onElementClick} ></DisplaySection>
              </div>
            </div>

            <Modal
                show={this.state.showModal}
                onHide={this.closeComponentModal}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-custom-modal-styling-title"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Pick Element
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.elements.map((element, key) => 
                      element.component  &&
                      <Button key={key} style={{ margin : 10 }} onClick={() => this.useElement(element)} className="btn btn-info">{ element.component }</Button>
                      ) }
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button className="btn btn-teal" disabled={ this.state.element_name === "" } onClick={this.saveElement}>Save</Button>
                </Modal.Footer> */}
              </Modal>

          </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
      state
    }
  };

export default connect( mapStateToProps)(GenerateSection);