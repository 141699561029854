import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper';
import DatePicker from "react-datepicker";
import DataTable from '../../components/DataTable2';



class CiaCodeAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            Date : new Date(),
            maxDate : new Date().setDate(new Date().getDate()),
            input_month : '',
            showTable : false,
            fetching : false,
            queryData : [],
            columns: [{
                name: 'Client',
                selector: row => row['Client'],
                sortable: true,
            },
            {
                name: 'Client_Model',
                selector: row => row['Client_Model'],
                sortable: true,
            },
            {
                name: 'Month',
                selector: row => row['Month'],
                sortable: true,
            },
            {
                name: 'ClientCode',
                selector: row => row['ClientCode'],
                sortable: true,
            },
            {
                name: 'StationCode',
                selector: row => row['StationCode'],
                sortable: true,
            },
            {
                name: 'Atten',
                selector: row => row['Atten'],
                sortable: true,
            },
            {
                name: 'Shipment',
                selector: row => row['Shipment'],
                sortable: true,
            },
            {
                name: 'CommissionCode',
                selector: row => row['CommissionCode'],
                sortable: true,
            },
            {
                name: 'CIATrack',
                selector: row => row['CIATrack'],
                sortable: true,
            },
            {
                name: 'RateCard',
                selector: row => row['RateCard'],
                sortable: true,
            },
            {
                name: 'CostRep',
                selector: row => row['CostRep'],
                sortable: true,
            },
            {
                name: 'ErrCode',
                selector: row => row['ErrCode'],
                sortable: true,
            }]
         }
    }

   
    handleChange = (ev) => {

        console.log(ev)       

        this.setState({
            Date: ev
        })

    }

    onGetCiaCodeAnalysis = () => {
        this.setState({
            ...this.state,
            fetching : true,
            showTable : false
        }, () => {
            const DATE =  this.state.Date

            postRequest('api/transactions/getCiaCodeAnalysis', { date : DATE })
            .then(res => {
                if(res.type === 'success') {
                    this.setState({
                        queryData : JSON.parse(res.res.data),
                        fetching : false,
                        showTable : true
                    });
                }
            });
        })
    }

    // setDate = (date,field) =>{

    //     this.setState({
    //     ...this.state,
    //     [field] : date
    //     })
    // }


    render() { 
        return (
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="errcode_obj">Select Month</label>

                            <DatePicker selected={this.state.Date} showPopperArrow={false} id="Date" name="Date" maxDate={ this.state.maxDate } className="col-md-12" dateFormat="yyyy/MM" onChange={ date => this.handleChange(date) } />

                        </div>
                        <div className="col-md-2">
                            <button type='button'
                             disabled={ this.state.Date === "" || this.state.fetching } 
                             className='btn btn-info' style={{ marginTop : 33, width: '250px', height: '4rem' }} onClick={this.onGetCiaCodeAnalysis} >Get CIA Code Analysis</button>
                        </div>
                    </div>

                    <br /><br />


                    
                    <Fragment>
                        <DataTable title="CIA Code Analysis" loading={this.state.fetching} columns={this.state.columns} key={this.state.queryData} data={this.state.queryData} />
                    </Fragment> 
                

                </>
                
        );
    }
}
 
export default CiaCodeAnalysis;