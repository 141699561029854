import { SET_MODULES } from "../constants/action-types";

export default function interaction (state = [], action){
    switch(action.type)
    {
        case SET_MODULES : {
            return {
                ...state,
                modules : action.payload
            }
        }
        default : {
            return state;
        }
    }
}