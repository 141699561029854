import React, { useEffect, Fragment } from 'react';
import {  useDispatch } from 'react-redux';
import { changeDependencyValues, getDropdownOptions } from '../../actions/dynamic_forms_action';
import _ from 'lodash';
import data from './sample2.json';


export default function SelectBox(props){
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDropdownOptions(props.id))
        if(props.value)
        {
            let ev = {
                target : {
                    name : props.serviceKey,
                    value : props.value
                }
            }
            props.onChange(ev, props.id, props.value)
        }
    }, []);

    const onDropDownValueChange = (ev) => {
        props.onChange(ev, props.id, props.serviceKey)

        changeDependencyValues(props.id);

        // dispatch(changeDependencyValues(props.id))

        // let parent_id = props.id
        // // let children = _.filter(data, item => item.type !== "dropdown-option" && item.type !== "Screen_Found" && item.parentId === parent_id);
        // let children = _.filter(data, item => item.type === "select" && item.parentId === parent_id);
        // children.map((child) => {
        //     dispatch(getDropdownOptions(child.id))
        // })
    }


    return(
        <Fragment>
            <label htmlFor={props.value} > {props.label} </label> <br/>
            <select name={props.serviceKey} style={props.inputOptions.css} id={props.serviceKey}  onChange={(ev) => onDropDownValueChange(ev)} value={props.value}>
                <option></option>
                { props.options !== undefined && props.options.map((opt, optKey) => 
                    <option key={optKey} style={opt.inputOptions.css} value={opt.value} >{opt.label}</option>
                ) }
            </select><br/>
        </Fragment>
    )
}