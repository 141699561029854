import React, { Component, Fragment } from 'react'
import {Button, Form} from 'react-bootstrap';
import { getRequest, getStandardDate, postRequest } from '../../globalhelper/helper';
import DataTable from '../../components/DataTable2';
import GetAppIcon from '@material-ui/icons/GetApp';
import {CSVLink} from 'react-csv';
import TableWithDownload from '../../components/TableWithDownload'
import Popup from '../../components/SimplePOPUP';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { HiOutlineRefresh } from "react-icons/hi";
import { Animated } from "react-animated-css";




export class FunctionalJournal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {

        showActivate: true,
        Companies : [],
        Company : "",
        EditTable: {
            data: null,
            columns: [
                {
                    name: 'BaseValue',
                    selector:'BaseValue',
                },
                {
                    name: 'DocumentNumber',
                    selector:'documentnumber',
                },
                {
                    name: 'TotalBaseValue',
                    selector:'TotalBaseValue',
                },
                {
                    name: 'AmortizedValue',
                    selector:'AmortizedValue',
                },
                {
                    name: 'BalanceToBeAmortized',
                    selector:'amountinlocalcurrency',
                },
                {
                    name: 'SenderName',
                    selector:'sendername',
                },
                {
                    name: 'ItemName',
                    selector:'itemname',
                },
                {
                    name: 'DocumentRef',
                    selector:'documentref',
                },
                {
                    name: 'DocumentDate',
                    selector:'documentdate',
                },
            ]
        },
        _Table_1 : {
            data: null,
            FULL_data: null,
            selected_ID: [],
            columns : [
                {
                    name: 'Document Number',
                    selector:'documentnumber',
                },
                {
                    name: 'DocumentType',
                    selector:'documenttype',
                },
                {
                    name: 'Vendor Name',
                    selector:'sendername',
                },
                {
                    name: 'Invoice Number',
                    selector:'documentref',
                },
                {
                    name: 'Current BaseValue',
                    selector:'basevalueforthismonth',
                },
                {
                    name: 'AJ Posted',
                    selector:'processedajforthismonth',
                },
                {
                    name: 'AJ ToPost',
                    selector:'topostaj',
                },   
                {
                    name: 'Total BaseValue of the Bill',
                    selector:'basevalueforthisbill',
                },
                {
                    name: 'Total Amortised AJ for the Bill',
                    selector:'processedajforthisbill',
                },
                {
                    name: 'Balance AJ to be Amortised',
                    selector:'balanceaj',
                },
                {
                    name: 'SumOf PostJournal',
                    selector:'sumofpostjournal',
                },
                {
                    name: 'SumOf AllocationValue',
                    selector:'sumofallocationvalue',
                },
                {
                    name: 'Month',
                    selector:'month',
                },
                {
                    name: 'ForPrdFrom',
                    selector:'forprdfrom',
                },
                {
                    name: 'ForPrdTo',
                    selector:'forprdto',
                },
                {
                    name: 'CA Posted',
                    selector:'processedcaforthismonth',
                },
                {
                    name: 'CA ToPost',
                    selector:'topostca',
                },
                {
                    name: 'Total Amortised CA for the Bill',
                    selector:'processedcaforthisbill',
                },
                {
                    name: 'Balance CA to be Amortised',
                    selector:'balanceca',
                }
            ]
        },
        _Table_2 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'BillNumber',
                    selector: "documentnumber",
                    style: { minWidth: '120px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'AccountDate',
                    selector: "accountingdate",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'AccountClass',
                    selector: "accountclass",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'MISGrouping',
                    selector: "misgrouping",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountType',
                    selector: "accounttype",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'LedgerName',
                    selector: "ledgername",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountGroup',
                    selector: "accountgroup",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountCode',
                    selector: "accountcode",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'ItemType',
                    selector: "itemtype",
                    style: { minWidth: '70px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdfrom",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'InvoiceRef',
                    selector: "documentref",
                    style: { minWidth: '110px'}
                },
                {
                    name: 'AmtInLclCurr',
                    selector: "amountinlocalcurrency",
                    style: { minWidth: '70px'}
                },
                {
                    name: 'AmtInTxnCurr',
                    selector: "amountintransactioncurrency",
                    style: { minWidth: '70px'}
                }
            ]
        },
        _Table_3 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'BillNumber',
                    selector: "documentnumber",
                    style: { minWidth: '120px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'CostAllocationMethod',
                    selector: "costallocationmethod",
                    style: { minWidth: '190px'}
                },
                {
                    name: 'LOB',
                    selector: "lob",
                    style: { minWidth: '60px'}
                },
                {
                    name: 'CostAllocationParameterValue',
                    selector: "costallocationparametervalue",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'CostAllocated Lcl Curr',
                    selector: "amountinlocalcurrency",
                    style: { minWidth: '100px'}
                }
            ]
        },
        Table1 : {
            data: null,
            FULL_data: null,
            selected_ID: [],
            columns : [
                {
                    name: 'Document Number',
                    selector:'documentnumber',
                },
                {
                    name: 'DocumentType',
                    selector:'documenttype',
                },
                {
                    name: 'Vendor Name',
                    selector:'sendername',
                },
                {
                    name: 'Invoice Number',
                    selector:'documentref',
                },
                {
                    name: 'This Month BaseValue',
                    selector:'basevalueforthismonth',
                },
                {
                    name: 'This Month AJ Posted',
                    selector:'processedajforthismonth',
                },
                {
                    name: 'This Month AJ ToPost',
                    selector:'topostaj',
                },   
                {
                    name: 'This Month CA Posted',
                    selector:'processedcaforthismonth',
                },
                {
                    name: 'This Month CA ToPost',
                    selector:'topostca',
                },
                {
                    name: 'Total BaseValue of the Bill',
                    selector:'basevalueforthisbill',
                },
                {
                    name: 'Total Amortised AJ for the Bill',
                    selector:'processedajforthisbill',
                },
                {
                    name: 'Balance AJ to be Amortised',
                    selector:'balanceaj',
                },
                {
                    name: 'Total Amortised CA for the Bill',
                    selector:'processedcaforthisbill',
                },
                {
                    name: 'Balance CA to be Amortised',
                    selector:'balanceca',
                },
                {
                    name: 'SumOf PostJournal',
                    selector:'sumofpostjournal',
                },
                {
                    name: 'SumOf AllocationValue',
                    selector:'sumofallocationvalue',
                },
                {
                    name: 'Month',
                    selector:'month',
                },
                {
                    name: 'ForPrdFrom',
                    selector:'forprdfrom',
                },
                {
                    name: 'ForPrdTo',
                    selector:'forprdto',
                }
            ]
        },
        Table2 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'BillNumber',
                    selector: "documentnumber",
                    style: { minWidth: '120px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'AccountDate',
                    selector: "accountingdate",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'AccountClass',
                    selector: "accountclass",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'MISGrouping',
                    selector: "misgrouping",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountType',
                    selector: "accounttype",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'LedgerName',
                    selector: "ledgername",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountGroup',
                    selector: "accountgroup",
                    style: { minWidth: '170px'}
                },
                {
                    name: 'AccountCode',
                    selector: "accountcode",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'ItemType',
                    selector: "itemtype",
                    style: { minWidth: '70px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdfrom",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'InvoiceRef',
                    selector: "documentref",
                    style: { minWidth: '110px'}
                },
                {
                    name: 'AmtInLclCurr',
                    selector: "amountinlocalcurrency",
                    style: { minWidth: '70px'}
                },
                {
                    name: 'AmtInTxnCurr',
                    selector: "amountintransactioncurrency",
                    style: { minWidth: '70px'}
                }
            ]
        },
        Table3 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'BillNumber',
                    selector: "documentnumber",
                    style: { minWidth: '120px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'CostAllocationMethod',
                    selector: "costallocationmethod",
                    style: { minWidth: '190px'}
                },
                {
                    name: 'LOB',
                    selector: "lob",
                    style: { minWidth: '60px'}
                },
                {
                    name: 'CostAllocationParameterValue',
                    selector: "costallocationparametervalue",
                    style: { minWidth: '100px'}
                },
                {
                    name: 'CostAllocated Lcl Curr',
                    selector: "amountinlocalcurrency",
                    style: { minWidth: '100px'}
                }
            ]
        },
        Table_1 : {
            data: null,
            FULL_data: null,
            selected_ID: [],
            columns : [
                {
                    name: 'DocumentNumber',
                    selector:'documentnumber',
                },
                {
                    name: 'DocumentType',
                    selector:'documenttype',
                },
                {
                    name: 'VenderName',
                    selector:'sendername',
                },
                {
                    name: 'TotalBaseValue',
                    selector:'totalbasevalue',
                },
                {
                    name: 'ThisPrdBaseValue',
                    selector:'thisprdbasevalue',
                },
                {
                    name: 'BillValue',
                    selector:'billvalue',
                },
                {
                    name: 'ProcessedAJ',
                    selector:'processedaj',
                },
                {
                    name: 'ToPostAJ',
                    selector:'topostaj',
                },
                {
                    name: 'SumOfPostJournal',
                    selector:'sumofpostjournal',
                },
                {
                    name: 'ProcessedCA',
                    selector:'processedca',
                },
                {
                    name: 'ToPostCA',
                    selector:'topostca',
                },
                {
                    name: 'SumOfAllocationValue',
                    selector:'sumofallocationvalue',
                },
                {
                    name: 'Month',
                    selector:'month',
                },
                {
                    name: 'ForPrdFrom',
                    selector:'forprdfrom',
                },
                {
                    name: 'ForPrdTo',
                    selector:'forprdto',
                }]
        },
        Table_2 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'DocumentNumber',
                    selector: "documentnumber",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountDate',
                    selector: "accountingdate",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountClass',
                    selector: "accountclass",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'MISGrouping',
                    selector: "misgrouping",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountCode',
                    selector: "accountcode",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountType',
                    selector: "accounttype",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AmtInLclCurr',
                    selector: "amountinlocalcurrency",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'LedgerName',
                    selector: "ledgername",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountGroup',
                    selector: "accountgroup",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ItemType',
                    selector: "itemtype",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdfrom",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: {minWidth : '100px'}
                }
            ]
        },
        Table_3 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'DocumentNumber',
                    selector: "documentnumber",
                    style: {width: '100px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdto",
                    style: {width: '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocationMethod',
                    selector: "costallocationmethod",
                    style: {width: '100px'}
                },
                {
                    name: 'LOB',
                    selector: "lob",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocationParameterValue',
                    selector: "costallocationparametervalue",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocatedLclCurr',
                    selector: "amountinlocalcurrency",
                    style: {width: '100px'}
                }
            ]
        },
        Table__1 : {
            data: null,
            FULL_data: null,
            selected_ID: [],
            columns : [
                {
                    name: 'DocumentNumber',
                    selector:'documentnumber',
                },
                {
                    name: 'DocumentType',
                    selector:'documenttype',
                },
                {
                    name: 'VenderName',
                    selector:'sendername',
                },
                {
                    name: 'Provision',
                    selector:'totalbasevalue',
                },
                // {
                //     name: 'ThisPrdBaseValue',
                //     selector:'thisprdbasevalue',
                // },
                {
                    name: 'BillBooked',
                    selector:'billvalue',
                },
                {
                    name: 'ProvisionReversedAJ',
                    selector:'processedaj',
                },
                {
                    name: 'ReversalToPostAJ',
                    selector:'topostaj',
                },
                {
                    name: 'ProvisionReversedCA',
                    selector:'processedca',
                },
                {
                    name: 'ReversalToPostCA',
                    selector:'topostca',
                },
                {
                    name: 'AJDiffInd',
                    selector:'sumofpostjournal',
                },
                {
                    name: 'SumOfAllocationValue',
                    selector:'sumofallocationvalue',
                },
                {
                    name: 'Month',
                    selector:'month',
                },
                {
                    name: 'ForPrdFrom',
                    selector:'forprdfrom',
                },
                {
                    name: 'ForPrdTo',
                    selector:'forprdto',
                }
            ]
        },
        Table__2 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'DocumentNumber',
                    selector: "documentnumber",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountDate',
                    selector: "accountingdate",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountClass',
                    selector: "accountclass",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'MISGrouping',
                    selector: "misgrouping",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountCode',
                    selector: "accountcode",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountType',
                    selector: "accounttype",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AmtInLclCurr',
                    selector: "amountinlocalcurrency",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'LedgerName',
                    selector: "ledgername",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'AccountGroup',
                    selector: "accountgroup",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ItemType',
                    selector: "itemtype",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdfrom",
                    style: {minWidth : '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: {minWidth : '100px'}
                }
            ]
        },
        Table__3 : {
            data: null,
            FULL_data: null,
            columns : [
                {
                    name: 'DocumentNumber',
                    selector: "documentnumber",
                    style: {width: '100px'}
                },
                {
                    name: 'ForPrdFrom',
                    selector: "forprdto",
                    style: {width: '100px'}
                },
                {
                    name: 'ForPrdTo',
                    selector: "forprdto",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocationMethod',
                    selector: "costallocationmethod",
                    style: {width: '100px'}
                },
                {
                    name: 'LOB',
                    selector: "lob",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocationParameterValue',
                    selector: "costallocationparametervalue",
                    style: {width: '100px'}
                },
                {
                    name: 'CostAllocatedLclCurr',
                    selector: "amountinlocalcurrency",
                    style: {width: '100px'}
                }
            ]
        },
        fetching : false,
        StartDate: '',
        EndDate: '',
        dropdown_month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        dropdown_year: [],
        dropdown_report: [
            {
                name : "Expense Amortization Tracking",
                value : "Table"
            },
            {
                name : "Deferred PrePayment Amortization Tracking",
                value : "_Table_"
            },
            // {
            //     name : "Cost Provision Status Tracking (PO/IP)",
            //     value : "Table_"
            // },
            // {
            //     name : "Cost Provision Status Tracking (GSE)",
            //     value : "Table__"
            // }
        ],
        OPT_ : {
            "Table" : "Expense Amortization Tracking",
            "_Table_" : "Deferred PrePayment Amortization Tracking",
            "Table_" : "Cost Provision Status Tracking (PO/IP)",
            "Table__" : "Cost Provision Status Tracking (GSE)"
        },
        formData: {
            Month: '',
            Year: '',
            Report : '',
            ReportName : ''
        }
      }
    }


    componentDidMount () {

        // Get the current year
        let currentYear = new Date().getFullYear();

        // Calculate the last three years, excluding years earlier than 2023
        let lastThreeYears = [];
        for (let i = 0; i < 3; i++) {
            let year = currentYear - i;
            // if (year >= 2023) {
                lastThreeYears.push(year);
            // }
        }

        console.log(lastThreeYears);

        this.setState({ dropdown_year: lastThreeYears })

        getRequest(`api/veranda/getCompany0`)
        .then( res => {

            if(res.type === "success"){
                
                let data = JSON.parse(res.res.data)

                data = _.sortBy(data, "orgname")

                console.log(data)

                this.setState({Companies: data})
            }
        })
    }


    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        if(NAME === "Report"){

            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    [NAME]: VALUE,
                    ReportName: this.state.OPT_[VALUE]
                }
            }, () => console.log(this.state.formData))
        }

        else{

            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    [NAME]: VALUE
                }
            }, () => console.log(this.state.formData))
        }


    }


    handleSubmit = () => {

        const year = this.state.formData.Year
        const month = this.state.formData.Month
        const monthIndex = new Date(Date.parse(month + '1,' + year)).getMonth()
        const startDate = new Date(year, monthIndex, 1)
        const endDate = new Date(year, monthIndex + 1, 0)

        this.setState(
        {
            ...this.state,
            StartDate: getStandardDate(startDate),
            EndDate: getStandardDate(endDate),
            showActivate: true,
            Table1 : {
                ...this.state.Table1,
                selected_ID: [],
                data: null,
                FULL_data: null,
            },
            Table2 : {
                ...this.state.Table2,
                data: null,
                FULL_data: null,
            },
            Table3 : {
                ...this.state.Table3,
                data: null,
                FULL_data: null,
            },
            Table_1 : {
                ...this.state.Table_1,
                selected_ID: [],
                data: null,
                FULL_data: null,
            },
            Table_2 : {
                ...this.state.Table_2,
                data: null,
                FULL_data: null,
            },
            Table_3 : {
                ...this.state.Table_3,
                data: null,
                FULL_data: null,
            },
            Table__1 : {
                ...this.state.Table__1,
                selected_ID: [],
                data: null,
                FULL_data: null,
            },
            Table__2 : {
                ...this.state.Table__2,
                data: null,
                FULL_data: null,
            },
            Table__3 : {
                ...this.state.Table__3,
                data: null,
                FULL_data: null,
            }
        }, () => {
            console.log(this.state.StartDate, this.state.EndDate)

            if(this.state.formData.Report === '_Table_' && !this.state.EditTable.data){
                getRequest(`api/edureka/GetDeferredAmortizationTracking_pre?orgid=${this.state.Company}&startdate=${this.state.StartDate}&enddate=${this.state.EndDate}`)
                .then(res => {

                    if(res.res.status === 'success'){

                        let DATA = JSON.parse(res.res.data)
                        
                        console.log(DATA)

                        this.setState({ 
                            ...this.state,
                            EditTable: {
                                ...this.state.EditTable,
                                data: DATA
                            }
                        })
                        
                    }
                })
            }
            else if(this.state.formData.Report === '_Table_' && this.state.EditTable.data){

                postRequest(`api/edureka/GetDeferredAmortizationTracking?orgid=${this.state.Company}&startdate=${this.state.StartDate}&enddate=${this.state.EndDate}`, { PrePaidReport : this.state.EditTable.data } )
                .then(res => {
                    if(res.res.status === 'success'){
        
                        console.log(res.res.data)
        
                        let RESP = JSON.parse(res.res.data.Payload)
        
                        console.log(RESP)

                        this.setState({ 
                            ...this.state,
                            EditTable: {
                                ...this.state.EditTable,
                                data: null
                            }
                        })  
        
                        RESP.ExpenseAmortizationSummary && 
                        this.setState({ 
                            ...this.state,
                            _Table_1 : {
                                ...this.state._Table_1,
                                FULL_data : this.selectableCheck(RESP.ExpenseAmortizationSummary)
                            }
                        })
        
                        RESP.AmortizePrePaidAccountingJournal && 
                        this.setState({ 
                            ...this.state,
                            _Table_2 : {
                                ...this.state._Table_2,
                                FULL_data : RESP.AmortizePrePaidAccountingJournal
                            }
                        })
        
                        RESP.PrePaidCostAllocation && 
                        this.setState({ 
                            ...this.state,
                            _Table_3 : {
                                ...this.state._Table_3,
                                FULL_data : RESP.PrePaidCostAllocation
                            }
                        })
                    }
                })

            }
            else if(this.state.formData.Report === 'Table'){

                getRequest(`api/edureka/getExpenseAmortizationTracking?orgid=${this.state.Company}&startdate=${this.state.StartDate}&enddate=${this.state.EndDate}`)
                .then(res => {
                    if(res.res.status === 'success'){
        
                        console.log(res.res.data)
        
                        let RESP = JSON.parse(res.res.data.Payload)
        
                        console.log(RESP)
        
                        RESP.ExpenseAmortizationSummary && 
                        this.setState({ 
                            ...this.state,
                            Table1 : {
                                ...this.state.Table1,
                                FULL_data : this.selectableCheck(RESP.ExpenseAmortizationSummary)
                            }
                        })
        
                        RESP.AmortizePrePaidAccountingJournal && 
                        this.setState({ 
                            ...this.state,
                            Table2 : {
                                ...this.state.Table2,
                                FULL_data : RESP.AmortizePrePaidAccountingJournal
                            }
                        })
        
                        RESP.PrePaidCostAllocation && 
                        this.setState({ 
                            ...this.state,
                            Table3 : {
                                ...this.state.Table3,
                                FULL_data : RESP.PrePaidCostAllocation
                            }
                        })
                    }
                })
            }
            else if(this.state.formData.Report === 'Table_') {

                getRequest(`api/edureka/getCostProvisionStatusTracking?startdate=${this.state.StartDate}&enddate=${this.state.EndDate}`)
                .then(res => {
                    if(res.res.status === 'success'){
                        console.log(res.res.data)
        
                        let RESP = JSON.parse(res.res.data)
        
                        console.log(RESP)

                        RESP.CostProvisionSummary && 
                        this.setState({ 
                            ...this.state,
                            Table_1 : {
                                ...this.state.Table_1,
                                FULL_data : this.selectableCheck(RESP.CostProvisionSummary)
                            }
                        })
        
                        RESP.CostProvisionJournal && 
                        this.setState({ 
                            ...this.state,
                            Table_2 : {
                                ...this.state.Table_2,
                                FULL_data : RESP.CostProvisionJournal
                            }
                        })
        
                        RESP.CPCostAllocation && 
                        this.setState({ 
                            ...this.state,
                            Table_3 : {
                                ...this.state.Table_3,
                                FULL_data : RESP.CPCostAllocation
                            }
                        })
                    }
                })
            } 
            else {

                getRequest(`api/edureka/getCostProvisionReversal?startdate=${this.state.StartDate}&enddate=${this.state.EndDate}`)
                .then(res => {
                    if(res.res.status === 'success'){
                        console.log(res.res.data)
                        
                        let RESP = JSON.parse(res.res.data.Payload)
                        console.log(RESP)

                        RESP.CostProvisionSummary && 
                        this.setState({ 
                            ...this.state,
                            Table__1 : {
                                ...this.state.Table__1,
                                FULL_data : this.selectableCheck(RESP.CostProvisionSummary)
                            }
                        })
        
                        RESP.CostProvisionReversalJournal && 
                        this.setState({ 
                            ...this.state,
                            Table__2 : {
                                ...this.state.Table__2,
                                FULL_data : RESP.CostProvisionReversalJournal
                            }
                        })
        
                        RESP.CPBGSERCostAllocation && 
                        this.setState({ 
                            ...this.state,
                            Table__3 : {
                                ...this.state.Table__3,
                                FULL_data : RESP.CPBGSERCostAllocation
                            }
                        })
                    }
                })
            }
        })
    }


    selectableCheck = (array) => {

        array.forEach( each => {

            if(
                (each.sumofallocationvalue === each.topostca || Number(each.sumofallocationvalue) === Number(each.topostca) ) && 
                (each.sumofpostjournal === '0' || each.sumofpostjournal === '0.0' || each.sumofpostjournal === 0) &&
                each.topostaj !== '0' && each.topostaj !== '0.0' && each.topostaj !== 0 && each.topostaj !== "" && each.topostaj !== "nan" &&
                each.topostca !== "nan" 
            ) {

                each.selectable = true
            }
            else{
                
                each.selectable = false
            }
        })

        console.log(array)

        return _.sortBy(array, "documentref");
    }


    onCheckbox = ( e ) => {

        let table = this.state.formData.Report

        const ID = e.target.id

        let selected_ID = this.state[table + '1'].selected_ID

        if(selected_ID.includes(ID)){

            const index = selected_ID.indexOf(ID);
            if (index > -1) { // only splice array when item is found
                selected_ID.splice(index, 1); // 2nd parameter means remove one item only
            }

        }
        else selected_ID.push(ID)

        this.applyFilter(table, selected_ID)
    }


    applyFilter = (table, selected_ID) => {

        let data2 = this.state[table + '2'].FULL_data.filter( each => selected_ID.includes(each.documentnumber))

        let data3 = this.state[table + '3'].FULL_data.filter( each => selected_ID.includes(each.documentnumber))

        this.setState({
            ...this.state,
            [table + '1'] : {
                ...this.state[table + '1'],
                selected_ID
            },
            [table + '2'] : {
                ...this.state[table + '2'],
                data : data2
            },
            [table + '3'] : {
                ...this.state[table + '3'],
                data : data3
            }
        })
      
    }


    selectALL = (all) => {

        let tabl = this.state.formData.Report

        let Table = this.state[tabl + '1'].FULL_data

        let selected_ID = []

        if(all) {

            Table.forEach( each => {

                if(each.selectable) selected_ID.push(each.documentnumber)
            })
        }

        this.applyFilter(tabl, selected_ID)
    }


    handleDownload = () => {
        this.setState({
            ...this.state
        }, () => {
            this.downloadLink.link.click()
        })
    }
    

    onSave = () => {

        console.log('onSave')

        this.setState({
            showActivate: false
        }, () => {

            let Report = this.state.formData.Report 

            let selected_ID = this.state[Report + '1'].selected_ID

            let arry = []

            selected_ID.forEach( each => {
                let obj = {}

                obj.ID = each

                obj.data2 = this.state[Report + '2'].FULL_data.filter( one => each === one.documentnumber)
                obj.data3 = this.state[Report + '3'].FULL_data.filter( one => each === one.documentnumber)

                arry.push(obj)
            })

            console.log(arry)

            let formData = this.state.formData

            postRequest(`api/edureka/periodEndProcess_Save?orgid=${this.state.Company}&fileName=${formData.ReportName}_${formData.Year}_${formData.Month}`, arry)
            .then( res => {
                console.log(res)

                setTimeout(() => {

                    this.GetLogs()
                        
                    // window.location.reload()
                    
                }, 4000);
            })

            // Call the function to start making the API calls in sequence

            // this.setState({Logs_Array : arry})

            // this.makeApiCallsSequentially(arry);

        })
        
    }
    
    GetLogs = () => {

        let formData = this.state.formData

        getRequest(`api/edureka/periodEndProcess_GetLog?orgid=${this.state.Company}&fileName=${formData.ReportName}_${formData.Year}_${formData.Month}`)
        .then( res => {

            if(res.res.status === "success"){

                let DATA = JSON.parse(res.res.data)

                // const logSortOrder = (log) => {
                //     if (log === 'Started') return 0;
                //     if (log === 'Success') return 1;
                //     return 2; // Assume 'Failed' as last priority
                // };

                // const sortProcessCd = (log) => {
                //     if (log === 'AD') return 0;
                //     if (log === 'CA') return 1;
                // };

                // DATA = _.orderBy(DATA, ['ID', (item) => sortProcessCd(item.ProcessCode), (item) => logSortOrder(item.Log), 'Time'], ['asc', 'desc', 'desc', 'desc']);
                
                
                DATA = _.orderBy(DATA, ['ID', 'SortKey'], ['asc', 'desc']);

                console.log(DATA)

                this.setState({ Logs_Array: DATA })
            }
        })
    }


    makeApiCallsSequentially = async (ARRAY) => {
        const itemsToProcess = ARRAY;
        
        for (const item of itemsToProcess) {
          // Make the API call using fetch or any other method
          const response = await postRequest(`api/edureka/periodEndProcess_Save_`, item)
      
          // Process the response as needed
          const RES = await response
          
          console.log(RES); // or do something else with the response

          let status = '...'

          if(RES.res.status === "success") status = "Success"
          else status = "Failed"

          this.setState( prevState => {

            let Logs_Array = prevState.Logs_Array

            Logs_Array.forEach( each => {

                if(each.ID === item.ID) each.status = status
                console.log(each)
            })

            return {
                ...prevState,
                Logs_Array
            }

          }, () => {
            console.log(this.state.Logs_Array)
          })
        }
    }

    showErr = (obj) => {
        console.log(obj)
        
        if(obj.topostca === "nan"){
            this.TopErrAlert(`This Month CA ToPost is "nan"`)
        }
    }


    TopErrAlert = (msg) => {

        this.setState({
            alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{msg}</strong></div> </Animated>
        });
        setTimeout(() => { this.setState({alert : null}); }, 3000);
    }
    

    onEditTableChange = (row, e) => {

        let val = e.target.value
        let name = e.target.name

        let DATA = this.state.EditTable.data

        console.log(val)

        if(val === ""){

        }
        else if(name === "BaseValue"){
            val = Number(val)
        }

        if( val <= DATA[row]["amountinlocalcurrency"] ){

            DATA[row][name] = val
    
            this.setState({ 
                ...this.state,
                EditTable: {
                    ...this.state.EditTable,
                    data: DATA
                }
            },() => {
                console.log(this.state.EditTable.data)
            })
        }
        else{
            this.TopErrAlert(`BaseValue should be less than or equal to "BalanceToBeAmortized"`)
        }

    }
      

  render() {

    const TABLE = this.state.formData.Report

    return (
        <Fragment>
            {/* <div style={{alignItems:'left', fontSize:'large', fontWeight:'bolder', fontFamily:'sans-serif'}}>
                Functional P&L Journal
            </div> */}

            <br/>

            { this.state.Logs_Array && 
                <Popup
                content={
                    <div className='row m-4'>
                        <div align='right'><button className='btn btn-success' onClick={this.GetLogs}><HiOutlineRefresh /> Refresh</button></div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th >ID</th>
                                <th >Time</th>
                                <th >Record</th>
                                <th >ProcessCode</th>
                                <th >Log</th>
                            </tr>
                            </thead>
                            <tbody>
                                { this.state.Logs_Array.map( (each, index) => {
                                    return(
                                        <tr key={index}>
                                            
                                            <td>{ each.ID }</td>
                                            <td>{ each.Time }</td>
                                            <td>{ each.Record }</td>
                                            <td>{ each.ProcessCode }</td>
                                            <td>{ each.Log }</td>
                                            
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                handleClose={() => this.setState({ Logs_Array : null}) }
                />
            }

            {this.state.alert}

            <div className='row mb-3'>

                <div className='col-md-2'>
                    <label htmlFor="Company" style={{fontSize: '1rem'}}> Company </label>
                    <select className="browser-default custom-select" id="Company" autoFocus name="Company" value={this.state.Company} onChange={(e) => this.setState({Company: e.target.value}) } >
                        <option value=""> select </option>
                        { this.state.Companies.map((item, key) => 
                            <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
                        )}
                    </select>
                </div>

                <div className='col-md-3'>
                    <label htmlFor="Report" style={{fontSize: '1rem'}}> Report </label>
                    <select className="browser-default custom-select" name='Report' value={this.state.formData.Report} onChange={this.handleChange}>
                        <option value=''>Select</option>
                        {this.state.dropdown_report.map((item, index) => <option key={index} value={item.value}>{item.name}</option>)}
                    </select>
                </div>

                <div className='col-md-2'>
                    <label htmlFor="Year" style={{fontSize: '1rem'}}> Year </label>
                    <select className="browser-default custom-select" name='Year' value={this.state.formData.Year} onChange={this.handleChange}>
                        <option value=''>Select</option>
                        {this.state.dropdown_year.map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </div>

                <div className='col-md-2'>
                    <label htmlFor="Month" style={{fontSize: '1rem'}}> Month </label>
                    <select className="browser-default custom-select" name='Month' value={this.state.formData.Month} onChange={this.handleChange}>
                        <option value=''>Select</option>
                        {this.state.dropdown_month.map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </div>

                <div className='col-md-1 mt-4'>
                    <button className='btn btn-warning' disabled={!this.state.formData.Month || !this.state.formData.Year || !this.state.formData.Report} onClick={this.handleSubmit}>Get</button>
                </div>
                <div className='col-md-1 mt-4'>
                    <button className='btn btn-success' disabled={!this.state.formData.Month || !this.state.formData.Year || !this.state.formData.Report} onClick={this.GetLogs}>GetLogs</button>
                </div>

            </div>


            {this.state.EditTable.data &&

            <>
            <h5>PrePaidReport</h5>

            <div className='scroll-table'>
            <table id='table-css' >
            <thead style={{heigth: '4rem'}}>
            <tr style={{heigth: '4rem'}}>
                {this.state.EditTable.columns.map( (each, ind) => {
                    return(
                        <th>{each.name}</th>
                    )
                })}                                              
            </tr>
            </thead>

            <tbody>           
                {this.state.EditTable.data.map((each, indx) => {
                    return(
                    <tr key={indx}>                       
                        {this.state.EditTable.columns.map( (one, ind) => {
                            if(one.selector === "BaseValue"){
                            return(
                                <td><input type='number' name={one.selector} id={ind} value={each[one.selector]} onChange={(e)=>this.onEditTableChange(indx,e)}></input></td>
                            )}
                            else return(
                                <td>{each[one.selector]}</td>
                            )
                        })}
                    </tr>
                    )
                })
                }             
            </tbody>
            </table>
            </div>

            <div align='center'>
                <button className='btn btn-warning' onClick={this.handleSubmit}>Get</button>
            </div>

            </>
            }


            {(this.state.Table1.FULL_data || this.state._Table_1.FULL_data) &&

            <>
            <h5>{this.state.OPT_[TABLE]} Report</h5>

            <div className='row'>
                <div className='col-md-6' align='left'>
                    <button className='btn btn-primary' onClick={() =>this.selectALL(true)}>Select All</button>
                    <button className='btn btn-secondary' onClick={() => this.selectALL()}>Select None</button>
                </div>
                <div className='col-md-6' align='right'>
                    <button className='btn btn-primary' onClick={this.handleDownload}> <GetAppIcon /> CSV</button>
                    <CSVLink data={this.state[TABLE + '1'].FULL_data} filename={`${this.state.OPT_[TABLE]}.csv`} ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
                </div>
            </div>

            
            <div className='scroll-table'>
            <table id='table-css' >
            <thead style={{heigth: '4rem'}}>
            <tr style={{heigth: '4rem'}}>
                <th ></th>
                {this.state[TABLE + '1'].columns.map( (each, ind) => {
                    return(
                        <th>{each.name}</th>
                    )
                })}                                              
            </tr>
            </thead>

            <tbody>           
                {this.state[TABLE + '1'].FULL_data.map((each, indx) => {
                    return(
                    <tr onClick={()=>this.showErr(each)} key={indx} style={{ 'background': this.state[TABLE + '1'].selected_ID.includes(each.documentnumber) ? '#ccdbfd' : '' }}>
                        <td><input type="checkbox" name={each.documentnumber} id={each.documentnumber} onChange={(e) => this.onCheckbox(e)}
                        disabled={!each.selectable} 
                        checked={this.state[TABLE + '1'].selected_ID.includes(each.documentnumber)} /></td>
                        {this.state[TABLE + '1'].columns.map( (one, ind) => {
                            return(
                                <td >{each[one.selector]}</td>
                            )
                        })}
                    </tr>
                    )
                })
                }             
            </tbody>
            </table>
            </div>

            <div align='center'>
                <button className='btn btn-success' disabled={!this.state[TABLE + '1'].selected_ID.length || !this.state.showActivate} onClick={this.onSave}>Activate</button>
            </div>

            </>
            }

            <br/>
                                            
            { (this.state.Table2.data || this.state._Table_2.data) && 
            
                <TableWithDownload 
                title={'Amortization Accounting Journal'} 
                columns={this.state[TABLE + '2'].columns} 
                data={this.state[TABLE + '2'].data}
                download={true}
                loading={false}
                downloadFileName={'AmortizationAccountingJournal'}/>
            }

            <br/>

            { (this.state.Table3.data || this.state._Table_3.data) && 

                <TableWithDownload 
                title={'Cost Allocation'} 
                columns={this.state[TABLE + '3'].columns} 
                data={this.state[TABLE + '3'].data}
                download={true}
                loading={false}
                downloadFileName={'CostAllocation'}/>
            }


            {/* screen 2 */}

            {this.state.Table_1.FULL_data &&

            <>
            <h5>Cost Provision Summary (PO/IP)</h5>

            <div className='row'>
                <div className='col-md-6' align='left'>
                    <button className='btn btn-primary' onClick={() =>this.selectALL(true)}>Select All</button>
                    <button className='btn btn-secondary' onClick={() => this.selectALL()}>Select None</button>
                </div>
                <div className='col-md-6' align='right'>
                    <button className='btn btn-primary' onClick={this.handleDownload}> <GetAppIcon /> CSV</button>
                    <CSVLink data={this.state.Table_1.FULL_data} filename='CostProvisionSummary.csv' ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
                </div>
            </div>

            <div className='scroll-table'>
            <table id='table-css' >
            <thead style={{heigth: '4rem'}}>
            <tr style={{heigth: '4rem'}}>
                <th ></th>
                {this.state.Table_1.columns.map( (each, ind) => {
                    return(
                        <th>{each.name}</th>
                    )
                })} 
                                               
            </tr>
            </thead>

            <tbody>           
                {this.state.Table_1.FULL_data.map((each, indx) => {
                    return(
                    <tr key={indx} style={{ 'background': this.state.Table_1.selected_ID.includes(each.documentnumber) ? '#ccdbfd' : '' }}>
                        <td><input type="checkbox" name={each.documentnumber} id={each.documentnumber} onChange={(e) => this.onCheckbox(e)}
                        disabled={!each.selectable} 
                        checked={this.state.Table_1.selected_ID.includes(each.documentnumber)} /></td>

                        {this.state.Table_1.columns.map( (one, ind) => {
                            return(
                                <td >{each[one.selector]}</td>
                            )
                        })}
                        
                    </tr>
                    )
                })
                }             
            </tbody>
            </table>
            </div>

            <div align='center'>
                <button className='btn btn-success' disabled={!this.state.Table_1.selected_ID.length || !this.state.showActivate} onClick={this.onSave}>Activate</button>
            </div>

            </>
            }

            <br /> 

            { this.state.Table_2.data && 

                <TableWithDownload 
                title={'Cost Provision Accounting Journal (PO/IP)'} 
                columns={this.state.Table_2.columns} 
                data={this.state.Table_2.data}
                download={true}
                loading={false}
                downloadFileName={'CostProvisionAccountingJournal(PO/IP)'}/>
            }

            <br/>

            { this.state.Table_3.data && 

                <TableWithDownload 
                title={'Cost Allocation (PO/IP)'} 
                columns={this.state.Table_3.columns} 
                data={this.state.Table_3.data}
                download={true}
                loading={false}
                downloadFileName={'CostAllocation(PO/IP)'}/>
            }

              {/* screen 3 */}

              {this.state.Table__1.FULL_data &&

                <>
                <h5>Cost Provision Summary (GSE)</h5>

                <div className='row'>
                    <div className='col-md-6' align='left'>
                        <button className='btn btn-primary' onClick={() =>this.selectALL(true)}>Select All</button>
                        <button className='btn btn-secondary' onClick={() => this.selectALL()}>Select None</button>
                    </div>
                    <div className='col-md-6' align='right'>
                        <button className='btn btn-primary' onClick={this.handleDownload}> <GetAppIcon /> CSV</button>
                        <CSVLink data={this.state.Table__1.FULL_data} filename='CostProvisionSummary(GSE).csv' ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
                    </div>
                </div>

                <div className='scroll-table'>
                <table id='table-css' >
                <thead style={{heigth: '4rem'}}>
                <tr style={{heigth: '4rem'}}>
                    <th ></th>
                    {this.state.Table__1.columns.map( (each, ind) => {
                        return(
                            <th>{each.name}</th>
                        )
                    })} 
                                                
                </tr>
                </thead>

                <tbody>           
                    {this.state.Table__1.FULL_data.map((each, indx) => {
                        return(
                        <tr key={indx} style={{ 'background': this.state.Table__1.selected_ID.includes(each.documentnumber) ? '#ccdbfd' : '' }}>
                            <td><input type="checkbox" name={each.documentnumber} id={each.documentnumber} onChange={(e) => this.onCheckbox(e)}
                            disabled={!each.selectable} 
                            checked={this.state.Table__1.selected_ID.includes(each.documentnumber)} /></td>

                            {this.state.Table__1.columns.map( (one, ind) => {
                                return(
                                    <td >{each[one.selector]}</td>
                                )
                            })}
                            
                        </tr>
                        )
                    })
                    }             
                </tbody>
                </table>
                </div>

                <div align='center'>
                    <button className='btn btn-success' disabled={!this.state.Table__1.selected_ID.length || !this.state.showActivate} onClick={this.onSave}>Activate</button>
                </div>

                </>
                }

                <br /> 

                { this.state.Table__2.data && 

                    <TableWithDownload 
                    title={'Cost Provision Accounting Journal (GSE)'} 
                    columns={this.state.Table__2.columns} 
                    data={this.state.Table__2.data}
                    download={true}
                    loading={false}
                    downloadFileName={'CostProvisionAccountingJournal(GSE)'}/>
                }

                <br/>

                { this.state.Table__3.data && 

                    <TableWithDownload 
                    title={'Cost Allocation (GSE)'} 
                    columns={this.state.Table__3.columns} 
                    data={this.state.Table__3.data}
                    download={true}
                    loading={false}
                    downloadFileName={'CostAllocation(GSE)'}/>
                }

           
        </Fragment>
      
    )
  }
}

export default FunctionalJournal;