import React, { useEffect } from "react";
import {
  Widget,
  addResponseMessage,
  renderCustomComponent,
  toggleMsgLoader,
} from "react-chat-widget";

import { useDispatch } from "react-redux";
import { fetchFromDialogflow } from "../redux";

import ViewLogic from "./ViewLogic";
import ButtonGroup from "./ButtonGroup";
// import MenuCard from "./MenuCard";
// import SelectForm from './SelectForm';

// import menu from "./arrayrefs";

import "react-chat-widget/lib/styles.css";
import { connect } from "react-redux";
// import { getRequest } from "../globalhelper/helper";
import { fetchChatbotItems } from '../redux/SelectApp/saActions'
// var menu = [];
function Chatbot(props) {

  // const [ menu, setMenu ] = useState([]);

  const getChatBotItems = () => {
    console.log(props.store)
    props.fetchChatbotItems();
    if (props.store.interactions && props.store.selectApp.chatItems && props.store.selectApp.chatItems.length > 0) {
      const defaultMenu = props.store.selectApp.chatItems.find(({ id }) => id === "SelectApp");
      console.log(defaultMenu);
      addResponseMessage("Welcome to AUDIRE.");
      renderCustomComponent(() => (
        <div>
          {/* <MenuCard title={defaultMenu.title} options={defaultMenu.options} /> */}
          {/* <p>{defaultMenu.short_text}</p> */}
          {/* <ButtonGroup title={props.store.selectApp.chatItems[0].children[0].parent_short_text} options={props.store.selectApp.chatItems[0].children[0].children} /> */}
          <iframe src="http://localhost:5000"></iframe>
          {/* <SelectForm /> */}
        </div>
      ));
    }
  }


  useEffect(() => {
    getChatBotItems();
  }, [props.store.selectApp.chatItems.length]);

  const dispatch = useDispatch();

  const handleNewUserMessage = async (newMessage) => {
    toggleMsgLoader();
    dispatch(fetchFromDialogflow(newMessage)).then((data) => {
      toggleMsgLoader();
      renderCustomComponent(() => (
        // <ViewLogic intent={data.intent} value={data.value} />
        <iframe src="http://localhost:5000/" frameborder="0"></iframe>
      ));
    });
  };

  return (
    <div >
      <Widget
        // fullScreenMode={true}
        className="Chatbot"
        handleNewUserMessage={handleNewUserMessage}
        title="DataTwin - Control Tower"
        subtitle=""
      />
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    store: state
  }
};

// export default  Transactions;

export default connect(
  mapStateToProps,
  { fetchChatbotItems }
)(Chatbot);

// export default Chatbot;
