

export default function popupReducer(state = [], action) {
  switch (action.type) {
    case 'openPopup':
      return {
        ...state,
        popVisible: action.popVisible,
        currentNodeData: action.currentNodeData,
      }
    case 'closePopup':
      return {
        ...state,
        popVisible: action.popVisible,
      }
    case 'popupFieldChange':
      console.log("Reducer",{
        ...state,
        currentNodeData : {
          ...state.currentNodeData,
          node : {
            ...state.currentNodeData.node,
            enable : action.enable
          }
        },
        fieldName: action.fieldName,
        nodeName: undefined,
        nodeType: action.nodeType,
        enable: action.enable,
        position: action.position
      })
      return {
        ...state,
        fieldName: action.fieldName,
        nodeName: undefined,
        nodeType: action.nodeType,
        enable: action.enable,
        position: action.position
      }
      case 'popupNodeAdd':
      return {
        ...state,
        nodeName: action.nodeName,
        nodeType: action.nodeType,
        position: action.position
      }
    default:
      return state
  }
}