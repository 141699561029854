import React, { PureComponent } from 'react';
import { Form, Col } from "react-bootstrap";

import { setFormValue } from '../redux/FormInputs/formActions';
import { connect } from 'react-redux';

class Input extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Form
                className="bg-white p-1 rounded col-md-6"
            // style={{ width: "15vw" }}
            >
                <Form.Group as={Col} md="6" controlId={this.props.modelName} >
                    <Form.Label style={{ whiteSpace : 'nowrap', overflow : 'hidden' }}>{this.props.label}</Form.Label>
                    <span>
                        {/* <label htmlFor={this.props.modelName}> {this.props.label} </label> */}
                        <input className="form-control"
                            onChange={(ev) => {
                                this.props.setFormValue(this.props.modelName, ev.target.value)
                            }}
                            type="text" id={this.props.modelName} name={this.props.modelName} placeholder={"Please input the " + this.props.label} /> <br />
                    </span>
                </Form.Group>
            </Form>
        )
    }
};

const mapStateToProps = (state) => {
    return (
        {
            store: state
        }
    )
}

export default connect(mapStateToProps, { setFormValue })(Input);