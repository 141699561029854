import React, { Component } from 'react';
import './COD.css';
import {connect} from 'react-redux'
import {Form, Button} from 'react-bootstrap';
import {getRequest, postRequest, getHumanReadableDate, getCurrentDate, convertToINR} from '../../globalhelper/helper'

export class LossAndRecovery extends Component {
    constructor(props) {
      super(props)

      this.current = this.props.state

      console.log(this.current)
    
      this.state = {
         formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            date: getCurrentDate(),
            category: '',
            amount: '',
            remarks: '',
            selectedFile: null,
            receiptname: '',
            postedby: this.current.login.userData.USR_Name
         },
         fetching: false,
         show_table: false,
         allData: [],
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         selectedFile: null,
         fileName: '',
         fileChange: '',
         formCompleted: false,
         fileUploadButton: 'Upload',
         confirmButton: 'Confirm'
      }
    }

    componentDidMount(){
        console.log(this.state.formData)
        
        this.setState({
            ...this.state,
            fetching: true
        })

        getRequest(`api/cod/getDepositReport`).then(response => {
            if(response.res.data !== undefined){
                let allData = response.res.data
                let temp_dropdown_client = []
                let temp_dropdown_stationcode = []
                allData.forEach(item=> {
                    temp_dropdown_client.push(item.client)
                    temp_dropdown_stationcode.push(item.stationcode)
                })

                let dropdown_client = [... new Set(temp_dropdown_client)]
                let dropdown_stationcode = [... new Set(temp_dropdown_stationcode)]

                this.setState({
                    ...this.state,
                    fetching: false,
                    show_table: true,
                    allData: allData,
                    dropdown_client: dropdown_client,
                    dropdown_stationcode: dropdown_stationcode
                })
            }
        })
    }

    handleClientChange = (event) => {
        const VALUE = event.target.value;

        let model = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                client : VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.client === VALUE){
                model.push(item.businessmodel)
                return model
            }
        })

        let model1 = [...new Set(model)]

        this.setState({
            ...this.state,
            dropdown_model: model1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formCompleted: false,
            formData: {
                ...this.state.formData,
                client: VALUE,
                model: '',
                zone: '',
                stationcode: ''
            }
        }, () => this.formValidation())
    }

    handleModelChange = (event) => {
        const VALUE = event.target.value;

        let zone = []

        this.setState({
            formData: {
                ...this.state.formData,
                model : VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.businessmodel === VALUE && item.client === this.state.formData.client){
                zone.push(item.zone)
                return zone
            }
        })

        let zone1 = [...new Set(zone)]

        this.setState({
            ...this.state,
            dropdown_zone: zone1,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                zone: '',
                stationcode: '',
                model: VALUE
            }
        }, () => this.formValidation())
    }

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                stationcode.push(item.stationcode)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                stationcode: '',                
                zone: VALUE
            }
        }, () => this.formValidation())
    }

    handleStationCodeChange = (event) => {
        const VALUE = event.target.value

        this.setState({
            ...this.state,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                stationcode: VALUE
            }
        }, () => {
            this.getRemainingValues()
            this.formValidation()
        })
    }

    getRemainingValues = () => {
        getRequest(`api/cod/getRemainingValues?input=` + this.state.formData.stationcode).then(response => {
            if(response.res.status === 'success'){
                let remainingData = response.res.data

                console.log(remainingData)

                let dropdown_client = remainingData.map(value=> value.client_short_name)

                let dropdown_model = remainingData.map(value => value.ci_business_type)

                let dropdown_zone = remainingData.map(value => value.zone)

                this.setState({
                    ...this.state,
                    dropdown_model: [dropdown_model[0]],
                    dropdown_zone: [dropdown_zone[0]],
                    formCompleted: false,
                    formData: {
                        ...this.state.formData,
                        client: dropdown_client[0],
                        model: dropdown_model[0],
                        zone: dropdown_zone[0],
                    }
                }, () => this.formValidation())
            }
        })
    }

    handleCategoryChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                // remarks: VALUE === 'WriteOff' ?'Operations Head Approval' : '',
                [NAME]: VALUE
            }
        }, () => this.formValidation())
    }

    handleChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => this.formValidation())
    }

    handleRemarksChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => this.formValidation())
    }

    fileChangeHandler = (e) => {
        const nameChange = [e.target.files[0]].map(value => {
            return value, {
                name: value.name.replace(/[^a-zA-Z0-9_.]/g, '')
            }
        })

        this.setState({
            ...this.state,
            fileName: '',
            fileChange: nameChange[0].name,
            selectedFile: e.target.files[0],
            fileUploadButton: 'Upload',
            formCompleted: false,
            formData: {
                ...this.state.formData,
                selectedFile: e.target.files[0]
            }
        }, () => this.formValidation())
    }

    fileUpload = async () => {
        const formData = new FormData()

        formData.append(
            "file",
            this.state.selectedFile
        )

        await postRequest(`api/cod/recoveryUpload`, formData).then(response => {
            console.log(response)
        })

        this.setState({
            ...this.state,
            fileName: 'Yes',
            formCompleted: false,
            fileUploadButton: 'Uploaded',
            formData: {
                ...this.state.formData,
                receiptname: this.state.fileChange
            }
        }, () => this.formValidation())
    }

    dataUpload = async (event) => {
        event.preventDefault()
        const formData = new FormData()
        formData.append("file", this.state.formData)
        console.log(this.state.formData)
        await postRequest(`api/cod/recoveryData`, this.state.formData).then(res=> {
            console.log(res)
        })

        this.setState({
            ...this.state,
            confirmButton: 'Uploaded'
        })

        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    formValidation = () => {
        if(this.state.formData.amount && this.state.formData.category && this.state.formData.client && this.state.formData.model
            && this.state.formData.date && this.state.formData.stationcode && this.state.formData.postedby && this.state.formData.receiptname
            && this.state.formData.stationcode && this.state.formData.zone){
                this.setState({
                    ...this.state,
                    formCompleted: true
                })
            }
    }

  render() {
    return (
      <div>
        <div className='header_div'>
            COD Loss & Recovery
        </div>

        <br/>
        <br/>

        {!this.state.fetching ?
        
        <Form>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Client</Form.Label>
                        <select className='spacing' value={this.state.formData.client} name='client' required onChange={this.handleClientChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_client.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Model</Form.Label>
                        <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_model.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Zone</Form.Label>
                        <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_zone.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Station Code</Form.Label>
                        <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationCodeChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_stationcode.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <select className='spacing' value={this.state.formData.category} name='category' required onChange={this.handleCategoryChange}>
                            <option value=''>Select</option>
                            <option value='WriteOff'>WriteOff</option>
                            <option value='Recovered'>Recovered</option>
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Amount</Form.Label>
                        <input className='spacing' type='number' required value={this.state.formData.amount} name='amount' onChange={this.handleChange}></input>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Remarks</Form.Label>
                        {this.state.formData.category === "" ?
                        <select className='spacing' value={this.state.formData.remarks} name='remarks' required onChange={this.handleRemarksChange}>
                            <option value='' disabled>Select</option>
                        </select> : null}
                        {this.state.formData.category === "WriteOff" ?
                            <select className='spacing' value={this.state.formData.remarks} name='remarks' required onChange={this.handleRemarksChange}>
                                <option value=''>Select</option>
                                <option value='Operations Head Approval'>Operations Head Approval</option>
                                <option value='Client Confirmation'>Client Confirmation</option>
                            </select> :
                        this.state.formData.category === "Recovered" ? 
                            <select className='spacing' value={this.state.formData.remarks} name='remarks' required onChange={this.handleRemarksChange}>
                                <option value=''>Select</option>
                                <option value="Adjustment towards expenses">Adjustment towards expenses</option>
                                <option value='Debit To DA'>Debit To DA</option>
                                <option value='Debit To Vendor'>Debit To Vendor</option>
                                <option value='Debit To Onroll Staff'>Debit To Onroll Staff</option>
                                <option value='Debit To Offroll Staff'>Debit To Offroll Staff</option>
                                <option value='Transfer to ConnectIndia'>Transfer to ConnectIndia</option>
                            </select> : null}
                    </Form.Group>
                </div>                
            </div>
        </Form> : <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div> }

            {!this.state.fetching ? 
            <div className='row'>
                 <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Proof Upload</Form.Label>
                        <Form.Control style={{marginBottom:5}} type='file' accept='application/jpg, application/pdf, application/jpeg' onChange={this.fileChangeHandler}></Form.Control>
                        <Button variant='success' onClick={this.fileUpload} disabled={!this.state.selectedFile || this.state.fileUploadButton === 'Uploaded'}>{this.state.fileUploadButton}</Button>
                    </Form.Group>
                </div>
            </div>: null}

            <br/>
            <br/>

            {this.state.show_table ?
            <div className='col-md-4'>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th style={{width:150}}>Details</th>
                            <th style={{width:300}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Client</td>
                            <td>{this.state.formData.client}</td>
                        </tr>
                        <tr>
                            <td>Model</td>
                            <td>{this.state.formData.model}</td>
                        </tr>
                        <tr>
                            <td>Zone</td>
                            <td>{this.state.formData.zone}</td>
                        </tr>
                        <tr>
                            <td>Client Code</td>
                            <td>{this.state.formData.stationcode}</td>
                        </tr>
                        <tr>
                            <td>Posted Date</td>
                            <td>{getHumanReadableDate(this.state.formData.date)}</td>
                        </tr>
                        <tr>
                            <td>Category</td>
                            <td>{this.state.formData.category}</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>{convertToINR(Number(this.state.formData.amount))}</td>
                        </tr>
                        <tr>
                            <td>Remarks</td>
                            <td>{this.state.formData.remarks}</td>
                        </tr>
                        {this.state.fileName === 'Yes' ?
                        <tr>
                            <td>Proof Name</td>
                            <td>{this.state.selectedFile.name}</td>
                        </tr> : null }
                    </tbody>
                </table>
            </div>
            : null}
            <br/>
            <br/>

            {this.state.show_table ?
            <div className='wrapper'>
                <Button variant='success' onClick={this.dataUpload} disabled={!this.state.formCompleted}>{this.state.confirmButton}</Button>
            </div> : null }

      </div>
    )
  }
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (LossAndRecovery)