import React, {Component, Fragment} from 'react'
import {Form, Button} from 'react-bootstrap';
import './UI.css'
import axios from 'axios'
import { convertNumberIntoINR, getRequest, postRequest } from '../../globalhelper/helper';

class UI extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this)
      this.state = {
          formData: {
            pincode: '',
            tarifftype: 'residential',
            monthlybill: '',
            rooftoparea: '',
            sanctionload: '5',
            batterybackup: 'no',
            batterycapacity: '10',
            electricvehicle: 'no',  //change
            loanrequired: 'yes',
            installationheight: 'Low(0.5m)', //change
            formid : '1',  //change
            discom: '',
            state: '',
            district: '' 
          },
          discomList: [],
          resultObj: {},
          show_recommendation : false
      }
    }


handleSubmit =(event) => {
    event.preventDefault();
    postRequest('api/solarcalculator/saveinputs', this.state.formData).then(response => {
        console.log(response)
        this.setState({
            ...this.state,
            resultObj : response.res.data.response,
            show_recommendation : true
        })
    });
}

onPincodeEnter = () => {
   
    const PINCODE = this.state.formData.pincode;
    this.setState({
        discomList: []
    })
    getRequest('api/solarcalculator/getStateForPincode?pincode=' + PINCODE).then(response => {
        console.log(response)
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                state : response.res.data.statename,
                district : response.res.data.district,
                state_district : response.res.data.statename + "-" + response.res.data.district,
                discom : response.res.data.discoms[0].discoms
            },
            discomList : response.res.data.discoms
        })
    })
}


    handleChange = (ev) => {
        const NAME = ev.target.name, VALUE = ev.target.value;

        this.setState({
            ...this.state,
            show_recommendation : false,
            formData : {
                ...this.state.formData,
                [NAME] : VALUE
            }
        })

    }

  
    render() {
      return (

        <Form onSubmit = {this.handleSubmit}>
            <div className="row">
                <div className="col-md-3">
                    <Form.Group>
                        <Form.Label> Pincode</Form.Label>
                        <input className="calc" autoFocus type="number" name="pincode" value={this.state.formData.pincode} min={100000} max={999999} onChange={this.handleChange} placeholder="Enter pincode"/>
                    </Form.Group>
                </div>
                <div className="col-md-1">
                    <Button style={{ marginTop : 27 }} onClick={this.onPincodeEnter}>Enter</Button>
                </div>
                <div className="col-md-4">
                    <Form.Group>
                        <Form.Label> District</Form.Label>
                        <input className="calc" readOnly type = "text" value={this.state.formData.district}/>
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group>
                        <Form.Label> State</Form.Label>
                        <input className="calc" readOnly type = "text" value={this.state.formData.state}/>
                    </Form.Group>
                </div>
            </div>

            <div className="row">
            </div>

            <div className="row">
                <div className={ this.state.formData.tarifftype ==="commercial" ? "col-md-4" : "col-md-4" }>
                    <Form.Group>
                        <Form.Label> Discom</Form.Label>
                        <select className="calc" value={this.state.formData.discom} name="discom" onChange={this.handleChange}>
                            { this.state.discomList.map((item, ind) => 
                                <option value={item.discoms} key={ind} > {item.discoms} </option>
                            ) }
                            
                        </select>
                    </Form.Group>
                </div>
                <div className={ this.state.formData.tarifftype ==="commercial" ? "col-md-4" : "col-md-4" }>
                    <Form.Group>
                        <Form.Label> Tariff type</Form.Label>
                            <select className="calc" name="tarifftype" value ={ this.state.formData.tarifftype} onChange={this.handleChange}>
                                <option value = "residential"> Residential</option>
                                <option value = "commercial"> Commercial</option>
                            </select>
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group>
                    {this.state.formData.tarifftype ==="commercial" ?
                        <Fragment>
                            <Form.Label> Sanction load </Form.Label>
                            <input className="calc" name="sanctionload" type="number" value={this.state.formData.sanctionload} onChange={this.handleChange}></input>
                        </Fragment>
                        : null }
                    </Form.Group>
                </div>
            </div>

            <div className="row">
                
                <div className={ this.state.formData.batterybackup === "yes" ? "col-md-3" : "col-md-4"}>
                    <Form.Group>
                        <Form.Label> Monthly Bill</Form.Label>
                        <input className="calc" name="monthlybill" type = 'number' value={this.state.formData.monthlybill} onChange={this.handleChange} placeholder = "Enter your bill"/>
                    </Form.Group>
                </div>
                <div className={ this.state.formData.batterybackup === "yes" ? "col-md-3" : "col-md-4"}>
                    <Form.Group>
                        <Form.Label> Rooftop area (in Sq.ft)</Form.Label>
                        <input className="calc" type = "number" name="rooftoparea" value ={this.state.formData.rooftoparea} onChange = {this.handleChange} placeholder = "Rooftop Area in Sq.ft"/>
                    </Form.Group>
                </div>
                
                <div className={ this.state.formData.batterybackup === "yes" ? "col-md-3" : "col-md-4"}>
                    <Form.Group>
                        <Form.Label> Battery Backup</Form.Label>
                        <select className="calc" value={this.state.formData.batterybackup} name="batterybackup" onChange={this.handleChange}>
                            <option value = "yes"> Yes</option>
                            <option value = "no"> No</option>
                        </select>
                    </Form.Group>
                    
                </div>
                
                { this.state.formData.batterybackup === "yes" ?
                    <div className="col-md-3">
                        <Form.Group>
                            <Fragment>
                                <Form.Label> Battery Capacity</Form.Label>
                                <select className="calc" value ={this.state.formData.batterycapacity} name="batterycapacity" onChange = {this.handleChange}>
                                    <option value ="4"> 4</option>
                                    <option value ="6"> 6</option>
                                    <option value ="8"> 8</option>
                                    <option value ="10"> 10</option>
                                </select>
                            </Fragment>
                        </Form.Group>
                    </div>
                : null}
            </div>

            <div className="row">
                
            </div>

            <div className="row">
                
                <div className="col-md-4">
                    <Form.Group>
                        <Form.Label> Loan Required</Form.Label>
                        <select className="calc" value={this.state.formData.loanrequired} name="loanrequired" onChange = {this.handleChange}>
                            <option value ="yes"> Yes</option>
                            <option value = "no"> No</option>
                        </select>
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group>
                        <Form.Label> Installation Height</Form.Label>
                        <select className="calc" value={this.state.formData.installationheight} name="installationheight" onChange = {this.handleChange}>
                            <option value = "High(2.5m)"> High</option>
                            <option value = "Low(0.3m)"> Low</option>
                        </select>
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group>
                        <Form.Label> Electric Vehicle in Future</Form.Label>
                        <select className="calc" value={this.state.formData.electricvehicle} name="electricvehicle" onChange={this.handleChange}>
                            <option value ="yes"> Yes</option>
                            <option value = "no"> No</option>
                        </select>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" align="right">
                    <Button type = "submit"> Submit</Button>
                </div>
            </div>


        { this.state.show_recommendation ?
            <Fragment>
                <div className="recom-box" >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Energy Requirement (in KW) </b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["EnergyRequirement"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Product Recommendation</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["RecommendedSKU"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>DC Capacity (in kWp)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["RecommendedDCUnit"]}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>AC Capacity (in kW)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["RecommendedACUnit"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Price of Product (in Rs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {
                                        this.state.resultObj["CapitalCost"].split("-").map((item, index) => 
                                            <Fragment key={index}>{convertNumberIntoINR (item)} { index === 0 ? " - " : ""} </Fragment>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Downpayment</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : 30%</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Loan Amount (in Rs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : { convertNumberIntoINR(this.state.resultObj["TermLoan"])}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Loan Tenure (in years)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["TermLoanTenure"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Loan Interest %</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {(this.state.resultObj["TermLoanInterestRate"])}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>EMI (in Rs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {convertNumberIntoINR(this.state.resultObj["monthly_EMI"])}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Amount Saved in 10 years (in Lakhs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {(this.state.resultObj["Savings_10"])}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Amount Saved in 25 years (in Lakhs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > :  {(this.state.resultObj["Savings_25"])}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Payback Period (in years)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["PayBackPeriod"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>Trees Saved in 25 years</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {this.state.resultObj["TreesSaved"]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <b>CO2 saved (in kgs)</b>
                                </div>
                                <div className="col-md-6">
                                    <p className="calc-display" > : {convertNumberIntoINR(this.state.resultObj["CO2"])}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>

                        <div className="col-md-4">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <b>Average Monthly Savings (in Rs)</b>
                                </div>
                                <div className='col-md-6'>
                                    <p className='calc-display'> : {convertNumberIntoINR(this.state.resultObj["AvgMonthlySavings"])}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <b>Monthly Solar Units</b>
                                </div>
                                <div className='col-md-6'>
                                    <p className='calc-display'> : {this.state.resultObj["MonthlySolarUnits"]}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Form.Label>Related Products</Form.Label>

                
                <table className="calc-table" border="1" width="80%">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Structure Height</th>
                            <th>Price Range (in Rs.)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.resultObj.Related_SKUS.map((item, ind) =>
                            <tr key={ind}>
                                <td>{item.RelatedSKU}</td>
                                <td>{item.Height}</td>
                                <td>
                                    {
                                        item.Price.split("-").map((item, index) => 
                                        <Fragment key={index}>{convertNumberIntoINR (item)} { index === 0 ? " - " : ""} </Fragment>
                                    )}
                                </td>
                            </tr>
                        )}   
                    </tbody>
                </table>

                   
            </Fragment>
            : null }
      </Form>
      );
      
    }
  }

  export default UI