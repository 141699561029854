import React, { useState, useEffect} from 'react';
import { getPreviousDate, getDateFormat, getRequest, getStandardDate, postRequest} from '../../../globalhelper/helper';
import {Form, Button, Spinner} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import '../../CI/COD.css';
import { Select } from './Select';
import { SelectMonth } from './SelectMonth';
import { connect } from 'react-redux';
import CostContributionPopup from './CostContributionPopup';
import { CSVLink } from 'react-csv';

const CostContribution = (props) => {

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: "",
        transType: "",
        vendorName: ""
    })

    const [companyName, setCompanyName] = useState([])

    const [companyDetails, setCompanyDetails] = useState([])

    const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

    const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

    const [month, setMonth] = useState("")

    const [year, setYear] = useState("")

    const [loading, setLoading] = useState(false)

    const [tableData, setTableData] = useState([])

    const [dropdownTransType, setDropdownTransType] = useState([])

    const [dropdownVendorName, setDropdownVendorName] = useState([])

    const [dropdownCAM, setDropdownCAM] = useState([])

    const [completeDataFromDB, setCompleteDataFromDB] = useState([])

    const [changed, setChanged] = useState([])

    const [camValue, setCamValue] = useState([])

    const [showSpinner, setShowSpinner] = useState(false)

    const [showPopup, setShowPopup] = useState(false)

    const [items, setItems] = useState({})

    const [LOB, setLOB] = useState([])

    const [tableDataCheck, setTableDataCheck] = useState(["Test"])

    const [selectedCreatedId, setSelectedCreatedId] = useState(null);

    const [selectedCreatedIds, setSelectedCreatedIds] = useState([]);

    const [DD,setDD] = useState([]);

    // To get companies assigned to the user

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    // Function to get dates of a selected month

    useEffect(() => {
        const selectedYear = year
        const selectedMonth = month 

        const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
        const startDate = new Date(selectedYear, monthIndex, 1)
        const endDate = new Date(selectedYear, monthIndex + 1, 0)

        console.log(getStandardDate(startDate), getStandardDate(endDate))

        setFormData(prev => {
            return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
        })
    }, [year, month])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleMonthChange = (e) => {
        setMonth(e.target.value)
    }

    const handleYearChange = (e) => {
        setYear(e.target.value)
    }

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            company: '',
            transType: '',
            vendorName: ''
        })
        setYear("")
        setMonth("")
        setChanged([])
        setDropdownTransType([])
        setDropdownVendorName([])
        setTableData([])
        setCompleteDataFromDB([])
    }

    const getFilteredArray = async (value, key) => {
        console.log(key)
        const dropdown_array = value.map(val => val[key])
        console.log(dropdown_array)
        const filtered_dropdown_array = [...new Set(dropdown_array)]
        console.log(filtered_dropdown_array)
        return filtered_dropdown_array.filter(item => item !== undefined && item !== '')
    }

    const handleFilterChange = (e) => {
        const {name, value} = e.target

        // const data = [...completeDataFromDB]

        setFormData(prevState => {
            return {...prevState, [name]: value}
        })

        // const filteredData = data.filter(item => item.transactiontype === value)

        // setTableData(filteredData)
    }

    useEffect(() => {
        let Array = [];
    
        const data = [...completeDataFromDB];
    
        let transactionType = [formData.transType];
        let vendorName = [formData.vendorName];
    
        if (!transactionType[0]) {
            transactionType = [...dropdownTransType];
        }
    
        if (!vendorName[0]) {
            vendorName = [...dropdownVendorName];
            vendorName.push(undefined);
        }
    
        for (const [i, item] of data.entries()) {
            if (transactionType.includes(item.transactiontype) && vendorName.includes(item.sendername)) {
                Array.push(item);
            }
    
            if (i === data.length - 1) {
                setTableData(Array);
                setTableDataCheck(Array);
            }
        }
    
        // Automatically set the first transaction type if not already set
        if (!formData.transType && dropdownTransType.length > 0) {
            setFormData(prev => ({
                ...prev,
                transType: dropdownTransType[0],
            }));
        }
    
    }, [formData.transType, formData.vendorName, dropdownTransType]);
    

    useEffect(() => {
        if(!tableDataCheck.length){
            toast.error("No data found for the given combination")
        }
    }, [tableDataCheck])

    // const handleTableCAMChange = (item, e) => {
    //     if (!selectedCreatedIds.includes(item.createdid)) {
    //         setSelectedCreatedIds([...selectedCreatedIds, item.createdid]); // Add the new createdid to the array
    //     }
    //     setSelectedCreatedId(item.createdid);
    //     const data = [...tableData];
    //     const completeData = [...completeDataFromDB];
    //     const value = e.target.value;
    //     let newArray;
    
    //     const costMethodVal = camValue.filter(cam => cam.costallocationmethod === value);
    //     console.log(costMethodVal);
    
    //     if (!costMethodVal.length && value !== "Dynamic") {
    //         toast.error("No value found for that method, please select some other method or try again later");
    //     } else if (value === 'Dynamic') {
    //         console.log(item);
    //         setItems(item);
    //         setShowPopup(true);
    //     } else {
    //         const totalCostAllocationValue = costMethodVal.reduce((acc, val) => acc + parseFloat(val.costallocationvalue), 0);
    
    //         newArray = costMethodVal.map((val, ind) => {
    //             const output = {};
    //             let calculatedPercentage = 0;
    
    //             if (totalCostAllocationValue !== 0) {
    //                 calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
    //             }
    
    //             output[`LOB${ind + 1}`] = val.lob;
    //             output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);
    
    //             let amount = 0;
    //             if (totalCostAllocationValue !== 0) {
    //                 amount = (parseFloat(item.ExpAmt) * calculatedPercentage) / 100;
    //             }
    
    //             output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
    
    //             return output;
    //         });
    
    //         const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
    
    //         // Update Table Data
    //         const updatedTableData = data.map(row => {
    //             if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
    //                 const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
    //                     .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
    //                 return { ...filteredObj, ...plainObject, costallocationmethod: e.target.value };
    //             }
    //             return row;  // Return unchanged rows
    //         });
    
    //         setTableData(updatedTableData);
    
    //         // Update Complete Data
    //         const updatedCompleteData = completeData.map(row => {
    //             if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
    //                 const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
    //                     .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
    //                 return { ...filteredObj, ...plainObject, costallocationmethod: e.target.value };
    //             }
    //             return row;  // Return unchanged rows
    //         });
    
    //         setCompleteDataFromDB(updatedCompleteData);
    
    //         const changedIDS = [...changed, item.createdid];
    //         setChanged(changedIDS);
    //     }
    // };


    const handleTableCAMChange = (item) => {
        // Step 1: Filter the `camValue` based on `forprdfrom` and `forprdto` values matching the current row
        const filteredCAM = camValue.filter(cam => 
                                cam.forprdfrom <= item.forprdto && 
                                (cam.forprdto === null || cam.forprdto >= item.forprdfrom || cam.forprdto === '')   
        );
    
        // Step 2: Extract unique cost allocation methods using Set to avoid duplicates
        const uniqueMethods = [...new Set(filteredCAM.map(cam => cam.costallocationmethod))];
    
        // Step 3: If no matches found, show a warning and return
        if (uniqueMethods.length === 0) {
            toast.warning("No matching cost allocation methods found for the selected period");
            return;
        }
    
        // Step 4: Update dropdown options with unique filtered data
        setDropdownCAM(uniqueMethods);
        setDD(filteredCAM)
    };
    
    
    const handleDropdownChange = (item, e) => {
        const value = e.target.value;
        
        // Step 4: Handle what happens when a value is selected from the dropdown
        if (!selectedCreatedIds.includes(item.createdid)) {
            setSelectedCreatedIds([...selectedCreatedIds, item.createdid, item.transactiontype1]); // Add the new createdid to the array
        }
        setSelectedCreatedId(item.createdid);
    
        const costMethodVal = DD.filter(cam => cam.costallocationmethod === value);
        console.log("CM",costMethodVal);
        
    
        if (!costMethodVal.length && value !== "Dynamic") {
            toast.error("No value found for that method, please select some other method or try again later");
        } else if (value === 'Dynamic') {
            console.log(item);
            setItems(item);
            setShowPopup(true);
        } else {
            const totalCostAllocationValue = costMethodVal.reduce((acc, val) => acc + parseFloat(val.costallocationvalue), 0);
    
            const newArray = costMethodVal.map((val, ind) => {
                const output = {};
                let calculatedPercentage = 0;
    
                if (totalCostAllocationValue !== 0) {
                    calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
                }
    
                output[`LOB${ind + 1}`] = val.lob;
                output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);
    
                let amount = 0;
                if (totalCostAllocationValue !== 0) {
                    amount = (parseFloat(item.ExpAmt) * calculatedPercentage) / 100;
                }
    
                output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
    
                return output;
            });
    
            const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
    
            // Update Table Data
            const updatedTableData = tableData.map(row => {
                if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
                    const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
                        .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
                    return { ...filteredObj, ...plainObject, costallocationmethod: value };
                }
                return row;  // Return unchanged rows
            });

            console.log(updatedTableData);
            
    
            setTableData(updatedTableData);
    
            // Update Complete Data
            const updatedCompleteData = completeDataFromDB.map(row => {
                if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
                    const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
                        .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
                    return { ...filteredObj, ...plainObject, costallocationmethod: value };
                }
                return row;  // Return unchanged rows
            });
    
            setCompleteDataFromDB(updatedCompleteData);
    
            const changedIDS = [...changed, item.createdid];
            setChanged(changedIDS);
        }
    };
    
    

    const handleSubmit = () => {
        const {fromDate, toDate, company} = formData

        if(fromDate && toDate && company){
            setLoading(true)
            setTableData([])
            setChanged([])
            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            const request = {
                fromDate,
                toDate, 
                company: companyid[0],
                userID: props.state.login.userData.USR_Name
            }

            Promise.all([
                postRequest(`api/edureka/getCostAllocationAndContribution`, request),
                postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, request),
                postRequest(`api/edureka/getDistinctLOBs`, request)
            ]).then(async ([item, value, val]) => {
                const responseData = JSON.parse(item.res.data)
                console.log(responseData)
                const camData = JSON.parse(value.res.data)
                console.log(camData)

                console.log(JSON.parse(val.res.data))
                const responseLOB = JSON.parse(val.res.data)

                const distinctLOBs = responseLOB.map(item => item.lob)

                if(responseData.length && camData.length){
                    const transTypeList = await getFilteredArray(responseData, "transactiontype1")
                    const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod")
                    const vendorNameList = await getFilteredArray(responseData, "sendername")

                    costAllocationMethodList.push("Dynamic")
                    costAllocationMethodList.push("Equal")
                    
                    const transitionedData = await processTransactions(responseData)
                    
                    console.log(transitionedData)
                    console.log(transTypeList)
                    console.log(costAllocationMethodList)
                    
                    setDropdownTransType(transTypeList)
                    setDropdownVendorName(vendorNameList)
                    setDropdownCAM([... new Set(costAllocationMethodList)])
                    setTableData(transitionedData)
                    setCompleteDataFromDB(transitionedData)
                    setLoading(false)
                    setCamValue(camData)
                    setLOB(distinctLOBs)
                } else {
                    setLoading(false)
                    toast.warning("No data found, please try some other combination")
                }
            }).catch(err => {
                console.log(err)
                toast.error("Oops something went wrong")
            })

        } else {
            toast.error("Please select all the required fields")
        }
    }

    // const handleSubmit = () => {
    //     const {fromDate, toDate, company} = formData;
    
    //     if(fromDate && toDate && company){
    //         setLoading(true);
    //         setTableData([]);
    //         setChanged([]);
    //         const id = companyDetails.filter(item => item.orgname === company);
    
    //         const companyid = id.map(item => item.orgid);
    
    //         const request = {
    //             fromDate,
    //             toDate, 
    //             company: companyid[0],
    //             userID: props.state.login.userData.USR_Name
    //         };
    
    //         // Call first API and get inprdfrom, forprdfrom for the second API
    //         postRequest(`api/edureka/getCostAllocationAndContribution`, request)
    //         .then(async (item) => {
    //             const responseData = JSON.parse(item.res.data);
    //             console.log(responseData);
    
    //             const { forprdto, forprdfrom } = responseData.length ? responseData[0] : {};
    
    //             const secondRequest = {
    //                 ...request,
    //                 fromDate: forprdfrom || fromDate, 
    //                 toDate: forprdto || toDate 
    //             };

    //             console.log("secondRequest", secondRequest);
                
    
    //             return Promise.all([
    //                 postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, secondRequest),
    //                 postRequest(`api/edureka/getDistinctLOBs`, request)
    //             ])
    //             .then(async ([value, val]) => {
    //                 const camData = JSON.parse(value.res.data);
    //                 console.log(camData);
    
    //                 console.log(JSON.parse(val.res.data));
    //                 const responseLOB = JSON.parse(val.res.data);
    
    //                 const distinctLOBs = responseLOB.map(item => item.lob);
    
    //                 if(responseData.length && camData.length){
    //                     const transTypeList = await getFilteredArray(responseData, "transactiontype1");
    //                     const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod");
    //                     const vendorNameList = await getFilteredArray(responseData, "sendername");
    
    //                     costAllocationMethodList.push("Dynamic");
    //                     costAllocationMethodList.push("Equal");
                        
    //                     const transitionedData = await processTransactions(responseData);
                        
    //                     console.log(transitionedData);
    //                     console.log(transTypeList);
    //                     console.log(costAllocationMethodList);
                        
    //                     setDropdownTransType(transTypeList);
    //                     setDropdownVendorName(vendorNameList);
    //                     setDropdownCAM([...new Set(costAllocationMethodList)]);
    //                     setTableData(transitionedData);
    //                     setCompleteDataFromDB(transitionedData);
    //                     setLoading(false);
    //                     setCamValue(camData);
    //                     setLOB(distinctLOBs);
    //                 } else {
    //                     setLoading(false);
    //                     toast.warning("No data found, please try some other combination");
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //                 toast.error("Oops something went wrong");
    //             });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             toast.error("Oops something went wrong");
    //         });
    //     } else {
    //         toast.error("Please select all the required fields");
    //     }
    // };
    

    useEffect(() => {
        let filteredData = [];

        // Check if dropdownTransType and completeDataFromDB are populated
        if (dropdownTransType.length > 0 && completeDataFromDB.length > 0) {
            // Use the selected transaction type from formData
            let transactionType = formData.transType ? [formData.transType] : dropdownTransType;
            
            // Use the selected vendor name from formData, or include all if not set
            let vendorName = formData.vendorName ? [formData.vendorName] : [...dropdownVendorName, undefined];

            // Filter the data based on transaction type and vendor name
            filteredData = completeDataFromDB.filter(item =>
                transactionType.includes(item.transactiontype1) && vendorName.includes(item.sendername)
            );

            // Update table data
            setTableData(filteredData);
            setTableDataCheck(filteredData);
        }

    }, [formData.transType, formData.vendorName, dropdownTransType, completeDataFromDB, dropdownVendorName]);

    async function processTransactions(transactions) {
        return transactions.map(transaction => {
          const output = {};
          const lobOrder = []
      
          if (transaction.LOBAllocList) {
            const LOBParts = transaction.LOBAllocList.split(',');
            
            LOBParts.forEach(part => {
              const [lob, amount] = part.split('(');
              const lobKey = lob.trim();
              let amtValue = parseFloat(amount.replace(/[^\d.-]/g, ''));
                
                const lobIndex = lobOrder.length + 1;
                lobOrder.push(lobKey);

                output[`LOB${lobIndex}`] = lobKey;
                output[`LOB${lobIndex}Amt`] = amtValue
            });
          }

          lobOrder.forEach((lob, index) => {
            output[`LOB${index + 1}`] = output[`LOB${index + 1}`] || lob;
            output[`LOB${index + 1}Amt`] = output[`LOB${index + 1}Amt`] || 0;
          });
      
          return {
            ...transaction,
            ...output
          };
        });
    }

    // const handleUpdate = () => {
    //     const data = [...completeDataFromDB]
    //     const changedData = data.filter(item => changed.includes(item.createdid))
    //     const rowsToUpdate = tableData.filter(row => row.createdid === selectedCreatedId);
    //     console.log('Updating rows: ', rowsToUpdate);

    //     const id = companyDetails.filter(item => item.orgname === formData.company)

    //     const companyid = id.map(item => item.orgid)

    //     const request = {
    //         data: changedData,
    //         transtype: formData.transType,
    //         company: companyid[0],
    //         userID: props.state.login.userData.USR_Name
    //     }
    //     console.log("REQUEST",request);
        

    //     setShowSpinner(true)

    //     postRequest(`api/edureka/updateCAMAllocation`, request).then(response => {
    //         console.log(response.res.data)
    //         toast.success("Records updated successfully, please press submit to know the updated value")
    //         setShowSpinner(false)
    //         setChanged([])
    //         setFormData(prev => {
    //             return {...prev, "transType": "", "vendorName": ""}
    //         })
    //     }).catch(err => {
    //         console.log(err)
    //         toast.error("Oops something went wrong")
    //         setShowSpinner(false)
    //     })
    // }

    const handleUpdate = () => {
        const data = [...completeDataFromDB];
    
        // Ensure that you're filtering by both createdid and transactiontype
        const changedData = data.filter(item => 
            changed.includes(item.createdid) && 
            item.transactiontype1 === formData.transType
        );
    
        // Ensure rows to update are filtered by both createdid and transactiontype
        const rowsToUpdate = tableData.filter(row => 
            row.createdid === selectedCreatedId && 
            row.transactiontype1 === formData.transType
        );
    
        console.log('Updating rows: ', rowsToUpdate);
    
        // Aggregate values for ledgername, accounttype, and ADLineRef
        const ledgernames = new Set();
        const accounttypes = new Set();
        const ADLineRefs = new Set();
    
        rowsToUpdate.forEach(row => {
            if (row.ledgername) ledgernames.add(row.ledgername);
            if (row.accounttype) accounttypes.add(row.accounttype);
            if (row.ADLineRef) ADLineRefs.add(row.ADLineRef);
        });
    
        const aggregatedLedgernames = Array.from(ledgernames).join(', ');
        const aggregatedAccounttypes = Array.from(accounttypes).join(', ');
        const aggregatedADLineRefs = Array.from(ADLineRefs).join(', ');
    
        const id = companyDetails.find(item => item.orgname === formData.company);
        const companyid = id ? id.orgid : "";
    
        const request = {
            data: changedData,
            transtype: formData.transType,
            company: companyid,
            userID: props.state.login.userData.USR_Name,
            ledgernames: aggregatedLedgernames,
            accounttypes: aggregatedAccounttypes,
            ADLineRefs: aggregatedADLineRefs
        };
    
        console.log("REQUEST", request);
    
        setShowSpinner(true);
    
        postRequest(`api/edureka/updateCAMAllocation`, request)
            .then(response => {
                console.log(response.res.data);
                toast.success("Records updated successfully, please press submit to know the updated value");
                setShowSpinner(false);
                setChanged([]);
                setFormData(prev => ({
                    ...prev,
                    transType: "",
                    vendorName: ""
                }));
            })
            .catch(err => {
                console.log(err);
                toast.error("Oops something went wrong");
                setShowSpinner(false);
            });
    };
    
    

  return (
    <>
        <form>
            <div className='row'>
                <div className='col-md-2'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className="col-md-2">
                    <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
                </div>

                <div className="col-md-2">
                    <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
                </div>

                {dropdownTransType.length ?
                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>Transaction</Form.Label>
                            <select className='spacing' value={formData.transType} name='transType' onChange={handleFilterChange}>
                                <option value="" disabled>Select</option>
                                {dropdownTransType.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                        </Form.Group>
                    </div> 
                : null}

                {dropdownVendorName.length ?
                <div className='col-md-2'>
                    <Form.Group>
                        <Form.Label>Vendor/Party Name</Form.Label>
                        <select className='spacing' value={formData.vendorName} name='vendorName' onChange={handleFilterChange}>
                            <option value="" disabled>Select</option>
                            {dropdownVendorName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div> 
                :null}

            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button style={{marginLeft:'10px'}} size='sm' variant='danger' onClick={handleReset}>Reset</Button>
                {tableData.length ?
                        <CSVLink data={tableData} filename='Cost_Allocation_Report.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}

                {selectedCreatedId && changed.length && !showSpinner ?
                <Button style={{marginLeft:'10px'}} size='sm' variant='primary' onClick={handleUpdate}>
                    Update
                </Button> : null}

                {showSpinner ?
                <Button style={{marginLeft:'10px'}} variant="warning" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                <span className="visually-hidden">Loading...</span>
                </Button> : null}
            </div>
        </form>

        <br/>

        {loading ? <div className='center'>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
              </div>: null}

        <ToastContainer/>

        {/* {showPopup ?
        <CostContributionPopup
        lobs={LOB}
        handleClose = {() => {
            setShowPopup(false)

        }} */}
        {showPopup ?
        <CostContributionPopup
        mode="percentage"  //mbp
        // totalValue ={tableData.map((item)=>(item.ExpAmt))}
        
           lobs={LOB}
            handleClose = {() => {
                setShowPopup(false)

            }}

            handleData = {(prop) => {
                    console.log(prop)
                    setShowPopup(false)

                    const data = [...tableData]
                    const completeData = [...completeDataFromDB]

                    let newArray

                    newArray = prop.map((val, ind) => {
                        const output = {}

                        output[`LOB${ind+1}`] = val.lob
                        output[`LOB${ind +1}Percentage`] = val.percentage

                        const amount = parseFloat(items.ExpAmt) * parseFloat(val.percentage) /100

                        output[`LOB${ind +1}Amt`] = amount.toFixed(2)

                        console.log(output)

                        return output
                    })

                    const plainObject = newArray.reduce((acc, obj) => {
                        return {...acc, ...obj}
                    }, {})

                    const objIndex1 = data.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)
                    const objIndex2 = completeData.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)

                    const update1 = [items].map(obj => {
    
                        const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
                        .reduce((acc, key) => {
                            acc[key] = obj[key]
                            return acc
                        }, {})
            
                        const rest = {...filteredObj, ...plainObject}
                        rest["costallocationmethod"] = "Dynamic"
                        return rest
                    })
            
                    const updatedObj1 = {...update1[0]}
            
                    const updatedArray1 = [...data.slice(0, objIndex1), updatedObj1, ...data.slice(objIndex1 + 1)]
            
                    console.log(updatedArray1)
            
                    //[completeData[objIndex2]]
                    const update2 = [items].map(obj => {
            
                        const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
                        .reduce((acc, key) => {
                            acc[key] = obj[key]
                            return acc
                        }, {})
            
                        const rest = {...filteredObj, ...plainObject}
                        rest["costallocationmethod"] = "Dynamic"
                        return rest
                    })
            
                    const updatedObj2 = {...update2[0]}
            
                    const updatedArray2 = [...completeData.slice(0, objIndex2), updatedObj2, ...completeData.slice(objIndex2 +1)]
            
                    setTableData(updatedArray1)
                    setCompleteDataFromDB(updatedArray2)
                    
                    const changedIDS = [...changed]
                    changedIDS.push(items.createdid)
                    console.log(changedIDS)
                    setChanged(changedIDS)
                    console.log(update1)

                }
            } 
        /> : null}

{!loading && tableData.length ? (
    <div>
        <table border="1" id="table-css">
            <thead>
                <tr>
                    <th>Document Number</th>
                    <th>Accounting Journal</th>
                    <th style={{ minWidth: '20px', width:'20px'  }}>Ac Date</th>
                    <th>For Prd From</th>
                    <th>For Prd To</th>
                    <th>Party Name</th>
                    <th>Account Class/Ledger Name</th>
                    <th>ItemID</th>
                    <th>AccountType</th>
                    <th style={{width:'20px'}}>DocumentRef</th>
                    <th style={{width:'210px'}}>Cost Allocation Method</th>
                    <th>Amount in Local Currency</th>
                    <th>LOB1</th>
                    <th>Amt</th>
                    <th>LOB2</th>
                    <th>Amt</th>
                    <th>LOB3</th>
                    <th>Amt</th>
                    <th>LOB4</th>
                    <th>Amt</th>
                    <th>LOB5</th>
                    <th>Amt</th>
                    <th>LOB6</th>
                    <th>Amt</th>
                    <th>LOB7</th>
                    <th>Amt</th>
                    <th>LOB8</th>
                    <th>Amt</th>
                    <th>LOB9</th>
                    <th>Amt</th>
                    <th>LOB10</th>
                    <th>Amt</th>
                </tr>
            </thead>

            <tbody>
                {tableData
                    .sort((a, b) => a.createdid.localeCompare(b.createdid))
                    .map((item, index) => {
                        // Highlight the row if its createdid is in the selectedCreatedIds array
                        const isHighlighted = selectedCreatedIds.includes(item.createdid) && item.transactiontype === formData.transType;
                        return (
                            <tr key={index} className={isHighlighted ? 'highlight' : ''}>
                                <td>{item.createdid}</td>
                                <td>{item.accountingdocnumber}</td>
                                <td style={{ minWidth: '20px', width:'20px'  }}>{getPreviousDate( item.accountingdate)}</td>
                                <td>{item.forprdfrom}</td>
                                <td>{item.forprdto}</td>
                                <td>{item.sendername}</td>
                                <td>{item.ledgername}</td>
                                <td>{item.itemid}</td>
                                <td>{item.accounttype}</td>
                                <td>{item.documentref}</td>
                                <td style={{ minWidth: '250px' }}>
                                    <div>
                                    <select
                                        className="spacing"
                                        value={item.costallocationmethod || ''}
                                        name="costallocationmethod"
                                        onFocus={() => handleTableCAMChange(item)}  // Filter CAM based on forprdfrom and forprdto when dropdown opens
                                        onChange={(e) => handleDropdownChange(item, e)}  // Handle selected value from dropdown
                                    >
                                        <option value="">Select</option>
                                        {dropdownCAM
                                            .sort()
                                            .map((camItem, camIndex) => (
                                                <option key={camIndex} value={camItem}>
                                                    {camItem}
                                                </option>
                                            ))}
                                    </select>   
                                    </div>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {Number(item.ExpAmt ? item.ExpAmt : 0).toFixed(2)}
                                </td>
                                <td>{item.LOB1}</td>
                                <td>{item.LOB1Amt}</td>
                                <td>{item.LOB2}</td>
                                <td>{item.LOB2Amt}</td>
                                <td>{item.LOB3}</td>
                                <td>{item.LOB3Amt}</td>
                                <td>{item.LOB4}</td>
                                <td>{item.LOB4Amt}</td>
                                <td>{item.LOB5}</td>
                                <td>{item.LOB5Amt}</td>
                                <td>{item.LOB6}</td>
                                <td>{item.LOB6Amt}</td>
                                <td>{item.LOB7}</td>
                                <td>{item.LOB7Amt}</td>
                                <td>{item.LOB8}</td>
                                <td>{item.LOB8Amt}</td>
                                <td>{item.LOB9}</td>
                                <td>{item.LOB9Amt}</td>
                                <td>{item.LOB10}</td>
                                <td>{item.LOB10Amt}</td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    </div>
) : null}
    </>
  )
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (CostContribution)












// import React, { useState, useEffect} from 'react';
// import { getPreviousDate, getDateFormat, getRequest, getStandardDate, postRequest} from '../../../globalhelper/helper';
// import {Form, Button, Spinner} from 'react-bootstrap';
// import {toast, ToastContainer} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
// import '../../CI/COD.css';
// import { Select } from './Select';
// import { SelectMonth } from './SelectMonth';
// import { connect } from 'react-redux';
// import CostContributionPopup from './CostContributionPopup';
// import { CSVLink } from 'react-csv';

// const CostContribution = (props) => {

//     const [formData, setFormData] = useState({
//         company: "",
//         fromDate: "",
//         toDate: "",
//         transType: "",
//         vendorName: ""
//     })

//     const [companyName, setCompanyName] = useState([])

//     const [companyDetails, setCompanyDetails] = useState([])

//     const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

//     const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

//     const [month, setMonth] = useState("")

//     const [year, setYear] = useState("")

//     const [loading, setLoading] = useState(false)

//     const [tableData, setTableData] = useState([])

//     const [dropdownTransType, setDropdownTransType] = useState([])

//     const [dropdownVendorName, setDropdownVendorName] = useState([])

//     const [dropdownCAM, setDropdownCAM] = useState([])

//     const [completeDataFromDB, setCompleteDataFromDB] = useState([])

//     const [changed, setChanged] = useState([])

//     const [camValue, setCamValue] = useState([])

//     const [showSpinner, setShowSpinner] = useState(false)

//     const [showPopup, setShowPopup] = useState(false)

//     const [items, setItems] = useState({})

//     const [LOB, setLOB] = useState([])

//     const [tableDataCheck, setTableDataCheck] = useState(["Test"])

//     const [selectedCreatedId, setSelectedCreatedId] = useState(null);

//     const [selectedCreatedIds, setSelectedCreatedIds] = useState([]);

//     // To get companies assigned to the user

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)
//         })
//     }, [])

//     // Function to get dates of a selected month

//     useEffect(() => {
//         const selectedYear = year
//         const selectedMonth = month 

//         const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
//         const startDate = new Date(selectedYear, monthIndex, 1)
//         const endDate = new Date(selectedYear, monthIndex + 1, 0)

//         console.log(getStandardDate(startDate), getStandardDate(endDate))

//         setFormData(prev => {
//             return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
//         })
//     }, [year, month])

//     const handleChange = (e) => {
//         const {name, value} = e.target
//         setFormData((prev) => {
//             return {...prev, [name]: value}
//         })
//     }

//     const handleMonthChange = (e) => {
//         setMonth(e.target.value)
//     }

//     const handleYearChange = (e) => {
//         setYear(e.target.value)
//     }

//     const handleReset = () => {
//         setFormData({
//             fromDate: '',
//             toDate: '',
//             company: '',
//             transType: '',
//             vendorName: ''
//         })
//         setYear("")
//         setMonth("")
//         setChanged([])
//         setDropdownTransType([])
//         setDropdownVendorName([])
//         setTableData([])
//         setCompleteDataFromDB([])
//     }

//     const getFilteredArray = async (value, key) => {
//         console.log(key)
//         const dropdown_array = value.map(val => val[key])
//         console.log(dropdown_array)
//         const filtered_dropdown_array = [...new Set(dropdown_array)]
//         console.log(filtered_dropdown_array)
//         return filtered_dropdown_array.filter(item => item !== undefined && item !== '')
//     }

//     const handleFilterChange = (e) => {
//         const {name, value} = e.target

//         // const data = [...completeDataFromDB]

//         setFormData(prevState => {
//             return {...prevState, [name]: value}
//         })

//         // const filteredData = data.filter(item => item.transactiontype === value)

//         // setTableData(filteredData)
//     }

//     useEffect(() => {
//         let Array = [];
    
//         const data = [...completeDataFromDB];
    
//         let transactionType = [formData.transType];
//         let vendorName = [formData.vendorName];
    
//         if (!transactionType[0]) {
//             transactionType = [...dropdownTransType];
//         }
    
//         if (!vendorName[0]) {
//             vendorName = [...dropdownVendorName];
//             vendorName.push(undefined);
//         }
    
//         for (const [i, item] of data.entries()) {
//             if (transactionType.includes(item.transactiontype) && vendorName.includes(item.sendername)) {
//                 Array.push(item);
//             }
    
//             if (i === data.length - 1) {
//                 setTableData(Array);
//                 setTableDataCheck(Array);
//             }
//         }
    
//         // Automatically set the first transaction type if not already set
//         if (!formData.transType && dropdownTransType.length > 0) {
//             setFormData(prev => ({
//                 ...prev,
//                 transType: dropdownTransType[0],
//             }));
//         }
    
//     }, [formData.transType, formData.vendorName, dropdownTransType]);
    

//     useEffect(() => {
//         if(!tableDataCheck.length){
//             toast.error("No data found for the given combination")
//         }
//     }, [tableDataCheck])

//     const handleTableCAMChange = (item, e) => {
//         if (!selectedCreatedIds.includes(item.createdid)) {
//             setSelectedCreatedIds([...selectedCreatedIds, item.createdid]); // Add the new createdid to the array
//         }
//         setSelectedCreatedId(item.createdid);
//         const data = [...tableData];
//         const completeData = [...completeDataFromDB];
//         const value = e.target.value;
//         let newArray;
    
//         const costMethodVal = camValue.filter(cam => cam.costallocationmethod === value);
//         console.log(costMethodVal);
    
//         if (!costMethodVal.length && value !== "Dynamic") {
//             toast.error("No value found for that method, please select some other method or try again later");
//         } else if (value === 'Dynamic') {
//             console.log(item);
//             setItems(item);
//             setShowPopup(true);
//         } else {
//             const totalCostAllocationValue = costMethodVal.reduce((acc, val) => acc + parseFloat(val.costallocationvalue), 0);
    
//             newArray = costMethodVal.map((val, ind) => {
//                 const output = {};
//                 let calculatedPercentage = 0;
    
//                 if (totalCostAllocationValue !== 0) {
//                     calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
//                 }
    
//                 output[`LOB${ind + 1}`] = val.lob;
//                 output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);
    
//                 let amount = 0;
//                 if (totalCostAllocationValue !== 0) {
//                     amount = (parseFloat(item.ExpAmt) * calculatedPercentage) / 100;
//                 }
    
//                 output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
    
//                 return output;
//             });
    
//             const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
    
//             // Update Table Data
//             const updatedTableData = data.map(row => {
//                 if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
//                     const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
//                     return { ...filteredObj, ...plainObject, costallocationmethod: e.target.value };
//                 }
//                 return row;  // Return unchanged rows
//             });
    
//             setTableData(updatedTableData);
    
//             // Update Complete Data
//             const updatedCompleteData = completeData.map(row => {
//                 if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
//                     const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
//                     return { ...filteredObj, ...plainObject, costallocationmethod: e.target.value };
//                 }
//                 return row;  // Return unchanged rows
//             });
    
//             setCompleteDataFromDB(updatedCompleteData);
    
//             const changedIDS = [...changed, item.createdid];
//             setChanged(changedIDS);
//         }
//     };

//     // const handleSubmit = () => {
//     //     const {fromDate, toDate, company} = formData

//     //     if(fromDate && toDate && company){
//     //         setLoading(true)
//     //         setTableData([])
//     //         setChanged([])
//     //         const id = companyDetails.filter(item => item.orgname === company)

//     //         const companyid = id.map(item => item.orgid)

//     //         const request = {
//     //             fromDate,
//     //             toDate, 
//     //             company: companyid[0],
//     //             userID: props.state.login.userData.USR_Name
//     //         }

//     //         Promise.all([
//     //             postRequest(`api/edureka/getCostAllocationAndContribution`, request),
//     //             postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, request),
//     //             postRequest(`api/edureka/getDistinctLOBs`, request)
//     //         ]).then(async ([item, value, val]) => {
//     //             const responseData = JSON.parse(item.res.data)
//     //             console.log(responseData)
//     //             const camData = JSON.parse(value.res.data)
//     //             console.log(camData)

//     //             console.log(JSON.parse(val.res.data))
//     //             const responseLOB = JSON.parse(val.res.data)

//     //             const distinctLOBs = responseLOB.map(item => item.lob)

//     //             if(responseData.length && camData.length){
//     //                 const transTypeList = await getFilteredArray(responseData, "transactiontype1")
//     //                 const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod")
//     //                 const vendorNameList = await getFilteredArray(responseData, "sendername")

//     //                 costAllocationMethodList.push("Dynamic")
//     //                 costAllocationMethodList.push("Equal")
                    
//     //                 const transitionedData = await processTransactions(responseData)
                    
//     //                 console.log(transitionedData)
//     //                 console.log(transTypeList)
//     //                 console.log(costAllocationMethodList)
                    
//     //                 setDropdownTransType(transTypeList)
//     //                 setDropdownVendorName(vendorNameList)
//     //                 setDropdownCAM([... new Set(costAllocationMethodList)])
//     //                 setTableData(transitionedData)
//     //                 setCompleteDataFromDB(transitionedData)
//     //                 setLoading(false)
//     //                 setCamValue(camData)
//     //                 setLOB(distinctLOBs)
//     //             } else {
//     //                 setLoading(false)
//     //                 toast.warning("No data found, please try some other combination")
//     //             }
//     //         }).catch(err => {
//     //             console.log(err)
//     //             toast.error("Oops something went wrong")
//     //         })

//     //     } else {
//     //         toast.error("Please select all the required fields")
//     //     }
//     // }

//     const handleSubmit = () => {
//         const {fromDate, toDate, company} = formData;
    
//         if(fromDate && toDate && company){
//             setLoading(true);
//             setTableData([]);
//             setChanged([]);
//             const id = companyDetails.filter(item => item.orgname === company);
    
//             const companyid = id.map(item => item.orgid);
    
//             const request = {
//                 fromDate,
//                 toDate, 
//                 company: companyid[0],
//                 userID: props.state.login.userData.USR_Name
//             };
    
//             // Call first API and get inprdfrom, forprdfrom for the second API
//             postRequest(`api/edureka/getCostAllocationAndContribution`, request)
//             .then(async (item) => {
//                 const responseData = JSON.parse(item.res.data);
//                 console.log(responseData);
    
//                 const { forprdto, forprdfrom } = responseData.length ? responseData[0] : {};
    
//                 const secondRequest = {
//                     ...request,
//                     fromDate: forprdfrom || fromDate, 
//                     toDate: forprdto || toDate 
//                 };

//                 console.log("secondRequest", secondRequest);
                
    
//                 return Promise.all([
//                     postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, secondRequest),
//                     postRequest(`api/edureka/getDistinctLOBs`, request)
//                 ])
//                 .then(async ([value, val]) => {
//                     const camData = JSON.parse(value.res.data);
//                     console.log(camData);
    
//                     console.log(JSON.parse(val.res.data));
//                     const responseLOB = JSON.parse(val.res.data);
    
//                     const distinctLOBs = responseLOB.map(item => item.lob);
    
//                     if(responseData.length && camData.length){
//                         const transTypeList = await getFilteredArray(responseData, "transactiontype1");
//                         const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod");
//                         const vendorNameList = await getFilteredArray(responseData, "sendername");
    
//                         costAllocationMethodList.push("Dynamic");
//                         costAllocationMethodList.push("Equal");
                        
//                         const transitionedData = await processTransactions(responseData);
                        
//                         console.log(transitionedData);
//                         console.log(transTypeList);
//                         console.log(costAllocationMethodList);
                        
//                         setDropdownTransType(transTypeList);
//                         setDropdownVendorName(vendorNameList);
//                         setDropdownCAM([...new Set(costAllocationMethodList)]);
//                         setTableData(transitionedData);
//                         setCompleteDataFromDB(transitionedData);
//                         setLoading(false);
//                         setCamValue(camData);
//                         setLOB(distinctLOBs);
//                     } else {
//                         setLoading(false);
//                         toast.warning("No data found, please try some other combination");
//                     }
//                 })
//                 .catch(err => {
//                     console.log(err);
//                     toast.error("Oops something went wrong");
//                 });
//             })
//             .catch(err => {
//                 console.log(err);
//                 toast.error("Oops something went wrong");
//             });
//         } else {
//             toast.error("Please select all the required fields");
//         }
//     };
    

//     useEffect(() => {
//         let filteredData = [];

//         // Check if dropdownTransType and completeDataFromDB are populated
//         if (dropdownTransType.length > 0 && completeDataFromDB.length > 0) {
//             // Use the selected transaction type from formData
//             let transactionType = formData.transType ? [formData.transType] : dropdownTransType;
            
//             // Use the selected vendor name from formData, or include all if not set
//             let vendorName = formData.vendorName ? [formData.vendorName] : [...dropdownVendorName, undefined];

//             // Filter the data based on transaction type and vendor name
//             filteredData = completeDataFromDB.filter(item =>
//                 transactionType.includes(item.transactiontype1) && vendorName.includes(item.sendername)
//             );

//             // Update table data
//             setTableData(filteredData);
//             setTableDataCheck(filteredData);
//         }

//     }, [formData.transType, formData.vendorName, dropdownTransType, completeDataFromDB, dropdownVendorName]);

//     async function processTransactions(transactions) {
//         return transactions.map(transaction => {
//           const output = {};
//           const lobOrder = []
      
//           if (transaction.LOBAllocList) {
//             const LOBParts = transaction.LOBAllocList.split(',');
            
//             LOBParts.forEach(part => {
//               const [lob, amount] = part.split('(');
//               const lobKey = lob.trim();
//               let amtValue = parseFloat(amount.replace(/[^\d.-]/g, ''));
                
//                 const lobIndex = lobOrder.length + 1;
//                 lobOrder.push(lobKey);

//                 output[`LOB${lobIndex}`] = lobKey;
//                 output[`LOB${lobIndex}Amt`] = amtValue
//             });
//           }

//           lobOrder.forEach((lob, index) => {
//             output[`LOB${index + 1}`] = output[`LOB${index + 1}`] || lob;
//             output[`LOB${index + 1}Amt`] = output[`LOB${index + 1}Amt`] || 0;
//           });
      
//           return {
//             ...transaction,
//             ...output
//           };
//         });
//     }

//     // const handleUpdate = () => {
//     //     const data = [...completeDataFromDB]
//     //     const changedData = data.filter(item => changed.includes(item.createdid))
//     //     const rowsToUpdate = tableData.filter(row => row.createdid === selectedCreatedId);
//     //     console.log('Updating rows: ', rowsToUpdate);

//     //     const id = companyDetails.filter(item => item.orgname === formData.company)

//     //     const companyid = id.map(item => item.orgid)

//     //     const request = {
//     //         data: changedData,
//     //         transtype: formData.transType,
//     //         company: companyid[0],
//     //         userID: props.state.login.userData.USR_Name
//     //     }
//     //     console.log("REQUEST",request);
        

//     //     setShowSpinner(true)

//     //     postRequest(`api/edureka/updateCAMAllocation`, request).then(response => {
//     //         console.log(response.res.data)
//     //         toast.success("Records updated successfully, please press submit to know the updated value")
//     //         setShowSpinner(false)
//     //         setChanged([])
//     //         setFormData(prev => {
//     //             return {...prev, "transType": "", "vendorName": ""}
//     //         })
//     //     }).catch(err => {
//     //         console.log(err)
//     //         toast.error("Oops something went wrong")
//     //         setShowSpinner(false)
//     //     })
//     // }

//     const handleUpdate = () => {
//         const data = [...completeDataFromDB];
//         const changedData = data.filter(item => changed.includes(item.createdid));
//         const rowsToUpdate = tableData.filter(row => row.createdid === selectedCreatedId);
//         console.log('Updating rows: ', rowsToUpdate);
    
//         // Aggregate values for ledgername, accounttype, and ADLineRef
//         const ledgernames = new Set();
//         const accounttypes = new Set();
//         const ADLineRefs = new Set();
    
//         rowsToUpdate.forEach(row => {
//             if (row.ledgername) ledgernames.add(row.ledgername);
//             if (row.accounttype) accounttypes.add(row.accounttype);
//             if (row.ADLineRef) ADLineRefs.add(row.ADLineRef);
//         });
    
//         const aggregatedLedgernames = Array.from(ledgernames).join(', ');
//         const aggregatedAccounttypes = Array.from(accounttypes).join(', ');
//         const aggregatedADLineRefs = Array.from(ADLineRefs).join(', ');
    
//         console.log('Updating rows: ', rowsToUpdate);
    
//         const id = companyDetails.find(item => item.orgname === formData.company);
//         const companyid = id ? id.orgid : "";
    
//         const request = {
//             data: changedData,
//             transtype: formData.transType,
//             company: companyid,
//             userID: props.state.login.userData.USR_Name,
//             ledgernames: aggregatedLedgernames,
//             accounttypes: aggregatedAccounttypes,
//             ADLineRefs: aggregatedADLineRefs
//         };
    
//         console.log("REQUEST", request);
    
//         setShowSpinner(true);
    
//         postRequest(`api/edureka/updateCAMAllocation`, request)
//             .then(response => {
//                 console.log(response.res.data);
//                 toast.success("Records updated successfully, please press submit to know the updated value");
//                 setShowSpinner(false);
//                 setChanged([]);
//                 setFormData(prev => ({
//                     ...prev,
//                     transType: "",
//                     vendorName: ""
//                 }));
//             })
//             .catch(err => {
//                 console.log(err);
//                 toast.error("Oops something went wrong");
//                 setShowSpinner(false);
//             });
//     };
    

//   return (
//     <>
//         <form>
//             <div className='row'>
//                 <div className='col-md-2'>
//                     <Form.Group>
//                         <Form.Label>Company</Form.Label>
//                         <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
//                             <option value='' disabled>Select</option>
//                             {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div>

//                 <div className="col-md-2">
//                     <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
//                 </div>

//                 <div className="col-md-2">
//                     <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
//                 </div>

//                 {dropdownTransType.length ?
//                     <div className='col-md-2'>
//                         <Form.Group>
//                             <Form.Label>Transaction</Form.Label>
//                             <select className='spacing' value={formData.transType} name='transType' onChange={handleFilterChange}>
//                                 <option value="" disabled>Select</option>
//                                 {dropdownTransType.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                             </select>
//                         </Form.Group>
//                     </div> 
//                 : null}

//                 {dropdownVendorName.length ?
//                 <div className='col-md-2'>
//                     <Form.Group>
//                         <Form.Label>Vendor/Party Name</Form.Label>
//                         <select className='spacing' value={formData.vendorName} name='vendorName' onChange={handleFilterChange}>
//                             <option value="" disabled>Select</option>
//                             {dropdownVendorName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div> 
//                 :null}

//             </div>

//             <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
//                 <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
//                 <Button style={{marginLeft:'10px'}} size='sm' variant='danger' onClick={handleReset}>Reset</Button>
//                 {tableData.length ?
//                         <CSVLink data={tableData} filename='Cost_Allocation_Report.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}

//                 {selectedCreatedId && changed.length && !showSpinner ?
//                 <Button style={{marginLeft:'10px'}} size='sm' variant='primary' onClick={handleUpdate}>
//                     Update
//                 </Button> : null}

//                 {showSpinner ?
//                 <Button style={{marginLeft:'10px'}} variant="warning" disabled>
//                     <Spinner
//                         as="span"
//                         animation="border"
//                         size="sm"
//                         role="status"
//                         aria-hidden="true"
//                     />
//                 <span className="visually-hidden">Loading...</span>
//                 </Button> : null}
//             </div>
//         </form>

//         <br/>

//         {loading ? <div className='center'>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//               </div>: null}

//         <ToastContainer/>

//         {/* {showPopup ?
//         <CostContributionPopup
//         lobs={LOB}
//         handleClose = {() => {
//             setShowPopup(false)

//         }} */}
//         {showPopup ?
//         <CostContributionPopup
//         mode="percentage"  //mbp
//         // totalValue ={tableData.map((item)=>(item.ExpAmt))}
        
//            lobs={LOB}
//             handleClose = {() => {
//                 setShowPopup(false)

//             }}

//             handleData = {(prop) => {
//                     console.log(prop)
//                     setShowPopup(false)

//                     const data = [...tableData]
//                     const completeData = [...completeDataFromDB]

//                     let newArray

//                     newArray = prop.map((val, ind) => {
//                         const output = {}

//                         output[`LOB${ind+1}`] = val.lob
//                         output[`LOB${ind +1}Percentage`] = val.percentage

//                         const amount = parseFloat(items.ExpAmt) * parseFloat(val.percentage) /100

//                         output[`LOB${ind +1}Amt`] = amount.toFixed(2)

//                         console.log(output)

//                         return output
//                     })

//                     const plainObject = newArray.reduce((acc, obj) => {
//                         return {...acc, ...obj}
//                     }, {})

//                     const objIndex1 = data.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)
//                     const objIndex2 = completeData.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)

//                     const update1 = [items].map(obj => {
    
//                         const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => {
//                             acc[key] = obj[key]
//                             return acc
//                         }, {})
            
//                         const rest = {...filteredObj, ...plainObject}
//                         rest["costallocationmethod"] = "Dynamic"
//                         return rest
//                     })
            
//                     const updatedObj1 = {...update1[0]}
            
//                     const updatedArray1 = [...data.slice(0, objIndex1), updatedObj1, ...data.slice(objIndex1 + 1)]
            
//                     console.log(updatedArray1)
            
//                     //[completeData[objIndex2]]
//                     const update2 = [items].map(obj => {
            
//                         const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => {
//                             acc[key] = obj[key]
//                             return acc
//                         }, {})
            
//                         const rest = {...filteredObj, ...plainObject}
//                         rest["costallocationmethod"] = "Dynamic"
//                         return rest
//                     })
            
//                     const updatedObj2 = {...update2[0]}
            
//                     const updatedArray2 = [...completeData.slice(0, objIndex2), updatedObj2, ...completeData.slice(objIndex2 +1)]
            
//                     setTableData(updatedArray1)
//                     setCompleteDataFromDB(updatedArray2)
                    
//                     const changedIDS = [...changed]
//                     changedIDS.push(items.createdid)
//                     console.log(changedIDS)
//                     setChanged(changedIDS)
//                     console.log(update1)

//                 }
//             } 
//         /> : null}

// {!loading && tableData.length ? (
//     <div>
//         <table border="1" id="table-css">
//             <thead>
//                 <tr>
//                     <th>Document Number</th>
//                     <th>Accounting Journal</th>
//                     <th>Accounting Date</th>
//                     <th>For Prd From</th>
//                     <th>For Prd To</th>
//                     <th>Party Name</th>
//                     <th>Account Class/Ledger Name</th>
//                     <th>ItemID</th>
//                     <th>AccountType</th>
//                     <th>DocumentRef</th>
//                     <th>Cost Allocation Method</th>
//                     <th>Amount in Local Currency</th>
//                     <th>LOB1</th>
//                     <th>Amt</th>
//                     <th>LOB2</th>
//                     <th>Amt</th>
//                     <th>LOB3</th>
//                     <th>Amt</th>
//                     <th>LOB4</th>
//                     <th>Amt</th>
//                     <th>LOB5</th>
//                     <th>Amt</th>
//                     <th>LOB6</th>
//                     <th>Amt</th>
//                     <th>LOB7</th>
//                     <th>Amt</th>
//                     <th>LOB8</th>
//                     <th>Amt</th>
//                     <th>LOB9</th>
//                     <th>Amt</th>
//                     <th>LOB10</th>
//                     <th>Amt</th>
//                 </tr>
//             </thead>

//             <tbody>
//                 {tableData
//                     .sort((a, b) => a.createdid.localeCompare(b.createdid))
//                     .map((item, index) => {
//                         // Highlight the row if its createdid is in the selectedCreatedIds array
//                         const isHighlighted = selectedCreatedIds.includes(item.createdid);
//                         return (
//                             <tr key={index} className={isHighlighted ? 'highlight' : ''}>
//                                 <td>{item.createdid}</td>
//                                 <td>{item.accountingdocnumber}</td>
//                                 <td>{getPreviousDate( item.accountingdate)}</td>
//                                 <td>{item.forprdfrom}</td>
//                                 <td>{item.forprdto}</td>
//                                 <td>{item.sendername}</td>
//                                 <td>{item.ledgername}</td>
//                                 <td>{item.itemid}</td>
//                                 <td>{item.accounttype}</td>
//                                 <td>{item.documentref}</td>
//                                 <td style={{ minWidth: '100px' }}>
//                                     <div>
//                                         <select
//                                             className="spacing"
//                                             value={item.costallocationmethod || ''}
//                                             name="costallocationmethod"
//                                             onChange={(e) => handleTableCAMChange(item, e)}
//                                         >
//                                             <option value="">Select</option>
//                                             {dropdownCAM
//                                                 .sort()
//                                                 .map((camItem, camIndex) => (
//                                                     <option key={camIndex} value={camItem}>
//                                                         {camItem}
//                                                     </option>
//                                                 ))}
//                                         </select>
//                                     </div>
//                                 </td>
//                                 <td style={{ textAlign: 'right' }}>
//                                     {Number(item.ExpAmt ? item.ExpAmt : 0).toFixed(2)}
//                                 </td>
//                                 <td>{item.LOB1}</td>
//                                 <td>{item.LOB1Amt}</td>
//                                 <td>{item.LOB2}</td>
//                                 <td>{item.LOB2Amt}</td>
//                                 <td>{item.LOB3}</td>
//                                 <td>{item.LOB3Amt}</td>
//                                 <td>{item.LOB4}</td>
//                                 <td>{item.LOB4Amt}</td>
//                                 <td>{item.LOB5}</td>
//                                 <td>{item.LOB5Amt}</td>
//                                 <td>{item.LOB6}</td>
//                                 <td>{item.LOB6Amt}</td>
//                                 <td>{item.LOB7}</td>
//                                 <td>{item.LOB7Amt}</td>
//                                 <td>{item.LOB8}</td>
//                                 <td>{item.LOB8Amt}</td>
//                                 <td>{item.LOB9}</td>
//                                 <td>{item.LOB9Amt}</td>
//                                 <td>{item.LOB10}</td>
//                                 <td>{item.LOB10Amt}</td>
//                             </tr>
//                         );
//                     })}
//             </tbody>
//         </table>
//     </div>
// ) : null}
//     </>
//   )
// }

// function mapStateToProps(state){
//     return {
//         state
//     }
// }

// export default connect(mapStateToProps) (CostContribution)
