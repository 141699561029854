
export default function VR2_Reducer(state = [], action) {
    switch (action.type) {
        case 'requestTreeData':
            return state
        case 'changeLogs':
            return {
                ...state,
                changeLogs: action.Logs
            }
        case 'storeVals':
            return {
                ...state,
                storeVals: action.vals
            }
        case 'athenaStorage':
            return {
                ...state,
                athenaStorage: action.data
            }
        case 'one_side_PDF_Key':
            return {
                ...state,
                PDF_Key: action.PDF_Key
            }
        case 'changeTreeData':
            return {
                ...state,
                VR2_Form_Json: action.treeData
            }
        case 'dependency_json':
            return {
                ...state,
                dependency_json: action.json
            }
        case 'dynamicOption_Done':
            return {
                ...state,
                dynamicOption_Done: action.Arr
            }
        case 'ORG_ID_CONST':
            return {
                ...state,
                ORG_ID_CONST: action.ID
            }
        default:
            return state
    }
}
// export const getTreeDatas = state => state.treeData;


