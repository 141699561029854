import React, { useState, useEffect, Fragment } from 'react';
import { getRequest } from '../../globalhelper/helper';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';

const NewFunctionalForFrance = () => {
    const [formData, setFormData] = useState({
        fromDate: "",
        toDate: "",
        theme: ""
    });
    const [apiResponse, setApiResponse] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredColumns, setFilteredColumns] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [completeDataFromDB, setCompleteDataFromDB] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (completeDataFromDB.length > 0) {
            const columns = Object.keys(completeDataFromDB[0]);
            setFilteredColumns(columns);
        }
    }, [completeDataFromDB]);

    const handleColumnChange = (e) => {
        setSelectedValue("")
        setSelectedColumn(e.target.value);
    };

    const handleValueChange = (e) => {
        setSelectedValue(e.target.value);
    };

    const handleSubmit = () => {
        const { fromDate, toDate, theme } = formData;

        if (fromDate && toDate && theme) {
            setLoading(true);
            setApiResponse([]);
            setSubmitted(true);
            getRequest(`api/edureka/franceFE?fromDate=${fromDate}&toDate=${toDate}&theme=${theme}`)
                .then(response => {
                    const responseData = JSON.parse(response.res.data.processed_data);
                    setLoading(false);
                    setApiResponse(responseData);
                    setCompleteDataFromDB(responseData);
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                    toast.error("Oops something went wrong");
                });
        } else {
            toast.error("Please select all the required fields");
        }
    };

    const renderTableCell = (value) => {
        const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);
        return <td style={{ textAlign: isNumeric ? 'right' : 'left' }}>{value}</td>;
    };

    const columnNames = Object.keys(apiResponse[0] || {});
    const filteredValues = selectedColumn ? completeDataFromDB.map(row => row[selectedColumn]) : [];
    const uniqueValues = [...new Set(filteredValues)];

    const filteredData = selectedValue
        ? apiResponse.filter(row => {
            const columnValue = row[selectedColumn];
            if (!isNaN(columnValue)) {
                return parseFloat(columnValue) === parseFloat(selectedValue);
            } else {
                return columnValue === selectedValue;
            }
        })
        : apiResponse;

    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>From Date</Form.Label>
                        <input type='date' className='spacing' name='fromDate' value={formData.fromDate} onChange={handleChange} />
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>To Date</Form.Label>
                        <input type='date' className='spacing' name='toDate' value={formData.toDate} onChange={handleChange} />
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Theme</Form.Label>
                        <select className='spacing' name='theme' value={formData.theme} onChange={handleChange}>
                            <option value="" disabled>Select</option>
                            <option value="ERP-Supplier-TransporterReconciliation">ERP-Supplier-Transporter-Reconciliation</option>
                            <option value="ERP-SupplierReconciliation">ERP-Supplier-Reconciliation</option>
                            <option value="Bill">Bill Query Result</option>
                            <option value="TransporterBill">Transporter-Bill</option>
                            <option value="SupplierBill">Supplier-Bill</option>
                        </select>
                    </Form.Group>
                </div>
                {submitted && formData.theme &&
                    <div className='col-md-3'>
                        <Form.Group>
                            <Form.Label>Column</Form.Label>
                            <select className='spacing' name='column' value={selectedColumn} onChange={handleColumnChange}>
                                <option value="" disabled>Select</option>
                                {filteredColumns.map((column, index) => <option key={index} value={column}>{column}</option>)}
                            </select>
                        </Form.Group>
                    </div>
                }
                {selectedColumn &&
                    <div className='col-md-3'>
                        <Form.Group>
                            <Form.Label>Value</Form.Label>
                            <select className='spacing' name='value' value={selectedValue} onChange={handleValueChange}>
                                <option value="" disabled>Select</option>
                                {uniqueValues.map((value, index) => <option key={index} value={value}>{value}</option>)}
                            </select>
                        </Form.Group>
                    </div>
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Button size='sm' onClick={handleSubmit} variant='success'>Submit</Button>
                {apiResponse.length ?
                    <CSVLink data={apiResponse} filename='Reports.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}
            </div>
            {loading ? (
                <div className='center'>
                    {[...Array(10)].map((_, idx) => (
                        <div key={idx} className="wave"></div>
                    ))}
                </div>
            ) : null}
            {!loading && filteredData.length ?
                <div className='scroll-table'>
                    <table border='1' id='table-css'>
                        <thead>
                            <tr>
                                {columnNames.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columnNames.map((column, columnIndex) => (
                                        renderTableCell(row[column] || "")
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : null}
            <ToastContainer />
        </Fragment>
    );
};

export default NewFunctionalForFrance;
