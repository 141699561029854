import React, { Component } from 'react';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class AMReport extends Component {
  render() {
    return (
      <VR2_Forms
      screenName = 'AM_Screen'
      screenAction = 'create'
      />
    )
  }
}

export default AMReport