import React, {useState, useEffect, Fragment} from 'react';
import {Form, Button} from 'react-bootstrap';
import '../CI/COD.css';
import { getRequest, getStandardDate } from '../../globalhelper/helper';
import NewDynamicTable from './NewDynamicTable';
import {CSVLink} from 'react-csv';
import {connect} from 'react-redux';

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const SelectType = ({label, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    <option value="Summary">Summary</option>
                    <option value="Product">Product</option>
                    <option value='Customer'>Customer</option>
                    <option value='Region'>Region/Location</option>
                    <option value='Revenue Recognition Schedule'>Revenue Recognition Schedule</option>
                    <option value='Receipts'>Receipts</option>
                    <option value='ReceiptsReversal'>ReceiptsReversal</option>
                    <option value='RevenueAdjustment'>Revenue Adjustment</option>
                    <option value='RevenueAdjustmentReversed'>RevenueAdjustmentReversed</option>
                    <option value='RevenueTransferredtoIRA'>Revenue Transferred to IRA</option>
                    <option value='UnbilledSales'>UnbilledSales</option>
                </select>
            </Form.Group>
        </div>
    )
}

const SalesReport = (props) => {

    const [tableData, setTableData] = useState([])
    const [companyName, setCompanyName] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: "",
        type: ""
    }) 

    useEffect(() => {
        getRequest(`api/edureka/getUserDetailsOfLastLogin?user=${props.state.login.userData.USR_Email}&screen=${localStorage.getItem("pageName")}`).then(response => {
            if(response.res.data.length){
                const data = response.res.data[0]
                setFormData({
                    company: data.companyName,
                    fromDate: data.fromDate,
                    toDate: data.toDate,
                    type: data.type
                })
            }
        })
    }, [])

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleReset = () => {
        setFormData({
            company: "",
            fromDate: "",
            toDate: "",
            type: ""
        })
        setTableData([])
        setLoading(false)
        setShowError(false)
    }

    const handleSubmit = () => {
        const {company, fromDate, toDate, type} = formData

        if(company && fromDate && toDate && type){
            setLoading(true)
            setShowError(false)
            setTableData([])

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            const getData = {
                companyName: company,
                companyID: companyid[0],
                screen: localStorage.getItem("pageName"),
                fromDate: fromDate,
                toDate: toDate,
                type: type,
                mailID: props.state.login.userData.USR_Email
            }

            Promise.all([
                getRequest(`api/edureka/getSalesReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}&type=${type}`),
                getRequest(`api/edureka/setUserDetailsOfSalesReport?companyName=${getData.companyName}&companyID=${getData.companyID}&fromDate=${getData.fromDate}&toDate=${getData.toDate}&screen=${getData.screen}&mailID=${getData.mailID}&type=${getData.type}`)
            ]).then(([response, value]) => {
                console.log(value.res.data)
                const responseData = JSON.parse(response.res.data)
                if(responseData.length){
                    setTableData(responseData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setShowError(true)
                    setErrorMessage("No data found, please try some other combination")
                } 
            }).catch(err => {
                console.log(err)
                setLoading(false)
                setShowError(true)
                setErrorMessage("Oops something went wrong!!")
            })
        } else {
            setShowError(true)
            setErrorMessage("Please select all the required fields")
        }
    }

  return (
    <Fragment>
        <div className='row'>
            <div className='col-md-2'>
                <Form.Group>
                    <Form.Label>Company</Form.Label>
                    <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                        <option value='' disabled>Select</option>
                        {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-2'>
                <Input type="date" label="For Period From" name="fromDate" value={formData.fromDate} onChange={handleChange} />
            </div>

            <div className='col-md-2'>
                <Input type="date" label="For Period To" name="toDate" value={formData.toDate} onChange={handleChange} />
            </div>

            <div className="col-md-2">
                <SelectType label="Type" name="type" value={formData.type} onChange={handleChange} />
            </div>

        </div>

        <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
            <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
            <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
            {tableData.length ?
            <CSVLink size='sm' className='btn btn-warning' data={tableData} filename='Sales_Report.csv' style={{marginLeft:'10px'}}>Download</CSVLink> : null}
        </div>

        <br/>

        {tableData.length ?
        <NewDynamicTable data={tableData}></NewDynamicTable> : null}

        {showError &&
         <div className="center-screen">
            {errorMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
        </div>: null}
    </Fragment>
  )
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps)
 (SalesReport)
