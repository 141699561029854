import React, { Component } from 'react';
// import background from '../../assets/Audire_Login.svg';
import background_ from '../../assets/DataTwin First Page-1.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './confirmation.css';
import ApiConstants from "../../config";
import '../../App.css';
import {Animated} from "react-animated-css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import axios from 'axios'; 

class Confirmation extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            alert : "",
            message : "",
            redirect : null
        };
        // this.routeParam = this.props.location;
        var d = window.location.pathname.split('/');
        var a = d[2].split('=');
        this.token = a[1];
    }
    
    componentDidMount = () => {
        // axios.defaults.headers.common['Authorization'] = this.token;
        var formData = { tenant_id : localStorage.getItem("tempTenant"), organisation : localStorage.getItem("tempOrg")}
        console.log(this.token)
        axios.post(ApiConstants.externals.serverUrl + 'api/user/createUser', formData,{
            headers: { authorization: "Bearer " + this.token }, 
        })
        .then(response => {
            console.log(response);
            if (response.data.status === "error") {
                this.setState({message : <h3> Account Not Verified.</h3>});
                this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{ response.data.message._message ? response.data.message._message : response.data.message }</strong></div> </Animated> });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
                // return ()
              } else {
                this.setState({message : <h3> Account Confirmed.Logging in..</h3>});
                this.setState({alert : <div className="alert alert-success"><strong>{response.data.message}</strong></div> });
                setTimeout(() => { this.setState({alert : null, redirect: "/"}); }, 3000);
              }
        })
      }


    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div className="row">
                <div className="col-md-6 .login-sub1">
                    <img src={background_} alt="bg" className="bg"/>
                </div>
                <div className="col-md-6 flex-screen" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '98vh'}}>
                   {this.state.message}
                </div>
                {this.state.alert}
            </div>
        );
    }
}

export default Confirmation;
