export default function tree_reducer ( state = [], action) {
    switch ( action.type ) {
        case 'updateTreeDataNow':
            return {
                ...state,
                tree_Data : action.treeData
            }

        case 'currentNodeData' :
            return {
                ...state,
                treeNodeDisplay : true,
                current_NodeData : action.current_NodeData,
                shalliAddChild : false,
                tempNewChild : {title: "", technicalName: "", val: ""},
            }
            
        case 'flushCurrentNode' :
            return {
                ...state,
                treeNodeDisplay : false ,
                current_NodeData : null,
                shalliAddChild : false,
                tempNewChild : {title: "", technicalName: "", val: ""},
            }

        case 'flushTempNewChild' :
            return {
                ...state,
                shalliAddChild : false,
                tempNewChild : {title: "", technicalName: "", val: ""},
            }
            
        case 'shalliAddChild' :
            return {
                ...state,
                shalliAddChild : action.shalliAddChild,
            }

        case 'tempNewChild' :
            return {
                ...state,
                tempNewChild : {
                    ...state.tempNewChild,
                    [action.tempNewChild_key] : action.tempNewChild_val
                }

            }

        case 'copySetOfNode' :
            return {
                ...state,
                setOfCopiedNode : action.setOfCopiedNode,
            }
                

        default : 
            return state
    }
}

export const getTree_Datas = state => state.tree_Data;