import React, {useState, useEffect, Fragment} from 'react'
import {Form, Button} from 'react-bootstrap';
import '../CI/COD.css';
import { getRequest, getStandardDate, postRequest } from '../../globalhelper/helper';
import NewDynamicTable from './NewDynamicTable';
import {CSVLink} from 'react-csv';
import {connect} from 'react-redux'

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const Select = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const SelectMonth = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const DeferredRevenueAndAmortization = (props) => {

    const [tableData, setTableData] = useState([])
    const [companyName, setCompanyName] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [month, setMonth] = useState("")

    const [year, setYear] = useState("")

    const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

    const [dropdown_year, setDropdown_year] = useState([new Date().getFullYear() - 1, new Date().getFullYear()])

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: ""
    }) 

    useEffect(() => {
        getRequest(`api/edureka/getUserDetailsOfLastLogin?user=${props.state.login.userData.USR_Email}&screen=${localStorage.getItem("pageName")}`).then(response => {
            console.log(response)
            if(response.res.data.length){
                const data = response.res.data[0]
                setFormData({
                    company: data.companyName
                })
                setMonth(data.month)
                setYear(data.year)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    useEffect(() => {
        if(year !== "" && month !== "") {
            const selectedYear = year
            const selectedMonth = month 
    
            const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
            const startDate = new Date(selectedYear, monthIndex, 1)
            const endDate = new Date(selectedYear, monthIndex + 1, 0)
    
            setFormData(prev => {
                return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
            })
        }
    }, [year, month])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleMonthChange = (e) => {
        setMonth(e.target.value)
    }

    const handleYearChange = (e) => {
        setYear(e.target.value)
    }

    const handleReset = () => {
        setFormData({
            fromDate: '', toDate: '', company: ''
        })
        setMonth("")
        setYear("")
        setTableData([])
        setLoading(false)
        setShowError(false)
    }

    const handleSubmit = () => {
        const {fromDate, toDate, company} = formData

        if(fromDate && toDate && company){
            setLoading(true)
            setShowError(false)
            setTableData([])

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            const postData = {
                companyName: company,
                companyID: companyid[0],
                month: month,
                year: year,
                screen: localStorage.getItem("pageName"),
                mailID: props.state.login.userData.USR_Email
            }

            console.log(postData)

            Promise.all([
                getRequest(`api/edureka/getDeferredRevenueAndAmortization?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`),
                getRequest(`api/edureka/setUserDetailsOfDeferredRevenueAndAmortization?companyName=${postData.companyName}&companyID=${postData.companyID}&month=${postData.month}&year=${postData.year}&screen=${postData.screen}&mailID=${postData.mailID}`)
            ]).then(([response, value]) => {
                console.log(value.res.data)
                const responseData = JSON.parse(response.res.data)
                if(responseData.length){
                    setTableData(responseData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setShowError(true)
                    setErrorMessage("No data found, please try some other combination")
                }  
            }).catch(err => {
                console.log(err)
                setLoading(false)
                setShowError(true)
                setErrorMessage("Oops something went wrong!!")
            })

            // getRequest(`api/edureka/getDeferredRevenueAndAmortization?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`).then(response => {
            //     const responseData = JSON.parse(response.res.data)
            //     if(responseData.length){
            //         setTableData(responseData)
            //         setLoading(false)
            //     } else {
            //         setLoading(false)
            //         setShowError(true)
            //         setErrorMessage("No data found, please try some other combination")
            //     }   
            // }).catch(err => {
            //     setLoading(false)
            //     setShowError(true)
            //     setErrorMessage("Oops something went wrong!!")
            // })
        } else {
            setShowError(true)
            setErrorMessage("Please select all the required fields")
        }
    }

  return (
    <Fragment>

            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className="col-md-2">
                    <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
                </div>

                <div className="col-md-2">
                    <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
                </div>

                {/* <div className='col-md-3'>
                    <Input type="date" label="For Period From" name="fromDate" value={formData.fromDate} onChange={handleChange} />
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="For Period To" name="toDate" value={formData.toDate} onChange={handleChange} />
                </div> */}
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                {tableData.length ?
                <CSVLink size='sm' className='btn btn-warning' data={tableData} filename='Deferred_Revenue&Amortization_Report.csv' style={{marginLeft:'10px'}}>Download</CSVLink> : null}
            </div>


        <br/>

        {tableData.length ?
        <NewDynamicTable data={tableData}></NewDynamicTable> : null}

        {showError &&
         <div className="center-screen">
            {errorMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
        </div>: null}

    </Fragment>
  )
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps)
 (DeferredRevenueAndAmortization)