import React, { Component } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper'
import axios from 'axios'; 

import ApiConstants from "../../config";
import {Animated} from "react-animated-css";

import CountdownTimer from "../../components/CountdownTimer"

import { FcApproval } from 'react-icons/fc';
import { FaBan } from 'react-icons/fa';


class Edureka_OTP_Approval extends Component {


    constructor(props) {
        super(props);
        this.state = {
            alert : "",
            timeOut : false,
            ask_otp: false,
            UUID: null,
            input_json: null,
            Activity : ""
        };
        var d = window.location.pathname.split('/');
        var a = d[2].split('=');
        this.token = a[1];
    }
    
    componentDidMount = () => {
        console.log(this.token)
        
        axios.post(ApiConstants.externals.serverUrl + 'api/edureka/Edureka_OTP_Approval_1', 'data', {
            headers: { authorization: "Bearer " + this.token }, 
        })
        .then(res => {
            console.log(res);
            if(res.data.status === "success"){
                let DATA = res.data.data
                // console.log(DATA);
                this.setState({
                    ask_otp: true,
                    UUID: DATA.UUID,
                    input_json: DATA.input_json,
                    alert: <div className="alert alert-success"><strong>{res.data.message}</strong></div>
                });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
            }
            else {
                this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{ res.data.message._message ? res.data.message._message : res.data.message }</strong></div> </Animated> });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
            } 
        })
      }


      submitOTP = (e) => {

        e.preventDefault();

        let data = {
            USER_OTP : this.state.user_otp,
            UUID : this.state.UUID
        }

        axios.post(ApiConstants.externals.serverUrl + 'api/edureka/Edureka_OTP_Approval_2', data)
        .then(res => {
            console.log(res);
            if(res.data.status === "success"){
                let Activity = res.data.data
                console.log(Activity);
                this.setState({
                    ask_otp: false,
                    Activity : Activity,
                    alert: <div className={Activity === "Rejected" ? "alert alert-danger" : "alert alert-success" }><strong>{res.data.message}</strong></div>
                });
                axios.post(ApiConstants.externals.serverUrl + `api/edureka/initiatorEmail`, 'data', { headers : {
                    authorization: "Bearer " + this.token,
                    status: Activity
                } }).then(res => {
                    console.log("Success")
                }).catch(err => console.log(err))
                setTimeout(() => { this.setState({alert : null}); }, 3000);
            }
            else {
                this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{ res.data.message._message ? res.data.message._message : res.data.message }</strong></div> </Animated> });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
            } 
        })

      }




    render (){       
        
        return (
            <div className="row mt-5" align="center">
                {this.state.alert}
                
                { this.state.ask_otp ? 
                    <div className='col-md-9'>
                        <div>Please enter the OTP sent to your Email ID 
                            <span> "{this.state.input_json.EmailID}" </span>
                             to confirm your action. </div><br />
                        <label htmlFor="otp" className='mr-2'>Enter OTP :</label>
                        <input id="otp" name='otp' type="number" pattern="[0-9]{6}" value={this.state.user_otp} className='mr-2' onChange={(e)=>{
                            let val = e.target.value

                            if(val.match(/^\d{0,6}$/)) this.setState({user_otp : val})} 
                        } 
                        />

                        <CountdownTimer
                            timeOut = {
                                (status) => this.setState({
                                    timeOut: status
                                })
                            }
                        />

                        <br />
                        <br />

                        <button className='btn btn-info' disabled={this.state.timeOut} onClick={this.submitOTP}>Submit</button>
                    </div>
                
                : null
                }    

                { this.state.Activity === "Approved" ?

                    <div className='col-md-9' >
                        <span style={{fontSize: '4rem'}}>
                            <FcApproval />
                        </span>
                        <div style={{fontWeight: 'bold'}}>Successfully {this.state.Activity}</div>
                    </div>

                :

                this.state.Activity === "Rejected" ?

                    <div className='col-md-9'>
                        <span style={{fontSize: '3.5rem', color : 'red'}}>
                            <FaBan />
                        </span>
                        <div style={{fontWeight: 'bold'}}>{this.state.Activity}</div>
                    </div>

                : 
                null
                }
            </div>
        );
    }
}

export default Edureka_OTP_Approval;
