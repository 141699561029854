import React, {Fragment,Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal'
import {getRequest} from '../../globalhelper/helper';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import {Chart, ArcElement, registerables} from 'chart.js'
import { CSVLink } from 'react-csv';
Chart.register(...registerables); 
Chart.register(ArcElement);


class Dashboard extends Component {
    constructor(props){
        super(props)

        this.state = {
            entity: '',
            showModal: false,
            data: [],
            dataF26ASRevenue : [],
            dataF26ASTDS: [],
            dataTDSR: [],
            dataReceipt: [],
            dataControlTotal: [],
            dataTANControlTotal: [],
            dataTANReport: [],
            dataUnreconciledReason: [],
            entity_dropdown: [],
            CTData: [],
            TANWiseDS: [],
            TANWiseReportDS: [],
            inputDataSRRPercentage: {
                datasets: [{
                    data: [],
                    backgroundColor: ["#00BAF7","#1E46A3"],
                    hoverOffset: 10
            }],
            },
            inputDataF26ASRevenuePercentage : {
                datasets: [{
                    data: [],
                    backgroundColor: ["#00BAF7","#1E46A3"],
                    hoverOffset: 10
                }]
            },
            inputDataF26ASTDSPercentage : {
                datasets: [{
                    data: [],
                    backgroundColor: ["#00BAF7","#1E46A3"],
                    hoverOffset: 10
                }]
            },
            inputDataTDSRPercentage : {
                datasets: [{
                    data: [],
                    backgroundColor: ["#00BAF7","#1E46A3"],
                    hoverOffset: 10
                }]
            },
            inputDataReceiptPercentage : {
                datasets: [{
                    data: [],
                    backgroundColor: ["#00BAF7","#1E46A3"],
                    hoverOffset: 10
                }]
            },
            controlTotals: {
                datasets: [{
                    data: [],
                },
                {
                    data: [],
                },
                {
                    data: [],
                },
                {
                    data: [],
                }]
            },
            TANWiseControlTotals: {
                datasets: [{
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                }]
            },
            TANWiseReport: {
                datasets: [{
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                },
                {
                    data: []
                }]
            },
            ReasonForUnreconciliation: {
                datasets: [{
                    data: [],
                    backgroundColor: ["#58508d",
                    "#003f5c",
                    "#50AF95",
                    "#ffa600",
                    "#2a71d0",
                    "#ecf0f1"],
                    hoverOffset: 15
                }]
            },
            TAN: []
        }
    }

    // showAlert = () =>  {
    //     alert("Access Denied. Please contact the admin")
    // }

    componentDidMount(){
        getRequest('api/tds/getTransactionEntity').then(response =>{

            

            let data = response.res.data === undefined || null ? [{"txnentity": "No Company Found"}] : response.res.data

            // let data = JSON.parse(response.res.data)

            let entity_dropdown = data.map(value => value.txnentity)

            
            this.setState({
                entity_dropdown : entity_dropdown
            })
         
        })
    }

    callSRRPercentage = () => {
        this.setState({},
            () => {
                getRequest('api/tds/getSRRPercentage?input=' + this.state.entity).then(response => {

                    let data = response.res.data === undefined || null ? [{"SRR_REC_Percent" : "0", "SRR_UNR_Percent": "0"}] : response.res.data
            
                    let map = [data.map((data) => data.SRR_REC_Percent), data.map((data) => data.SRR_UNR_Percent)]
            
                    this.setState({
                      ...this.state,
                      data : data,
                      inputDataSRRPercentage : {
                        ...this.state.inputDataSRRPercentage,
                        labels: ["REC","UNR"],
                        datasets : [{
                          data: map,
                          backgroundColor: ["#00BAF7","#1E46A3"],
                          hoverOffset: 10
                      }],
                      options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'Revenue (in %)',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    }
            
                      }
                    })
                  })
            })
    }

    callF26ASRevenuePercentage = () => {
        this.setState({},
            () => {
                getRequest('api/tds/getF26ASRevenue?input=' + this.state.entity).then(response => {
                    let dataF26ASRevenue = response.res.data === undefined || null ? [{"F26ASAmt_REC_Percent" : "0", "F26ASAmt_UNR_Percent": "0"}] : response.res.data

                    let map = [dataF26ASRevenue.map((data) => data.F26ASAmt_REC_Percent), dataF26ASRevenue.map((data)=> data.F26ASAmt_UNR_Percent)]

                    this.setState({
                        ...this.state,
                        dataF26ASRevenue : dataF26ASRevenue,
                        inputDataF26ASRevenuePercentage : {
                            ...this.state.inputDataF26ASRevenuePercentage,
                            labels: ["REC","UNR"],
                            datasets : [{
                                data: map,
                                backgroundColor: ["#00BAF7","#1E46A3"],
                                hoverOffset: 10
                            }],
                            options:{
                                plugins: {
                                    title: {
                                        display: true,
                                        align: 'center',
                                        text: 'Revenue as per F26AS (in %)',
                                        font: {
                                            size: 18
                                        }
                                    }
                                }
                            },
                        }
                    })
                })
            })
    }

    callF26TDSPercentage = () => {
        getRequest('api/tds/getF26ASTDS?input=' + this.state.entity).then(response => {
            let dataF26ASTDS = response.res.data === undefined || null ? [{"F26ASTDS_REC_Percent" : "0", "F26ASTDS_UNR_Percent": "0"}] : response.res.data

            let map = [dataF26ASTDS.map((data)=> data.F26ASTDS_REC_Percent), dataF26ASTDS.map((data)=> data.F26ASTDS_UNR_Percent)]

            this.setState({
                ...this.state,
                dataF26ASTDS : dataF26ASTDS,
                inputDataF26ASTDSPercentage : {
                    ...this.state.inputDataF26ASTDSPercentage,
                    labels: ["REC","UNR"],
                    datasets : [{
                        data: map,
                        backgroundColor: ["#00BAF7","#1E46A3"],
                        hoverOffset: 10
                    }],
                    options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'TDS as per F26AS (in %)',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    },
                }
            })
        })
    }

    callTDSRPercentage = () => {
        getRequest('api/tds/getTDSRPercentage?input=' + this.state.entity).then(response => {

            console.log(response.res.data)
            // let dataTDSR = response.res.data
            let dataTDSR = response.res.data === undefined || null ? [{"TDSR_REC_Percent" : "0", "TDSR_UNR_Percent": "0"}] : response.res.data

            let map = [dataTDSR.map((data)=>data.TDSR_REC_Percent), dataTDSR.map((data)=> data.TDSR_UNR_Percent)]

            this.setState({
                ...this.state,
                dataTDSR : dataTDSR,
                inputDataTDSRPercentage : {
                    ...this.state.inputDataTDSRPercentage,
                    labels: ["REC","UNR"],
                    datasets : [{
                        data: map,
                        backgroundColor: ["#00BAF7","#1E46A3"],
                        hoverOffset: 10
                    }],
                    options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'TDS as per book of accounts (in %)',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    },
                }
            })

            console.log(this.state.dataTDSR)
        })
    }

    callReceiptPercentage = () => {
        getRequest('api/tds/getReceiptPercentage?input=' + this.state.entity).then(response => {

            console.log(response.res.data)


            let dataReceipt = response.res.data === undefined || null ? [{"RCPT_REC_Percent":"0","RCPT_UNR_Percent":"0"}] : response.res.data

            let map = [dataReceipt.map((data)=>data.RCPT_REC_Percent), dataReceipt.map((data)=>data.RCPT_UNR_Percent)]


            this.setState({
                ...this.state,
                dataReceipt : dataReceipt,
                inputDataReceiptPercentage : {
                    ...this.state.inputDataReceiptPercentage,
                    labels: ["REC","UNR"],
                    datasets : [{
                        data: map,
                        backgroundColor: ["#00BAF7","#1E46A3"],
                        hoverOffset: 10
                    }],
                    options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'Collections (in %)',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    },
                }
            })

            console.log(this.state.inputDataReceiptPercentage)
        })
    }

    callControlTotal = () => {
        getRequest('api/tds/getControlTotals?input=' + this.state.entity).then(response => {
            let dataControlTotal = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                dataControlTotal: dataControlTotal,
                controlTotals: {
                    ...this.state.controlTotals,
                    labels: dataControlTotal.map(data=>data.reconstatus),
                    datasets: [{
                    label: "Revenue",
                    data: dataControlTotal.map(data=>data.SRR),
                    backgroundColor: "#58508d",
                    borderColor: "black",
                    borderWidth: 0.5
                },
                {
                    label: "TDS as per book of accounts",
                    data: dataControlTotal.map(data=>data.TDSR),
                    backgroundColor: "#50AF95",
                    borderColor: "black",
                    borderWidth: 0.5
                },
                {
                    label: "Revenue as per F26AS",
                    data: dataControlTotal.map(data=>data.F26AS_AMT),
                    backgroundColor: "#ffa600",
                    borderColor: "black",
                    borderWidth: 0.5
                },
                {
                    label: "TDS as per F26AS Deposited",
                    data: dataControlTotal.map(data=>data.F26AS_TDS),
                    backgroundColor: "#2a71d0",
                    borderColor: "black",
                    borderWidth: 0.5
                    }],
                    options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'Control Figures in Crores',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    },
                }
            })
        })
    }

    callTANWiseControlTotal = () => {
        getRequest('api/tds/getTANWiseControlTotal?input=' + this.state.entity).then(response => {
            let dataTANControlTotal = JSON.parse(response.res.data)

            let TAN = dataTANControlTotal

            this.setState({
                ...this.state,
                dataTANControlTotal : dataTANControlTotal,
                TANWiseControlTotals: {
                    ...this.state.TANWiseControlTotals,
                    labels: dataTANControlTotal.sort((a,b)=>a.tan.localeCompare(b.tan)).slice(0,5).map(data=>data.tan),
                    datasets: [{
                        label: "TDS as per book of accounts (in Rs)",
                        data: dataTANControlTotal.map((data) => data.TDSR_Val),
                        backgroundColor: "#58508d",
                         borderColor: "black",
                         borderWidth: 0.5
                     },{
                       label: "Revenue (in Rs)",
                       data: dataTANControlTotal.map((data) => data.SRR_Val),
                       backgroundColor: "#003f5c",
                       borderColor: "black",
                       borderWidth: 1
                     },{
                       label: "Collections (in Rs)",
                       data: dataTANControlTotal.map((data) => data.RCPT_Val),
                       backgroundColor: "#50AF95",
                       borderColor: "black",
                       borderWidth: 1
                     },{
                       label: "Revenue as per F26AS (in Rs)",
                       data: dataTANControlTotal.map((data) => data.f26AS_AMT_Val),
                       backgroundColor: "#ffa600",
                       borderColor: "black",
                       borderWidth: 1
                     },{
                       label: "TDS as per F26AS Deposited (in Rs)",
                       data: dataTANControlTotal.map((data) => data.f26AS_TDS_Val),
                       backgroundColor: "#2a71d0",
                       borderColor: "black",
                       borderWidth: 1
                     }],
                     options:{
                       plugins: {
                           title: {
                               display: true,
                               align: 'center',
                               text: 'TAN Wise Control Figures',
                               font: {
                                   size: 18
                               }
                           }
                       },
                       scales: {
                        y: {
                            min: 0,
                            max: 10000000
                        }
                       }
                   },  
                }
            })

        })
    }

    callTANWiseReport =() => {
        getRequest('api/tds/getTANWiseReport?input=' + this.state.entity).then(response => {
            let dataTANReport = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                dataTANReport: dataTANReport,
                TANWiseReport: {
                    ...this.state.TANWiseReport,
                    labels: dataTANReport.sort((a,b)=>a.tan.localeCompare(b.tan)).slice(0,5).map(data=>data.tan),
                    datasets: [{
                        label: " Reconciled F26AS Amount (in %)",
                data: dataTANReport.map((data) => data.REC_F26ASAMT),
                backgroundColor: "#1E46A3",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Reconciled F26AS TDS (in %)",
                data: dataTANReport.map((data) => data.REC_F26ASTDS),
                backgroundColor: "#8fbc8f",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Reconciled SRR (in %)",
                data: dataTANReport.map((data) => data.REC_SRR),
                backgroundColor: "#00bfff",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Reconciled TDS Accounted (in %)",
                data: dataTANReport.map((data) => data.REC_TDSR),
                backgroundColor: "#adff2f",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Unreconciled F26AS Amount (in %)",
                data: dataTANReport.map((data) => data.UNR_F26ASAMT),
                backgroundColor: "#cd5c5c",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Unreconciled F26AS TDS (in %)",
                data: dataTANReport.map((data) => data.UNR_F26ASTDS),
                backgroundColor: "#228b22",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Unreconciled SRR (in %)",
                data: dataTANReport.map((data) => data.UNR_SRR),
                backgroundColor: "#ff69b4",
                borderColor: "black",
                borderWidth: 0.5
            },
            {
                label: "Unreconciled TDS Accounted (in %)",
                data: dataTANReport.map((data) => data.UNR_TDSWR),
                backgroundColor: "#ffa500",
                borderColor: "black",
                borderWidth: 0.5
             }],
             options: {
                plugins: {
                   title: {
                        display: true,
                        align: "center",
                        text: "TAN wise Percentage",
                        font: {
                            size: 18
                        }
                   } 
                },
                    scales: {
                        x: {
                            stacked: true
                        },
                        y: {
                            stacked: true,
                            max: 400,
                            min: -200
                        }
                    }
            }
                }
            })
        })
    }

    callUnreconciledReason = () => {
        getRequest('api/tds/getReasonsForUnreconciliation?input=' + this.state.entity).then(response => {
            let dataUnreconciledReason = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                dataUnreconciledReason: dataUnreconciledReason,
                ReasonForUnreconciliation: {
                    ...this.state.ReasonForUnreconciliation,
                    labels: dataUnreconciledReason.map(data=>data.Status),
                    datasets: [{
                        data: dataUnreconciledReason.map(data=>data.CountofTan),
                        backgroundColor: ["#58508d",
                        "#003f5c",
                        "#50AF95",
                        "#ffa600",
                        "#2a71d0",
                        "#ecf0f1"],
                        hoverOffset: 15
                    }],
                    options:{
                        plugins: {
                            title: {
                                display: true,
                                align: 'center',
                                text: 'Reasons for Unreconciled TANs',
                                font: {
                                    size: 18
                                }
                            }
                        }
                    }
                }
            })
        })
    }

    downloadCTSheet = async () => {
        await getRequest('api/tds/getCTSheet?input=' + this.state.entity).then(response => {
            console.log(response)
            this.setState({
                CTData: JSON.parse(response.res.data)
            }, ()=> {
                this.downloadLink1.link.click()
            })
        })
    }

    downloadTANSheet = async () => {
        await getRequest('api/tds/getTANWiseSheet?input=' + this.state.entity).then(response => {
            this.setState({
                TANWiseDS: JSON.parse(response.res.data)
            }, () => {
                this.downloadLink2.link.click()
            })
        })
    }

    downloadTANWisePercentageSheet = async () => {
        await getRequest('api/tds/getTANWisePercentageSheet?input=' + this.state.entity).then(response => {
            this.setState({
                TANWiseReportDS: JSON.parse(response.res.data)
            }, () => {
                this.downloadLink.link.click()
            })
        })
    }


    onInputChange = (e) => {
        this.setState({
            entity: e.target.value
        },
        () => {
            this.callSRRPercentage()
            this.callF26ASRevenuePercentage()
            this.callF26TDSPercentage()
            this.callTDSRPercentage()
            this.callReceiptPercentage()
            this.callControlTotal()
            this.callTANWiseControlTotal()
            this.callTANWiseReport()
            this.callUnreconciledReason()
        })
    }

    setDefault = () => {

        this.setState({
            ...this.state,
            TANWiseControlTotals: {
                ...this.state.TANWiseControlTotals,
                labels: this.state.dataTANControlTotal.sort((a,b)=>a.tan.localeCompare(b.tan)).slice(0,5).map(data=>data.tan),
                datasets: [{
                    label: "TDS as per book of accounts (in Rs)",
                    data: this.state.dataTANControlTotal.map((data) => data.TDSR_Val),
                    backgroundColor: "#58508d",
                     borderColor: "black",
                     borderWidth: 0.5
                 },{
                   label: "Revenue (in Rs)",
                   data: this.state.dataTANControlTotal.map((data) => data.SRR_Val),
                   backgroundColor: "#003f5c",
                   borderColor: "black",
                   borderWidth: 1
                 },{
                   label: "Collections (in Rs)",
                   data: this.state.dataTANControlTotal.map((data) => data.RCPT_Val),
                   backgroundColor: "#50AF95",
                   borderColor: "black",
                   borderWidth: 1
                 },{
                   label: "Revenue as per F26AS (in Rs)",
                   data: this.state.dataTANControlTotal.map((data) => data.f26AS_AMT_Val),
                   backgroundColor: "#ffa600",
                   borderColor: "black",
                   borderWidth: 1
                 },{
                   label: "TDS as per F26AS Deposited (in Rs)",
                   data: this.state.dataTANControlTotal.map((data) => data.f26AS_TDS_Val),
                   backgroundColor: "#2a71d0",
                   borderColor: "black",
                   borderWidth: 1
                 }],
                 options:{
                   plugins: {
                       title: {
                           display: true,
                           align: 'center',
                           text: 'TAN Wise Control Figures',
                           font: {
                               size: 18
                           }
                       }
                   },
                   scales: {
                    y: {
                        min: 0,
                        max: 10000000
                    }
                   }
               },  
            }

        })
    }

    updateTAN = (e) => {
        const VALUE = e.target.value

        if(VALUE === "default"){
            this.setDefault()
        }


        this.state.dataTANControlTotal.forEach((each) => {
            if(each.tan === VALUE) {
                this.setState({
                    ...this.state,
                    TAN: each,
                    TANWiseControlTotals: {
                        ...this.state.TANWiseControlTotals,
                        labels: [VALUE],
                        datasets: [{
                            label: "TDS as per book of accounts (in Rs)",
                            data: [each.TDSR_Val],
                            backgroundColor: "#58508d",
                            borderColor: "black",
                            borderWidth: 0.5
                        },{
                            label: "Revenue (in Rs)",
                            data: [each.SRR_Val],
                            backgroundColor: "#003f5c",
                            borderColor: "black",
                            borderWidth: 0.5
                        },{
                          label: "Collections (in Rs)",
                          data: [each.RCPT_Val],
                          backgroundColor: "#50AF95",
                          borderColor: "black",
                          borderWidth: 0.5
                        },{
                          label: "Revenue as per F26AS (in Rs)",
                            data: [each.f26AS_AMT_Val],
                            backgroundColor: "#ffa600",
                            borderColor: "black",
                            borderWidth: 0.5
                        },{
                          label: "TDS as per F26AS Deposited (in Rs)",
                            data: [each.f26AS_TDS_Val],
                            backgroundColor: "#2a71d0",
                            borderColor: "black",
                            borderWidth: 0.5
                        }],
                        options:{
                            plugins: {
                                title: {
                                    display: true,
                                    align: 'center',
                                    text: 'TAN Wise Control Figures',
                                    font: {
                                        size: 18
                                    }
                                }
                            },
                            scales: {
                                y: {
                                    min: 0,
                                    max: 1000000
                                }
                               }
                        },
                    }
                })
                return
            }
        })
    }

    setChange = () => {
        this.setState({
            ...this.state,
            TANWiseReport: {
                ...this.state.TANWiseReport,
                labels: this.state.dataTANReport.sort((a,b)=>a.tan.localeCompare(b.tan)).slice(0,5).map(data=>data.tan),
                datasets: [{
                    label: " Reconciled F26AS Amount (in %)",
            data: this.state.dataTANReport.map((data) => data.REC_F26ASAMT),
            backgroundColor: "#1E46A3",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Reconciled F26AS TDS (in %)",
            data: this.state.dataTANReport.map((data) => data.REC_F26ASTDS),
            backgroundColor: "#8fbc8f",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Reconciled SRR (in %)",
            data: this.state.dataTANReport.map((data) => data.REC_SRR),
            backgroundColor: "#00bfff",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Reconciled TDS Accounted (in %)",
            data: this.state.dataTANReport.map((data) => data.REC_TDSR),
            backgroundColor: "#adff2f",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Unreconciled F26AS Amount (in %)",
            data: this.state.dataTANReport.map((data) => data.UNR_F26ASAMT),
            backgroundColor: "#cd5c5c",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Unreconciled F26AS TDS (in %)",
            data: this.state.dataTANReport.map((data) => data.UNR_F26ASTDS),
            backgroundColor: "#228b22",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Unreconciled SRR (in %)",
            data: this.state.dataTANReport.map((data) => data.UNR_SRR),
            backgroundColor: "#ff69b4",
            borderColor: "black",
            borderWidth: 0.5
        },
        {
            label: "Unreconciled TDS Accounted (in %)",
            data: this.state.dataTANReport.map((data) => data.UNR_TDSWR),
            backgroundColor: "#ffa500",
            borderColor: "black",
            borderWidth: 0.5
         }],
         options: {
            plugins: {
               title: {
                    display: true,
                    align: "center",
                    text: "TAN wise Percentage",
                    font: {
                        size: 18
                    }
               } 
            },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true,
                        max: 400,
                        min: -200
                    }
                }
        }
            }
        })
    }

    updateReport = (e) => {
        const VALUE = e.target.value

        if(VALUE === "default"){
            this.setChange()
        }

        this.state.dataTANReport.forEach((find) => {
            if(find.tan === VALUE){
                console.log(find)
                this.setState({
                    ...this.state,
                    TANWiseReport: {
                        ...this.state.TANWiseReport,
                        labels: [VALUE],
                        datasets: [{
                            label: "Reconciled F26AS Amount (in %)",
                            data: [find.REC_F26ASAMT],
                            backgroundColor: "#bdb76b",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Reconciled F26AS TDS (in %)",
                            data: [find.REC_F26ASTDS],
                            backgroundColor: "#8fbc8f",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Reconciled SRR (in %)",
                            data: [find.REC_SRR],
                            backgroundColor: "#00bfff",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Reconciled TDS Accounted (in %)",
                            data: [find.REC_TDSR],
                            backgroundColor: "#adff2f",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Unreconciled F26AS Amount (in %)",
                            data: [find.UNR_F26ASAMT],
                            backgroundColor: "#cd5c5c",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Unreconciled F26AS TDS (in %)",
                            data: [find.UNR_F26ASTDS],
                            backgroundColor: "#228b22",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Unreconciled SRR (in %)",
                            data: [find.UNR_SRR],
                            backgroundColor: "#ff69b4",
                            borderColor: "black",
                            borderWidth: 0.5
                        },
                        {
                            label: "Unreconciled TDS Accounted (in %)",
                            data: [find.UNR_TDSWR],
                            backgroundColor: "#ffa500",
                            borderColor: "black",
                            borderWidth: 0.5
                        }],
                        options: {
                            plugins: {
                               title: {
                                    display: true,
                                    align: "center",
                                    text: "TAN wise Percentage",
                                    font: {
                                        size: 18
                                    }
                               } 
                            },
                                scales: {
                                    x: {
                                        stacked: true
                                    },
                                    y: {
                                        stacked: true,
                                        max: 400,
                                        min: -200
                                    }
                                }
                        }
                    }
                })
                return
            }
        })
    }

    render(){
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-md-2 mt-1'>
                    <label htmlFor="input">Select Company</label>< br />
                        <select name="entity" id="entity" style={{width: '9rem', borderRadius: '20%', marginTop: '.3rem'}} value={this.state.entity} onChange={this.onInputChange}>
                            <option value="" disabled>Select</option>
                            {this.state.entity_dropdown.map( each => <option key={each} value={each}>{each}</option>)}
                        </select>
                    </div>
                </div>

                <br/>
                <br/>
                <br />


                {this.state.entity !== '' ?
                <div>
                <div style={{display:'flex', border:'groove', borderRadius:5, borderColor: '#606060',padding:10}}>
                <div>
                    <div style={{width:230}}>
                        {this.state.inputDataSRRPercentage === '' ? <h4>No Data Found</h4>:
                        <div>
                     <Doughnut data={this.state.inputDataSRRPercentage} options={this.state.inputDataSRRPercentage.options}/>
                        </div>}
                    </div>
                </div>

                <div>
                    <div style={{width:230}}>
                        {this.state.dataF26ASRevenue === null ? <h3> No Data Found</h3> :
                        <div>
                        <Doughnut data={this.state.inputDataF26ASRevenuePercentage} options={this.state.inputDataF26ASRevenuePercentage.options}/>
                        </div>
                        }
                    </div>
                 </div>

                 <div>
                    <div style={{width:230}}>
                        {this.state.dataF26ASTDS === null ? <h3> No Data Found</h3> :
                        <div>
                        <Doughnut data={this.state.inputDataF26ASTDSPercentage} options={this.state.inputDataF26ASTDSPercentage.options}/>
                        </div>
                         }
                    </div>
                 </div>

                 <div>
                    <div style={{width:230}}>
                        {this.state.dataTDSR === null ? <h3> No Data Found</h3> :
                        <div>
                    <Doughnut data={this.state.inputDataTDSRPercentage} options={this.state.inputDataTDSRPercentage.options}/>
                        </div>
                        }
                    </div>
                 </div>

                 <div>
                    <div style={{width:230}}>
                        {this.state.dataReceipt === null ? <h3> No Data Found</h3>:
                        <div>
                    <Doughnut data={this.state.inputDataReceiptPercentage} options={this.state.inputDataReceiptPercentage.options}/>
                        </div>}
                    </div>
                 </div>

                 </div>

                 <br/>
                 <br/>
                 <br/>
                 <br/>
                <div style={{display: "flex", justifyContent: "space-around"}}>
                 <div>
                    <div style={{width:550}}>
                    <Bar data={this.state.controlTotals} options={this.state.controlTotals.options}/>
                    <br/>
                    <br/>
                    <div style={{alignItems: 'center'}}>
                    <button style={{padding: 7, backgroundColor: "#4CAF50", border: 'none', color: 'white', textAlign:'center', textDecoration:'none',display:'inline-block'}} onClick={this.downloadCTSheet}>Download Data sheet</button>
                    <CSVLink data={this.state.CTData} filename='ControlTotal.csv' ref={(r)=> this.downloadLink1 = r} target='_blank'/>
                    </div>
                    </div>
                 </div>

                 <div>
                    <div style={{width:550}}>
                    <Bar data={this.state.TANWiseControlTotals} options={this.state.TANWiseControlTotals.options}/>
                    <br/>
                    <label style={{fontFamily: 'sans-serif', fontSize: 'small', padding: 2 }}>Select TAN</label>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <select style={{fontFamily: 'sans-serif', fontSize: 'small', padding: 1}} onChange={this.updateTAN}>
                    <option value="default">All</option>
                    {this.state.dataTANControlTotal.sort((a,b)=>a.tan.localeCompare(b.tan)).map((item, ind)=> 
                    <option value={item.tan} key={ind}>{item.tan}</option>)}
                    </select>
                    <button style={{padding: 7, backgroundColor: "#4CAF50", border: 'none', color: 'white', textAlign:'center', textDecoration:'none',display:'inline-block'}} onClick={this.downloadTANSheet}>Download Data sheet</button>
                    <CSVLink data={this.state.TANWiseDS} filename='TAN Wise Total.csv' ref={(r)=>this.downloadLink2 =r} target='_blank'/>
                    </div>
                    </div>
                 </div>
                 </div>

                 <br/>
                 <br/>
                 <br/>
                 <br/>

                 <div style={{display: "flex", justifyContent: 'space-around'}}>
                    <div style={{width:700}}>
                    <Bar data={this.state.TANWiseReport} options={this.state.TANWiseReport.options}></Bar>
                    <br/>
                    <label style={{fontFamily: 'sans-serif', fontSize: 'small', padding: 2}}>Select TAN</label>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <select style={{fontFamily: 'sans-serif', fontSize: 'small', padding: 1}} onChange={this.updateReport}>
                        <option value="default">All</option>
                        {this.state.dataTANReport.sort((a,b)=>a.tan.localeCompare(b.tan)).map((item, ind)=>
                        <option value={item.tan} key={ind}>{item.tan}</option>)}
                    </select>
                    <button style={{padding: 7, backgroundColor: "#4CAF50", border: 'none', color: 'white', textAlign:'center', textDecoration:'none',display:'inline-block'}} onClick={this.downloadTANWisePercentageSheet}>Download Data sheet</button>
                    <CSVLink data={this.state.TANWiseReportDS} filename='TAN Wise Report.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
                    </div>
                    </div>

                    <div>
                        <div style={{width:400}}>
                        <Pie data={this.state.ReasonForUnreconciliation} options={this.state.ReasonForUnreconciliation.options}></Pie>
                        </div>
                    </div>
                 </div> 
                 </div>: 
                 <div style={{textAlign: "center"}}>
                 <h3 className="mt-6"><strong>Please select the Company to view Dashboard</strong></h3>
                 </div> }

                 
            </Fragment>
        )
    }


}

export default Dashboard