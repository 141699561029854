import React, { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker"; //modify css of datepicker to remove border.

import { useDispatch } from "react-redux";
import { dateForm } from "../redux";

import { setFormValue } from '../redux/FormInputs/formActions';
import { connect } from 'react-redux';

function DateForm(props) {
  const dispatch = useDispatch();

  const [value1, onChange1] = useState(() => {
    let today = new Date();
    let date = new Date(today);
    dispatch(setFormValue(props.modelName, today));
    date.setDate(today.getDate());
    return date;
  });
  const [value2, onChange2] = useState(() => {
    let today = new Date();
    let date = new Date(today);
    date.setDate(today.getDate());
    return date;
  });

  return (
    <Form
      className="bg-white p-1 rounded col-md-12"
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(
          dateForm({
            startDate: value1.toLocaleDateString("en-GB"),
            endDate: value2.toLocaleDateString("en-GB"),
          })
        );
      }}
    >
      <Form.Group as={Row} controlId={props.modelName} lg="12">
        <Form.Label column lg="4" style={{ whiteSpace : 'nowrap', overflow : 'hidden' }}>{props.label}</Form.Label>
        {/* <span> */}
        <Col lg="8">
            <DatePicker
              className="text-info border-info col-md-12"
              style={{ innerWidth : '95%' }}
              onChange={(ev) => {
                dispatch(setFormValue(props.modelName, new Date(ev)))
              }}
              value={props.chatbotFormInputs[props.modelName] ? props.chatbotFormInputs[props.modelName] : new Date()}
              format="dd-MM-yyyy"
              // maxDate={new Date()}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
            />
          </Col>
        {/* </span> */}
      </Form.Group>
    </Form>
  );
}



const mapStateToProps = (state) => {
  return {
    chatbotFormInputs: state.chatbotFormInputs
  }
};

// export default  Transactions;

export default connect(
  mapStateToProps
)(DateForm);

// export default DateForm;
