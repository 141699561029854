import {
    SHOWALERT,
    HIDEALERT,
    NAVURL,
    INITIATECOMMON,
    VERIFYLOGIN,
    SET_MENU_TREE,
    FLUSH_MENU_TREE,
    FLESH_COMMON_OBJECT,
    GET_CURRENT_MENU_TREE
} from '../constants/action-types';
import {
    postRequestWithoutAuth,
    postRequest
} from '../globalhelper/helper'
import {
    requestCurrentState
} from './login_actions';



export function showAlert(msg, status) {
    return (dispatch) => {
        dispatch({
            type: SHOWALERT,
            text: msg,
            status: status
        })
    }
}

export function hideAlert() {
    return (dispatch) => {
        dispatch({
            type: HIDEALERT
        })
    }
}

export function flushCommonObject() {
    return (dispatch) => {
        dispatch({
            type: FLESH_COMMON_OBJECT
        })
    }
}

export function flushMenuTree() {
    return (dispatch) => {
        dispatch({
            type: FLUSH_MENU_TREE
        })
    }
}

export function getCurrentMenuTree() {
    return (dispatch) => {
        dispatch({
            type: GET_CURRENT_MENU_TREE
        })
    }
}

export function setMenuTree(menuTree) {
    return (dispatch) => {
        console.log(menuTree, "Actions")
        dispatch(flushMenuTree());
        dispatch({
            type: SET_MENU_TREE,
            menuTree: menuTree
        })
    }
}

export function navUrl(url) {
    console.log(url)
    return (dispatch) => {
        dispatch({
            type: NAVURL,
            url: url
        })
    }
}

export function initiateCommon(data) {
    return (dispatch) => {
        dispatch({
            type: INITIATECOMMON,
            data: data
        })
    }
}

export function verifyLogin() {
    return (dispatch) => {
        dispatch(requestCurrentState());
        return postRequest("api/user/verifyLogin").then((data) => {
            if (data) {
                if (data.type === "success") {
                    // console.log(data)
                    return data.res;
                }
            }
        })
    }
}