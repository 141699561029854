import React, { Component, Fragment } from 'react';
import {Form, Button} from 'react-bootstrap'
import '../CI/COD.css';
import { postRequest, getRequest } from '../../globalhelper/helper';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer'

export class VendorItemInfoSearch extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            vendorName: '',
            vendorID: '',
            itemName:'',
            itemID: '',
            orgname: ''
         },
         dropdown_vendorname: [],
         dropdown_vendorid: [],
         dropdown_itemid: [],
         dropdown_itemname: [],
         dropdown_orgname: [],
         tableData: [],
         showTable: false,
         companyDetails: [],
         error: false,
         errorMessage: ''
      }
    }

    getFilteredArray = (value, key) => {
        let dropdown_array = value.map((value) => value[key])
        let filtered_dropdown_array = [... new Set(dropdown_array)]
        return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
    }

    componentDidMount(){

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))

        // getRequest(`api/edureka/getVendorItemInfo`).then(response => {
        //     console.log(JSON.parse(response.res.data))
        //     const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

        //     let dropdown_vendorname = this.getFilteredArray(data, "Vendor Name")
        //     let dropdown_vendorid = this.getFilteredArray(data, "Vendor ID")
        //     let dropdown_itemid = this.getFilteredArray(data, "ItemID")
        //     let dropdown_itemname = this.getFilteredArray(data, "Item Name")

        //     this.setState({
        //         ...this.state,
        //         dropdown_vendorname: dropdown_vendorname,
        //         dropdown_vendorid: dropdown_vendorid,
        //         dropdown_itemid: dropdown_itemid,
        //         dropdown_itemname: dropdown_itemname
        //     })
        // })
    }

    handleChange = (e) => {
        const {name, value} = e.target

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSearch = () => {
        const {vendorID, vendorName, itemID, itemName, orgname} = this.state.formData

        if(orgname){

            this.setState({
                ...this.state,
                error: false
            })

            const id = this.state.companyDetails.filter(item => item.orgname === orgname)
            const companyid = id.map(item => item.orgid)

            postRequest(`api/edureka/getVendorItemInfoSearch`, {vendorID:vendorID, vendorName:vendorName, itemID:itemID, itemName: itemName, orgid: companyid[0]}).then(response => {
                console.log(JSON.parse(response.res.data))
    
                const data = JSON.parse(response.res.data)
    
                if(data.length){
                    this.setState({
                        ...this.state,
                        tableData: data,
                        showTable: true
                    })
                } else {
                    this.setState({
                        ...this.state,
                        error: true,
                        errorMessage: "No data found. Please try some other combination"
                    })
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }       
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            tableData: [],
            showTable: false,
            error: false,
            formData: {
                vendorName: '',
                vendorID: '',
                itemName: '',
                itemID: '',
                orgname: ''
            }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

    handleJSON = (item) => {
        const vendorid = item['Vendor ID']
        const itemid = item['Item ID']
        const inforecordname = item['Info Record Name']
        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
        const companyid = id.map(item => item.orgid)

        postRequest(`api/edureka/getVendorItemInfoJSON`, {vendorid: vendorid, itemid: itemid, inforecordname: inforecordname, orgid: companyid[0]}).then(response => {
            if(response.res.status === 'success'){
                this.setState({
                    redirect: true, 
                    SearchJSON: response.res.data
                })
            } else {
                console.log('No JSON exists')
            }
        })
    }

    handleOrgChange = (e) => {
        const {name, value} = e.target

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => this.handleRemainingAPIs())
    }

    handleRemainingAPIs = () => {
        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

        const companyid = id.map(item => item.orgid)

        getRequest(`api/edureka/getVendorItemInfo?orgid=${companyid[0]}`).then(response => {
            console.log(JSON.parse(response.res.data))
            const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

            let dropdown_vendorname = this.getFilteredArray(data, "Vendor Name")
            let dropdown_vendorid = this.getFilteredArray(data, "Vendor ID")
            let dropdown_itemid = this.getFilteredArray(data, "ItemID")
            let dropdown_itemname = this.getFilteredArray(data, "Item Name")

            this.setState({
                ...this.state,
                dropdown_vendorname: dropdown_vendorname,
                dropdown_vendorid: dropdown_vendorid,
                dropdown_itemid: dropdown_itemid,
                dropdown_itemname: dropdown_itemname
            })
        })
    }

    
  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
                screenName = {"VendorItemInfo"}
                screenAction = {"edit"}
                From_SearchJSON = {this.state.SearchJSON}
                onBack = {this.onBack}
            >

            </VR2_Forms>
        )
    }
    else return (
      <Fragment>
        <div className='header_div'>Vendor Item Info</div>

        <br/>

        <Form style={{display:'flex', flexDirection: 'column'}}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
                <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
                <select className='col-lg-3' name='vendorName' value={this.state.formData.vendorName} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
                <select className='col-lg-3' name='vendorID' value={this.state.formData.vendorID} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {this.state.dropdown_vendorid.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item Name:</div>
                <select className='col-lg-3' name='itemName' value={this.state.formData.itemName} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {this.state.dropdown_itemname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Item ID:</div>
                <select className='col-lg-3' name='itemID' value={this.state.formData.itemID} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {this.state.dropdown_itemid.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{textAlign:'center'}}>
                <Button variant='success' onClick={() => this.handleSearch()}>Search</Button>
                <Button variant='danger' style={{marginLeft:10}} onClick={this.handleReset}>Reset</Button>
            </div>
        </Form>

        <br/>

        {this.state.showTable && this.state.tableData.length ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Item Name</th>
                        <th>Vendor ID</th>
                        <th>Vendor Name</th>
                        <th>Info Record Name</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item['Item ID']}</td>
                                <td>{item['Item Name']}</td>
                                <td><Button variant='link' size='sm' onClick={() => this.handleJSON(item)}>{item['Vendor ID']}</Button></td>
                                <td>{item['Vendor Name']}</td>
                                <td>{item['Info Record Name']}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {this.state.error ?
        <div className='center-screen'>
            {this.state.errorMessage}
        </div> : null}
      </Fragment>
    )
  }
}

export default VendorItemInfoSearch


// import React, { Component, Fragment } from 'react';
// import {Form, Button} from 'react-bootstrap'
// import '../CI/COD.css';
// import { postRequest, getRequest } from '../../globalhelper/helper';
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import ReactSelect from 'react-select';

// export class VendorItemInfoSearch extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//          formData: {
//             vendorName: '',
//             vendorID: '',
//             itemName:'',
//             itemID: '',
//             orgname: ''
//          },
//          dropdown_vendorname: [],
//          dropdown_vendorid: [],
//          dropdown_itemid: [],
//          dropdown_itemname: [],
//          dropdown_orgname: [],
//          tableData: [],
//          showTable: false,
//          companyDetails: [],
//          error: false,
//          errorMessage: ''
//       }
//     }

//     getFilteredArray = (value, key) => {
//         let dropdown_array = value.map((value) => value[key])
//         let filtered_dropdown_array = [... new Set(dropdown_array)]
//         return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
//     }

//     componentDidMount(){

//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             console.log(arr)
//             this.setState({
//                 ...this.state,
//                 dropdown_orgname: arr,
//                 companyDetails: responseData
//             })
//         }).catch(err => console.log(err))

//         // getRequest(`api/edureka/getVendorItemInfo`).then(response => {
//         //     console.log(JSON.parse(response.res.data))
//         //     const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

//         //     let dropdown_vendorname = this.getFilteredArray(data, "Vendor Name")
//         //     let dropdown_vendorid = this.getFilteredArray(data, "Vendor ID")
//         //     let dropdown_itemid = this.getFilteredArray(data, "ItemID")
//         //     let dropdown_itemname = this.getFilteredArray(data, "Item Name")

//         //     this.setState({
//         //         ...this.state,
//         //         dropdown_vendorname: dropdown_vendorname,
//         //         dropdown_vendorid: dropdown_vendorid,
//         //         dropdown_itemid: dropdown_itemid,
//         //         dropdown_itemname: dropdown_itemname
//         //     })
//         // })
//     }

//     handleChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         })
//     }

//     handleSearch = () => {
//         const {vendorID, vendorName, itemID, itemName, orgname} = this.state.formData

//         if(orgname){

//             this.setState({
//                 ...this.state,
//                 error: false
//             })

//             const id = this.state.companyDetails.filter(item => item.orgname === orgname)
//             const companyid = id.map(item => item.orgid)

//             postRequest(`api/edureka/getVendorItemInfoSearch`, {vendorID:vendorID, vendorName:vendorName, itemID:itemID, itemName: itemName, orgid: companyid[0]}).then(response => {
//                 console.log(JSON.parse(response.res.data))
    
//                 const data = JSON.parse(response.res.data)
    
//                 if(data.length){
//                     this.setState({
//                         ...this.state,
//                         tableData: data,
//                         showTable: true
//                     })
//                 } else {
//                     this.setState({
//                         ...this.state,
//                         error: true,
//                         errorMessage: "No data found. Please try some other combination"
//                     })
//                 }
//             })
//         } else {
//             this.setState({
//                 ...this.state,
//                 error: true,
//                 errorMessage: "Please select all the required fields"
//             })
//         }       
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             tableData: [],
//             showTable: false,
//             error: false,
//             formData: {
//                 vendorName: '',
//                 vendorID: '',
//                 itemName: '',
//                 itemID: '',
//                 orgname: ''
//             }
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//     handleJSON = (item) => {
//         const vendorid = item['Vendor ID']
//         const itemid = item['Item ID']
//         const inforecordname = item['Info Record Name']
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
//         const companyid = id.map(item => item.orgid)

//         postRequest(`api/edureka/getVendorItemInfoJSON`, {vendorid: vendorid, itemid: itemid, inforecordname: inforecordname, orgid: companyid[0]}).then(response => {
//             if(response.res.status === 'success'){
//                 this.setState({
//                     redirect: true, 
//                     SearchJSON: response.res.data
//                 })
//             } else {
//                 console.log('No JSON exists')
//             }
//         })
//     }

//     handleOrgChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         }, () => this.handleRemainingAPIs())
//     }

//     handleRemainingAPIs = () => {
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getVendorItemInfo?orgid=${companyid[0]}`).then(response => {
//             console.log(JSON.parse(response.res.data))
//             const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

//             let dropdown_vendorname = this.getFilteredArray(data, "Vendor Name")
//             let dropdown_vendorid = this.getFilteredArray(data, "Vendor ID")
//             let dropdown_itemid = this.getFilteredArray(data, "ItemID")
//             let dropdown_itemname = this.getFilteredArray(data, "Item Name")

//             this.setState({
//                 ...this.state,
//                 dropdown_vendorname: dropdown_vendorname,
//                 dropdown_vendorid: dropdown_vendorid,
//                 dropdown_itemid: dropdown_itemid,
//                 dropdown_itemname: dropdown_itemname
//             })
//         })
//     }

    
//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//                 screenName = {"VendorItemInfo"}
//                 screenAction = {"edit"}
//                 From_SearchJSON = {this.state.SearchJSON}
//                 onBack = {this.onBack}
//             >

//             </VR2_Forms>
//         )
//     }
//     else return (
//       <Fragment>
//         <div className='header_div'>Vendor Item Info</div>

//         <br/>

//         <Form style={{display:'flex', flexDirection: 'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
//                 <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
//                     Vendor Name:
//                 </div>
//                 <ReactSelect
//                     className='col-lg-3'
//                     name='vendorName'
//                     value={this.state.dropdown_vendorname.find(option => option.value === this.state.formData.vendorName)}
//                     onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorName', value: selectedOption.value } })}
//                     options={this.state.dropdown_vendorname.map(item => ({ value: item, label: item }))}
//                     styles={{
//                         container: (base) => ({ ...base, padding: 5 }),
//                         control: (base) => ({ 
//                             ...base, 
//                             borderRadius: 5, 
//                             border: '1px solid #ccc', 
//                             minHeight: '38px',  
//                             paddingLeft: '0px', // Remove left padding on the control to avoid clipping
//                         }),
//                         valueContainer: (base) => ({
//                             ...base,
//                             padding: '0 15px', // Add horizontal padding here instead
//                         }),
//                         input: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0, 
//                         }),
//                         singleValue: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0,
//                         })
//                     }}
//                     placeholder="Select"
//                 />
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
//                     Vendor ID:
//                 </div>
//                 <ReactSelect
//                     className='col-lg-3'
//                     name='vendorID'
//                     value={this.state.dropdown_vendorid.find(option => option.value === this.state.formData.vendorID)}
//                     onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorID', value: selectedOption.value } })}
//                     options={this.state.dropdown_vendorid.map(item => ({ value: item, label: item }))}
//                     styles={{
//                         container: (base) => ({ ...base, padding: 5 }),
//                         control: (base) => ({ 
//                             ...base, 
//                             borderRadius: 5, 
//                             border: '1px solid #ccc', 
//                             minHeight: '38px',  
//                             paddingLeft: '0px', // Remove left padding on the control to avoid clipping
//                         }),
//                         valueContainer: (base) => ({
//                             ...base,
//                             padding: '0 15px', // Add horizontal padding here instead
//                         }),
//                         input: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0, 
//                         }),
//                         singleValue: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0,
//                         })
//                     }}
//                     placeholder="Select"
//                 />
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
//                     Item Name:
//                 </div>
//                 <ReactSelect
//                     className='col-lg-3'
//                     name='itemName'
//                     value={this.state.dropdown_itemname.find(option => option.value === this.state.formData.itemName)}
//                     onChange={(selectedOption) => this.handleChange({ target: { name: 'itemName', value: selectedOption.value } })}
//                     options={this.state.dropdown_itemname.map(item => ({ value: item, label: item }))}
//                     styles={{
//                         container: (base) => ({ ...base, padding: 5 }),
//                         control: (base) => ({ 
//                             ...base, 
//                             borderRadius: 5, 
//                             border: '1px solid #ccc', 
//                             minHeight: '38px',  
//                             paddingLeft: '0px', // Remove left padding on the control to avoid clipping
//                         }),
//                         valueContainer: (base) => ({
//                             ...base,
//                             padding: '0 15px', // Add horizontal padding here instead
//                         }),
//                         input: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0, 
//                         }),
//                         singleValue: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0,
//                         })
//                     }}
//                     placeholder="Select"
//                 />
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
//                     Item ID:
//                 </div>
//                 <ReactSelect
//                     className='col-lg-3'
//                     name='itemID'
//                     value={this.state.dropdown_itemid.find(option => option.value === this.state.formData.itemID)}
//                     onChange={(selectedOption) => this.handleChange({ target: { name: 'itemID', value: selectedOption.value } })}
//                     options={this.state.dropdown_itemid.map(item => ({ value: item, label: item }))}
//                     styles={{
//                         container: (base) => ({ ...base, padding: 5 }),
//                         control: (base) => ({ 
//                             ...base, 
//                             borderRadius: 5, 
//                             border: '1px solid #ccc', 
//                             minHeight: '38px',  
//                             paddingLeft: '0px', // Remove left padding on the control to avoid clipping
//                         }),
//                         valueContainer: (base) => ({
//                             ...base,
//                             padding: '0 15px', // Add horizontal padding here instead
//                         }),
//                         input: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0, 
//                         }),
//                         singleValue: (base) => ({
//                             ...base,
//                             margin: 0,
//                             padding: 0,
//                         })
//                     }}
//                     placeholder="Select"
//                 />
//             </div>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='success' onClick={() => this.handleSearch()}>Search</Button>
//                 <Button variant='danger' style={{marginLeft:10}} onClick={this.handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {this.state.showTable && this.state.tableData.length ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>Item ID</th>
//                         <th>Item Name</th>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         <th>Info Record Name</th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.map((item, index) => {
//                         return (
//                             <tr key={index}>
//                                 <td>{item['Item ID']}</td>
//                                 <td>{item['Item Name']}</td>
//                                 <td><Button variant='link' size='sm' onClick={() => this.handleJSON(item)}>{item['Vendor ID']}</Button></td>
//                                 <td>{item['Vendor Name']}</td>
//                                 <td>{item['Info Record Name']}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}

//         {this.state.error ?
//         <div className='center-screen'>
//             {this.state.errorMessage}
//         </div> : null}
//       </Fragment>
//     )
//   }
// }

// export default VendorItemInfoSearch