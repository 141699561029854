import { SET_MODULES } from "../constants/action-types";
import { getRequest } from "../globalhelper/helper";
import store from '../store'


const setModules = (modules) => {
    return(dispatch) => {
        dispatch({
            type : SET_MODULES,
            payload : modules
        })
    }
}

export function getSetModules()
{
    return(dispatch) => {
        return getModules().then(data => {
            if(data.type === "success")
            {
                dispatch(setModules(data.res.data));
            }
        })
    }
}

const getModules = () => {
    const STATE = store.getState();
    return getRequest('api/module/modules?tenant_id=' + STATE.login.userData.USR_Tenant).then(data => data);
}