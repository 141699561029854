import React from 'react';
import { Fragment } from 'react';
import { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { getRequest, postRequest } from '../globalhelper/helper'
import { connect } from 'react-redux';
import { Card, ProgressBar } from "react-bootstrap";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ApiConstants from "../config.json";
import axios from 'axios';
import {Animated} from "react-animated-css";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class PDM extends Component{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                module : '',
                process : '',
                theme : '',
                processName : '',
                themeName : '',
            },
            showUpload : false,
            moduleList : [],
            processFormData : {},
            processList : [],
            themeList : [],
            csvheaders : [],
            rawHeadings : [],
            processTablepagination : [],
            processdefn : [],
            processTableHeader : [
                {
                    sort : true,
                    dataField: 'short_text',
                    text: 'Process Defn.',
                    formatter: (rowContent, row) => {
                        return (
                            <p className={ row.mandatory ? 'text-danger' : 'text-gray' }> { rowContent } </p>
                        )
                      }
                },
                {
                    sort : true,
                    dataField: 'csvheader',
                    text: 'CSV Header',
                    formatter: (rowContent, row, rowInd) => {
                        return (
                            <select className="form-control" id={ "csvheader" } value={row.csvheader} onChange={ (ev) =>  this.handleProcessTableChange(ev, rowInd) } model={ this.state.processFormData[row.process] }>
                                <option selected value=""></option>
                                 {/* {this.state.csvheaders.map((opt, optInd) =><option key={optInd} selected={ row.csvheader ? row.csvheader : this.state.processFormData[row.process] === opt.process ? this.state.processFormData[row.process] : '' }  value={opt.csvheader} defaultValue={ this.state.csvheaders.length === 1 ? this.state.csvheaders[0] : "" }> { opt.csvheader } </option> )} </select> */}

                                 {this.state.rawHeadings.map((opt, optInd) =><option key={optInd} value={opt} > { opt } </option> )} </select>
                        )
                      }
                },
                {
                    sort : true,
                    dataField: 'constant',
                    text: 'Constant Values',
                    formatter: (rowContent, row, rowInd) => {
                        return (
                            // model={ this.state.processFormData["constant"] }
                            <input className="form-control" id={ "constant" }  onChange={ (ev) =>  this.handleProcessTableChange(ev, rowInd) }  model={ row["constant"] } value={ row["constant"] }></input>
                        )
                      }
                },
                {
                    sort : true,
                    dataField: 'derive_formula',
                    text: 'Derive Formula',
                    formatter: (rowContent, row, rowInd) => {
                        return (
                            // model={ this.state.processFormData["derive_formula"] }
                            <input className="form-control" id={ "derive_formula" }  onChange={ (ev) =>  this.handleProcessTableChange(ev, rowInd) }  model={ row["derive_formula"] } value={ row["derive_formula"] }></input>
                        )
                      }
                },
                {
                    sort : true,
                    dataField: 'data_format',
                    text: 'Data Formats',
                    formatter: (rowContent, row, rowInd) => // model={ this.state.processFormData["data_format"] }
                            <input className="form-control" id={ "data_format" }  onChange={ (ev) =>  this.handleProcessTableChange(ev, rowInd) } model={ row["data_format"] } defaultValue={ row["data_format"] } ></input>
                },
            ],
        }
    }

    handleChange = (event) => {
        const name = event.target.name, value = event.target.value ;
        let size = "0.0 B";
        let exactFile = null;
        let fileName = null;
        console.log(name,value);
        var processName = this.state.formData.processName
        if(name === 'file')
        {
            size = event.target.files[0].size;
            fileName = event.target.files[0].name;
            exactFile = event.target.files[0];
        }
        else if(name === 'process')
        {
            const { processList } = this.state;

            processList.map(process=> {
                if (process.process_code === value)
                {
                    processName = process.process 
                }
            })
        }
        
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                [name] : value,
                processName : processName
            }
        }, () => {
            if(name === 'file')
            {
                let file_size = '0.00 B';
                if(size < 1024) file_size = size + " B";
                else if(size < 1048576) file_size = (size / 1024).toFixed(1) + " KB";
                else if(size < 1073741824) file_size = (size / 1048576).toFixed(1) + " MB";
                else file_size = (size / 1073741824).toFixed(3) + " GB";
                this.setState({
                    formData: {
                        ...this.state.formData,
                        fileSize: file_size,
                        filename: fileName,
                        exactFile : exactFile
                    }
                },() => this.uploadFile() )
                
            }
            
            else if (name === 'theme')
            {
                this.getProcessDefn();
            }
            else if (name === 'module')
            {
                this.state.moduleList.forEach(function(module){
                    if(module.name === this.state.formData.module)
                    {
                        this.setState({
                            ...this.state,
                            system : module.system,
                            formData : {
                                ...this.state.formData,
                                process : null
                            }
                        },() => {
                            this.getProcessDefn();
                        })
                    }
                }.bind(this))
            }
        })

      };

      

    handleProcessTableChange = (event, ind) => {
        console.log(event.target.id)
        console.log(event.target.value)
        console.log(ind)
        var id = event.target.id;
        var value = event.target.value;
        console.log(this.state.processdefn[ind])
        this.setState(prevState => {
            let processdefn = prevState.processdefn;
            let currentItem = processdefn[ind]
            currentItem[id] = value;
            console.log(currentItem);
            return {...prevState, processdefn : processdefn };
            // processFormData: {
            //       ...this.state.processFormData,
            //       [id]: event.target.value
            // }
        },() => {
            this.forceUpdate();
            if(value !== "" && id === 'csvheader')
            {
                this.checkHeaders(id,value)
            }
        })

    }

    
    checkHeaders = (id, value) => {
        Object.entries(this.state.processFormData).map(function(data) {
            if(data[0] !== id[0])
            {
                if(data[1] === value)
                {
                    document.getElementById(id[0]).selectedIndex = 0;
                    this.setState({
                        ...this.state,
                        processFormData : {
                            ...this.state.processFormData,
                            [id[0]] : ""
                        },
                        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>Can't set multiple headers</strong></div> </Animated>
                    })
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                }
            }
        }.bind(this))
    }
      
    uploadFile = () => {
        let fileObject = this.state.formData.exactFile;

        var formData = new FormData();
        formData.append("tenantId", this.props.state.login.userData.tenant_id);
        formData.append("checkDuplicate", false);
        formData.append("system", "Audire");
        formData.append("file", this.state.formData.exactFile);
        formData.append("process", this.state.formData.processName);

        this.setState({
            ...this.state,
            showmodal : false
        })

        const processFile = () => {
            
            this.setState({
                showPercentage : true,
                percentage : 0
            })
            let {state} = this.props;
            axios.post( ApiConstants.externals.serverUrl + 'api/upload/upload',formData,{
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' :'Bearer '+ state.login.auth
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = ( (loaded * 100) / total ).toFixed(2)
                        console.log( `${loaded}kb of ${total} | ${percent}` )
                        if(percent < 100)
                        {
                            this.setState({
                                percentage : percent
                            })
                        }
                        // Do whatever you want with the native progress event
                    }
                })
                .then(response => {
                    console.log(response)
                    if(response.data.status === "success")
                    {
                        this.setState({
                            percentage : 100,
                            // alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-success"><strong>{response.data.message}</strong></div> </Animated>,
                            uploadedFileName : response.data.data.filename,
                            s3FileName : response.data.data.s3Filename,
                        });
                        setTimeout(() => { this.setState({alert : null}); }, 1000);
                        setTimeout(() => { this.setState({showPercentage : false}); }, 1000);

                        axios.post(ApiConstants.externals.serverUrl +  "api/upload/checkHeaders?type=pdm",
                            {
                                tenantId: this.props.state.login.userData.tenant_id,
                                processName: this.state.formData.processName,
                                theme: this.state.formData.theme === "Create New" ? "Standard" : this.state.formData.theme,
                                fileNames: response.data.data.filename,
                                module: this.state.formData.module
                            },{
                                headers: {
                                    'Authorization' :'Bearer '+ state.login.auth
                                }
                            }
                        )
                        .then(result => {
                            let rawHeadings = result.data.data.rawHeadings
                            let headers = result.data.data.headers

                            if(result.data.status === "success")
                            {
                                var formData =  this.state.processFormData;

                                var tifOptions = Object.keys(formData).map(function(key) {
                                    Object.entries(headers).map(function(value,index) {
                                        if(key === value[1].process)
                                        {
                                            formData[key] = value[1].process
                                        }
                                    });
                                });

                                var processdefn = this.state.processdefn;

                                var man = processdefn.filter(obj => {
                                    return obj.mandatory === 1
                                })

                                var non_man = processdefn.filter(obj => {
                                    return obj.mandatory !== 1
                                })

                                // var i = 0;
                                // var entry1;
                                // while (i < non_man.length) {
                                //     entry1 = non_man[i];
                                //         if (result.data.data.headers.some(function(entry2) { return entry1.process === entry2.process; })) {
                                //             ++i;
                                //         } else {
                                //             non_man.splice(i, 1);
                                //         }
                                // }
                                processdefn =  [...man, ...non_man ];



                                // sourcefieldName should be '' when user unmapped any columns. So put '' in each object csvheader(sourcefieldName)

                                if(this.state.formData.theme === "Create New"){

                                    for(const [i , prof] of processdefn.entries() ){
    
                                        let obj = prof
                                        obj.csvheader = ""
    
                                        processdefn[i] = obj
    
                                        for(const [j, head] of rawHeadings.entries()){
    
                                            if( prof.short_text === head ){
                                                let obj = prof
                                                obj.csvheader = head
                                                processdefn[i] = obj
                                            }                                       
                                        }                              
                                    }
                                }
                                    

                                this.setState({
                                    ...this.state,
                                    csvheaders : headers,
                                    rawHeadings : rawHeadings,
                                    processFormData : formData,
                                    processdefn : processdefn
                                },() => {
                                    console.log(this.state.processFormData)
                                    console.log(this.state.processdefn)
                                    console.log(this.state.rawHeadings, 'rawHeadings')
                                })

                            }
                            else{
                                this.setState({
                                    ...this.state,
                                    alert : null,  
                                }, () => {
                                    this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{response.data.message}</strong></div> </Animated> });
                                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                                    setTimeout(() => { window.location.reload() },3000);
                                })
                            }
                        })
                    }
                    else{
                        this.setState({
                            showErr : true,
                            showPercentage : false,
                            Err : response.data.message
                        // },() => {
                        //     setTimeout(() => { window.location.reload() },4000)
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({
                        showErr : true,
                        showPercentage : false,
                        Err : "An Unexpected Error occurred while uploading your file. Please check after sometimes"
                    })
                })
        }

        if(fileObject)
        {
            processFile();
        }
    }


      getModule = () => {
          
        getRequest("api/module/modules?tenantId=" + this.props.state.login.userData.USR_Tenant)
        .then(response => {
            if(response.res.status === "success")
            {
              this.setState({
                  ...this.state,
                  moduleList : response.res.data
            //   }, () => {
            //       this.getProcessSB();
              })
          }
        })
      }

      componentDidMount = () => {
          this.getModule();
      }

      onModuleChange = (module) => {
          postRequest('api/module/moduleProcess', { moduleName : module }).then(response => {
            if(response.res.status === "success")
            {
              this.setState({
                ...this.state,
                processList : response.res.data
              })
            }
          })
      }

      onProcessChange = (process_code) => {
        getRequest('api/upload/getThemes?process_code=' + process_code + "&text=create&module=" + this.state.formData.module ).then(res => {
            if(res.type === "success")
            {
                this.setState({
                    ...this.state,
                    themeList : res.res.data
                }, () => {
                    if(this.state.themeList.length === 1)
                    {
                        this.setState({
                            ...this.state,
                            formData : {
                                ...this.state.formData,
                                theme : this.state.themeList[0].theme
                            }
                        },() => {
                            this.getProcessDefn();  
                        })
                    }
                })
            }
        })
      }


      getProcessDefn = () => {
        if(this.state.formData.process !== null)
        {
          postRequest("api/upload/process_defn",{
                  processName: this.state.formData.processName,
                  theme : this.state.formData.theme === "Create New" ? "Standard" : this.state.formData.theme,
                  tenantId: this.props.state.login.userData.USR_Tenant,
                  module: this.state.formData.module
              })
              .then( response => {
                  console.log(response);
                  if(response.res.status === "success")
                  {
                      let formData = {};
                      response.res.data.map( process => {
                          formData = {
                              ...formData,
                              [process.process] : ""
                          };
                      })
                      this.setState({
                          ...this.state,
                          processdefn : response.res.data,
                          showUpload : true,
                          processFormData : formData,
                          processTablepagination : {
                              custom: true,
                              sizePerPage : response.res.data.length
                          }
                      })
                  }
                  else{
                    //   setTimeout(() => { window.location.reload() }, 3000);
                  }
              })
          }
    }

    saveTheme = () => {
        const sDATA = {
            process_code : this.state.formData.process,
            theme : this.state.formData.theme === "Create New" ? this.state.formData.themeName : this.state.formData.theme,
            module : this.state.formData.module,
            data : JSON.stringify(this.state.processdefn)
        }

        console.log(this.state.processFormData)
        console.log(this.state.processdefn)
        console.log(this.state.rawHeadings)        

        
        postRequest('api/upload/saveNewTheme', { data : sDATA }).then(response => {
            if(response.res.status === "success")
            {
                setTimeout( () => { window.location.reload() }, 500)                
            }
        })
    }

    render(){
        return(
            <Fragment>
                <div className="row">
                    <FormControl variant="standard" sx={{ m: 1 }}  className="col-md-2" >
                        <InputLabel id="module-label">Module</InputLabel>
                        <Select
                        labelId="module-label"
                        id="module"
                        name="module"
                        size="small"
                        value={this.state.formData.module}
                        onChange={(ev) => {this.handleChange(ev); this.onModuleChange(ev.target.value)}}
                        >
                        {this.state.moduleList.map((item) => (
                            <MenuItem
                            key={item.name}
                            value={item.name}
                            >
                            {item.short_text}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1 }}  className="col-md-2" >
                        <InputLabel id="process-label">Process</InputLabel>
                        <Select
                        labelId="process-label"
                        id="process"
                        name="process"
                        size="small"
                        value={this.state.formData.process}
                        onChange={(ev) => {this.handleChange(ev); this.onProcessChange(ev.target.value) }}
                        >
                        {this.state.processList.map((item) => (
                            <MenuItem
                            key={item.process_code}
                            value={item.process_code}
                            >
                            {item.short_text + " (" + item.process_code + ") "}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1 }} className="col-md-2" >
                        <InputLabel id="theme-label">Theme</InputLabel>
                        <Select
                        labelId="theme-label"
                        id="theme"
                        name="theme"
                        size="small"
                        value={this.state.formData.theme}
                        onChange={(ev) => {this.handleChange(ev); }}
                        >
                        {this.state.themeList.map((item) => (
                            <MenuItem
                            key={item.theme}
                            value={item.theme}
                            >
                            {item.theme}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    { this.state.formData.theme === "Create New" ?
                        <FormControl variant="standard" sx={{ m: 1 }} className="col-md-2" >
                            <TextField id="standard-basic" value={this.state.formData.themeName} name="themeName" onChange={this.handleChange} label="Theme Name" variant="standard" />
                        </FormControl>
                        : null}
                    { this.state.formData.process && this.state.formData.module &&  this.state.showUpload  ?
                        <div className="col-md-2" style={{ marginTop : 17 }}>
                            <Card className="uploader">
                                <Card.Header className="card-header"> 
                                    <div className="row" style={{ marginLeft: "0px" }}>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <b className="text-white">Please upload a sample file</b>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <input type="file" id="file" name="file" onChange={ this.handleChange } accept=".csv"/>
                                            <AddCircleIcon style={{ fontSize : "2rem", cursor :'pointer', marginLeft : "-10px"}} onClick={ () => document.getElementById('file').click() }/>
                                        </div>
                                    </div>
                                    </Card.Header>
                                <Card.Body className="card-body">
                                    { this.state.formData.filename ? 
                                        <div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                            <p className="text-center text-gray" style={{ fontSize : '12px !important' }} > { this.state.formData.filename }</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <p className="text-center text-gray" style={{ fontSize : '12px !important' }}> 
                                                            <DeleteOutlineIcon  style={{ cursor :'pointer' }} onClick={ this.deleteFile } />
                                                            </p>
                                                    </div>
                                                </div>
                                            <Card.Text className="text-center">
                                                { this.state.formData.fileSize } 
                                            </Card.Text>
                                        </div>
                                        : null
                                    }   
                                </Card.Body>
                                { this.state.showPercentage ?
                                    <Card.Footer >
                                        <ProgressBar animated now={this.state.percentage} label={ this.state.percentage + "%" } />
                                        <p> Please wait while your file is uploading.. </p>
                                        <p> It will take couple of minutes if your file size is greater than 20MB </p>
                                    </Card.Footer>
                                    : null}
                                { this.state.showErr ?
                                    <Card.Footer >
                                        <ProgressBar variant="danger" animated now={this.state.percentage} label={ this.state.percentage + "%" } />
                                        <p style={{ color : 'red' }}> { this.state.Err } </p>
                                    </Card.Footer>
                                    : null}
                            </Card>
                        </div>
                    : null }
                </div>

                
                { this.state.csvheaders.length > 0 ?
                        <div>
                            <div className="row">
                                <div className="col-md-9">
                                    <h6 className="feild-header"> Map Fields </h6>
                                </div>
                            </div>

                            {/* <div className="row scroll-table">
                                <div className="col-md-12">
                                    <BootstrapTable key={JSON.stringify(this.state.processdefn)}  keyField='key' data={ this.state.processdefn } columns={ this.state.processTableHeader } pagination={ paginationFactory(this.state.processTablepagination) }/>
                                </div>
                            </div> */}

                            <div className="row scroll-table">
                                <div className="col-md-12">                                    
                                    <table id='table-css' >
                                        <thead style={{heigth: '4rem'}}>
                                        <tr style={{heigth: '4rem'}}>
                                            <th >Process Defn.</th>
                                            <th >CSV Header</th>
                                            <th >Constant Values</th>
                                            <th >Derive Formula</th>
                                            <th >Data Formats</th>                                              
                                        </tr>
                                        </thead>

                                        <tbody>                        
                                            
                                        { this.state.processdefn.map( (row, i) => {                                    
                                            return(
                                            
                                                <tr key={i} >

                                                    <td><p className={ row.mandatory ? 'text-danger' : 'text-gray' }> { row.short_text } </p></td>

                                                    <td>
                                                        <select className="form-control" id="csvheader" value={row.csvheader} onChange={ (ev) =>  this.handleProcessTableChange(ev, i) } model={ this.state.processFormData[row.process] }>
                                                            <option value=""></option>                                                                
                                                            {this.state.rawHeadings.map((each, j) =><option key={j} value={each} > { each } </option> )} 
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <input className="form-control" id="constant"  onChange={ (ev) =>  this.handleProcessTableChange(ev, i) }  model={ row["constant"] } value={ row["constant"] }></input>
                                                    </td>

                                                    <td>
                                                        <input className="form-control" id="derive_formula"  onChange={ (ev) =>  this.handleProcessTableChange(ev, i) }  model={ row["derive_formula"] } value={ row["derive_formula"] }></input>   
                                                    </td>

                                                    <td>
                                                        <input className="form-control" id="data_format"  onChange={ (ev) =>  this.handleProcessTableChange(ev, i) }  model={ row["data_format"] } defaultValue={ row["data_format"] } ></input>
                                                    </td>                                                      

                                                </tr>
                                            )
                                        })
                                        }        

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            
                            <br/>


                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 text-center">
                                    <button className="btn btn-teal" disabled={ this.state.buttonDisabled } onClick={ this.saveTheme }  > Save Theme </button>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>


                        </div>
                        : null }
            </Fragment>
        )
    }
}



function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
)(PDM);