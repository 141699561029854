import React, {Fragment, useState, useEffect} from 'react';
import { getRequest, getStandardDate, postRequest} from '../../globalhelper/helper';
import {Form, Button, Modal} from 'react-bootstrap';
import { connect } from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import '../CI/COD.css'
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { v4 as uuidv4 } from 'uuid'

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const Select = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const SelectMonth = ({label, name, value, options, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    {options.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

const convertToINRs = (number) => {
    const roundedNumber = Math.floor(Number(number))
    const num = roundedNumber.toLocaleString("en-IN", {style: "currency", currency: "INR", minimumFractionDigits: 0 })
    return num
  }

  const calculateTotalAmount = (array, key) => {
    const val = array.reduce((total, item) => total + (parseFloat(item[key]) || 0), 0);
    return convertToINRs(val);
  }
  

const EmployeeCostSplitup = (props) => {
    const [tableData, setTableData] = useState([])
    const [companyName, setCompanyName] = useState([])
    const [dropdownDepartment, setDropdownDepartment] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [showScrollButton, setShowScrollButton] = useState(false);

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

    const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

    const [month, setMonth] = useState("")

    const [year, setYear] = useState("")

    const [showpopup, setShowpopup] = useState(false)

    const [previousMonthData, setPreviousMonthData] = useState([])

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: "",
        department: ""
    })

    const handleMonthChange = (e) => {
        setMonth(e.target.value)
    }

    const handleYearChange = (e) => {
        setYear(e.target.value)
    }

    useEffect(() => {
        const selectedYear = year
        const selectedMonth = month 

        const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
        const startDate = new Date(selectedYear, monthIndex, 1)
        const endDate = new Date(selectedYear, monthIndex + 1, 0)

        console.log(getStandardDate(startDate), getStandardDate(endDate))

        setFormData(prev => {
            return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
        })
    }, [year, month])


    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    useEffect(() => {
        getRequest(`api/edureka/getUniqueDepartmentsForEC`).then(response => {
            const responseData = response.res.data
            const department = responseData.map(item => item.department)
            const filteredDepartment = department.filter(item => item !== ' ')
            setDropdownDepartment(filteredDepartment)
        })
    }, [])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            company: '',
            department: ''
        })
        setTableData([])
        setLoading(false)
        setShowError(false)
    }

    const handleSubmit = () => {
        const {fromDate, toDate, company, department} = formData

        if(fromDate && toDate && company && department){
            setLoading(true)
            setShowError(false)
            setTableData([])

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            const request = {
                fromDate,
                toDate, 
                company: companyid[0],
                department
            }

            postRequest(`api/edureka/getEmployeeCostSplitup`, request).then(response => {
                const responseData = JSON.parse(response.res.data)
                console.log(responseData)
                if(responseData.length){
                    setTableData(responseData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setShowError(true)
                    setShowpopup(true)
                    setErrorMessage("No data found, please try some other combination")
                }
            }).catch(err => {
                setLoading(false)
                setShowError(true)
                // setShowpopup(true)
                setErrorMessage("Oops something went wrong!!")
            })
        } else {
            toast.error("Please select all the required fields", {theme:"colored"})
            setShowError(true)
            setErrorMessage("Please select all the required fields")
        }
    }

    useEffect(() => {
        const processData = async () => {
            const data = [...previousMonthData]
            
            for (const item of data) {
                const allPercentagesPresent = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
                    .every(key => item[key] !== undefined && item[key] !== '')
    
                if (allPercentagesPresent) {
                    await handlePerc(item, { target: { name: 'b2cpercentage', value: item.b2cpercentage } }, 'b2cAmount')
                    await handlePerc(item, { target: { name: 'pgppercentage', value: item.pgppercentage } }, 'pgpAmount')
                    await handlePerc(item, { target: { name: 'b2bpercentage', value: item.b2bpercentage } }, 'b2bAmount')
                    await handlePerc(item, { target: { name: 'elcpercentage', value: item.elcpercentage } }, 'elcAmount')
                    await handlePerc(item, { target: { name: 'higheredpercentage', value: item.higheredpercentage } }, 'higheredAmount')
                    await handlePerc(item, { target: { name: 'vlabspercentage', value: item.vlabspercentage } }, 'vlabsAmount')
                    await handlePerc(item, { target: { name: 'verandapercentage', value: item.verandapercentage } }, 'verandaAmount')
                }
            }
        }
        processData()
    }, [previousMonthData])
    

    const handlePerc = async (val, e, amt) => {
        const { name, value } = e.target
    
        setTableData(prevTableData => {
            return prevTableData.map(item => {
                if (item.EmployeeID === val.EmployeeID) {
                    const decimalPercentage = Number(value) / 100
                    const amount = Number(item.TotalSalary) * decimalPercentage
                    return { ...item, [name]: Number(value), [amt]: parseFloat(amount.toFixed(2)) }
                } else {
                    return item
                }
            })
        })
    
    }
    

    const handlePercentage = (val, e, amt) => {
        
        const {name, value} = e.target
        const data = [...tableData]

        const mappedData = data.map((item, index) => {
            if(item["EmployeeID"] === val["EmployeeID"]){

                // console.log(index)
                if(index > 0){
                    const previousLine = index - 1

                    const previousItem = data[previousLine]

                    const totalPercentageofPreviousItem = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
                    .reduce((sum, key) => sum + (previousItem[key] || Number(0)), Number(0))

                    // console.log(totalPercentageofPreviousItem)

                    if(totalPercentageofPreviousItem !== 100){
                        toast.error("Please fill the above row correctly to proceed further", {
                            theme: "dark"
                        })
                        return item
                    } else {
                        const decimalPercentage = Number(value)/100
    
                        const amount = Number(item.TotalSalary) * decimalPercentage
        
                        const updatedItem = {...item, [name]: Number(value), [amt]: parseFloat((amount).toFixed(2))}
        
                        const totalPercentage = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
                        .reduce((sum, key) => sum + (updatedItem[key] || Number(0)), Number(0))
        
                        // console.log(totalPercentage)
        
                        if(totalPercentage > 100 ){
                            toast.error("Total percentage exceeds 100%. Please adjust the values", {
                                theme: "colored"
                            })
                            return item
                        }
        
                        // console.log(updatedItem)
                        return updatedItem
                    }
                } 
                
                else if (index === 0) {

                    const decimalPercentage = Number(value)/100
    
                    const amount = Number(item.TotalSalary) * decimalPercentage
    
                    const updatedItem = {...item, [name]: Number(value), [amt]: parseFloat((amount).toFixed(2))}
    
                    const totalPercentage = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
                    .reduce((sum, key) => sum + (updatedItem[key] || Number(0)), Number(0))
    
                    // console.log(totalPercentage)
    
                    if(totalPercentage > 100 ){
                        toast.error("Total percentage exceeds 100%. Please adjust the values", {
                            theme: "colored"
                        })
                        return item
                    }
    
                    // console.log(updatedItem)
                    return updatedItem
                }
            } else {
                return item
            }
        })
        console.log(mappedData)
        console.log("Before setting")
        setTableData(mappedData)
        console.log("After Setting")
    }

    const scrollToBottom = () => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleScroll = () => {
        // Check if the user is close to the bottom of the page
        const isCloseToBottom =
          window.innerHeight + window.scrollY >= document.body.offsetHeight
        
        // Update the state to show or hide the scroll button
        setShowScrollButton(isCloseToBottom);
    };
    
    useEffect(() => {
        // Add event listener for scroll events
        window.addEventListener('scroll', handleScroll);
        
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getCurrentDateTime = () => {
        const currentDate = new Date();
  
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');
        
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        
        return formattedDateTime;
    }

    const getInPrdToTime = () => {
        const currentDate = new Date('2100-12-31');
  
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');
        
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        
        return formattedDateTime;
    }

    const getFinancialYearAndMonth = (startDate, endDate) => {
        console.log(startDate, endDate)
        const startYear = new Date(startDate).getFullYear()
        const endYear = new Date(endDate).getFullYear()
        const startMonth = new Date(startDate).getMonth() + 1
        const endMonth = new Date(endDate).getMonth() + 1

        let financialYear, financialMonth

        if(startMonth < 4){
            financialYear = startYear - 1
        } else {
            financialYear = startYear
        }

        if(endMonth >=4){
            financialMonth = endMonth -3
        } else {
            financialMonth = endMonth + 9
        }

        return {
            financialYear, financialMonth
        }
    }

    const getFinancialMonth = (startDate) => {
        const date = new Date(startDate)

        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')

        return `MTH_${year}_${month}`
    }

    const handleUpload = () => {
        const inputData = [...tableData]

        const outputData = []

        const validation = inputData.every((item, index) => {
            const totalPercentage = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
                .reduce((sum, key) => sum + (item[key] || Number(0)), Number(0))
    
            if (totalPercentage !== 100) {
                toast.error("Please fill the values correctly to proceed further", {
                    theme: 'dark'
                });
                return false
            } else {
                return true
            }
        })

        // const amountValidation = inputData.every((item, index) => {
        //     const totalAmount = ['b2cAmount', 'b2bAmount', 'elcAmount', 'pgpAmount', 'higheredAmount', 'vlabsAmount', 'verandaAmount']
        //     .reduce((sum, key) => sum + (item[key] || 0), 0)

        //     const totalPercentage = ['b2cpercentage', 'pgppercentage', 'b2bpercentage', 'elcpercentage', 'higheredpercentage', 'vlabspercentage', 'verandapercentage']
        //         .reduce((sum, key) => sum + (item[key] || Number(0)), Number(0))

        //     if(totalPercentage > 0){
        //         if(totalAmount  (item['TotalSalary'] + 2)){
        //             toast.error("Please fill the values correctly to proceed further", {
        //                 theme: 'dark'
        //             });
        //             return false
        //         } else {
        //             return true
        //         }
        //     } else {
        //         return true
        //     }
        // })

        console.log(validation)

        if(validation){

            const {fromDate, toDate, company, department} = formData
    
            const result = getFinancialYearAndMonth(fromDate, toDate)
    
            const financialYear = `${result.financialYear}-${result.financialYear +1}`
    
            const financialMonth = getFinancialMonth(fromDate)
    
            console.log(financialYear, financialMonth)
    
            const id = companyDetails.filter(item => item.orgname === formData.company)
    
            const companyid = id.map(item => item.orgid)
    
            inputData.forEach(employee => {
                for (const key in employee){
                    if(key.endsWith("Amount") && employee[key] !== null){
                        const lobType = key.replace("Amount", "").toUpperCase()
    
                        const outputObject = {
                            "AllocationType": "%",
                            "AmountInLocalCurrency": employee[key],
                            "AmountInTransactionCurrency": null,
                            "CostAllocatedDocumentValue": null,
                            "CostAllocationMethod": employee["BusinessVertical"],
                            "CostAllocationParameterPerc": employee[`${lobType.toLowerCase()}percentage`],
                            "CostAllocationParameterValue": null,
                            "CostAllocationValue": null,
                            "CreatedID": employee["EmployeeID"],
                            "CreatedBy": props.state.login.userData.USR_Name,
                            "DocumentNumber": null,
                            "DocumentRef": null,
                            "DocumentValue": employee["TotalSalary"],
                            "FinancialDate": null,
                            "FinancialYear": financialYear,
                            "ForPrdFrom": fromDate,
                            "ForPrdTo": toDate,
                            "Function": employee["Department"],
                            "InPrdFrom": getCurrentDateTime(),
                            "InPrdTo": getInPrdToTime(),
                            "InterimID": null,
                            "ItemID": null,
                            "ItemName": null,
                            "LOB": lobType,
                            "LinKType": null,
                            "LineID": null,
                            "LinkRef": null,
                            "LinkRef1": null,
                            "LinkRef2": null,
                            "LinkRef3": null,
                            "MessageID": null,
                            "Month": financialMonth,
                            "OrgID": companyid[0],
                            "OrigDocumentNumber": null,
                            "OrigDocumentRef": null,
                            "ReceiverAccount": companyid[0],
                            "ReceiverName": company,
                            "Reference1": null,
                            "Reference2": null,
                            "Reference3": null,
                            "SenderAccount": null,
                            "SenderName": null,
                            "TransactionStatus": "Waiting For Approval",
                            "TransactionType": "EmployeeCostSplitup",
                            "Upload": null,
                            "PK": "TY#EmployeeCostSplitup",
                            "SK": `ID#${employee["EmployeeID"]}#FROMDT#${fromDate}#TODT#${toDate}#LOB#${lobType}`,
                            "CheckSum": null,
                            "TransactionID": uuidv4(),
                            "Input": null,
                            "Output": `ACTIVE/CA/ECSplitup/${companyid[0]}/${employee["EmployeeID"]}/FY_${financialYear}/${financialMonth}/CA_${companyid[0]}_${employee["EmployeeID"]}_FY_${financialYear}_${financialMonth}.json`
                          };
                          
                        outputData.push(outputObject)
                    }
    
                }
            })
            console.log(outputData)
    
            postRequest(`api/edureka/postEmployeeCostContributionArray`, outputData).then(response => {
                console.log(response.res.data)
                // toast.success("Uploaded Successfully")
                
                const request = {
                    arr: inputData,
                    period: financialMonth,
                    department: department,
                    date: fromDate,
                    company: companyid[0]
                }

                postRequest(`api/edureka/postEmployeeContribution`, request).then(response => {
                    console.log(response.res.data)
                    toast.success("Uploaded Successfully")
                    window.location.reload()
                }).catch(() => {
                    toast.error("Oops something went wrong")
                })

                console.log(request)
            }).catch(err => {
                console.log(err)
                toast.error("Oops some problem with uploading, please try again after sometime")
            })
        }      
    }

    const getPreviousMonthRange = (fromDate) => {

        const date = new Date(fromDate)

        // date.setMonth(date.getMonth() -1)

        const day = '01'
        const month = (date.getMonth()).toString().padStart(2, '0')
        const year = date.getFullYear()

        const startDate = `${year}-${month}-${day}`

        return startDate
    }

    const handlePopupSubmit = () => {
        setShowpopup(false)
        setLoading(true)
        setShowError(false)

        const {fromDate, company, department} = formData

        const startDate = getPreviousMonthRange(fromDate)

        console.log(startDate)

        const id = companyDetails.filter(item => item.orgname === company)

        const companyid = id.map(item => item.orgid)

        const request = {
            fromDate : startDate,
            toDate: fromDate, 
            company: companyid[0],
            department
        }

        postRequest(`api/edureka/getEmployeeCostSplitupForNonExisting`, request).then(response => {
            const responseData = JSON.parse(response.res.data)
            
            if(responseData.length){
                setTableData(responseData)
                setLoading(false)
                setPreviousMonthData(responseData)
            } else {
                setLoading(false)
                setShowError(true)
                setErrorMessage("No data found, please try some other combination")
            }
        }).catch(err => {
            setLoading(false)
            setShowError(true)
            setErrorMessage("Oops something went wrong!!")
        })
    }

    const handleSalaryChange = (arr, e) => {
        const {name, value} = e.target

        const data = [...tableData]

        const mappedData = data.map((item, index) => {
            if(item['EmployeeID'] === arr['EmployeeID']){
                // const updatedItem = {...item, "TotalSalary": parseFloat(value)}
                const calculateAmount = handleAmt(value, item)
                const returnItem = {...item, "TotalSalary": parseFloat(value), ...calculateAmount}
                console.log(returnItem)
                return returnItem
            } else {
                return item
            }
        })

        setTableData(mappedData)
    }

    const handleAmt = (amt, item) => {
        console.log(amt)

        const obj = {
            "b2bAmount": parseFloat(amt) * (parseFloat(item.b2bpercentage)/100),
            "b2cAmount": parseFloat(amt) * (parseFloat(item.b2cpercentage)/100),
            "elcAmount": parseFloat(amt) * (parseFloat(item.elcpercentage)/100),
            "pgpAmount": parseFloat(amt) * (parseFloat(item.pgppercentage)/100),
            "higheredAmount": parseFloat(amt) * (parseFloat(item.higheredpercentage)/100),
            "vlabsAmount": parseFloat(amt) * (parseFloat(item.vlabspercentage)/100),
            "verandaAmount": parseFloat(amt) * (parseFloat(item.verandapercentage)/100)
        }

        console.log(obj)

        return obj
    }   
    

  return (
    <Fragment>
        <form>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className="col-md-3">
                    <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
                </div>

                <div className="col-md-3">
                    <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Department</Form.Label>
                        <select className='spacing' value={formData.department} name='department' onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {dropdownDepartment.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                {tableData.length ?
                <Button size='sm' variant='primary' onClick={handleUpload} style={{marginLeft:'10px'}}>
                    Upload
                </Button> : null}
            </div>
        </form>

        <br/>

        {showError &&
         <div className="center-screen">
            {errorMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
        </div>: null}

        {!loading && tableData.length ?
        <div>
            <table border='1' id="table-css">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Business Vertical</th>
                        <th>Department</th>
                        <th>Total Salary</th>
                        {/* <th>Tally Status</th> */}
                        <th>B2C %</th>
                        <th>B2C Amt</th>
                        <th>PGP %</th>
                        <th>PGP Amt</th>
                        <th>B2B %</th>
                        <th>B2B Amt</th>
                        <th>ELC %</th>
                        <th>ELC Amt</th>
                        <th>HigherEd %</th>
                        <th>HigherEd Amt</th>
                        <th>VLabs %</th>
                        <th>VLabs Amt</th>
                        <th>Veranda %</th>
                        <th>Veranda Amt</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.sort((a, b) => a["EmployeeID"].localeCompare(b["EmployeeID"])).map((item, index) => {
                        return (
                            <tr key={item.EmployeeID}
                                className={index === selectedRowIndex ? 'highlighted-row' : ''}
                                onClick={() => setSelectedRowIndex(index)}
                            >
                                <td>{item.EmployeeID}</td>
                                <td>{item.EmployeeName}</td>
                                <td>{item.BusinessVertical}</td>
                                <td>{item.Department}</td>
                                {/* <td style={{textAlign:'right'}}>{Number(item.TotalSalary ? item.TotalSalary : 0).toFixed(2)}</td> */}
                                <td>
                                    <input style={{maxWidth:'100px'}} name='TotalSalary' disabled value={item.TotalSalary || ''} type='number' onChange={(e) => handleSalaryChange(item, e)}></input>
                                </td>
                                {/* <td>{item.TallyStatus}</td> */}
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='b2cpercentage' value={item.b2cpercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "b2cAmount")}/>
                                </td>
                                <td>{item.b2cAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='pgppercentage' value={item.pgppercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "pgpAmount")}/>
                                </td>
                                <td>{item.pgpAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='b2bpercentage' value={item.b2bpercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "b2bAmount")}/>
                                </td>
                                <td>{item.b2bAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='elcpercentage' value={item.elcpercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "elcAmount")}/>
                                </td>
                                <td>{item.elcAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='higheredpercentage' value={item.higheredpercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "higheredAmount")}/>
                                </td>
                                <td>{item.higheredAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='vlabspercentage' value={item.vlabspercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "vlabsAmount")}/>
                                </td>
                                <td>{item.vlabsAmount || 0}</td>
                                <td style={{maxWidth:'50px'}}>
                                    <input style={{maxWidth:'50px'}} min="0" max="100" name='verandapercentage' value={item.verandapercentage || ''} type='number' onChange={(e) => handlePercentage(item, e, "verandaAmount")}/>
                                </td>
                                <td>{item.verandaAmount || 0}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{fontWeight:"bolder"}} colSpan="4">Grand Total</td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "TotalSalary")}</td>
                        <td colSpan="1"></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "b2cAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "pgpAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "b2bAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "elcAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "higheredAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "vlabsAmount")}</td>
                        <td></td>
                        <td style={{fontWeight:"bolder"}}>{calculateTotalAmount(tableData, "verandaAmount")}</td>
                    </tr>
                </tfoot>
            </table>
        </div> : null}
        <ToastContainer/>
        <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
            {showScrollButton ? (
            <>
            <Button size='sm' onClick={scrollToTop}><NorthIcon/></Button>
            <br />
            <br/>
            <Button size='sm' onClick={scrollToBottom}><SouthIcon/></Button>
            </>
        ) : null}
    </div>
    
    {showpopup ?
    <div>
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title style={{fontSize: '1rem'}}>
                    No data found, do you want to fetch from previous month?
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                <Button size='sm' variant='danger' onClick={()=> setShowpopup(false)}>
                    No
                </Button>
                <Button size='sm' variant='success' onClick={handlePopupSubmit}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </div> : null}
    </Fragment>
  )
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps) (EmployeeCostSplitup)