import React, { Component, Fragment } from 'react';
import '../CI/COD.css'
import {Form, Button} from 'react-bootstrap'
import { getRequest, postRequest } from '../../globalhelper/helper';
import {connect} from 'react-redux'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import Pagination1 from '../CI/Pagination';
import Selector1 from '../../components/Selector1';

export class Approval extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            task: '',
            documentID: '',
            documentName: '',
            orgname: ''
         },
         tableData: [],
         initialJSON: [],
         approveJSON: [],
         activityCode: '',
         showtable: false,
         fetching: false,
         currentPage: 1,
         postsPerPage: 20,
         tableDisplay: [],
         dropdown_id: [],
         dropdown_number: [],
         filterData: [],
         dropdown_orgname: [],
         companyDetails: [],
         loading: false
      }
    }

    componentDidMount(){

        localStorage.setItem("pageName", "Approval")

        this.setState({
            ...this.state,
            fetching: true
        })

        getRequest(`api/edureka/getVendorActivityCode`).then(res => {
            console.log(res.res.data)
            this.setState({
                ...this.state,
                fetching: false,
                activityCode: res.res.data
            })
        })

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))
    }

    handleChange = (val) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                task: val
            }
        }, () => this.handleSearch())
    }

    handleSearch = () => {

        if(this.state.formData.orgname){
            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
            const companyid = id.map(item => item.orgid)
            this.setState({
                ...this.state,
                fetching: true,
                loading: true,
                showtable: false
            })
            getRequest(`api/edureka/getVendorApproval?status=${this.state.formData.task}&orgid=${companyid[0]}`).then(res => {
                console.log(res.res.data)
                const value = res.res.data
    
                const tempArr = value.map(obj => obj["Vendor ID"])
                const tempArrName = value.map(obj => obj["Vendor Name"])
    
                const filteredName = [...new Set(tempArrName)]
                const finalName = filteredName.filter((item) => item !==undefined && item!== '')
                console.log(finalName)
                console.log(filteredName)
                console.log(tempArr)
                this.setState({
                    ...this.state,
                    tableData: res.res.data,
                    filterData: res.res.data,
                    dropdown_id: tempArr,
                    dropdown_number: finalName,
                    showtable: true,
                    fetching: false,
                    currentPage: 1,
                    loading: false
                }, () => this.handlePagination())
            })
        }

    }

    getTimeAgeing = (item) => {
        const providedDateTime = item

        // Parse the provided date-time
        const providedDate = new Date(providedDateTime);

        // Get the current date and time
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - providedDate;

        // Convert the time difference to hours, minutes, and seconds
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = 60 * millisecondsInOneSecond;
        const millisecondsInOneHour = 60 * millisecondsInOneMinute;
        const millisecondsInOneDay = 24 * millisecondsInOneHour;

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        // Display the time difference
        // console.log(`${days} days, ${hours} hours, ${minutes} minutes`);

        return `${days}D, ${hours}H, ${minutes}M`
    }

    handleID = (i) => {
        console.log(i)

        const activityCode = this.state.activityCode
        console.log(activityCode)
        const id = i["Vendor ID"]
        const lde = i["organizationid"]
        console.log(id)
        console.log(id.substring(0,2))

        if(id.substring(0,2) === "VM" && lde === 'NoLDE'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"VendorMaster"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectVM : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })      
        } else if (id.substring(0,2) === "PO"){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"PurchaseOrder"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectPO : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })
        } else if (id.substring(0,2) ==='IM' && lde === 'NoLDE'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"ItemMaster"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectIM : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })
        } else if (id.substring(0,2) === 'IP'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"InternalPlan"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectIP : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })
        } else if (id.substring(0,2) === 'OM'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"OrganizationMaster"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectOM : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })
        } else if (id.substring(0,2) === 'BN' || id.substring(0,2) === 'BE'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"BillExtract"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
    
                    this.setState({ redirectBE : true, SearchJSON : response.res.data }) 
                } else {
                    console.log("No Data")
                }
            })
        } 
        else if (id.substring(0,2) === 'GS'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"GoodsAndServicesEntries"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
                    this.setState({redirectGS: true, SearchJSON: response.res.data})
                } else {
                    console.log("No Data")
                }
            })
        }
        else if (id.substring(0,2) === 'VM' && lde !== 'NoLDE'){
            console.log("Entered VendorPreference")
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"VendorPreferences"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
                    this.setState({redirectVP: true, SearchJSON: response.res.data})
                } else {
                    console.log("No Data")
                }
            })
        }
        else if (id.substring(0,2) === 'IM' && lde !== 'NoLDE'){
            console.log("Entered ItemPreferences")
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"ItemPreferences"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
                    this.setState({redirectItemPre: true, SearchJSON: response.res.data})
                } else {
                    console.log("No Data")
                }
            })
        }
        else if (id.substring(0,2) === 'BR'){
            postRequest(`api/edureka/approveJson?module=${localStorage.getItem('module')}&screenName=${"BudgetRelease"}&activitycode=${activityCode}&id=${id}&lde=${lde}`).then(response => {
                if(response.res.status === 'success'){
                    console.log(response.res.data)
                    this.setState({redirectBudgetRelease: true, SearchJSON: response.res.data})
                } else {
                    console.log("No Data")
                }
            })
        }
    }

    handlePagination = () => {
        console.log(this.state.currentPage)
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        let currentPosts = this.state.tableData.sort((a,b)=>a['Vendor ID'].localeCompare(b['Vendor ID'])).slice(indexofFirstPost, indexOfLastPost)
        // let currentPostsTimeBasedSorting = this.state.tableData.sort((a,b) => b['inprdfrom'] ? b['inprdfrom'].localeCompare(a['inprdfrom']) : null).slice(indexofFirstPost, indexOfLastPost)
        let currentPostsTimeBasedSorting = this.state.tableData
            .filter(post => post['inprdfrom']) // Filter out posts with missing 'inprdfrom' property
            .sort((a, b) => b['inprdfrom'].localeCompare(a['inprdfrom']))
            .slice(indexofFirstPost, indexOfLastPost);
        this.setState({
            ...this.state,
            tableDisplay: currentPostsTimeBasedSorting
        })
    }

    paginate = (pageNumber) => {
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    fetchTableData = () => {
        if (this.state.formData.orgname) {
            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname);
            const companyid = id.map(item => item.orgid);
    
            this.setState({
                fetching: true,
                loading: true,
                showtable: false
            });
    
            // Call the API after a 5-second delay
            setTimeout(() => {
                getRequest(`api/edureka/getVendorApproval?status=${this.state.formData.task}&orgid=${companyid[0]}`)
                    .then(res => {
                        const value = res.res.data;
                        const tempArr = value.map(obj => obj["Vendor ID"]);
                        const tempArrName = value.map(obj => obj["Vendor Name"]);
                        const filteredName = [...new Set(tempArrName)];
                        const finalName = filteredName.filter(item => item !== undefined && item !== '');
    
                        this.setState({
                            tableData: res.res.data,
                            filterData: res.res.data,
                            dropdown_id: tempArr,
                            dropdown_number: finalName,
                            showtable: true,
                            fetching: false,
                            currentPage: 1,
                            loading: false
                        }, () => this.handlePagination());
                    })
                    .catch(err => console.log(err));
            }, 5000); // 5000 milliseconds = 5 seconds
        }
    }
    
    onBack = (input) => {
        this.setState({
            [input]: false,
            SearchJSON: []
        }, () => this.fetchTableData());
        // window.location.reload();
    }
    

    handleDropdownID = (id) => {
        console.log(id)
        // const NAME = e.target.name
        const VALUE = id

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                documentID: VALUE
            }
        }, () => this.handleFilterID())
    }

    handleFilterID = () => {
        const tempArr = []

        this.state.filterData.map(obj => {
            if(obj['Vendor ID'] === this.state.formData.documentID){
                tempArr.push(obj)
            }
        })

        this.setState({
            ...this.state,
            tableData: tempArr
        }, () => this.handlePagination())

        console.log(tempArr)
    }

    handleDropdownName = (name) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                documentName: name
            }
        }, () => this.handleFilterName())
    }

    handleFilterName = () => {
        const tempArr = []

        this.state.filterData.map(obj => {
            if(obj['Vendor Name'] === this.state.formData.documentName){
                tempArr.push(obj)
            }
        })

        this.setState({
            ...this.state,
            tableData: tempArr
        }, () => this.handlePagination())
    }

    handleCompanyChange = (val) => {
        this.setState({
            ...this.state,
            showtable: false,
            formData: {
                ...this.state.formData,
                task: '',
                orgname: val
            }
        })
    }

  render() {
    if(this.state.redirectVM){
        return(
            <VR2_Forms
            screenName = {"VendorMaster"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectVM")}   
            />        
        )
    }

    if(this.state.redirectPO){
        return(
            <VR2_Forms
            screenName = {"PurchaseOrder"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectPO")}          
            />        
        )
    }

    if(this.state.redirectIM){
        return(
            <VR2_Forms
            screenName = {"ItemMaster"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectIM")}          
            />        
        )
    }

    if(this.state.redirectIP){
        return(
            <VR2_Forms
            screenName = {"InternalPlan"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectIP")}          
            />        
        )
    }

    if(this.state.redirectOM){
        return(
            <VR2_Forms
            screenName = {"OrganizationMaster"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectOM")}          
            />        
        )
    }

    if(this.state.redirectBE){
        return(
            <VR2_Forms
            screenName = {"BillExtract"}
            screenAction = {"approval"}  
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {() => this.onBack("redirectBE")}          
            />        
        )
    }

    if(this.state.redirectGS){
        return(
            <VR2_Forms
            screenName={"GoodsAndServicesEntries"}
            screenAction={"approval"}
            From_SearchJSON={this.state.SearchJSON}
            onBack={() => this.onBack("redirectGS")}/>
        )
    }

    if(this.state.redirectVP){
        return(
            <VR2_Forms
            screenName={"VendorPreferences"}
            screenAction={"approval"}
            From_SearchJSON={this.state.SearchJSON}
            onBack= {() => this.onBack("redirectVP")}
            />
        )
    }

    if(this.state.redirectItemPre){
        return(
            <VR2_Forms
            screenName={"ItemPreferences"}
            screenAction={"approval"}
            From_SearchJSON={this.state.SearchJSON}
            onBack={() => this.onBack("redirectItemPre")}
            />
        )
    }

    if(this.state.redirectBudgetRelease){
        return(
            <VR2_Forms
            screenName={"BudgetRelease"}
            screenAction={"approval"}
            From_SearchJSON={this.state.SearchJSON}
            onBack={() => this.onBack("redirectBudgetRelease")}
            />
        )
    }

    return (
      <Fragment>

        <br/>

        <Form style={{display:'flex', flexDirection:'row'}}>
            <div className='col-lg-2' style={{fontWeight:'bold'}} >Company Name:</div>
            <Selector1 options={this.state.dropdown_orgname} onChange={this.handleCompanyChange} />
        </Form>

        <Form style={{display:'flex', flexDirection:'row'}}>
            <div className='col-lg-2' style={{fontWeight:'bold'}}>What Approvals Do You Want To See?</div>
            <Selector1 options={['Approved', 'Waiting For Approval', 'Rejected']} onChange={this.handleChange}/>

        </Form>    

        <br/>

        {this.state.showtable && this.state.tableData.length ?

            <div style={{display:'flex', flexDirection:'row'}}>
                <div className='col-lg-2' style={{fontWeight:'bold'}}>Select By ID</div>
                <Selector1 options={this.state.dropdown_id} onChange={this.handleDropdownID}>

            </Selector1>
            </div>

             : null }

        {this.state.showtable && this.state.tableData.length ?

        <div style={{display:'flex', flexDirection:'row'}}>
        <div className='col-lg-2' style={{fontWeight:'bold'}}>Select By Name</div>
        <Selector1 options={this.state.dropdown_number} onChange={this.handleDropdownName}/>
        </div>

        : null }

        <br/>

        {this.state.showtable && this.state.tableData.length ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        {/* <th>S.No</th> */}
                        <th>Document ID</th>
                        <th>Organization ID</th>
                        {/* <th>Due Date</th> */}
                        <th>Type</th>
                        <th>Name</th>
                        <th>Process Code</th>
                        <th>Process Name</th>
                        <th>LOB</th>
                        <th>Function</th>
                        <th>Activity Type</th>
                        <th>Activity Code</th>
                        <th>Activity Name</th>
                        <th>In Period From</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.tableDisplay.map((item, index) => {
                        return(
                            <tr key={index}>
                                {/* <td>{index+1}</td> */}
                                <td><Button variant='link' size='sm' onClick={() => this.handleID(item)}>{item['Vendor ID']}</Button></td>
                                <td><Button variant='link' size='sm' onClick={() => this.handleID(item)}>{item['organizationid']}</Button></td>
                                <td>{item['Type']}</td>
                                <td>{item['Vendor Name']}</td>
                                <td>{item.processcode}</td>
                                <td>{item.processname}</td>
                                <td>{item.lob}</td>
                                <td>{item['Department']}</td>
                                <td>{item.activitytype}</td>
                                <td>{item.activitycode}</td>
                                <td>{item.activityname}</td>
                                <td>{item.inprdfrom}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {this.state.loading ?
        <div className='center'>
            <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
        </div> : null}

        <br/>

        {this.state.tableData.length ===0 && !this.state.fetching ? <div className='center-screen'><h5>No Data Found</h5></div> : null}

        {!this.state.fetching && this.state.showtable && this.state.tableData.length ?
        <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.tableData.length} paginate={this.paginate}></Pagination1> : null}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        userData: state.login.userData
    }
}

export default connect(mapStateToProps)(Approval)