import React, { Component } from "react";

class CountdownTimer extends Component {
  state = {
    seconds: 300, // 5 minutes in seconds
  };

  intervalId = null;

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        seconds: prevState.seconds - 1,
      }), () => {
        if (this.state.seconds === 0) {
          this.props.timeOut(true);
          clearInterval(this.intervalId);
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
    //   <div>
        <span>
          {minutes.toString().padStart(2, "0")}:{remainingSeconds
            .toString()
            .padStart(2, "0")}
        </span>
    //   </div>
    );
  }
}

export default CountdownTimer;
