import React, {
  Component,
  PureComponent
} from 'react';
import {
  Table
} from 'react-bootstrap';
import tableData from './tableData';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';
import w3 from './../assets/w3'
import Api from '../config.json';
import {
  getCurrentDate,
  checkValidDate,
  getStandardDate,
  getYearFromDate
} from '../globalhelper/helper';
import { setSelectedObj } from "../redux/FormInputs/formActions"
import { toggleVRScreenMode } from "../actions/treeAction"
import { connect } from 'react-redux';


class MyTable extends PureComponent {
  state = {
    tableRowData: tableData,
  };

  downloadExcel = (treenode) => {
    // console.log(treenode.collection, treenode.Holder)
    var formData = this.props.data;
    formData.Holder = treenode.Holder
    formData.External_ID = formData.id
    formData.tenantId = formData.tenant_id
    formData.acc_column = formData.Holder
    formData.ledger = "IFRS"
    if (treenode.collection === "Lease") {
      axios.post(Api.externals.serverUrl + 'api/lease/getExcelFromLease', formData, {
          responseType: 'arraybuffer'
        })
        .then(result => {
          var FileSaver = require('file-saver');
          var blob = new Blob([result.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          FileSaver.saveAs(blob, treenode.short_text + '.xlsx');
        })
    } else if (treenode.collection === "Finance") {
      axios.post(Api.externals.serverUrl + 'api/lease/exportAccounttExcel', {
          formData: formData
        }, {
          responseType: 'arraybuffer'
        })
        .then(result => {
          var FileSaver = require('file-saver');
          var blob = new Blob([result.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          FileSaver.saveAs(blob, treenode.short_text + '.xlsx');
        })
    }
  }


  selectionFromVRScreen = (row) => {
    // console.log(row);
    if(this.props.store.treeReducer.selection_mode)
    {
      this.props.toggleVRScreenMode();
      this.props.setSelectedObj(row)
    }
  }

  getLableOutput = (value) => {
    // console.log(value, typeof value);
    if (typeof value === "string") {
      // console.log(checkValidDate(value), value)
      if (checkValidDate(value) && getYearFromDate(getStandardDate(value)) <= 9999  && getYearFromDate(getStandardDate(value)) >= 1800) {
        return value.split("T")[0] === "1800-01-01" || value.split("T")[0] === "2100-12-31" ? "" : getStandardDate(value) 
      } else {
        return value
      }
    }  else if(typeof value === "number")
    {
      if(Number.isInteger(value))
      {
        return <p className="number-format"> { value.toFixed(2)} </p>;
      }
      else{
        var d = Math.round((value + Number.EPSILON) * 1000) / 1000
        return <p className="number-format"> { Number(d).toLocaleString() } </p>
      }
    } else if (typeof value === "date") {
      return value.split("T")[0]
    }
  }

  sortTable(tableId, n) {
    // console.log(n,'Index');
    w3.sortHTML("#" + tableId, '.sortable', 'td:nth-child(' + Number(n+1) + ')')
  }
  render() {
    if(this.props.tableData){
      // return (
      //   <div>
      //     {
      //         this.props.tableData.children.map((treenode, index) => {
      //         if (treenode.type === 'section' && treenode.view === 'table') {
      //           if (treenode.headerView === 'h') {
      //             headerLength = treenode.children.length
      //             return (
      //              <div key={index} className="table-container"> <div className="section-title">{treenode.short_text}</div>
      //             <Table striped bordered hover>
      //               <thead>
      //                 <tr style={{ whiteSpace : 'nowrap' }}>
      //                   {
      //                     treenode.children.map((childrenNode, index) => {
      //                       if (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children')  && childrenNode.enable === true) {
      //                         return (<th key={index} colSpan={childrenNode.type === 'group' ? childrenNode.children.length : null} rowSpan={childrenNode.type === 'field' ? 2 : null}>{childrenNode.title}</th>)
      //                       } else if (childrenNode.type === 'field'  && childrenNode.enable === true) {
      //                         return (<th key={index} rowSpan={2}>{childrenNode.title}</th>)
      //                       }
  
      //                     })
      //                   }
      //                 </tr>
      //                 <tr>
      //                   {
      //                     treenode.children.map((childrenNode, index) => {
      //                       if (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children') && childrenNode.enable === true) {
      //                         return childrenNode.children.map((grandchild, index) => {
      //                           return (
      //                             <th key={index}>{grandchild.title}</th>
      //                           )
      //                         })
      //                       }
      //                     })
      //                   }
      //                 </tr>
      //               </thead>
      //               <tbody>
      //                 {this.props.formData.map((data) => {
      //                   if(data[treenode.title])
      //                   {
      //                     if(data[treenode.title].length >  0)
      //                     {
      //                       data[treenode.title].map((d, idx) => {
      //                         return (
      //                           <tr key={idx}> <td  align="justify"> Hari </td> </tr>)
      //                       })
      //                     }
      //                   }
      //                   else{
      //                     return(
      //                       <tr>
      //                         <td colSpan={ headerLength } align="justify"> No Data Found </td>
      //                       </tr>
      //                     )
      //                   }
      //                 })
  
      //                 } 
      //               </tbody>
      //             </Table></div>)
  
      //           }
      //           else {
      //             return (<div key={index} className="table-container"> <div className="section-title">{treenode.short_text}</div><Table striped bordered hover>
      //               <tbody>
      //               {treenode.children.map((childrenNode, index) => {
      //                 if (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children') && childrenNode.enable === true) {
      //                   return childrenNode.children.map((grandchild, index) => {
                          
      //                     if (index === 0 && childrenNode.enable === true) {
      //                       return (<tr key={index}><th rowSpan={childrenNode.type === 'group' ? childrenNode.children.length : null}>{childrenNode.title}</th><th> {grandchild.title}</th>{
      //                         this.props.formData.map((data, index) => {
      //                           return (<td key={index}>{this.getLableOutput(data[grandchild.serviceKey])}</td>)
      //                         })
      //                       }</tr>)
      //                     } else {
      //                       return (<tr><th> {grandchild.title} </th> {
      //                         this.props.formData.map((data, index) => {
      //                           return (<td key={index}>{this.getLableOutput(data[grandchild.serviceKey])}</td>)
      //                         })
      //                       }</tr>)
      //                     }
      //                   })
      //                 } else if (childrenNode.type === 'field' && childrenNode.enable === true) {
      //                   return (<tr key={index}><th colSpan={2}>{childrenNode.title}</th>{
      //                     this.props.formData.map((data, index) => {
      //                       return (<td key={index}>{data[childrenNode.serviceKey]}</td>)
      //                     })
      //                   }</tr>)
      //                 }
      //               })
      //               }
      //               </tbody>
      //             </Table></div>)
      //           }
      //         }
      //       })
      //     // })
  
      //     }
      //   </div>
      // )

        return ( 
          <div>

            {this.props.tableData.children.map((treenode, index) => 
              (treenode.type === 'section' && treenode.view === 'table' && treenode.enable) ?
              <div key={index + 1}>
                <div key={index} className="table-container"> 
                <div className="row">
                  <div className="col-md-10">
                    <div className="section-title">{treenode.short_text}</div>
                    
                   {/* <pre> <code>  { JSON.stringify(treenode.title)} </code> </pre>  */}
                  </div>
                  <div className="col-md-2" align="right">
                  { treenode.downloadable ? 
                      // <button className="btn btn-info" onClick={ () => this.downloadExcel(treenode) } >
                      //   <GetAppIcon /> 
                      // </button>
                      <div>
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button "
                          className="download-table-xls-button btn btn-info"
                          table={treenode.title}
                          filename={ treenode.title.split("_").join(" ") }
                          sheet="tablexls"
                          buttonText={<GetAppIcon /> }/>
                      </div>
                      : null}
                    <br/>
                    <br/>
                  </div>

                </div>
                { treenode.headerView === 'h' ? 
                  <div className="scroll-table">
                  {/* <p> {treenode.title} </p> */}
                    <Table id={treenode.title} className="BootstrapTable">
                      <thead>
                        <tr>
                        {treenode.children.map((childrenNode, ix) => 
                              (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children')  && childrenNode.enable === true) ?
                                <th key={ix} onClick={ () => this.sortTable(treenode.title, ix) } style={{ cursor:'pointer' }} colSpan={childrenNode.type === 'group' ? childrenNode.children.length : null} rowSpan={childrenNode.type === 'field' ? 2 : null}>{childrenNode.title}</th>
                              :
                              (childrenNode.enable === true) ?
                              <th key={ix} onClick={ () => this.sortTable(treenode.title, ix) } style={{cursor : 'pointer'}} rowSpan={2}>{childrenNode.title }</th>
                              : null
                              )
                            }
                        </tr>
                        <tr>
                          { treenode.children.map((childrenNode, index) => 
                          (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children') && childrenNode.enable === true) ? 
                               childrenNode.children.map((grandchild, ix) => 
                                  <th  onClick={ () => this.sortTable(treenode.title, ix) }  key={ix}>{grandchild.title}</th>
                              )
                              : null
                          )
                          }
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.formData.map((data,key) => 
                        (data[treenode.title]) ?
                          (data[treenode.title].length >  0) ?
                            data[treenode.title].map((d, idx) => {
                              return (
                              <tr key={idx} onClick={() => this.selectionFromVRScreen(d)} className={ this.props.store.treeReducer.selection_mode  ? "sortable selectable-row" : "sortable" }>
                                {treenode.children.map( (childrenNode,id) => {
                                  return (
                                    childrenNode.enable ? 
                                          <td key={id} style={{ textAlign : typeof d[childrenNode.serviceKey] === "number" ? "right" : "left" }}> { this.getLableOutput(d[childrenNode.serviceKey]) } </td> 
                                          : null
                                          )}
                                  )
                                }
                                  </tr>
                                  )
                                  })
                                  // }
                                : 
                                <tr key={key}>
                                  <td colSpan={ treenode.children.length } style={{ textAlign : "center" }} align="justify"> <strong> No Data Found </strong> </td>
                                </tr>
                              : 
                              <tr key={key}>
                                <td colSpan={ treenode.children.length } align="justify"> No Data Found </td>
                              </tr>
                            )
                        }
                  </tbody>
                  </Table>
                  {/* className="scroll-table" */}
                </div>
                : <div key={index} className="table-container"> 
                <div className="section-title">{treenode.short_text}</div>
                <Table striped bordered hover id={ treenode.title } className="BootstrapTable">
                  <tbody>
                  {treenode.children.map((childrenNode, index) => 
                      (childrenNode.type === 'group' && childrenNode.hasOwnProperty('children') && childrenNode.enable === true) ?
                        childrenNode.children.map((grandchild, index) => 
                          
                          (index === 0 && childrenNode.enable === true) ?
                            <tr className={ this.props.store.treeReducer.selection_mode  ? "sortable selectable-row" : "sortable" } key={index}><th rowSpan={childrenNode.type === 'group' ? childrenNode.children.length : null}>{childrenNode.title}</th><th> {grandchild.title}</th>{
                              this.props.formData.map((data, index) => 
                                <td key={index} style={{ textAlign : typeof data[grandchild.serviceKey] === "number" ? "right" : "left" }} >{this.getLableOutput(data[grandchild.serviceKey])}</td>
                            )
                            }</tr>
                          :
                            (<tr className={ this.props.store.treeReducer.selection_mode  ? "sortable selectable-row" : "sortable" }><th> {grandchild.title} </th> {
                              this.props.formData.map((data, index) => 
                               <td key={index} style={{ textAlign : typeof data[grandchild.serviceKey] === "number" ? "right" : "left" }} >{this.getLableOutput(data[grandchild.serviceKey])}</td>
                              )
                            }</tr>)
                        )
                       : (childrenNode.type === 'field' && childrenNode.enable === true) ? 
                        <tr className={ this.props.store.treeReducer.selection_mode  ? "sortable selectable-row" : "sortable" } key={index}><th colSpan={2}>{childrenNode.title}</th>
                        {this.props.formData.map((data, index) => 
                          childrenNode.enable ?
                            <td key={index} style={{ textAlign : typeof data[childrenNode.serviceKey] === "number" ? "right" : "left" }}>{this.getLableOutput(data[childrenNode.serviceKey])}</td>
                            : null
                          )
                        }</tr>
                        : null
                      
                    )
                    }
                    </tbody>
                  </Table></div>
                }
              </div>
          </div>
          : null
        )
        }
        </div>
        )
    }else{
      return(<div>Loading.....</div>)
    }    
  }
}

const mapStateToProps = (state) => {
  return {
    store : state
  }
};

// export default  Transactions;

export default connect(
  mapStateToProps, { setSelectedObj, toggleVRScreenMode }
)(MyTable);