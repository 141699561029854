import React from "react";
import { renderCustomComponent, toggleMsgLoader } from "react-chat-widget";

import { Button } from "react-bootstrap";

// import { useDispatch } from "react-redux";

import ViewLogic from "./ViewLogic";

import { connect } from 'react-redux';
// import { navUrl } from "../actions/common_actions";
// import { toggleVRScreenMode } from "../actions/treeAction";
// import { getStandardDate } from "../globalhelper/helper";
// import { TextField } from "@material-ui/core";

function ButtonGroup(props) {
  // const dispatch = useDispatch();


  // const getScreen = () => {
  //   // useHistory('/home')
  //   dispatch(toggleVRScreenMode())
  //   dispatch(navUrl('/home/' + getStandardDate(props.store.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom) + "/" + getStandardDate(props.store.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo)));
  // }

  return (
    <div className="row">
      <div className="col-md-12">
      <h4 className="text-info">{props.title}</h4>
      { props.options && props.options.length > 0 ?
        props.options.map((option, index) => (
          option.parent_short_text !== "welcome" ?
            option.parent_type === "Option" ?
              <Button
                variant="outline-info"
                className="m-1"
                style={{ borderRadius: "15px" }}
                key={props.title + "_" + option.parent_name + Math.random()}
                value={option.parent_short_text}
                name={option.parent_name}
                onClick={(e) => {
                  toggleMsgLoader();
                  // dispatch(fetchFromDialogflow(e.target.value)).then((data) => {
                  toggleMsgLoader();
                  var name = e.target.name;
                  var value = e.target.value;
                  renderCustomComponent(() => {
                    if (name && value) {
                      return (<ViewLogic options={option.children} showTitle short_text={option.parent_short_text} type={option.parent_type} intent={name} value={value} />)
                    }
                    else {
                      return null;
                    }
                  });
                  // });
                }}
              >
                {option.parent_short_text}
              </Button>
              :
              option.parent_type === "Intent" && props.options.length === 1 ?
                <ViewLogic options={option.children} short_text={props.options.length === 1 ? props.options[0].parent_short_text : ""} showTitle type={option.parent_type} intent={option.parent_name} value={option.parent_short_text} />
                :
                props.store.chatbotFormInputs.objectSelected && (option.parent_type === "BOTPost" || option.parent_type === "BOTGet")
                  ?
                  <Button
                    variant="primary"
                    className="m-1"
                    style={{ borderRadius: "15px" }}
                    key={props.title + "_" + option.parent_name + Math.random()}
                    value={option.parent_short_text}
                    name={option.parent_name}
                    onClick={(e) => {
                      toggleMsgLoader();
                      toggleMsgLoader();
                      var name = e.target.name;
                      var value = e.target.value;
                      renderCustomComponent(() => {
                        if (name && value) {
                          return (<ViewLogic options={option.children} name={option.parent_name} short_text={option.parent_short_text} type={option.parent_type} intent={name} value={value} />)
                        }
                        else {
                          return null;
                        }
                      });
                    }}
                  >
                    Next
                  </Button>
                  : option.parent_type === "Parameter" && index === 0
                    ? <div className="row">
                      <ViewLogic type="Parameter" options={props.options} />
                    </div>
                    :
                    option.parent_type === "BOTInput" && index === 0 ?
                      <div className="row">
                        <ViewLogic type="BOTInput" options={props.options} />
                      </div>
                      :
                      option.parent_type === "BOTOutput" && index === 0 ?
                        <div className="row">
                          <ViewLogic type="BOTOutput" options={props.options} />
                        </div>
                        : null
            : null
        )
        )
        : null
      }
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    store: state
  }
};

// export default  Transactions;

export default connect(
  mapStateToProps
)(ButtonGroup);

// export default ButtonGroup;
