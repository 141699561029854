import React, { Component, Fragment } from 'react';
import { getHumanReadableDateTime, getRequest, postRequest } from '../../globalhelper/helper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import DataTable from '../../components/DataTable2';


class EventHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData: {
                device_id : '',
                period_from : '',
                period_to : ''
            },
            DEVICEIDList : [],
            data : [],
            columns : [
                {
                    name: 'S No',
                    selector: row => row['S. No.'],
                    sortable: true,
                    width : '60px'
                },
                {
                    name: 'Device ID',
                    selector: row => row['Device ID'],
                    sortable: true,
                },
                {
                    name: 'Status',
                    selector: row => row['Status'],
                    sortable: true,
                },
                {
                    name: 'Device Error Code',
                    selector: row => row['Device Error Code'],
                    sortable: true,
                },
                {
                    name: 'Device Model',
                    selector: row => row['Device Model'],
                    sortable: true,
                },
                {
                    name: 'Local Head Office',
                    selector: row => row['Local Head Office'],
                    sortable: true,
                },
                {
                    name: 'Owner',
                    selector: row => row['Owner'],
                    sortable: true,
                },
                {
                    name: 'Event Device',
                    selector: row => row['Event Device'],
                    sortable: true,
                },
                {
                    name: 'Start Date',
                    selector: row => getHumanReadableDateTime(row['Start Date']),
                    sortable: true,
                },
                {
                    name: 'End Date',
                    selector: row => getHumanReadableDateTime(row['End Date']),
                    sortable: true,
                },
                {
                    name: 'Duration',
                    selector: row => row['Duration'],
                    sortable: true,
                },
                {
                    name: 'Event Status Desc.',
                    selector: row => row['Event Status Desc.'],
                    sortable: true,
                },
                {
                    name: 'Location Type',
                    selector: row => row['Location Type'],
                    sortable: true,
                },
                {
                    name: 'Location',
                    selector: row => row['Location'],
                    sortable: true,
                },
                {
                    name: 'ATS',
                    selector: row => row['ATS'],
                    sortable: true,
                },
                {
                    name: 'SLM',
                    selector: row => row['SLM'],
                    sortable: true,
                },
            ],
            showData : false,
            fetching : false,
         }
    }

    handleChange = (ev) => {
        const { formData } = this.state;
        formData[ev.target.name] = ev.target.value;
        if(ev.target.name === 'device_obj'){
            formData['device_id'] = ev.target.value.value;
        }
        this.setState({ formData });
    }

    onDEVICEIDChange = (device_id) => {
        console.log(device_id);
    }

    componentDidMount = () => {
        getRequest('api/cms/getEventHistoryDEVICEList').then((res) => {
            if (res.type === "success") {
                this.setState({ DEVICEIDList : JSON.parse(res.res.data) });
            }
        })

    }

    
  setDate = (date,field) =>{
  
      this.setState({
        ...this.state,
        formData : {
          ...this.state.formData,
          [field] : date,
        }
      })
    }

    onFetchData = () => {
        this.setState({
            ...this.state,
            fetching : true,
            showData : false,
            data : []
        }, () => {
        postRequest('api/cms/getEventHistoryForDeviceAndPeriod',this.state.formData).then(res => {
            if(res.type === "success")
            {
                this.setState({
                    ...this.state,
                    data : JSON.parse(res.res.data),
                    showData : true,
                    fetching : false,
                })
            }
        })
        })
    }

    render() { 
        return (  
            <div>
                <form name="event_history_form" defaultValue={this.state.formData}>
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="device_obj">Device ID</label>
                            <Select
                                value={this.state.formData.device_obj}
                                onChange={(value) => this.handleChange({ target : { name : 'device_obj', value } })}
                                options={this.state.DEVICEIDList}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_from">Period From</label>
                            <DatePicker selected={this.state.formData.period_from} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_from') } id="period_from" />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_to">Period To</label>
                            <DatePicker selected={this.state.formData.period_to} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_to') } id="period_to" />
                        </div>
                        <div className="col-md-2">
                            <button type='button' disabled={ this.state.formData.device_id === "" || this.state.formData.period_from === "" || this.state.formData.period_to === "" || this.state.fetching } className='btn btn-info' style={{ marginTop : 33 }} onClick={this.onFetchData} >Fetch Data</button>
                        </div>
                    </div>
                </form>
                <br /><br />
                <Fragment>
                    <DataTable title="Event History" loading={this.state.fetching} columns={this.state.columns} key={this.state.data} data={this.state.data} />
                </Fragment>
            </div>
        );
    }
}
 
export default EventHistory;