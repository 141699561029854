import React, { useEffect, useState } from "react";

import { Form, Spinner, Col, Row } from "react-bootstrap";

import { useDispatch, connect } from "react-redux";
import { getRequest } from "../globalhelper/helper";
import { setFormValue } from '../redux/FormInputs/formActions';

// import {
//   selectApp,
//   selectProcess,
//   fetchProcessArray,
//   fetchProcessDefn,
//   getTemplate,
// } from "../redux";

// import menu from "./arrayrefs";

// import CsvFileUploadCard from "./CsvFileUploadCard";
// import Template from "./Template";

const SelectForm = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(['Please Wait'])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {

    if(!props.options.includes("()"))
    {
      console.log(props.options.includes("()"))
      setFetching(false);
    }

    if(props.options.includes("()"))
    {
      switch (props.options) {
        case 'GetStationCdFromTT()' : 
          getRequest('api/transactions/GetStationCdFromTT?client=' + props.chatbotFormInputs.OCLCT1_BOTInput_Client +'&client_model=' + props.chatbotFormInputs.OCLCT1_BOTInput_ClientModel + '&src=' + props.chatbotFormInputs.selectedObj.Type).then(data => {
            // if(data.status === "success")
            // {
              setOptions(data.res.data)
              setFetching(false);
            // }
          })
        break;
        default : 
          setOptions(props.options.split(","));
        }
      }
      else{
        setOptions(props.options.split(","));
    }
  }, [])

  return (
    // <Form className="mt-3">
    // {/* <Form.Row> */ }
    !fetching ?
      <>
        <Form.Group
          as={Row}
          // style={{ width: '15vw', marginLeft : 15 }}
          lg="12"
          controlId={props.modelName}
        >
          <Form.Label column lg="4" style={{ whiteSpace : 'nowrap', overflow : 'hidden' }}>{props.label}</Form.Label>
          <Col>
            <Form.Control
              lg="8"
              className="text-info border-info col-md-12"
              as="select"
              onChange={(ev) => {
                dispatch(setFormValue(props.modelName, ev.target.value))
              }}
              defaultValue={props.chatbotFormInputs[props.modelName] ? props.chatbotFormInputs[props.modelName] : "choose"}
              name={props.modelName}
            >
              <option value="choose" disabled>Choose...</option>
              {options.map(option =>
                <option value={option}>{option}</option>
              )}
            </Form.Control>
          </Col>
        </Form.Group >
      </>
      :
      <div className="row" style={{ width: '15vw', marginLeft : 15, marginTop : 15 }}>
        <p>Please Wait...</p>
        <Spinner  animation="border" />
      </div>
      // {/* </Form.Row> */ }
    // {/* {templateAvailable && <Template />}
    //       {app && process && !processDefnLoading && <CsvFileUploadCard />} */}
    // </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    chatbotFormInputs: state.chatbotFormInputs
  };
};

export default connect(mapStateToProps)(SelectForm);
