import React, { Component, useState  } from 'react';
import ApiConstants from "../../config";
import './style.css'
import Spinner from "react-bootstrap/Spinner";
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Tooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, FormCheck,FormFile, Col  } from 'react-bootstrap'
import { postRequest } from '../../globalhelper/helper'
import {
  BrowserRouter as Router,
  Switch, 
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)


class PoList extends Component {

  constructor (props){
    super(props);
    this.state = 
    {
      alert : '',
      selectedExternal_id : '',
      showDetailsModal : false,
      tooltipOpen : false,
      formData : {
        process : '',
        fromdate : new Date('2019-04-01'),
        todate : new Date('2020-03-31')
      },
      poReceived : false,
      grnReceived : false,
      podetails : {
        ERP_MatchCnt: 0,
        ERP_MatchVal: 0,
        ERP_TotCnt: 0,
        ERP_TotVal: 0,
        HIS_MatchCnt: 0,
        HIS_MatchVal: 0,
        HIS_TotCnt: 0,
        HIS_TotVal: 0,
        TOT_TotCnt: 0,
        TOT_TotVal: 0,
      },
      grndetails : {
        ERP_MatchGRNCnt: 0,
        ERP_MatchGRNVal: 0,
        ERP_TotGRNCnt: 0,
        ERP_TotGRNVal: 0,
        HIS_MatchGRNCnt: 0,
        HIS_MatchGRNVal: 0,
        HIS_TotGRNCnt: 0,
        HIS_TotGRNVal: 0,
        TOT_TotGRNCnt: 0,
        TOT_TotGRNVal: 0,
      },
     
    }
  }

  
  handleChange = (event) => {
    console.log(event);
    var id = [event.target.id];

    this.setState( {
        formData: {
              ...this.state.formData,
              [id]: event.target.value
        }
    })

}

getGrnDetails = () =>{
  var formdata = this.state.formData;

  if(formdata.fromdate !== "" && formdata.todate !== "") 
  {
    postRequest( 'api/po/getGrnDashboard', formdata)
    .then(result => {
      if(result.data.data.length > 0)
      {
        this.setState({
          ...this.state,
          grndetails : {
            ERP_MatchGRNCnt: result.data.data[0].ERP_MatchGRNCnt,
            ERP_MatchGRNVal: result.data.data[0].ERP_MatchGRNVal,
            ERP_TotGRNCnt: result.data.data[0].ERP_TotGRNCnt,
            ERP_TotGRNVal: result.data.data[0].ERP_TotGRNVal,
            HIS_MatchGRNCnt: result.data.data[0].HIS_MatchGRNCnt,
            HIS_MatchGRNVal: result.data.data[0].HIS_MatchGRNVal,
            HIS_TotGRNCnt: result.data.data[0].HIS_TotGRNCnt,
            HIS_TotGRNVal: result.data.data[0].HIS_TotGRNVal,
            TOT_TotGRNCnt: result.data.data[0].TOT_TotGRNCnt,
            TOT_TotGRNVal: result.data.data[0].TOT_TotGRNVal,
          },
          grnReceived : true
        },() => {
          console.log(this.state.grndetails)
        })
      }
    })
    .catch(err => console.log(err))
  }
}

  
  handleSubmit = () => {
    var formdata = this.state.formData;
    this.setState({
      ...this.state,
      poReceived : false,
      grnReceived : false,
    })
    if(formdata.fromdate !== "" && formdata.todate !== "") 
    {
      postRequest( 'api/po/getPoDashboard', formdata)
      .then(result => {
        this.getGrnDetails()
        if(result.data.data.length > 0)
        {
          this.setState({
            ...this.state,
            podetails : {
              ERP_MatchCnt: result.data.data[0].ERP_MatchCnt,
              ERP_MatchVal: result.data.data[0].ERP_MatchVal,
              ERP_TotCnt: result.data.data[0].ERP_TotCnt,
              ERP_TotVal: result.data.data[0].ERP_TotVal,
              HIS_MatchCnt: result.data.data[0].HIS_MatchCnt,
              HIS_MatchVal: result.data.data[0].HIS_MatchVal,
              HIS_TotCnt: result.data.data[0].HIS_TotCnt,
              HIS_TotVal: result.data.data[0].HIS_TotVal,
              TOT_TotCnt: result.data.data[0].TOT_TotCnt,
              TOT_TotVal: result.data.data[0].TOT_TotVal,
            },
            poReceived : true
          },() => {
            console.log(this.state.podetails)
          })
        }
      })
      .catch(err => console.log(err))
    }
}

componentDidMount = () =>{
  if(this.state.formData.fromdate !== '' && this.state.formData.todate !== '')
  {
    this.handleSubmit();
  }
}

openDetailsModal = () =>{
  this.setState({
    ...this.state,
    showDetailsModal : true
  })
}

setDate = (date,field) =>{

  this.setState({
    ...this.state,
    formData : {
      ...this.state.formData,
      [field] : date
    }
  },() => {
    console.log(this.state)
  })
}

togleToolTip = () =>{
  this.setState({
    ...this.state,
    tooltipOpen : !this.state.tooltipOpen
  })
}

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div>
        <div className="row">
          <div className='col-md-3'>
              <label>From</label> <br/>
                <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
          </div>
          

          <div className='col-md-3'>
              <label>To</label><br/>
              <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
          </div>
          

          <div className='col-md-3 text-center' style={{ marginTop : '25px' }}>
            <button className="btn btn-teal" type="button" onClick={ this.handleSubmit }  > Load </button>
          </div>

        </div>
      <br/>
      <br/>

      { this.state.poReceived || this.state.grnReceived  ?
        <>
          <div className="row text-center" align="center">
            <div className="col-md-4 tile-holder" align="center">
              <h6>Total PO Released</h6>
              <h3> { this.state.podetails.ERP_TotCnt +  ' | ₹ '+ this.state.podetails.ERP_TotVal.toFixed(0) }</h3>
            </div>
              <div className="col-md-4 tile-holder" align="center">
              <h6>Total Goods Received</h6>
              <h3> { this.state.grndetails.HIS_TotGRNCnt +  ' | ₹ '+ this.state.grndetails.HIS_TotGRNVal.toFixed(0) }</h3>
              </div>
            <div className="col-md-4 tile-holder" align="center">
              <h6>Difference</h6>
              <h3> { (this.state.podetails.ERP_TotCnt - this.state.grndetails.HIS_TotGRNCnt) +  ' | ₹ '+ ((this.state.podetails.ERP_TotVal - this.state.grndetails.HIS_TotGRNVal)).toFixed(0)  }</h3>
            </div>
          </div>
        <br/>
        <br/>

          <div className="row text-center" align="center">


            <div className="col-md-4 tile-holder-yellow" align="center">
              <h3>ERP</h3>
              <div className="row flex-nowrap">
                <div className="col-md-1">
                  <h6>Cat.</h6>
                </div>
                <div className="col-md-4">
                  <h6>#</h6>
                </div>
                <div className="col-md-7">
                <h6>Status</h6>
                </div>
              </div>
              <br/>
              <div className="row flex-nowrap">             
                <div className="col-md-1">
                  <p className="text-black" style={{marginBottom : '0px'}}>PO</p>
                </div>
                <div className="col-md-4">
                  <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.podetails.TOT_TotCnt }</p>
                  <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.podetails.TOT_TotVal.toFixed(0) }</p>
                </div>
                <div className="col-md-7">
                    <ProgressBar >


                      <ProgressBar animated max={this.state.podetails.ERP_TotCnt} striped label={this.state.podetails.ERP_MatchCnt && this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_MatchCnt : ''} variant="success" now={this.state.podetails.ERP_MatchCnt && this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_MatchCnt : ''  } onClick={() => this.openDetailsModal()} id="TooltipExample" key={1} /> <br/>
                      {/* <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.togleToolTip}>
                        Matched PO's
                        <ul>
                          <li>Hari</li>
                          <li>Hari</li>
                        </ul>
                      </Tooltip> */}


                      <ProgressBar animated max={this.state.podetails.ERP_TotCnt} label={ this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt : ''} variant="danger" now={this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt : ''} key={2} />
                    </ProgressBar>
                    {/* </div> */}
                <div className="row">
                  <div className="col-md-6" align="left">
                      <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.podetails.ERP_MatchVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-6" align="right">
                      <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.podetails.ERP_TotVal -  this.state.podetails.ERP_MatchVal).toFixed(0) }</p>
                  </div>
                </div>
                </div>
              </div>
              <br/>
              <div className="row flex-nowrap">
                <div className="col-md-1">
                  <p className="text-black" style={{marginBottom : '0px'}}>GRN</p>
                </div>
                <div className="col-md-4">
                  <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.grndetails.ERP_TotGRNCnt }</p>
                  <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.grndetails.ERP_TotGRNVal.toFixed(0) }</p>
                </div>
                <div className="col-md-7">


                <ProgressBar >
                      <ProgressBar animated max={this.state.grndetails.ERP_TotGRNCnt} striped label={this.state.grndetails.ERP_MatchGRNCnt && this.state.grndetails.ERP_MatchGRNCnt > 0 ? this.state.grndetails.ERP_MatchGRNCnt : ''} variant="success" now={this.state.grndetails.ERP_MatchGRNCnt && this.state.grndetails.ERP_MatchGRNCnt > 0 ? this.state.grndetails.ERP_MatchGRNCnt : ''  }   key={1} />

                      <ProgressBar animated max={this.state.grndetails.ERP_TotGRNCnt} label={ this.state.grndetails.ERP_TotGRNCnt - this.state.grndetails.ERP_MatchGRNCnt > 0 ? this.state.grndetails.ERP_TotGRNCnt - this.state.grndetails.ERP_MatchGRNCnt : ''} variant="danger" now={this.state.grndetails.ERP_TotGRNCnt - this.state.grndetails.ERP_MatchGRNCnt > 0 ? this.state.grndetails.ERP_TotGRNCnt - this.state.grndetails.ERP_MatchGRNCnt : ''} key={2} />
                </ProgressBar>


                <div className="row">
                  <div className="col-md-6" align="left">
                      <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.grndetails.ERP_MatchGRNVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-6" align="right">
                      <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.grndetails.ERP_TotGRNVal -  this.state.grndetails.ERP_MatchGRNVal).toFixed(0) }</p>
                  </div>
                </div>
                </div>
              </div>
            </div>



            <div className="col-md-4 tile-holder-yellow" align="center">
              <h3>HIS</h3>
              <div className="row flex-nowrap">
                <div className="col-md-1">
                  <h6>Cat.</h6>
                </div>
                <div className="col-md-4">
                  <h6>#</h6>
                </div>
                <div className="col-md-7">
                <h6>Status</h6>
                </div>
              </div>
              <br/>
              <div className="row flex-nowrap">             
                <div className="col-md-1">
                  <p className="text-black" style={{marginBottom : '0px'}}>PO</p>
                </div>
                <div className="col-md-4">
                  <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.podetails.HIS_TotCnt }</p>
                  <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.podetails.HIS_TotVal.toFixed(0) }</p>
                </div>
                <div className="col-md-7">
                <ProgressBar >


                  <ProgressBar  className="highlight-bar" animated max={this.state.podetails.HIS_TotCnt} striped label={this.state.podetails.HIS_MatchCnt && this.state.podetails.HIS_MatchCnt > 0 ? this.state.podetails.HIS_MatchCnt : ''} variant="success" now={this.state.podetails.HIS_MatchCnt && this.state.podetails.HIS_MatchCnt > 0 ? this.state.podetails.HIS_MatchCnt : ''  }   key={1} /> <br/>


                  <ProgressBar animated max={this.state.podetails.HIS_TotCnt} label={ this.state.podetails.HIS_TotCnt - this.state.podetails.HIS_MatchCnt > 0 ? this.state.podetails.HIS_TotCnt - this.state.podetails.HIS_MatchCnt : ''} variant="danger" now={this.state.podetails.HIS_TotCnt - this.state.podetails.HIS_MatchCnt > 0 ? this.state.podetails.HIS_TotCnt - this.state.podetails.HIS_MatchCnt : ''} key={2} />
                </ProgressBar>


                <div className="row">
                  <div className="col-md-6" align="left">
                      <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.podetails.HIS_MatchVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-6" align="right">
                      <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.podetails.HIS_TotVal -  this.state.podetails.HIS_MatchVal).toFixed(0) }</p>
                  </div>
                </div>


                </div>
              </div>
              <br/>
              
              <div className="row flex-nowrap">             
                <div className="col-md-1">
                  <p className="text-black" style={{marginBottom : '0px'}}>GRN</p>
                </div>
                <div className="col-md-4">
                  <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.grndetails.HIS_TotGRNCnt }</p>
                  <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.grndetails.HIS_TotGRNVal.toFixed(0) }</p>
                </div>
                <div className="col-md-7">
                <ProgressBar >


                  <ProgressBar animated className="highlight-bar" max={this.state.grndetails.HIS_TotGRNCnt} striped label={this.state.grndetails.HIS_MatchGRNCnt && this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_MatchGRNCnt : ''} variant="success" now={this.state.grndetails.HIS_MatchGRNCnt && this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_MatchGRNCnt : ''  }   key={1} /> <br/>


                  <ProgressBar animated max={this.state.grndetails.HIS_TotGRNCnt} label={ this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt : ''} variant="danger" now={this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt : ''} key={2} />
                </ProgressBar>
                
                <div className="row">
                  <div className="col-md-6" align="left">
                      <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.grndetails.HIS_MatchGRNVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-6" align="right">
                      <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.grndetails.HIS_TotGRNVal -  this.state.grndetails.HIS_MatchGRNVal).toFixed(0) }</p>
                  </div>
                </div>

                </div>
              </div>
            </div>


              <div className="col-md-4 tile-holder-yellow" >
                <h3>Recon</h3>
                <div className="row flex-nowrap">
                  <div className="col-md-1">
                    <h6>Cat.</h6>
                  </div>
                  <div className="col-md-4">
                    <h6>#</h6>
                  </div>
                  <div className="col-md-7">
                  <h6>Status</h6>
                  </div>
                </div>
                <br/>
                <div className="row flex-nowrap">             
                  <div className="col-md-1">
                    <p className="text-black" style={{marginBottom : '0px'}}>PO</p>
                  </div>
                  <div className="col-md-4">
                    <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.podetails.TOT_TotCnt }</p>
                    <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.podetails.TOT_TotVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-7">
                  <ProgressBar >


                    <ProgressBar animated max={this.state.podetails.ERP_TotCnt} striped label={this.state.podetails.ERP_MatchCnt && this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_MatchCnt : ''} variant="success" now={this.state.podetails.ERP_MatchCnt && this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_MatchCnt : ''  }   key={1} /> <br/>


                    <ProgressBar animated max={this.state.podetails.ERP_TotCnt} label={ this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt : ''} variant="danger" now={this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt > 0 ? this.state.podetails.ERP_TotCnt - this.state.podetails.ERP_MatchCnt : ''} key={2} />
                  </ProgressBar>
                  <div className="row">
                    <div className="col-md-6" align="left">
                        <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.podetails.ERP_MatchVal.toFixed(0) }</p>
                    </div>
                    <div className="col-md-6" align="right">
                        <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.podetails.ERP_TotVal -  this.state.podetails.ERP_MatchVal).toFixed(0) }</p>
                    </div>
                  </div>
                  </div>
                </div>
                <br/>
              <div className="row flex-nowrap">             
                <div className="col-md-1">
                  <p className="text-black" style={{marginBottom : '0px'}}>GRN</p>
                </div>
                <div className="col-md-4">
                  <p className="text-black" style={{marginBottom : '0px'}}>{ this.state.grndetails.HIS_TotGRNCnt }</p>
                  <p className="text-black" style={{marginBottom : '0px'}}>{ '₹ ' + this.state.grndetails.HIS_TotGRNVal.toFixed(0) }</p>
                </div>
                <div className="col-md-7">
                <ProgressBar >


                  <ProgressBar animated max={this.state.grndetails.HIS_TotGRNCnt} striped label={this.state.grndetails.HIS_MatchGRNCnt && this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_MatchGRNCnt : ''} variant="success" now={this.state.grndetails.HIS_MatchGRNCnt && this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_MatchGRNCnt : ''  }   key={1} /> <br/>


                  <ProgressBar animated max={this.state.grndetails.HIS_TotGRNCnt} label={ this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt : ''} variant="danger" now={this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt > 0 ? this.state.grndetails.HIS_TotGRNCnt - this.state.grndetails.HIS_MatchGRNCnt : ''} key={2} />
                </ProgressBar>
                
                <div className="row">
                  <div className="col-md-6" align="left">
                      <p className="text-black" style={{marginBottom : '0px' , marginTop : '5px'}}>{ "₹ " + this.state.grndetails.HIS_MatchGRNVal.toFixed(0) }</p>
                  </div>
                  <div className="col-md-6" align="right">
                      <p className="text-black" style={{marginBottom : '0px', marginTop  : '5px'}}> ₹ { (this.state.grndetails.HIS_TotGRNVal -  this.state.grndetails.HIS_MatchGRNVal).toFixed(0) }</p>
                  </div>
                </div>

                </div>
              </div>
              </div>

          </div>
          <br/>
          <br/>
          </>
          : null }
      </div>
    );
  }
}

export default  PoList;
