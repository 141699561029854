import React, { Component } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

export class Rodal_popup extends Component {
  render() {
    return (
        <Rodal visible={this.props.show} onClose={this.props.hide}>
            {this.props.popupcontent}
        </Rodal>    
        )
  }
}

export default Rodal_popup;