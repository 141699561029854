import {
  FETCH_DIALOGFLOW_REQUEST,
  FETCH_DIALOGFLOW_SUCCESS,
  FETCH_DIALOGFLOW_FAILURE,
} from "./dialogflowTypes";
import { selectApp, dataLoad, fetchProcessArray, fetchMenuItems } from "../../index";
import { postRequest } from '../../../globalhelper/helper'
import store from '../../../store';


export const fetchFromDialogflow = (query) => {
  // console.log("query: " + query);
  return async (dispatch) => {
  // new Promise(async (resolve, reject) => {
      dispatch(fetchDialogflowRequest());
      try {

        // **** -----> Fetching From Dialogflow <------- ****** //
        return postRequest('api/chatbot/textQuery', {text : query}).then(async (response) => {
          // console.log(response, "Harish");
          var data = response.res.data;
          // console.log(data);
          dispatch(fetchDialogflowSuccess(data));
          if (data.intent === "SelectApp") {
            localStorage.setItem("module", data.value)
            dispatch(selectApp(data.value));
            dispatch(fetchProcessArray(data.value));
            // dispatch(fetchMenuItems(data.value));
            if(data)
            {
              return data;
            }
            else{
              return {
                intent : "",
                value : ""
              };
            }
          } else if (data.intent === "DataLoadOptions") {
            dispatch(dataLoad(data.value));
            return data;
          }
          // return response.res.data;
        });


        // **** -----> Fetching From Store <------- ****** //

        // const currentStore = store.getState();
        // var nextSet = currentStore.selectApp.chatItems[0].find(({short_text}) => short_text === query);
        // console.log(nextSet, "New Set");
        // return nextSet;

      } catch (error) {
        // console.log(error, "Error Catch")
        dispatch(fetchDialogflowFailure(error.details));
        return {
          intent : "",
          value : ""
        }
      }
    // })
  };
};

export const fetchDialogflowRequest = () => {
  return {
    type: FETCH_DIALOGFLOW_REQUEST,
  };
};

export const fetchDialogflowSuccess = (data) => {
  return {
    type: FETCH_DIALOGFLOW_SUCCESS,
    payload: data,
  };
};

export const fetchDialogflowFailure = (error) => {
  return {
    type: FETCH_DIALOGFLOW_FAILURE,
    payload: error,
  };
};
