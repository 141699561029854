/*
 src/actions/simpleAction.js
*/
import _ from 'lodash';
import data from '../pages/Dynamic_forms/sample2.json'; 
import store from '../store';

export const ADD_CHILDREN = "ADD_CHILDREN";
export const CHANGE_FORM_VALUES = "CHANGE_FORM_VALUES";
export const INITIAL = "INITIAL";

// export function isNullOrEmpty (value) {
//     return value === "" || value === undefined || value === null
// }

export function isNullOrEmpty (value, operator) {

    if(operator === "EQ") return value === "" || value === undefined || value === null
    if(operator === "NE") return value !== "" && value !== undefined && value !== null
}


// export function compileOperators(dependency, formValues){
//     dependency.map((dep, ind) => {
//         dep.satisfied = false;
//         if(dep.operator === "EQ")
//         {
//             dep.satisfied = formValues[dep.lookFor] === (dep.value === "" ? isNullOrEmpty(formValues[dep.lookFor]) : dep.value);
//         }
//         else if(dep.operator === "NE")
//         {
//             dep.satisfied = formValues[dep.lookFor] !== (dep.value === "" ? isNullOrEmpty(formValues[dep.lookFor]) : dep.value);
//         }
//     })
//     return dependency.every(item => item.satisfied === true);
// }

export function compileOperators (dependency, formValues) {

    dependency.map((dep, ind) => {
        dep.satisfied = false;

        if(dep.operator === "EQ")
        {
            dep.satisfied = dep.value === "" ?  isNullOrEmpty(formValues[dep.lookFor], dep.operator) : formValues[dep.lookFor] === dep.value
        }
        else if(dep.operator === "NE")
        {
            console.log(dep, formValues[dep.lookFor], isNullOrEmpty(formValues[dep.lookFor], dep.operator)  )
            dep.satisfied = dep.value === "" ? isNullOrEmpty(formValues[dep.lookFor], dep.operator) : formValues[dep.lookFor] !== dep.value
        }
    })
    return dependency.every(item => item.satisfied === true);
}


export function checkDependency (dependency) {
    const state = store.getState();
    const formValues = state.Dynamic_Forms_Reducer.formValues;
    return compileOperators(dependency, formValues);
}

export function initialState () {
    console.log("initialState")
    return(dispatch) => {
        dispatch({
            type : INITIAL
        })
    }
}

export function add_UJ_JSON (json) {
    console.log("UJ JSON")
    return(dispatch) => {
        dispatch({
            type : ADD_CHILDREN,
            UJ : json
        })
    }
}

export function addChildren (parent_id) {
    console.log('addChildren callling')

    const state = store.getState();
    const UJ_JSON = state.Dynamic_Forms_Reducer.UJ_JSON;

    const children = _.map(UJ_JSON, (item) => {
        if(item.parentId === parent_id)
        {
            if(item.dependency && item.dependency.length)
            {
                if(Boolean(checkDependency(item.dependency)))
                {
                    item.active = true;
                }
                else{
                    item.active = false;
                }
            }
            else{

                item.active = true;
            }
        }
        return item;
    });
    console.log('addChildren callling', children)

    return(dispatch) => {
        dispatch({
            type : ADD_CHILDREN,
            UJ : children
        })
    }
}

export function changeFormValues(formValues, parent_id) {
    console.log(formValues)
    return(dispatch) => {
        // dispatch(getDropdownOptions(parent_id))
        dispatch({
            type : CHANGE_FORM_VALUES,
            formValues : formValues
        })
    }
}


export function getDropdownOptions(parent_id){
    const state = store.getState();
    const UJ_JSON = state.Dynamic_Forms_Reducer.UJ_JSON;
    let options = _.filter(UJ_JSON, item => {
        if(item.type === "dropdown-option" && item.parentId === parent_id)
        {
            if(item.dependency && item.dependency.length)
            {
                return checkDependency(item.dependency);
            }
            else{
                return true;
            }
        }
        else{
            return false;
        }
    });
    const children = _.map(UJ_JSON, (item) => {
        if(item.id === parent_id && item.type === "select")
        {
            item.options = options;
        }
        return item;
    });
    return(dispatch) => {
        dispatch({
            type : ADD_CHILDREN,
            UJ : children
        })
    }
}


export function changeDependencyValues(parent_id){
    const state = store.getState();
    const UJ_JSON = state.Dynamic_Forms_Reducer.UJ_JSON;
    // let children = _.filter(data, item => item.type !== "dropdown-option" && item.type !== "Screen_Found" && item.parentId === parent_id);
    let children = _.filter(UJ_JSON, item => item.type === "select" && item.parentId === parent_id);
    children.map((child) => {
        getDropdownOptions(child.id)
    })
}