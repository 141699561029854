import React, { useEffect, useState } from 'react'
import '../CI/COD.css';
import { getRequest, getHumanReadableDate, postRequest } from "../../globalhelper/helper";
import {Button, Form} from 'react-bootstrap'
import {CSVLink} from 'react-csv'

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const EmployeeCostReport = () => {

    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        type: '',
        company: ''
    })

    const [companyDetails, setCompanyDetails] = useState([])

    const [companyName, setCompanyName] = useState([])

    const [queryResult, setQueryResult] = useState([])

    const [loading, setLoading] = useState(false)

    const [showError, setShowError] = useState(false)

    const [warningMessage, setWarningMessage] = useState('')

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    const handleSubmit = () => {
        const {fromDate, toDate, type, company} = formData

        if(fromDate && toDate && type && company){
            setLoading(true)
            setShowError(false)

            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            console.log(companyid)

            getRequest(`api/edureka/getEmployeeCostReport?startDate=${fromDate}&endDate=${toDate}&type=${type}&company=${companyid[0]}`).then(response => {
                console.log(response.res.data)
                const responseData = JSON.parse(response.res.data)
                if(responseData.length){
                    setQueryResult(responseData)
                    setLoading(false)
                } else {

                    setLoading(false)
                    setShowError(true)
                    setWarningMessage("No data found, please try some other combination")
                }
            }).catch(err => {
                setLoading(false)
            })
        } else {
            setShowError(true)
            setWarningMessage("Please select all the required fields")
        }

    }

    const handleChange = (e) => {
        console.log(e.target.value)
        const {name, value} = e.target
        console.log(name, value)
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            type: '',
            company: ''
        })
        setQueryResult([])
        setLoading(false)
        setShowError(false)
    }

  return (
    <>
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' name='company' value={formData.company} onChange={handleChange}>
                        <option value='' disabled>Select</option>
                        {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="From Date" name="fromDate" value={formData.fromDate}  onChange={handleChange}/>
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="To Date" name="toDate" value={formData.toDate}  onChange={handleChange}/>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <select className='spacing' name='type' value={formData.type} onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            <option value='detailed'>Detailed</option>
                            <option value='summary'>Summary</option>
                        </select>
                    </Form.Group>
                </div>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size="sm" onClick={handleSubmit} variant="success">Submit</Button>
                <Button size="sm" onClick={handleReset} style={{marginLeft:'10px'}} variant="danger">Reset</Button>
                {queryResult.length ?
                <CSVLink data={queryResult} filename='Employee Cost Report.csv' style={{marginLeft:'10px'}} >Download</CSVLink> :  null}
            </div>
        </form>

        {!loading && queryResult.length && formData.type === 'detailed' ?
        <div>
            <table border='1' id='table-css'>
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Account Type</th>
                        <th>Account Group</th>
                        <th>Account Class</th>
                        <th>Account Code</th>
                        <th>Ledger Name</th>
                        <th style={{maxWidth:'50px'}}>Amount (INR)</th>
                    </tr>
                </thead>
                <tbody>
                    {queryResult.slice(0, 100).map((item, index) => (
                        <tr key={index}>
                            <td>{item.createdid}</td>
                            <td>{item.accounttype}</td>
                            <td>{item.accountgroup}</td>
                            <td>{item.accountclass}</td>
                            <td>{item.accountcode}</td>
                            <td>{item.ledgername}</td>
                            <td style={{textAlign:'right'}}>{Number(item.amountinlocalcurrency ? item.amountinlocalcurrency : 0).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div> : null}

        {!loading && queryResult.length && formData.type === 'summary' ?
        <div>
            <table border='1' id='table-css'>
                <thead>
                    <tr>
                        <th>Account Type</th>
                        <th>Account Group</th>
                        <th>Account Class</th>
                        <th>Account Code</th>
                        <th>Ledger Name</th>
                        <th style={{maxWidth:'50px'}}>Amount (INR)</th>
                    </tr>
                </thead>
                <tbody>
                    {queryResult.map((item, index) => (
                        <tr key={index}>
                            <td>{item.accounttype}</td>
                            <td>{item.accountgroup}</td>
                            <td>{item.accountclass}</td>
                            <td>{item.accountcode}</td>
                            <td>{item.ledgername}</td>
                            <td style={{textAlign:'right'}}>{Number(item.amountinlocalcurrency ? item.amountinlocalcurrency : 0).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div> : null}

        {showError &&
         <div className="center-screen">
            {warningMessage}
        </div>}

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
            </div>: null}
    </>
  )
}

export default EmployeeCostReport