import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper'
import DataTable from '../../components/DataTable2';



export class Amortize_Bill_Report extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        info : {
            ForPrdFrom : '',
            ForPrdTo : '',
            status : '',
        },
        CSV_columns : [{
            name: 'Bill Number',
            selector: row => row['Bill Number'],
            sortable: true,
            },
            {
            name: 'Unit Period',
            selector: row => row['Unit Period'],
            sortable: true,
            },
            {
            name: 'BaseValue Unitval',
            selector: row => row['BaseValue Unitval'],
            sortable: true,
            },
            {
            name: 'ForPrd From',
            selector: row => row['ForPrd From'],
            sortable: true,
            },
            {
            name: 'ForPrd To',
            selector: row => row['ForPrd To'],
            sortable: true,
            },
            {
            name: 'Exp Amortize Date',
            selector: row => row['Exp Amortize Date'],
            sortable: true,
            },
            {
            name: 'Vendor ID',
            selector: row => row['Vendor ID'],
            sortable: true,
            },
            {
            name: 'Vendor Name',
            selector: row => row['Vendor Name'],
            sortable: true,
            },
            {
            name: 'Invoice Ref',
            selector: row => row['Invoice Ref'],
            sortable: true,
            },
            {
            name: 'Item ID',
            selector: row => row['Item ID'],
            sortable: true,
            },
        ],
        CSV_ARRAY : [],
        isLoading : false
      }
    }


    componentDidMount(){
    }


    handleChange = (e) => {
        let id = e.target.name
        let value = e.target.value

        console.log(id, value)

        this.setState({
            ...this.state,
            info: {
                ...this.state.info,
                [id]: value
            }
        })
    }

    
    getReport = (e) => {
        e.preventDefault()

        this.setState({ isLoading : true }, () => {

            postRequest('api/edureka/get_Amortize_Bill_Report', this.state.info)
            .then( res => {
                if( res.res.status === "success"){
    
                    let DATA = JSON.parse(res.res.data)
    
                    console.log(DATA)
    
                    this.setState({ CSV_ARRAY : DATA })
                    
                }

                this.setState({ isLoading : false})
            })

        })

    }


  render() {
    return (
    <div>
        <div className='row'>

            <div className='col-md-3'>
                <label htmlFor='ForPrdFrom'>Accounting Dt From:</label>
                <input id='ForPrdFrom' name='ForPrdFrom' type='date' onChange={this.handleChange} />
            </div>

            <div className='col-md-3'>
                <label htmlFor='ForPrdTo'>Accounting Dt To:</label>
                <input id='ForPrdTo' name='ForPrdTo' type='date' onChange={this.handleChange} />
            </div>

            <div className='col-md-3'>
                <label htmlFor='status'>Status:</label>
                <select id='status' name='status' onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Not Released'>Not Released</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Void'>Void</option>
                    <option value='Waiting For Approval'>Waiting For Approval</option>
                </select>
            </div>

            <div className='col-md-3'>
                <button className='btn btn-info' disabled={!this.state.info.ForPrdFrom || !this.state.info.ForPrdTo } onClick={this.getReport}>Get Report</button>
            </div>

        </div>
        <div>

            <Fragment>
                <DataTable title="Amortize Bill Report" loading={this.state.isLoading} columns={this.state.CSV_columns} key={this.state.CSV_ARRAY} data={this.state.CSV_ARRAY} />
            </Fragment> 

        </div>
    </div>
    )
  }
}

export default Amortize_Bill_Report;