import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from "../../globalhelper/helper";
import DataTableS3Download from "../../components/DataTableS3Download";
import { connect } from 'react-redux';
import { navUrl } from '../../actions/common_actions';



class ReconciliationSummary extends Component {
    constructor(props) {
        super(props)
        this.currentState = this.props.state;
        this.userData = this.currentState.login.userData;
    
      this.state = {
        s3Key: 'key',
        input : '',
        input_option : [],
        data : [],
        fetching : false,
        columns : [
                { name: 'LegalEntity', selector: row => row['LegalEntity'], sortable: true },
                { name: 'TAN', selector: row => row['TAN'], sortable: true },
                { name: 'ReconStatus', selector: row => row['ReconStatus'], sortable: true },
                { name: 'GroupRef', selector: row => row['GroupRef'], sortable: true },
                { name: 'Invoice', selector: row => row['Invoice'], sortable: true },
                { name: 'Sales', selector: row => row['Sales'], sortable: true },
                { name: 'F26ASAmtCredited', selector: row => row['F26ASAmtCredited'], sortable: true },
                { name: 'TDSR', selector: row => row['TDSR'], sortable: true },
                { name: 'F26ASTDSDeposited', selector: row => row['F26ASTDSDeposited'], sortable: true },
                { name: 'RevDiff', selector: row => row['RevDiff'], sortable: true },
                { name: 'TDSDiff', selector: row => row['TDSDiff'], sortable: true },
                { name: 'ReconStage', selector: row => row['ReconStage'], sortable: true }
                ]
      }
    }

    componentDidMount() {

        getRequest('api/tds/getTxnentity')
        .then(res => {
            let data = JSON.parse(res.res.data)
            console.log(data)

            let input_option = data.map( each => each.txnentity )

            this.setState({ input_option : input_option })
        })
        .catch(err => {
            console.log(err)
        })
    }

    
    getDATA = () => {
        
        this.setState({
                    fetching: true,
                    data: [],
                    s3Key: 'key',
                    // columns: []
                },
            () => {
                getRequest('api/tds/getSummaryReport?input=' + this.state.input)
                .then(res => {
                    let data = JSON.parse(res.res.data.data)
                    console.log(data)

                    // let KEYS = Object.keys(data[0])
                    // console.log(KEYS)

                    // let columns = []

                    // KEYS.forEach( each => {
                    //     columns.push({
                    //         name: each,
                    //         selector: row => row[each],
                    //         sortable: true
                    //         // width : '250px',
                    //     })
                    // })
    
                    this.setState({
                        data: data,
                        fetching: false,
                        s3Key : res.res.data.S3Location
                        // columns: columns
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ fetching : false })
                })
            })
    }


    onInputChange = (e) => {
        this.setState({
            input: e.target.value,
            data : [],
            // columns: []
        }, 
        () => this.getDATA()
        )
    }


            
  render() {
    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-2'>
                <label htmlFor="input">Company</label>
                    <select name="input" id="input" style={{width: '9rem', borderRadius: '20%'}} value={this.state.input} onChange={this.onInputChange}>
                        <option value="" disabled>select</option>
                        {this.state.input_option.map( each => <option key={each} value={each}>{each}</option>)}
                    </select>
                </div>
            </div>

            <div className="row mt-2">           
                <div className="col-md-12">
                    <DataTableS3Download columns={this.state.columns} title="Reconciliation Summary Report" loading={this.state.fetching} key={this.state.data} data={this.state.data} s3Key={this.state.s3Key} TenantID={this.userData.USR_Tenant} ></DataTableS3Download>
                </div>
            </div>
        </Fragment>
    )
  }
}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(ReconciliationSummary);