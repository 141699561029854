// import React, { Component, Fragment } from 'react'
// import {Form, Button} from 'react-bootstrap';
// import { getRequest, postRequest } from '../../globalhelper/helper';
// import '../CI/COD.css';
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

// export class BillExtract_Search extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//          formData: {
//             vendorname: '',
//             orgname: '',
//             billnumber: ''
//          },
//          showtable: false,
//          tableData: [],
//          dropdown_vendorname: [],
//          dropdown_orgname: [],
//          dropdown_billnumber: [],
//          companyDetails: [],
//          error: false,
//          errorMessage: ''
//       }
//     }

//     componentDidMount(){
//         localStorage.setItem("pageName", "Search Bill Extract")

//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             console.log(arr)
//             this.setState({
//                 ...this.state,
//                 dropdown_orgname: arr,
//                 companyDetails: responseData
//             })
//         }).catch(err => console.log(err))
//     }

//     handleOrgChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         }, () => this.handleRemainingValues())
//     }

//     handleRemainingValues = () => {
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/billExtractInfo?orgid=${companyid[0]}`).then(res => {
//             console.log(res.res.data)
//             if(res.res.status === 'success'){
//                 const temp = JSON.parse(res.res.data)
//                 const vendorname = temp.map(value => value['Vendor Name'])
//                 const billnumber = temp.map(val => val.documentnumber)
//                 this.setState({
//                     ...this.state,
//                     dropdown_vendorname: [...new Set(vendorname)],
//                     dropdown_billnumber: [...new Set(billnumber)]
//                 })
//             } else {
//                 console.log('No Data')
//             }
//         })
//     }

//     handleChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         })
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             tableData: [],
//             error: false,
//             errorMessage: '',
//             formData: {
//                 vendorname: '',
//                 orgname: '',
//                 billnumber: ''
//             }
//         })
//     }

//     handleSearch = () => {

//         if(this.state.formData.orgname){

//             this.setState({
//                 ...this.state,
//                 error: false,
//                 tableData: []
//             })

//             const {vendorname, billnumber} = this.state.formData

//             const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//             const companyid = id.map(item => item.orgid)

//             const request = {
//                 name: vendorname,
//                 orgid: companyid[0],
//                 billnumber: billnumber
//             }
    
//             postRequest(`api/edureka/getBillExtractSearchData`, request).then(res => {
//                     const temp = JSON.parse(res.res.data)

//                     if(temp.length){
//                         this.setState({
//                             ...this.state,
//                             tableData: temp,
//                             showtable: true
//                         })
//                     } else {
//                         this.setState({
//                             ...this.state,
//                             error: true,
//                             errorMessage: "No data found. Please try some other combination"
//                         })
//                     }
                
//             })
//         } else {
//             this.setState({
//                 ...this.state,
//                 error: true,
//                 errorMessage: "Please select all the required fields"
//             })
//         }

//     }

//     handleBill = (i) => {
//         const billid = i['Bill Number']

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getBillExtractUpdatedJSON?billid=${billid}&orgid=${companyid[0]}`).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({ redirect : true, SearchJSON : res.res.data })                
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     handleInterimID = (i) => {

//         const interimid = i.interimid

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getInterimBill?interimid=${interimid}&orgid=${companyid[0]}`).then(res => {
//             if(res.res.status === 'success'){
//                 this.setState({
//                     ...this.state,
//                     redirect: true,
//                     SearchJSON: res.res.data
//                 })
//             } else {
//                 console.log("No data")
//             }
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"BillExtract"}
//             screenAction ={"edit"}
//             From_SearchJSON = {this.state.SearchJSON}
//             onBack = {this.onBack}
//             />
//         )
//     }
//     else 
//     return (
//       <Fragment>
//         <div className='header_div'>
//             Search Bill
//         </div>

//         <br/>

//         <Form style={{display:'flex',flexDirection:'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
//                 <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
//                 <select className='col-lg-3' name='vendorname' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Bill Number:</div>
//                 <select className='col-lg-3' name='billnumber' value={this.state.formData.billnumber} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_billnumber.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <br/>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='primary' onClick={this.handleSearch}>Search</Button>
//                 <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {this.state.showtable && this.state.tableData.length  ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>Bill Number</th>
//                         <th>Interim ID</th>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         <th>Date</th>
//                         <th>Status</th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b)=>a['Bill Number'].localeCompare(b['Bill Number'])).map((item, index) => {
//                         return (
//                             <tr key={index}>
//                                 <td><Button size='sm' variant='link' disabled={item.Status === 'Rejected'} onClick={()=>this.handleBill(item)}>{item['Bill Number']}</Button></td>
//                                 <td><Button size='sm' variant='link' disabled={item.Status === 'Active' || item.Status === 'Waiting For Approval'} onClick={() => this.handleInterimID(item)}>{item.interimid}</Button></td>
//                                 <td>{item['Vendor ID']}</td>
//                                 <td>{item['Vendor Name']}</td>
//                                 <td>{item['Date']}</td>
//                                 <td>{item['Status']}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div>: null}

//         {this.state.error ?
//         <div className='center-screen'>
//             {this.state.errorMessage}
//         </div> : null}
//       </Fragment>
//     )
//   }
// }

// export default BillExtract_Search


import React, { Component, Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select'; // Import React Select
import { getRequest, postRequest } from '../../globalhelper/helper';
import '../CI/COD.css';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class BillExtract_Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        vendorname: '',
        orgname: '',
        billnumber: ''
      },
      showtable: false,
      tableData: [],
      dropdown_vendorname: [],
      dropdown_orgname: [],
      dropdown_billnumber: [],
      companyDetails: [],
      error: false,
      errorMessage: '',
      redirect: false,
      SearchJSON: []
    };
  }

  componentDidMount() {
    localStorage.setItem("pageName", "Search Bill Extract");

    getRequest(`api/edureka/getCompanyAssignedToUser`)
      .then((response) => {
        const responseData = response.res.data;
        const arr = responseData.map((item) => ({
          label: item.orgname,
          value: item.orgname
        }));
        this.setState({
          dropdown_orgname: arr,
          companyDetails: responseData
        });
      })
      .catch((err) => console.log(err));
  }

  handleOrgChange = (selectedOption) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          orgname: selectedOption ? selectedOption.value : ''
        }
      },
      () => this.handleRemainingValues()
    );
  };

  handleRemainingValues = () => {
    const { orgname } = this.state.formData;
    const companyDetail = this.state.companyDetails.find((item) => item.orgname === orgname);

    if (companyDetail) {
      getRequest(`api/edureka/billExtractInfo?orgid=${companyDetail.orgid}`)
        .then((res) => {
          if (res.res.status === 'success') {
            const temp = JSON.parse(res.res.data);
            const vendornameOptions = temp.map((value) => ({
              label: value['Vendor Name'],
              value: value['Vendor Name']
            }));
            const billnumberOptions = temp.map((val) => ({
              label: val.documentnumber,
              value: val.documentnumber
            }));
            this.setState({
              dropdown_vendorname: [...new Set(vendornameOptions)],
              dropdown_billnumber: [...new Set(billnumberOptions)]
            });
          } else {
            console.log('No Data');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (selectedOption, actionMeta) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [actionMeta.name]: selectedOption ? selectedOption.value : ''
      }
    });
  };

  handleReset = () => {
    this.setState({
      showtable: false,
      tableData: [],
      error: false,
      errorMessage: '',
      formData: {
        vendorname: '',
        orgname: '',
        billnumber: ''
      }
    });
  };

  handleSearch = () => {
    if (this.state.formData.orgname) {
      this.setState({
        error: false,
        tableData: []
      });

      const { vendorname, billnumber } = this.state.formData;
      const companyDetail = this.state.companyDetails.find((item) => item.orgname === this.state.formData.orgname);

      if (companyDetail) {
        const request = {
          name: vendorname,
          orgid: companyDetail.orgid,
          billnumber: billnumber
        };

        postRequest(`api/edureka/getBillExtractSearchData`, request)
          .then((res) => {
            const temp = JSON.parse(res.res.data);

            if (temp.length) {
              this.setState({
                tableData: temp,
                showtable: true
              });
            } else {
              this.setState({
                error: true,
                errorMessage: "No data found. Please try some other combination"
              });
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "Please select all the required fields"
      });
    }
  };

  handleBill = (i) => {
    const billid = i['Bill Number'];
    const companyDetail = this.state.companyDetails.find((item) => item.orgname === this.state.formData.orgname);

    if (companyDetail) {
      getRequest(`api/edureka/getBillExtractUpdatedJSON?billid=${billid}&orgid=${companyDetail.orgid}`)
        .then((res) => {
          if (res.res.status === 'success') {
            this.setState({ redirect: true, SearchJSON: res.res.data });
          } else {
            console.log("No Data");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleInterimID = (i) => {
    const interimid = i.interimid;
    const companyDetail = this.state.companyDetails.find((item) => item.orgname === this.state.formData.orgname);

    if (companyDetail) {
      getRequest(`api/edureka/getInterimBill?interimid=${interimid}&orgid=${companyDetail.orgid}`)
        .then((res) => {
          if (res.res.status === 'success') {
            this.setState({
              redirect: true,
              SearchJSON: res.res.data
            });
          } else {
            console.log("No data");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  onBack = () => {
    this.setState({
      redirect: false,
      SearchJSON: []
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <VR2_Forms
          screenName={"BillExtract"}
          screenAction={"edit"}
          From_SearchJSON={this.state.SearchJSON}
          onBack={this.onBack}
        />
      );
    } else
      return (
        <Fragment>
          <div className='header_div'>
            Search Bill
          </div>

          <br />

          <Form style={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
              <div className="col-lg-2" style={{ marginRight: 15, fontFamily: 'sans-serif', fontSize: '15', fontWeight: 'bold' }}>Organization Name:<span style={{ color: 'red' }}>*</span> </div>
              <Select
                className='col-lg-3'
                value={this.state.dropdown_orgname.find(option => option.value === this.state.formData.orgname)}
                options={this.state.dropdown_orgname}
                onChange={this.handleOrgChange}
                name='orgname'
                styles={{
                                            container: (base) => ({ ...base, padding: 5 }),
                                            control: (base) => ({ 
                                                ...base, 
                                                borderRadius: 5, 
                                                border: '1px solid #ccc', 
                                                minHeight: '38px',  
                                                paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                                            }),
                                            valueContainer: (base) => ({
                                                ...base,
                                                padding: '0 15px', // Add horizontal padding here instead
                                            }),
                                            input: (base) => ({
                                                ...base,
                                                margin: 0,
                                                padding: 0, 
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                margin: 0,
                                                padding: 0,
                                            })
                                        }}
                placeholder='Select'
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
              <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontSize: '15', fontWeight: 'bold' }}>Vendor Name:</div>
              <Select
                className='col-lg-3'
                name='vendorname'
                value={this.state.dropdown_vendorname.find(option => option.value === this.state.formData.vendorname)}
                options={this.state.dropdown_vendorname}
                onChange={this.handleChange}
                styles={{
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    })
                }}
                placeholder='Select'
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
              <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontSize: '15', fontWeight: 'bold' }}>Bill Number:</div>
              <Select
                className='col-lg-3'
                name='billnumber'
                value={this.state.dropdown_billnumber.find(option => option.value === this.state.formData.billnumber)}
                options={this.state.dropdown_billnumber}
                onChange={this.handleChange}
                styles={{
                    container: (base) => ({ ...base, padding: 5 }),
                    control: (base) => ({ 
                        ...base, 
                        borderRadius: 5, 
                        border: '1px solid #ccc', 
                        minHeight: '38px',  
                        paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 15px', // Add horizontal padding here instead
                    }),
                    input: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0, 
                    }),
                    singleValue: (base) => ({
                        ...base,
                        margin: 0,
                        padding: 0,
                    })
                }}
                placeholder='Select'
              />
            </div>

            <br />

            <div style={{ textAlign: 'center' }}>
              <Button variant='primary' onClick={this.handleSearch}>Search</Button>
              <Button style={{ marginLeft: 10 }} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>
          </Form>

          <br />

          {this.state.showtable && this.state.tableData.length ?
            <div>
              <table border='1' id='table_styling'>
                <thead>
                  <tr>
                    <th>Bill Number</th>
                    <th>Interim ID</th>
                    <th>Vendor ID</th>
                    <th>Vendor Name</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.tableData.sort((a, b) => a['Bill Number'].localeCompare(b['Bill Number'])).map((item, index) => {
                    return (
                      <tr key={index}>
                        <td><Button size='sm' variant='link' disabled={item.Status === 'Rejected'} onClick={() => this.handleBill(item)}>{item['Bill Number']}</Button></td>
                        <td><Button size='sm' variant='link' disabled={item.Status === 'Active' || item.Status === 'Waiting For Approval'} onClick={() => this.handleInterimID(item)}>{item.interimid}</Button></td>
                        <td>{item['Vendor ID']}</td>
                        <td>{item['Vendor Name']}</td>
                        <td>{item['Date']}</td>
                        <td>{item['Status']}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> : null}

          {this.state.error ?
            <div className='center-screen'>
              {this.state.errorMessage}
            </div> : null}
        </Fragment>
      );
  }
}

export default BillExtract_Search;
