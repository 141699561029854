import React, { PureComponent } from 'react';
import { storiesOf } from '@storybook/react';
import differenceBy from 'lodash/differenceBy';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import memoize from 'memoize-one';
import DataTable from '../DataTable/DataTable';
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress';
// import { getRequest } from '../../globalhelper/helper'
import { withRouter } from 'react-router';
// import { navUrl } from '../../actions/common_actions'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import  history from '../../history'
// import CustomMaterialMenu from '../shared/CustomMaterialMenu';


const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};



const Export = ({ onExport }) => (
  <Button color="primary" size="small" variant="outlined" onClick={e => onExport(e.target.value)}>Export</Button>
  );
  
  const AddButton = ({ onNavigate }) => (
    <Button color="primary" size="small" variant="contained" onClick={() => onNavigate()}> <Add /> Add</Button>
    );
  
  
  
  class DataTableTemplate extends PureComponent {
    
  constructor(props) {
    super(props)
    this.state = {
      selectedRows: [], 
      toggleCleared: false,
      data: [],
      actions : (
        
        <Grid container alignItems="flex-start" justify="flex-end" direction="row" spacing="2">
          { this.props.enableExport ? 
            <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Export onExport={() => this.downloadCSV(this.props.data)} />
            </Grid>
            : null}
            { this.props.enableAddButton ?
              <Grid item xs={6} sm={6} md={2} lg={2}>
                      <AddButton onNavigate={() => this.onNavigate(this.props.addUrl)} />
              </Grid>
              : null }
        </Grid>
      )
         
    }

    
    this.tempCol = this.props.columns;
    this.columns = memoize(deleteHandler => {
      if(this.props.deleteable)
      {
        return [
          ...this.tempCol,
            {
              cell: row => <IconButton color="secondary" onClick={() => this.props.deleteFunction(row)} > <Delete /> </IconButton>,
              allowOverflow: true,
              button: true,
              width: '66px', 
            },
        ]
      }
      else {
        return this.tempCol;
      }
    }) 
  }
  
  onNavigate = (url) => {
    // this.props.navUrl(url);
    if(this.props.history) this.props.history.push(url);
  }

  editCompany = (id) => {
    const { history } = this.props;
    if(history) history.push('/masters/edit_company/'+ id);
  }


convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  console.log(this.tempCol, "columns");
  const keys = this.tempCol.map(col => col.selector);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
downloadCSV = (array) => {
  const link = document.createElement('a');
  let csv = this.convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = this.props.exportFileName ? this.props.exportFileName + '.csv' : "Report" + '.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}



  
  handleChange = () => {
    this.setState({ selectedRows: this.state.selectedRows });
  };

  handleRowClicked = row => {
    
    console.log(`${row.name} was clicked!`);
  }

  // deleteCompany = (company) => {
  //   console.log(company);
  //   if (window.confirm(`Are you sure you want to delete:\r ${company.name}?`)) {
  //     const { data } = this.state;

  //     getRequest('masters/deleteCompany?id=' + company._id)
  //     .then(result => {
  //       if(result.type === "success")
  //       {
  //         window.location.reload();
  //       }
  //     })

  //     // this.setState(state => ({
  //     //   toggleCleared: !state.toggleCleared,
  //     //   data: [...state.data.slice(0, index), ...state.data.slice(index + 1)],
  //     // }));
  //   }
  // }


  deleteAll = () => {
    const { selectedRows } = this.state;
    const rows = selectedRows.map(r => r.name);
    
    if (window.confirm(`Are you sure you want to delete:\r ${rows}?`)) {
      this.setState(state => ({ toggleCleared: !state.toggleCleared, data: differenceBy(state.data, state.selectedRows, 'name') }));
    }
  }

  deleteOne = row => {
    console.log(row);
    if (window.confirm(`Are you sure you want to delete:\r ${row.name}?`)) {
      const { data } = this.state;
      const index = data.findIndex(r => r === row);

      this.setState(state => ({
        toggleCleared: !state.toggleCleared,
        data: [...state.data.slice(0, index), ...state.data.slice(index + 1)],
      }));
    }
  }

  render() {
    const { data, toggleCleared } = this.state;

    return (
      <Card style={{ height: '100%' }}>
        <DataTable
          title={this.props.name}
          columns={this.columns()}
          data={this.props.data}
          // selectableRows
          highlightOnHover
          defaultSortField={this.props.defaultSortField}
          actions={this.state.actions}
          // contextActions={this.state.contextActions}
          sortIcon={sortIcon}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectProps}
          onSelectedRowsChange={this.handleChange}
          clearSelectedRows={toggleCleared}
          onRowClicked={this.handleRowClicked}
          pagination
          progressPending={!this.props.data}
          progressComponent={<LinearIndeterminate />}
          persistTableHead
        />
      </Card>
    );
  }
}

storiesOf('Material UI', module)
  .add('Action Buttons', () => <DataTableTemplate />);

  const mapStateToProps = (state) => {
    return {
      state
    }
  };


// export default withRouter(DataTableTemplate);
export default connect(
    mapStateToProps,
    // {  navUrl  }
)(DataTableTemplate);