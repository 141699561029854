import React, { Fragment, useEffect, useState } from 'react';
import { getRequest } from '../../globalhelper/helper';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';

const Input = ({ label, type, name, value, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
      </Form.Group>
    </div>
  );
};

const PaymentReport = () => {
  const [tableData, setTableData] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    company: '',
    fromDate: '',
    toDate: ''
  });

  useEffect(() => {
    getRequest('api/edureka/getCompanyAssignedToUser').then((response) => {
      const responseData = response.res.data;
      const orgname = responseData.map((item) => item.orgname);
      setCompanyDetails(responseData);
      setCompanyName(orgname);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleReset = () => {
    setFormData({
      fromDate: '',
      toDate: '',
      company: ''
    });
    setTableData([]);
    setSubmitted(false);
  };

  const handleSubmit = () => {
    const { fromDate, toDate, company } = formData;

    if (fromDate && toDate && company) {
      setLoading(true);
      setSubmitted(true);

      const id = companyDetails.filter((item) => item.orgname === company);
      const companyid = id.map((item) => item.orgid);

      getRequest(`api/edureka/getPaymentReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`)
        .then((response) => {
          const responseData = JSON.parse(response.res.data);
          setLoading(false);
          if (responseData.length) {
            setTableData(responseData);
          } else {
            setTableData([]); // Set empty if no data is found
            toast.warning('No data found, please try some other combination');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.warning('Oops something went wrong');
        });
    } else {
      toast.error('Please select all the required fields', { theme: 'colored' });
    }
  };

  const renderTable = () => {
    if (!tableData.length) return null;

    const headers = Object.keys(tableData[0]); // Dynamically get headers from the first data object

    return (
      <table border='1' id='table-css'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex}>{item[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Fragment>
      <form>
        <div className='row'>
          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                <option value='' disabled>
                  Select
                </option>
                {companyName.sort().map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Input type='date' label='Period From' name='fromDate' value={formData.fromDate} onChange={handleChange} />
          </div>

          <div className='col-md-3'>
            <Input type='date' label='Period To' name='toDate' value={formData.toDate} onChange={handleChange} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Button size='sm' variant='success' onClick={handleSubmit}>
            Submit
          </Button>
          <Button size='sm' variant='danger' onClick={handleReset} style={{ marginLeft: '10px' }}>
            Reset
          </Button>
          {tableData.length ? (
            <CSVLink data={tableData} filename='Payment_Report.csv' style={{ marginLeft: '10px' }}>
              Download
            </CSVLink>
          ) : null}
        </div>
      </form>

      {loading ? (
        <div className='center'>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
        </div>
      ) : submitted && !loading && tableData.length > 0 ? (
        <div>{renderTable()}</div>
      ) : submitted && !loading && tableData.length === 0 ? (
        <div style={{display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center',alignContent:'center', marginTop:'200px'}}>No records to display</div>
      ) : null}

      <ToastContainer />
    </Fragment>
  );
};

export default PaymentReport;
