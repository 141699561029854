import React, { Component, Fragment } from 'react';
import './COD.css'
import FormBasicSelect from './FormBasicSelect';
import LoadingAnimation from '../../components/LoadingAnimation';
import FormWithToolTipSelect from './FormWithToolTipSelect';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {connect} from 'react-redux'
import {Form, Button} from 'react-bootstrap'
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios';
import {Redirect} from 'react-router-dom'
import {getRequest, postRequest, getHumanReadableDate, getHumanReadableDateTime, convertToINR} from '../../globalhelper/helper';

export class COD extends Component {
    constructor(props) {
      super(props)

      this.current = this.props.state

      console.log(this.current)
    
      this.state = {
        formData: {
            clientdata: '',
            clientmodel: '',
            zone: '',
            clientcode: '',
            date: '',
            depositstatus: '',
            slipnumber: '',
            pendingCOD: '',
            depositedCOD: '',
            reasonforshortdeposit: '',
            reason: '',
            selectedFile: null,
            erpfile: null,
            receipt: '', 
            ERPScreenshot: '',
            redirect: false, 
            postedOn: new Date(),
            postedBy: this.current.login.userData.USR_Name
        },
        selectedFile: null,
        erpfile: null,
        show_preview: true,
        show_table: false,
        dropdown_clientdata: [],
        dropdown_clientname: [],
        dropdown_clientmodel: [],
        dropdown_zone: [],
        dropdown_clientcode: [], 
        buttonColor: 'secondary',
        downloadButton: 'disabled',
        erpButton: 'disabled',
        uploadButton: '',
        erpUploadButton: '',
        confirmButtonColor: 'primary',
        fileName: '',
        fileChange: '',
        erpName: '',
        erpChange: '',
        erpColor: 'secondary',
        showWarning: false,
        warning: 'Please select all the required fields'
      }
    }

    getFilteredArray = (value, key) => {
        let dropdown_array = value.map((value) => value[key])
        let filtered_dropdown_array = [...new Set(dropdown_array)]
        return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
    }

    // Function to get all the station details

    componentDidMount () {

        getRequest('api/cod/getAllData').then(response => {
            
            let alldata = JSON.parse(response.res.data)

            let uniqueClient = this.getFilteredArray(alldata, "client_short_name")
            let uniqueStationCode = this.getFilteredArray(alldata, "client_hub_code")

            console.log(uniqueClient)
            console.log(uniqueStationCode)
            
            this.setState({
                ...this.state,
                dropdown_clientname: uniqueClient,
                dropdown_clientcode: uniqueStationCode,
                dropdown_clientdata: alldata,
                show_table: true
            })
        })
    }

    // If the user selects the stationcode, it retrieves the remaining values like Client, zone etc

    getRemainingValues = () => {
        getRequest(`api/cod/getRemainingValues?input=` + this.state.formData.clientcode).then(response => {
            if(response.res.status === 'success'){
                let remainingData = response.res.data

                console.log(remainingData)

                let dropdown_client = remainingData.map(value=> value.client_short_name)

                let dropdown_clientmodel = remainingData.map(value => value.ci_business_type)

                let dropdown_zone = remainingData.map(value => value.zone)

                this.setState({
                    ...this.state,
                    dropdown_clientmodel: [dropdown_clientmodel[0]],
                    dropdown_zone: [dropdown_zone[0]],
                    formData: {
                        ...this.state.formData,
                        clientdata: dropdown_client[0],
                        clientmodel: dropdown_clientmodel[0],
                        zone: dropdown_zone[0],
                    }
                })
            } 
        })
    }

    // Handles user inputs like deposit date, remittance number etc

    handleChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME] : VALUE,
            }
        })
    }

    // Handles Reason Change

    handleReasonChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME] : VALUE,
                reason: VALUE
            }
        })
    }

    // handles status change

    handleStatusChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value

        this.setState({
            ...this.state,
            downloadButton: 'disabled',
            formData: {
                ...this.state.formData,
                [NAME] : VALUE
            }
        }, ()=>this.handleNoChange())
    }

    // If the deposit status is no, it manages the download button functionality

    handleNoChange = () => {
        if(this.state.formData.depositstatus === 'No'){
            this.setState({
                ...this.state,
                downloadButton: 'enable'
            }, ()=> console.log(this.state.downloadButton))
        }
    }

    // Handles Client change and fills the model dropdown based on the client (only relevant models will show)

    handleClientChange = (event) => {
        const VALUE = event.target.value;

        let model = []

        this.setState({
            formData: {
                clientdata : VALUE
            }
        })

        this.state.dropdown_clientdata.forEach(item => {
            if(item.client_short_name === VALUE){
                model.push(item.ci_business_type)
                return model
            }
        })

        let model1 = [...new Set(model)]

        this.setState({
            ...this.state,
            dropdown_clientmodel: model1,
            dropdown_clientcode: [],
            dropdown_zone: [],
            formData: {
                ...this.state.formData,
                clientdata: VALUE,
                clientmodel: '',
                zone: '',
                clientcode: ''
            }
        })
    }

    // Handles Model change and fills the zone dropdown based on the client and model

    handleModelChange = (event) => {
        const VALUE = event.target.value;

        let zone = []

        this.setState({
            formData: {
                ...this.state.formData,
                clientmodel : VALUE
            }
        })

        this.state.dropdown_clientdata.forEach(item => {
            if(item.ci_business_type === VALUE && item.client_short_name === this.state.formData.clientdata){
                zone.push(item.zone)
                return zone
            }
        })

        let zone1 = [...new Set(zone)]

        this.setState({
            ...this.state,
            dropdown_zone: zone1,
            formData: {
                ...this.state.formData,
                zone: '',
                clientcode: '',
                clientmodel: VALUE
            }
        })
    }

    // Handles Zone change and fills the station code dropdown based on the client, model and zone

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.dropdown_clientdata.forEach(item => {
            if(item.zone === VALUE && item.client_short_name === this.state.formData.clientdata && item.ci_business_type === this.state.formData.clientmodel){
                stationcode.push(item.client_hub_code)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_clientcode: stationcode1,
            formData: {
                ...this.state.formData,
                clientcode: '',                
                zone: VALUE
            }
        })
    }

    // Handles Station Change, which will fill all the remaining values

    handleStationChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                clientcode : VALUE
            }
        }, () => {
            this.getRemainingValues()
        })
    }

    handleSubmit =() => {
        // event.preventDefault()
        this.setState({
            ...this.state,
            ...this.state.formData,
            show_preview : true
        })
    }

    // Changes the file name, adding a unique string and removes any special character in deposit slip

    fileChangeHandler = (e) => {

        // const nameChange = [e.target.files[0]].map(value => {
        //     return value, {
        //         name: value.name.replace(/[^a-zA-Z0-9_.]/g, '')
        //     }
        // })

        const nameChange = [e.target.files[0]].map(file => {
            const uniqueString = Date.now().toString()

            const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9_.]/g, '')

            const fileExtension = sanitizedFileName.substring(sanitizedFileName.lastIndexOf('.'))

            const newFileName = `${sanitizedFileName.replace(fileExtension, '')}_${uniqueString}${fileExtension}`

            return {
                name: newFileName
            }
        })

        console.log(nameChange[0].name)

        this.setState({
            ...this.state,
            fileName: '',
            fileChange: nameChange[0].name, 
            uploadButton: 'enable',
            buttonColor: 'primary',
            downloadButton: 'disabled',
            selectedFile: e.target.files[0],
            formData : {
                ...this.state.formData,
                selectedFile: e.target.files[0]
            }            
        })
    }

    // Changes the file name, adding a unique string and removes any special character in remittance slip

    erpChangeHandler = (e) => {
        // const nameChange = [e.target.files[0]].map(value => {
        //     return value, {
        //         name: value.name.replace(/[^a-zA-Z0-9_.]/g, '')
        //     }
        // }) 

        const nameChange = [e.target.files[0]].map(file => {
            const uniqueString = Date.now().toString()

            const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9_.]/g, '')

            const fileExtension = sanitizedFileName.substring(sanitizedFileName.lastIndexOf('.'))

            const newFileName = `${sanitizedFileName.replace(fileExtension, '')}_${uniqueString}${fileExtension}`

            return {
                name: newFileName
            }
        })

        this.setState({
            ...this.state,
            erpName: '',
            erpChange: nameChange[0].name,
            erpUploadButton: 'enable',
            erpColor: 'primary',
            erpfile: e.target.files[0],
            formData: {
                ...this.state.formData,
                erpfile: e.target.files[0]
            }
        })
    }

    // Uploads the deposit slip

    fileUpload = async () => {
        const formData = new FormData()

        formData.append(
            "file",
            this.state.selectedFile
        )

        let fileKey = ""

        await postRequest('api/cod/fileupload', formData).then(response => {
            console.log(response)
            fileKey = response.res.data
        })

        setTimeout(() => {
            this.setState({
                ...this.state,
                buttonColor: 'secondary',
                downloadButton: 'enable',
                uploadButton: 'disabled',
                fileName: 'Yes',
                formData: {
                    ...this.state.formData,
                    // receipt: this.state.fileChange
                    receipt: fileKey
                }
            },
            () => console.log(this.state.formData))
        }, 2000)
    }

    // Uploads the remittance slip

    erpUpload = async () => {
        const formData = new FormData()

        formData.append(
            "file",
            this.state.erpfile
        )

        let fileKey = ""

        await postRequest(`api/cod/erpUpload`, formData).then(res => {
            console.log(res)
            fileKey = res.res.data
        })

        setTimeout(()=> {
            this.setState({
                ...this.state,
                erpName: 'Yes',
                erpButton: 'enable',
                erpUploadButton: 'disabled',
                erpColor: 'secondary',
                formData: {
                    ...this.state.formData,
                    // ERPScreenshot: this.state.erpChange
                    ERPScreenshot: fileKey
                }
            })
        })
    }

    // Main function which will prepare the object to be uploaded to the backend

    dataUpload = () => {

            const dateTime = getHumanReadableDateTime(new Date())
            console.log(dateTime)
            this.setState({
                ...this.state,
                formData:{
                    ...this.state.formData,
                    postedOn: dateTime,
                    slipnumber: (this.state.formData.slipnumber === '' || this.state.formData.depositstatus === 'No') ? 'Not Applicable' : this.state.formData.slipnumber.trim(),
                    depositedCOD: (this.state.formData.depositedCOD === '' || this.state.formData.depositstatus === 'No') ? 0 : this.state.formData.depositedCOD,
                    pendingCOD: this.state.formData.pendingCOD === '' ? 0 : this.state.formData.pendingCOD
                }
            }, ()=>this.handle())
    }

    // Final function which triggers API to push the record to the database

    handle = async () => {
        const formData = new FormData()
        formData.append("file", this.state.formData)
        console.log(this.state.formData)
        await postRequest('api/cod/insertRecords', this.state.formData).then(response => {
            console.log(response)
        })

        this.setState({
            ...this.state,
            confirmButtonColor: 'success',
            downloadButton: 'disabled',
            erpButton: 'disabled'
        })

        setTimeout(() => {
            this.setState({
                ...this.state,
                redirect: true
            })
        }, 15000)
    }

    // Downloads the deposit slip

    fileDownload = () => {
        const fileName = this.state.formData.receipt
        console.log(fileName)
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadConvertedFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
            console.log(response.data)
            const blob = response.data;
            download(blob, this.state.formData.selectedFile.name);
        })
    }

    // Downloads the ERP Screenshot

    erpDownload = () => {
        const fileName = this.state.formData.ERPScreenshot
        console.log(fileName)
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadERPFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
            console.log(response.data)
            const blob = response.data;
            download(blob, this.state.formData.erpfile.name);
        })
    }

    // Checks if all the required fields are filled based on the Client (AMAZON, FLIPKART etc)

    confirmationButtonCheck = () => {
        const {clientdata, clientcode, clientmodel, zone, date, depositstatus, slipnumber, pendingCOD, depositedCOD, reason, reasonforshortdeposit, selectedFile, erpfile, receipt, ERPScreenshot} = this.state.formData
        if(clientdata === 'FLIPKART' && clientcode && clientmodel && zone){
            if(depositstatus === 'No' && date){
                if(erpfile && pendingCOD && reason && ERPScreenshot) {
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            } else if (depositstatus === 'Yes' && date){
                if(slipnumber && depositedCOD && selectedFile && erpfile && receipt && ERPScreenshot) {
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            } else if (depositstatus === 'Short Deposit' && date){
                if(slipnumber && pendingCOD && depositedCOD && reasonforshortdeposit && selectedFile && erpfile && receipt && ERPScreenshot) {
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            }
        } else if ((clientdata === 'AMAZON' || clientdata === 'FAARMS') && clientcode && clientmodel && zone){
            if(depositstatus === 'No' && date){
                if(pendingCOD && reason) {
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            } else if (depositstatus === 'Yes' && date){
                if(slipnumber && depositedCOD && selectedFile && receipt) {
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            } else if (depositstatus === 'Short Deposit' && date){
                if(slipnumber && pendingCOD && depositedCOD && reasonforshortdeposit && selectedFile && receipt){
                    this.setState({
                        ...this.state,
                        showWarning: false
                    }, () => this.dataUpload())
                } else {
                    this.setState({
                        ...this.state,
                        showWarning: true
                    })
                }
            }
        } else {
            this.setState({
                ...this.state,
                showWarning: true
            })
        }
    }

  render() {
    if(this.state.redirect){
        return <Redirect to='/cod_posting_history'/>
    }
    return (
    <Fragment>

    <div className='header_div'>
       COD Daily Submission
    </div>
    <br/>
    <br/>

    <Form onSubmit={this.handleSubmit}>
    {this.state.show_table ? 
    
    <div className='row'>

        <FormBasicSelect label='Client' value={this.state.formData.clientdata} name='clientdata' onChange={this.handleClientChange} options={this.state.dropdown_clientname} />
        
        <FormBasicSelect label='Model' value={this.state.formData.clientmodel} name='clientmodel' onChange={this.handleModelChange} options={this.state.dropdown_clientmodel} />
        
        <FormBasicSelect label='Zone' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange} options={this.state.dropdown_zone} />

        <FormBasicSelect label='Station Code' value={this.state.formData.clientcode} name='clientcode' onChange={this.handleStationChange} options={this.state.dropdown_clientcode} />

    </div> : <LoadingAnimation/>}
    
    {this.state.show_table ?
    
    <div className='row'>
        
        <div className='col-md-3'>
            <Form.Group>
                <FormWithToolTipSelect label='Deposit Date' text='Enter the date at which the deposit is made'/>
                <input className='spacing' type='date' min='2021-01-01' name='date' value={this.state.formData.date} onChange={this.handleChange}></input>
            </Form.Group>
        </div>

        {this.state.formData.date ? 
        
        <div className='col-md-3'>
            <Form.Group>
                <FormWithToolTipSelect label='Deposit Status' text='Deposit details like fully deposited, partly or not deposited'/>
                <select className='spacing' value={this.state.formData.depositstatus} name='depositstatus' onChange={this.handleStatusChange}>
                    <option value='' disabled>Select</option>
                    <option value='Yes'>Yes (Fully Deposited)</option>
                    <option value='No'>No (Not Deposited)</option>
                    <option value='Short Deposit'>Short Deposit (Partly Deposited)</option>
                </select>
            </Form.Group>
        </div> : null}

        {this.state.formData.depositstatus === "Yes" || this.state.formData.depositstatus === "Short Deposit" ?
        
            <div className='col-md-3'>
                <Form.Group>
                    <FormWithToolTipSelect label='Client Remittance Number' text='Enter Remittance number for Amazon, PIS number for Reliance'/>
                    <input className='spacing' value={this.state.formData.slipnumber} name='slipnumber' onChange={this.handleChange}></input>
                </Form.Group>
            </div> 
         : null
        }

        {this.state.formData.depositstatus === "No" || this.state.formData.depositstatus === "Short Deposit" ?                
                    <div className='col-md-3'>
                        <Form.Group>
                            <FormWithToolTipSelect label='Pending Amount' text='Enter the pending amount in case of short deposit or no deposit'/>
                            <input className='spacing' type='number' value={this.state.formData.pendingCOD} name="pendingCOD" onChange={this.handleChange}></input>
                        </Form.Group>
                    </div>
                : null
            }

        {this.state.formData.depositstatus === "Short Deposit" || this.state.formData.depositstatus === "Yes" ?
                <div className='col-md-3'>
                    <Form.Group>
                        <FormWithToolTipSelect label='Deposited Amount' text='Enter the deposited amount in case of full deposit or short deposit'/>
                        <input className='spacing' type='number' value={this.state.formData.depositedCOD} name="depositedCOD" onChange={this.handleChange}></input>
                    </Form.Group>
                </div>
            : null
         }

        {this.state.formData.depositstatus === "Short Deposit" ?
                    <div className='col-md-3'>
                        <Form.Group>
                            <FormWithToolTipSelect label='Reason for Short Deposit' text='Enter the reason for short deposit'/>
                            <input className='spacing' value={this.state.formData.reasonforshortdeposit} name='reasonforshortdeposit' onChange={this.handleReasonChange}></input>
                        </Form.Group>
                    </div>
                : null
            }

        {this.state.formData.depositstatus === "Yes" || this.state.formData.depositstatus === "Short Deposit" ?
                    <div className='col-md-3'>
                        <Form.Group>
                            <FormWithToolTipSelect label='Receipt Upload' text='Upload the receipt'/>
                            <Form.Control style={{marginBottom:5}} type='file' accept='application/jpg, application/pdf, application/jpeg' onChange={this.fileChangeHandler}></Form.Control>
                            <Button variant = {this.state.buttonColor} onClick={this.fileUpload} disabled={!this.state.selectedFile || this.state.uploadButton === 'disabled'}>Upload</Button>
                        </Form.Group>
                    </div>
                 : null
            }

        {this.state.formData.clientdata === "FLIPKART" ?
                    <div className='col-md-3'>
                        <Form.Group>
                            <FormWithToolTipSelect label='ERP Screenshot' text='Upload ERP screenshot'/>
                            <Form.Control style={{marginBottom:5}} type='file' accept='application/jpg, application/pdf, application/jpeg' onChange={this.erpChangeHandler}></Form.Control>
                            <Button variant = {this.state.erpColor} onClick={this.erpUpload} disabled={!this.state.erpfile || this.state.erpUploadButton === 'disabled'}>Upload</Button>
                        </Form.Group>
                    </div>
                 : null
            }       

        {this.state.formData.depositstatus === "No" ?
                    <div className='col-md-3'>
                        <Form.Group>
                            <FormWithToolTipSelect label='Reason' text='Select a reason for no deposit'/>
                            <select className='spacing' value={this.state.formData.reason} name='reason' onChange={this.handleChange} >
                                <option value='' disabled>Select</option>
                                <option value='CMS arrived after 5PM'>CMS arrived after 5PM</option>
                                <option value='CMS did not come'>CMS did not come</option>
                                <option value='Bank Holiday'>Bank Holiday</option>
                                <option value='Pending from branch end'>Pending from branch end</option>
                                <option value='No COD collected'>No COD collected</option>
                                <option value='Reason not listed here'>Reason not listed here</option>
                            </select>
                        </Form.Group>
                    </div>
                 : null
            }

        
    </div> : null
    }

    <br/>
    <br/>
    {this.state.show_table ?
    <div className='col-md-4'>
        <table border = '1' id='table_styling'>
            <thead>
            <tr>
                <th style={{width: 150}}>Details </th>
                <th style={{width: 300}}> </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Client</td>
                <td>{this.state.formData.clientdata}</td>
            </tr>
            <tr>
                <td>Model</td>
                <td>{this.state.formData.clientmodel}</td>
            </tr>
            <tr>
                <td>Zone</td>
                <td>{this.state.formData.zone}</td>
            </tr>
            <tr>
                <td>Client Code</td>
                <td>{this.state.formData.clientcode}</td>
            </tr>

            {this.state.formData.date !== '' && this.state.formData.date !== null && this.state.formData.date !== undefined ?
            <tr>
                <td>Date</td>
                <td>{getHumanReadableDate(this.state.formData.date)}</td>
            </tr> : null}
            
            <tr>
                <td>Deposit Status</td>
                <td>{this.state.formData.depositstatus}</td>
            </tr> 
            
            {this.state.formData.depositstatus === 'Yes' || this.state.formData.depositstatus === 'Short Deposit' ?
            <tr>
                <td>Slip Number</td>
                <td>{this.state.formData.slipnumber}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'Yes' ?
            <tr>
                <td>Deposited Amount</td>
                <td>{convertToINR(Number(this.state.formData.depositedCOD))}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'No' || this.state.formData.depositstatus === 'Short Deposit' ?
            <tr>
                <td>Pending COD</td>
                <td>{convertToINR(Number(this.state.formData.pendingCOD))}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'No' ?
            <tr>
                <td>Reason</td>
                <td>{this.state.formData.reason}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'Short Deposit' ?
            <tr>
                <td>Deposited COD</td>
                <td>{convertToINR(Number(this.state.formData.depositedCOD))}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'Short Deposit' ?
            <tr>
                <td>Reason for Short Deposit</td>
                <td>{this.state.formData.reasonforshortdeposit}</td>
            </tr>
            : null}
            {this.state.fileName === 'Yes' ?
            <tr>
                <td>Receipt Name</td>
                <td>{this.state.selectedFile.name}</td>
            </tr>
            : null}
            {this.state.formData.depositstatus === 'Yes' || this.state.formData.depositstatus === 'Short Deposit' ?
            <tr>
                <td>Receipt Download</td>
                <td>
                    <div className='wrapper'>
                        <Button disabled={this.state.downloadButton === 'disabled'} onClick={this.fileDownload}><FileDownloadIcon/></Button>
                    </div>
                </td>
            </tr>
            : null}
            {this.state.erpName === 'Yes' ?
            <tr>
                <td>ERP Screenshot</td>
                <td>{this.state.erpfile.name}</td>
            </tr>
            : null }
            {this.state.erpName === 'Yes' ?
            <tr>
                <td>ERP Screenshot Download</td>
                <td>
                    <div className='wrapper'>
                        <Button disabled={this.state.erpButton === 'disabled'} onClick={this.erpDownload}><FileDownloadIcon/></Button>
                    </div>
                </td>
            </tr>
            : null }
            </tbody>
        </table>
        <br/>
        <br/>
        <div className='wrapper'>
        <Button variant={this.state.confirmButtonColor} onClick={this.confirmationButtonCheck}>Confirm</Button>       
        </div>
        {this.state.showWarning && <div style={{color:'red', marginLeft:'20px'}}>{this.state.warning}</div>}   
    </div> : null }
    </Form>
    </Fragment>
    
    )
  }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps) (COD)