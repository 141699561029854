import React, { Component, Fragment } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import DataTable from '../../components/DataTable2';


class EventCapture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                device_id: '',
                period_from: '',
                period_to: ''
            },
            DeviceIDList: [],
            data: [],
            columns: [{
                    name: 'Device ID',
                    selector: row => row['Device ID'],
                    sortable: true,
                    width: '110px'
                },
                {
                    name: 'Ev Time',
                    selector: row => row['Event Time'],
                    sortable: true,
                    width: '150px'
                },
                {
                    name: 'Ev Cat',
                    selector: row => row['Event Category'],
                    sortable: true,
                    width : '60px'
                },
                {
                    name: 'Ev Device',
                    selector: row => row['Event Device'],
                    sortable: true,
                },
                {
                    name: 'Ev Code',
                    selector: row => row['Event Code'],
                    sortable: true,
                },
                {
                    name: 'Occurence',
                    selector: row => row['Occurence'],
                    sortable: true,
                },
                {
                    name: 'Unit',
                    selector: row => row['Unit'],
                    sortable: true,
                    width : '70px'
                },
                {
                    name: 'Command',
                    selector: row => row['Command'],
                    sortable: true,
                },
                {
                    name: 'Module',
                    selector: row => row['Module'],
                    sortable: true,
                    width : '80px'
                },
                {
                    name: 'Err Desc',
                    selector: row => row['Err Desc'],
                    sortable: true,
                },
                {
                    name: 'Additional Info',
                    selector: row => row['Additional Info'],
                    sortable: true,
                },
                {
                    name: 'Seq ID',
                    selector: row => row['Seq ID'],
                    sortable: true,
                    width : '50px'
                },
                {
                    name: 'Cat Seq ID',
                    selector: row => row['Cat Seq ID'],
                    sortable: true,
                    width : '50px'
                },
            ],
            showData: false,
            fetching: false,
        }
    }

    handleChange = (ev) => {
        const {
            formData
        } = this.state;
        formData[ev.target.name] = ev.target.value;
        if (ev.target.name === 'device_obj') {
            formData['device_id'] = ev.target.value.value;
        }
        this.setState({
            formData
        });
    }

    onDeviceIDChange = (device_id) => {
        console.log(device_id);
    }

    componentDidMount = () => {
        getRequest('api/cms/getEventCaptureDeviceList').then((res) => {
            if (res.type === "success") {
                this.setState({
                    DeviceIDList: JSON.parse(res.res.data)
                });
            }
        })

    }


    setDate = (date, field) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [field]: date,
            }
        })
    }

    onFetchData = () => {
        this.setState({
            ...this.state,
            fetching: true,
            showData: false,
            data: []
        }, () => {
            postRequest('api/cms/getEventCaptureForDeviceAndPeriod', this.state.formData).then(res => {
                    if (res.type === "success") {
                        this.setState({
                            ...this.state,
                            data: JSON.parse(res.res.data),
                            showData: true,
                            fetching: false,
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        fetching: false,
                    })
                })
        })
    }

    buttonDisabled = () => {
        if (this.state.fetching) return true;

        if (this.state.formData.device_id !== '') return false

        if (this.state.formData.period_from !== '' && this.state.formData.period_to !== '') return false;


        return true;
    }

    render() { 
        return (  
            <div>
                <form name="event_history_form" defaultValue={this.state.formData}>
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="device_obj">Device ID</label>
                            <Select
                                value={this.state.formData.device_obj}
                                onChange={(value) => this.handleChange({ target : { name : 'device_obj', value } })}
                                options={this.state.DeviceIDList}
                            />
                        </div>
                        <div className="col-md-1" align="middle">
                            <div class="hr-sect">OR</div>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_from">Period From</label>
                            <DatePicker selected={this.state.formData.period_from} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_from') } id="period_from" />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="period_to">Period To</label>
                            <DatePicker selected={this.state.formData.period_to} showPopperArrow={false} dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'period_to') } id="period_to" />
                        </div>
                        <div className="col-md-2">
                            <button type='button' disabled={ this.buttonDisabled() } className='btn btn-info' style={{ marginTop : 33 }} onClick={this.onFetchData} >Fetch Data</button>
                        </div>
                    </div>
                </form>
                <br /><br />
                <Fragment>
                    <DataTable title="Event Capture" loading={this.state.fetching} columns={this.state.columns} key={this.state.data} data={this.state.data} />
                </Fragment>
            </div>
        );
    }
}
 
export default EventCapture;