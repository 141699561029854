import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import {CSVLink} from 'react-csv';


function TableWithDownload(props) {

    

  return (
    <div>
        <div className='row'>
            <div className='col-md-6' align='left'>
                <h5>{props.title}</h5>
            </div>
            <div className='col-md-6' align='right'>
            { props.download &&
                <CSVLink data={props.data} filename={props.downloadFileName + '.csv' } className='btn btn-primary' target='_blank'> <GetAppIcon /> CSV</CSVLink>
            }
            </div>

        </div>
        {
            props.loading ?

            <div>
                Loading...
            </div>
            :
            !props.data || !props.data.length ?

            <div>
                There are no records to display.
            </div>
            :
            <>
            
            <div className='scroll-table'>
            <table id='table-css' >
            <thead style={{heigth: '4rem'}}>
            <tr style={{heigth: '4rem'}}>
                {props.columns.map( (each, ind) => {
                    return(
                        <th style={each.style}>{each.name}</th>
                    )
                })}                                             
            </tr>
            </thead>

            <tbody>           
                {props.data.map((each, indx) => {
                    return(
                    <tr key={indx} >
                        {props.columns.map( (one, ind) => {
                            return(
                                <td >{each[one.selector]}</td>
                            )
                        })}
                    </tr>
                    )
                })
                }             
            </tbody>
            </table>
            </div>
            </>
        }
        
    </div>
  )
}

export default TableWithDownload