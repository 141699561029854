import React, { Fragment, Component } from "react";
import { getHumanReadableDateTime, getRequest, postRequest } from "../../globalhelper/helper";
import Select from 'react-select';
import Datatable from "../../components/DataTable2";

class TDSReconSheet extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData :{
                txnentity : '',
                tan : '',
                txnentity_obj : '',
                tan_obj : ''
            },
            columns : [
                {
                    name: 'S.No',
                    selector: (row, ind) => ind+1,
                    sortable: true,
                    width : '50px',
                },
                {
                    name: 'NewMatchCode',
                    selector: row => row["NewMatchCode"],
                    sortable: true,
                    width : '200px'
                },
                {
                    name: 'Group Ref3',
                    selector: row => row["Group Ref3"],
                    sortable: true,
                },
                {
                    name: 'Group Ref2',
                    selector: row => row["Group Ref2"],
                    sortable: true,
                },
                {
                    name: 'Group Ref1',
                    selector: row => row["Group Ref1"],
                    sortable: true,
                },
                {
                    name: 'Invoice Reference',
                    selector: row => row["Invoice Reference"],
                    sortable: true,
                },
                {
                    name: 'Recon Status',
                    selector: row => row["Recon Status"],
                    sortable: true,
                    width : '100px',
                },
                {
                    name: 'Legal Entity',
                    selector: row => row["Legal Entity"],
                    sortable: true,
                },
                {
                    name: 'TAN',
                    selector: row => row["TAN"],
                    sortable: true,
                },
                {
                    name: 'Names As Per Books',
                    selector: row => row["Names As Per Books"],
                    sortable: true,
                    width : '350px'
                },
                {
                    name: 'Names As Per F26AS',
                    selector: row => row["Names As Per F26AS"],
                    sortable: true,
                    width : '350px'
                },
                {
                    name: 'Account',
                    selector: row => row["Account"],
                    sortable: true,
                },
                {
                    name: 'Sale Posting Dts',
                    selector: row => row["Sale Posting Dts"],
                    sortable: true,
                },
                {
                    name: 'TDSR Dts',
                    selector: row => row["TDSR Dts"],
                    sortable: true,
                },
                {
                    name: 'F26AS Posting Dts',
                    selector: row => row["F26AS Posting Dts"],
                    sortable: true,
                },
                {
                    name: 'Sales Rev',
                    selector: row => row["Sales Rev"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'Receipts',
                    selector: row => row["Receipts"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'TDS Debits',
                    selector: row => row["TDS Debits"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'F26AS Amt Credited',
                    selector: row => row["F26AS Amt Credited"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'F26AS TDS Deposited',
                    selector: row => row["F26AS TDS Deposited"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'F26AS TDS vs TDS Led',
                    selector: row => row["F26AS TDS vs TDS Led"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'F26AS Amt Cr vs Sales Rev',
                    selector: row => row["F26AS Amt Cr vs Sales Rev"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'TDS (2018-19)',
                    selector: row => row["TDS (2018-19)"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'TDS Ref (2018-19)',
                    selector: row => row["TDS Ref (2018-19)"],
                    sortable: true,
                    style : {
                        paddingLeft : '20px'
                    },
                },
                {
                    name: 'TDS (2019-20)',
                    selector: row => row["TDS (2019-20)"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'TDS Ref (2019-20)',
                    selector: row => row["TDS Ref (2019-20)"],
                    sortable: true,
                    style : {
                        paddingLeft : '20px'
                    },
                },
                {
                    name: 'TDS(2020-21)',
                    selector: row => row["TDS(2020-21)"],
                    sortable: true,
                    isAmount : true
                },
                {
                    name: 'TDS Ref (2020-21)',
                    selector: row => row["TDS Ref (2020-21)"],
                    sortable: true,
                    style : {
                        paddingLeft : '20px'
                    },
                },
            ],
            fetching : false,
            txnEntityList : [],
            tanList : [],
            data : [],
         };
    }

    getTxnEntityList = () => {
        getRequest('api/tds/getTxnEntityList').then(res => {
            if(res.type === 'success') {
                this.setState({
                    txnEntityList : JSON.parse(res.res.data)
                });
            }
        });
    }

    handleChange = (ev) => {
        let { formData } = this.state;
        let NAME = ev.target.name, VALUE = ev.target.value;
        console.log(NAME, VALUE)
        formData[NAME] = VALUE;
        if(NAME === 'txnentity_obj'){
            formData['txnentity'] = VALUE.value;
        }
        if(NAME === 'tan_obj'){
            formData['tan'] = VALUE.map(tan => tan.value).join(',');
        }
        this.setState({
            ...this.state,
            formData
        }, () => {
            if(NAME === "txnentity_obj")
            {
                this.getTansListForTxnEntity(this.state.formData.txnentity);
            }
        });
    }

    getTansListForTxnEntity = () => {
        getRequest('api/tds/getTanListForTxnEntity?txnentity=' + this.state.formData.txnentity).then(res => {
            if(res.type === 'success') {
                this.setState({
                    tanList : JSON.parse(res.res.data)
                });
            }
        });
    }

    componentDidMount = () => {
        this.getTxnEntityList();
    }

    getTDSReconSheetForEntityAndTans = () => {
        this.setState({
            ...this.state,
            fetching : true
        },() => {
            const BODY = {
                tan : this.state.formData.tan,
                txnentity : this.state.formData.txnentity
            }
            postRequest('api/tds/getTDSReconSheetForEntityAndTans', { data : JSON.stringify(BODY)}).then(res => {
                if(res.type === 'success') {
                    // window.location.reload();
                    this.setState({
                        ...this.state,
                        data : JSON.parse(res.res.data),
                        fetching : false
                    })
                }
            });
        });

    }

    render() { 
        return (  
            <Fragment>
                <form >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Txn Entity</label>
                                <Select
                                    value={this.state.formData.txnentity_obj}
                                    onChange={(value) => this.handleChange({ target : { name : 'txnentity_obj', value } })}
                                    options={this.state.txnEntityList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>TAN</label>
                                <Select
                                    value={this.state.formData.tan_obj}
                                    isMulti
                                    onChange={(value) => this.handleChange({ target : { name : 'tan_obj', value } })}
                                    options={this.state.tanList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary" style={{ marginTop : 33 }} disabled={this.state.formData.txnentity == ""} onClick={this.getTDSReconSheetForEntityAndTans} >Get Data</button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <Datatable columns={this.state.columns} title="TDS Reconciliation Sheet" loading={this.state.fetching} key={this.state.data} data={this.state.data} ></Datatable>
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
export default TDSReconSheet;