import React, {
    PureComponent, Fragment
} from 'react';
import { connect } from 'react-redux'
import { getStandardDate, subtractDays, addDays, getRequest } from '../../globalhelper/helper';
import DisplayContainer from './display_container';
import TreeContainer from './tree_container';
import _ from 'lodash';

const sampleData = [
    {
        "source" : "demand_planning",
        "data" : [
            {
                "product_code" : "Mutton",
                "uom" : "Ton",
                "location" : "Anna Nagar",
                "type" : "BaseLine Forecast",
                "sales" : 50,
            },
            {
                "product_code" : "Mutton",
                "uom" : "Ton",
                "location" : "Anna Nagar",
                "type" : "Sales",
                "sales" : 45,
            },
            {
                "product_code" : "Mutton",
                "uom" : "Ton",
                "location" : "Adampakkam",
                "type" : "BaseLine Forecast",
                "sales" : 70,
            },
            {
                "product_code" : "Mutton",
                "uom" : "Ton",
                "location" : "Adampakkam",
                "type" : "Sales",
                "sales" : 50,
            },
            {
                "product_code" : "Chicken",
                "uom" : "Ton",
                "location" : "Anna Nagar",
                "type" : "BaseLine Forecast",
                "sales" : 100,
            },
            {
                "product_code" : "Chicken",
                "uom" : "Ton",
                "location" : "Anna Nagar",
                "type" : "Sales",
                "sales" : 120,
            },
            {
                "product_code" : "Chicken",
                "uom" : "Ton",
                "location" : "Adampakkam",
                "type" : "BaseLine Forecast",
                "sales" : 120,
            },
            {
                "product_code" : "Chicken",
                "uom" : "Ton",
                "location" : "Adampakkam",
                "type" : "Sales",
                "sales" : 120,
            }
        ]
    }
]

class GenerateForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDetails : false,
           JSON : {
                "components" : [
                    {
                        "type" : "table",
                        "dataSource" : "demand_planning",
                        "columns" : [
                            {
                                "label" : "Product Code",
                                "value_key" : "product_code",
                                "value_type" : "label"
                            },
                            {
                                "label" : "UOM",
                                "value_key" : "PurUOM",
                                "value_type" : "label"
                            },
                            {
                                "label" : "Location",
                                "value_key" : "location",
                                "value_type" : "label"
                            },
                            {
                                "label" : "Type",
                                "value_key" : "type",
                                "value_type" : "label"
                            },
                            {
                                "label" : getStandardDate(new Date()),
                                "value_key" : "demand_for_" + new Date().getTime(),
                                "value_type" : "input",
                                "sub_type" : "number"
                            }
                        ]
                    }
                ]
            },
            data : []
        }
        this.userData = this.props.state.login.userData;
    }

    componentDidMount = () => {
        getRequest('api/visual/getDesign?name=Demand Planning').then(data => {
            console.log(data);
            if(data.type === "success")
            {
                data.res.data.map((item, ind) => {
                    item.json.components.map((component, compInd) => {
                        component.columns.map((column, colInd) => {
                            if(column.label_fun)
                            {
                                if(column.label_gen === "today()")
                                {
                                    column.label = getStandardDate(new Date());
                                    column.value_key = column.value_prefix +"" + new Date(getStandardDate(new Date())).getTime();
                                    console.log(column);
                                }
                            }
                            // if(ind === data.res.data.length - 1 && compInd === item.json.components.length -1 && colInd === component.columns.length - 1)
                            // {
                            //     this.setState({
                            //         ...this.state,
                            //         JSON : data.res.data.length ? data.res.data[0].json : { components : []},
                            //         showDetails : true,
                            //     })
                            // }
                        })
                        
                        // if(ind === data.res.data.length - 1 && compInd === item.json.components.length -1)
                        // {
                        //     this.setState({
                        //         ...this.state,
                        //         JSON : data.res.data.length ? data.res.data[0].json : { components : []},
                        //         showDetails : true,
                        //     })
                        // }
                    })
                    
                    // if(ind === data.res.data.length - 1)
                    // {
                    // }
                })
                this.setState({
                    ...this.state,
                    JSON : data.res.data.length ? data.res.data[0].json : { components : []},
                    showDetails : true,
                })
            }
        })

        getRequest('api/transactions/getAllProducts').then(data => {
            if(data.type === "success")
            {
                this.setState({
                    ...this.state,
                    data : [
                        {
                            "source" : "demand_planning",
                            data : data.res.data
                        }
                    ]
                })
            }
        })
    }
    
    addColumns = () => {
        const historicPeriod = this.state.previousDays, forecastPeriod = this.state.upcomingDays;
        console.log(historicPeriod, forecastPeriod);
        var JSON = Object.assign({},this.state.JSON);
        JSON.components.map((component,ind) => {
            _.remove(component.columns, function (column) {
                return column.origin === 'generated'
              });

            component.columns.map((columns, key) => {
                
                if(columns.label === getStandardDate(new Date()))
                {

                    for(var i=0; i< Number(historicPeriod); i++)
                    {
                        console.log(subtractDays(i,'days'));
                         var newOBJ =  {
                            "label" : subtractDays(i +1,'days'),
                            "value_key" : "demand_for_" + new Date(subtractDays(i +1,'days')).getTime(),
                            "value_type" : "input",
                            "sub_type" : "number",
                            origin : "generated"
                        }
                        component.columns.splice(key, 0, newOBJ);
                    }

                    for(var j=0; j< Number(forecastPeriod); j++)
                    {
                        console.log(addDays(j + 1,'days'));
                        var newOBJ1 =  {
                           "label" : addDays(j+1,'days'),
                           "value_key" : "demand_for_" + new Date(addDays(j+1,'days')).getTime(),
                           "value_type" : "input",
                           "sub_type" : "number",
                           origin : "generated"
                       }
                        component.columns.push(newOBJ1);
                    }
                    // suits.splice(2, 0, "Brooks Brothers");
                }
            })

            if(ind === JSON.components.length - 1)
            {
                this.setState({
                    ...this.state,
                    JSON : JSON
                })
            }
        })

    }

    onDaysChange = (ev) => {
        var name = ev.target.name, value = ev.target.value;
        this.setState({
            ...this.state,
            [name] : value
        }, () => {
            this.addColumns()
        })
    }

    onColumnValueChange = (ev, dataIndex, ind) => {
        var name = ev.target.name, value= ev.target.value;

        var prevState = Object.assign({}, this.state);

        prevState.data.map((data, index) => {
            if(index === dataIndex)
            {
                data.data.map((dt, inx) => {
                    if(inx === ind)
                    {
                        dt[name] = value;
                    }
                })
            }
        })
        console.log(prevState);
        this.setState({
            ...this.state,
            data : prevState.data
        })
    }

    render() {
        return ( 
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="previousDays">Historic Period</label>
                        <input name="previousDays" id="previousDays" value={this.state.previousDays} onChange={this.onDaysChange} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="upcomingDays"> Forecast Period </label>
                        <input name="upcomingDays" id="upcomingDays" value={this.state.upcomingDays} onChange={this.onDaysChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        { this.state.showDetails && this.state.JSON.components.map((component, index) => 
                            this.state.data.map((data, dataIndex) => 
                                component.type === "table" && component.dataSource === data.source ?
                                    <table key={index} border="1" width="100%">
                                        <thead>
                                            <tr>
                                                { component.columns.map((column,key) => 
                                                    <th key={key}> { column.label } </th>
                                                ) }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { data.data.map((row, ind) => 
                                                <tr key={ind} >
                                                    { component.columns.map((column,key) => 
                                                        <td key={key}> { 
                                                            column.value_type === "label" ?
                                                            <label>
                                                                { row[column.value_key] }
                                                            </label> :
                                                            column.value_type === "input" ?
                                                            <input name={column.value_key} onChange={ (ev) => this.onColumnValueChange(ev,dataIndex, ind) } id={column.value_key} value={row[column.value_key]} type={column.sub_type} ></input> :
                                                            null
                                                        } </td>
                                                    ) }
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                            : null
                            )
                        )}
                    </div>
                </div>
           </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
)(GenerateForm);