import React,{ memo, useCallback, useState, useEffect } from 'react'


export default function Filter({store, uptStore, onSave}) {


    const onCheck = (length, array) => {

        let prevStore = store

        if(length === 3){

            let val = prevStore[array[0]][array[1]][array[2]].isSelected

            prevStore[array[0]][array[1]][array[2]].isSelected = !val
        }
        if(length === 6){

            let val = prevStore[array[0]][array[1]][array[2]][array[3]][array[4]][array[5]].isSelected

            prevStore[array[0]][array[1]][array[2]][array[3]][array[4]][array[5]].isSelected = !val
        }
        if(length === 9){

            let val = prevStore[array[0]][array[1]][array[2]][array[3]][array[4]][array[5]][array[6]][array[7]][array[8]].isSelected

            prevStore[array[0]][array[1]][array[2]][array[3]][array[4]][array[5]][array[6]][array[7]][array[8]].isSelected = !val
        }

        uptStore( {...prevStore} )
    }

    


  return (
    <div>

        <div>
            { Object.keys(store).map( one => {
                return(

                <div>
                    <div style={{fontWeight: 'bold', fontSize: '1rem'}}>{store[one].Q}</div>
                    { 
                    Object.keys(store[one].options).map( two => {
                        let isSelected = store[one].options[two].isSelected
                        return(
                        <div>
                        <div onClick={() => onCheck(3, [one, "options", two])} style={{padding: 0, margin: 0}}><input type='checkbox' className="exclude-input" name={two} checked={isSelected} /> {two} </div>
                        { isSelected && Object.keys(store[one].options[two]).map( three => {

                            if( three !== "isSelected") return(
                                <div style={{marginLeft: "2rem"}}>
                                    <div style={{fontWeight: 'bold', fontSize: '1rem'}}>{store[one].options[two][three].Q}</div>
                                    {
                                        Object.keys(store[one].options[two][three].options).map( four => {
                                            let isSelected = store[one].options[two][three].options[four].isSelected
                                            return(
                                                <div>
                                                <div onClick={() => onCheck(6, [one, "options", two, three, "options", four])}><input type='checkbox' className="exclude-input" name={four} checked={isSelected} /> {four}</div>

                                                { isSelected && Object.keys(store[one].options[two][three].options[four]).map( five => {

                                                    if( five !== "isSelected") return(
                                                        <div style={{marginLeft: "2rem"}}> 
                                                            <div style={{fontWeight: 'bold', fontSize: '1rem'}}>{store[one].options[two][three].options[four][five].Q}</div>
                                                            {
                                                                Object.keys(store[one].options[two][three].options[four][five].options).map( six => {
                                                                    let isSelected = store[one].options[two][three].options[four][five].options[six].isSelected
                                                                    return(
                                                                        <div>
                                                                        <div onClick={() => onCheck(9, [one, "options", two, three, "options", four, five, "options", six])}><input type='checkbox' className="exclude-input" name={six} checked={isSelected} /> {six} </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    )
                                                })
                                                }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                            })
                        }
                        </div>
                        )
                    })

                    }

                </div>
                )

            })}
        </div>

        <div>
            <button className='btn btn-success mt-5' onClick={onSave}>Submit</button>
        </div>

    </div>
  )
}
