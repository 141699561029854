import {
  SHOWALERT,
  HIDEALERT,
  FLUSH_MENU_TREE,
  NAVURL,
  INITIATECOMMON,
  SET_MENU_TREE,
  FLESH_COMMON_OBJECT,
  GET_CURRENT_MENU_TREE
} from '../constants/action-types';



export default function common(state = [], action) {
  switch (action.type) {
    case SHOWALERT:
      return {
        ...state,
        alert: action.text,
        status: action.status
      }
      case HIDEALERT:
        return {
          ...state,
          alert: null,
            status: null
        }
        case GET_CURRENT_MENU_TREE:
          // console.log(state.menuTree, "menuTree")
          return state.menuTree ? state.menuTree : [];
        case FLESH_COMMON_OBJECT:
          return {
            url: '',
              alert: null,
              status: null,
              menuTree: []
          }
          case FLUSH_MENU_TREE:
            return {
              ...state,
              menuTree: []
            }
            case NAVURL:
              // history.push(action.url);
              return {
                ...state,
                url: action.url
              }
              case SET_MENU_TREE:
                console.log(action.menuTree, "Reducers")
                return {
                  ...state,
                  menuTree: action.menuTree
                }
                case INITIATECOMMON:
                  return action.data
                default:
                  return state
  }
}

// export default common;