import './CIPopup.css';
import './LARPopup.css'
import {Modal, Button} from 'react-bootstrap';

const StationPopup = (props) => {
    return(
        <div>
            <Modal show={true} onHide={false} backdrop='static'>
                <Modal.Header>
                    <Modal.Title style={{fontSize:'1rem'}}>Please select all the required fields</Modal.Title>
                </Modal.Header>

                <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                    <button className='btn red_button' onClick={props.handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StationPopup