import './LARPopup.css'

function FormBasicInput({label, value, name, type, disabled, onChange}){
    return (
        <div className='outline'>
            <div className='col-lg-2 content'>{label}:</div>
            {onChange ?
            <input className='col-lg-3 input_content' value={value} name={name} type={type} disabled={disabled} onChange={onChange}></input>
            : 
            <input className='col-lg-3 input_content' value={value} name={name} type={type} disabled={disabled}></input>}
        </div>
    )
}

export default FormBasicInput